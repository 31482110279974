import { Checkbox as CheckboxAnt, CheckboxProps } from "antd";
import React from "react";
import BigCheckboxGroup from "./BigCheckboxGroup";

interface Props extends CheckboxProps {}

export default function Checkbox(props: Props) {
  return <CheckboxAnt {...props} />;
}

Checkbox.__ANT_CHECKBOX = true;
Checkbox.BigGroup = BigCheckboxGroup;
