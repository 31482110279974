import { CountryCode } from "@trolley/common-frontend";
import { UploadFile } from "antd";
import { Onboarding } from "store/actions/onboarding";

export type ValueOf<T> = T[keyof T];

export function mapToUploadFile(file: ValueOf<Onboarding["files"]>, index?: number, array?: any[]): UploadFile {
  return {
    uid: file.id,
    url: file.uri,
    name: (file.uri || "").substring((file.uri || "").lastIndexOf("/") + 1),
    status: "success",
  };
}

const US_BUSINESS_TYPE = [
  { label: "C-Corporation ", value: "corporation" },
  { label: "S-Corporation ", value: "s_corporation" },
  { label: "Public Company", value: "public_company" },
  { label: "LLC", value: "llc" },
  { label: "LLP", value: "llp" },
  { label: "Sole Proprietor", value: "sole_proprietor" },
  { label: "Government Entity", value: "government_entity" },
  { label: "Non-Profit Organization", value: "non-profit_organization" },
];

const CA_BUSINESS_TYPE = [
  { label: "Corporation ", value: "corporation" },
  { label: "Public Company", value: "public_company" },
  { label: "LLC", value: "llc" },
  { label: "LLP", value: "llp" },
  { label: "Sole Proprietor", value: "sole_proprietor" },
  { label: "Government Entity", value: "government_entity" },
  { label: "Non-Profit Organization", value: "non-profit_organization" },
];

const EU_BUSINESS_TYPE = [
  { label: "Limited Company", value: "corporation" },
  { label: "Public Limited Company", value: "public_company" },
  { label: "Partnership", value: "llp" },
  { label: "Sole Trader", value: "sole_proprietor" },
  { label: "Government Entity", value: "government_entity" },
  { label: "Non-Profit Organization", value: "non-profit_organization" },
];

export function getBusinessTypes(country: CountryCode | null | undefined): { label: string; value: string }[] {
  switch (country) {
    case CountryCode.US:
      return US_BUSINESS_TYPE;
    case CountryCode.CA:
      return CA_BUSINESS_TYPE;
    default:
      return EU_BUSINESS_TYPE;
  }
}

const US_ID_TYPES = [
  { label: "Passport", value: "passport" },
  { label: "Passport Card", value: "passport_card" },
  { label: "Drivers License", value: "drivers_license" },
];

const CA_ID_TYPES = [
  { label: "Passport", value: "passport" },
  { label: "Drivers License", value: "drivers_license" },
  { label: "Permanent Resident Card", value: "permanent_resident_card" },
];

const EU_ID_TYPES = [
  { label: "Passport", value: "passport" },
  { label: "Drivers License", value: "drivers_license" },
  { label: "National ID Card", value: "permanent_resident_card" },
];

export function getIdTypes(country: CountryCode | null | undefined): { label: string; value: string }[] {
  switch (country) {
    case CountryCode.US:
      return US_ID_TYPES;
    case CountryCode.CA:
      return CA_ID_TYPES;
    default:
      return EU_ID_TYPES;
  }
}
