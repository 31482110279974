import { Form as AntForm, FormInstance, FormItemProps } from "antd";
import React from "react";

export default function Control<V = any>(
  props: Pick<FormItemProps<V>, "shouldUpdate" | "dependencies"> & {
    children: (form: FormInstance<V>) => React.ReactNode;
  },
) {
  return <AntForm.Item noStyle {...props} />;
}
