import {
  Access,
  CurrencyCode,
  EndOfYearFormType,
  EoyTaxReportDeliveryStatusEmail,
  EoyTaxReportDeliveryStatusMail,
  EoyTaxReportStatus,
  TaxDeliveryType,
  TaxFormType,
} from "@trolley/common-frontend";
import BigNumber from "bignumber.js";
import {
  Alert,
  Button,
  ButtonDropdown,
  ColumnsDisplay,
  Container,
  CurrencyDisplay,
  DateDisplay,
  Divider,
  Dropdown,
  FileDownload,
  Grid,
  Heading,
  Icon,
  LabelTooltip,
  Menu,
  MenuProps,
  Modal,
  RecordCount,
  Reloader,
  Space,
  Status,
  Stop,
  Table,
  TableColumnsType,
  TableFilterField,
  TableFilters,
  TableName,
  Text,
  Tooltip,
  tableColumnFilter,
  useColumnsDisplay,
} from "components";
import { RecipientDeliveryMethod, RecipientProfile } from "features/recipient";
import { translateDeliveryMethod } from "features/recipient/RecipientDeliveryMethod";
import { EoyTaxFormTypeDisplay, TaxFormDisplay } from "features/taxForm";
import TaxDocumentPreview from "pages/RecipientsPage/Details/Tax/TaxDocumentPreview";
import { TAX_ENTITY_TYPE_LABELS, taxTypeLabelArray } from "pages/RecipientsPage/Details/Tax/variables";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useHistory, useParams } from "react-router-dom";
import { computeID } from "store/actions/actionUtils";
import { resetEoySummary } from "store/actions/eoySummary";
import { EoyTax, EoyTaxQuery, bulkUpdate, resendEoyTaxForms, resetEoyTaxForms, sendEoyTaxForms, updateEoyTaxForms } from "store/actions/eoyTaxForms";
import { MerchantSettings } from "store/actions/merchantSettings";
import { notify, notifyError, notifySuccess } from "store/actions/notifications";
import { getTaxFormLabel } from "store/actions/recipientTaxForms";
import { TaxMerchantYear } from "store/actions/taxMerchantYear";
import { useEoyTaxForms } from "store/hooks/eoyTaxForms";
import { useMerchantSettings } from "store/hooks/merchantSettings";
import { useTaxForm } from "store/hooks/taxForms";
import { useMerchantTaxYear } from "store/hooks/taxMerchantYear";
import { useAccess } from "store/hooks/user";
import { BaseError } from "store/reducers/standardReducer";
import styled from "style/classname";
import { BREAKPOINTS } from "style/variables";
import { pick } from "utils/helpers";
import { useQuery, useWindowSize } from "utils/hooks";
import { Pages, isProfileComplete, isStatementActionLocked, StatementActions } from ".";
import { LAST_YEAR, TAX_PATHS } from "../..";
import EarningPreview from "./EarningPreview";
import ReviewEarningProgress from "./ReviewEarningProgress";
import { TaxDeliveryCostWarning } from "./TaxStatements";

const styledTableRow = styled`
  height: 60px !important;
`();

const FILTER_FIELDS: TableFilterField[] = [
  {
    type: "select",
    label: "Earnings Status",
    filterKey: "status",
    options: [
      { label: "Needs Review", value: EoyTaxReportStatus.NEEDS_REVIEW },
      { label: "Approved", value: EoyTaxReportStatus.APPROVED },
      { label: "Hold", value: EoyTaxReportStatus.HOLD },
      { label: "Do Not Send", value: EoyTaxReportStatus.DO_NOT_SEND },
      { label: "Sent to Recipient", value: EoyTaxReportStatus.SENT },
      { label: "Voided", value: EoyTaxReportStatus.VOID },
    ],
  },
  {
    type: "select",
    label: "Tax Form Type",
    filterKey: "taxFormTypes",
    options: [
      { label: "W-9", value: TaxFormType.W9 },
      { label: "W-8BEN", value: TaxFormType.W8BEN },
      { label: "W-8BEN-E", value: TaxFormType.W8BENE },
      // { label: "8233", value: TaxFormType.F8233 },
      // { label: "W-4", value: UsUploadTaxFormType.W4 },
      // { label: "W-8ECI", value: UsUploadTaxFormType.W8ECI },
      // { label: "W-8EXP", value: UsUploadTaxFormType.W8EXP },
      // { label: "W-8IMY", value: UsUploadTaxFormType.W8IMY },
      // { label: "No Form", value: "missing" }, // or "no-form"?
    ],
  },
  {
    type: "select",
    label: "Tax Statement Type",
    filterKey: "formTypes",
    options: [
      { label: getTaxFormLabel(EndOfYearFormType.F1099_NEC), value: EndOfYearFormType.F1099_NEC },
      { label: getTaxFormLabel(EndOfYearFormType.F1099), value: EndOfYearFormType.F1099 },
      { label: getTaxFormLabel(EndOfYearFormType.F1042), value: EndOfYearFormType.F1042 },
      { label: getTaxFormLabel(EndOfYearFormType.NONE), value: EndOfYearFormType.NONE },
    ],
  },
  {
    type: "input",
    inputMode: "numeric",
    label: "Earnings Less Than",
    filterKey: "totalEquivUntaxedAmountLt",
  },
  {
    type: "input",
    inputMode: "numeric",
    label: "Earnings Equal or Greater Than",
    filterKey: "totalEquivUntaxedAmountGte",
  },
  {
    type: "select",
    label: "US Federal Tax Classification",
    filterKey: "taxTypes",
    options: taxTypeLabelArray.map(([key, label]) => ({
      label,
      value: key,
    })),
  },
  {
    type: "radio",
    label: "Statement Delivery Method",
    filterKey: "taxDeliveryType",
    options: [
      { label: "All", value: undefined },
      { label: "E-Delivery", value: TaxDeliveryType.E_DELIVERY },
      { label: "Postal Mail", value: TaxDeliveryType.MAIL },
    ],
  },
  {
    type: "radio",
    label: "Statement Requires Delivery",
    tooltip: (
      <>
        Meets criteria requiring a tax statement to be sent to recipients. Criteria includes:
        <RequiresTaxStatementCriteria />
      </>
    ),
    filterKey: "requireTaxForm",
    options: [
      {
        label: "All",
        value: undefined,
      },
      {
        label: "Required",
        value: true,
      },
      {
        label: "Optional",
        value: false,
      },
    ],
  },
  {
    type: "radio",
    label: "Total Withholdings Warnings",
    tooltip: "Tax statements where the expected and reported total withholdings amounts are different.",
    filterKey: "hasWithholdingMismatch",
    options: [
      {
        label: "All",
        value: undefined,
      },
      {
        label: "Yes",
        value: true,
      },
      {
        label: "No",
        value: false,
      },
    ],
  },
  {
    type: "radio",
    label: "Downloaded By Recipient",
    tooltip: "Recipient has downloaded the Tax statement through the Widget/Portal",
    filterKey: "downloadedByRecipient",
    options: [
      {
        label: "All",
        value: undefined,
      },
      {
        label: "Yes",
        value: true,
      },
      {
        label: "No",
        value: false,
      },
    ],
  },
  {
    type: "radio",
    label: "Submitted to the IRS",
    tooltip: "Tax statements that have been submitted to the IRS",
    filterKey: "requireIrsSubmittedAt",
    options: [
      {
        label: "All",
        value: undefined,
      },
      {
        label: "Yes",
        value: true,
      },
      {
        label: "No",
        value: false,
      },
    ],
  },
  {
    type: "tags",
    label: "Tax Statement IDs",
    placeholder: "Tax Statement ID",
    filterKey: "ids",
  },
];

export function OffLinePaymentsInstruction() {
  return (
    <p>
      If there were payments made outside of Trolley to a recipient during the tax year that need to be reported, you can add or upload{" "}
      <Link to="/payments/offline-payments">Offline Payments</Link> data to make the total earnings and withholding accurate.
    </p>
  );
}

export function ApprovalInstruction() {
  return (
    <p>
      You can mark any recipients that don't require to be sent a 1099 or 1042-S tax statement as <q>Do Not Send</q>, such as C-Corporations and S-Corporations
      or those that received less than <CurrencyDisplay value="600" currency="USD" /> (or less than <CurrencyDisplay value="10" currency="USD" /> for
      royalties). Remember, anyone that had any withholding applied, even if just 1 cent, will need to be sent a tax statement.
    </p>
  );
}

export function UnsupportedTaxForms() {
  return (
    <p>
      Note that the Trolley tax center does not support EOY tax reporting for forms: W-8ECI, W-8EXP, W-8IMY, W-4, and 8223. You would need to handle those forms
      manually.
    </p>
  );
}

export function TaxEntityDisplay({ taxFormId }: { taxFormId: string | undefined | null }) {
  const { data: taxForm } = useTaxForm(taxFormId);

  if (taxForm?.taxFormType === TaxFormType.W9) {
    const taxType = (taxForm?.taxFormData as any)?.taxType;
    if (taxType) {
      return <span>{TAX_ENTITY_TYPE_LABELS[taxType] || taxType}</span>;
    }
  }

  return <span>-</span>;
}

const DEFAULT_QUERY: EoyTaxQuery = {
  page: 1,
  pageSize: 10,
  requireTaxForm: true,
  status: [EoyTaxReportStatus.NEEDS_REVIEW, EoyTaxReportStatus.HOLD],
};

enum ColumnKeys { // also used for sortBy query if exists
  STATUS = "status",
  PROGRESS = "progress",
  RECIPIENT = "recipient",
  EARNINGS = "reportData.totalEquivUntaxedAmount",
  WITHHOLDING = "reportData.totalEquivWithholdingAmount",
  TAX_PAID_WITHHOLDING_AGENT = "taxPaidByWithholdingAgents",
  FORM_TYPE = "formType",
  REQUIRE_TAX_FORM = "requireTaxForm",
  EFILED = "irsSubmittedAt",
  DELIVERY = "delivery",
  UPDATED_AT = "updatedAt",
  TAX_FORM_TYPE = "taxFormType",
  DOWNLOADED_BY_RECIPIENT = "downloadedByRecipient",
  US_TAX_ENTITY_TYPE = "taxType",
  ACTIONS = "actions",
}
const defaultColumnsDisplay = {
  [ColumnKeys.PROGRESS]: {
    label: "Progress",
    value: true,
  },
  [ColumnKeys.WITHHOLDING]: {
    label: "Total Withholdings",
    value: true,
  },
  [ColumnKeys.TAX_PAID_WITHHOLDING_AGENT]: {
    label: "Tax Paid By Withholding Agent",
    value: false,
  },
  [ColumnKeys.TAX_FORM_TYPE]: {
    label: "Tax Form Type",
    value: false,
  },
  [ColumnKeys.FORM_TYPE]: {
    label: "Tax Statement Type",
    value: true,
  },
  [ColumnKeys.REQUIRE_TAX_FORM]: {
    label: "Requires Delivery",
    value: true,
  },
  [ColumnKeys.DOWNLOADED_BY_RECIPIENT]: {
    label: "Downloaded By Recipient",
    value: true,
  },
  [ColumnKeys.EFILED]: {
    label: "Submitted to the IRS",
    value: true,
  },
  [ColumnKeys.DELIVERY]: {
    label: "Delivery Method",
    value: true,
  },
  [ColumnKeys.US_TAX_ENTITY_TYPE]: {
    label: "US Federal Tax Classification",
    value: false,
  },
  [ColumnKeys.UPDATED_AT]: {
    label: "Updated On",
    value: false,
  },
};

export function RequiresTaxStatementCriteria() {
  return (
    <ul>
      <li>All recipient earnings with a non-1099 Tax Statement.</li>
      <li>
        1099 Tax Statements with at least <CurrencyDisplay value="10" currency="USD" /> paid in the tax year for Royalties (Copyright, Film & TV) income type.
      </li>
      <li>
        1099 Tax Statements with at least <CurrencyDisplay value="600" currency="USD" /> paid in the tax year in Services performed by someone who is not your
        employee, Rents, Prizes and awards, Other income payments.
      </li>
      <li>
        1099 Tax Statement with US Federal Tax Classification excluding <em>C-Corporations</em>, <em>S-Corporations</em>, and{" "}
        <em>LLCs that elect treatment as an S-Corporation or C-Corporation</em>, as these do not need to be sent a 1099 Tax Statement.
      </li>
      <li>
        Includes ALL recipient earnings that have ANY withholding (even just <CurrencyDisplay value="0.01" currency="USD" />
        ). This includes corporations that meet the previous criteria.
      </li>
    </ul>
  );
}

function isApprovable(eoyTax: EoyTax) {
  return (
    [EoyTaxReportStatus.NEEDS_REVIEW, EoyTaxReportStatus.HOLD, EoyTaxReportStatus.DO_NOT_SEND].includes(eoyTax.status) &&
    eoyTax.formType !== EndOfYearFormType.NONE
  );
}

function isHoldable(eoyTax: EoyTax) {
  return [EoyTaxReportStatus.NEEDS_REVIEW, EoyTaxReportStatus.APPROVED, EoyTaxReportStatus.DO_NOT_SEND].includes(eoyTax.status);
}

function isDNSendable(eoyTax: EoyTax) {
  return [EoyTaxReportStatus.NEEDS_REVIEW, EoyTaxReportStatus.APPROVED, EoyTaxReportStatus.HOLD].includes(eoyTax.status);
}

function isSendable(taxMerchantYear: TaxMerchantYear | undefined, eoyTax: EoyTax) {
  return eoyTax.status === EoyTaxReportStatus.APPROVED && eoyTax.formType !== EndOfYearFormType.NONE && isProfileComplete(taxMerchantYear, eoyTax.formType);
}

function isResendable(taxMerchantYear: TaxMerchantYear | undefined, eoyTax: EoyTax) {
  return eoyTax.status === EoyTaxReportStatus.SENT && eoyTax.formType !== EndOfYearFormType.NONE && isProfileComplete(taxMerchantYear, eoyTax.formType);
}

function getHiddenColumns(merchantSettings: MerchantSettings | undefined, ongoingYear: boolean, accessTaxEOYWrite: boolean, width: number) {
  const excludedColumns: string[] = [];

  if (!merchantSettings?.tax?.enabledTaxPaidByWithholdingAgents) {
    excludedColumns.push(ColumnKeys.TAX_PAID_WITHHOLDING_AGENT);
  }

  if (!ongoingYear || width < BREAKPOINTS.md || !accessTaxEOYWrite) {
    excludedColumns.push(ColumnKeys.ACTIONS);
  }

  return excludedColumns;
}

export default function ReviewEarnings() {
  const history = useHistory();
  const { width = 0 } = useWindowSize();
  const { data: merchantSettings } = useMerchantSettings();
  const accessTaxEOYWrite = useAccess(Access.TAX_EOY_WRITE);
  const params = useParams<{ taxYear: string }>();
  const taxYear = Number(params.taxYear);
  const ongoingYear = taxYear >= LAST_YEAR;
  const [urlQuery, setQuery] = useQuery<EoyTaxQuery>(DEFAULT_QUERY, {
    arrayKeys: ["taxFormTypes", "formTypes", "taxTypes", "ids"],
    numberKeys: ["totalEquivUntaxedAmountLt", "totalEquivUntaxedAmountGte"],
  });

  const query = urlQuery.ids?.length ? pick(urlQuery, ["page", "pageSize", "orderBy", "sortBy", "ids"]) : urlQuery;
  const [selected, setSelected] = useState<EoyTax[]>([]);
  const [previewEoyTax, setPreviewEoyTax] = useState<EoyTax | undefined>();
  const [previewTaxForm, setPreviewTaxForm] = useState("");
  const [selectAll, setSelectAll] = useState(false);
  const { data: taxMerchantYear } = useMerchantTaxYear(taxYear);
  const [displayedColumns, setDisplayedColumns] = useColumnsDisplay(
    TableName.TAX_EARNINGS,
    defaultColumnsDisplay,
    getHiddenColumns(merchantSettings, ongoingYear, accessTaxEOYWrite, width),
  );
  const { data: eoyTaxForms, status: eoyTaxFormsStatus, error: eoyTaxFormsErrors } = useEoyTaxForms(taxYear, query);
  const { records, meta } = eoyTaxForms;
  const selectedApprovable = selected.filter(isApprovable);
  const selectedHoldable = selected.filter(isHoldable);
  const selectedDoNotSendable = selected.filter(isDNSendable);
  const selectedSendable = selected.filter((t) => isSendable(taxMerchantYear, t));
  const selectedResendable = selected.filter((t) => isResendable(taxMerchantYear, t));
  const selectedVoidable = selected.filter((t) => t.status === EoyTaxReportStatus.SENT);

  useEffect(() => {
    setSelected([]);
    setSelectAll(false);
  }, [computeID(query)]);

  function onChangeStatus(
    status: EoyTaxReportStatus.APPROVED | EoyTaxReportStatus.HOLD | EoyTaxReportStatus.DO_NOT_SEND | EoyTaxReportStatus.VOID,
    eoyTax?: EoyTax,
  ) {
    return async (e?: any) => {
      e?.stopPropagation?.();
      try {
        if (eoyTax) {
          await updateEoyTaxForms(taxYear, {
            recipientEoyTaxIds: [
              {
                id: eoyTax.id,
                hash: eoyTax.hash,
              },
            ],
            status,
          });
        } else {
          let selectedTaxForms = selectedApprovable;
          if (status === EoyTaxReportStatus.HOLD) {
            selectedTaxForms = selectedHoldable;
          } else if (status === EoyTaxReportStatus.DO_NOT_SEND) {
            selectedTaxForms = selectedDoNotSendable;
          } else if (status === EoyTaxReportStatus.VOID) {
            selectedTaxForms = selectedVoidable;
          }

          if (selectedTaxForms.length > 0) {
            await updateEoyTaxForms(taxYear, {
              recipientEoyTaxIds: selectedTaxForms.map((t) => ({
                id: t.id,
                hash: t.hash,
              })),
              status,
            });
          }
        }
        notifySuccess("Status updated");
        setSelected([]);
        resetEoySummary();
      } catch (errors) {
        notifyError("Updating status failed", { errors });
      }
    };
  }

  function getMenu(eoyTax?: EoyTax) {
    const enableApprove = eoyTax ? isApprovable(eoyTax) : selectedApprovable.length > 0;
    const enableHold = eoyTax ? isHoldable(eoyTax) : selectedHoldable.length > 0;
    const enableDNS = eoyTax ? isDNSendable(eoyTax) : selectedDoNotSendable.length > 0;
    const enableSend = eoyTax ? isSendable(taxMerchantYear, eoyTax) : selectedSendable.length > 0;
    const enableResend = eoyTax ? isResendable(taxMerchantYear, eoyTax) : selectedResendable.length > 0;
    const enableVoid = eoyTax ? eoyTax.status === EoyTaxReportStatus.SENT : selectedVoidable.length > 0;

    return (
      <Menu onClick={onClickMenuStatus(eoyTax)}>
        {(!eoyTax || ![EoyTaxReportStatus.SENT, EoyTaxReportStatus.VOID].includes(eoyTax.status)) && [
          <Menu.Item key={EoyTaxReportStatus.APPROVED} disabled={!enableApprove}>
            <Icon type="check" theme="solid" color={enableApprove ? "green" : undefined} left />
            Approve Earnings{!eoyTax && ` (${selectedApprovable.length})`}
          </Menu.Item>,
          <Menu.Item key={EoyTaxReportStatus.HOLD} disabled={!enableHold}>
            <Icon type="pause" theme="solid" color={enableHold ? "yellow" : undefined} left />
            Hold Approval{!eoyTax && `s (${selectedHoldable.length})`}
          </Menu.Item>,
          <Menu.Item key={EoyTaxReportStatus.DO_NOT_SEND} disabled={!enableDNS}>
            <Icon type="stop" theme="solid" color={enableDNS ? "grey" : undefined} left />
            Do Not Send{!eoyTax && ` (${selectedDoNotSendable.length})`}
          </Menu.Item>,
        ]}
        {(!eoyTax || eoyTax.status === EoyTaxReportStatus.APPROVED) && (
          <Menu.Item key={EoyTaxReportStatus.SENT} disabled={!enableSend || isStatementActionLocked(taxYear, StatementActions.SEND)}>
            <Icon type="paper-plane" theme="solid" color={enableSend ? "blue" : undefined} left />
            Send Statement{!eoyTax && `s (${selectedSendable.length})`}
            {eoyTax?.formType === EndOfYearFormType.NONE ? (
              <Text type="error" size="small" style={{ marginLeft: "22px" }}>
                Unable to send due to missing tax form
              </Text>
            ) : (
              eoyTax?.formType &&
              !isProfileComplete(taxMerchantYear, eoyTax.formType) && (
                <Text type="error" size="small" style={{ marginLeft: "22px" }}>
                  Unable to send due to incomplete business tax profile
                </Text>
              )
            )}
          </Menu.Item>
        )}
        {(!eoyTax || eoyTax.status === EoyTaxReportStatus.SENT) && (
          <Menu.SubMenu
            title={
              <>
                <Icon type="paper-plane" theme="solid" color={enableResend ? "blue" : undefined} left />
                Resend Statement{!eoyTax && `s (${selectedResendable.length})`}
              </>
            }
            disabled={!enableResend || isStatementActionLocked(taxYear, StatementActions.SEND)}
          >
            <Menu.Item key="resend">By Recipient{eoyTax ? "'s" : "s'"} Preference</Menu.Item>
            <Menu.Item key={`resend-${TaxDeliveryType.E_DELIVERY}`}>By {translateDeliveryMethod(TaxDeliveryType.E_DELIVERY)}</Menu.Item>
            <Menu.Item key={`resend-${TaxDeliveryType.MAIL}`}>By {translateDeliveryMethod(TaxDeliveryType.MAIL)}</Menu.Item>
          </Menu.SubMenu>
        )}

        {(!eoyTax || eoyTax?.status === EoyTaxReportStatus.SENT) && (
          // Only show void action on each table row when already "Sent"
          <Menu.Item key={EoyTaxReportStatus.VOID} disabled={!enableVoid || isStatementActionLocked(taxYear, StatementActions.VOID)}>
            <Icon type="ban" theme="solid" left />
            Void Statement {!eoyTax && `(${selectedVoidable.length})`}
          </Menu.Item>
        )}
      </Menu>
    );
  }

  function onClickMenuStatus(eoyTax: EoyTax | undefined): MenuProps["onClick"] {
    return async (params) => {
      params.domEvent.stopPropagation();
      const newStatus = params.key as
        | EoyTaxReportStatus.APPROVED
        | EoyTaxReportStatus.HOLD
        | EoyTaxReportStatus.DO_NOT_SEND
        | EoyTaxReportStatus.SENT
        | EoyTaxReportStatus.VOID
        | "resend"
        | "resend-e-delivery"
        | "resend-mail";

      try {
        if (newStatus === EoyTaxReportStatus.SENT) {
          Modal.confirm({
            title: "Send Tax Statements",
            width: 480,
            content: <TaxDeliveryCostWarning showAdditionalCostWarning />,
            okText: "Ok, Send statements",
            onOk: async () => {
              try {
                // prevent send if 1099 or 1042 is not complete
                const statementsToBeSent = eoyTax ? [eoyTax.id] : selectedSendable.map((tx) => tx.id);
                const ids = await sendEoyTaxForms(taxYear, { recipientEoyTaxIds: statementsToBeSent });
                resetEoySummary();
                setSelected([]);
                if (eoyTax) {
                  if (ids.includes(eoyTax.id)) {
                    notifySuccess("Statement sent");
                  } else {
                    notify("Statement cannot be sent", { type: "warning" });
                  }
                } else if (ids.length > 0) {
                  notifySuccess(`${ids.length} statements were sent`);
                } else {
                  notify("Statements cannot be sent", { type: "warning" });
                }
              } catch (errors) {
                if (!!errors?.length) {
                  if (errors.some((e: BaseError) => e.code === "empty_field")) {
                    Modal.confirm({
                      title: "Incomplete business tax profile",
                      content: "You cannot send statements because the business tax profile is not complete. Do you want to complete it?",
                      okText: "Yes, bring me there",
                      onOk: () => {
                        history.push(`${TAX_PATHS.EOY_REPORTING}/${taxYear}/${Pages.PROFILE}`);
                      },
                    });
                  } else {
                    notifyError(errors[0]?.message || "Failed to send form");
                  }
                }
              }
            },
          });
        } else if (newStatus === "resend" || newStatus === "resend-e-delivery" || newStatus === "resend-mail") {
          const taxDeliveryType =
            newStatus === "resend-e-delivery" ? TaxDeliveryType.E_DELIVERY : newStatus === "resend-mail" ? TaxDeliveryType.MAIL : undefined;
          Modal.confirm({
            width: 480,
            title: "Resend Tax Statements",
            content: (
              <TaxDeliveryCostWarning
                showAdditionalCostWarning={["resend", "resend-mail"].includes(newStatus)} // show warning only for Postal Mail
              />
            ),
            okText: "Ok, Resend statements",
            onOk: async () => {
              try {
                // prevent send if 1099 or 1042 is not complete
                const statementsToBeSent = eoyTax ? [eoyTax.id] : selectedResendable.map((tx) => tx.id);
                const ids = await resendEoyTaxForms(taxYear, { recipientEoyTaxIds: statementsToBeSent, taxDeliveryType });
                setSelected([]);
                if (eoyTax) {
                  if (ids.includes(eoyTax.id)) {
                    notifySuccess("Statement resent");
                  } else {
                    notify("Statement cannot be resent", { type: "warning" });
                  }
                } else if (ids.length > 0) {
                  notifySuccess(`${ids.length} statements were resent`);
                } else {
                  notify("Statements cannot be resent", { type: "warning" });
                }
              } catch (errors) {
                if (!!errors?.length) {
                  if (errors.some((e: BaseError) => e.code === "empty_field")) {
                    Modal.confirm({
                      title: "Incomplete business tax profile",
                      content: "You cannot send statements because the business tax profile is not complete. Do you want to complete it?",
                      okText: "Yes, bring me there",
                      onOk: () => {
                        history.push(`${TAX_PATHS.EOY_REPORTING}/${taxYear}/${Pages.PROFILE}`);
                      },
                    });
                  } else {
                    notifyError(errors[0]?.message || "Failed to resent form");
                  }
                }
              }
            },
          });
        } else if (newStatus === EoyTaxReportStatus.VOID) {
          Modal.confirm({
            title: "Void Tax Statements",
            content: (
              <>
                <p>
                  If you have already E-Filed this tax statement to the IRS, then voiding this statement will create a replacement one as an official corrected
                  statement.
                </p>
                <p>If you have not E-Filed it yet to the IRS, then voiding it will create a replacement* that is an original tax statement.</p>

                <Text type="secondary">* Replacement tax statements may take up to 24h to be recreated.</Text>
              </>
            ),
            okText: "Ok, Void statements",
            onOk: async () => {
              try {
                await onChangeStatus(newStatus, eoyTax)();
                setSelected([]);

                notifySuccess("Statements voided");
              } catch {
                notifyError("Voiding statements failed");
              }
            },
          });
        } else {
          await onChangeStatus(newStatus, eoyTax)();
          notifySuccess("Status updated");
          setSelected([]);
        }
      } catch (errors) {
        notifyError("Updating failed", { errors });
      }
    };
  }

  function renderActions(eoyTax: EoyTax) {
    if (eoyTax.status === EoyTaxReportStatus.VOID) {
      return null;
    }

    return (
      <Stop>
        <Space wrap={false} justify="flex-end">
          {isApprovable(eoyTax) && (
            <Tooltip title="Approve Earnings">
              <Button
                type={eoyTax.status === EoyTaxReportStatus.NEEDS_REVIEW ? "primary" : "default"}
                onClick={onChangeStatus(EoyTaxReportStatus.APPROVED, eoyTax)}
                icon={<Icon theme="solid" type="check" style={{ verticalAlign: "middle" }} />}
              />
            </Tooltip>
          )}
          {isDNSendable(eoyTax) && (
            <Button
              onClick={onChangeStatus(EoyTaxReportStatus.DO_NOT_SEND, eoyTax)}
              icon={<Icon type="stop" theme="solid" color="grey" />}
              tooltipProps={{ title: "Do Not Send" }}
            />
          )}
          <Dropdown overlay={getMenu(eoyTax)}>
            <Button icon={<Icon type="ellipsis" />} />
          </Dropdown>
        </Space>
      </Stop>
    );
  }

  const columns: TableColumnsType<EoyTax> = [
    {
      key: ColumnKeys.STATUS,
      title: <LabelTooltip type="eoyTaxFormStatus" />,
      align: "center",
      dataIndex: "status",
      render: (status: EoyTax["status"]) => <Status type={status} style={{ minWidth: "96px" }} />,
    },
    {
      key: ColumnKeys.PROGRESS,
      title: "Progress",
      render: (eoyTax: EoyTax) => <ReviewEarningProgress eoyTax={eoyTax} />,
    },
    {
      key: ColumnKeys.RECIPIENT,
      title: "Recipient",
      dataIndex: "recipientId",
      render: (id: string) => <RecipientProfile size="small" recipientId={id} showAddress showLink showStatus="dot" />,
    },
    {
      key: ColumnKeys.EARNINGS,
      title: "Total Earnings",
      align: "right",
      dataIndex: "reportData.totalEquivUntaxedAmount",
      sorter: true,
      sortOrder: query.orderBy?.[0] === "reportData.totalEquivUntaxedAmount" ? (query.sortBy?.[0] === "asc" ? "ascend" : "descend") : undefined,
      render: (totalEquivUntaxedAmount: string) => <CurrencyDisplay value={totalEquivUntaxedAmount} currency="USD" />,
    },
    {
      key: ColumnKeys.WITHHOLDING,
      title: "Total Withholdings",
      align: "right",
      dataIndex: "reportData.totalEquivWithholdingAmount",
      sorter: true,
      sortOrder: query.orderBy?.[0] === "reportData.totalEquivWithholdingAmount" ? (query.sortBy?.[0] === "asc" ? "ascend" : "descend") : undefined,
      render: (totalEquivWithholdingAmount: string, eoyTax: EoyTax) => (
        <>
          {eoyTax.reportData?.withholdingMismatch && eoyTax.reportData.recipientWithholdingPercentage && (
            <Icon.Status
              type="warning"
              left
              tooltip={
                <>
                  <div>
                    The total withholding amount does not seem to match the total expected withholding amount of{" "}
                    <CurrencyDisplay
                      value={new BigNumber(eoyTax.reportData.totalEquivUntaxedAmount).times(eoyTax.reportData.recipientWithholdingPercentage).dividedBy(100)}
                      currency="USD"
                    />
                    . The withholding rate is {eoyTax.reportData.recipientWithholdingPercentage}%.
                  </div>
                </>
              }
            />
          )}
          <CurrencyDisplay value={totalEquivWithholdingAmount} currency="USD" />
        </>
      ),
    },
    {
      key: ColumnKeys.TAX_PAID_WITHHOLDING_AGENT,
      title: <LabelTooltip type="taxPaidByWithholdingAgents" />,
      dataIndex: "reportData.reportBlocks",
      align: "right",
      render: (reportBlocks: EoyTax["reportData"]["reportBlocks"] = []) => {
        const total = reportBlocks.reduce((acc, item) => {
          return acc.plus(item.taxPaidByWithholdingAgents ?? 0);
        }, new BigNumber("0"));

        return <CurrencyDisplay value={total} currency={CurrencyCode.USD} />;
      },
    },
    {
      key: ColumnKeys.TAX_FORM_TYPE,
      title: "Tax Form Type",
      dataIndex: "lastTaxFormId",
      render(lastTaxFormId: EoyTax["lastTaxFormId"]) {
        return (
          <TaxFormDisplay
            taxFormId={lastTaxFormId}
            showWarnings
            onClick={(e) => {
              e?.preventDefault?.();
              e?.stopPropagation?.();
              setPreviewTaxForm(lastTaxFormId as string);
            }}
          />
        );
      },
    },
    {
      key: ColumnKeys.FORM_TYPE,
      title: "Tax Statement",
      dataIndex: "formType",
      sorter: true,
      sortOrder: query.orderBy?.[0] === "formType" ? (query.sortBy?.[0] === "asc" ? "ascend" : "descend") : undefined,
      render: (formType: EoyTax["formType"], eoyTax: EoyTax) => <EoyTaxFormTypeDisplay eoyTax={eoyTax} />,
    },
    {
      key: ColumnKeys.REQUIRE_TAX_FORM,
      title: (
        <>
          Requires Delivery
          <Icon.Hint
            right
            tooltip={
              <>
                Tax Statements that meet criteria are required to be sent to recipients. Criteria includes:
                <RequiresTaxStatementCriteria />
              </>
            }
          />
        </>
      ),
      dataIndex: "requireTaxForm",
      render: (requireTaxForm: EoyTax["requireTaxForm"]) => (requireTaxForm ? "Required" : "Optional"),
    },
    {
      key: ColumnKeys.DOWNLOADED_BY_RECIPIENT,
      title: (
        <Tooltip title="The recipient has downloaded the tax statement through the Widget/Portal">
          <span>
            Downloaded By Recipient
            <Icon.Hint right />
          </span>
        </Tooltip>
      ),
      dataIndex: "downloadedByRecipient",
      render: (downloadedByRecipient: EoyTax["downloadedByRecipient"]) => (downloadedByRecipient ? "Downloaded" : " - "),
    },
    {
      key: ColumnKeys.EFILED,
      title: (
        <Tooltip title="An E-File containing the tax statement has been submitted to the IRS">
          <span>
            Submitted to the IRS
            <Icon.Hint right />
          </span>
        </Tooltip>
      ),
      dataIndex: "irsSubmittedAt",
      render: (irsSubmittedAt: EoyTax["irsSubmittedAt"]) => (irsSubmittedAt ? "Submitted" : " - "),
    },
    {
      key: ColumnKeys.DELIVERY,
      title: "Delivery Method",
      render: (eoyTax: EoyTax) => (
        <>
          <RecipientDeliveryMethod recipientId={eoyTax.recipientId} />
          {eoyTax.deliveryStatusEmail === EoyTaxReportDeliveryStatusEmail.SEND_REQUEST_FAILED_GENERIC && (
            <Icon.Status type="error" left tooltip="Email Delivery failed" />
          )}
          {eoyTax.deliveryStatusMail === EoyTaxReportDeliveryStatusMail.SEND_REQUEST_FAILED_GENERIC && (
            <Icon.Status type="error" left tooltip="Postal Mail Delivery failed" />
          )}
          {eoyTax.deliveryStatusMail === EoyTaxReportDeliveryStatusMail.SEND_REQUEST_FAILED_BAD_ZIP_CODE && (
            <Icon.Status type="error" left tooltip="Postal Mail Delivery failed due to incorrect ZIP code format" />
          )}
        </>
      ),
    },
    {
      key: ColumnKeys.US_TAX_ENTITY_TYPE,
      title: "US Federal Tax Classification",
      dataIndex: "lastTaxFormId",
      render(lastTaxFormId: EoyTax["lastTaxFormId"]) {
        return <TaxEntityDisplay taxFormId={lastTaxFormId} />;
      },
    },
    {
      key: ColumnKeys.UPDATED_AT,
      title: "Updated On",
      dataIndex: "updatedAt",
      sorter: true,
      sortOrder: query.orderBy?.[0] === "updatedAt" ? (query.sortBy?.[0] === "asc" ? "ascend" : "descend") : undefined,
      render: (date: string) => <DateDisplay value={date} />,
    },
    {
      key: ColumnKeys.ACTIONS,
      title: <LabelTooltip type="eoyFormActions" />,
      align: "right",
      fixed: "right",
      render: renderActions,
    },
  ].filter(tableColumnFilter(displayedColumns)) as TableColumnsType<EoyTax>;

  return (
    <Container padding="none">
      <Helmet>
        <title>Recipient Earnings Review</title>
      </Helmet>
      {ongoingYear && (
        <Alert type="info" header="Offline Payments">
          <OffLinePaymentsInstruction />
        </Alert>
      )}
      <Heading tag="h2">
        <RecordCount prefix="Showing" value={meta.records} one="1 recipient earnings record" other="# recipient earnings records" />
        <Reloader status={eoyTaxFormsStatus} onClick={resetEoyTaxForms} />
      </Heading>
      <div>Review and approve the earnings below before sending tax statements to the recipients.</div>
      <ApprovalInstruction />
      <UnsupportedTaxForms />

      <TableFilters
        showSearch
        fields={FILTER_FIELDS}
        query={query}
        onChange={(changes: EoyTaxQuery) => {
          setQuery(changes, {
            scrollToTop: false,
          });
        }}
        suffix={
          <Space direction="row-reverse">
            <ColumnsDisplay columns={displayedColumns} onChange={setDisplayedColumns} />
            {meta.records > 0 && (
              <FileDownload
                key={history.location.search}
                url="/v1/tax-year/eoy-form/export"
                method="POST"
                query={{ ...query, taxYear, format: "csv" }}
                fileName={`earnings-review-${taxYear}`}
                defaultExtension="csv"
                button
                icon="export"
              >
                Export
              </FileDownload>
            )}
          </Space>
        }
      >
        {selected.length > 0 && (
          <Grid padding="small">
            <Grid.Item>
              {selectAll ? (
                <Button
                  type="primary"
                  onClick={() => {
                    Modal.confirm({
                      title: "Approve all selected earnings?",
                      content: (
                        <>
                          Approving all earnings can only approve records in the following statuses:
                          <p>
                            <Status type={EoyTaxReportStatus.NEEDS_REVIEW} />, <Status type={EoyTaxReportStatus.HOLD} />,{" "}
                            <Status type={EoyTaxReportStatus.DO_NOT_SEND} />
                          </p>
                          <p>Do you want to approve all selected earnings?</p>
                        </>
                      ),
                      okText: "Yes, Approve All",
                      onOk: async () => {
                        try {
                          await bulkUpdate("approve-all", { ...query, taxYear });
                          setSelectAll(false);
                          resetEoySummary();
                          setSelected([]);
                        } catch (errors) {
                          notifyError("Failed to approve all earnings", { errors });
                        }
                      },
                    });
                  }}
                >
                  <RecordCount value={eoyTaxForms.meta.records} other="Approve all # Earnings Records" />
                </Button>
              ) : (
                <ButtonDropdown onClick={onChangeStatus(EoyTaxReportStatus.APPROVED)} overlay={getMenu()} type="primary">
                  <RecordCount value={selectedApprovable.length} other="Approve Earnings (#)" />
                </ButtonDropdown>
              )}
            </Grid.Item>

            {!selectAll && selected.length === eoyTaxForms.records.length && eoyTaxForms.meta.records > selected.length && (
              <Grid.Item>
                <Button
                  type="link"
                  onClick={() => {
                    setSelectAll(true);
                  }}
                >
                  <RecordCount value={eoyTaxForms.meta.records} other="Select all # recipient earnings records" />
                </Button>
              </Grid.Item>
            )}

            {selectAll && (
              <Grid.Item>
                <Button
                  type="link"
                  onClick={() => {
                    setSelected([]);
                    setSelectAll(false);
                  }}
                >
                  Clear Selection
                </Button>
              </Grid.Item>
            )}
          </Grid>
        )}
      </TableFilters>

      <Table<EoyTax>
        status={eoyTaxFormsStatus}
        errors={eoyTaxFormsErrors}
        columns={columns}
        rowClassName={() => styledTableRow}
        dataSource={records}
        rowSelection={
          ongoingYear && accessTaxEOYWrite
            ? {
                fixed: true,
                selectedRowKeys: selected.map((s) => s.id),
                onChange: (selectedRowKeys, selectedRows) => {
                  setSelected(selectedRows);
                },
                onSelect(record, isSelected) {
                  if (!isSelected) {
                    setSelectAll(false);
                  }
                },
                onSelectAll(selected: boolean) {
                  if (!selected) {
                    setSelectAll(false);
                  }
                },
              }
            : undefined
        }
        onRow={(eoyTax) => ({
          onClick() {
            setPreviewEoyTax(eoyTax);
          },
        })}
        pagination={{
          current: query.page,
          pageSize: query.pageSize,
          total: eoyTaxForms.meta.records,
        }}
        onQueryChange={setQuery}
      />
      <Divider margin="small" transparent />

      <EarningPreview
        onClose={() => {
          setPreviewEoyTax(undefined);
        }}
        eoyTaxId={previewEoyTax?.id}
      />
      <TaxDocumentPreview
        taxFormId={previewTaxForm}
        onClose={() => {
          setPreviewTaxForm("");
        }}
      />
    </Container>
  );
}
