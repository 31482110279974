import { Avatar, AvatarProps } from "antd";
import { Icon } from "components";
import React from "react";
import { User } from "store/actions/user";
import { useMerchantSettings } from "store/hooks/merchantSettings";
import { useWhiteLabel } from "store/hooks/whiteLabel";
import { useThemeToken } from "style/classname";

interface Props extends Pick<AvatarProps, "src" | "className" | "style" | "size"> {
  user?: User;
}

export default function AvatarUser(props: Props) {
  const { user, style, ...avatarProps } = props;
  const { data: whiteLabel } = useWhiteLabel();
  const theme = useThemeToken();
  const { data: merchantSettings } = useMerchantSettings();
  const initials = user && merchantSettings?.name?.charAt?.(0).toLocaleUpperCase();
  const icon = initials ? <span>{initials}</span> : <Icon type="user-tie" theme="solid" />;

  return (
    <Avatar
      src={whiteLabel?.icon || user?.gravatarUrl}
      shape="square"
      icon={icon}
      style={{ display: "block", boxShadow: theme.boxShadowTertiary, ...style }}
      {...avatarProps}
    />
  );
}
