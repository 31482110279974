import { Alert, Button, Checkbox, Divider, Form, FormInstance, Input } from "components";
import React, { forwardRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { RecipientAccount, RecipientAccountUpdate } from "store/actions/recipientAccount";
import { usePayoutMethods } from "store/hooks/payoutMethods";
import { handleFormErrors } from "utils/helpers";
import { PayoutMethodType } from "@trolley/common-frontend";

interface Props {
  recipientAccount?: RecipientAccount;
  onSubmit(changes: RecipientAccountUpdate): void;
}

interface FormFields {
  wallet: string;
  primary?: boolean;
}

export default forwardRef<FormInstance, Props>(({ recipientAccount, onSubmit }, ref) => {
  const { data: payoutMethods } = usePayoutMethods();
  const [form] = Form.useForm<FormFields>();
  const bitcoinPayout = payoutMethods.find((pm) => pm.integration === PayoutMethodType.BITCOIN);

  useEffect(() => {
    if (typeof ref === "function") {
      ref(form);
    }
  }, [form]);

  async function onFinish(formValues: FormFields) {
    try {
      await onSubmit({ ...formValues, type: PayoutMethodType.BITCOIN });
    } catch (errors) {
      handleFormErrors(errors, form);
    }
  }

  return (
    <Form form={form} initialValues={recipientAccount} onFinish={onFinish}>
      {!bitcoinPayout?.enabled && (
        <Alert type="warning">
          Bitcoin is disabled. Go to <Link to="/settings/payout-methods/bitcoin">Bitcoin Settings</Link> to enable it.
        </Alert>
      )}

      <Form.Item label="Bitcoin Wallet Address" name="wallet" rules={[{ required: true, message: "wallet address is required" }]}>
        <Input name="wallet" />
      </Form.Item>

      {!recipientAccount && (
        <>
          <Divider margin="small" transparent />
          <Form.Item name="primary" valuePropName="checked" noStyle>
            <Checkbox>Set Account as Active</Checkbox>
          </Form.Item>
        </>
      )}
      <Button hidden htmlType="submit" />
    </Form>
  );
});
