import React from "react";
import Icon, { IconProps } from ".";

export interface IconHintProps extends Pick<IconProps, "tooltip" | "left" | "right" | "tooltipProps" | "className" | "style"> {
  color?: "inherit";
}

export default function IconHint({ color, style, ...props }: IconHintProps) {
  return (
    <Icon
      theme="solid"
      type="circle-question"
      color={color === "inherit" ? undefined : "grey"}
      style={{ cursor: "help", display: "initial", ...style }}
      {...props}
    />
  );
}
