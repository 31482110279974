import { UploadFile } from "antd/lib/upload/interface";
import { Container, Modal, UploadDragger } from "components";
import React, { ReactNode, useEffect, useState } from "react";
import { createFormData } from "utils/helpers";
import { UploadProps } from "./UploadDragger";

export { UploadFile };
export const MAX_FILE_SIZE = 10;
const MAX_COUNT_FILES = 5;

interface Props extends UploadProps {
  title?: string;
  visible?: boolean;
  maxSize?: number; // in MB
  onSubmit(files: FormData): Promise<void>;
  onClose(): void;
  addOnBefore?: ReactNode;
  mimeTypes?: string[];
  children?: ReactNode;
  onSuccess?: () => void;
}

export default function UploadWindow(props: Props) {
  const { title, className, addOnBefore, children, visible, loading, mimeTypes, maxSize, multiple, maxCount, onClose, onSubmit, onSuccess } = props;
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [busy, setBusy] = useState(false);

  useEffect(() => {
    if (visible) {
      setFileList([]);
    }
  }, [visible]);

  async function onOk() {
    setBusy(true);
    setFileList((state) => {
      const newFileList = state.map((x: UploadFile) => {
        if (!x.status) {
          x.status = "uploading";
        }

        return x;
      }) as UploadFile[];
      Promise.allSettled(newFileList.filter((file) => file.status === "uploading").map((file) => onSubmit(createFormData([file]))))
        .then((results) => {
          if (results.some((r) => r.status === "rejected")) {
            setFileList((state) => {
              let resultIndex = 0;

              return state.map((file) => {
                if (file.status !== "uploading" || resultIndex >= results.length) return file;
                const result = results[resultIndex];
                resultIndex += 1;

                file.status = result.status === "rejected" ? "error" : "success";
                file.error = result.status === "rejected" ? result.reason ?? "" : "";

                return file;
              });
            });
          } else if (!!onSuccess) {
            onSuccess();
          }
        })
        .catch(() => {})
        .finally(() => {
          setBusy(false);
        });

      return newFileList;
    });
  }

  return (
    <Modal
      title={title}
      visible={visible}
      onCancel={onClose}
      okText="Upload"
      onOk={onOk}
      confirmLoading={loading}
      okButtonProps={{
        disabled: busy || !fileList.length || fileList.some((file) => !!file.error) || !fileList.some((file) => !file.status),
      }}
    >
      {addOnBefore}
      <UploadDragger
        mimeTypes={mimeTypes}
        onRemove={(file) => {
          setFileList((fileList) => fileList.filter((f) => f !== file));
        }}
        multiple={!!multiple}
        maxCount={!multiple ? 1 : maxCount ?? MAX_COUNT_FILES}
        loading={loading}
        beforeUpload={(file: UploadFile) => {
          let error = "";
          if (maxSize !== undefined && file.size && file.size / 1024 / 1024 > maxSize) {
            error = `Upload file size must be smaller than ${maxSize} MB.`;
          } else if (mimeTypes && file.type && !mimeTypes.includes(file.type)) {
            error = "Unsupported file format.";
          }
          file.error = error;
          setFileList((fileList) => (!!multiple ? [...fileList, file] : [file]));

          return false;
        }}
        fileList={fileList}
      />
      <Container padding="small" className={className}>
        {children}
      </Container>
    </Modal>
  );
}
