import { useEffect, useState } from "react";
import { loadOfflinePayments, OfflinePaymentsQuery } from "store/actions/offlinePayments";
import { PaymentTax } from "store/actions/paymentTaxes";
import { emptyList } from "store/reducers/standardReducer";
import { useShallowSelector } from ".";

export function useOfflinePayments(query: OfflinePaymentsQuery) {
  const [fetchId, setFetchId] = useState("");

  useEffect(() => {
    setFetchId(loadOfflinePayments(query));
  }, [query]);

  return useShallowSelector((state) => {
    if (fetchId && state.offlinePaymentList.fetchStatus[fetchId] === undefined) {
      loadOfflinePayments(query);
    }

    const data = state.offlinePaymentList.entities[fetchId];

    return {
      data: data
        ? {
            records: data.records.map((id) => state.paymentTaxes.entities[id]).filter((v) => v) as PaymentTax[],
            meta: data.meta,
          }
        : emptyList,
      status: state.offlinePaymentList.fetchStatus[fetchId],
      error: state.offlinePaymentList.errors[fetchId],
    };
  });
}
