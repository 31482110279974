import { PaymentCategory, PaymentTaxesPayoutMethods, PayoutMethodType, TaxFormTinStatus, TaxStatus } from "@trolley/common-frontend";
import { Checkbox, DateDisplay, DatePicker, Form, Input, Modal, Select, Text, getLabelTooltip } from "components";
import { PayoutMethodDisplay } from "features/payoutMethod";
import { TaxFormDisplay } from "features/taxForm";
import React, { useEffect } from "react";
import { PaymentTaxUpdate } from "store/actions/paymentTaxes";
import { useMerchantSettings } from "store/hooks/merchantSettings";
import { useRecipientTaxForms } from "store/hooks/recipientTaxForms";
import { CATEGORY_DETAILS, FORCE_US_TAX_CATEGORIES, NON_TAXABLE_CATEGORIES } from "utils/constants";
import { getPaymentCategoryLabel, handleFormErrors, omitUndefined } from "utils/helpers";

interface FormFields extends PaymentTaxUpdate {
  clear?: {
    memo?: boolean;
    taxFormId?: boolean;
  };
}
interface Props {
  visible: boolean;
  recipientId?: string; // filter on recipient Id;
  onUpdate(update?: PaymentTaxUpdate): Promise<void>;
}

export default function PaymentTaxesBulkEdit(props: Props) {
  const { visible, recipientId, onUpdate } = props;
  const [form] = Form.useForm<FormFields>();
  const { data: merchantSettings } = useMerchantSettings();
  const { data: recipientTaxForms } = useRecipientTaxForms(visible ? recipientId : undefined, {
    pageSize: 1000,
    status: [TaxStatus.REVIEWED, TaxStatus.SUBMITTED, TaxStatus.EXPIRED, TaxStatus.VOIDED],
  });
  const enabledCategories = Object.entries(merchantSettings?.payment?.categories || {})
    .filter(([cat, enabled]) => enabled)
    .map(([cat]) => cat as PaymentCategory);
  const taxForms = recipientTaxForms.records.filter((tf) => tf.tinStatus !== TaxFormTinStatus.NOT_VALID && !!tf.signedAt);

  useEffect(() => {
    if (visible) {
      form.resetFields();
    }
  }, [visible]);

  async function onSubmit({ clear, ...values }: FormFields) {
    const filteredValues = omitUndefined(values);
    if (Object.keys(filteredValues).length) {
      Modal.confirm({
        title: "Confirm Changes?",
        content: (
          <>
            <p>The following changes will be applied to all selected records:</p>
            <Form layout="horizontal" compact>
              {Object.entries(filteredValues).map(([key, value]: [string, string]) => {
                if (key === "payoutMethod") {
                  return (
                    <Form.Item key={key} label="Payout Method">
                      <PayoutMethodDisplay value={value as PayoutMethodType} showLabel />
                    </Form.Item>
                  );
                }

                if (key === "category") {
                  return (
                    <Form.Item label="Payment Purpose" key={key}>
                      {getPaymentCategoryLabel(value as PaymentCategory)}
                    </Form.Item>
                  );
                }

                if (key === "taxReportable") {
                  return (
                    <Form.Item label="Tax Reportable" key={key}>
                      {value ? "Yes" : "No"}
                    </Form.Item>
                  );
                }
                if (key === "forceUsTaxActivity") {
                  return (
                    <Form.Item label="Override US Activities" key={key}>
                      {value ? "Yes" : "No"}
                    </Form.Item>
                  );
                }

                if (key === "memo") {
                  return (
                    <Form.Item label="Memo" key={key}>
                      {value || "[clear all memos]"}
                    </Form.Item>
                  );
                }

                if (key === "processedAt") {
                  return (
                    <Form.Item key={key} label="Processed On">
                      <DateDisplay value={value} time={false} icon={false} preserveFormat />
                    </Form.Item>
                  );
                }

                if (key === "taxFormId") {
                  return (
                    <Form.Item label="Tax Form" key={key}>
                      {value ? <TaxFormDisplay taxFormId={value} showSignedDate showStatus showIcon /> : "[clear all tax forms]"}
                    </Form.Item>
                  );
                }

                return (
                  <Form.Item key={key}>
                    {key}: {value}
                  </Form.Item>
                );
              })}
            </Form>
          </>
        ),
        okText: "Yes, Confirm Changes",
        onOk: async () => {
          try {
            await onUpdate(filteredValues);
          } catch (errors) {
            handleFormErrors(errors, form);
          }
        },
      });
    } else {
      await onUpdate();
    }
  }

  return (
    <Modal
      visible={visible}
      title="Update Payment Tax Records"
      onCancel={async () => {
        await onUpdate();
      }}
      onOk={form.submit}
      okText="Apply Changes"
    >
      <Form form={form} onFinish={onSubmit}>
        <Form.Item label="Payout Method" name="payoutMethod">
          <Select allowClear>
            {Object.values(PaymentTaxesPayoutMethods).map((pm) => (
              <Select.Option key={pm} value={pm}>
                <PayoutMethodDisplay value={pm} showLabel />
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item {...getLabelTooltip("category")} name="category">
          <Select<PaymentCategory>
            allowClear
            onChange={(cat) => {
              if (cat && NON_TAXABLE_CATEGORIES.includes(cat)) {
                form.setFieldsValue({
                  taxReportable: undefined,
                });
              }

              if (cat && !FORCE_US_TAX_CATEGORIES.includes(cat)) {
                form.setFieldsValue({
                  forceUsTaxActivity: undefined,
                });
              }
            }}
          >
            {Object.values(PaymentCategory)
              .map(
                (category) =>
                  CATEGORY_DETAILS[category] && (
                    <Select.Option key={category} value={category}>
                      {CATEGORY_DETAILS[category].name}
                    </Select.Option>
                  ),
              )
              .filter((v) => v)}
          </Select>
        </Form.Item>

        <Form.Control dependencies={["category"]}>
          {({ getFieldValue }) => {
            const category = getFieldValue("category");
            const showForceUsTaxActivities = enabledCategories.some((cat) => FORCE_US_TAX_CATEGORIES.includes(cat));
            const disabledTaxReportable = category && NON_TAXABLE_CATEGORIES.includes(category);
            const disabledForceUsTax = category && !FORCE_US_TAX_CATEGORIES.includes(category);

            return (
              <>
                <Form.Item
                  {...getLabelTooltip("taxReportable")}
                  name="taxReportable"
                  extra={disabledTaxReportable && <Text size="small">{CATEGORY_DETAILS[category]?.name} are non-taxable</Text>}
                >
                  <Select allowClear disabled={disabledTaxReportable}>
                    <Select.Option value>Taxable</Select.Option>
                    <Select.Option value={false}>Exempt</Select.Option>
                  </Select>
                </Form.Item>
                {showForceUsTaxActivities && (
                  <Form.Item
                    {...getLabelTooltip("forceUsTaxActivity")}
                    name="forceUsTaxActivity"
                    extra={disabledForceUsTax && <Text size="small">Does not apply to {CATEGORY_DETAILS[category]?.name}</Text>}
                  >
                    <Select allowClear disabled={disabledForceUsTax}>
                      <Select.Option value>US Activities</Select.Option>
                      <Select.Option value={false}>Off</Select.Option>
                    </Select>
                  </Form.Item>
                )}
              </>
            );
          }}
        </Form.Control>

        <Form.Item name="processedAt" label="Processed On" normalize={(v) => v || undefined}>
          <DatePicker allowClear type="past" />
        </Form.Item>

        {!!recipientId && (
          <Form.Control dependencies={[["clear", "taxFormId"]]}>
            {({ getFieldValue }) => {
              const clearTaxId = getFieldValue(["clear", "taxFormId"]);

              return (
                <Form.Item
                  label="Tax Form"
                  name="taxFormId"
                  dependencies={[["clear", "taxFormId"]]}
                  extra={
                    <Form.Item noStyle name={["clear", "taxFormId"]} valuePropName="checked">
                      <Checkbox
                        onChange={(e) => {
                          form.setFieldsValue({ taxFormId: e.target.checked ? null : undefined }); // undefined is be filtered out
                        }}
                      >
                        Clear All Tax Forms
                      </Checkbox>
                    </Form.Item>
                  }
                >
                  <Select disabled={clearTaxId} allowClear>
                    {taxForms.map((tf) => (
                      <Select.Option key={tf.id} value={tf.id}>
                        <div>
                          <TaxFormDisplay taxFormId={tf.id} showIcon showStatus />
                          <br />
                          <Text type="secondary" size="small">
                            Signed on <DateDisplay value={tf.signedAt} icon={false} time={false} />
                            {tf.status === TaxStatus.VOIDED && (
                              <>
                                , Voided on <DateDisplay value={tf.signedAt} icon={false} time={false} />
                              </>
                            )}
                          </Text>
                        </div>
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              );
            }}
          </Form.Control>
        )}

        <Form.Control dependencies={[["clear", "memo"]]}>
          {({ getFieldValue }) => {
            const clearMemo = getFieldValue(["clear", "memo"]);

            return (
              <Form.Item
                {...getLabelTooltip("paymentMemo")}
                name="memo"
                dependencies={[["clear", "memo"]]}
                normalize={(v) => v || undefined}
                extra={
                  <Form.Item noStyle name={["clear", "memo"]} valuePropName="checked">
                    <Checkbox
                      onChange={(e) => {
                        form.setFieldsValue({ memo: e.target.checked ? "" : undefined }); // undefined is be filtered out
                      }}
                    >
                      Clear All Memos
                    </Checkbox>
                  </Form.Item>
                }
              >
                <Input disabled={clearMemo} maxLength={1024} />
              </Form.Item>
            );
          }}
        </Form.Control>
      </Form>
    </Modal>
  );
}
