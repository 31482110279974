import { BaseError } from "store/reducers/standardReducer";
import { Response } from ".";
// Parse the standard error structure from the Trolley API
//  @res == the XHR http response structure
export default function handleErrors(res: Response<{ errors: BaseError[] }>) {
  if (Array.isArray(res?.body?.errors)) {
    return res.body.errors;
  }

  return new Error("Server Internal Error");
}
