import { useEffect } from "react";
import { useShallowSelector } from ".";
import { loadRoyaltyCalculatorIntegration } from "store/actions/royaltyIntegrations";

export function useRoyaltyCalculatorsIntegrations() {
  useEffect(() => {
    loadRoyaltyCalculatorIntegration();
  }, []);

  return useShallowSelector((state) => {
    if (state.royaltyIntegrations.fetchStatus.data === undefined) {
      loadRoyaltyCalculatorIntegration();
    }

    return {
      data: state.royaltyIntegrations.entities.data || [],
      status: state.royaltyIntegrations.fetchStatus.data,
      error: state.royaltyIntegrations.errors.data,
    };
  });
}
