import { Divider, Icon } from "components";
import Text, { TextProps } from "components/Typography/Text";
import React, { FunctionComponent } from "react";
import { BaseError } from "store/reducers/standardReducer";
import { classnames, createUseStyle, css } from "style/classname";
import palette from "style/palette";

const useStyledError = createUseStyle(({ theme }) =>
  css`
    margin-bottom: 16px;
    border-radius: ${theme.borderRadius}px;
    padding: 24px 16px;
    background-color: ${palette["red-alpha-2"]};
  `(),
);

interface Props {
  wrapped?: boolean;
  title?: string;
  className?: string;
  align?: TextProps["align"];
  errors: BaseError[] | undefined;
}

const Error: FunctionComponent<Props> = (props) => {
  const { title = "Error", errors, className, children, wrapped = true, align = "center" } = props;
  const styledError = useStyledError();
  function renderErrors() {
    try {
      if (!!errors?.length) {
        return errors.map((error) => (
          <Text key={error.code}>
            {error.message || error.code}
            {error.field && ` (${error.field} field error)`}
          </Text>
        ));
      }

      return null;
    } catch {
      return "Try it again in a minute and let us know if you still can't get what your looking for.";
    }
  }

  if (!errors || !Array.isArray(errors) || !errors.length || !errors.some((e) => e.message || e.code)) {
    return null;
  }

  const description = (
    <Text align={align}>
      {renderErrors()}
      {errors && children && <Divider transparent margin="small" />}
      {children}
    </Text>
  );

  return wrapped ? (
    <Text className={classnames(styledError, className)} align={align}>
      <Icon.Status type="error" size="3x" />
      <Divider transparent margin="small" />
      <Text size="large">{title}</Text>
      {description}
    </Text>
  ) : (
    description
  );
};

export default Error;
