import store from "store";
import { OpCode, standardDispatch } from "store/dispatcher";
import { POST } from "services/request";
import { Access, Account, AccountResult, CurrencyCode, SerializerMetaBlock, TransferFunds } from "@trolley/common-frontend";
import { isLoaded } from "./actionUtils";

export type Transfer = TransferFunds.Transfer;
export { Account } from "@trolley/common-frontend";

export function loadBalances(force?: boolean) {
  const { balances } = store.getState();

  if (force || !isLoaded(balances.fetchStatus.data)) {
    standardDispatch(OpCode.LOADING, "balances");

    POST<{ accounts: Account[]; meta: SerializerMetaBlock }>(`/v1/accounts/search`, { query: { refresh: !!force, pageSize: 1000 } })
      .then(({ body }) => {
        standardDispatch(OpCode.DATA, "balances", {
          bulk: body.accounts.reduce((acc, account) => {
            acc[account.id] = account;

            return acc;
          }, {}),
        });
      })
      .catch((errors) => {
        standardDispatch(OpCode.ERROR, "balances", { errors });
      });
  }
}

interface AccountUpdate {
  visible?: boolean;
}

export async function updateBalance(balanceId: string, update: AccountUpdate) {
  const { user } = store.getState();
  if (user?.entities.data?.access.includes(Access.ACCOUNT_FUNDING)) {
    try {
      standardDispatch(OpCode.LOADING, "balances");
      const { body } = await POST<AccountResult>("/v1/accounts/update", {
        query: {
          ...update,
          id: balanceId,
        },
      });

      standardDispatch<Account>(OpCode.UPDATE, "balances", {
        id: balanceId,
        update: body.account,
      });
    } catch (errors) {
      standardDispatch(OpCode.ERROR, "balances", { errors });
    }
  }
}

export interface QuoteQuery {
  debit: {
    value: string;
    currency: CurrencyCode;
  };
  debitId: string;
  credit: {
    value: string;
    currency: CurrencyCode;
  };
  creditId: string;
}

export interface TransferQuery extends QuoteQuery {
  quote: string;
}

export async function transferQuote(query: QuoteQuery) {
  standardDispatch(OpCode.LOADING, "balances");

  try {
    const res = await POST<TransferFunds.ResultQuote>("/v1/accounts/transfer/quote", { query });
    standardDispatch(OpCode.LOADING, "balances", { loading: false });

    return res.body.transfer;
  } catch (errors) {
    standardDispatch(OpCode.ERROR, "balances", { errors });
    throw errors;
  }
}

export async function transferFunds(query: TransferQuery) {
  standardDispatch(OpCode.LOADING, "balances");
  try {
    await POST<TransferFunds.Result>("/v1/accounts/transfer/create", { query });
    standardDispatch(OpCode.LOADING, "balances", { loading: false });
    standardDispatch(OpCode.RESET, "ledgerItems");
  } catch (errors) {
    standardDispatch(OpCode.ERROR, "balances", { errors });
    throw errors;
  }
}
