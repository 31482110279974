import { uniqueList } from "@trolley/common-frontend";
import qs from "qs";
import { Query } from "utils/hooks";

export default function parseQuery(
  queryStr: string,
  options?: {
    arrayKeys?: string[]; // query fields that are suppose to be an array
    numberKeys?: string[]; // query fields that are suppose to be numbers
  },
) {
  const query = qs.parse(queryStr, {
    comma: true,
    ignoreQueryPrefix: true,
  }) as Query;
  const arrayKeys = uniqueList(["sortBy", "orderBy", "status", ...(options?.arrayKeys || [])]);
  const numberKeys = uniqueList(["page", "pageSize", ...(options?.numberKeys || [])]);

  return Object.fromEntries(
    Object.entries(query).map(([k, v]) => {
      if (v === "") {
        return [k, undefined]; // critical for overwriting DEFAULT_QUERY
      } else if (arrayKeys.includes(k) && !Array.isArray(v)) {
        return [k, [String(v)]];
      } else if (["true", "false"].includes(String(v))) {
        return [k, v === "true"];
      } else if (numberKeys.includes(k)) {
        return [k, Number(v)];
      }

      return [k, v];
    }),
  );
}
