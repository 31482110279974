import { CountryCode, WORLD_REGIONS, WorldRegions, addRegionCountriesTo, formatCountry, removeRegionCountriesFrom } from "@trolley/common-frontend";
import { Collapse } from "antd";
import { Divider, Flag, FormItem, Grid, Icon, Input, Switch, Tooltip } from "components";
import React, { Fragment, KeyboardEvent, useMemo, useState } from "react";
import { notifyError, notifySuccess } from "store/actions/notifications";
import { escapeRegExp } from "utils/helpers";
import Form from "./Form";

export const BANNED_COUNTRIES_LABEL = "Banned Countries";

const COUNTRY_NAMES = Object.values(CountryCode).reduce((map: Record<string, string>, countryCode) => {
  map[countryCode] = formatCountry(countryCode);

  return map;
}, {});

interface EnabledCountriesProps {
  loading: boolean;
  disabled?: boolean;
  enabledCountries: CountryCode[];
  restrictedCountries?: CountryCode[];
  restrictedTooltip?(countryName: string, countryCode: CountryCode): string;
  bannedCountries: CountryCode[];
  bannedTooltip?(countryName: string, countryCode: CountryCode): string;
  onSubmit(countries: CountryCode[]): Promise<void>;
}

export default function EnabledCountries(props: EnabledCountriesProps) {
  const { loading, disabled, enabledCountries, restrictedCountries, restrictedTooltip, bannedCountries, bannedTooltip, onSubmit } = props;
  const [searchCountry, setSearchCountry] = useState("");
  const allowedCountries = useMemo(
    () =>
      Object.values(CountryCode).filter((c) => {
        return !bannedCountries.includes(c);
      }),
    [bannedCountries],
  );

  function onToggleCountry(countryCode: CountryCode) {
    return async (checked: boolean) => {
      try {
        if (checked) {
          const newEnabledCountries = Array.from(new Set([...enabledCountries, countryCode]));

          await onSubmit(newEnabledCountries.length >= allowedCountries.length ? [] : newEnabledCountries);
          notifySuccess("Saved");
        } else {
          const newEnabledCountries = (enabledCountries.length ? enabledCountries : allowedCountries).filter((c) => c !== countryCode);

          if (newEnabledCountries.length) {
            await onSubmit(newEnabledCountries);
            notifySuccess("Saved");
          } else {
            notifyError("At least 1 country must be enabled");
          }
        }
      } catch (errors) {
        notifyError(`Failed to ${checked ? "enable" : "disable"} country`, { errors });
      }
    };
  }

  function onToggleRegion(regionCode: WorldRegions) {
    return async (checked: boolean, e: any) => {
      e?.stopPropagation?.();

      try {
        if (checked) {
          const newEnabledCountries = addRegionCountriesTo(regionCode, enabledCountries).filter((c) => !bannedCountries.includes(c));

          await onSubmit(newEnabledCountries.length >= allowedCountries.length ? [] : newEnabledCountries);
          notifySuccess("Saved");
        } else {
          const newEnabledCountries = removeRegionCountriesFrom(regionCode, !enabledCountries.length ? allowedCountries : enabledCountries);

          if (newEnabledCountries.length) {
            await onSubmit(newEnabledCountries);
            notifySuccess("Saved");
          } else {
            notifyError("At least 1 country must be enabled");
          }
        }
      } catch (errors) {
        notifyError(`Failed to ${checked ? "enable" : "disable"} region`, { errors });
      }
    };
  }

  function renderRow(countryCode: CountryCode) {
    const isRestricted = restrictedCountries?.includes(countryCode);
    const checked = !enabledCountries.length || enabledCountries.includes(countryCode);

    return (
      <Grid.Item key={countryCode} xs={24} lg={12} xxl={8}>
        <FormItem
          label={
            <>
              <Flag code={countryCode} suffix={`(${countryCode})`} />
              {isRestricted && checked && <Icon.Status type="warning" right tooltip={restrictedTooltip?.(COUNTRY_NAMES[countryCode], countryCode)} />}
            </>
          }
        >
          <Switch
            data-testid={countryCode}
            loading={loading}
            disabled={disabled}
            checked={checked}
            checkedChildren="ENABLED"
            unCheckedChildren="DISABLED"
            onChange={onToggleCountry(countryCode)}
          />
        </FormItem>
      </Grid.Item>
    );
  }

  function filterCountry(countryCode: CountryCode) {
    const pattern = new RegExp(escapeRegExp(searchCountry), "ig");

    return !searchCountry || pattern.test(countryCode) || pattern.test(COUNTRY_NAMES[countryCode]);
  }

  function onKeyDown(e: KeyboardEvent<HTMLInputElement>) {
    if (e?.key) {
      switch (e.key) {
        case "Escape":
          setSearchCountry("");
          break;
      }
    }
  }

  return (
    <Form layout="horizontal" compact labelCol={{ flex: "150px" }} labelAlign="left">
      <Input
        value={searchCountry}
        size="large"
        prefix={<Icon type="search" color="grey" />}
        placeholder="Find country"
        onChange={(e) => {
          setSearchCountry(e.target.value?.replace?.(/[^\w\s]/gi, ""));
        }}
        onKeyDown={onKeyDown}
        allowClear
      />
      <Divider transparent margin="medium" />
      {!searchCountry &&
        Object.values(WorldRegions).map((regionCode) => {
          const worldRegion = WORLD_REGIONS[regionCode];
          const worldRegionCountries = worldRegion.countries.filter((c) => !bannedCountries.includes(c));
          const enabledCountriesInRegion = worldRegionCountries.filter((c) => enabledCountries.includes(c)).length;

          return (
            <Fragment key={regionCode}>
              <Collapse size="large">
                <Collapse.Panel
                  key={regionCode}
                  header={worldRegion.label}
                  extra={
                    <Switch
                      data-region={regionCode}
                      loading={loading}
                      disabled={disabled}
                      checked={!enabledCountries.length || !!worldRegionCountries.every((c) => enabledCountries.includes(c))}
                      checkedChildren="ENABLED"
                      unCheckedChildren={enabledCountriesInRegion ? `${enabledCountriesInRegion} / ${worldRegionCountries.length} ENABLED` : "DISABLED"}
                      onChange={onToggleRegion(regionCode)}
                    />
                  }
                >
                  <Grid>{worldRegionCountries.map(renderRow)}</Grid>
                </Collapse.Panel>
              </Collapse>

              <Divider transparent margin="small" />
            </Fragment>
          );
        })}

      <Collapse size="large">
        <Collapse.Panel key="banned" header={BANNED_COUNTRIES_LABEL}>
          <Grid>
            {bannedCountries.map((bannedCountryCode) => (
              <Grid.Item key={bannedCountryCode} xs={24} lg={12} xxl={8}>
                <FormItem label={<Flag code={bannedCountryCode} suffix={`(${bannedCountryCode})`} />}>
                  <Tooltip
                    title={bannedTooltip?.(COUNTRY_NAMES[bannedCountryCode], bannedCountryCode) || `${COUNTRY_NAMES[bannedCountryCode]} cannot be enabled`}
                  >
                    <Switch checked={false} disabled unCheckedChildren="DISABLED" />
                  </Tooltip>
                </FormItem>
              </Grid.Item>
            ))}
          </Grid>
        </Collapse.Panel>
      </Collapse>

      {searchCountry && <Grid>{allowedCountries.filter(filterCountry).map(renderRow)}</Grid>}
    </Form>
  );
}
