import { VerificationStatus } from "@trolley/common-frontend";
import { Box, CopyToClipboard, DateDisplay, Divider, Status, Flyout, Form, FormItem, Flag } from "components";
import { RecipientPreview, RecipientProfile } from "features/recipient";
import React, { useState } from "react";
import { IndividualVerification } from "store/actions/verifications";
import { IndividualRejectedReasons } from "./IdVerificationList";
import { RejectedReasonLabels } from ".";

interface Props {
  IdVerification?: IndividualVerification;
  onClose(): void;
}

const getDocumentType = (documentType: string | null) => {
  switch (documentType) {
    case "PASSPORT":
      return "Passport";
    case "ID_CARD":
      return "ID Card";
    case "DRIVERS_LICENSE":
      return "Drivers License";
    case "RESIDENCE_PERMIT":
      return "Residence Permit";
    case "VISA":
      return "Visa";
    case "OTHER":
      return "Other";
    default:
      return "-";
  }
};

export default function IdVerificationPreview(props: Props) {
  const { IdVerification, onClose } = props;
  const [recipientId, setRecipientId] = useState<string | undefined>();

  return (
    <Flyout
      visible={!!IdVerification}
      onClose={onClose}
      title={
        IdVerification && (
          <>
            Individual Verification <Status type={IdVerification.status} size="small" right />
          </>
        )
      }
      subtitle={
        IdVerification && (
          <>
            <Divider margin="medium" />
            <RecipientProfile
              recipientId={IdVerification.recipientId}
              showLink={() => {
                setRecipientId(IdVerification.recipientId);
              }}
              size="large"
              showEmail
              showAddress
            />
          </>
        )
      }
    >
      {IdVerification && (
        <Form layout="horizontal" compact>
          <Box padding="large" header="Verification Details">
            <FormItem label="Verification ID">
              <CopyToClipboard value={IdVerification.id} />
            </FormItem>
            {IdVerification.status === VerificationStatus.REJECTED &&
              ((IdVerification.reasonType && IndividualRejectedReasons[IdVerification.reasonType]) || IdVerification.verifiedData.reason) && (
                <FormItem label="Rejected Reason" data-test="void-reason">
                  {IdVerification.reasonType && IndividualRejectedReasons[IdVerification.reasonType]
                    ? `${RejectedReasonLabels[IdVerification.reasonType]} - ${IndividualRejectedReasons[IdVerification.reasonType]}`
                    : IdVerification.verifiedData.reason ?? IdVerification.reasonType}
                </FormItem>
              )}
            <FormItem label="Name">
              {IdVerification.verifiedData.firstName || IdVerification.verifiedData.lastName
                ? [IdVerification.verifiedData.firstName, IdVerification.verifiedData.lastName].join(" ")
                : "-"}
            </FormItem>
            <FormItem label="Document Type">{getDocumentType(IdVerification.verifiedData.documentType)}</FormItem>
            <FormItem label="Document Issuance Date">
              <DateDisplay value={IdVerification.verifiedData.documentValidFrom} icon={false} />
            </FormItem>
            <FormItem label="Document Expiration Date">
              <DateDisplay value={IdVerification.verifiedData.documentValidUntil} icon={false} />
            </FormItem>
            <FormItem label="Document Issuing Country">
              {IdVerification.verifiedData.documentIssuingCountry ? <Flag code={IdVerification.verifiedData.documentIssuingCountry} /> : "-"}
            </FormItem>
            <FormItem label="Submitted At">
              <DateDisplay value={IdVerification.submittedAt} icon={false} />
            </FormItem>
            <FormItem label="Decision On">
              <DateDisplay value={IdVerification.decisionAt} icon={false} />
            </FormItem>
          </Box>
          <Box padding="large" header="Verified Address">
            <Form.Item label="Street1">{IdVerification.verifiedData.address.street1 || "-"}</Form.Item>
            <Form.Item label="Street2">{IdVerification.verifiedData.address.street2 || "-"}</Form.Item>
            <Form.Item label="City">{IdVerification.verifiedData.address.city || "-"}</Form.Item>
            <Form.Item label="Region">{IdVerification.verifiedData.address.region || "-"}</Form.Item>
            <Form.Item label="Country">{IdVerification.verifiedData.address.country || "-"}</Form.Item>
            <Form.Item label="Postal Code">{IdVerification.verifiedData.address.postalCode || "-"}</Form.Item>
          </Box>
        </Form>
      )}
      <RecipientPreview
        recipientId={recipientId}
        onClose={() => {
          setRecipientId(undefined);
        }}
      />
    </Flyout>
  );
}
