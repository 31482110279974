import { UploadFile } from "antd/lib/upload/interface";

export default function createFormData(files: UploadFile[], data?: Record<string, string | Blob>): FormData {
  const formData = new FormData();
  if (Array.isArray(files) && files.length > 0) {
    files.forEach((file: any, index: number) => {
      formData.append(String(index), file);
    });
  }

  if (data) {
    Object.entries(data).forEach(([key, value]) => {
      formData.set(key, value);
    });
  }

  return formData;
}
