import { CountryCode, formatCountry, getCountryGovIdTypes, RecipientType } from "@trolley/common-frontend";
import { Cascader as AntCascader } from "antd";
import { FilledFieldNamesType } from "antd/lib/cascader";
import DisabledContext from "antd/lib/config-provider/DisabledContext";
import { Button, Flag, Grid, Icon, Input, Text } from "components";
import { addressCountriesOptions } from "components/Form/SelectCountry";
import React, { useContext } from "react";
import { Recipient } from "store/actions/recipients";
import { entriesGroupBy, escapeRegExp, pick } from "utils/helpers";
import { styledRemoveIcon } from "./Input";
import { RecipientConfig } from "store/actions/recipientConfig";

interface GovernmentId {
  country: CountryCode | null;
  type?: string | null;
  value?: string | null;
}

interface LocalGovernmentId extends GovernmentId {
  id: string;
}

interface GovIdFieldsProps {
  formCountry: CountryCode;
  recipientType: RecipientType;
  recipientCountry: CountryCode | null;
  recipientConfig?: RecipientConfig;
  value?: Recipient["governmentIds"];
  readOnly?: boolean;
  disabled?: boolean;
  onChange?(values: GovernmentId[]): void;
}

export const governmentIdsValidator = {
  async validator(rule: any, value: Recipient["governmentIds"]) {
    if (!Array.isArray(value)) {
      throw "Value is invalid";
    } else if (value.some((gov) => !gov.country)) {
      throw "A government ID country is missing";
    } else if (value.some((gov) => gov.type === undefined)) {
      throw "A goverment ID type is missing";
    } else if (value.some((gov) => !gov.value)) {
      throw "A government ID number is missing";
    } else {
      const [countryTypeKey] = entriesGroupBy(value, (gov) => `${gov.country}_${gov.type || ""}`).find(([k, v]) => v.length > 1) || [];
      if (countryTypeKey) {
        const [country, type] = countryTypeKey.split("_");
        throw `Cannot submit multiple ${type || "Other"} ID types for ${formatCountry(country)}`;
      }
    }
  },
};

export function validateGovernmentIds(rule: any, value: Recipient["governmentIds"], cb: (error?: string) => void) {
  if (!Array.isArray(value)) {
    cb("Value is invalid");
  } else if (value.some((gov) => !gov.country)) {
    cb("A government ID country is missing");
  } else if (value.some((gov) => gov.type === undefined)) {
    cb("A goverment ID type is missing");
  } else if (value.some((gov) => !gov.value)) {
    cb("A government ID number is missing");
  } else {
    const [countryTypeKey] = entriesGroupBy(value, (gov) => `${gov.country}_${gov.type || ""}`).find(([k, v]) => v.length > 1) || [];
    if (countryTypeKey) {
      const [country, type] = countryTypeKey.split("_");
      cb(`Cannot submit multiple ${type || "Other"} ID types for ${formatCountry(country)}`);
    } else {
      cb();
    }
  }
}

function computeId(id: GovernmentId, i: number) {
  return `${id.country}_${id.type}_${i}`;
}

export default function GovernmentIds({
  formCountry,
  value = [],
  readOnly,
  disabled: customDisabled,
  onChange,
  recipientConfig,
  recipientType,
  recipientCountry,
}: GovIdFieldsProps) {
  const disabledContext = useContext(DisabledContext);
  const disabled = customDisabled ?? disabledContext;
  const ids = value.map((gov, i) => ({ ...gov, id: computeId(gov, i) })); // change type to empty string to match "other" option

  // Show recipientCountry first in the list, so that he does not need to scroll or search..
  const recipientFirstAddressCountriesOptions = [...addressCountriesOptions].sort((a, b) => {
    if (a.value === formCountry) {
      return -1;
    }
    if (b.value === formCountry) {
      return 1;
    }

    return 0;
  });

  const govTypeOptions = recipientFirstAddressCountriesOptions.map((option) => {
    const govIdTypes = getCountryGovIdTypes(option.value);

    const defaultDocumentTypes = [
      ...govIdTypes?.map((idType) => ({
        label: (
          <>
            {idType.label}
            {idType.tooltip && (
              <Text type="secondary" size="small">
                <Icon.Hint left />
                {idType.tooltip}
              </Text>
            )}
          </>
        ),
        title: `${idType.label} ${idType.value}`,
        value: idType.value,
      })),
      { value: "passport", label: "Passport" },
      { value: null, label: "Other" } as any,
    ];

    const countryDocumentTypes = recipientCountry && option.value === recipientCountry && recipientConfig?.documentTypes?.[recipientType];

    const children = countryDocumentTypes || defaultDocumentTypes;

    return {
      label: <Flag code={option.value} />,
      value: option.value,
      title: `${option.value} ${formatCountry(option.value)}`,
      children: children.map((typeOption) => ({
        ...typeOption,
        disabled: ids.some((govId) => govId.country === option.value && govId.type === typeOption.value),
      })),
    };
  });

  function onChangeGovermentId(innerIds: LocalGovernmentId[]) {
    onChange?.(innerIds.map((id) => pick(id, ["country", "type", "value"]) as GovernmentId));
  }

  function renderId(record: LocalGovernmentId, index: number) {
    const selectedGovId = record.country && getCountryGovIdTypes(record.country).find((g) => g.value === record.type);

    const disableRecord = disabled || !!(record.country && record.value?.includes("*"));

    return readOnly ? (
      <Grid>
        <Grid.Item>
          <Flag code={record.country} showLabel={false} showTooltip />
          {selectedGovId?.label}
        </Grid.Item>
        <Grid.Item>{record.value}</Grid.Item>
      </Grid>
    ) : (
      <Input.Group compact className={styledRemoveIcon} key={record.id} style={{ marginBottom: "4px" }}>
        <AntCascader
          suffixIcon={<Icon type="angle-down" />}
          key={`${index}_${record.value}`}
          options={govTypeOptions}
          allowClear={false}
          disabled={disableRecord}
          value={[record.country, record.type as any]}
          onChange={([country, type]: [CountryCode, string]) => {
            onChangeGovermentId(ids.map((gov) => (gov.id === record.id ? { ...gov, country, type } : gov)));
          }}
          showSearch={{
            matchInputWidth: false,
            filter: (inputValue: string, path, names: FilledFieldNamesType) =>
              path.length > 1 &&
              path.some((segment) => {
                if (typeof segment.label === "string" && new RegExp(escapeRegExp(inputValue), "i").test(segment.label)) {
                  return true;
                }
                if (typeof segment.title === "string" && new RegExp(escapeRegExp(inputValue), "i").test(segment.title)) {
                  return true;
                }

                return new RegExp(escapeRegExp(inputValue), "i").test(String(segment.value || ""));
              }),
            render: (inputValue: string, [countrySegment, typeSegment], prefixCls: string | undefined, names: FilledFieldNamesType) => (
              <Grid wrap={false} padding="xsmall">
                <Grid.Item>{countrySegment?.label}</Grid.Item>
                <Grid.Item>/</Grid.Item>
                <Grid.Item>{typeSegment?.label}</Grid.Item>
              </Grid>
            ),
          }}
          displayRender={(label, selectedType) =>
            label.length > 1 ? (
              <Grid wrap={false} padding="xsmall">
                <Grid.Item>{label[0] || "Choose Country"}</Grid.Item>
                <Grid.Item>/</Grid.Item>
                <Grid.Item>{label[1] || "Choose ID Type"}</Grid.Item>
              </Grid>
            ) : (
              "Select Goverment ID Type"
            )
          }
          style={{ width: "50%" }}
        />
        <Input
          key={record.id}
          onChange={(e) => {
            const value = e.target.value;
            onChangeGovermentId(ids.map((gov) => (gov.id === record.id ? { ...gov, value: String(value ?? "").replace(/[^-\w\s.&/]/gi, "") } : gov)));
          }}
          value={record.value ?? undefined}
          disabled={disableRecord || !record.country || record.type === undefined}
          placeholder={selectedGovId?.tooltip || selectedGovId?.label || "ID Number"}
          prefix={record.value && selectedGovId?.tooltip ? <Icon.Hint tooltip={selectedGovId.tooltip} /> : undefined}
          suffix={
            !disabled && (
              <Icon
                tooltip="Remove ID"
                tooltipProps={{ placement: "left" }}
                color="grey"
                fixedWidth={false}
                type="circle-xmark"
                theme="solid"
                onClick={() => {
                  onChangeGovermentId(ids.filter((gov, i) => gov.id !== record.id));
                }}
              />
            )
          }
          style={{ width: "50%" }}
        />
      </Input.Group>
    );
  }

  function onAddNewId() {
    onChange?.([...(value || []), { country: formCountry }]);
  }

  return (
    <div>
      {Object.values(ids).map(renderId)}
      {!readOnly &&
        !disabled &&
        (!ids.length ? (
          <Button onClick={onAddNewId}>
            <Icon type="plus" left />
            Provide a Government ID Number
          </Button>
        ) : (
          <Button
            style={{
              padding: "0",
              float: "left",
            }}
            size="small"
            type="link"
            onClick={onAddNewId}
          >
            <Icon type="plus" left />
            Provide Additional ID Number
          </Button>
        ))}
    </div>
  );
}
