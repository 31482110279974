import { Button, Checkbox, Divider, Icon, Text, Tooltip } from "components";
import React, { useEffect, useState } from "react";
import { storageFactory } from "utils/factories";
import { computeID } from "store/actions/actionUtils";
import { omit } from "utils/helpers";
import { Query } from "utils/hooks";
import { Popover } from "antd";

const storage = storageFactory(() => localStorage);

export enum TableName { // keys in localStorage
  BATCH_PAYMENTS = "pr-batch-details-col",
  TAX_FORMS = "pr-tax-forms-col",
  TAX_PROFILES = "pr-tax-profiles-col",
  TAX_EARNINGS = "pr-tax-earnings-col",
  RECIPIENT_LIST = "pr-recipient-list-col",
  PAYMENT_LIST = "pr-payment-list-col",
  BATCH_LIST = "pr-batch-list-col",
  OFFLINE_PAYMENTS = "pr-offline-payments-col",
  RECIPIENT_OFFLINE_PAYMENTS = "pr-recipient-offline-payments-col",
  PAYMENT_TAXES = "pr-payment-taxes-col",
  RECIPIENT_PAYMENTS = "pr-recipient-payments-col",
  INVOICE_LIST = "pr-invoice-list-col",
  INVOICE = "pr-invoice-col",
}

type Key = string;
export type Column = { label: string; value: boolean };
export type Columns = Record<Key, Column>;
export type MixedColumn = { label: string; value: boolean } | boolean;
export type MixedColumns = Record<Key, MixedColumn>;

interface Props {
  columns: MixedColumns;
  // hiddenColumnKeys?: Key[];
  onChange(update: Record<Key, boolean>): void;
}

function getModifiedDefaultColumn<K extends string>(columns: Record<K, MixedColumn>, query?: Query): Record<K, MixedColumn> {
  const orderBy = query?.orderBy?.[0];
  if (orderBy && columns[orderBy]) {
    // always show the orderedBy column
    return { ...columns, [orderBy]: true };
  }

  return columns;
}

/**
 * @param name
 * key name to be stored in storage
 * @param defaultColumns
 * default columns preferences.
 * @param hiddenColumnKeys
 * Hide certain columns regarless of mandtory or preferences. Typically due to a disabled features, or a property
 */
export function useColumnsDisplay(name: TableName, cols: MixedColumns, hiddenColumnKeys: string[] = [], query?: Query): [MixedColumns, Props["onChange"]] {
  const [displayColumnDictionary, setDisplayColumnDictionary] = useState<Record<Key, boolean>>({});
  const defaultColumns = getModifiedDefaultColumn(cols, query);

  const { preferenceColumns, mandatoryColumns } = Object.entries(defaultColumns).reduce(
    (acc, [key, item]) => {
      if (typeof item === "boolean") {
        acc.mandatoryColumns.push(key);
      } else {
        acc.preferenceColumns[key] = item;
      }

      return acc;
    },
    { mandatoryColumns: [] as string[], preferenceColumns: {} as Columns },
  );

  const columns = {
    ...mandatoryColumns.reduce((acc, k) => {
      acc[k] = true;

      return acc;
    }, {}),

    ...Object.entries(preferenceColumns).reduce((acc, [key, val]) => {
      acc[key] = {
        ...val,
        value: !!displayColumnDictionary[key],
      };

      return acc;
    }, {} as Columns),

    // hide all columns due to disabled features or properties
    ...hiddenColumnKeys.reduce((acc, key) => {
      acc[key] = false;

      return acc;
    }, {}),
  };

  useEffect(() => {
    const defaultColMap: Record<Key, boolean> = omit(
      {
        ...Object.entries(preferenceColumns).reduce((acc, [key, item]) => {
          acc[key] = item.value;

          return acc;
        }, {}),
        ...JSON.parse(storage.getItem(name) || "{}"),
      },
      hiddenColumnKeys,
    );

    storage.setItem(name, JSON.stringify(defaultColMap));

    setDisplayColumnDictionary(defaultColMap);
  }, [computeID(preferenceColumns)]);

  function setCacheColumns(update: Record<Key, boolean>) {
    setDisplayColumnDictionary((state) => {
      const newDisplayedColumns = omit({ ...state, ...update }, hiddenColumnKeys) as Record<Key, boolean>;

      storage.setItem(name, JSON.stringify(newDisplayedColumns));

      return newDisplayedColumns;
    });
  }

  return [columns, setCacheColumns];
}

export function tableColumnFilter(displayedColumns: MixedColumns) {
  return ({ key }: any) => {
    // must contain a key
    const col = displayedColumns[String(key)];

    return typeof col === "boolean" ? col : col?.value !== false;
  };
}

export default function ColumnsDisplay(props: Props) {
  const { columns: mixedColumns, onChange } = props;
  const columns = Object.fromEntries(Object.entries(mixedColumns).filter(([key, val]) => typeof val !== "boolean")) as Columns;
  const columnEntries = Object.entries(columns);
  const allChecked = columnEntries.every(([k, col]) => !!col.value);
  const indeterminate = !allChecked && columnEntries.some(([k, col]) => !!col.value);

  function renderColumnsMenu() {
    return (
      <>
        <Text weight="bold">
          <Checkbox
            indeterminate={indeterminate}
            checked={allChecked}
            onChange={() => {
              // check all if none or partially displayed
              // if all checked, remove
              const newColumns = columnEntries.reduce((acc: Record<Key, boolean>, [key, v]) => {
                acc[key] = !allChecked;

                return acc;
              }, {});

              onChange(newColumns);
            }}
          >
            Show all columns
          </Checkbox>
        </Text>
        <Divider margin="small" />
        {columnEntries.map(([key, col]) => (
          <Text key={key}>
            <Checkbox
              checked={col.value}
              onChange={(e) => {
                onChange({ [key]: e.target.checked });
              }}
            >
              {col.label}
            </Checkbox>
          </Text>
        ))}
      </>
    );
  }

  return (
    <Tooltip title="Show Columns">
      <Popover trigger="click" placement="bottomRight" content={renderColumnsMenu()}>
        <Button icon={<Icon type="columns" theme="solid" />} />
      </Popover>
    </Tooltip>
  );
}
