import * as types from "@trolley/common-frontend";
import { batch } from "react-redux";
import * as request from "services/request";
import store from "store";
import { OpCode, standardDispatch } from "store/dispatcher";
import { Mapped } from "store/reducers/standardReducer";
import { queueFactory } from "utils/factories";
import { Query } from "utils/hooks";
import { computeID, isLoaded } from "./actionUtils";

export { InvoiceStatus } from "@trolley/common-frontend";

export interface Invoice extends Omit<types.Invoice, "status"> {
  status: types.InvoiceStatus | "deleted"; // delete status only exists in store
}

export function resetInvoices() {
  standardDispatch(OpCode.RESET, "invoiceList");
}

const invoiceQueueLoader = queueFactory(
  (invoiceIds) => {
    request
      .POST<{ invoices: Invoice[]; meta: types.SerializerMetaBlock }>("/v1/invoices/search", {
        query: {
          invoiceIds,
          pageSize: invoiceIds.length,
        },
      })
      .then(({ body }) => {
        const mappedInvoices = body.invoices.reduce((acc, inv) => {
          acc[inv.id] = inv;

          return acc;
        }, {});

        standardDispatch(OpCode.DATA, "invoices", {
          bulk: invoiceIds.reduce((acc, id) => {
            acc[id] = mappedInvoices[id] ?? undefined;

            return acc;
          }, {}),
        });
      })
      .catch((errors) => {
        standardDispatch(OpCode.ERROR, "invoices", {
          ids: invoiceIds,
          errors,
        });
      });
  },
  (id) => /^I-\w+/.test(id),
);

export function loadInvoice(id: string, force?: boolean) {
  const { invoices } = store.getState();

  if (force || !isLoaded(invoices.fetchStatus[id])) {
    standardDispatch(OpCode.LOADING, "invoices", { id });
    invoiceQueueLoader.add(id);
  }
}

export interface InvoicesQuery extends Query {
  invoiceDate?: string;
  invoiceNumber?: string[];
  recipientId?: string[];
  externalId?: string[];
  tags?: string[];
}

export function loadInvoices(query: InvoicesQuery, force?: boolean) {
  const { invoiceList } = store.getState();
  const id = computeID(query);
  if (force || !isLoaded(invoiceList.fetchStatus[id])) {
    standardDispatch(OpCode.LOADING, "invoiceList", { id });

    request
      .POST<{ invoices: Invoice[]; meta: types.SerializerMetaBlock }>("/v1/invoices/search", {
        query,
      })
      .then(({ body }) => {
        const invoices: Mapped<Invoice> = {};
        const ids = body.invoices.map((invoice) => {
          invoices[invoice.id] = invoice;

          return invoice.id;
        });

        standardDispatch(OpCode.DATA, "invoices", {
          bulk: invoices,
        });

        standardDispatch(OpCode.DATA, "invoiceList", {
          id,
          data: { records: ids, meta: body.meta },
        });
      })
      .catch((errors) => {
        standardDispatch(OpCode.ERROR, "invoiceList", { id, errors });
      });
  }

  return id;
}

export interface InvoiceCreateQuery {
  recipientId: string;
  invoiceNumber?: string;
  description?: string;
  externalId?: string;
  invoiceDate?: string;
  dueDate?: null | string;
  releaseAfter?: null | string;
  // lines: t.array(lineCreate),
}
export async function createInvoice(query: InvoiceCreateQuery) {
  const { body } = await request.POST<{ invoice: Invoice }>("/v1/invoices/create", { query });

  batch(() => {
    standardDispatch(OpCode.RESET, "invoicePayments");
    standardDispatch(OpCode.RESET, "invoiceList");
  });

  return body.invoice;
}

interface InvoiceUpdateQuery
  extends Partial<Pick<Invoice, "invoiceNumber" | "description" | "invoiceDate" | "dueDate" | "releaseAfter" | "externalId" | "tags">> {}

export async function updateInvoice(id: string, query: InvoiceUpdateQuery) {
  standardDispatch(OpCode.LOADING, "invoices", { id });
  try {
    const { body } = await request.POST<{ invoice: Invoice }>("/v1/invoices/update", {
      query: {
        ...query,
        invoiceId: id,
      },
    });

    standardDispatch(OpCode.DATA, "invoices", {
      id,
      data: body.invoice,
    });
  } catch (errors) {
    standardDispatch(OpCode.ERROR, "invoices", { id, errors });
    throw errors;
  }
}

export async function deleteInvoices(ids: string[]) {
  standardDispatch(OpCode.LOADING, "invoices", { ids });

  try {
    await request.POST("/v1/invoices/delete", { query: { invoiceIds: ids } });
    batch(() => {
      standardDispatch(OpCode.LOADING, "invoices", { ids, loading: false });
      standardDispatch(OpCode.RESET, "invoicePayments");
    });
  } catch (errors) {
    standardDispatch(OpCode.ERROR, "invoices", { ids, errors });
    throw errors;
  }
}

/** ================================================================
 *  INVOICE LINE ITEMS
 * ================================================================= */

export interface InvoiceLine extends types.InvoiceLine {}

type AddInvoiceLineQuery = {
  unitAmount: types.SerializedAmountCurrency;
  description?: string;
  itemUrl?: string;
  externalId?: string;
  taxReportable?: boolean;
  forceUsTaxActivity?: boolean;
  tags?: string[];
  category?: types.PaymentCategory;
  taxAmount?: types.SerializedAmountCurrency;
  discountAmount?: types.SerializedAmountCurrency;
};

export async function createInvoiceLine(invoiceId: string, lines: AddInvoiceLineQuery[]) {
  standardDispatch(OpCode.LOADING, "invoices", { id: invoiceId });

  try {
    const { body } = await request.POST<{ invoice: Invoice }>("/v1/invoices/create-lines", {
      query: {
        invoiceId,
        lines,
      },
    });

    standardDispatch(OpCode.DATA, "invoices", { id: invoiceId, data: body.invoice });
  } catch (errors) {
    standardDispatch(OpCode.ERROR, "invoices", { id: invoiceId, errors });
    throw errors;
  }
}

type InvoiceLineUpdateQuery = {
  invoiceLineId: string;
  description?: string;
  itemUrl?: string | null;
  externalId?: string;
  taxReportable?: boolean;
  forceUsTaxActivity?: boolean;
  tags?: string[];
  quantity?: string;
  unitAmount?: types.SerializedAmountCurrency;
  category?: types.PaymentCategory;
  taxAmount?: types.SerializedAmountCurrency;
  discountAmount?: types.SerializedAmountCurrency;
};
export async function updateInvoiceLine(invoiceId: string, lines: InvoiceLineUpdateQuery[]) {
  standardDispatch(OpCode.LOADING, "invoices", { id: invoiceId });

  try {
    const { body } = await request.POST<{ invoice: Invoice }>("/v1/invoices/update-lines", {
      query: {
        invoiceId,
        lines,
      },
    });

    standardDispatch(OpCode.DATA, "invoices", {
      id: invoiceId,
      data: body.invoice,
    });
  } catch (errors) {
    standardDispatch(OpCode.ERROR, "invoices", { id: invoiceId, errors });
    throw errors;
  }
}

export async function deleteInvoiceLine(invoiceId: string, lineIds: string[]) {
  standardDispatch(OpCode.LOADING, "invoices", { id: invoiceId });

  try {
    const { body } = await request.POST<{ invoice: Invoice }>("/v1/invoices/delete-lines", {
      query: {
        invoiceId,
        invoiceLineIds: lineIds,
      },
    });
    standardDispatch(OpCode.DATA, "invoices", { id: invoiceId, data: body.invoice });
  } catch (errors) {
    standardDispatch(OpCode.ERROR, "invoices", { id: invoiceId, errors });
    throw errors;
  }
}
