/* istanbul ignore file */
import { connectRouter } from "connected-react-router";
import { History } from "history";
import { combineReducers } from "redux";
import { Store } from "store";
import { SESSION_EXPIRED, SESSION_LOGOUT, SESSION_NEW } from "store/actions/session";
import { requestController } from "services/request/requestController";
import notifications from "./notifications";
import session from "./session";
import standardReducer from "./standardReducer";

type ActionBase = {
  type: string;
};

export default (history: History) => (stateIn: any, action: ActionBase) => {
  let state = stateIn;
  if ([SESSION_EXPIRED, SESSION_LOGOUT, SESSION_NEW].includes(action.type)) {
    requestController.cancel();

    state = {
      router: state.router,
      session: state.session,
    };
  }

  return combineReducers<Store>({
    router: connectRouter(history),
    session,
    notifications,

    accountMappings: standardReducer("accountMappings"),
    accountMappingCandidates: standardReducer("accountMappingCandidates"),
    activity: standardReducer("activity"),
    addFunds: standardReducer("addFunds"),
    apiKeys: standardReducer("apiKeys"),
    approvalApprovers: standardReducer("approvalApprovers"),
    approvalLimits: standardReducer("approvalLimits"),
    authLogs: standardReducer("authLogs"),
    balances: standardReducer("balances"),
    bankCodes: standardReducer("bankCodes"),
    bankInfo: standardReducer("bankInfo"),
    batch: standardReducer("batch"),
    batchSummary: standardReducer("batchSummary"),
    batches: standardReducer("batches"),
    codatJournals: standardReducer("codatJournals"),
    compliance: standardReducer("compliance"),
    currencies: standardReducer("currencies"),
    dac7EoySummary: standardReducer("dac7EoySummary"),
    dac7EoyTaxForm: standardReducer("dac7EoyTaxForm"),
    dac7EoyTaxForms: standardReducer("dac7EoyTaxForms"),
    dac7TaxMerchantYear: standardReducer("dac7TaxMerchantYear"),
    dac7EoyTaxExports: standardReducer("dac7EoyTaxExports"),
    directDebit: standardReducer("directDebit"),
    eoyTaxExports: standardReducer("eoyTaxExports"),
    eoySummary: standardReducer("eoySummary"),
    eoyTaxForm: standardReducer("eoyTaxForm"),
    eoyTaxForms: standardReducer("eoyTaxForms"),
    features: standardReducer("features"),
    fees: standardReducer("fees"),
    files: standardReducer("files"),
    fileList: standardReducer("fileList"),
    iframeConfig: standardReducer("iframeConfig"),
    invoiceList: standardReducer("invoiceList"),
    invoices: standardReducer("invoices"),
    invoicePayments: standardReducer("invoicePayments"),
    integrations: standardReducer("integrations"),
    locale: standardReducer("locale"),
    ledgerItems: standardReducer("ledgerItems"),
    syncRecords: standardReducer("syncRecords"),
    messages: standardReducer("messages"),
    merchantSettings: standardReducer("merchantSettings"),
    merchantSubscription: standardReducer("merchantSubscription"),
    merchantSubscriptionPlans: standardReducer("merchantSubscriptionPlans"),
    merchantSubscriptionInvoices: standardReducer("merchantSubscriptionInvoices"),
    merchantFeed: standardReducer("merchantFeed"),
    merchantFeedItem: standardReducer("merchantFeedItem"),
    offlinePaymentList: standardReducer("offlinePaymentList"),
    onboarding: standardReducer("onboarding"),
    pandaDoc: standardReducer("pandaDoc"),
    payment: standardReducer("payment"),
    payments: standardReducer("payments"),
    paymentDac7Taxes: standardReducer("paymentDac7Taxes"),
    paymentDac7TaxList: standardReducer("paymentDac7TaxList"),
    paymentTaxes: standardReducer("paymentTaxes"),
    paymentTaxList: standardReducer("paymentTaxList"),
    paymentTickets: standardReducer("paymentTickets"),
    payoutMethods: standardReducer("payoutMethods"),
    recipient: standardReducer("recipient"),
    recipientAccount: standardReducer("recipientAccount"),
    recipientAccountConfig: standardReducer("recipientAccountConfig"),
    recipientConfig: standardReducer("recipientConfig"),
    recipientLogs: standardReducer("recipientLogs"),
    recipientTaxForms: standardReducer("recipientTaxForms"),
    recipientTaxProfiles: standardReducer("recipientTaxProfiles"),
    recipients: standardReducer("recipients"),
    recipientTickets: standardReducer("recipientTickets"),
    royaltyIntegrations: standardReducer("royaltyIntegrations"),
    statsData: standardReducer("statsData"),
    subMerchants: standardReducer("subMerchants"),
    syncFeatureSettings: standardReducer("syncFeatureSettings"),
    syncCadenceSettings: standardReducer("syncCadenceSettings"),
    syncLinkedJournal: standardReducer("syncLinkedJournal"),
    taxForm: standardReducer("taxForm"),
    taxForms: standardReducer("taxForms"),
    taxProfile: standardReducer("taxProfile"),
    taxProfiles: standardReducer("taxProfiles"),
    taxMerchantYear: standardReducer("taxMerchantYear"),
    teamMemberList: standardReducer("teamMemberList"),
    teamMembers: standardReducer("teamMembers"),
    tfaLogs: standardReducer("tfaLogs"),
    ticket: standardReducer("ticket"),
    tickets: standardReducer("tickets"),
    transferInfo: standardReducer("transferInfo"),
    user: standardReducer("user"),
    webhooks: standardReducer("webhooks"),
    webhookLogs: standardReducer("webhookLogs"),
    whiteLabel: standardReducer("whiteLabel"),
    whiteLabelDns: standardReducer("whiteLabelDns"),
    withholdingTable: standardReducer("withholdingTable"),
    verifications: standardReducer("verifications"),
  })(state, action);
};
