import { Icon } from "components";
import React from "react";
import { useRecipient } from "store/hooks/recipients";
import { TaxDeliveryType } from "@trolley/common-frontend";

type Props = {
  recipientId: string | undefined;
  showIcon?: boolean;
};

export function translateDeliveryMethod(type: TaxDeliveryType | undefined) {
  if (type === TaxDeliveryType.MAIL) {
    return "Postal Mail";
  }
  if (type === TaxDeliveryType.E_DELIVERY) {
    return "E-Delivery";
  }

  return "Unknown Delivery Method";
}

export default function RecipientDeliveryMethod(props: Props) {
  const { recipientId, showIcon = true } = props;
  const { data: recipient } = useRecipient(recipientId);

  if (recipient?.taxDeliveryType === TaxDeliveryType.MAIL) {
    return (
      <>
        {showIcon && <Icon type="envelope" left />}
        {translateDeliveryMethod(recipient.taxDeliveryType)}
      </>
    );
  }

  if (recipient?.taxDeliveryType === TaxDeliveryType.E_DELIVERY) {
    return (
      <>
        {showIcon && <Icon type="at" left />}
        {translateDeliveryMethod(recipient.taxDeliveryType)}
      </>
    );
  }

  return null;
}
