/* istanbul ignore file */
import loadScript from "load-script";
import config from "config";

export function gTagInit() {
  if (config.GTAG_ID && !window.signupConversionFunction) {
    // https://developers.google.com/tag-manager/quickstart
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ "gtm.start": new Date().getTime(), event: "gtm.js" });

    // this loads series of tags and scripts from https://tagmanager.google.com/
    loadScript(`https://www.googletagmanager.com/gtm.js?id=${config.GTAG_ID}`, (err, script) => {
      //
    });
  }
}

export function gTagCapture() {
  if (config.GTAG_ID && window.signupConversionFunction) {
    // Event snippet for Sign-up conversion page
    try {
      window.signupConversionFunction();
    } catch (errors) {
      // eslint-disable-next-line no-console
      console.error(errors);
    }
  }
}
