import { Icon } from "components";
import Tooltip, { TooltipProps } from "components/Tooltip";
import React, { ReactNode } from "react";
import { LinkProps, Link as RouterLink } from "react-router-dom";
import css, { classnames, createUseStyle } from "style/classname";
import ButtonLink from "./ButtonLink";

interface Props extends LinkProps {
  disabled?: boolean;
  tooltip?: TooltipProps | false;
  children: ReactNode;
}
export default function Link(props: Props) {
  const { className, disabled, tooltip = { title: "View page", placement: "right" }, children, ...rest } = props;
  const styledLink = useStyledLink();
  const route = (
    <RouterLink {...rest} className={classnames(styledLink, className)} role="link">
      {children}
      <Icon type="angle-right" color="grey" theme="solid" size="small" style={{ verticalAlign: "baseline", display: "inline", marginLeft: "6px" }} />
    </RouterLink>
  );

  return disabled ? <span>{children}</span> : tooltip ? <Tooltip {...tooltip}>{route}</Tooltip> : route;
}

Link.Button = ButtonLink;

export const useStyledLink = createUseStyle(({ theme }) =>
  css`
    &[role] {
      &,
      &:hover,
      &:active {
        color: ${theme.colorText};
      }
      &:focus-within,
      &:hover {
        .fa-angle-right {
          color: ${theme.colorLinkHover};
        }
      }
    }
  `(),
);
