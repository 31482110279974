import { Icon, Tooltip } from "components";
import Button, { ButtonProps } from "components/Button";
import { IconName } from "components/Icon";
import Modal, { ConfirmProps } from "components/Modal";
import * as mimeTypes from "mime-types";
import React, { useState } from "react";
import * as request from "services/request";
import { notifyError } from "store/actions/notifications";
import { BaseError, BaseStatus } from "store/reducers/standardReducer";
import styled, { classnames } from "style/classname";
import colors from "style/colors";
import { omit } from "utils/helpers";
import { JsonObject } from "utils/hooks";
import Spinner from "./Icon/Spinner";
import Space from "./Space";

const styledButton = styled`
  .icon.fa-arrow-up::before { // custom export icon
    display: inline-block;
    transform: rotate(45deg);
  }
  ${(props: Props) => props.left && "margin-right: 4px;"}
  ${(props: Props) => props.right && "margin-left: 4px;"}
`;

const styledHyperlink = styled`
  ${(props: Props) => props.left && "margin-right: 4px;"}
  ${(props: Props) => props.right && "margin-left: 4px;"}
  ${(props: Props) =>
    props.disabled &&
    `
    cursor: not-allowed;
    opacity: 0.7;
    color: ${colors.grey};
    text-decoration: none;
  `}
`;

export interface DownloadQuery extends JsonObject {}

export interface Props extends Omit<ButtonProps, "icon"> {
  url: string;
  method?: "POST" | "GET";
  query?: DownloadQuery;
  fileName: string;
  defaultExtension?: string; // file name extension
  button?: boolean;
  icon?: true | IconName | "export";
  tooltip?: string;
  disabled?: boolean;
  left?: boolean;
  right?: boolean;
  confirmDownload?: Omit<ConfirmProps, "onOk" | "onCancel">;
  onDownloadComplete?(): void;
  onDownloadFail?(errors: BaseError[]): void;
}

/**
 * Download file to client's device
 */
export async function downloadGETFile(url: string, query: DownloadQuery | undefined, fileName: string, defaultExtension?: string) {
  const { blob } = await request.GET(url, {
    query,
    blob: true,
  });
  if (blob) {
    const ext = mimeTypes.extension(blob.type) || defaultExtension || "";
    const fileFullName = [fileName || Date.now(), ext].join(".");
    const a = document.createElement("a");

    a.setAttribute("style", "display: none");
    document.body.appendChild(a);
    const obUrl = window.URL.createObjectURL(blob);
    a.href = obUrl;
    a.setAttribute("download", fileFullName);
    a.click();
    window.URL.revokeObjectURL(obUrl);
  }
}

/**
 * Download file to client's device
 */
export async function downloadPOSTFile(url: string, query: DownloadQuery | undefined, fileName: string, defaultExtension?: string) {
  const { blob } = await request.POST(url, {
    query,
    blob: true,
  });
  if (blob) {
    const ext = mimeTypes.extension(blob.type) || defaultExtension || "";
    const fileFullName = [fileName || Date.now(), ext].join(".");
    const a = document.createElement("a");

    a.setAttribute("style", "display: none");
    document.body.appendChild(a);
    const obUrl = window.URL.createObjectURL(blob);
    a.href = obUrl;
    a.setAttribute("download", fileFullName);
    a.click();
    window.URL.revokeObjectURL(obUrl);
  }
}

export default function FileDownload(props: Props) {
  const [status, setStatus] = useState<BaseStatus | undefined>();
  const { url, method = "GET", query, button, tooltip, fileName, disabled, defaultExtension, confirmDownload, onDownloadComplete, onDownloadFail } = props;

  async function onDownload(e: React.MouseEvent<HTMLAnchorElement> | any) {
    e?.preventDefault?.();
    e?.stopPropagation?.();
    if (!props.disabled) {
      try {
        confirmDownload
          ? Modal.confirm({
              ...confirmDownload,
              onOk,
            })
          : await onOk();
      } catch {
        //
      }
    }
  }

  async function onOk() {
    if (!disabled && status !== BaseStatus.LOADING) {
      try {
        const filteredQuery = query ? omit(query, ["page", "pageSize", "orderBy", "sortBy"]) : undefined;
        setStatus(BaseStatus.LOADING);
        if (method === "POST") {
          await downloadPOSTFile(url, filteredQuery, fileName, defaultExtension);
        } else {
          await downloadGETFile(url, filteredQuery, fileName, defaultExtension);
        }
        setStatus(BaseStatus.LOADED);
        onDownloadComplete?.();
      } catch (errors) {
        setStatus(BaseStatus.ERROR);
        if (onDownloadFail) {
          onDownloadFail(errors);
        } else {
          notifyError("Downloading file failed", { errors });
        }
      }
    }
  }

  function renderElement() {
    if (button) {
      const bprops: ButtonProps = {
        type: props.type,
        size: props.size,
        disabled: props.disabled,
        ghost: props.ghost,
        block: props.block,
        htmlType: "button",
        style: props.style,
        className: classnames(styledButton(props), props.className),
        onClick: onDownload,
        icon: renderIcon(props.icon, status),
      };

      return <Button {...bprops}>{props.children}</Button>;
    }

    return (
      <a role="button" className={classnames(styledHyperlink(props), props.className)} onClick={onDownload} style={props.style}>
        <Space inline>
          {renderIcon(props.icon, status)}
          {props.children && <span>{props.children}</span>}
        </Space>
      </a>
    );
  }

  return tooltip ? <Tooltip title={tooltip}>{renderElement()}</Tooltip> : renderElement();
}

function renderIcon(icon: Props["icon"], status: BaseStatus | undefined) {
  if (status === BaseStatus.LOADING) {
    return <Spinner color="blue" />;
  }
  if (status === BaseStatus.ERROR) {
    return <Icon.Status type="error" />;
  }

  if (status === BaseStatus.LOADED) {
    return <Icon.Status type="success" />;
  }

  return icon && <Icon type={icon === true ? "cloud-download-alt" : icon === "export" ? "external-link" : icon} />;
}
