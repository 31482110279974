import store from "store";
import { OpCode, standardDispatch } from "store/dispatcher";
import * as request from "services/request";
import { EmailMessageListResult, EmailTemplate } from "@trolley/common-frontend";
import { computeID, isLoaded } from "./actionUtils";

const EmailTemplates: Record<EmailTemplate, string> = {
  [EmailTemplate.DEFAULT]: "General Email",
  [EmailTemplate.A_ATB_UPLOAD_READY]: "ATB Ready",
  [EmailTemplate.A_BUSINESS_PROFILE_ONBOARDING_ADMIN]: "Business Onboarding Completed",
  [EmailTemplate.A_BANK_PROFILE_ONBOARDING_ADMIN]: "Bank Transfer Onboarding Completed",
  [EmailTemplate.A_DAILY_SWEEP]: "Daily account sweep",

  [EmailTemplate.M_REGISTRATION]: "Registration",
  [EmailTemplate.M_TEAM_INVITE]: "Team Invitation",
  [EmailTemplate.M_PASSWORD_RESET]: "Password Reset",
  [EmailTemplate.M_USER_TFA_CODE]: "Two Factor Authentication Code",
  [EmailTemplate.M_USER_NEW_DEVICE]: "New Device Detected",
  [EmailTemplate.M_BATCH_APPROVAL]: "Batch Approval Request",
  [EmailTemplate.M_NOTIFY_BALANCE_CREDITED]: "Account Balance Credited",
  [EmailTemplate.M_NOTIFY_RECIPIENT]: "Recipient Updated",
  [EmailTemplate.M_NOTIFY_PAYMENT]: "Payment Updated",
  [EmailTemplate.M_NOTIFY_BATCH]: "Batch Updated",
  [EmailTemplate.M_NOTIFY_USER]: "User Updated",
  [EmailTemplate.M_NOTIFY_TICKET]: "New Ticket has been created", // to the merchant
  [EmailTemplate.M_NOTIFY_NEW_MESSAGE]: "New message was added a ticket",

  [EmailTemplate.WL_PAYMENT_SENT]: "Payment Sent",
  [EmailTemplate.WL_PAYMENT_METHOD_SETUP]: "Payout Method Setup",
  [EmailTemplate.WL_RETURNED_PAYMENT]: "Payment Returned",
  [EmailTemplate.WL_PORTAL_METHOD_SETUP]: "Account Information Request",
  [EmailTemplate.WL_PORTAL_EMAIL_CODE]: "Recipient Portal Verification Code",
  [EmailTemplate.WL_DAC7_TAX_STATEMENT_AVAILABLE]: "DAC7 Statement Available",
  [EmailTemplate.WL_TAX_STATEMENT_AVAILABLE]: "Tax Statement Available",
  [EmailTemplate.WL_TAX_STATEMENT_REMINDER]: "Tax Statement Available Reminder",
  [EmailTemplate.WL_TAX_REQUIRED]: "Tax Information Request",
  [EmailTemplate.WL_TIN_FAILED]: "TIN Validation Failed",
  [EmailTemplate.WL_TAX_FORM_WILL_EXPIRE]: "Tax Form Will Expire Soon",
  [EmailTemplate.WL_TAX_REQUIRED_ONE_WEEK]: "Missing Tax Form (2nd reminder)",
  [EmailTemplate.WL_TAX_REQUIRED_LAST_REMINDER]: "Missing tax Form (last reminder)",
  [EmailTemplate.WL_TIN_FAILED_ONE_WEEK]: "TIN is invalid (2nd reminder)",
  [EmailTemplate.WL_TIN_FAILED_LAST_REMINDER]: "TIN is invalid (last reminder)",
  [EmailTemplate.WL_TICKET_CREATED]: "New Ticket has been created", // to the recipient
  [EmailTemplate.WL_RECIPIENT_ACCOUNT_CHANGED]: "Recipient Payout Details has Changed",
  [EmailTemplate.WL_RECIPIENT_ACCOUNT_INACTIVE]: "Recipient Account Inactive",
  [EmailTemplate.WL_RECIPIENT_VERIFICATION_STATUS_UPDATED]: "Verification Results",
};

export function getEmailTemplateLabel(templateId: string | undefined): string {
  return EmailTemplates[templateId || ""] || templateId;
}

export interface MessageEvent {
  date: string;
  type: string;
}

export interface Message {
  events: MessageEvent[];
  relatedItemGuid: string | null;
  status: string;

  // email
  messageGuid?: string;
  templateId?: string;
  referenceGuid?: string;
  updatedAt?: string;
  createdAt?: string;

  // lob
  lobGuid?: string;
  merchantGuid: string;
}

export type EmailMessagesQuery = {
  recipientId?: string;
  userId?: string;
  relatedItemId?: string; // eg. Tax Form TX-...
};

export function loadEmailMessages(query: EmailMessagesQuery, force?: boolean) {
  const { messages } = store.getState();
  const id = computeID({ ...query, type: "email" });
  if (query.recipientId || query.userId) {
    if (force || !isLoaded(messages.fetchStatus[id])) {
      standardDispatch(OpCode.LOADING, "messages", { id });

      request
        .POST<EmailMessageListResult>("/v1/messages/email", { query })
        .then(({ body }) => {
          standardDispatch(OpCode.DATA, "messages", {
            id,
            data: body.messages,
          });
        })
        .catch((errors) => {
          standardDispatch(OpCode.ERROR, "messages", {
            id,
            errors,
          });
        });
    }
  }

  return id;
}

export interface LobMessagesQuery {
  relatedItemId?: string; // eg. Tax Form TX-...
}

export function loadLobMessages(query: LobMessagesQuery, force?: boolean) {
  const { messages } = store.getState();
  const id = computeID({ ...query, type: "lob" });

  if (force || !isLoaded(messages.fetchStatus[id])) {
    standardDispatch(OpCode.LOADING, "messages", { id });

    request
      .POST<EmailMessageListResult>("/v1/messages/lob", { query })
      .then(({ body }) => {
        standardDispatch(OpCode.DATA, "messages", {
          id,
          data: body.messages,
        });
      })
      .catch((errors) => {
        standardDispatch(OpCode.ERROR, "messages", {
          id,
          errors,
        });
      });
  }

  return id;
}
