import { batch } from "react-redux";
import store from "store";
import { loadRecipient } from "store/actions/recipients";
import { TaxProfile, TaxProfilesQuery } from "store/actions/taxProfiles";
import { OpCode, standardDispatch } from "store/dispatcher";
import { Mapped } from "store/reducers/standardReducer";
import * as request from "services/request";
import * as types from "@trolley/common-frontend";
import { computeID, isLoaded } from "./actionUtils";
import { RecursivePartial } from "@trolley/common-frontend";

export function loadRecipientTaxProfiles(recipientId: string, query: TaxProfilesQuery, force: boolean = false) {
  const { recipientTaxProfiles } = store.getState();
  const id = computeID({ ...query, recipientId });

  if (force || !isLoaded(recipientTaxProfiles.fetchStatus[id])) {
    standardDispatch(OpCode.LOADING, "recipientTaxProfiles", { id });

    request
      .POST<types.TaxProfileListResponse>("/v1/tax-profile/search", { query: { ...query, recipientId } })
      .then((res) => {
        const ids: string[] = [];
        const mappedProfiles: Mapped<TaxProfile> = {};
        res.body.taxProfiles.forEach((tp) => {
          mappedProfiles[tp.id] = tp;
          ids.push(tp.id);
        });
        batch(() => {
          standardDispatch(OpCode.DATA, "recipientTaxProfiles", {
            id,
            data: {
              records: ids,
              meta: res.body.meta,
            },
          });
          standardDispatch(OpCode.DATA, "taxProfile", {
            bulk: mappedProfiles,
          });
        });
      })
      .catch((errors) => {
        standardDispatch(OpCode.ERROR, "recipientTaxProfiles", {
          id,
          errors,
        });
      });
  }

  return id;
}

export async function updateRecipientTaxProfiles(recipientId: string, taxProfileId: string, changes: RecursivePartial<TaxProfile>) {
  standardDispatch(OpCode.LOADING, "taxProfile", {
    id: taxProfileId,
  });

  try {
    const { body } = await request.POST<types.TaxProfileResponse>("/v1/tax-profile/update", { query: changes });
    standardDispatch(OpCode.DATA, "taxProfile", {
      id: taxProfileId,
      data: body.taxProfile,
    });
    loadRecipient(recipientId, true);
  } catch (errors) {
    standardDispatch(OpCode.ERROR, "taxProfile", {
      id: taxProfileId,
      errors,
    });
    throw errors;
  }
}

export async function voidRecipientTaxProfile(recipientId: string, taxProfileId: string, reason?: types.TaxFormVoidReason) {
  standardDispatch(OpCode.LOADING, "taxProfile", { id: taxProfileId });

  try {
    await request.POST<{}>("/v1/tax-profile/void", { query: { profilesToVoid: [{ id: taxProfileId, reason }] } });
    standardDispatch(OpCode.UPDATE, "taxProfile", {
      id: taxProfileId,
      update: {
        status: types.TaxStatus.VOIDED,
      },
    });
    standardDispatch(OpCode.RESET, "recipientTaxProfiles");
    loadRecipient(recipientId, true);
  } catch (errors) {
    standardDispatch(OpCode.ERROR, "taxProfile", {
      errors,
      id: taxProfileId,
    });
    throw errors;
  }
}

// review tax profile
export async function approveRecipientTaxProfile(recipientId: string, taxProfileId: string) {
  standardDispatch(OpCode.LOADING, "taxProfile", { id: taxProfileId });

  try {
    await request.POST<{}>("/v1/tax-profile/approve", { query: { ids: [taxProfileId] } });
    batch(() => {
      standardDispatch(OpCode.LOADING, "taxProfile", { id: taxProfileId, loading: false });
      standardDispatch(OpCode.UPDATE, "taxProfile", {
        id: taxProfileId,
        update: {
          status: types.TaxStatus.REVIEWED,
        },
      });
      standardDispatch(OpCode.RESET, "recipientTaxProfiles");
    });
    loadRecipient(recipientId, true);
  } catch (errors) {
    standardDispatch(OpCode.ERROR, "taxProfile", {
      errors,
      id: taxProfileId,
    });
    throw errors;
  }
}

// review multiple tax profiles
export async function approveRecipientTaxProfiles(recipientId: string, taxProfileIds: string[]) {
  standardDispatch(OpCode.LOADING, "taxProfile", { ids: taxProfileIds });

  try {
    const res = await request.POST<types.TaxProfileListResponse>("/v1/tax-profile/approve", { query: { ids: taxProfileIds } });
    batch(() => {
      const mapTaxProfiles: Mapped<TaxProfile> = {};
      const ids = res.body.taxProfiles.map((tp) => {
        mapTaxProfiles[tp.id] = tp;

        return tp.id;
      });
      standardDispatch(OpCode.LOADING, "taxProfile", { ids: taxProfileIds, loading: false });
      standardDispatch(OpCode.UPDATE, "taxProfile", {
        bulk: ids.reduce((acc, id) => {
          acc[id] = {
            status: types.TaxStatus.REVIEWED,
          };

          return acc;
        }, {}),
      });
      standardDispatch(OpCode.RESET, "recipientTaxProfiles");
    });
    loadRecipient(recipientId, true);
  } catch (errors) {
    standardDispatch(OpCode.ERROR, "taxProfile", {
      errors,
      ids: taxProfileIds,
    });
    throw errors;
  }
}

// void multiple tax profiles
export async function voidRecipientTaxProfiles(recipientId: string, taxProfileIds: string[], reason?: types.TaxFormVoidReason) {
  standardDispatch(OpCode.LOADING, "taxProfile", { ids: taxProfileIds });

  const profilesToVoid = taxProfileIds.map((id) => ({
    id,
    reason,
  }));

  try {
    const res = await request.POST<types.TaxProfileListResponse>("/v1/tax-profile/void", { query: { profilesToVoid } });
    batch(() => {
      const mapTaxProfiles: Mapped<TaxProfile> = {};
      const ids = res.body.taxProfiles.map((tp) => {
        mapTaxProfiles[tp.id] = tp;

        return tp.id;
      });
      standardDispatch(OpCode.LOADING, "taxProfile", { ids: taxProfileIds, loading: false });
      standardDispatch(OpCode.UPDATE, "taxProfile", {
        bulk: ids.reduce((acc, id) => {
          acc[id] = {
            status: types.TaxStatus.VOIDED,
          };

          return acc;
        }, {}),
      });
      standardDispatch(OpCode.RESET, "recipientTaxProfiles");
    });
    loadRecipient(recipientId, true);
  } catch (errors) {
    standardDispatch(OpCode.ERROR, "taxProfile", {
      errors,
      ids: taxProfileIds,
    });
    throw errors;
  }
}
