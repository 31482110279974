import {
  Container,
  CopyToClipboard,
  CurrencyDisplay,
  DateDisplay,
  Heading,
  Icon,
  LabelTooltip,
  RecordCount,
  Table,
  TableColumnsType,
  Tag,
  Tooltip,
} from "components";
import ColumnsDisplay, { MixedColumn, tableColumnFilter, TableName, useColumnsDisplay } from "components/Table/ColumnsDisplay";
import { PaymentPreview, StatusPayment } from "features/payment";
import { renderMultiCategories, renderMultiForceUsActivities, renderMultiTaxReportable } from "features/payment/PaymentPreview";
import { TaxFormDisplay } from "features/taxForm";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { Features, MerchantSettings } from "store/actions/merchantSettings";
import { Payment, PaymentsQuery, PaymentsQueryArrayKeys } from "store/actions/payments";
import { useMerchantSettings } from "store/hooks/merchantSettings";
import { useRecipientPaymentList } from "store/hooks/payments";
import { FORCE_US_TAX_CATEGORIES } from "utils/constants";
import { useQuery } from "utils/hooks";
import { PaymentCategory } from "@trolley/common-frontend";
import RecipientOfflinePayments from "./RecipientOfflinePayments";
import RecipientDac7OfflinePayments from "./RecipientDac7OfflinePayments";

export const DEFAULT_QUERY: PaymentsQuery = {
  page: 1,
  pageSize: 10,
};

type Params = { recipientId: string };

enum ColumnKeys {
  STATUS = "status",
  ID = "id",
  AMOUNT = "amount",
  TAX_WITHHOLDING = "withholdingAmountOnCreation",
  CATEGORY = "category",
  FORCE_US_TAX = "forceUsTaxActivity",
  MERCHANT_FEES = "merchantFees",
  RECIPIENT_FEES = "recipientFees",
  EXCHANGE_RATE = "exchangeRate",
  RECIPIENT_RECEIVES = "targetAmount",
  TAX_REPORTABLE = "taxReportable",
  TAX_FORM = "taxForm",
  MEMO = "memo",
  TAGS = "tags",
  UPDATED_AT = "updatedAt",
  CREATED_AT = "createdAt",
  EXTERNAL_ID = "external_id",
}

const defaultColumnsDisplay: Record<ColumnKeys, MixedColumn> = {
  [ColumnKeys.STATUS]: true, // mandatory column, ignore filter.
  [ColumnKeys.AMOUNT]: true, // mandatory column, ignore filter.
  [ColumnKeys.ID]: {
    label: "Payment ID",
    value: true,
  },
  [ColumnKeys.EXTERNAL_ID]: {
    label: "External ID",
    value: false,
  },
  [ColumnKeys.TAX_WITHHOLDING]: {
    label: "Withholding Amount",
    value: true,
  },
  [ColumnKeys.MERCHANT_FEES]: {
    label: "Merchant Fees",
    value: true,
  },
  [ColumnKeys.RECIPIENT_FEES]: {
    label: "Recipient Fees",
    value: true,
  },
  [ColumnKeys.EXCHANGE_RATE]: {
    label: "Exchange Rate",
    value: true,
  },
  [ColumnKeys.RECIPIENT_RECEIVES]: {
    label: "Recipient Receives",
    value: true,
  },
  [ColumnKeys.CATEGORY]: {
    label: "Payment Purpose",
    value: true,
  },
  [ColumnKeys.TAX_REPORTABLE]: {
    label: "Taxable",
    value: true,
  },
  [ColumnKeys.FORCE_US_TAX]: {
    label: "US Activities Override",
    value: false,
  },
  [ColumnKeys.TAX_FORM]: {
    label: "Tax Form",
    value: false,
  },
  [ColumnKeys.MEMO]: {
    label: "Memo",
    value: false,
  },
  [ColumnKeys.TAGS]: {
    label: "Tags",
    value: true,
  },
  [ColumnKeys.UPDATED_AT]: {
    label: "Updated On",
    value: true,
  },
  [ColumnKeys.CREATED_AT]: {
    label: "Created On",
    value: false,
  },
};

export function getExcludedKeysForDisplayColumnMenu(merchantSettings: MerchantSettings | undefined, features: Partial<Features>) {
  const excludeColumns: string[] = [];
  const taxEnabled = features.tax && merchantSettings?.tax?.enabled;
  const enabledCategories = Object.entries(merchantSettings?.payment?.categories || {})
    .filter(([c, v]) => v)
    .map(([c, _]) => c as PaymentCategory);

  if (!taxEnabled) {
    excludeColumns.push(ColumnKeys.TAX_REPORTABLE, ColumnKeys.FORCE_US_TAX, ColumnKeys.TAX_WITHHOLDING, ColumnKeys.TAX_FORM);
  } else if (!enabledCategories.some((cat) => FORCE_US_TAX_CATEGORIES.includes(cat))) {
    excludeColumns.push(ColumnKeys.FORCE_US_TAX);
  }

  return excludeColumns;
}

export default function RecipientPayments() {
  const { recipientId } = useParams<Params>();
  const [previewPaymentId, setPreviewPaymentId] = useState<string | undefined>();
  const [query, setQuery] = useQuery(DEFAULT_QUERY, {
    arrayKeys: PaymentsQueryArrayKeys,
  });
  const { features, data: merchantSettings } = useMerchantSettings();
  const {
    data: { records, meta },
    status: recipientPaymentListStatus,
    error: recipientPaymentListErrors,
  } = useRecipientPaymentList(recipientId, query);
  const [displayedColumns, setDisplayedColumns] = useColumnsDisplay(
    TableName.PAYMENT_LIST,
    defaultColumnsDisplay,
    getExcludedKeysForDisplayColumnMenu(merchantSettings, features),
  );
  const dac7Enabled = features.euTax && merchantSettings?.euTax?.enabled;

  const columns = [
    {
      title: <LabelTooltip type="paymentStatus" />,
      key: ColumnKeys.STATUS,
      dataIndex: "status",
      width: 100,
      align: "center",
      render: (status: Payment["status"], p: Payment) => <StatusPayment payment={p} />,
    },
    {
      title: "Payment ID",
      key: ColumnKeys.ID,
      dataIndex: "id",
      render: (id: string) => <CopyToClipboard value={id} />,
    },
    {
      title: "External ID",
      key: ColumnKeys.EXTERNAL_ID,
      dataIndex: "externalId",
      render: (id: string) => <CopyToClipboard value={id} />,
    },
    {
      title: "Payment Amount",
      key: ColumnKeys.AMOUNT,
      dataIndex: "amount",
      align: "right",
      render: (amount: Payment["amount"], record: Payment) => <CurrencyDisplay value={amount} currency={record.currency} />,
    },
    {
      title: "Tax Withholding",
      key: ColumnKeys.TAX_WITHHOLDING,
      dataIndex: "withholdingAmountOnCreation",
      align: "right",
      render: (equivalentWithholdingAmount: Payment["withholdingAmountOnCreation"], record: Payment) => (
        <CurrencyDisplay value={equivalentWithholdingAmount} currency={record.equivalentWithholdingCurrency} />
      ),
    },
    {
      title: "Merchant Fees",
      key: ColumnKeys.MERCHANT_FEES,
      dataIndex: "merchantFees",
      align: "right",
      render: (fees: Payment["merchantFees"], record: Payment) => <CurrencyDisplay value={fees} currency={record.sourceCurrency} />,
    },
    {
      title: "Recipient Fees",
      key: ColumnKeys.RECIPIENT_FEES,
      dataIndex: "recipientFees",
      align: "right",
      render: (fees: Payment["recipientFees"], record: Payment) => <CurrencyDisplay value={fees} currency={record.sourceCurrency} />,
    },
    {
      title: "Exchange Rate",
      key: ColumnKeys.EXCHANGE_RATE,
      dataIndex: "exchangeRate",
      align: "right",
    },
    {
      title: "Recipient Receives",
      key: ColumnKeys.RECIPIENT_RECEIVES,
      dataIndex: "targetAmount",
      align: "right",
      render: (amount: Payment["amount"], record: Payment) => <CurrencyDisplay value={amount} currency={record.targetCurrency} />,
    },
    {
      title: "Payment Purpose",
      key: ColumnKeys.CATEGORY,
      dataIndex: "category",
      render: (category: Payment["category"], payment: Payment) => renderMultiCategories(payment.taxes),
    },
    {
      title: <LabelTooltip type="taxReportable" />,
      key: ColumnKeys.TAX_REPORTABLE,
      dataIndex: "taxReportable",
      render: (taxable: Payment["taxReportable"], payment: Payment) => renderMultiTaxReportable(payment.taxes),
    },
    {
      key: ColumnKeys.FORCE_US_TAX,
      title: <LabelTooltip type="forceUsTaxActivity" />,
      dataIndex: "forceUsTaxActivity",
      render: (usActivities: Payment["forceUsTaxActivity"], payment: Payment) => renderMultiForceUsActivities(payment.taxes),
    },
    {
      key: ColumnKeys.TAX_FORM,
      title: "Tax Form",
      dataIndex: "taxes",
      render: (taxes: Payment["taxes"] = []) => {
        const [paymentTax, ...otherPaymentTaxes] = taxes;
        if (otherPaymentTaxes.some((t) => t.taxFormId && t.taxFormId !== paymentTax.taxFormId)) {
          return "Multiple";
        }

        return <TaxFormDisplay taxFormId={paymentTax.taxFormId} showIcon showSignedDate />;
      },
    },
    { title: "Tags", key: ColumnKeys.TAGS, dataIndex: "tags", render: (tags: string[]) => tags.map((tag) => <Tag key={tag}>{tag}</Tag>) },
    {
      title: "Memo",
      key: ColumnKeys.MEMO,
      dataIndex: "memo",
      render: (memo: Payment["memo"]) =>
        memo && (
          <Tooltip placement="top" title={memo}>
            <Icon type="sticky-note" size="large" />
          </Tooltip>
        ),
    },
    {
      title: "Updated On",
      key: ColumnKeys.UPDATED_AT,
      sorter: true,
      sortOrder: query.orderBy?.[0] === "updatedAt" ? (query.sortBy?.[0] === "asc" ? "ascend" : "descend") : undefined,
      render: (p: Payment) => <DateDisplay value={p.initiatedAt || p.updatedAt} />,
    },
    {
      title: "Created On",
      key: ColumnKeys.CREATED_AT,
      dataIndex: "createdAt",
      sorter: true,
      sortOrder: query.orderBy?.[0] === "createdAt" ? (query.sortBy?.[0] === "asc" ? "ascend" : "descend") : undefined,
      render: (date: Payment["createdAt"]) => <DateDisplay value={date} />,
    },
  ].filter(tableColumnFilter(displayedColumns)) as TableColumnsType<Payment>;

  return (
    <Container>
      <Helmet>
        <title>Payments</title>
      </Helmet>
      <Heading tag="h2" extraActions={<ColumnsDisplay columns={displayedColumns} onChange={setDisplayedColumns} />}>
        <RecordCount prefix="Showing" value={meta.records} one="1 payment" other="# payments" />
      </Heading>

      <Table<Payment>
        status={recipientPaymentListStatus}
        errors={recipientPaymentListErrors}
        emptyProps={{ description: "No payments found" }}
        dataSource={records}
        columns={columns}
        onRow={(record) => ({
          onClick: () => {
            setPreviewPaymentId(record.id);
          },
        })}
        pagination={{
          current: query.page,
          pageSize: query.pageSize,
          total: meta.records,
        }}
        onQueryChange={setQuery}
      />

      {!dac7Enabled && <RecipientOfflinePayments recipientId={recipientId} />}

      {dac7Enabled && <RecipientDac7OfflinePayments recipientId={recipientId} />}

      <PaymentPreview
        paymentId={previewPaymentId}
        onClose={() => {
          setPreviewPaymentId(undefined);
        }}
      />
    </Container>
  );
}
