import { useEffect, useState } from "react";
import { computeID } from "store/actions/actionUtils";
import { EoyTaxExportsQuery, loadEoyTaxExports } from "store/actions/eoyTaxExports";
import { emptyList } from "store/reducers/standardReducer";
import { useShallowSelector } from ".";

export function useEoyTaxExports(query: EoyTaxExportsQuery) {
  const [fetchId, setFetchId] = useState("");

  useEffect(() => {
    if (query.taxYear && query.formTypes?.length === 1) {
      setFetchId(loadEoyTaxExports(query));
    } else {
      setFetchId("");
    }
  }, [computeID(query)]);

  return useShallowSelector((state) => {
    if (fetchId && state.eoyTaxExports.fetchStatus[fetchId] === undefined) {
      loadEoyTaxExports(query);
    }

    return {
      data: state.eoyTaxExports.entities[fetchId] || emptyList,
      status: state.eoyTaxExports.fetchStatus[fetchId],
      error: state.eoyTaxExports.errors[fetchId],
    };
  });
}
