import { DatePicker } from "antd";
import { RangePickerDateProps } from "antd/lib/date-picker/generatePicker";
import Icon from "components/Icon";
import dayjs, { Dayjs } from "dayjs";
import React from "react";

export interface RangeProps extends Omit<RangePickerDateProps<Dayjs>, "onChange" | "value" | "format"> {
  onChange?(value: [string, string] | [undefined, undefined]): void;
  value: (string | undefined)[] | undefined | null;
  format?: string; // format for onChange.
}

const defaultFormat = "YYYY-MM-DDTHH:mm:ssZ";

export default function RangePicker(props: RangeProps) {
  const { onChange, value, className, format = defaultFormat, ...rest } = props;
  let rangeValue: undefined | [Dayjs, Dayjs];
  if (value && value[0] && value[1]) {
    rangeValue = [dayjs(value[0]), dayjs(value[1])];
  }

  return (
    <DatePicker.RangePicker
      value={rangeValue}
      format="YYYY-MM-DD"
      onChange={(dates) => {
        if (dates && dates[0] && dates[1]) {
          const startDate = dates[0].startOf("day").format(format);
          const endDate = dates[1].endOf("day").format(format);
          onChange?.([startDate, endDate]);
        } else if (!dates) {
          onChange?.([undefined, undefined]);
        }
      }}
      suffixIcon={<Icon type="calendar" fixedWidth={false} />}
      {...rest}
    />
  );
}
