import { CurrencyCode, getPayoutMethodLabel, PaymentCategory, PayoutMethodType } from "@trolley/common-frontend";
import {
  Button,
  ColumnsDisplay,
  Container,
  CurrencyDisplay,
  DateDisplay,
  Dropdown,
  Grid,
  Heading,
  Icon,
  InputTags,
  LabelTooltip,
  Menu,
  MixedColumn,
  RecordCount,
  Reloader,
  Table,
  tableColumnFilter,
  TableColumnsType,
  TableFilters,
  TableName,
  Text,
  Tooltip,
  useColumnsDisplay,
} from "components";
import { PaymentDisplay } from "features/payment";
import { PaymentTaxRecordEdit } from "features/paymentTax";
import { PayoutMethodDisplay } from "features/payoutMethod";
import { RecipientPreview, RecipientProfile } from "features/recipient";
import { TaxFormDisplay } from "features/taxForm";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { MerchantSettings } from "store/actions/merchantSettings";
import { PaymentTax, PaymentTaxesQuery, resetPaymentTaxes, updateBulkPaymentTaxes } from "store/actions/paymentTaxes";
import { useMerchantSettings } from "store/hooks/merchantSettings";
import { usePaymentTaxes } from "store/hooks/paymentTaxes";
import { CATEGORY_DETAILS, FORCE_US_TAX_CATEGORIES } from "utils/constants";
import { getPaymentCategoryLabel } from "utils/helpers";
import { useQuery } from "utils/hooks";
import PaymentTaxesBulkEdit from "./PaymentTaxesBulkEdit";

const DEFAULT_QUERY: PaymentTaxesQuery = {
  page: 1,
  pageSize: 10,
  orderBy: ["createdAt"],
  sortBy: ["desc"],
  dateColumn: "createdAt",
};

enum ColumnKeys {
  SEARCH = "search",
  RECIPIENT = "recipient",
  PAYMENT_ID = "paymentId",
  AMOUNT = "amount",
  TAX_WITHHOLDING = "withholdingAmount",
  TAX_PAID_BY_AGENT = "taxPaidByWithholdingAgents",
  TAX_FORM = "taxFormId",
  CATEGORY = "category",
  TAX_REPORTABLE = "taxReportable",
  FORCE_US_TAX = "forceUsTaxActivity",
  TAGS = "tags",
  MEMO = "memo",
  MODIFIED = "modifiedSinceCreation",
  CREATED_AT = "createdAt",
  UPDATED_AT = "updatedAt",
  PROCESSED_AT = "processedAt",
  PAYOUT_METHOD = "payoutMethod",
}

const defaultColumnsDisplay: Record<ColumnKeys, MixedColumn> = {
  [ColumnKeys.SEARCH]: true,
  [ColumnKeys.RECIPIENT]: true,
  [ColumnKeys.AMOUNT]: true,
  [ColumnKeys.TAX_WITHHOLDING]: true,
  [ColumnKeys.PAYMENT_ID]: {
    label: "Related Payment",
    value: true,
  },
  [ColumnKeys.TAX_PAID_BY_AGENT]: {
    label: "Tax Paid By Withholding Agent",
    value: false,
  },
  [ColumnKeys.PAYOUT_METHOD]: {
    label: "Payout Method",
    value: true,
  },
  [ColumnKeys.CATEGORY]: {
    label: "Payment Purpose",
    value: true,
  },
  [ColumnKeys.TAX_REPORTABLE]: {
    label: "Taxable",
    value: true,
  },
  [ColumnKeys.FORCE_US_TAX]: {
    label: "Force US Activity",
    value: false,
  },
  [ColumnKeys.TAX_FORM]: {
    label: "Tax Form",
    value: true,
  },
  [ColumnKeys.TAGS]: {
    label: "Tags",
    value: false,
  },
  [ColumnKeys.MEMO]: {
    label: "Memo",
    value: false,
  },
  [ColumnKeys.MODIFIED]: {
    label: "Modified Since Creation",
    value: false,
  },
  [ColumnKeys.CREATED_AT]: {
    label: "Created On",
    value: true,
  },
  [ColumnKeys.UPDATED_AT]: {
    label: "Updated On",
    value: false,
  },
  [ColumnKeys.PROCESSED_AT]: {
    label: "Processed On",
    value: false,
  },
};

export function getBulkEditRecipientId(allSelected: boolean, query: PaymentTaxesQuery, selected: PaymentTax[]) {
  if (allSelected && query.recipientId?.length === 1) {
    return query.recipientId[0];
  }
  if (selected.length) {
    const [pt1, ...rest] = selected;

    if (!rest.length || rest.every((pt) => pt.recipientId === pt1.recipientId)) {
      return pt1.recipientId;
    }
  }

  return undefined;
}

export function getExcludedKeysForDisplayColumnMenu(merchantSettings: MerchantSettings | undefined, query: PaymentTaxesQuery) {
  const excludeColumns: string[] = [];
  const enabledCategories = Object.entries(merchantSettings?.payment?.categories || {})
    .filter(([c, v]) => v)
    .map(([c, _]) => c as PaymentCategory);

  if (!enabledCategories.some((cat) => FORCE_US_TAX_CATEGORIES.includes(cat))) {
    excludeColumns.push(ColumnKeys.FORCE_US_TAX);
  }
  if (!merchantSettings?.tax?.enabledTaxPaidByWithholdingAgents) {
    excludeColumns.push(ColumnKeys.TAX_PAID_BY_AGENT);
  }

  if (query.recipientId) {
    excludeColumns.push(ColumnKeys.SEARCH);
  }

  return excludeColumns;
}

export default function PaymentTaxes() {
  const { data: merchantSettings } = useMerchantSettings();
  const [query, setQuery] = useQuery(DEFAULT_QUERY, {
    arrayKeys: ["payoutMethod", "category", "status"],
  });
  const { data: paymentTaxes, status, error } = usePaymentTaxes(query);
  const [selected, setSelected] = useState<PaymentTax[]>([]);
  const [showBulkEdit, setShowBulkEdit] = useState(false);
  const [previewRecipient, setPreviewRecipient] = useState<undefined | string>();
  const [allSelected, setAllSelected] = useState(false);
  const selectedDateColumn = query.dateColumn || "createdAt";
  const [displayedColumns, setDisplayedColumns] = useColumnsDisplay(
    TableName.PAYMENT_TAXES,
    defaultColumnsDisplay,
    getExcludedKeysForDisplayColumnMenu(merchantSettings, query),
    query,
  );
  const [paymentTax, editPaymentTax] = useState<PaymentTax | undefined>();

  useEffect(() => {
    setAllSelected(false);
    setSelected([]);
  }, [query]);

  return (
    <Container>
      <Helmet>
        <title>Payment Tax Records</title>
      </Helmet>
      <Heading>
        <RecordCount
          prefix="Showing"
          value={paymentTaxes.meta.records}
          one="1 payment tax record"
          other="# payment tax records"
          equalZero="payment tax records"
        />
        <Reloader onClick={resetPaymentTaxes} status={status} />
      </Heading>

      <TableFilters
        showSearch
        onChange={setQuery}
        query={query}
        suffix={<ColumnsDisplay columns={displayedColumns} onChange={setDisplayedColumns} />}
        fields={[
          {
            label: "Payout Method",
            type: "select",
            filterKey: "payoutMethod",
            options: Object.values(PayoutMethodType).map((pm) => ({
              label: getPayoutMethodLabel(pm, true),
              value: pm,
            })),
          },
          {
            label: "Payment Purpose",
            type: "select",
            filterKey: "category",
            options: Object.entries(CATEGORY_DETAILS).map(([category, item]) => ({
              label: item.name,
              value: category,
            })),
          },
          {
            label: "Payment Origin",
            type: "radio",
            filterKey: "withPayment",
            options: [
              { label: "All", value: undefined },
              { label: "Trolley", value: true },
              { label: "Offline", value: false },
            ],
          },
          {
            label: (
              <Tooltip title="Filter payment tax records that have non-zero tax withholding amount.">
                Has Tax Withholding
                <Icon.Hint right />
              </Tooltip>
            ),
            type: "radio",
            filterKey: "withTaxWithholding",
            options: [
              { label: "All", value: undefined },
              { label: "Has Withholding", value: true },
              { label: "No Withholding", value: false },
            ],
          },
          {
            label: <LabelTooltip type="taxReportable" />,
            type: "radio",
            filterKey: "taxReportable",
            options: [
              { label: "All", value: undefined },
              { label: "Taxable", value: true },
              { label: "Exempt", value: false },
            ],
          },
          {
            label: <LabelTooltip type="forceUsTaxActivity" />,
            type: "radio",
            filterKey: "forceUsTaxActivity",
            options: [
              { label: "All", value: undefined },
              { label: "Override On", value: true },
              { label: "Override Off", value: false },
            ],
          },

          {
            label: (
              <Text capitalize inline>
                <Dropdown
                  placement="bottomLeft"
                  overlay={
                    <Menu
                      onClick={(params) => {
                        setQuery((state) => {
                          const dateColumn = params.key as "createdAt" | "updatedAt" | "processedAt";
                          if (state.startDate && state.endDate) {
                            return {
                              dateColumn,
                              startDate: params.key === "processedAt" ? state.startDate.substring(0, 10) : dayjs(state.startDate).startOf("date").format(),
                              endDate: params.key === "processedAt" ? state.endDate.substring(0, 10) : dayjs(state.endDate).endOf("date").format(),
                            };
                          } else {
                            return {
                              dateColumn,
                            };
                          }
                        });
                      }}
                    >
                      <Menu.Item disabled={query.dateColumn === "updatedAt"} key="updatedAt">
                        Updated Between
                      </Menu.Item>
                      <Menu.Item disabled={query.dateColumn === "createdAt"} key="createdAt">
                        Created Between
                      </Menu.Item>
                      {/* <Menu.Item disabled={query.dateColumn === "initiatedAt"} key="initiatedAt">
                    Initiated Between
                  </Menu.Item> */}
                      <Menu.Item disabled={query.dateColumn === "processedAt"} key="processedAt">
                        Processed Between
                      </Menu.Item>
                    </Menu>
                  }
                >
                  <span>
                    {selectedDateColumn.replace("At", " Between")}
                    <Icon type="angle-down" size="small" right />
                  </span>
                </Dropdown>
              </Text>
            ),
            filterKey: ["startDate", "endDate"],
            type: "date-range",
            format: selectedDateColumn === "processedAt" ? "YYYY-MM-DD" : undefined,
          },
          {
            label: "Recipient ID",
            type: "tags",
            filterKey: "recipientId",
            placeholder: "Recipient ID",
            maxLength: 1,
            renderTag: (id) => <RecipientProfile onlyName recipientId={id} />,
          },
        ]}
      >
        {selected.length > 0 && (
          <Grid padding="small">
            <Grid.Item>
              <Button
                type="primary"
                onClick={() => {
                  setShowBulkEdit(true);
                }}
              >
                {allSelected ? (
                  <RecordCount value={paymentTaxes.meta.records} other="Edit All # Payment Tax Records" />
                ) : (
                  <RecordCount value={selected.length} one="Edit # Payment Tax Record" other="Edit # Payment Tax Records" />
                )}
              </Button>
            </Grid.Item>
            {!allSelected && selected.length === paymentTaxes.records.length && paymentTaxes.meta.records > selected.length && (
              <Grid.Item>
                <Button
                  type="link"
                  onClick={() => {
                    setAllSelected(true);
                  }}
                >
                  <RecordCount value={paymentTaxes.meta.records} other="Select all # payment tax records" />
                </Button>
              </Grid.Item>
            )}

            {allSelected && (
              <Grid.Item>
                <Button
                  type="link"
                  onClick={() => {
                    setSelected([]);
                    setAllSelected(false);
                  }}
                >
                  Clear Selection
                </Button>
              </Grid.Item>
            )}
          </Grid>
        )}
      </TableFilters>

      <Table
        status={status}
        errors={error}
        dataSource={paymentTaxes.records}
        emptyProps={{ description: "No payment tax records found" }}
        rowSelection={{
          fixed: true,
          selectedRowKeys: selected.map((item) => item.id),
          onChange: (_, pTaxes) => {
            setSelected(pTaxes);
          },
          onSelect(record, isSelected) {
            if (!isSelected) {
              setAllSelected(false);
            }
          },
          onSelectAll(isSelected: boolean) {
            if (!isSelected) {
              setAllSelected(false);
            }
          },
        }}
        onRow={(record) => ({
          onClick(e) {
            editPaymentTax(record);
          },
        })}
        columns={
          [
            {
              key: ColumnKeys.SEARCH,
              dataIndex: "recipientId",
              width: 10,
              render: (recipientId: string) =>
                query.recipientId ? null : (
                  <a
                    role="button"
                    onClick={(e) => {
                      e?.stopPropagation?.();
                    }}
                  >
                    <Icon
                      type="search"
                      right
                      tooltip="Filter by Recipient"
                      onClick={() => {
                        setQuery({ recipientId });
                      }}
                    />
                  </a>
                ),
            },
            {
              title: "Recipient",
              key: ColumnKeys.RECIPIENT,
              dataIndex: "recipientId",
              render: (id: string) => (
                <RecipientProfile
                  recipientId={id}
                  showStatus="dot"
                  size="small"
                  showAddress
                  showLink={(e) => {
                    setPreviewRecipient(id);
                  }}
                />
              ),
            },
            {
              title: "Related Payment",
              key: ColumnKeys.PAYMENT_ID,
              align: "right",
              dataIndex: "paymentId",
              render: (id: string, pt: PaymentTax) =>
                id ? (
                  <PaymentDisplay showPreview paymentId={id} showDate />
                ) : (
                  <Text type="secondary">
                    Offline Payment
                    {pt.externalId && <Text size="small">External ID: {pt.externalId}</Text>}
                  </Text>
                ),
            },
            {
              title: "Payment Tax Amount",
              key: ColumnKeys.AMOUNT,
              dataIndex: "entered",
              align: "right",
              render: (entered: PaymentTax["entered"]) => <CurrencyDisplay value={entered.value} currency={entered.currency} />,
            },
            {
              title: "Tax Withholding (USD)",
              key: ColumnKeys.TAX_WITHHOLDING,
              dataIndex: "equivalentWithholding",
              align: "right",
              render: (equivalentWithholding: PaymentTax["equivalentWithholding"]) => (
                <CurrencyDisplay value={equivalentWithholding.value} currency={equivalentWithholding.currency} />
              ),
            },
            {
              title: <LabelTooltip type="taxPaidByWithholdingAgents" />,
              key: ColumnKeys.TAX_PAID_BY_AGENT,
              dataIndex: "taxPaidByWithholdingAgents",
              align: "right",
              render: (taxPaidByWithholdingAgent: PaymentTax["taxPaidByWithholdingAgents"]) => (
                <CurrencyDisplay value={taxPaidByWithholdingAgent} currency={CurrencyCode.USD} />
              ),
            },
            {
              title: "Tax Form",
              key: ColumnKeys.TAX_FORM,
              dataIndex: "taxFormId",
              render: (taxFormId: string | undefined) => <TaxFormDisplay taxFormId={taxFormId} showIcon />,
            },
            {
              title: "Payout Method",
              key: ColumnKeys.PAYOUT_METHOD,
              dataIndex: "payoutMethod",
              render: (pm: PaymentTax["payoutMethod"]) => <PayoutMethodDisplay value={pm} size="large" showTooltip />,
            },
            {
              key: ColumnKeys.CATEGORY,
              title: <LabelTooltip type="category" />,
              dataIndex: "category",
              render: (category: PaymentTax["category"]) => getPaymentCategoryLabel(category),
            },
            {
              title: <LabelTooltip type="taxReportable" />,
              key: ColumnKeys.TAX_REPORTABLE,
              dataIndex: "taxReportable",
              render: (taxable: boolean) => (taxable ? "Taxable" : "Exempt"),
            },
            {
              title: <LabelTooltip type="forceUsTaxActivity" />,
              key: ColumnKeys.FORCE_US_TAX,
              dataIndex: "forceUsTaxActivity",
              render: (forceUsTaxActivity: boolean) => (forceUsTaxActivity ? "US Activities" : "Off"),
            },
            { title: "Tags", key: ColumnKeys.TAGS, dataIndex: "tags", render: (tags: string[]) => <InputTags value={tags} /> },
            {
              title: "Memo",
              key: ColumnKeys.MEMO,
              dataIndex: "memo",
              render: (memo: string) => memo && <Icon type="sticky-note" size="large" tooltip={memo} />,
            },
            {
              title: (
                <Tooltip title="Record has been modified since its creation.">
                  <span>
                    Modified Since Creation
                    <Icon.Hint right />
                  </span>
                </Tooltip>
              ),
              key: ColumnKeys.MODIFIED,
              dataIndex: "modifiedSinceCreation",
              render: (modifiedSinceCreation: PaymentTax["modifiedSinceCreation"]) => (modifiedSinceCreation ? "Modified" : ""),
            },
            {
              title: "Processed On (UTC)",
              key: ColumnKeys.PROCESSED_AT,
              dataIndex: "processedAt",
              sorter: true,
              sortOrder: query.orderBy?.[0] === "processedAt" ? (query.sortBy?.[0] === "asc" ? "ascend" : "descend") : undefined,
              render: (date: string) => <DateDisplay value={date} time={false} showUtc />,
            },
            {
              title: "Created On",
              key: ColumnKeys.CREATED_AT,
              dataIndex: "createdAt",
              sorter: true,
              sortOrder: query.orderBy?.[0] === "createdAt" ? (query.sortBy?.[0] === "asc" ? "ascend" : "descend") : undefined,
              render: (date: string) => <DateDisplay value={date} />,
            },
            {
              title: "Updated On",
              key: ColumnKeys.UPDATED_AT,
              dataIndex: "updatedAt",
              sorter: true,
              sortOrder: query.orderBy?.[0] === "updatedAt" ? (query.sortBy?.[0] === "asc" ? "ascend" : "descend") : undefined,
              render: (date: string) => <DateDisplay value={date} />,
            },
          ].filter(tableColumnFilter(displayedColumns)) as TableColumnsType<PaymentTax>
        }
        onQueryChange={setQuery}
        pagination={{
          current: query.page,
          pageSize: query.pageSize,
          total: paymentTaxes.meta.records,
        }}
      />
      <PaymentTaxRecordEdit
        paymentTax={paymentTax}
        onClose={() => {
          editPaymentTax(undefined);
        }}
      />
      <RecipientPreview
        recipientId={previewRecipient}
        onClose={() => {
          setPreviewRecipient(undefined);
        }}
      />
      <PaymentTaxesBulkEdit
        visible={showBulkEdit}
        recipientId={getBulkEditRecipientId(allSelected, query, selected)}
        onUpdate={async (updates) => {
          if (updates) {
            await updateBulkPaymentTaxes(allSelected ? query : { ids: selected.map((pt) => pt.id) }, updates, query);
            setSelected([]);
            setAllSelected(false);
          }

          setShowBulkEdit(false);
        }}
      />
    </Container>
  );
}
