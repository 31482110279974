import { TFAVerificationType } from "@trolley/common-frontend";
import { Text } from "components";
import React from "react";

const TFATypeLabel: Record<TFAVerificationType, string> = {
  [TFAVerificationType.SMS]: "SMS",
  [TFAVerificationType.TOTP]: "TOTP App",
  [TFAVerificationType.WEBAUTHN]: "WebAuthn",
};

interface Props {
  type: TFAVerificationType | null;
  emptyText?: string;
}

export default function TFATypeDisplay({ type, emptyText = "" }: Props) {
  return <Text inline>{type ? TFATypeLabel[type] || type : emptyText}</Text>;
}
