import { Select as AntSelect, SelectProps as AntSelectProps } from "antd";
import Icon from "components/Icon";
import React, { ReactNode, useState } from "react";
import css, { classnames, createUseStyle } from "style/classname";

export { SelectValue } from "antd/lib/select";

export interface SelectProps<ValueType = any> extends Omit<AntSelectProps<ValueType>, "showAction" | "clearIcon"> {
  children?: ReactNode;
}

export default function Select<ValueType>(props: SelectProps<ValueType>) {
  const { className, popupClassName, ...rest } = props;
  const [open, setOpen] = useState(false);
  const styledSelect = useStyledSelect();
  const styledPopup = useStyledPopup();

  return (
    <AntSelect
      onDropdownVisibleChange={(open) => {
        setOpen(open);
      }}
      showAction={["click", "focus"]}
      suffixIcon={<Icon type={open ? (props.showSearch ? "search" : "angle-up") : "angle-down"} />}
      removeIcon={<Icon type="xmark" />}
      menuItemSelectedIcon={<Icon type="check" />}
      className={classnames(styledSelect, className)}
      popupClassName={classnames(styledPopup, popupClassName)}
      virtual={false} // this is needed for accessibility and to find the option during tests
      {...rest}
    />
  );
}

Select.Option = AntSelect.Option;
Select.OptGroup = AntSelect.OptGroup;

const useStyledSelect = createUseStyle(({ theme }) =>
  css`
    .${theme.prefixCls}-select-selector {
      box-shadow: ${theme.boxShadowInset};
    }
    .${theme.prefixCls}-select-selection-item {
      .icon {
        overflow: visible;
      }
    }
  `(),
);

const useStyledPopup = createUseStyle(({ theme }) =>
  css`
    .${theme.prefixCls}-select-item-option-content {
      .icon {
        overflow: visible; // antd defaults all option-content > * to overflow hidden
      }
    }
  `(),
);
