import { DateDisplay, Icon, Spinner, Status, Text } from "components";
import TaxWarning from "pages/RecipientsPage/Details/Tax/TaxWarning";
import React from "react";
import { getTaxFormLabel } from "store/actions/recipientTaxForms";
import { UsUpload } from "store/actions/taxForms";
import { useTaxForm } from "store/hooks/taxForms";
import { BaseStatus } from "store/reducers/standardReducer";
import { TaxFormType } from "@trolley/common-frontend";

interface Props {
  taxFormId: string | undefined | null;
  showStatus?: boolean;
  showIcon?: boolean;
  showSignedDate?: boolean;
  showWarnings?: boolean;
  emptyText?: string;
  onClick?(e?: any): void;
}

export default function TaxFormDisplay({ taxFormId, showStatus, showIcon, showWarnings, showSignedDate, onClick, emptyText }: Props) {
  const { data: taxForm, status } = useTaxForm(taxFormId);

  if (taxForm) {
    const el = (
      <span>
        {showWarnings && !!taxForm?.warnings?.length ? (
          <Icon.Status type="warning" left tooltip={<TaxWarning taxForm={taxForm} />} />
        ) : (
          showIcon && <Icon type="file-alt" left />
        )}
        {getTaxFormLabel((taxForm.taxFormType as TaxFormType) || (taxForm?.data as UsUpload)?.uploadKind)}
        {taxForm.kind === TaxFormType.US_UPLOAD && " (Uploaded Form)"}
        {showStatus && <Status type={taxForm.status} size="small" right />}
        {showSignedDate && taxForm.signedAt && (
          <Text type="secondary" size="small">
            Signed <DateDisplay value={taxForm.signedAt} time={false} icon={false} showUtc />
          </Text>
        )}
      </span>
    );

    return onClick ? (
      <a role="button" onClick={onClick}>
        {el}
      </a>
    ) : (
      el
    );
  }

  if (status === BaseStatus.LOADING) {
    return (
      <>
        {emptyText ?? taxFormId}
        <Spinner right />
      </>
    );
  }

  return <span>No Form</span>;
}
