import { useEffect, useState } from "react";
import { useShallowSelector } from ".";
import { TaxForm, loadTaxForms, TaxFormsQuery, loadTaxForm } from "store/actions/taxForms";
import { emptyList } from "store/reducers/standardReducer";
import { computeID } from "store/actions/actionUtils";

export function useTaxForms(query: TaxFormsQuery) {
  const [fetchId, setFetchId] = useState("");

  useEffect(() => {
    setFetchId(loadTaxForms(query));
  }, [computeID(query)]);

  return useShallowSelector((state) => {
    if (fetchId && state.taxForms.fetchStatus[fetchId] === undefined) {
      loadTaxForms(query);
    }

    const data = state.taxForms.entities[fetchId];

    return {
      data: data
        ? {
            records: data.records.map((id) => state.taxForm.entities[id]).filter((v) => v) as TaxForm[],
            meta: data.meta,
          }
        : emptyList,
      status: state.taxForms.fetchStatus[fetchId],
      error: state.taxForms.errors[fetchId],
    };
  });
}

export function useTaxForm(taxId: string | null | undefined) {
  const id = taxId && /^TX-\w+/.test(taxId) ? taxId : "";
  useEffect(() => {
    if (id) {
      loadTaxForm(id);
    }
  }, [id]);

  return useShallowSelector((state) => {
    if (id && state.taxForm.fetchStatus[id] === undefined) {
      loadTaxForm(id);
    }

    return {
      data: state.taxForm.entities[id],
      status: state.taxForm.fetchStatus[id],
      error: state.taxForm.errors[id],
    };
  });
}
