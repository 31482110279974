/* eslint-disable no-console */
import React from "react";
import deepEqual from "fast-deep-equal";

const isPrimitive = (val: any) => val !== Object(val);

export function checkDeps(deps: React.DependencyList, name: string) {
  const reactHookName = `React.${name.replace(/DeepCompare/, "")}`;

  if (!(deps instanceof Array) || !deps.length) {
    console.warn(`${name} should not be used with no dependencies. Use ${reactHookName} instead.`);
  }

  if (deps.every(isPrimitive)) {
    console.warn(`${name} should not be used with dependencies that are all primitive values. Use ${reactHookName} instead.`);
  }
}

export default function useDeepCompareMemoize(value: React.DependencyList) {
  const ref = React.useRef<React.DependencyList>([]);

  if (!ref.current || !deepEqual(value, ref.current)) {
    ref.current = value;
  }

  return ref.current;
}
