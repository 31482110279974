import { Result, ResultProps } from "antd";
import { ButtonLink, Container, Icon } from "components";
import React from "react";

type Props = {
  title?: string;
  subTitle?: React.ReactNode;
  extra?: ResultProps["extra"];
};

export default function PageNotFound(props: Props) {
  const {
    title = "The page vanished!",
    subTitle = "The page you visited does not exist in this world anymore.",
    extra = (
      <ButtonLink path="/" ghost type="primary">
        Return to Dashboard
      </ButtonLink>
    ),
  } = props;

  return (
    <Container padding="none">
      <Result
        icon={<Icon.Ghost />}
        title={
          <>
            <strong>BOO</strong>ps! {title}
          </>
        }
        subTitle={
          <>
            {subTitle}
            <br />
            Error Status: 404
          </>
        }
        extra={extra}
      />
    </Container>
  );
}
