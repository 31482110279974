import React, { ReactNode, createContext, useContext } from "react";
import { useMerchantSettings } from "store/hooks/merchantSettings";
import { useUser } from "store/hooks/user";

const defaultContext = {
  isTfaRequired: false,
};

const TfaContext = createContext(defaultContext);

export function useTFA() {
  return useContext(TfaContext);
}

export default function TFAProvider({ children }: { children?: ReactNode }) {
  const { data: merchantSettings } = useMerchantSettings();
  const { data: user } = useUser();

  const isTfaRequired = !!(
    (merchantSettings?.security?.requireTFA || merchantSettings?.security?.requireSCA) &&
    user?.tfaType === null &&
    !user?.isImpersonated
  );

  return <TfaContext.Provider value={{ isTfaRequired }}>{children}</TfaContext.Provider>;
}
