import { batch } from "react-redux";
import store from "store";
import { OpCode, standardDispatch } from "store/dispatcher";
import * as request from "services/request";
import * as types from "@trolley/common-frontend";
import { isLoaded } from "./actionUtils";

export { PayoutMethodType, PayoutMethodStatus } from "@trolley/common-frontend";
export interface PayoutMethod extends types.PayoutMethod.PayoutMethod {}
export type PaypalSettings = types.PayoutMethod.PaypalSettings & {
  allowRestrictedCountries: boolean | null;
  restrictedCountries: types.CountryCode[];
};
export type BankSettingsAfx = types.PayoutMethod.BankSettingsAfx;
export type BankSettingsSandbox = types.PayoutMethod.BankSettingsSandbox;
export type BankSettingsPrl = types.PayoutMethod.BankSettingsPrl;
export type CheckSettings = types.PayoutMethod.CheckSettings;
export type VenmoSettings = types.PayoutMethod.VenmoSettings & {
  allowedCountries: types.CountryCode[];
};
export function isPaypalSettings(settings: PayoutMethod["settings"]): settings is PaypalSettings {
  return settings.provider === "paypal";
}

export function isAfxSettings(settings: PayoutMethod["settings"]): settings is BankSettingsAfx {
  return settings.provider === "afx";
}

export function isSandboxSettings(settings: PayoutMethod["settings"]): settings is BankSettingsSandbox {
  return settings.provider === "sandbox";
}

export function isPrlSettings(settings: PayoutMethod["settings"]): settings is BankSettingsPrl {
  return settings.provider === "prl";
}

export function isCheckSettings(settings: PayoutMethod["settings"]): settings is CheckSettings {
  return settings.provider === "check";
}

export function isVenmoSettings(settings: PayoutMethod["settings"]): settings is VenmoSettings {
  return settings.provider === "venmo";
}

export type PayoutMethodUpdate =
  | {
      integration: types.PayoutMethodType.BANKTRANSFER;
      enabled?: boolean;
      enabledCountries?: types.CountryCode[];
    }
  | {
      integration: types.PayoutMethodType.PAYPAL;
      enabled?: boolean;
      enabledCountries?: types.CountryCode[];
      mode?: string; // sandbox or live
      apiUsername?: string;
      apiPassword?: string;
      apiSignature?: string;
      account?: string;
      clientId?: string;
      secret?: string;
      webhookID?: string;
      allowRestrictedCountries?: boolean;
    }
  | {
      integration: types.PayoutMethodType.CHECK;
      enabled?: boolean;
      enabledCountries?: types.CountryCode[];
      branchId?: string;
      accountNum?: string;
      signatory?: string;
      verification1?: string;
      verification2?: string;
      startingCheck?: string;
      mailing?: {
        name: string;
        street1: string;
        street2?: string;
        city: string;
        region: string;
        postal: string;
        country: types.CountryCode;
      };
    }
  | {
      integration: types.PayoutMethodType.BITCOIN;
      enabled?: boolean;
    }
  | {
      integration: types.PayoutMethodType.INTERAC;
      enabled?: boolean;
    }
  | {
      integration: types.PayoutMethodType.VENMO;
      enabled?: boolean;
      mode?: string; // sandbox or live
      apiUsername?: string;
      apiPassword?: string;
      apiSignature?: string;
      account?: string;
      clientId?: string;
      secret?: string;
      webhookID?: string;
      allowedCountries?: boolean;
    };

export function loadPayoutMethods(force?: boolean) {
  const data = store.getState().payoutMethods;
  if (force || !isLoaded(data.fetchStatus.data)) {
    standardDispatch(OpCode.LOADING, "payoutMethods");

    request
      .POST<types.PayoutMethod.ListResult>("/v1/payout-methods/search")
      .then(({ body }) => {
        standardDispatch(OpCode.DATA, "payoutMethods", {
          data: body.payoutMethods,
        });
      })
      .catch((errors) => {
        standardDispatch(OpCode.ERROR, "payoutMethods", {
          errors,
        });
      });
  }
}

export async function updatePayoutMethod(query: PayoutMethodUpdate) {
  standardDispatch(OpCode.LOADING, "payoutMethods");
  try {
    const { body } = await request.POST<types.PayoutMethod.Result>("/v1/payout-methods/update", {
      query,
    });
    const payoutMethods = store.getState().payoutMethods.entities.data || [];
    standardDispatch(OpCode.DATA, "payoutMethods", {
      data: payoutMethods.map((pm) => (pm.integration === query.integration ? body.payoutMethod : pm)),
    });
    batch(() => {
      standardDispatch(OpCode.RESET, "balances");
      if ("enabledCountries" in query) {
        standardDispatch(OpCode.RESET, "recipients");
        standardDispatch(OpCode.RESET, "payments");
        standardDispatch(OpCode.RESET, "batches");
      }
    });

    return body.payoutMethod;
  } catch (errors) {
    standardDispatch(OpCode.ERROR, "payoutMethods", {
      errors,
    });
    throw errors;
  }
}
