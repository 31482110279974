import { Alert, AlertProps, ExternalLink } from "components";
import dayjs from "dayjs";
import React, { useRef } from "react";
import { useIntl } from "utils/context";

interface AlertMaintenanceProps extends Omit<AlertProps, "children"> {
  applyTranslation?: boolean;
}

const VISIBLE = false;

export default function AlertMaintenance(props: AlertMaintenanceProps) {
  const { formatMessage } = useIntl();
  const {
    current: { start, end },
  } = useRef({
    start: dayjs
      .utc({
        year: 2022,
        month: 9, // october
        day: 30,
        hour: 1,
        minute: 0,
      })
      .local(),
    end: dayjs
      .utc({
        year: 2022,
        month: 9, // october
        day: 30,
        hour: 3,
        minute: 59,
      })
      .local(),
  });

  if (VISIBLE && dayjs().isBefore(end)) {
    return !!props.applyTranslation ? (
      <Alert {...props}>
        {formatMessage(
          { id: "auth.fullScreenAuth.alertMaintenance.alert" },
          {
            start: start.format("MMMM DD, h:mm a"),
            end: end.format("MMMM DD, h:mm a"),
            statusPage: (
              <ExternalLink href="https://status.trolley.com"> {formatMessage({ id: "auth.fullScreenAuth.alertMaintenance.statusPage" })}</ExternalLink>
            ),
          },
        )}
      </Alert>
    ) : (
      <Alert {...props}>
        Our website may be down for a scheduled maintenance between {start.format("MMMM DD, h:mm a")} and {end.format("MMMM DD, h:mm a")}. Visit our{" "}
        <ExternalLink href="https://status.trolley.com"> status page</ExternalLink> for more information.
      </Alert>
    );
  }

  return null;
}
