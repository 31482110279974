import React from "react";
import { Anchor as AntAnchor, AnchorProps as AntAnchorProps } from "antd";

interface AnchorProps extends AntAnchorProps {}

export default function Anchor(props: AnchorProps) {
  return <AntAnchor {...props} />;
}

Anchor.Link = AntAnchor.Link;
