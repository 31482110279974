import { Access } from "@trolley/common-frontend";
import { useEffect } from "react";
import { loadUser } from "store/actions/user";
import { useShallowSelector } from ".";

export function useUser() {
  useEffect(() => {
    loadUser();
  }, []);

  return useShallowSelector((state) => {
    if (state.user.fetchStatus.data === undefined) {
      loadUser();
    }

    return {
      data: state.user.entities.data,
      status: state.user.fetchStatus.data,
      error: state.user.errors.data,
    };
  });
}

export function useAccess(access: Access) {
  const { data: user } = useUser();

  return !!user?.access.includes(access);
}
