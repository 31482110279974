import { Badge, Tooltip, FileDownload, Icon, Text } from "components";
import config from "config";
import { LAST_YEAR, TAX_PATHS } from "pages/TaxCenter";
import { isProfileComplete, Pages } from "pages/TaxCenter/EndOfYear/USTax";
import React, { ReactNode } from "react";
import { Link } from "react-router-dom";
import { EoyTax } from "store/actions/eoyTaxForms";
import { getTaxFormLabel } from "store/actions/recipientTaxForms";
import { useMerchantTaxYear } from "store/hooks/taxMerchantYear";
import { EndOfYearFormType, EoyTaxReportStatus } from "@trolley/common-frontend";

interface Props {
  eoyTax: EoyTax;
}
export default function EoyTaxFormTypeDisplay(props: Props) {
  const { eoyTax } = props;
  const { data: taxMerchantYear } = useMerchantTaxYear(eoyTax && eoyTax.taxYear < LAST_YEAR ? undefined : eoyTax.taxYear);

  if (!eoyTax) {
    return null;
  }

  const { formType } = eoyTax;
  const isComplete =
    eoyTax.taxYear < LAST_YEAR || // everything before last tax year can be shown.
    isProfileComplete(taxMerchantYear, formType);
  const taxFormLabel = getTaxFormLabel(formType);
  const downloadIcon = formType !== EndOfYearFormType.NONE &&
    (config.ENV !== "production" || eoyTax.status === EoyTaxReportStatus.SENT) && ( // on production, only show icon if sent
      <span>
        <FileDownload
          url="/v1/tax-year/eoy-form/single-pdf"
          method="POST"
          query={{ id: eoyTax.id, taxYear: eoyTax.taxYear }}
          fileName={`PR_eoyTaxForm_${eoyTax.id}`}
          defaultExtension="pdf"
          icon
          disabled={!isComplete}
          right
        />
      </span>
    );
  let tooltipTitle: ReactNode = "Download Statement";
  if (!isComplete) {
    tooltipTitle = (
      <>
        Tax Statement is missing information.
        <br />
        Please complete <Link to={`${TAX_PATHS.EOY_REPORTING}/${eoyTax.taxYear}/${Pages.PROFILE}`}>the business tax profile </Link>
        related to Tax Statement {taxFormLabel}.
      </>
    );
  } else if (eoyTax.status === EoyTaxReportStatus.VOID) {
    tooltipTitle = "Download Voided Statement";
  } else if (eoyTax.correction > 0) {
    tooltipTitle = (
      <>
        Corrected statement #{eoyTax.correction}.
        <Text type="secondary" size="small">
          A Corrected Statement is issued when a statement has been updated (i.e. corrected) and re-issued to the recipient. This may happen multiple times to
          the same recipient’s statement. The number indicates which version of the corrected statement this is.
        </Text>
      </>
    );
  }

  return (
    <span>
      {taxFormLabel}
      {downloadIcon && (
        <Tooltip
          title={
            tooltipTitle && (
              <>
                {tooltipTitle}
                {config.ENV !== "production" && (
                  <Text type="warning" size="small">
                    <Icon.Status type="warning" left size="small" />
                    On production, only available if SENT
                  </Text>
                )}
              </>
            )
          }
        >
          {eoyTax.correction > 0 ? <Badge dot>{downloadIcon}</Badge> : downloadIcon}
        </Tooltip>
      )}
      {eoyTax.status === EoyTaxReportStatus.VOID && (
        <Text size="small" type="secondary">
          Voided
        </Text>
      )}
    </span>
  );
}
