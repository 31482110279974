import { Button, Divider, Form, Heading, Icon, Input, Text } from "components";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { authForgot } from "store/actions/auth";
import { useShallowSelector } from "store/hooks";
import { BaseStatus } from "store/reducers/standardReducer";
import FullscreenAuth from "./FullscreenAuth";
import { useIntl } from "utils/context";
import { useLocale } from "store/hooks/locale";
import { getPublicPath, PublicModulesEnum } from "pages/LoginNotRequired";

type FormFields = { email: string };

export default function RequestResetPassword() {
  const { formatMessage } = useIntl();
  const locale = useLocale();
  const userStatus = useShallowSelector((state) => state.user.fetchStatus.data);
  const [emailSent, setEmailSent] = useState<undefined | string>();

  async function onFinish(res: FormFields) {
    try {
      await authForgot(res.email);
    } catch {
      // Don't display errors. It should not indicate whether our system contains that email
    }

    setEmailSent(res.email);
  }

  return (
    <FullscreenAuth size={emailSent ? "large" : undefined}>
      {emailSent ? (
        <>
          <Text align="center" style={{ margin: "24px" }}>
            <Icon type="envelope-circle-check" theme="solid" size="4x" color="green" />
          </Text>
          <Text align="center" size="large">
            {formatMessage({ id: "auth.password.request.emailSent" }, { emailSent })}
          </Text>
        </>
      ) : (
        <>
          <Heading>{formatMessage({ id: "auth.password.request.title" })}</Heading>
          <Form onFinish={onFinish}>
            <Form.Item
              label={formatMessage({ id: "auth.password.request.enterEmail" })}
              name="email"
              rules={[
                { required: true, message: formatMessage({ id: "auth.password.request.emailRequired" }) },
                { type: "email", message: formatMessage({ id: "auth.password.request.emailAddressInvalid" }) },
              ]}
            >
              <Input autoComplete="off" name="email" />
            </Form.Item>

            <Divider transparent margin="small" />
            <Button type="primary" htmlType="submit" block size="large" loading={userStatus === BaseStatus.LOADING}>
              {formatMessage({ id: "auth.password.request.resetPassword" })}
            </Button>
          </Form>
        </>
      )}
      <Divider transparent margin="small" />
      <Text align="center">
        <Link to={getPublicPath(PublicModulesEnum.Login, locale)}>
          <Icon type="angle-left" left />
          {emailSent ? formatMessage({ id: "auth.password.request.backToLogin" }) : formatMessage({ id: "auth.password.request.back" })}
        </Link>
      </Text>
    </FullscreenAuth>
  );
}
