import { RecipientStatus } from "@trolley/common-frontend";
import { Status, StatusProps, TooltipProps } from "components";
import { TicketAlert } from "features/ticket";
import { hasRemainingTicketTasks } from "features/ticket/TicketAlert";
import React from "react";
import { StoreRecipient } from "store/actions/recipients";
import { useRecipientTickets } from "store/hooks/tickets";

type Props = Omit<StatusProps, "type" | "tooltipProps"> & {
  recipient: StoreRecipient;
  showTicket?: boolean;
};

export default function StatusRecipient(props: Props) {
  const { recipient, showTicket, ...rest } = props;
  const recipientTickets = useRecipientTickets(recipient.id);

  const { status } = recipient;

  let tooltip: TooltipProps | undefined;

  if (!props.dot) {
    if (status === RecipientStatus.SUSPENDED) {
      tooltip = { title: "Suspended recipient is not payable. Contact support if you need to unsuspend a recipient." };
    } else if (status === RecipientStatus.BLOCKED) {
      tooltip = { title: "Blocked recipient is not payable." };
    } else if (showTicket && hasRemainingTicketTasks(recipientTickets)) {
      tooltip = { title: <TicketAlert relatedItemId={recipient.id} />, placement: "topLeft" };
    }
  }

  return <Status {...rest} tooltipProps={tooltip} type={status} />;
}
