import { Progress, Upload as AntUpload } from "antd";
import { DraggerProps } from "antd/lib/upload";
import { Box, Button, Container, Grid, Icon, Spinner, Text } from "components";
import React, { useEffect, useRef, useState } from "react";
import styled, { createUseStyle } from "style/classname";
import palette from "style/palette";
import LogoLoader from "./LogoLoader";

export interface UploadProps extends Omit<DraggerProps, "headers" | "action" | "showUploadList" | "iconRender"> {
  loading?: boolean;
  mimeTypes?: string[];
}

export default function UploadDragger(props: UploadProps) {
  const { loading, disabled, mimeTypes, onRemove, onDownload, onPreview, multiple, ...rest } = props;
  const styledDragger = useStyledDragger(props);

  return (
    <AntUpload.Dragger
      className={styledDragger}
      disabled={disabled || loading}
      accept={mimeTypes?.join(",")}
      showUploadList={{
        removeIcon(file) {
          return (file?.url || multiple) && (!file.status || file.status === "error") ? (
            <Icon type="trash" color="red" tooltip="Remove File" theme="solid" />
          ) : null;
        },
      }}
      itemRender={(originNode, file, fileList) => {
        return (
          <>
            <Box
              flat
              padding="none"
              color={file.status === "error" ? "red" : file.status === "uploading" ? "blue" : file.status === "success" ? "green" : "white"}
              style={{ marginTop: "8px", padding: "8px 16px", marginBottom: file.status === "error" ? "4px" : "unset" }}
            >
              <Grid wrap={false} padding={["small", "none"]} align="middle">
                {(onPreview || onDownload) && (
                  <Grid.Item>
                    {file.status === "uploading" ? (
                      <Spinner />
                    ) : (
                      <Icon
                        type="cloud-arrow-down"
                        tooltip="Download"
                        onClick={(e) => {
                          (onPreview || onDownload)?.(file);
                        }}
                      />
                    )}
                  </Grid.Item>
                )}
                <Grid.Item flex={1}>{file.name}</Grid.Item>
                {(file?.url || multiple) && (!file.status || file.status === "error") && (
                  <Grid.Item>
                    <Button
                      size="small"
                      type="text"
                      danger
                      onClick={async () => {
                        await onRemove?.(file);
                      }}
                      icon={<Icon type="trash" size="xsmall" color="red" theme="solid" style={{ verticalAlign: "inherit " }} />}
                    />
                  </Grid.Item>
                )}
                {file?.status === "success" && (
                  <Grid.Item>
                    <Icon type="check" size="xsmall" color="green" theme="solid" style={{ verticalAlign: "inherit " }} />
                  </Grid.Item>
                )}
              </Grid>

              {file.status === "uploading" && <FakeProgressBar />}
            </Box>
            {file?.error && <Text type="error">{file?.error}</Text>}
          </>
        );
      }}
      multiple={multiple}
      {...rest}
    >
      <LogoLoader spinning={!!loading}>
        <Container align="center" style={{ cursor: disabled ? "not-allowed" : "pointer" }}>
          <Icon type="cloud-arrow-up" size="4x" color={disabled ? "grey" : "blue"} theme="duotone" style={{ marginBottom: "8px", opacity: 0.5 }} />
          <Text type="secondary">Drag file here</Text>
          <Text type="secondary">or</Text>
          <Text type="secondary">Click here to browse</Text>
        </Container>
      </LogoLoader>
    </AntUpload.Dragger>
  );
}

function FakeProgressBar() {
  const [progress, setProgress] = useState(0);
  const timer = useRef<number | undefined>();

  useEffect(() => {
    timer.current = window.setInterval(() => {
      setProgress((current) => {
        const initialSpeed = current >= 60;
        const almostDone = current >= 80;
        const newProgress = current + (initialSpeed ? (almostDone ? 1 : 2) : 10);

        if (newProgress >= 98) {
          return 98;
        }

        return newProgress;
      });
    }, 100);

    return () => {
      if (typeof timer.current === "number") {
        window.clearInterval(timer.current);
        timer.current = undefined;
      }
    };
  }, []);

  return <Progress status="active" showInfo={false} size="small" strokeWidth={2} percent={progress} />;
}

export const useStyledDragger = createUseStyle<UploadProps>(({ theme, ...props }) =>
  styled`
    margin-bottom: 4px;
    &.${theme.prefixCls}-upload.${theme.prefixCls}-upload-drag {
      background-color: ${palette["primary-alpha-1"]};
      &.${theme.prefixCls}-upload-disabled {
        background-color: ${palette["grey-alpha-2"]};
        opacity: 0.8;
      }
    }

    & + .${theme.prefixCls}-upload-list {
      .${theme.prefixCls}-upload-list-item {
        height: 24px;
        line-height: 24px;
        transition: all 175ms ease;
        margin: 0;
        &:hover {
          .${theme.prefixCls}-upload-list-item-info {
            background-color: transparent;
          }
        }
      }
      .${theme.prefixCls}-upload-list-item-card-actions {
        .anticon {
          color: ${theme.red};
        }
        &:hover .anticon {
          color: ${theme.colorErrorHover};
        }
      }

    }
  `(),
);
