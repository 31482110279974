export const PUBLIC_ERROR_LABELS: { [key: string]: string } = {
  "Please review and acccept the terms of use.": "auth.api.common.terms",
  "Value is invalid": "auth.api.common.terms",
  "Merchant account is disabled": "auth.api.login.disabled",
  "Email or password is incorrect": "auth.api.login.email",
  "Your IP Address is not authorized to access this account": "auth.api.login.invalid_api_error",
  "Company Name missing.": "auth.api.register.name",
  "Email contains an invalid character": "auth.api.register.email",
  "Email already exists": "auth.api.register.email_exists",
  "Not a valid Phone number": "auth.api.register.phone",
  "determinePrimaryCurrency: Country must be provided": "auth.api.register.primaryCurrency",
};
