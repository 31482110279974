import store from "store";
import { authToken } from "utils/helpers";

export const SESSION_ACTIVE = "session/ACTIVE";
export const SESSION_IDLE = "session/IDLE";
export const SESSION_NEW = "session/NEW"; // for switching between merchants/sandbox. It will cancel all requests that are in flight
export const SESSION_LOGOUT = "session/LOGOUT";
export const SESSION_EXPIRED = "session/EXPIRED";

export function startSession() {
  store.dispatch({ type: SESSION_ACTIVE });
}

export function startIdling() {
  store.dispatch({ type: SESSION_IDLE });
}

export function endSession(reason: "logout" | "expire") {
  authToken.clear();
  store.dispatch({ type: reason === "logout" ? SESSION_LOGOUT : SESSION_EXPIRED });
}
