import { Access, CurrencyCode, getPayoutMethodLabel, PaymentCategory } from "@trolley/common-frontend";
import {
  Button,
  ButtonDelete,
  ButtonDropdown,
  ColumnsDisplay,
  CurrencyDisplay,
  DateDisplay,
  Divider,
  Grid,
  Heading,
  Icon,
  LabelTooltip,
  Menu,
  MixedColumn,
  RecordCount,
  Table,
  tableColumnFilter,
  TableColumnsType,
  TableFilters,
  TableName,
  Text,
  useColumnsDisplay,
} from "components";
import { AddOfflinePayments, PaymentTaxRecordEdit } from "features/paymentTax";
import { useRecipientEditable } from "features/recipient";
import { TaxFormDisplay } from "features/taxForm";
import PaymentTaxesBulkEdit from "pages/TaxCenter/PaymentTaxesBulkEdit";
import React, { useEffect, useState } from "react";
import { MerchantSettings } from "store/actions/merchantSettings";
import { notifyError, notifySuccess } from "store/actions/notifications";
import { deletePaymentTaxes, OfflinePaymentsQuery } from "store/actions/offlinePayments";
import { PaymentTax, updateBulkPaymentTaxes } from "store/actions/paymentTaxes";
import { useMerchantSettings } from "store/hooks/merchantSettings";
import { useOfflinePayments } from "store/hooks/offlinePayments";
import { useAccess } from "store/hooks/user";
import { FORCE_US_TAX_CATEGORIES } from "utils/constants";
import { getPaymentCategoryLabel } from "utils/helpers";
import { useWindowSize } from "utils/hooks";

export const DEFAULT_QUERY: OfflinePaymentsQuery = {
  page: 1,
  pageSize: 10,
  sortBy: ["desc"],
  orderBy: ["createdAt"],
};

interface Props {
  recipientId: string;
}

enum ColumnKeys {
  EXTERNAL_ID = "externalId",
  AMOUNT = "amount",
  TAX_WITHHOLDING = "equivalentWithholdingAmount",
  TAX_PAID_BY_AGENT = "taxPaidByWithholdingAgents",
  CATEGORY = "category",
  TAX_REPORTABLE = "taxReportable",
  FORCE_US_TAX = "forceUsTaxActivity",
  PROCESSED_AT = "processedAt",
  MEMO = "memo",
  TAX_FORM = "taxForm",
  CREATED_AT = "createdAt",
  UPDATED_AT = "updatedAt",
  PAYOUT_METHOD = "payoutMethod",
}

const defaultColumnsDisplay: Record<ColumnKeys, MixedColumn> = {
  [ColumnKeys.AMOUNT]: true,
  [ColumnKeys.TAX_WITHHOLDING]: true,
  [ColumnKeys.EXTERNAL_ID]: {
    label: "External ID",
    value: true,
  },
  [ColumnKeys.TAX_PAID_BY_AGENT]: {
    label: "Tax Paid By Withholding Agent",
    value: false,
  },
  [ColumnKeys.CATEGORY]: {
    label: "Payment Purpose",
    value: true,
  },
  [ColumnKeys.TAX_REPORTABLE]: {
    label: "Taxable",
    value: true,
  },
  [ColumnKeys.FORCE_US_TAX]: {
    label: "Force US Activity",
    value: false,
  },
  [ColumnKeys.PAYOUT_METHOD]: {
    label: "Payout Method",
    value: true,
  },
  [ColumnKeys.MEMO]: {
    label: "Memo",
    value: true,
  },
  [ColumnKeys.TAX_FORM]: {
    label: "Tax Form",
    value: false,
  },
  [ColumnKeys.PROCESSED_AT]: {
    label: "Processed On",
    value: true,
  },
  [ColumnKeys.CREATED_AT]: {
    label: "Created On",
    value: true,
  },
  [ColumnKeys.UPDATED_AT]: {
    label: "Updated On",
    value: true,
  },
};

export function getExcludedKeysForDisplayColumnMenu(merchantSettings: MerchantSettings | undefined) {
  const excludeColumns: string[] = [];
  const enabledCategories = Object.entries(merchantSettings?.payment?.categories || {})
    .filter(([c, v]) => v)
    .map(([c, _]) => c as PaymentCategory);

  if (!enabledCategories.some((cat) => FORCE_US_TAX_CATEGORIES.includes(cat))) {
    excludeColumns.push(ColumnKeys.FORCE_US_TAX);
  }
  if (!merchantSettings?.tax?.enabledTaxPaidByWithholdingAgents) {
    excludeColumns.push(ColumnKeys.TAX_PAID_BY_AGENT);
  }

  return excludeColumns;
}

export default function RecipientOfflinePayments(props: Props) {
  const { recipientId } = props;
  const { isMobile } = useWindowSize();
  const [query, setQueryState] = useState({ ...DEFAULT_QUERY, recipientId });
  const accessPaymentWrite = useAccess(Access.PAYMENTS_WRITE);
  const profileEditable = useRecipientEditable();
  const { data: merchantSettings } = useMerchantSettings();
  const [showUpload, setShowUpload] = useState(false);
  const [allSelected, setAllSelected] = useState(false);
  const [showBulkEdit, setShowBulkEdit] = useState(false);
  const [editPaymentTax, setEditPaymentTax] = useState<(Partial<PaymentTax> & { recipientId: string }) | undefined>();
  const [selectedPayments, setSelectedPayments] = useState<PaymentTax[]>([]);
  const { data: offlinePayments, status: offlinePaymentsStatus, error: offlinePaymentsErrors } = useOfflinePayments(query);
  const [displayedColumns, setDisplayedColumns] = useColumnsDisplay(
    TableName.RECIPIENT_OFFLINE_PAYMENTS,
    defaultColumnsDisplay,
    getExcludedKeysForDisplayColumnMenu(merchantSettings),
  );

  useEffect(() => {
    setSelectedPayments([]);
    setAllSelected(false);
  }, [query]);

  async function onDeleteOfflinePayments() {
    if (selectedPayments.length > 0) {
      try {
        await deletePaymentTaxes(
          recipientId,
          selectedPayments.map((op) => op.id),
        );
        notifySuccess("Offline payments deleted");
        setSelectedPayments([]);
      } catch (errors) {
        notifyError("Deleting offline payments failed");
      }
    }
  }

  return (
    <>
      <Divider transparent margin="medium" />
      <Heading
        tag="h2"
        extraActions={
          profileEditable &&
          accessPaymentWrite && (
            <ButtonDropdown
              type="primary"
              onClick={() => {
                setEditPaymentTax({ recipientId });
              }}
              overlay={
                <Menu
                  onClick={async (params) => {
                    const status = params.key as "manual" | "upload";
                    switch (status) {
                      case "manual":
                        setEditPaymentTax({ recipientId });
                        break;
                      case "upload":
                        setShowUpload(true);
                        break;
                    }
                  }}
                >
                  <Menu.Item key="manual">
                    <Icon type="plus" left />
                    Add Offline Payment
                  </Menu.Item>
                  <Menu.Item key="upload">
                    <Icon type="cloud-arrow-up" left /> Upload CSV file
                  </Menu.Item>
                </Menu>
              }
              style={{ width: isMobile ? "100%" : "auto" }}
            >
              <Icon type="plus" left />
              Add Offline Payment
            </ButtonDropdown>
          )
        }
      >
        <RecordCount prefix="Showing" value={offlinePayments.meta.records} one="1 offline payment" other="# offline payments" />
      </Heading>

      <TableFilters
        showSearch
        onChange={(updates) => {
          setQueryState((state) => ({
            ...state,
            page: 1,
            ...updates,
          }));
        }}
        query={query}
        suffix={<ColumnsDisplay columns={displayedColumns} onChange={setDisplayedColumns} />}
      >
        {selectedPayments.length > 0 && (
          <Grid padding="small">
            <Grid.Item>
              <Button
                type="primary"
                onClick={() => {
                  setShowBulkEdit(true);
                }}
              >
                {allSelected ? (
                  <RecordCount value={offlinePayments.meta.records} other="Edit All # Offline Payments" />
                ) : (
                  <RecordCount value={selectedPayments.length} one="Edit # Offline Payment" other="Edit # Offline Payments" />
                )}
              </Button>
            </Grid.Item>

            {!allSelected && (
              <Grid.Item>
                <ButtonDelete title="Delete the selected offline payments?" onConfirm={onDeleteOfflinePayments}>
                  Delete
                </ButtonDelete>
              </Grid.Item>
            )}

            {!allSelected && selectedPayments.length === offlinePayments.records.length && offlinePayments.meta.records > selectedPayments.length && (
              <Grid.Item>
                <Button
                  type="link"
                  onClick={() => {
                    setAllSelected(true);
                  }}
                >
                  <RecordCount value={offlinePayments.meta.records} other="Select all # offline payments" />
                </Button>
              </Grid.Item>
            )}

            {allSelected && (
              <Grid.Item>
                <Button
                  type="link"
                  onClick={() => {
                    setSelectedPayments([]);
                    setAllSelected(false);
                  }}
                >
                  Clear Selection
                </Button>
              </Grid.Item>
            )}
          </Grid>
        )}
      </TableFilters>

      <Table<PaymentTax>
        status={offlinePaymentsStatus}
        errors={offlinePaymentsErrors}
        columns={
          [
            { title: "External ID", key: ColumnKeys.EXTERNAL_ID, dataIndex: "externalId" },
            {
              title: "Payment Amount",
              key: ColumnKeys.AMOUNT,
              dataIndex: "entered",
              align: "right",
              render: (entered: PaymentTax["entered"]) => <CurrencyDisplay value={entered.value} currency={entered.currency} />,
            },
            {
              title: "Tax Withholding (USD)",
              key: ColumnKeys.TAX_WITHHOLDING,
              dataIndex: "equivalentWithholding",
              align: "right",
              render: (equivalentWithholding: PaymentTax["equivalentWithholding"]) => (
                <CurrencyDisplay value={equivalentWithholding.value} currency={equivalentWithholding.currency} />
              ),
            },
            {
              title: <LabelTooltip type="taxPaidByWithholdingAgents" />,
              key: ColumnKeys.TAX_PAID_BY_AGENT,
              dataIndex: "taxPaidByWithholdingAgents",
              align: "right",
              render: (taxPaidByWithholdingAgent: PaymentTax["taxPaidByWithholdingAgents"]) => (
                <CurrencyDisplay value={taxPaidByWithholdingAgent} currency={CurrencyCode.USD} />
              ),
            },
            {
              key: ColumnKeys.CATEGORY,
              title: <LabelTooltip type="category" />,
              dataIndex: "category",
              render: (category: PaymentTax["category"]) => getPaymentCategoryLabel(category),
            },
            {
              title: <LabelTooltip type="taxReportable" />,
              key: ColumnKeys.TAX_REPORTABLE,
              dataIndex: "taxReportable",
              render: (taxable: boolean, op: PaymentTax) => (
                <Text capitalize wrap={false}>
                  {taxable ? "Taxable" : "Exempt"}
                </Text>
              ),
            },
            {
              title: <LabelTooltip type="forceUsTaxActivity" />,
              key: ColumnKeys.FORCE_US_TAX,
              dataIndex: "forceUsTaxActivity",
              render: (forceUsTaxActivity: boolean) => (forceUsTaxActivity ? "US Activities" : "Off"),
            },
            {
              title: "Payout Method",
              key: ColumnKeys.PAYOUT_METHOD,
              dataIndex: "payoutMethod",
              render: (pm: string) => <Text wrap={false}>{getPayoutMethodLabel(pm, true)}</Text>,
            },
            { title: "Memo", key: ColumnKeys.MEMO, dataIndex: "memo" },
            {
              key: ColumnKeys.TAX_FORM,
              title: "Tax Form",
              dataIndex: "taxFormId",
              render: (taxFormId: PaymentTax["taxFormId"]) => {
                return <TaxFormDisplay taxFormId={taxFormId} showIcon showSignedDate />;
              },
            },

            {
              title: "Processed On (UTC)",
              key: ColumnKeys.PROCESSED_AT,
              dataIndex: "processedAt",
              render: (date: string) => <DateDisplay value={date} showUtc time={false} />,
            },
            {
              title: "Created On",
              key: ColumnKeys.CREATED_AT,
              dataIndex: "createdAt",
              sorter: true,
              sortOrder: query.orderBy?.[0] === "createdAt" ? (query.sortBy?.[0] === "asc" ? "ascend" : "descend") : undefined,
              render: (date: string) => <DateDisplay value={date} />,
            },
            {
              title: "Updated On",
              key: ColumnKeys.UPDATED_AT,
              dataIndex: "updatedAt",
              sorter: true,
              sortOrder: query.orderBy?.[0] === "updatedAt" ? (query.sortBy?.[0] === "asc" ? "ascend" : "descend") : undefined,
              render: (date: string) => <DateDisplay value={date} />,
            },
          ].filter(tableColumnFilter(displayedColumns)) as TableColumnsType<PaymentTax>
        }
        emptyProps={{ description: "No offline payments found" }}
        dataSource={offlinePayments.records}
        pagination={{
          current: query.page,
          pageSize: query.pageSize,
          total: offlinePayments.meta.records,
        }}
        onRow={(record: PaymentTax) => ({
          onClick: () => {
            setEditPaymentTax(record);
          },
        })}
        rowSelection={
          accessPaymentWrite
            ? {
                fixed: true,
                onChange: (keys: string[], selectedRows: PaymentTax[] = []) => {
                  setSelectedPayments(selectedRows);
                },
                selectedRowKeys: selectedPayments.map((r) => r.id),
                onSelect(record, selected) {
                  if (!selected) {
                    setAllSelected(false);
                  }
                },
                onSelectAll(selected: boolean) {
                  if (!selected) {
                    setAllSelected(false);
                  }
                },
              }
            : undefined
        }
        onQueryChange={(update) => {
          setQueryState((state) => ({
            ...state,
            page: 1,
            ...update,
          }));
        }}
      />

      <PaymentTaxRecordEdit
        paymentTax={editPaymentTax}
        onClose={() => {
          setEditPaymentTax(undefined);
        }}
      />
      <AddOfflinePayments
        onClose={() => {
          setShowUpload(false);
        }}
        visible={showUpload}
      />

      <PaymentTaxesBulkEdit
        visible={showBulkEdit}
        recipientId={recipientId}
        onUpdate={async (updates) => {
          if (updates) {
            await updateBulkPaymentTaxes(allSelected ? { ...query, recipientId } : { ids: selectedPayments.map((pt) => pt.id) }, updates, query);
            setSelectedPayments([]);
            setAllSelected(false);
          }

          setShowBulkEdit(false);
        }}
      />
    </>
  );
}
