import { useEffect, useState } from "react";
import { loadEoySummary } from "store/actions/eoySummary";
import { useShallowSelector } from ".";
import { computeID } from "../actions/actionUtils";
import { EoyTaxQuery } from "../actions/eoyTaxForms";

export function useEoySummary(queries: EoyTaxQuery[]) {
  const [fetchId, setFetchId] = useState("");

  useEffect(() => {
    setFetchId(loadEoySummary(queries));
  }, [computeID(queries)]);

  return useShallowSelector((state) => {
    if (fetchId && state.eoySummary.fetchStatus[fetchId] === undefined) {
      loadEoySummary(queries);
    }

    return {
      data: state.eoySummary.entities[fetchId] || [],
      status: state.eoySummary.fetchStatus[fetchId],
      error: state.eoySummary.errors[fetchId],
    };
  });
}
