import {
  addRegionCountriesTo,
  BANNED_BUSINESS_CATEGORIES,
  BusinessCategory,
  CountryCode,
  formatCountry,
  removeRegionCountriesFrom,
  SUPPORTED_PRIMARY_CURRENCIES,
  WorldRegions,
  WORLD_REGIONS,
} from "@trolley/common-frontend";
import { Checkbox, Flag, Form, Grid, Input, InputNumber, Select, Text } from "components";
import React from "react";
import { Onboarding } from "store/actions/onboarding";
import { useOnboarding } from "store/hooks/onboarding";
import { useUser } from "store/hooks/user";
import { isEmpty } from "utils/helpers";
import { FormNames, onFinishFailedOnboarding, onFinishOnboarding } from ".";

import OnboardingSection from "./OnboardingSection";

export function getPayoutInformationCompletion(onboarding: Onboarding | undefined): null | "partial" | "completed" {
  if (!onboarding) {
    return null;
  }

  const requiredFields: (keyof Onboarding)[] = [
    // "primaryCurrency", // it's always set
    "businessCategory",
    "businessPpm",
    "businessTotalMonthly",
    "businessIntlPercentage",
    "expectedPayoutCountries",
    "businessPurpose",
  ];

  if (
    requiredFields.every((field) => {
      return isEmpty(onboarding[field]);
    })
  ) {
    return null;
  }

  if (requiredFields.every((field) => !isEmpty(onboarding[field]))) {
    return "completed";
  }

  return "partial";
}

export default function PayoutInformation() {
  const { data: user } = useUser();
  const [form] = Form.useForm();
  const { data: onboarding, status: onboardingStatus } = useOnboarding();
  const expectedPayoutCountries = Form.useWatch("expectedPayoutCountries", form) || [];

  return (
    <OnboardingSection id={FormNames.PAYOUT_INFORMATION} title="How do you plan to use Trolley?">
      <Form
        name={FormNames.PAYOUT_INFORMATION}
        form={form}
        initialValues={onboarding}
        onFinish={onFinishOnboarding(onboarding, form)}
        onFinishFailed={onFinishFailedOnboarding(onboarding, form)}
      >
        <Grid padding={["medium", "none"]}>
          <Grid.Item xs={24} lg={12}>
            <Form.Item label="Primary Currency" name="primaryCurrency" rules={[{ required: true, message: "Primary currency is required" }]}>
              <Select showSearch placeholder="Select a currency" optionFilterProp="children">
                {SUPPORTED_PRIMARY_CURRENCIES.map((currency) => (
                  <Select.Option key={currency} value={currency}>
                    <Flag code={currency} />
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Grid.Item>
          <Grid.Item xs={24} lg={12}>
            <Form.Item
              label="Business Category"
              name="businessCategory"
              rules={[{ required: true, message: "Category is required" }]}
              tooltip={
                !user?.isImpersonated &&
                !!onboarding?.completedAt &&
                !!onboarding.businessCategory &&
                BANNED_BUSINESS_CATEGORIES.includes(onboarding.businessCategory as BusinessCategory)
                  ? "Unfortunately, Trolley does not support this industry category, and we will be unable to provide you business services. If you have accidentally selected the incorrect business category, please reach out to our support to assist you."
                  : "Industries are subject to review prior to account approval. Not all industries in this list are supported by Trolley."
              }
            >
              <Select
                showSearch
                disabled={
                  !user?.isImpersonated &&
                  !!onboarding?.completedAt &&
                  !!onboarding.businessCategory &&
                  BANNED_BUSINESS_CATEGORIES.includes(onboarding.businessCategory as BusinessCategory)
                }
                placeholder="Select a business category"
                optionFilterProp="children"
              >
                {Object.entries(BUSINESS_CATEGORY).map(([value, label]) => (
                  <Select.Option key={value} value={value}>
                    {label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Grid.Item>

          <Grid.Item xs={24}>
            <Form.Item
              label="How many individual payouts do expect to send each month?"
              name="businessPpm"
              rules={[{ required: true, message: "Expected number of payments is required" }]}
            >
              <InputNumber inputMode="decimal" maxLength={100} />
            </Form.Item>
          </Grid.Item>

          <Grid.Item xs={24}>
            <Form.Item
              label="What is total volume of monthly payments you expect to send?"
              name="businessTotalMonthly"
              rules={[{ required: true, message: "Estimated monthly payment volume is required" }]}
            >
              <InputNumber inputMode="decimal" maxLength={100} addonAfter="USD" />
            </Form.Item>
          </Grid.Item>

          <Grid.Item xs={24}>
            <Form.Item
              label="What percentage (%) of the above payment volume will be sent internationally?"
              name="businessIntlPercentage"
              rules={[
                { required: true, message: "A percentage is required" },
                {
                  async validator(rule, value: string) {
                    const percent = Number(value);
                    if (value && !Number.isNaN(percent)) {
                      if (percent < 0 || percent > 100) {
                        throw "Enter a number between 0 and 100";
                      }
                    }
                  },
                },
              ]}
            >
              <InputNumber inputMode="decimal" name="businessIntlPercentage" addonAfter="%" />
            </Form.Item>
          </Grid.Item>

          <Grid.Item xs={24}>
            <Form.Item
              label="Which countries will you send payments to over the next 12 months?"
              tooltip={
                <>
                  This is to set a baseline list of countries for your account. You will still be able to make payments to other countries not selected here.
                  Note: unnecessarily selecting many/all countries may result in additional account review during the onboarding process. We therefore kindly
                  suggest that you answer this question as accurately as possible.
                </>
              }
              name="expectedPayoutCountries"
              rules={[{ required: true, message: "Expected payout countries is required" }]}
              normalize={(countries: CountryCode[]) => countries?.filter((v) => v) ?? []}
            >
              <Select showSearch mode="multiple" optionFilterProp="title" showArrow allowClear maxTagCount={5}>
                {Object.values(WorldRegions).map((regionCode) => {
                  const worldRegion = WORLD_REGIONS[regionCode];
                  const enabledCountries = worldRegion.countries.filter((c) => expectedPayoutCountries.includes(c));
                  const isRegionSelected = WORLD_REGIONS[regionCode].countries.every((c) => expectedPayoutCountries.includes(c));
                  const isPartiallySelected = !isRegionSelected && WORLD_REGIONS[regionCode].countries.some((c) => expectedPayoutCountries.includes(c));

                  return (
                    <Select.OptGroup
                      key={regionCode}
                      label={
                        <Checkbox
                          checked={isRegionSelected}
                          indeterminate={isPartiallySelected}
                          onChange={(e) => {
                            if (worldRegion.countries.every((c) => expectedPayoutCountries.includes(c))) {
                              form.setFieldsValue({ expectedPayoutCountries: removeRegionCountriesFrom(regionCode, expectedPayoutCountries) });
                            } else {
                              form.setFieldsValue({ expectedPayoutCountries: addRegionCountriesTo(regionCode, expectedPayoutCountries) });
                            }
                          }}
                        >
                          {worldRegion.label} ({enabledCountries.length} / {worldRegion.countries.length})
                        </Checkbox>
                      }
                    >
                      {worldRegion.countries.map((countryCode) => (
                        <Select.Option key={countryCode} value={countryCode} title={`${countryCode} ${formatCountry(countryCode)}`}>
                          <Flag code={countryCode} />
                        </Select.Option>
                      ))}
                    </Select.OptGroup>
                  );
                })}
              </Select>
            </Form.Item>
          </Grid.Item>
        </Grid>

        <Text type="label">What is the purpose of your payments? Who will you be sending payments to?</Text>
        <Text type="secondary">Please provide as much detail as possible, as this form is used to validate your eligibility to use banking transfers.</Text>
        <Form.Item name="businessPurpose" rules={[{ required: true, message: "Purpose of payments is required" }]}>
          <Input.TextArea rows={4} name="businessPurpose" />
        </Form.Item>

        <Form.SubmitButtons status={onboardingStatus} saveText={onboarding?.completedAt ? "Save" : "Save & Continue"} allowReset />
      </Form>
    </OnboardingSection>
  );
}

export const BUSINESS_CATEGORY: Record<BusinessCategory, string> = {
  [BusinessCategory.AD_NETWORK]: "Advertising Network",
  [BusinessCategory.AFFILIATE_PLATFORM]: "Affiliate Platform",
  [BusinessCategory.APP_STORE]: "App Store",
  [BusinessCategory.BUSINESS_SERVICE]: "Business Services",
  [BusinessCategory.CHARITY]: "Charity",
  [BusinessCategory.CROWD_FUNDING]: "Crowd Funding",
  [BusinessCategory.CROWD_SOURCING]: "Crowd Sourcing",
  [BusinessCategory.EDUCATION]: "Education",
  [BusinessCategory.E_COMMERCE]: "E-Commerce",
  [BusinessCategory.ENTERTAINMENT]: "Entertainment",
  [BusinessCategory.INFLUENCER_PLATFORM]: "Influencer Platform",
  [BusinessCategory.PUBLISHING]: "Publishing",
  [BusinessCategory.MANUFACTURING]: "Manufacturing",
  [BusinessCategory.ONLINE_MARKET]: "Online Marketplace",
  [BusinessCategory.REBATES]: "Rebates",
  [BusinessCategory.SHARE_ECONOMY]: "Share Economy",
  [BusinessCategory.STARTUP]: "Startup",
  [BusinessCategory.SURVEYS]: "Surveys",
  [BusinessCategory.TRAVEL]: "Travel",

  // BANNED CATEGORIES
  [BusinessCategory.ADULT_ENTERTAINMENT]: "Adult Entertainment",
  [BusinessCategory.CREDIT_CARD_PROCESSING]: "Credit Card Processing",
  [BusinessCategory.FIREARMS]: "Firearms",
  [BusinessCategory.MONEY_TRANSMITTER_SERVICE]: "Money Transmitter Service",
  [BusinessCategory.MULTI_LEVEL_MARKETING]: "Multi-Level-Marketing",
  [BusinessCategory.ONLINE_GAMBLING]: "Online Gambling",
  [BusinessCategory.OTHER]: "Other",
};
