import { BaseError } from "@trolley/common-frontend";
import {
  EmptyProps,
  List,
  Pagination,
  PaginationProps,
  Table as TableAnt,
  TableColumnGroupType,
  TableColumnType,
  TableColumnsType,
  TablePaginationConfig,
  TableProps,
} from "antd";
import { FilterValue, SorterResult, TableRowSelection } from "antd/lib/table/interface";

import { Error, Icon, RecordCount, Stop, Text } from "components";
import Empty from "components/Empty";
import LogoLoader from "components/LogoLoader";
import React, { ReactNode } from "react";
import { BaseStatus } from "store/reducers/standardReducer";
import css, { classnames, createUseStyle } from "style/classname";
import { ANIMATION, BREAKPOINTS, MEDIA_BREAKPOINTS } from "style/variables";
import { Query, useWindowSize } from "utils/hooks";

export { TableColumnsType, TableColumnGroupType, TableColumnType };

function dotToArray<T = any>(key: T): T | string[] {
  return typeof key === "string" && key.includes(".") ? key.split(".") : key; // ANT 4.0 does not accept dotted notation for nested values.
}

export interface Props<T>
  extends Pick<
    TableProps<T>,
    "className" | "style" | "columns" | "bordered" | "size" | "rowSelection" | "footer" | "rowClassName" | "onRow" | "expandable" | "rowKey"
  > {
  /* GENERAL */
  status?: BaseStatus;
  errors?: BaseError[];

  /* TABLE  */
  title?: ReactNode;
  dataSource: T[] | undefined;
  emptyProps?: EmptyProps;
  pagination?: false | Omit<PaginationProps, "onChange">;
  onQueryChange?(change: Query<T>): void;
  renderListItem?(record: T): ReactNode;
}

export default function Table<T extends object>(props: Props<T>) {
  const { title, status, errors, dataSource = [], expandable, renderListItem, style, size = "large", onQueryChange, ...rest } = props;
  const { isMobile, width } = useWindowSize();
  const styledTable = useStyledTable(props);
  const styledList = useStyledList(props);
  const styledPagination = useStyledPagination();
  const pagination: boolean | Partial<PaginationProps> = !!props.pagination && {
    simple: isMobile,
    showTotal: (total, [from, to]) => (
      <Text type="secondary">
        Showing {from} to {to} of <RecordCount value={total} one="1 entry" other="# entries" />
      </Text>
    ),
    className: styledPagination,
    showLessItems: width ? width < BREAKPOINTS.lg : true,
    current: props.pagination.current ?? 1,
    pageSize: props.pagination.pageSize ?? 10,
    total: props.pagination.total ?? 0,
    showSizeChanger: props.pagination.showSizeChanger,
    pageSizeOptions: props.pagination.pageSizeOptions ?? ["10", "25", "50", "100"],
    hideOnSinglePage: true,
    onChange(page) {
      onQueryChange?.({ page });
    },
    prevIcon: <Icon type="angle-left" />,
    nextIcon: <Icon type="angle-right" />,
    onShowSizeChange(current, pageSize) {
      onQueryChange?.({ page: 1, pageSize });
    },
  };

  function onTableChange(p: TablePaginationConfig, filters: Record<string, FilterValue | null>, sorter: SorterResult<T>) {
    if (onQueryChange) {
      const query: Query = { page: 1, orderBy: undefined, sortBy: undefined };

      if (sorter.order && (sorter.columnKey || sorter.field)) {
        query.sortBy = sorter.order === "ascend" ? ["asc"] : ["desc"];
        query.orderBy = [(sorter.columnKey || sorter.field) as string];
      }

      onQueryChange(query);
    }
  }

  let rowSelection: TableRowSelection<T> | undefined;

  if (!!dataSource.length && props.rowSelection) {
    rowSelection = {
      renderCell: (value: boolean, record: T, index: number, originNode: React.ReactNode) => {
        return (
          // This is to prevent onRow['onClick'] behavior when clicking around the row selection checkbox
          <Stop
            type="div"
            style={{
              height: "48px",
              width: "48px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "0 auto",
            }}
          >
            {originNode}
          </Stop>
        );
      },
      ...props.rowSelection,
    };
  }

  const locale =
    status === BaseStatus.LOADING
      ? { emptyText: <>&nbsp;</> }
      : status === BaseStatus.ERROR && errors?.some?.((e) => e.message)
      ? {
          emptyText: (
            <Text type="error">
              <Icon.Status type="error" size="large" />
              <Error errors={errors} wrapped={false} />
            </Text>
          ),
        }
      : { emptyText: <Empty {...props.emptyProps} imageStyle={{ display: "none" }} /> };

  return (
    <LogoLoader spinning={status === BaseStatus.LOADING}>
      {isMobile && renderListItem ? (
        <List<T>
          {...rest}
          header={title}
          locale={locale}
          className={classnames(styledList, props.className)}
          itemLayout="vertical"
          dataSource={dataSource}
          pagination={false}
          rowKey={props.rowKey as any}
          renderItem={props.renderListItem}
        />
      ) : (
        <TableAnt<T>
          {...rest}
          title={title ? () => title : undefined}
          className={classnames(styledTable, props.className)}
          style={style}
          bordered={false}
          size={size}
          locale={locale}
          pagination={false}
          showHeader={!!dataSource?.length && props.columns?.some((c) => c.title)}
          rowKey={props.rowKey}
          rowClassName={(record: T, i, indent) =>
            classnames(
              {
                "row-click": !!props.onRow?.(record)?.onClick || !!props.expandable?.expandRowByClick,
                "row-click-disabled": props.onRow && !props.onRow(record)?.onClick,
              },
              typeof props.rowClassName === "function" ? props.rowClassName(record, i, indent) : props.rowClassName,
            )
          }
          dataSource={dataSource}
          columns={
            dataSource?.length
              ? props.columns?.map((col) => {
                  return {
                    ...col,
                    showSorterTooltip: false,
                    dataIndex: "dataIndex" in col ? dotToArray(col.dataIndex) : undefined,
                  };
                })
              : [{ title: "" }]
          }
          expandable={
            expandable
              ? {
                  ...expandable,
                  expandIcon:
                    expandable.expandIcon ??
                    (({ expanded, onExpand, record }) => (
                      <Icon
                        type={expanded ? "square-minus" : "square-plus"}
                        theme="solid"
                        size="large"
                        onClick={(e) => {
                          onExpand(record, e);
                        }}
                      />
                    )),
                }
              : undefined
          }
          rowSelection={rowSelection}
          onRow={props.onRow}
          onChange={onTableChange}
          footer={props.footer}
          scroll={{ x: true }}
        />
      )}
      {pagination && <Pagination {...pagination} />}
    </LogoLoader>
  );
}

Table.defaultProps = {
  /* TABLE  */
  bordered: true,
  rowKey: "id",
};

const useStyledList = createUseStyle<Props<any>>(({ theme, ...props }) =>
  css`
    &.${theme.prefixCls}-list {
      margin-bottom: 16px;
    }
  `(),
);

const useStyledTable = createUseStyle<Props<any>>(({ theme, ...props }) =>
  css`
    &.${theme.prefixCls}-table-wrapper {
      .${theme.prefixCls}-table-cell {
        animation: ${ANIMATION.hightlightFade} 1.75s ease;
      }

      .${theme.prefixCls}-table {
        border-radius: ${theme.borderRadius}px;
        box-shadow: ${props.bordered ? theme.boxShadowTertiary : "none"};
        margin-bottom: 16px;

        .${theme.prefixCls}-table-title {
          border-bottom: 1px solid ${theme.colorBorderSecondary};
          ${typeof props.title === "string"
            ? `
            font-weight: 600;
            font-size: ${theme.fontSizeHeading4}px;
            color: ${theme.colorText};
          `
            : `
          `}
        }

        .${theme.prefixCls}-table-thead > tr {
          &:first-child {
            & > th,
            & > td {
              background-color: ${theme.colorBgContainer};
            }
          }
          &:not(:first-child) > th {
            border-top: 1px solid ${theme.colorBorderSecondary};
          }
          & > th,
          & > td {
            font-size: ${theme.fontSizeSM}px;
            line-height: ${theme.lineHeightSM};
            text-transform: uppercase;
            &[rowspan]::before {
              display: none; // hide the small cell separator when the cell cover multiple rows
            }
          }
        }

        .${theme.prefixCls}-table-tbody {
          white-space: nowrap;
          overflow: hidden;
          .${theme.prefixCls}-table-selection-column {
            padding: 0px;
          }
          .${theme.prefixCls}-table-row {
            ${!props.footer &&
            // add rounded corner at the bottom of the table
            `
          &:last-child > .${theme.prefixCls}-table-cell {
            border-bottom-width: 0px;
            &:first-child {
              border-bottom-left-radius: ${theme.borderRadius}px;
            }
            &:last-child {
              border-bottom-right-radius: ${theme.borderRadius}px;
            }
          }
        `}
            &.row-click {
              cursor: pointer;
              &-disabled {
                background-color: ${theme.colorBgContainerDisabled};
              }
            }
          }
        }
        .${theme.prefixCls}-table-placeholder {
          .${theme.prefixCls}-table-cell {
            padding: 32px;
            & > div {
              width: auto !important;
              padding: 32px 0;
              background-color: ${theme.colorFillTertiary};
              border-radius: ${theme.borderRadius}px;
            }
          }
        }
        .${theme.prefixCls}-table-footer {
          background-color: transparent;
        }
      }
    }
  `(),
);

const useStyledPagination = createUseStyle(({ theme }) =>
  css`
    &.${theme.prefixCls}-pagination {
      display: block;
      margin-bottom: 16px;
      text-align: center;
      ${MEDIA_BREAKPOINTS.mdUp} {
        text-align: right;
      }
    }
    .${theme.prefixCls}-pagination-prev, .${theme.prefixCls}-pagination-next, .${theme.prefixCls}--pagination-jump-next, .${theme.prefixCls}-pagination-item {
      border-color: transparent !important;
      &:not(&-active):hover {
        background-color: ${theme.controlItemBgHover};
      }
      &-active {
        background-color: ${theme.controlItemBgActiveDisabled};
        a {
          color: ${theme.colorTextDisabled} !important;
          cursor: auto;
        }
      }
    }
    .${theme.prefixCls}-pagination-total-text {
      float: left;
    }
  `(),
);
