import { VerificationStatus } from "@trolley/common-frontend";
import { Box, CopyToClipboard, DateDisplay, Divider, Status, Flyout, Form, FormItem } from "components";
import { RecipientPreview, RecipientProfile } from "features/recipient";
import React, { useState } from "react";
import { BusinessVerification } from "store/actions/verifications";
import { RejectedReasonLabels, renderCountry } from ".";
import { BusinessRejectedReasons } from "./BusinessVerificationList";

interface Props {
  BusinessVerification?: BusinessVerification;
  onClose(): void;
}

export default function BusinessVerificationPreview(props: Props) {
  const { BusinessVerification, onClose } = props;
  const [recipientId, setRecipientId] = useState<string | undefined>();

  return (
    <Flyout
      visible={!!BusinessVerification}
      onClose={onClose}
      title={
        BusinessVerification && (
          <>
            Business Verification <Status type={BusinessVerification.status} size="small" right />
          </>
        )
      }
      subtitle={
        BusinessVerification && (
          <>
            <Divider margin="medium" />
            <RecipientProfile
              recipientId={BusinessVerification.recipientId}
              showLink={() => {
                setRecipientId(BusinessVerification.recipientId);
              }}
              size="large"
              showEmail
              showAddress
            />
          </>
        )
      }
    >
      {BusinessVerification && (
        <Form layout="horizontal" compact>
          <Box padding="large" header="Verification Details">
            <FormItem label="Verification ID">
              <CopyToClipboard value={BusinessVerification.id} />
            </FormItem>
            {BusinessVerification.status === VerificationStatus.REJECTED &&
              BusinessVerification.reasonType &&
              BusinessRejectedReasons[BusinessVerification.reasonType] && (
                <FormItem label="Rejected Reason" data-test="void-reason">
                  {`${RejectedReasonLabels[BusinessVerification.reasonType]} - ${BusinessRejectedReasons[BusinessVerification.reasonType]}`}
                </FormItem>
              )}
            {BusinessVerification.status === VerificationStatus.REJECTED &&
              (!BusinessVerification.reasonType || !BusinessRejectedReasons[BusinessVerification.reasonType]) &&
              BusinessVerification.verifiedData.reason && (
                <FormItem label="Rejected Reason" data-test="void-reason">
                  {BusinessVerification.verifiedData.reason.map((reason) => (
                    <>
                      {reason.name}
                      <br />
                    </>
                  ))}
                </FormItem>
              )}
            <FormItem label="Name">{BusinessVerification.verifiedData.name || "-"}</FormItem>
            <FormItem label="Country">{renderCountry(BusinessVerification.verifiedData.address.country?.toUpperCase())}</FormItem>
            <FormItem label="Submitted At">
              <DateDisplay value={BusinessVerification.submittedAt} icon={false} />
            </FormItem>
            <FormItem label="Decision On">
              <DateDisplay value={BusinessVerification.decisionAt} icon={false} />
            </FormItem>
          </Box>
          <Box padding="large" header="Verified Address">
            <Form.Item label="Street1">{BusinessVerification.verifiedData.address.street1 || "-"}</Form.Item>
            <Form.Item label="Street2">{BusinessVerification.verifiedData.address.street2 || "-"}</Form.Item>
            <Form.Item label="City">{BusinessVerification.verifiedData.address.city || "-"}</Form.Item>
            <Form.Item label="Region">{BusinessVerification.verifiedData.address.region || "-"}</Form.Item>
            <Form.Item label="Country">{BusinessVerification.verifiedData.address.country || "-"}</Form.Item>
            <Form.Item label="Postal Code">{BusinessVerification.verifiedData.address.postalCode || "-"}</Form.Item>
          </Box>
        </Form>
      )}
      <RecipientPreview
        recipientId={recipientId}
        onClose={() => {
          setRecipientId(undefined);
        }}
      />
    </Flyout>
  );
}
