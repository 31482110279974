import { CountryCode, formatCountry } from "@trolley/common-frontend";
import { RcFile } from "antd/lib/upload";
import { Box, ButtonLink, Divider, Form, Icon, Text, UploadDragger } from "components";
import { downloadPOSTFile } from "components/FileDownload";
import { mapToUploadFile } from "pages/BusinessProfile/variables";
import React, { useEffect, useState } from "react";
import { notifyError, notifySuccess } from "store/actions/notifications";
import { Onboarding, removeOnboardingFile, uploadOnboardingFileV2 } from "store/actions/onboarding";
import { useOnboarding } from "store/hooks/onboarding";
import { BaseStatus } from "store/reducers/standardReducer";
import { createFormData } from "utils/helpers";
import { BANK_ONBOARDING_PATH, BankOnboardingPages, OnboardingSection } from ".";

export function getShareRegisters(onboarding: Onboarding | undefined) {
  return Object.values(onboarding?.files || {})
    .filter((file) => file.type === "shareRegister")
    .map(mapToUploadFile);
}

export default function ShareRegister() {
  const { data: onboarding, status: onboardingStatus } = useOnboarding();
  const [shareRegisters, setShareRegisters] = useState(getShareRegisters(onboarding));

  useEffect(() => {
    if (onboarding) {
      setShareRegisters(getShareRegisters(onboarding));
    }
  }, [onboarding]);

  return (
    <OnboardingSection
      title="Upload Shareholder Register"
      description={
        <>
          <p>
            Please upload one or more copies of the most recent available Shareholder Register. The document should show the number of shares issued by the
            company, to whom and when they were issued. Find a list of suitable documents by country below. If these are unavailable, please provide written
            confirmation from an attorney, solicitor, or public accountant—with the requested information included.
          </p>
          <Box flat padding="small">
            <Form layout="horizontal" compact labelCol={{ xs: 24, md: 10 }}>
              <Text>
                <strong>{formatCountry(CountryCode.US)}: </strong>
                Shareholder List
              </Text>
              <Text>
                <strong>{formatCountry(CountryCode.CA)}: </strong>
                T2 Schedule 50 Shareholder Information
              </Text>
              <Text>
                <strong>{formatCountry(CountryCode.GB)}: </strong>
                Shareholder Register
              </Text>
              <Text>
                <strong>Europe: </strong>
                Shareholder Register
              </Text>
              <Text>
                <strong>{formatCountry(CountryCode.AU)}: </strong>
                Share Register
              </Text>
            </Form>
          </Box>
        </>
      }
    >
      <UploadDragger
        fileList={shareRegisters}
        beforeUpload={(file) => {
          setShareRegisters((files) => [
            ...files,
            {
              ...file,
              name: file.name,
              size: file.size,
              status: "uploading",
            },
          ]);
        }}
        customRequest={async (options) => {
          const file = options.file as RcFile;

          try {
            await uploadOnboardingFileV2(createFormData([file], { type: "shareRegister" }));
            notifySuccess("Document Uploaded");
          } catch {
            notifyError("Document failed to upload");
          }

          return false;
        }}
        onRemove={async (file) => {
          const uploadId = file.url && file.uid;
          if (uploadId) {
            try {
              await removeOnboardingFile(uploadId);
              notifySuccess("Document removed");
            } catch (errors) {
              notifyError("Uploaded Document failed");

              return false;
            }
          }

          return true;
        }}
        onDownload={async (file) => {
          try {
            await downloadPOSTFile("/v1/onboarding/documents/download", { uploadId: file.uid }, file.name);
          } catch {
            notifyError("Download failed");
          }
        }}
        loading={onboardingStatus === BaseStatus.LOADING}
      />
      <Divider transparent margin="medium" />

      <ButtonLink size="large" type="primary" block path={[BANK_ONBOARDING_PATH, BankOnboardingPages.REVIEW].join("/")}>
        Continue
        <Icon type="angle-right" />
      </ButtonLink>
    </OnboardingSection>
  );
}
