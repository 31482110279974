import { useEffect } from "react";
import { loadSubMerchants } from "store/actions/subMerchants";
import { emptyList } from "store/reducers/standardReducer";
import { useShallowSelector } from ".";

export function useSubMerchants() {
  useEffect(() => {
    loadSubMerchants();
  }, []);

  return useShallowSelector((state) => {
    if (state.subMerchants.fetchStatus.data === undefined) {
      loadSubMerchants();
    }

    return {
      data: state.subMerchants.entities.data || emptyList,
      status: state.subMerchants.fetchStatus.data,
      error: state.subMerchants.errors.data,
    };
  });
}
