import { LocationDescriptor } from "history";
import { useMemo } from "react";
import { useHistory } from "react-router-dom";
import { omitUndefined, parseQuery, stringifyQuery } from "utils/helpers";

export interface JsonArray extends Array<Json> {}
export interface JsonObject {
  [property: string]: Json | undefined;
}
export type Json = string | number | boolean | null | JsonObject | JsonArray;
export type JsonNoQuery = JsonObject & { query?: never };

type StringKeyOfT<T> = Extract<keyof T, string>;

export interface Query<T = any> {
  search?: string;
  page?: number;
  pageSize?: number;
  orderBy?: string[];
  sortBy?: ("asc" | "desc")[];
  status?: any[];
  dateColumn?: null | StringKeyOfT<T>;
  startDate?: string;
  endDate?: string;
  query?: never;
}

/* istanbul ignore next */
export default function useQuery<TQuery extends Query>(
  defaultQuery: TQuery,
  options?: {
    resetPageOnChange?: boolean;
    arrayKeys?: string[]; // query fields that are suppose to be an array
    numberKeys?: string[]; // query fields that are suppose to be numbers
  },
): [
  TQuery,
  (
    newQuery: Partial<TQuery> | ((q: Partial<TQuery>) => Partial<TQuery>),
    queryOptions?: {
      scrollToTop?: boolean; // default: true
      action?: "PUSH" | "REPLACE";
    },
  ) => void,
] {
  const history = useHistory();
  const { resetPageOnChange, ...parseOptions } = {
    resetPageOnChange: true,
    ...options,
  };
  const query: TQuery = useMemo(() => omitUndefined({ ...defaultQuery, ...parseQuery(history.location.search, parseOptions) }), [history.location.search]);

  return [
    query,
    (newQuery, queryOptions) => {
      const { scrollToTop = true, action = "PUSH" } = queryOptions ?? {};
      const urlQuery: TQuery = { ...defaultQuery, ...parseQuery(history.location.search, parseOptions) };
      const searchQuery = {
        ...urlQuery,
        ...(resetPageOnChange ? { page: 1 } : {}),
        ...(typeof newQuery === "function" ? newQuery(urlQuery) : newQuery),
      };

      const routerOptions: LocationDescriptor = {
        search: stringifyQuery(searchQuery),
      };
      routerOptions.state = {
        scrollToTop,
      };
      if (action === "REPLACE") {
        history.replace(routerOptions);
      } else {
        history.push(routerOptions);
      }
    },
  ];
}
