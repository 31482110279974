import React, { ReactNode } from "react";
import { List } from "antd";
import { Grid, Text } from "components";

export type ListItemsDataSource = {
  label?: string;
  value: ReactNode;
};

export type Props = {
  actionElement?: ReactNode;
  actionColumnWidth?: number;
  labelColumnWidth?: number;
  listItemsDataSource: ListItemsDataSource[];
};

export default function ListItems(props: Props) {
  const { actionElement, listItemsDataSource, actionColumnWidth = 40, labelColumnWidth = 100 } = props;

  const renderListItemsDataSource = (item: { label: string; value: ReactNode }) => (
    <Grid padding="small" align="middle" wrap={false}>
      {item.label && (
        <Grid.Item flex={`0 0 ${labelColumnWidth}px`}>
          <Text style={{ lineHeight: 2 }}>{item.label}</Text>
        </Grid.Item>
      )}
      <Grid.Item flex="1">{item.value}</Grid.Item>
    </Grid>
  );

  return (
    <List.Item>
      <Grid wrap={false}>
        <Grid.Item flex="1 1">
          <List itemLayout="vertical" dataSource={listItemsDataSource} renderItem={renderListItemsDataSource} />
        </Grid.Item>
        {!!actionElement && (
          <Grid.Item flex={`0 0 ${actionColumnWidth}px`} align="center">
            {actionElement}
          </Grid.Item>
        )}
      </Grid>
    </List.Item>
  );
}
