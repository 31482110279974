/* istanbul ignore file */
import { routerMiddleware, RouterState } from "connected-react-router";
import { applyMiddleware, createStore } from "redux";
import { createLogger } from "redux-logger";
import { AccountMapping, AccountMappingCandidates } from "store/actions/accountMappings";
import { ApiCall } from "store/actions/activity";
import { ApiKey } from "store/actions/apiKeys";
import { Approver } from "store/actions/approvalApprovers";
import { Limit } from "store/actions/approvalLimits";
import { AuthLog } from "store/actions/authLogs";
import { Account } from "store/actions/balances";
import { BankCodes } from "store/actions/bankCodes";
import { BankInfo } from "store/actions/bankInfo";
import { Batch } from "store/actions/batches";
import { BatchSummary } from "store/actions/batchSummary";
import { ComplianceCheck } from "store/actions/compliance";
import { CountryCurrency } from "store/actions/currencies";
import { DAC7EoyTax } from "store/actions/dac7EoyTaxForms";
import { DAC7TaxMerchantYear } from "store/actions/dac7TaxMerchantYear";
import { MerchantBankAccount } from "store/actions/directDebit";
import { EoyTax } from "store/actions/eoyTaxForms";
import { FeeStructure } from "store/actions/fees";
import { UploadFile } from "store/actions/files";
import { IFrameConfig } from "store/actions/iframeConfig";
import { Integration } from "store/actions/integrations";
import { InvoicePayment } from "store/actions/invoicePayments";
import { Invoice } from "store/actions/invoices";
import { LedgerItem } from "store/actions/ledgerItems";
import { Features, MerchantSettings } from "store/actions/merchantSettings";
import { ChargebeeInvoice, MerchantSubscription, Plan } from "store/actions/merchantSubscription";
import { Message } from "store/actions/messages";
import { Onboarding } from "store/actions/onboarding";
import { PandaDocState } from "store/actions/pandaDoc";
import { Payment } from "store/actions/payments";
import { DAC7Tax } from "store/actions/paymentDac7Taxes";
import { PaymentTax } from "store/actions/paymentTaxes";
import { PayoutMethod } from "store/actions/payoutMethods";
import { RecipientAccountConfig } from "store/actions/recipientAccountConfig";
import { RecipientLog } from "store/actions/recipientLogs";
import { StoreRecipient } from "store/actions/recipients";
import { Report } from "store/actions/statsData";
import { SubMerchant } from "store/actions/subMerchants";
import { TaxForm, W8UsWithholdingTable } from "store/actions/taxForms";
import { TaxProfile } from "store/actions/taxProfiles";
import { TaxMerchantYear } from "store/actions/taxMerchantYear";
import { Ticket } from "store/actions/tickets";
import { TransferInfo } from "store/actions/transferInfo";
import { User } from "store/actions/user";
import { Webhook } from "store/actions/webhooks";
import { WhiteLabel, WhiteLabelDns } from "store/actions/whiteLabel";
import { WebhookLog } from "store/hooks/webhookLogs";
import createRootReducer from "store/reducers";
import { NotificationState } from "store/reducers/notifications";
import { SessionState } from "store/reducers/session";
import { DispatchAction, ListState, StandardState } from "store/reducers/standardReducer";
import history from "services/history";
import { AccountIntegrationSyncEntity, CountryCode, MerchantFeed } from "@trolley/common-frontend";
import { EoyTaxExport } from "./actions/eoyTaxExports";
import { RecipientAccount } from "./actions/recipientAccount";
import { AddFunds } from "store/actions/addFunds";
import { TFALog } from "store/actions/tfaLogs";
import { RecipientConfig } from "store/actions/recipientConfig";
import { RoyaltyCalculatorSettings } from "store/actions/royaltyIntegrations";
import { SyncCadenceSettings, SyncFeatures } from "store/actions/syncSettings";
import { VerificationHistory } from "./actions/verifications";
import { Dac7EoyTaxExport } from "./actions/dac7EoyTaxExports";
import { SyncLinkedJournal } from "store/actions/journalLink";
import { StoredLocale } from "./actions/auth";

const middlewares = [routerMiddleware(history)];

if (process.env.SETTINGS !== "production") {
  middlewares.push(createLogger({ level: "log" }));
}

const store = createStore<Store, DispatchAction, {}, {}>(createRootReducer(history), applyMiddleware(...middlewares));

export default store;

export interface Store {
  router: RouterState;
  session: SessionState;
  notifications: NotificationState;

  accountMappings: StandardState<AccountMapping[]>;
  accountMappingCandidates: StandardState<AccountMappingCandidates>;
  activity: StandardState<ListState<ApiCall>>;
  addFunds: StandardState<AddFunds[]>;
  apiKeys: StandardState<ListState<ApiKey>>;
  approvalApprovers: StandardState<Approver[]>;
  approvalLimits: StandardState<Limit[]>;
  authLogs: StandardState<ListState<AuthLog>>;
  balances: StandardState<Account>;
  bankCodes: StandardState<BankCodes[]>;
  bankInfo: StandardState<BankInfo>;
  batch: StandardState<Batch>;
  batches: StandardState<ListState<string>>;
  codatJournals: StandardState<SyncLinkedJournal[]>;
  batchSummary: StandardState<BatchSummary>;
  compliance: StandardState<ListState<ComplianceCheck>>;
  currencies: StandardState<Partial<Record<CountryCode, CountryCurrency[]>>>;
  dac7EoySummary: StandardState<number[]>;
  dac7EoyTaxExports: StandardState<ListState<Dac7EoyTaxExport>>;
  dac7EoyTaxForm: StandardState<DAC7EoyTax>;
  dac7EoyTaxForms: StandardState<ListState<string>>;
  dac7TaxMerchantYear: StandardState<DAC7TaxMerchantYear>;
  directDebit: StandardState<MerchantBankAccount>;
  eoyTaxExports: StandardState<ListState<EoyTaxExport>>;
  eoySummary: StandardState<number[]>;
  eoyTaxForm: StandardState<EoyTax>;
  eoyTaxForms: StandardState<ListState<string>>;
  features: StandardState<Features>;
  fees: StandardState<FeeStructure>;
  files: StandardState<UploadFile>;
  fileList: StandardState<ListState<string>>;
  iframeConfig: StandardState<IFrameConfig>;
  integrations: StandardState<Integration>;
  invoices: StandardState<Invoice>;
  invoiceList: StandardState<ListState<string>>;
  invoicePayments: StandardState<InvoicePayment[]>;
  verifications: StandardState<VerificationHistory>;
  syncRecords: StandardState<ListState<AccountIntegrationSyncEntity>>;
  locale: StandardState<StoredLocale>;
  ledgerItems: StandardState<ListState<LedgerItem>>;
  messages: StandardState<Message[]>;
  merchantSettings: StandardState<MerchantSettings>;
  merchantSubscription: StandardState<MerchantSubscription[]>;
  merchantSubscriptionPlans: StandardState<Plan[]>;
  merchantSubscriptionInvoices: StandardState<ChargebeeInvoice[]>;
  merchantFeed: StandardState<ListState<string>>;
  merchantFeedItem: StandardState<MerchantFeed>;
  offlinePaymentList: StandardState<ListState<string>>;
  onboarding: StandardState<Onboarding>;
  pandaDoc: StandardState<PandaDocState>;
  payment: StandardState<Payment>;
  payments: StandardState<ListState<string>>;
  paymentDac7Taxes: StandardState<DAC7Tax>;
  paymentDac7TaxList: StandardState<ListState<string>>;
  paymentTaxes: StandardState<PaymentTax>;
  paymentTaxList: StandardState<ListState<string>>;
  payoutMethods: StandardState<PayoutMethod[]>;
  recipientLogs: StandardState<ListState<RecipientLog>>;
  paymentTickets: StandardState<string[]>;
  recipient: StandardState<StoreRecipient>;
  recipientAccount: StandardState<RecipientAccount>;
  recipientAccountConfig: StandardState<RecipientAccountConfig>;
  recipientConfig: StandardState<RecipientConfig>;
  recipientTaxForms: StandardState<ListState<string>>;
  recipientTaxProfiles: StandardState<ListState<string>>;
  recipients: StandardState<ListState<string>>;
  royaltyIntegrations: StandardState<RoyaltyCalculatorSettings[]>;
  statsData: StandardState<Report[]>;
  recipientTickets: StandardState<string[]>;
  subMerchants: StandardState<ListState<SubMerchant>>;
  syncFeatureSettings: StandardState<SyncFeatures>;
  syncCadenceSettings: StandardState<SyncCadenceSettings>;
  syncLinkedJournal: StandardState<SyncLinkedJournal>;
  taxMerchantYear: StandardState<TaxMerchantYear>;
  taxForm: StandardState<TaxForm>;
  taxForms: StandardState<ListState<string>>;
  taxProfile: StandardState<TaxProfile>;
  taxProfiles: StandardState<ListState<string>>;
  teamMemberList: StandardState<ListState<string>>;
  teamMembers: StandardState<User>;
  tfaLogs: StandardState<ListState<TFALog>>;
  ticket: StandardState<Ticket>;
  tickets: StandardState<ListState<string>>;
  transferInfo: StandardState<TransferInfo[]>;
  user: StandardState<User>;
  webhooks: StandardState<Webhook[]>;
  webhookLogs: StandardState<ListState<WebhookLog>>;
  whiteLabel: StandardState<WhiteLabel>;
  whiteLabelDns: StandardState<WhiteLabelDns>;
  withholdingTable: StandardState<W8UsWithholdingTable>;
}
