const { getAlphaColor } = require("antd/lib/theme/themes/default/colorAlgorithm.js");
const colors = require("./colors.js");
const generate = require("@ant-design/colors").generate;

/**
 * The color palette are all color variants of the main colors
 */

const greys = generate(colors.grey);

module.exports = {
  "primary-1": "hsl(205, 100%, 96%)",
  "primary-2": "hsl(205, 100%, 90%)",
  "primary-3": "hsl(205, 100%, 76%)",
  "primary-4": "hsl(205, 100%, 63%)",
  "primary-5": "#0095FF",
  "primary-6": "hsl(205, 100%, 48%)",
  "primary-7": "hsl(205, 100%, 45%)",
  "primary-8": "hsl(205, 100%, 43%)",
  "primary-9": "hsl(205, 100%, 40%)",
  "primary-alpha-1": "hsla(205, 100%, 50%, 0.08)",
  "primary-alpha-2": "hsla(205, 100%, 50%, 0.15)",
  "primary-alpha-3": "hsla(205, 100%, 50%, 0.2)",
  "primary-alpha-4": "hsla(205, 100%, 50%, 0.4)",
  "primary-alpha-5": "hsla(205, 100%, 50%, 0.5)",
  "primary-alpha-6": "hsla(205, 100%, 50%, 0.6)",
  "primary-alpha-7": "hsla(205, 100%, 50%, 0.7)",
  "primary-alpha-8": "hsla(205, 100%, 50%, 0.8)",
  "primary-alpha-9": "hsla(205, 100%, 50%, 0.9)",
  "blue-1": "hsl(205, 100%, 96%)",
  "blue-2": "hsl(205, 100%, 90%)",
  "blue-3": "hsl(205, 100%, 76%)",
  "blue-4": "hsl(205, 100%, 63%)",
  "blue-5": "#0095FF",
  "blue-6": "hsl(205, 100%, 48%)",
  "blue-7": "hsl(205, 100%, 45%)",
  "blue-8": "hsl(205, 100%, 42%)",
  "blue-9": "hsl(205, 100%, 40%)",
  "blue-alpha-1": "hsla(205, 100%, 50%, 0.08)",
  "blue-alpha-2": "hsla(205, 100%, 50%, 0.15)",
  "blue-alpha-3": "hsla(205, 100%, 50%, 0.2)",
  "blue-alpha-4": "hsla(205, 100%, 50%, 0.4)",
  "blue-alpha-5": "hsla(205, 100%, 50%, 0.5)",
  "blue-alpha-6": "hsla(205, 100%, 50%, 0.6)",
  "blue-alpha-7": "hsla(205, 100%, 50%, 0.7)",
  "blue-alpha-8": "hsla(205, 100%, 50%, 0.8)",
  "blue-alpha-9": "hsla(205, 100%, 50%, 0.9)",
  "green-1": "hsl(165, 34%, 94%)",
  "green-2": "hsl(162, 34%, 84%)",
  "green-3": "hsl(161, 34%, 71%)",
  "green-4": "hsl(161, 34%, 58%)",
  "green-5": "#57AD91",
  "green-6": "hsl(161, 34%, 45%)",
  "green-7": "hsl(161, 34%, 40%)",
  "green-8": "hsl(161, 34%, 34%)",
  "green-9": "hsl(161, 34%, 28%)",
  "green-alpha-1": "hsla(161, 34%, 51%, 0.05)",
  "green-alpha-2": "hsla(161, 34%, 51%, 0.15)",
  "green-alpha-3": "hsla(161, 34%, 51%, 0.3)",
  "green-alpha-4": "hsla(161, 34%, 51%, 0.4)",
  "green-alpha-5": "hsla(161, 34%, 51%, 0.5)",
  "green-alpha-6": "hsla(161, 34%, 51%, 0.6)",
  "green-alpha-7": "hsla(161, 34%, 51%, 0.7)",
  "green-alpha-8": "hsla(161, 34%, 51%, 0.8)",
  "green-alpha-9": "hsla(161, 34%, 51%, 0.9)",
  "red-1": "hsl(0, 90%, 96%)",
  "red-2": "hsl(0, 90%, 89%)",
  "red-3": "hsl(0, 90%, 82%)",
  "red-4": "hsl(0, 90%, 75%)",
  "red-5": "#F76666",
  "red-6": "hsl(0, 63%, 60%)",
  "red-7": "hsl(0, 45%, 52%)",
  "red-8": "hsl(0, 40%, 48%)",
  "red-9": "hsl(0, 36%, 41%)",
  "red-alpha-1": "hsla(0, 90%, 68%, 0.05)",
  "red-alpha-2": "hsla(0, 90%, 68%, 0.15)",
  "red-alpha-3": "hsla(0, 90%, 68%, 0.3)",
  "red-alpha-4": "hsla(0, 90%, 68%, 0.4)",
  "red-alpha-5": "hsla(0, 90%, 68%, 0.5)",
  "red-alpha-6": "hsla(0, 90%, 68%, 0.6)",
  "red-alpha-7": "hsla(0, 90%, 68%, 0.7)",
  "red-alpha-8": "hsla(0, 90%, 68%, 0.8)",
  "red-alpha-9": "hsla(0, 90%, 68%, 0.9)",
  "yellow-1": "hsl(43, 100%, 90%)",
  "yellow-2": "hsl(43, 100%, 80%)",
  "yellow-3": "hsl(43, 100%, 72%)",
  "yellow-4": "hsl(43, 100%, 62%)",
  "yellow-5": "#FFBA08",
  "yellow-6": "hsl(43, 94%, 41%)",
  "yellow-7": "hsl(43, 90%, 31%)",
  "yellow-8": "hsl(43, 82%, 20%)",
  "yellow-9": "hsl(43, 80%, 15%)",
  "yellow-alpha-1": "hsla(43, 100%, 51%, 0.1)",
  "yellow-alpha-2": "hsla(43, 100%, 51%, 0.2)",
  "yellow-alpha-3": "hsla(43, 100%, 51%, 0.3)",
  "yellow-alpha-4": "hsla(43, 100%, 51%, 0.4)",
  "yellow-alpha-5": "hsla(43, 100%, 51%, 0.5)",
  "yellow-alpha-6": "hsla(43, 100%, 51%, 0.6)",
  "yellow-alpha-7": "hsla(43, 100%, 51%, 0.7)",
  "yellow-alpha-8": "hsla(43, 100%, 51%, 0.8)",
  "yellow-alpha-9": "hsla(43, 100%, 51%, 0.9)",

  "grey-1": greys[0],
  "grey-2": greys[1],
  "grey-3": greys[2],
  "grey-4": greys[3],
  "grey-5": greys[4],
  "grey-6": greys[5],
  "grey-7": greys[6],
  "grey-8": greys[7],
  "grey-9": greys[8],
  "grey-10": greys[9],

  "grey-alpha-1": getAlphaColor(colors.grey, 0.1),
  "grey-alpha-2": getAlphaColor(colors.grey, 0.2),
  "grey-alpha-3": getAlphaColor(colors.grey, 0.3),
  "grey-alpha-4": getAlphaColor(colors.grey, 0.4),
  "grey-alpha-5": getAlphaColor(colors.grey, 0.5),
  "grey-alpha-6": getAlphaColor(colors.grey, 0.6),
  "grey-alpha-7": getAlphaColor(colors.grey, 0.7),
  "grey-alpha-8": getAlphaColor(colors.grey, 0.8),
  "grey-alpha-9": getAlphaColor(colors.grey, 0.9),

  "white-alpha-1": getAlphaColor(colors.white, 0.05),
  "white-alpha-2": getAlphaColor(colors.white, 0.1),
  "white-alpha-3": getAlphaColor(colors.white, 0.2),
  "white-alpha-4": getAlphaColor(colors.white, 0.4),
  "white-alpha-5": getAlphaColor(colors.white, 0.5),
  "white-alpha-6": getAlphaColor(colors.white, 0.6),
  "white-alpha-7": getAlphaColor(colors.white, 0.7),
  "white-alpha-8": getAlphaColor(colors.white, 0.8),
  "white-alpha-9": getAlphaColor(colors.white, 0.9),

  "black-alpha-1": getAlphaColor(colors.black, 0.05),
  "black-alpha-2": getAlphaColor(colors.black, 0.1),
  "black-alpha-3": getAlphaColor(colors.black, 0.2),
  "black-alpha-4": getAlphaColor(colors.black, 0.4),
  "black-alpha-5": getAlphaColor(colors.black, 0.5),
  "black-alpha-6": getAlphaColor(colors.black, 0.6),
  "black-alpha-7": getAlphaColor(colors.black, 0.7),
  "black-alpha-8": getAlphaColor(colors.black, 0.8),
  "black-alpha-9": getAlphaColor(colors.black, 0.9),
};
