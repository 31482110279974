import { useEffect, useState } from "react";
import { loadPaymentTaxes, PaymentTaxesQuery, PaymentTax } from "store/actions/paymentTaxes";

import { emptyList } from "store/reducers/standardReducer";
import { useShallowSelector } from ".";

export function usePaymentTaxes(query: PaymentTaxesQuery) {
  const [fetchId, setFetchId] = useState("");

  useEffect(() => {
    setFetchId(loadPaymentTaxes(query));
  }, [query]);

  return useShallowSelector((state) => {
    if (fetchId && state.paymentTaxList.fetchStatus[fetchId] === undefined) {
      loadPaymentTaxes(query);
    }

    const data = state.paymentTaxList.entities[fetchId];

    return {
      data: data
        ? {
            records: data.records.map((id) => state.paymentTaxes.entities[id]).filter((v) => v) as PaymentTax[],
            meta: data.meta,
          }
        : emptyList,
      status: state.paymentTaxList.fetchStatus[fetchId],
      error: state.paymentTaxList.errors[fetchId],
    };
  });
}
