import { Access, EndOfYearFormType, EoyTaxReportStatus, TaxDeliveryType } from "@trolley/common-frontend";
import BigNumber from "bignumber.js";
import {
  Alert,
  Button,
  Container,
  CurrencyDisplay,
  DateDisplay,
  Divider,
  Dropdown,
  FileDownload,
  Grid,
  Heading,
  Icon,
  Link,
  LogoLoader,
  Menu,
  Modal,
  RecordCount,
  Status,
  Table,
  Text,
} from "components";
import { ConnectedRouter } from "connected-react-router";
import dayjs from "dayjs";
import { translateDeliveryMethod } from "features/recipient/RecipientDeliveryMethod";
import { UserProfile } from "features/user";
import { DAC7_STATEMENT_FEES } from "pages/SettingsPage/Fees";
import React, { useEffect, useState } from "react";
import Helmet from "react-helmet";
import { FormattedNumber, useIntl } from "react-intl";
import { Provider } from "react-redux";
import { Link as RouteLink, useHistory, useParams } from "react-router-dom";
import { Cell, Label, Legend, Pie, PieChart, ResponsiveContainer } from "recharts";
import history from "services/history";
import store from "store";
import { loadDac7EoySummary } from "store/actions/dac7EoySummary";
import { DAC7EoyTaxQuery, resendDac7EoyTaxForms, sendDac7EoyTaxForms } from "store/actions/dac7EoyTaxForms";
import {
  Dac7EoyTaxExport,
  loadDac7EoyTaxExports,
  Dac7EoyExportStatusMerchant,
  generateDac7XmlFiles,
  markDac7ExportFiled,
} from "store/actions/dac7EoyTaxExports";
import { notifyError, notifySuccess } from "store/actions/notifications";
import { useDac7EoySummary } from "store/hooks/dac7EoySummary";
import { useDAC7TaxMerchantYear } from "store/hooks/dac7TaxMerchantYear";
import { useDac7EoyTaxExports } from "store/hooks/dac7EoyTaxExports";
import { useAccess } from "store/hooks/user";
import { useWhiteLabel } from "store/hooks/whiteLabel";
import { BaseError, BaseStatus } from "store/reducers/standardReducer";
import colors from "style/colors";
import { stringifyQuery } from "utils/helpers";
import { useWindowSize } from "utils/hooks";
import { Pages, getDueDate, isProfileComplete } from ".";
import { DAC7_INTRO_YEAR, LAST_YEAR, TAX_PATHS } from "../..";

// unique cost for TaxDeliveryType.E_DELIVERY
function calculateCost(records: number): BigNumber {
  const reportGenerationCost = new BigNumber(records * DAC7_STATEMENT_FEES.base.tier1);

  return reportGenerationCost;
}

function calculatePercent(needsReview: number, approved: number, sent: number, eFiled: number): number {
  const sum = needsReview + approved + sent + eFiled;
  const numerator = eFiled;

  return sum > 0 ? Number(new BigNumber(numerator).dividedBy(sum).times(100).toFixed(0)) : 0;
}

type DeliveryWarnings = { showAdditionalCostWarning?: boolean };

export function TaxDeliveryCostWarning(props: DeliveryWarnings) {
  return (
    <Provider key="modal-content" store={store}>
      <ConnectedRouter history={history}>
        <TaxDeliveryWarning {...props} />
      </ConnectedRouter>
    </Provider>
  );
}

function TaxDeliveryWarning({ showAdditionalCostWarning }: DeliveryWarnings) {
  const { data: whiteLabel } = useWhiteLabel();

  return (
    <div>
      <p>
        The End of Year statements will be made available on the Recipient Widget or Portal.
        <br />
        {whiteLabel?.taxEoyAvailable ? (
          "An email notification will be sent to the recipient to let them know they can login and download it."
        ) : (
          <Alert type="warning">
            <>
              Email notification will not be sent to the recipients because&nbsp;
              <RouteLink
                onClick={() => {
                  Modal.destroyAll();
                }}
                to="/settings/white-label/emails"
              >
                Tax Statement Delivery
              </RouteLink>
              &nbsp;notification is disabled.
            </>
          </Alert>
        )}
      </p>

      {showAdditionalCostWarning && <p>You will be charged for any additional printing and postal mailing of EOY tax statements.</p>}
    </div>
  );
}

const QUERIES: DAC7EoyTaxQuery[] = [
  // NEEDS_REVIEW:
  {
    pageSize: 0,
    status: [EoyTaxReportStatus.NEEDS_REVIEW, EoyTaxReportStatus.HOLD],
  },
  // APPROVED
  {
    pageSize: 0,
    status: [EoyTaxReportStatus.APPROVED],
  },
  // SENT but not submitted
  {
    pageSize: 0,
    status: [EoyTaxReportStatus.SENT],
    submitted: false,
  },
  // XML Submitted (Filed)
  {
    pageSize: 0,
    submitted: true,
  },
  // RESENT
  {
    pageSize: 0,
    status: [EoyTaxReportStatus.SENT],
  },
];

type Props = {
  formType?: Exclude<EndOfYearFormType, EndOfYearFormType.NONE>;
};

export default function DAC7Statement({ formType = EndOfYearFormType.F1099 }: Props) {
  const params = useParams<{ taxYear: string }>();
  const taxYear = Number(params.taxYear);
  const accessTaxEOYWrite = useAccess(Access.TAX_EOY_WRITE);
  const baseUrl = `${TAX_PATHS.EOY_REPORTING_DAC7}/${taxYear}`;
  const dac7EoyExportQuery = { taxYear, orderBy: ["count"], sortBy: ["asc"], pageSize: 1000 };
  const { data: dac7TaxMerchantYear, status: dac7TaxMerchantYearStatus } = useDAC7TaxMerchantYear(taxYear);
  const { data: dac7EoyExports } = useDac7EoyTaxExports(dac7EoyExportQuery);
  const history = useHistory();
  const intl = useIntl();
  const { isMobile } = useWindowSize();
  const queries = QUERIES.map((q) => ({
    taxYear,
    ...q,
  }));
  const [needsReviewQuery, approvedQuery, , , , , eFiledQuery] = queries;
  const {
    data: [reviewCount = 0, approvedCount = 0, sentToRecipientsCount = 0, submittedCount = 0, resendCount = 0],
    status: dac7EoySummaryStatus,
  } = useDac7EoySummary(queries);
  const [dac7EoyTaxFormsStatus, setDac7EoyTaxFormsStatus] = useState<BaseStatus>();

  const loading = dac7EoySummaryStatus === BaseStatus.LOADING;

  useEffect(() => {
    if (taxYear && taxYear < DAC7_INTRO_YEAR) {
      // Validating not reporting DAC7 before its intro year in 2023
      history.push(`${TAX_PATHS.EOY_REPORTING_DAC7}/${DAC7_INTRO_YEAR}`);
    } else if (dac7EoySummaryStatus === BaseStatus.LOADED) {
      reloadQueries();
    }
  }, [taxYear]);

  const ongoingYear = Number(taxYear) >= LAST_YEAR;
  const baseSentCost = calculateCost(approvedCount);
  const percentComplete = calculatePercent(reviewCount, approvedCount, sentToRecipientsCount, submittedCount);
  const GRAPH_colors = [colors.yellow, colors.purple, colors.blue, colors.green];
  const data = [
    { name: <RecordCount value={reviewCount} other="# on hold or in review" />, value: reviewCount },
    { name: <RecordCount value={approvedCount} other="# approved" />, value: approvedCount },
    { name: <RecordCount value={sentToRecipientsCount} other="# sent to recipients" />, value: sentToRecipientsCount },
    { name: <RecordCount value={submittedCount} other="# E-filed to the EU Tax Authorities" />, value: submittedCount },
  ];

  function reloadQueries() {
    loadDac7EoyTaxExports(dac7EoyExportQuery, true);
    window.setTimeout(() => {
      loadDac7EoySummary(queries, true); // the count doesn't seem to update immediately
    }, 500);
  }

  return (
    <Container padding="none">
      <Helmet>
        <title>DAC7 Earnings Summary Delivery and Filing</title>
      </Helmet>
      <Heading tag="h2">DAC7 Earnings Summary Delivery and Filing</Heading>
      <p>Deadline for delivering Earnings Summaries & DAC7 Filing: {dayjs(getDueDate(taxYear)).format("ll")}</p>

      <div style={{ maxWidth: "450px" }}>
        <LogoLoader spinning={loading}>
          <ResponsiveContainer width="100%" minWidth={isMobile ? 260 : 400} height={isMobile ? 260 : 160}>
            <PieChart>
              <Legend align={isMobile ? "center" : "right"} verticalAlign={isMobile ? "bottom" : "middle"} layout="vertical" />
              <Pie legendType="circle" dataKey="value" data={data} innerRadius={50} outerRadius={80}>
                {data.map((entry, index) => (
                  <Cell key={`cell-${entry.name}`} fill={GRAPH_colors[index]} fillOpacity={0.85} />
                ))}
                <Label
                  position="center"
                  value={`${percentComplete}%`}
                  fill={percentComplete < 100 ? colors.black : colors.green}
                  fontSize="28px"
                  fontWeight="bold"
                />
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        </LogoLoader>
      </div>

      <Divider transparent margin="small" />

      {/**
       * ======================================= IN REVIEW / HOLD =================================
       */}
      <Heading tag="h2">
        <Link
          to={{
            pathname: `${baseUrl}/${Pages.EARNINGS}`,
            search: stringifyQuery({ ...needsReviewQuery, page: 1, pageSize: 10 }),
          }}
        >
          <Icon type="circle" size="small" theme="solid" color="yellow" left />
          <RecordCount value={reviewCount} one="# earnings is on hold or needs to be reviewed" other="# earnings are on hold or need to be reviewed" />
        </Link>
      </Heading>
      {ongoingYear && reviewCount > 0 && (
        <Alert type="info" showIcon={!isMobile} style={{ marginLeft: "24px" }} header="Next Step!">
          <p>You need to review and approve earnings before the tax statements can be sent to recipients.</p>
          <Link to={{ pathname: `${baseUrl}/${Pages.EARNINGS}`, search: stringifyQuery({ ...needsReviewQuery, page: 1, pageSize: 10 }) }}>
            Click Here to Review DAC7 Earnings
          </Link>
        </Alert>
      )}

      {/**
       * ======================================= APPROVED =================================
       */}

      <Heading tag="h2">
        <Link
          to={{
            pathname: `${baseUrl}/${Pages.EARNINGS}`,
            search: stringifyQuery({
              ...approvedQuery,
              page: 1,
              pageSize: 10,
            }),
          }}
        >
          <Icon type="circle" size="small" theme="solid" color="purple" left />
          <RecordCount
            value={approvedCount}
            one="# approved earnings summaries needs to be sent to recipients"
            other="# approved earnings summaries need to be sent to recipients"
          />
        </Link>
      </Heading>
      {ongoingYear && approvedCount > 0 && (
        <Alert type="info" header="Next Step!" style={{ marginLeft: "24px" }}>
          You can now send the approved earnings summaries PDFs to the recipients. Once sent, the PDFs will be downloadable from the widget, portal, or this
          dashboard. If enabled in your settings, a white label email will be sent to notify recipients that their DAC7 earnings summary is available for
          download.
          <Table
            pagination={false}
            columns={[
              {
                dataIndex: "deliveryType",
                render: (deliveryType: TaxDeliveryType) => {
                  if (deliveryType === TaxDeliveryType.E_DELIVERY) {
                    return "E-Delivery";
                  } else if (deliveryType === TaxDeliveryType.MAIL) {
                    return "Postal Mail";
                  }

                  return null;
                },
              },
              {
                title: "# of Earnings Summaries",
                dataIndex: "count",
                align: "center",
                render: (count: number) => <FormattedNumber value={count} />,
              },
              {
                title: "Earnings Summary Generation Cost",
                dataIndex: "deliveryType",
                key: "generationCost",
                align: "right",
                render: (deliveryType: TaxDeliveryType, record) => {
                  return (
                    <Text wrap={false}>
                      <CurrencyDisplay value={DAC7_STATEMENT_FEES.base.tier1 * record.count} currency="EUR" />
                    </Text>
                  );
                },
              },
              {
                title: "Subtotal",
                dataIndex: "subtotal",
                align: "right",
                render: (subtotal: number) => <CurrencyDisplay value={subtotal} currency="EUR" />,
              },
            ]}
            rowKey="deliveryType"
            dataSource={[
              {
                deliveryType: TaxDeliveryType.E_DELIVERY,
                count: approvedCount,
                subtotal: baseSentCost,
              },
            ]}
          />
          <Text>
            Total Earnings Summary Delivery Cost:
            <CurrencyDisplay value={baseSentCost.toFixed(2)} currency="EUR" style={{ marginLeft: "20px" }} />
          </Text>
          {dac7TaxMerchantYearStatus === BaseStatus.LOADED && !isProfileComplete(dac7TaxMerchantYear) ? (
            <Alert type="warning">
              You must complete your
              <Link to={`${baseUrl}/${Pages.PROFILE}`}> business tax profile</Link> in order to send statements to recipients.
            </Alert>
          ) : (
            <Divider margin="small" transparent />
          )}
          <Button
            type="primary"
            disabled={!accessTaxEOYWrite || !approvedCount || !isProfileComplete(dac7TaxMerchantYear)}
            size="large"
            onClick={() => {
              Modal.confirm({
                title: `Send DAC7 Earnings Summaries?`,
                okText: "Yes, Send Earnings Summaries to Recipients",
                width: 600,
                okButtonProps: {
                  loading: dac7EoyTaxFormsStatus === BaseStatus.LOADING,
                },
                async onOk() {
                  if (dac7EoyTaxFormsStatus !== BaseStatus.LOADING) {
                    try {
                      setDac7EoyTaxFormsStatus(BaseStatus.LOADING);
                      const ids = await sendDac7EoyTaxForms(taxYear, {});
                      notifySuccess(`Updated ${ids.length} dac7 statement${ids.length > 1 ? "s" : ""}`);
                      setDac7EoyTaxFormsStatus(BaseStatus.LOADED);

                      reloadQueries();
                    } catch (errors) {
                      setDac7EoyTaxFormsStatus(BaseStatus.ERROR);
                      if (!!errors?.length) {
                        if (errors.some((e: BaseError) => e.code === "empty_field")) {
                          Modal.confirm({
                            title: "Incomplete business tax profile",
                            content: "You cannot send earnings summaries because the business tax profile is not complete. Do you want to complete it?",
                            okText: "Yes, bring me there",
                            onOk: () => {
                              history.push(`${baseUrl}/${Pages.PROFILE}`);
                            },
                          });
                        } else {
                          notifyError(errors[0]?.message || "Failed to send earnings summaries");
                        }
                      }
                    }
                  }
                },
                content: (
                  <span>
                    <TaxDeliveryCostWarning />
                    <Text weight="bold">Total Estimated Earnings Delivery Cost: {baseSentCost.toFixed(2)} USD</Text>
                    <p>
                      You are about to send {approvedCount} earning summar{approvedCount > 1 ? "ies" : "y"}. Do you wish to send?
                    </p>
                  </span>
                ),
              });
            }}
          >
            <span>
              <Icon type="paper-plane" left />
              Send Statements to Recipients
            </span>
          </Button>
          <Divider margin="small" transparent />
        </Alert>
      )}

      {/**
       * ======================================= SENT TO RECIPIENTS =================================
       */}

      <Heading tag="h2">
        <Link
          to={{
            pathname: `${baseUrl}/${Pages.EARNINGS}`,
            search: stringifyQuery({
              page: 1,
              pageSize: 10,
              status: [EoyTaxReportStatus.SENT],
              requireIrsSubmittedAt: false,
            }),
          }}
        >
          <Icon type="circle" size="small" theme="solid" color="blue" left />
          <RecordCount
            value={sentToRecipientsCount}
            one="# earnings need to be filed with EU Tax Authorities"
            other="# earnings need to be filed with EU Tax Authorities"
          />
        </Link>
      </Heading>

      <Text style={{ marginLeft: "24px" }}>
        {false && ongoingYear && resendCount > 0 && (
          <>
            <Dropdown
              overlay={
                <Menu
                  disabled // resend endpoint is not implemented yet
                  onClick={(params) => {
                    params?.domEvent?.preventDefault?.();
                    const taxDeliveryType = params.key === "default" ? undefined : (params.key as TaxDeliveryType);
                    const deliveryTypeLabel = taxDeliveryType ? translateDeliveryMethod(taxDeliveryType) : "Recipient's Preference";

                    Modal.confirm({
                      width: 600,
                      title: `Resend statements?`,
                      content: (
                        <>
                          <p>Tax statements that were previously sent to recipients will be resent by {deliveryTypeLabel}.</p>
                          <TaxDeliveryCostWarning showAdditionalCostWarning={taxDeliveryType !== TaxDeliveryType.E_DELIVERY} />
                        </>
                      ),
                      okText: `Yes, Resend by ${deliveryTypeLabel}`,
                      okButtonProps: {
                        loading: dac7EoyTaxFormsStatus === BaseStatus.LOADING,
                      },
                      onOk: async () => {
                        if (dac7EoyTaxFormsStatus !== BaseStatus.LOADING) {
                          try {
                            setDac7EoyTaxFormsStatus(BaseStatus.LOADING);
                            // resend endpoint is not implemented yet
                            const ids = await resendDac7EoyTaxForms(taxYear, { taxDeliveryType });
                            setDac7EoyTaxFormsStatus(BaseStatus.LOADED);

                            notifySuccess(
                              intl.formatMessage(
                                {
                                  id: "resend",
                                  defaultMessage: `
                                {count, plural,
                                  one {# statement has been sent}
                                  other {# statements have been sent}
                                }
                                `,
                                },
                                { count: ids.length },
                              ),
                            );
                          } catch (errors) {
                            setDac7EoyTaxFormsStatus(BaseStatus.ERROR);
                            notifyError("Resending statements failed", { errors });
                          }
                        }
                      },
                    });
                  }}
                >
                  <Menu.Item disabled key={TaxDeliveryType.E_DELIVERY}>
                    By {translateDeliveryMethod(TaxDeliveryType.E_DELIVERY)}
                  </Menu.Item>
                </Menu>
              }
            >
              {/* <Button disabled={!accessTaxEOYWrite || !isProfileComplete(taxMerchantYear, formType)}> */}
              <Button disabled>
                <span>
                  <RecordCount value={resendCount} one="Resend 1 Statement" other="Resend # Statements" />
                  <Icon type="chevron-down" right size="small" />
                </span>
              </Button>
            </Dropdown>
            <Divider transparent margin="small" />
          </>
        )}

        <Alert type="info" header="Next Step!">
          <p>Generate the XML File</p>
          <p>The XML file should be properly formatted and accepted as-is by any EU Country.</p>
          {dac7TaxMerchantYearStatus === BaseStatus.LOADED && !isProfileComplete(dac7TaxMerchantYear) && (
            <Alert type="warning">
              You must complete your <Link to={`${TAX_PATHS.EOY_REPORTING_DAC7}/${taxYear}/${Pages.PROFILE}`}>business tax profile</Link> in order to download
              the XML file for reporting.
            </Alert>
          )}
          <Grid>
            <Grid.Item xs={24}>
              <Text size="large" underline>
                DAC7 XML File Generate
              </Text>
              <p>
                <RecordCount
                  value={sentToRecipientsCount}
                  equalZero="We did not find any DAC7 records that are ready to be submitted."
                  one="We found # new DAC7 record ready to be submitted."
                  other="We found # new DAC7 record to be submitted."
                />
              </p>
              <p>
                <Text size="small" weight="bold">
                  Note: Trolley doesn't support the generation of Correction XML files. Please ensure your reporting data is accurate before submitting to your
                  local tax authority.
                </Text>
              </p>

              {/* TODO: Are we adding more description to the modal to generate the XML file? */}
              <Button.Group key={formType}>
                <Button
                  type="primary"
                  loading={loading}
                  disabled={!ongoingYear || !accessTaxEOYWrite || sentToRecipientsCount === 0 || !isProfileComplete(dac7TaxMerchantYear)}
                  onClick={() => {
                    Modal.confirm({
                      title: `Generate XML File`,
                      content: <>Are you sure you want to generate the XML file?</>,
                      okText: "Ok, Generate XML File",
                      onOk: async () => {
                        try {
                          await generateDac7XmlFiles({ taxYear });
                          reloadQueries();
                        } catch (errors) {
                          notifyError("Failed to generate the XML File", { errors });
                        }
                      },
                    });
                  }}
                >
                  Generate File ({sentToRecipientsCount})
                </Button>
              </Button.Group>
            </Grid.Item>
          </Grid>
          <Divider transparent margin="small" />
        </Alert>

        <Divider transparent margin="medium" />
      </Text>

      {/**
       * ======================================= E-FILING =================================
       */}

      <Heading tag="h2">
        <Link
          to={{
            pathname: `${baseUrl}/${Pages.EARNINGS}`,
            search: stringifyQuery({ ...eFiledQuery, page: 1, pageSize: 10, requireTaxForm: undefined }),
          }}
        >
          <Icon type="circle" size="small" theme="solid" color="green" left />
          <RecordCount
            value={submittedCount}
            one="# tax statement has been submitted to the EU Tax Authorities"
            other="# tax statements have been submitted to the EU Tax Authorities"
          />
        </Link>
      </Heading>
      <Alert type="info" header="Next Step!">
        Download the XML Files and mark as filed to complete your end of year filing with the EU Country of your choice. The download button will initiate the
        download of a ZIP file. The downloaded ZIP file will have 1 XML file with all of your reportable sellers and a folder with 1 XML per recipient country
        (some EU countries require platforms to submit one file per Seller country).
      </Alert>
      <Alert style={{ marginLeft: "24px" }}>
        <p>Below is a table of the XML submission history.</p>
        <Table
          dataSource={dac7EoyExports.records}
          pagination={false}
          columns={[
            {
              title: (
                <>
                  XML Status
                  <Icon.Hint
                    right
                    tooltip={
                      <ul>
                        <li>
                          <Status stronger type={Dac7EoyExportStatusMerchant.PROCESSING} size="small" left />
                          The XML document is generating and will be available for download shortly.
                        </li>
                        <li>
                          <Status stronger type={Dac7EoyExportStatusMerchant.READY_TO_FILE} size="small" left />
                          The XML document has generated and is available to be downloaded and submitted.
                        </li>
                        <li>
                          <Status stronger type={Dac7EoyExportStatusMerchant.FILED} size="small" left />
                          The document has been successfully submitted.
                        </li>
                        <li>
                          <Status stronger type={Dac7EoyExportStatusMerchant.ERROR} size="small" left />
                          Error generating XML document.
                        </li>
                      </ul>
                    }
                  />
                </>
              ),
              dataIndex: "status",
              render: (status: Dac7EoyTaxExport["status"]) => <Status type={status} />,
            },
            {
              title: "XML File ID",
              dataIndex: "id",
              render: (id: string) => String(id),
            },
            {
              title: "Exported On",
              dataIndex: "exportedAt",
              render: (exportedAt: string) => <DateDisplay value={exportedAt} />,
            },
            {
              title: "Exported By",
              dataIndex: "actorId",
              render: (actorId: string) => (/^U-\w+/.test(String(actorId)) ? <UserProfile userId={actorId} showEmail showRole /> : actorId),
            },
            {
              title: "# of Tax Statements",
              dataIndex: "recipientEoyTaxIdCount",
              render: (recipientEoyTaxIdCount: number) => <FormattedNumber value={recipientEoyTaxIdCount} />,
            },
            {
              dataIndex: "id",
              align: "right",
              render(id: string, dac7EoyExport: Dac7EoyTaxExport) {
                return (
                  <Dropdown
                    placement="bottomRight"
                    overlay={
                      <Menu
                        onClick={async (e) => {
                          switch (e.key) {
                            case "zip":
                              break;
                            case "mark-filed":
                              try {
                                await markDac7ExportFiled({ guid: id });
                                reloadQueries();
                              } catch (errors) {
                                notifyError("Failed to mark DAC7 XML export as Filed", { errors });
                              }
                              break;
                            case "csv":
                              break;
                          }
                        }}
                      >
                        <Menu.Item key="zip" disabled={!dac7EoyExport.s3XmlUri}>
                          <FileDownload
                            method="POST"
                            url="/v1/tax-year/dac7-eoy/report-download"
                            loading={loading}
                            query={{ exportGuid: id }}
                            icon="file-zip"
                            fileName={`dac7_${id}_${Date.now()}`}
                            defaultExtension="zip"
                            onDownloadFail={(errors: BaseError[]) => {
                              notifyError("Failed to download XML", { errors });
                            }}
                          >
                            Download XML
                          </FileDownload>
                        </Menu.Item>
                        <Menu.Item key="mark-filed" disabled={dac7EoyExport.status !== Dac7EoyExportStatusMerchant.READY_TO_FILE}>
                          <Icon type="pencil" left />
                          Mark as Filed
                        </Menu.Item>
                        <Menu.Item key="csv" disabled={!dac7EoyExport.s3CsvUri}>
                          <FileDownload
                            method="POST"
                            url="/v1/tax-year/dac7-eoy/report-download-csv"
                            loading={loading}
                            query={{ exportGuid: id }}
                            icon="file-csv"
                            fileName={`dac7_${id}_${Date.now()}`}
                            defaultExtension="csv"
                            onDownloadFail={(errors: BaseError[]) => {
                              notifyError("Failed to download CSV", { errors });
                            }}
                          >
                            Download CSV
                          </FileDownload>
                        </Menu.Item>
                      </Menu>
                    }
                  >
                    <Button icon={<Icon type="ellipsis-h" />} />
                  </Dropdown>
                );
              },
            },
          ]}
          emptyProps={{ description: "No DAC7 XML files have been submitted previously." }}
        />
      </Alert>
    </Container>
  );
}
