import { Layout as LayoutAnt } from "antd";
import { Button, Container, Dropdown } from "components";
import Icon from "components/Icon";
import Breadcrumbs from "pages/App/Header/Breadcrumbs";
import React, { ReactElement, ReactNode } from "react";
import styled, { classnames, createUseStyle } from "style/classname";
import palette from "style/palette";
import { useWindowSize } from "utils/hooks";

const SIDEBAR_GAP = 32;
const SIDEBAR_WIDTH = 200 + SIDEBAR_GAP;

interface Props {
  sidebar: ReactElement;
  sidebarWidth?: number;
  children: ReactNode;
}

export default function Layout(props: Props) {
  const { sidebar, sidebarWidth = SIDEBAR_WIDTH } = props;
  const { isMobile } = useWindowSize();
  const styledMenu = useStyledMenu();
  const styledSidebar = useStyledSidebar();

  return (
    <LayoutAnt>
      {!isMobile && (
        <LayoutAnt.Sider className={classnames(styledMenu, styledSidebar)} width={sidebarWidth}>
          {sidebar}
        </LayoutAnt.Sider>
      )}

      <LayoutAnt.Content>
        {isMobile && (
          <Container>
            <Dropdown overlayClassName={styledMenu} overlay={sidebar}>
              <Button block size="large" icon={<Icon type="bars" color="black" />} style={{ textAlign: "left" }}>
                <Breadcrumbs showLinks={false} startIndex={1} />
              </Button>
            </Dropdown>
          </Container>
        )}
        {props.children}
      </LayoutAnt.Content>
    </LayoutAnt>
  );
}

const useStyledMenu = createUseStyle(({ theme }) =>
  styled`
  .${theme.prefixCls}-menu-item-group {
    &>.${theme.prefixCls}-menu-item-group-title {
      font-weight: 500;
      height: 40px;
      line-height: 32px;
      color: ${theme.colorText};
    }
    .${theme.prefixCls}-dropdown-menu-item,
    .${theme.prefixCls}-menu-item {
      font-weight: normal;
    }
  }
  &, .${theme.prefixCls}-layout-sider-children {
    .${theme.prefixCls}-dropdown-menu-item,
    .${theme.prefixCls}-menu-item {
      position: relative;
      width: unset;
      height: unset;
      margin: 4px 4px;
      padding: 4px 16px;
      border-radius: ${theme.borderRadius}px;
      font-weight: 500;

      &-selected {
        background-color: ${palette["blue-alpha-2"]};
      }
      &-active {
        background-color: ${palette["blue-alpha-3"]};
      }

      &:not(&-selected) {
        &:hover {
          background-color: ${palette["blue-alpha-1"]};
          font-weight: 600;
          color: ${theme.colorTextSecondary};
        }
      }

      &.submenu {
        padding-left: 32px !important;
        font-weight: normal;
      }

    }


    .${theme.prefixCls}-menu-inline, .${theme.prefixCls}-menu-item::after {
      border-right: none;
    }
  }
`(),
);

const useStyledSidebar = createUseStyle(({ theme }) =>
  styled`
  &.${theme.prefixCls}-layout-sider {
    background: none;
  }
  .${theme.prefixCls}-layout-sider-children {
    transition: opacity 0.2s ease-in-out;
    min-height: 100%;
    margin: 16px 0 0 ${SIDEBAR_GAP}px;

    .${theme.prefixCls}-menu-root {
      border-radius: ${theme.borderRadius}px;
      box-shadow: ${theme.boxShadowTertiary};
    }
  }
`(),
);
