import { ButtonLink, Divider, Typography } from "components";
import { TextProps } from "components/Typography/Text";
import React, { ReactNode } from "react";
import { Link } from "react-router-dom";

interface Line {
  line: string | ReactNode;
  textProps?: TextProps;
}

export default function QuickBooksDisconnection() {
  const linesS1: Line[] = [
    {
      line: "Your QuickBooks Online integration has been disconnected. You will no longer be able to send pending bills directly to your QuickBooks Online account from Trolley.",
    },
    {
      line: "If you were a paying Sync customer, the add-on payment will be removed from the payment cycle after your current one.",
    },
  ];

  const linesS2: Line[] = [
    {
      line: "How to reconnect",
      textProps: {
        size: "large",
        weight: "bold",
      },
    },
    {
      line: (
        <>
          To reestablish the connection to QuickBooks Online or connect to another accounting software, visit the
          <Link to="/settings/integrations"> integration page </Link> on the Trolley dashboard.
        </>
      ),
    },
    {
      line: (
        <>
          For further information, please visit the Sync
          <a target="_blank" rel="noopener noreferrer" href="https://help.trolley.com/en/articles/6968129-connect-your-quickbooks-online-account-to-trolley">
            {" "}
            page{" "}
          </a>{" "}
          on the help center.
        </>
      ),
    },
  ];

  const linesS3: Line[] = [
    {
      line: "What happens now to all the bills I already pulled?",
      textProps: {
        size: "large",
        weight: "bold",
      },
    },
    {
      line: (
        <>
          To reestablish the connection to QuickBooks Online or connect to another accounting software, visit the
          <Link to="/settings/integrations"> integration page </Link> on the Trolley dashboard.
        </>
      ),
    },
    {
      line: (
        <>
          For further information, please visit the Sync
          <a target="_blank" rel="noopener noreferrer" href="https://help.trolley.com/en/articles/6968129-connect-your-quickbooks-online-account-to-trolley">
            {" "}
            page{" "}
          </a>{" "}
          on the help center.
        </>
      ),
    },
  ];

  const sections = [linesS1, linesS2, linesS3];

  return (
    <>
      <Typography.Title level={2}>QuickBooks Online Disconnected</Typography.Title>
      {sections.map((section, index) => (
        <div style={{ marginBottom: "32px" }} key={`disconnection-section-${index + 1}`}>
          {section.map(({ line, textProps }, i) => (
            <Typography.Text {...(textProps as TextProps)} key={`disconnection-text-line-${i + 1}`}>
              <p>{line}</p>
            </Typography.Text>
          ))}
        </div>
      ))}
      <Divider margin="large" transparent />
      <ButtonLink path="/settings/integrations" type="primary" size="large" style={{ margin: "0 auto" }}>
        Return to Integrations Page
      </ButtonLink>
    </>
  );
}
