import { Access, CountryCode, CurrencyCode, formatCountry, isCurrencyAmount, PayoutMethodType, uniqueList } from "@trolley/common-frontend";
import { BigNumber } from "bignumber.js";
import { Box, Container, CurrencyDisplay, Divider, Error, ExternalLink, Form, Heading, Icon, InputLive, Status, Table, Tag, Text, Tooltip } from "components";
import { TableColumnsType } from "components/Table";
import { PayoutMethodDisplay } from "features/payoutMethod";
import React from "react";
import { Helmet } from "react-helmet";
import { Account } from "store/actions/balances";
import { FeeStructure, updateMerchantCoverAmount } from "store/actions/fees";
import { notifySuccess } from "store/actions/notifications";
import { useBalances } from "store/hooks/balances";
import { useFees } from "store/hooks/fees";
import { useMerchantSettings } from "store/hooks/merchantSettings";
import { useAccess } from "store/hooks/user";
import { BaseStatus, Mapped } from "store/reducers/standardReducer";
import css, { createUseStyle } from "style/classname";

// @deprecated refer to TAX_STATEMENT_FEES_V2: ENG-3441 Tax Fee by Entitlement
export const TAX_STATEMENT_FEES = {
  base: {
    tier1: 2,
    tier2: 1,
  },
  mail: {
    // in addition to base cost
    tier1: 2,
    tier2: 1,
  },
  international: 1.5,
};

const TAX_STATEMENT_FEES_V2 = {
  base: {
    standard: 2,
    plus: 0,
  },
  mail: {
    standard: 2,
    plus: 0,
  },
  international: {
    standard: 1.5,
    plus: 0,
  },
};

export const DAC7_STATEMENT_FEES = {
  base: {
    tier1: 3,
  },
};

const useStyledTable = createUseStyle(({ theme }) =>
  css`
    .${theme.prefixCls}-table {
      .${theme.prefixCls}-table-thead {
        tr {
          th {
            border-bottom: 1px solid ${theme.colorBorder};
            &:not(:last-child) {
              border-right: 1px solid ${theme.colorBorderSecondary};
            }
          }
        }
        .${theme.prefixCls}-input {
          width: 100%;
        }
      }
      .${theme.prefixCls}-table-tbody {
        tr > td {
          &:nth-child(8n-3),
          &:nth-child(8n-4),
          &:nth-child(8n-5),
          &:nth-child(8n-6) {
            background-color: ${theme.colorFillTertiary};
          }
          &:nth-child(4n-1) {
            font-weight: bold;
          }
        }
      }
    }
  `(),
);

const API_LINK = "https://docs.trolley.com/api/#payout-methods-by-country";

type RoutingType = {
  icon: PayoutMethodType;
  payoutType: "bankTransfer" | "gateway";
  routingType: keyof FeeStructure["bankTransfer"] | keyof FeeStructure["gateway"];
  routingTypeDescription: string;
  country?: CountryCode | "EU";
  countryCurrencies?: CurrencyCode[];
  ref?: boolean;
  local?: true;
  instant?: true;
};

const bankTransferRoutingTypes: RoutingType[] = [
  {
    icon: PayoutMethodType.BANKTRANSFER,
    payoutType: "bankTransfer",
    routingType: "ach",
    routingTypeDescription: "ACH",
    country: CountryCode.US,
    countryCurrencies: [CurrencyCode.USD],
    local: true,
  },
  {
    icon: PayoutMethodType.BANKTRANSFER,
    payoutType: "bankTransfer",
    routingType: "eft",
    country: CountryCode.CA,
    countryCurrencies: [CurrencyCode.CAD, CurrencyCode.USD],
    routingTypeDescription: "EFT",
    local: true,
  },
  {
    icon: PayoutMethodType.BANKTRANSFER,
    payoutType: "bankTransfer",
    routingType: "fps",
    country: CountryCode.GB,
    countryCurrencies: [CurrencyCode.GBP],
    routingTypeDescription: "FPS",
    local: true,
    instant: true,
  },
  {
    icon: PayoutMethodType.BANKTRANSFER,
    payoutType: "bankTransfer",
    routingType: "npp",
    country: CountryCode.AU,
    countryCurrencies: [CurrencyCode.AUD],
    routingTypeDescription: "NPP",
    local: true,
  },
  {
    icon: PayoutMethodType.BANKTRANSFER,
    payoutType: "bankTransfer",
    routingType: "becs",
    country: CountryCode.NZ,
    countryCurrencies: [CurrencyCode.NZD],
    routingTypeDescription: "BECS",
    local: true,
  },
  {
    icon: PayoutMethodType.BANKTRANSFER,
    payoutType: "bankTransfer",
    routingType: "fpshk",
    country: CountryCode.HK,
    countryCurrencies: [CurrencyCode.HKD],
    routingTypeDescription: "FPSHK",
    local: true,
  },
  {
    icon: PayoutMethodType.BANKTRANSFER,
    payoutType: "bankTransfer",
    routingType: "sepa",
    country: "EU",
    countryCurrencies: [CurrencyCode.EUR],
    ref: true,
    routingTypeDescription: "SEPA",
    local: true,
    instant: true,
  },
  {
    icon: PayoutMethodType.BANKTRANSFER,
    payoutType: "bankTransfer",
    routingType: "iach",
    routingTypeDescription: "IACH",
    ref: true,
    local: true,
  },
  {
    icon: PayoutMethodType.BANKTRANSFER,
    payoutType: "bankTransfer",
    routingType: "wire",
    routingTypeDescription: "Swift Wire",
    ref: true,
  },
  {
    icon: PayoutMethodType.BANKTRANSFER,
    payoutType: "bankTransfer",
    routingType: "wire_no_fx",
    routingTypeDescription: "Same Currency Swift Wire",
  },
  {
    icon: PayoutMethodType.CHECK,
    payoutType: "gateway",
    routingType: "check",
    routingTypeDescription: "Check",
  },
  {
    icon: PayoutMethodType.PAYPAL,
    payoutType: "gateway",
    routingType: "paypal",
    routingTypeDescription: "PayPal *",
    instant: true,
  },
  {
    icon: PayoutMethodType.VENMO,
    payoutType: "gateway",
    routingType: "venmo",
    routingTypeDescription: "Venmo *",
    instant: true,
  },
];

const additionalFees = {
  CAD: "35.00",
  USD: "30.00",
  EUR: "30.00",
  AUD: "30.00",
  CHF: "24.00",
};

export function getCoverAmountColumns(balances: Account[], fees: Mapped<FeeStructure>, settingsWriteAccess: boolean): TableColumnsType<RoutingType> {
  const feesCurrencies = Object.values(fees).map((f: FeeStructure) => f.currencyCode);

  return [
    {
      title: "Sending Methods",
      key: "country",
      render: (record: RoutingType) => (
        <div>
          <PayoutMethodDisplay value={record.icon} showTooltip left />
          {record.routingTypeDescription}
          {record.country && ` in ${record.country === "EU" ? "Europe" : formatCountry(record.country)}`}
          {record.countryCurrencies && ` (${record.countryCurrencies.join(", ")})`}
          {record.local && <Status size="small" right type="local" colors={{ local: "green" }} />}
          {/* {record.instant && <Status className={instantTag} size="small" right type="instant" style={{ backgroundColor: "transparent" }} />} */}
          {record.ref && (
            <ExternalLink href={API_LINK}>
              {" "}
              - see countries <Icon type="external-link-alt" right />
            </ExternalLink>
          )}
        </div>
      ),
    },
    {
      title: "Fees Splits for Sending Currencies",
      children: uniqueList(balances.map((b) => b.amount.currency))
        .filter((c) => feesCurrencies.includes(c))
        .map((currency) => ({
          key: currency,
          children: [
            {
              title: "Cover up to",
              key: `${currency}coverUpTo`,
              align: "right",
              width: 160,
              render: (record: RoutingType) => {
                const totalFees = fees[currency]?.[record.payoutType]?.[record.routingType];
                const isTotalFeesZero = new BigNumber(totalFees).isEqualTo(0);

                return (
                  <div>
                    <InputLive
                      style={{ minWidth: "100px", maxWidth: "120px" }}
                      disabled={!settingsWriteAccess || isTotalFeesZero}
                      inputMode="decimal"
                      onSave={async (value: string) => {
                        if (currency && value) {
                          await updateMerchantCoverAmount(currency, new BigNumber(value).toFixed(2), record.payoutType, record.routingType);
                          notifySuccess(`${currency} cover amount updated`);
                        }
                      }}
                      name={currency}
                      formOptions={{
                        rules: [
                          { required: true, message: "Enter an amount" },
                          { pattern: isCurrencyAmount, message: "Enter a valid amount" },
                          {
                            async validator(rule, value) {
                              const enteredFee = new BigNumber(value);
                              if (enteredFee.isGreaterThan(totalFees)) {
                                throw "Number must be equal or less that the Total Fees";
                              }
                            },
                          },
                        ],
                      }}
                      value={
                        isTotalFeesZero ? new BigNumber(0).toFixed(2) : fees[currency]?.general.merchantCoverAmount[record.payoutType]?.[record.routingType]
                      }
                      suffix={currency}
                      maxLength={6}
                    />
                  </div>
                );
              },
            },
            {
              title: `${currency} Total Fees`,
              key: `${currency}totalFees`,
              align: "right",
              width: 160,
              render: (record: RoutingType) => {
                if (record.routingType === "check" && currency !== CurrencyCode.USD) {
                  return " - ";
                }

                return <CurrencyDisplay value={fees[currency]?.[record.payoutType]?.[record.routingType]} currency={currency} />;
              },
            },
            {
              title: "Merchant Fees",
              key: `${currency}merchantCover`,
              align: "right",
              width: 100,
              render: (record: RoutingType) => {
                if (record.routingType === "check" && currency !== CurrencyCode.USD) {
                  return " - ";
                }

                return (
                  <>
                    <CurrencyDisplay
                      value={BigNumber.minimum(
                        fees[currency]?.[record.payoutType]?.[record.routingType],
                        fees[currency]?.general.merchantCoverAmount[record.payoutType]?.[record.routingType] || 0,
                      ).toNumber()}
                      currency={currency}
                    />
                    <Tooltip
                      title={
                        <>
                          {record.routingTypeDescription} fees:{" "}
                          <CurrencyDisplay value={fees[currency]?.[record.payoutType]?.[record.routingType]} currency={currency} />
                          <br />
                          You cover up to{" "}
                          <CurrencyDisplay
                            value={fees[currency]?.general.merchantCoverAmount[record.payoutType]?.[record.routingType] || 0}
                            currency={currency}
                          />
                        </>
                      }
                    >
                      <Icon type="circle-info" right />
                    </Tooltip>
                  </>
                );
              },
            },
            {
              title: "Recipient Fees",
              key: `${currency}recipientCover`,
              align: "right",
              width: 100,
              render: (record: RoutingType) => {
                if (record.routingType === "check" && currency !== CurrencyCode.USD) {
                  return " - ";
                }

                const routingTypeFees = fees[currency]?.[record.payoutType]?.[record.routingType];
                const recipientFees = new BigNumber(routingTypeFees).minus(
                  BigNumber.minimum(routingTypeFees, fees[currency]?.general.merchantCoverAmount[record.payoutType]?.[record.routingType] || "0"),
                );

                return (
                  <>
                    <CurrencyDisplay value={recipientFees} currency={currency} />
                    <Tooltip
                      title={
                        <>
                          {record.routingTypeDescription} fees:{" "}
                          <CurrencyDisplay value={fees[currency]?.[record.payoutType]?.[record.routingType]} currency={currency} />
                          <br />
                          You cover up to{" "}
                          <CurrencyDisplay
                            value={fees[currency]?.general.merchantCoverAmount[record.payoutType]?.[record.routingType] || 0}
                            currency={currency}
                          />
                          <br />
                          The recipient will pay <CurrencyDisplay value={recipientFees} currency={currency} />
                        </>
                      }
                    >
                      <Icon type="circle-info" right />
                    </Tooltip>
                  </>
                );
              },
            },
          ],
        })),
    },
  ];
}

export default function Fees() {
  const { features, data: merchantSettings } = useMerchantSettings();
  const { data, status: balancesStatus, error: balancesErrors } = useBalances();
  const settingsWriteAccess = useAccess(Access.SETTINGS_WRITE);
  const { data: fees, error: feesErrors, status: feesStatus } = useFees();
  const primaryCurrency = merchantSettings?.primaryCurrency || CurrencyCode.USD;
  const filteredBankTransferRoutingTypes = bankTransferRoutingTypes.filter((type) => {
    if (type.routingType === "check") {
      return merchantSettings?.country === CountryCode.US && !!features.payoutCheck;
    }

    return true;
  });
  const styledTable = useStyledTable();

  return (
    <Container>
      <Form compact>
        <Helmet>
          <title>Fee Schedule</title>
        </Helmet>
        <Heading>Fee Schedule</Heading>

        <Error errors={feesErrors || balancesErrors} />

        <Table
          data-testid="fee-table"
          title={
            <>
              <Heading tag="h2">Fees Splitting</Heading>
              If you wish to split the fees with your recipients, you can enter the amount of the fees you wish to cover up to. The recipient will pay the
              difference.
            </>
          }
          status={[balancesStatus, feesStatus].some((status) => status === BaseStatus.LOADING) ? BaseStatus.LOADING : balancesStatus}
          rowKey="routingType"
          className={styledTable}
          columns={getCoverAmountColumns(data, fees, settingsWriteAccess)}
          dataSource={filteredBankTransferRoutingTypes}
          pagination={false}
          footer={() => <Text>* PayPal and Venmo fees still apply.</Text>}
        />
        <Divider transparent margin="small" />

        <Box header="Currency Conversion">
          {fees && (
            <Form.Item label="FX Rate">
              <div>{`${Number(fees[primaryCurrency]?.fxMargin.A).toFixed(2)}%`} (Currency Group A)</div>
              <div>{`${Number(fees[primaryCurrency]?.fxMargin.B).toFixed(2)}%`} (Currency Group B)</div>
              {Number(fees[primaryCurrency]?.fxMargin.C) !== Number(fees[primaryCurrency]?.fxMargin.A) && (
                <div>{`${Number(fees[primaryCurrency]?.fxMargin.C).toFixed(2)}%`} (Currency Group C)</div>
              )}
            </Form.Item>
          )}

          <Divider margin="small" />

          <Heading tag="h2">Additional Fees</Heading>
          <Form.Item label="Direct Debit NSF Fee" tooltip={<>If we receive a Non-Sufficient Funds (NSF) decline on Direct Debit for account funding.</>}>
            <CurrencyDisplay value={20} currency={CurrencyCode.USD} />
          </Form.Item>
          <Form.Item
            label="Return Fee"
            tooltip={
              <>
                We reserve the right to charge a return fee of <CurrencyDisplay value={5} currency={CurrencyCode.USD} /> per payment for excessive returns.
              </>
            }
          >
            <CurrencyDisplay value={5} currency={CurrencyCode.USD} />
          </Form.Item>
          {merchantSettings?.primaryCurrency && additionalFees[merchantSettings.primaryCurrency] && (
            <Form.Item
              label="Wire Trace"
              tooltip={
                <>
                  A wire trace is a last resort, we can provide a wire confirmation PDF on request for unreceived payments, which can be sent to the recipient
                  to provide to their bank as confirmation of payment – in approx. 90% of cases this is sufficient to find and credit the payment.
                </>
              }
            >
              <CurrencyDisplay value={additionalFees[merchantSettings.primaryCurrency]} currency={merchantSettings.primaryCurrency} />
            </Form.Item>
          )}
        </Box>

        {features.taxCenter && (
          <Box header="Tax Services">
            <p>Tax Services are billed on a monthly basis to the same credit card as your monthly subscription.</p>

            <Form.Item
              label="Tax Center"
              tooltip={
                <>Access to the Tax Center, U.S. IRS TIN Matching, W9/W8 collection, and review workflow is included in the Tax Standard Subscription Fee.</>
              }
            >
              Included in Tax Standard Plan or Tax Plus Plan
            </Form.Item>
            <Form.Item
              label="Cost for Generating 1099s and 1042s"
              tooltip={
                <>
                  Cost for the generation of an End of Year 1099-MISC, 1099-NEC, or 1042-S Tax Statement in PDF format. Downloads from the Tax Center,
                  E-Delivery via Recipient Widget/Portal, and recipient email notifications (if enabled) are included.
                </>
              }
            >
              <Table
                style={{ maxWidth: "400px" }}
                size="small"
                columns={[
                  { title: "Subscription Plan", dataIndex: "tiers" },
                  {
                    title: "Cost Per Statement",
                    dataIndex: "cost",
                    render: (cost: string) =>
                      cost && new BigNumber(cost).isGreaterThan(0) ? (
                        <CurrencyDisplay value={cost} currency={CurrencyCode.USD} />
                      ) : (
                        <>Contact Us for Tax Plus Pricing</>
                      ),
                  },
                ]}
                dataSource={[
                  {
                    tiers: <>Tax Standard</>,
                    cost: TAX_STATEMENT_FEES_V2.base.standard,
                  },
                  {
                    tiers: <>Tax Plus</>,
                    cost: TAX_STATEMENT_FEES_V2.base.plus,
                  },
                ]}
              />
            </Form.Item>

            <Form.Item
              label="Additional Cost for 1099s and 1042s Delivered by Mail"
              tooltip="Printing and mailing of physical EOY tax statements to recipients in the US or International (Note: An International Postage Fee applies in addition if mailed outside United States)."
            >
              <Table
                style={{ maxWidth: "400px" }}
                size="small"
                columns={[
                  { title: "Number of Statements per Year", dataIndex: "tiers" },
                  {
                    title: "Cost Per Statement",
                    dataIndex: "cost",
                    render: (cost: string) =>
                      cost && new BigNumber(cost).isGreaterThan(0) ? (
                        <CurrencyDisplay value={cost} currency={CurrencyCode.USD} />
                      ) : (
                        <>Contact Us for Tax Plus Pricing</>
                      ),
                  },
                ]}
                dataSource={[
                  {
                    tiers: <>Tax Standard</>,
                    cost: TAX_STATEMENT_FEES_V2.mail.standard,
                  },
                  {
                    tiers: <>Tax Plus</>,
                    cost: TAX_STATEMENT_FEES_V2.mail.plus,
                  },
                ]}
              />
            </Form.Item>

            <Form.Item
              label="Additional Cost for Mailed 1099s and 1042s with non-U.S. address"
              tooltip="Additional postage fee for mailing of physical EOY tax statements to international recipients with mailing addresses outside of United States"
            >
              <Table
                style={{ maxWidth: "400px" }}
                size="small"
                columns={[
                  { title: "Number of Statements per Year", dataIndex: "tiers" },
                  {
                    title: "Cost Per Statement",
                    dataIndex: "cost",
                    render: (cost: string) =>
                      cost && new BigNumber(cost).isGreaterThan(0) ? (
                        <CurrencyDisplay value={cost} currency={CurrencyCode.USD} />
                      ) : (
                        <>Contact Us for Tax Plus Pricing</>
                      ),
                  },
                ]}
                dataSource={[
                  {
                    tiers: <>Tax Standard</>,
                    cost: TAX_STATEMENT_FEES_V2.international.standard,
                  },
                  {
                    tiers: <>Tax Plus</>,
                    cost: TAX_STATEMENT_FEES_V2.international.plus,
                  },
                ]}
              />
            </Form.Item>

            <Text type="secondary" style={{ pointerEvents: "none" }}>
              <Icon type="circle-info" color="blue" theme="solid" left />
              E.g. Mailing a single statement to France would cost{" "}
              <CurrencyDisplay
                value={TAX_STATEMENT_FEES.base.tier1 + TAX_STATEMENT_FEES.mail.tier1 + TAX_STATEMENT_FEES.international}
                currency={CurrencyCode.USD}
              />
              <br />
              <Tag>
                <CurrencyDisplay value={TAX_STATEMENT_FEES.base.tier1} currency={CurrencyCode.USD} /> (generation fee)
              </Tag>
              +{" "}
              <Tag>
                <CurrencyDisplay value={TAX_STATEMENT_FEES.mail.tier1} currency={CurrencyCode.USD} /> (mailing fee)
              </Tag>
              +{" "}
              <Tag>
                <CurrencyDisplay value={TAX_STATEMENT_FEES.international} currency={CurrencyCode.USD} /> (internation postage fee)
              </Tag>
            </Text>
          </Box>
        )}
      </Form>
    </Container>
  );
}
