import store from "store";
import { OpCode, standardDispatch } from "store/dispatcher";
import * as request from "services/request";
import * as types from "@trolley/common-frontend";
import { isLoaded } from "./actionUtils";

export interface IFrameConfig extends types.IFrameConfig {}

export interface IFrameConfigUpdate extends types.RecursivePartial<IFrameConfig> {}

export function loadIframeConfig(force?: boolean) {
  const { iframeConfig } = store.getState();

  if (force || !isLoaded(iframeConfig.fetchStatus.data)) {
    standardDispatch(OpCode.LOADING, "iframeConfig");

    request
      .GET<types.IFrameConfigResult>("/v1/iframe/config")
      .then((res) => {
        standardDispatch(OpCode.DATA, "iframeConfig", {
          data: res.body.iframeConfig,
        });
      })
      .catch((errors) => {
        standardDispatch(OpCode.ERROR, "iframeConfig", {
          errors,
        });
      });
  }
}

export async function saveIframeConfig(data: IFrameConfigUpdate) {
  standardDispatch(OpCode.LOADING, "iframeConfig");
  try {
    const res = await request.POST<types.IFrameConfigResult>("/v1/iframe/config", { query: data });
    standardDispatch(OpCode.DATA, "iframeConfig", {
      data: res.body.iframeConfig,
    });
  } catch (errors) {
    standardDispatch(OpCode.ERROR, "iframeConfig", {
      errors,
    });
    throw errors;
  }
}
