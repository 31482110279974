import { Access, BaseError, RecipientStatus, RecipientType, TaxStatus } from "@trolley/common-frontend";
import { ButtonDropdown, DateDisplay, Dropdown, Flag, Icon, LabelTooltip, Menu, Space, Table, TableFilterField, TableFilters, Text, Tooltip } from "components";
import { TableColumnsType } from "components/Table";
import ColumnsDisplay, { MixedColumn, TableName, tableColumnFilter, useColumnsDisplay } from "components/Table/ColumnsDisplay";
import dayjs from "dayjs";
import { RecipientProfile } from "features/recipient";
import { StatusTaxProfile } from "features/taxProfile";
import { TaxProfileActions } from "features/taxProfile/TaxProfileActions";
import { UserProfile } from "features/user";
import TaxProfilePreview from "pages/RecipientsPage/Details/TaxProfile/TaxProfilePreview";
import React, { ReactNode, useState } from "react";
import { notifyError, notifySuccess } from "store/actions/notifications";
import { approveRecipientTaxProfiles } from "store/actions/recipientTaxProfiles";
import { TaxProfile, TaxProfilesQuery } from "store/actions/taxProfiles";
import { useShallowSelector } from "store/hooks";
import { useAccess } from "store/hooks/user";
import { BaseStatus, ListState } from "store/reducers/standardReducer";
import styled from "style/classname";
import getFTinLabel from "utils/helpers/ftins";
import VoidProfilePopup from "./VoidProfilePopup";

const styledTableRow = styled`
  height: 60px !important;
`();

export enum TaxFilterKeys {
  ADDRESS_COUNTRY = "primaryCountry",
  TIN_COUNTRY = "tinCountry",
  REPORTABLE_STATUS = "reportable",
  RECIPIENT_TYPE = "recipientType",
  PROFILE_STATUS = "status",
  SIGNED_ON = "signedAt",
  VOIDED_AT = "voidedAt",
  RECIPIENT_ID = "recipientId",
  TIN_STATUS = "tinStatus",
}

enum ColumnKeys {
  STATUS = "status",
  RECIPIENT = "recipientId",
  COUNTRY = "country",
  RECIPIENT_TYPE = "recipientType",
  DAC7_TAX_CLASSIFICATION = "dac7TaxClassification",
  FTIN_TYPE = "fTinType",
  SIGNED_AT = "signedAt",
  UPDATED_AT = "updatedAt",
  ACTIONS = "actions",
}

const defaultColumnsDisplay: Record<ColumnKeys, MixedColumn> = {
  [ColumnKeys.STATUS]: true,
  [ColumnKeys.RECIPIENT]: true,
  [ColumnKeys.ACTIONS]: true,
  [ColumnKeys.COUNTRY]: {
    label: "Tax Profile Country",
    value: true,
  },
  [ColumnKeys.RECIPIENT_TYPE]: {
    label: "Recipient Type",
    value: true,
  },
  [ColumnKeys.DAC7_TAX_CLASSIFICATION]: {
    label: "DAC7 Tax Classification",
    value: true,
  },
  [ColumnKeys.FTIN_TYPE]: {
    label: "FTIN Type",
    value: true,
  },
  [ColumnKeys.SIGNED_AT]: {
    label: "Signed On",
    value: true,
  },
  [ColumnKeys.UPDATED_AT]: {
    label: "Updated On",
    value: false,
  },
};

enum ButtonActions {
  APPROVE = "approve",
  VOID = "void",
}

function getExcludedColumns(showRecipients: boolean, accessTaxWrite: boolean) {
  const cols: ColumnKeys[] = [];

  if (!showRecipients) {
    cols.push(ColumnKeys.RECIPIENT);
  }

  if (!accessTaxWrite) {
    cols.push(ColumnKeys.ACTIONS);
  }

  return cols;
}

interface Props {
  taxProfiles: ListState<TaxProfile>;
  status?: BaseStatus;
  errors?: BaseError[];
  showRecipients?: boolean;
  showFilters?: boolean;
  query: TaxProfilesQuery;
  tableHeader?: ReactNode;
  onChangeQuery(newQuery: Partial<TaxProfilesQuery> | ((q: Partial<TaxProfilesQuery>) => Partial<TaxProfilesQuery>)): void;
}

export default function TaxProfilesTable(props: Props) {
  const { taxProfiles, query, showRecipients = true, showFilters, status, errors, tableHeader } = props;
  const accessTaxWrite = useAccess(Access.TAX_WRITE);
  const [previewProfile, setPreviewProfile] = useState<TaxProfile | undefined>();
  const [selected, setSelected] = useState<TaxProfile[]>([]);
  const [voidProfile, setVoidProfile] = useState<{ taxProfiles: TaxProfile[] } | undefined>();
  const [displayedColumns, setDisplayedColumns] = useColumnsDisplay(
    TableName.TAX_PROFILES,
    defaultColumnsDisplay,
    getExcludedColumns(showRecipients, accessTaxWrite),
  );
  const recipientEntities = useShallowSelector((state) => state.recipient.entities);
  const selectedApprovable = selected.filter(
    (t) =>
      t.status === TaxStatus.SUBMITTED &&
      recipientEntities[t.recipientId]?.status &&
      ![RecipientStatus.ARCHIVED, RecipientStatus.BLOCKED].includes(recipientEntities[t.recipientId]?.status as RecipientStatus),
  );
  const selectedVoidable = selected.filter((t) => t.status !== TaxStatus.VOIDED);
  const selectedDateColumn = query.dateColumn || "signedAt";

  const FIELDS: TableFilterField[] = [
    {
      label: "Profile Status",
      filterKey: TaxFilterKeys.PROFILE_STATUS,
      type: "select",
      options: [
        { label: "Reviewed", value: TaxStatus.REVIEWED },
        { label: "Submitted", value: TaxStatus.SUBMITTED },
        { label: "Voided", value: TaxStatus.VOIDED },
        { label: "Incomplete", value: TaxStatus.INCOMPLETE },
        { label: "Expired", value: TaxStatus.EXPIRED },
      ],
    },
    {
      label: "Tax Profile Country",
      filterKey: TaxFilterKeys.ADDRESS_COUNTRY,
      type: "select-countries",
      topCountries: [],
    },
    {
      label: "TIN Country",
      filterKey: TaxFilterKeys.TIN_COUNTRY,
      type: "select-countries",
      topCountries: [],
    },
    {
      label: "Reportable Status",
      filterKey: TaxFilterKeys.REPORTABLE_STATUS,
      type: "radio",
      options: [
        { label: "All", value: undefined },
        { label: "Reportable", value: true },
        { label: "Not Reportable", value: false },
      ],
    },
    {
      label: "Recipient Type",
      filterKey: TaxFilterKeys.RECIPIENT_TYPE,
      type: "radio",
      options: [
        { label: "All", value: undefined },
        { label: "Business", value: RecipientType.BUSINESS },
        { label: "Individual", value: RecipientType.INDIVIDUAL },
      ],
    },
    {
      label: (
        <Text capitalize inline>
          <Dropdown
            placement="bottomLeft"
            overlay={
              <Menu
                onClick={(params) => {
                  props.onChangeQuery((state: TaxProfilesQuery) => {
                    const dateColumn = params.key as "signedAt" | "voidedAt";
                    if (state.startDate && state.endDate) {
                      return {
                        dateColumn,
                        startDate: dayjs(state.startDate).startOf("date").format(),
                        endDate: dayjs(state.endDate).endOf("date").format(),
                      };
                    } else {
                      return {
                        dateColumn,
                      };
                    }
                  });
                }}
              >
                <Menu.Item disabled={query.dateColumn === "signedAt"} key={TaxFilterKeys.SIGNED_ON}>
                  Signed Between
                </Menu.Item>
                <Menu.Item disabled={query.dateColumn === "voidedAt"} key={TaxFilterKeys.VOIDED_AT}>
                  Voided Between
                </Menu.Item>
              </Menu>
            }
          >
            <span>
              {selectedDateColumn.replace("At", " Between")}
              <Icon type="angle-down" size="small" right />
            </span>
          </Dropdown>
        </Text>
      ),
      filterKey: ["startDate", "endDate"],
      type: "date-range",
    },
    {
      label: "Recipient ID",
      type: "tags",
      filterKey: TaxFilterKeys.RECIPIENT_ID,
      placeholder: "Recipient ID",
      maxLength: 1,
      renderTag: (id) => <RecipientProfile onlyName recipientId={id} />,
    },
    {
      label: "TIN Status",
      filterKey: TaxFilterKeys.TIN_STATUS,
      type: "select",
      options: [
        { label: "Valid", value: "valid" },
        { label: "Invalid", value: "not_valid" },
        { label: "Missing", value: "missing" },
      ],
    },
  ];

  function onAction(mainAction: ButtonActions) {
    if (mainAction === ButtonActions.VOID) {
      setVoidProfile({ taxProfiles: selectedVoidable });
    } else if (mainAction === ButtonActions.APPROVE && selectedApprovable.length > 0) {
      const recipientIds = [...new Set(selectedApprovable.map((tp) => tp.recipientId))];
      Promise.allSettled(
        recipientIds.map((recipientId) => {
          const recipientTaxProfileIds = selectedApprovable.filter((tp) => tp.recipientId === recipientId).map((tp) => tp.id);

          return approveRecipientTaxProfiles(recipientId, recipientTaxProfileIds);
        }),
      )
        .then((settled) => {
          notifySuccess(`${settled.filter((s) => s.status === "fulfilled").length} tax profiles marked as reviewed`);
          setSelected([]);
        })
        .catch(() => {
          notifyError("Approving tax profiles failed");
        });
    }
  }

  function getMenu() {
    const enableApprove = selectedApprovable.length > 0;
    const enableVoid = selectedVoidable.length > 0;

    return (
      <ButtonDropdown
        onClick={() => {
          onAction(ButtonActions.APPROVE);
        }}
        overlay={
          <Menu
            items={[
              {
                key: ButtonActions.APPROVE,
                disabled: !enableApprove,
                onClick: () => {
                  onAction(ButtonActions.APPROVE);
                },
                label: (
                  <>
                    <Icon type="file-check" color={enableApprove ? "blue" : undefined} left />
                    Mark as Reviewed ({selectedApprovable.length})
                  </>
                ),
              },
              {
                key: ButtonActions.VOID,
                disabled: !enableVoid,
                onClick: () => {
                  onAction(ButtonActions.VOID);
                },
                label: (
                  <>
                    <Icon type="file-slash" left color={enableVoid ? undefined : "grey"} />
                    Void Tax Forms ({selectedVoidable.length})
                  </>
                ),
              },
            ]}
          />
        }
      >
        Mark as Reviewed ({selectedApprovable.length})
      </ButtonDropdown>
    );
  }

  function renderFTINTypeColumn(tins: TaxProfile["tins"]) {
    if (!tins || tins.length === 0) return "-";
    if (tins.length === 1) {
      const tin = tins[0];

      return (
        <Space>
          <Flag code={tin.tinCountry} showLabel={false} />
          {getFTinLabel(tin.tinCountry, tin.tinType)}
        </Space>
      );
    }
    const content = (
      <ul>
        {tins.map(({ tinCountry, tinType, otherTinDescription }) => (
          <li key={tinType ?? otherTinDescription}>
            <Space>
              <Flag code={tinCountry} showLabel={false} />
              {getFTinLabel(tinCountry, tinType)}
            </Space>
          </li>
        ))}
      </ul>
    );

    return (
      <Tooltip title={content}>
        <Icon type="circle-info" left />
        Multiple
      </Tooltip>
    );
  }

  const columns = (
    [
      {
        key: ColumnKeys.STATUS,
        title: "Profile Status",
        width: 100,
        dataIndex: "status",
        align: "center",
        render: (status: string, tp: TaxProfile) => <StatusTaxProfile taxProfile={tp} showTooltipWarnings />,
      },
      {
        key: ColumnKeys.RECIPIENT,
        title: "Recipient",
        dataIndex: "recipientId",
        render: (id: TaxProfile["recipientId"]) => <RecipientProfile size="small" recipientId={id} showLink showAddress showStatus="dot" />,
      },
      {
        key: ColumnKeys.COUNTRY,
        title: "Tax Profile Country",
        dataIndex: "primaryCountry",
        render: (primaryCountry: TaxProfile["primaryCountry"]) => (primaryCountry ? <Flag showLabel code={primaryCountry} /> : "-"),
      },
      {
        key: ColumnKeys.RECIPIENT_TYPE,
        title: "Recipient Type",
        dataIndex: "recipientType",
        render: (recipientType: TaxProfile["recipientType"]) => (recipientType ? (recipientType === RecipientType.BUSINESS ? "Business" : "Individual") : "-"),
      },
      {
        key: ColumnKeys.DAC7_TAX_CLASSIFICATION,
        title: <LabelTooltip type="dac7Classification" />,
        dataIndex: "classification",
        render: (classification: TaxProfile["classification"]) => classification ?? "-",
      },
      {
        key: ColumnKeys.FTIN_TYPE,
        title: "FTIN Type",
        dataIndex: "tins",
        render: (tins: TaxProfile["tins"]) => renderFTINTypeColumn(tins),
      },
      {
        key: ColumnKeys.SIGNED_AT,
        title: "Signed On (UTC)",
        dataIndex: "signedAt",
        render: (signedAt: string, tp: TaxProfile) => (
          <>
            <DateDisplay value={signedAt} time={false} showUtc />
            {tp.signed && (
              <Text>
                by <UserProfile userId={tp.signed} style={{ display: "inline-flex" }} />
              </Text>
            )}
          </>
        ),
      },
      {
        key: ColumnKeys.UPDATED_AT,
        title: "Updated On",
        dataIndex: "updatedAt",
        render: (updatedAt: string) => <DateDisplay value={updatedAt} time={false} />,
      },
      {
        key: ColumnKeys.ACTIONS,
        title: <LabelTooltip type="profileActions" />,
        fixed: "right",
        align: "right",
        width: 32,
        dataIndex: "id",
        render: (id: string, record) => <TaxProfileActions taxProfileId={id} compact />,
      },
    ] as TableColumnsType<TaxProfile>
  ).filter(tableColumnFilter(displayedColumns));

  return (
    <>
      <TableFilters
        showSearch={showFilters}
        fields={showFilters ? FIELDS : undefined}
        query={query}
        onChange={props.onChangeQuery}
        addOnAfter={tableHeader}
        suffix={
          <Space direction="row-reverse">
            <ColumnsDisplay columns={displayedColumns} onChange={setDisplayedColumns} />
          </Space>
        }
      >
        {selected.length > 0 ? getMenu() : null}
      </TableFilters>

      <Table<TaxProfile>
        columns={columns}
        rowClassName={() => styledTableRow}
        dataSource={taxProfiles.records}
        rowSelection={
          showFilters && accessTaxWrite
            ? {
                selectedRowKeys: selected.map((tf) => tf.id),
                onChange: (keys: string[], selectedRows: TaxProfile[] = []) => {
                  setSelected(selectedRows);
                },
              }
            : undefined
        }
        onRow={(tp) => {
          return {
            onClick: () => {
              setPreviewProfile(tp);
            },
          };
        }}
        emptyProps={{
          description: "No tax profiles submitted",
        }}
        onQueryChange={props.onChangeQuery} // need to handle sortBy[] and orderBy[]
        pagination={{
          current: query.page,
          pageSize: query.pageSize,
          total: taxProfiles.meta.records,
        }}
        status={status}
        errors={errors}
      />

      <TaxProfilePreview
        taxProfileId={previewProfile?.id}
        showActions
        onClose={() => {
          setPreviewProfile(undefined);
        }}
      />

      <VoidProfilePopup
        taxProfiles={voidProfile?.taxProfiles}
        onClose={() => {
          setVoidProfile(undefined);
          setSelected([]);
        }}
      />
    </>
  );
}
