import { Access } from "@trolley/common-frontend";
import { Collapse } from "antd";
import BigNumber from "bignumber.js";
import { Box, ButtonLink, CopyToClipboard, CurrencyDisplay, DateDisplay, Flyout, Form, InputTags, LabelTooltip, Text } from "components";
import dayjs from "dayjs";
import { StatusInvoice } from "features/invoice";
import { InvoicePaymentsLoader, InvoicePaymentsTimeline } from "features/invoicePayment";
import { RecipientProfile } from "features/recipient";
import React from "react";
import { useLocation } from "react-router-dom";
import { updateInvoice } from "store/actions/invoices";
import { notifyError } from "store/actions/notifications";
import { useInvoice } from "store/hooks/invoices";
import { useAccess } from "store/hooks/user";
import { getPaymentCategoryLabel } from "utils/helpers";

export default function InvoicePreview(props: { invoiceId: string | undefined; onClose(e?: any): void }) {
  const { invoiceId, onClose } = props;
  const { data: invoice } = useInvoice(invoiceId);
  const accessInvoiceWrite = useAccess(Access.INVOICE_WRITE);
  const { pathname } = useLocation();

  return (
    <Flyout
      open={!!invoice}
      onClose={onClose}
      title={`Invoice ${invoice?.invoiceNumber || "Details"}`}
      width="small"
      extra={
        invoice &&
        !pathname.includes(invoice.id) && (
          <ButtonLink type="primary" path={`/invoices/${invoice.id}`}>
            View Invoice
          </ButtonLink>
        )
      }
    >
      {invoice && (
        <Form layout="horizontal" compact>
          <Box padding="small" header="Payable to">
            <RecipientProfile recipientId={invoice.recipientId} showLink showStatus showEmail showAddress />
          </Box>

          <Box padding="small" header="Invoice Details">
            <Form.Item label="Status">
              <StatusInvoice type={invoice.status} />
            </Form.Item>

            <Form.Item label="Invoice ID">
              <CopyToClipboard value={invoice.id} />
            </Form.Item>

            <Form.Item label="Invoice Number">{invoice.invoiceNumber || " - "}</Form.Item>

            {invoice.description && <Form.Item label="Description">{invoice.description}</Form.Item>}

            <Form.Item label="Invoice Date">
              <DateDisplay value={invoice.invoiceDate} showUtc time={false} />
            </Form.Item>

            <Form.Item label="Due Date">
              {invoice.dueDate ? (
                <Text type={dayjs.utc(invoice.dueDate).isSameOrBefore(dayjs(), "date") ? "error" : "text"}>
                  <DateDisplay value={invoice.dueDate} showUtc time={false} />
                </Text>
              ) : (
                " - "
              )}
            </Form.Item>

            {invoice.totalAmount && (
              <Form.Item label="Total Amount">
                <CurrencyDisplay value={invoice.totalAmount.value} currency={invoice.totalAmount.currency} />
              </Form.Item>
            )}

            {invoice.paidAmount && (
              <Form.Item label="Paid Amount">
                <CurrencyDisplay value={invoice.paidAmount.value} currency={invoice.paidAmount.currency} />
              </Form.Item>
            )}

            {invoice.dueAmount && (
              <Form.Item label="Balance Due">
                <CurrencyDisplay value={invoice.dueAmount.value} currency={invoice.dueAmount.currency} />
              </Form.Item>
            )}

            {invoice.externalId && (
              <Form.Item label="External ID">
                <CopyToClipboard value={invoice.externalId} />
              </Form.Item>
            )}

            {invoice.tags && (
              <Form.Item label="Tags">
                <InputTags
                  value={invoice.tags}
                  editable={accessInvoiceWrite && invoice.status !== "deleted"}
                  onChange={async (tags) => {
                    try {
                      await updateInvoice(invoice.id, { tags });
                    } catch (errors) {
                      notifyError("Update invoice tags failed", { errors });
                    }
                  }}
                />
              </Form.Item>
            )}
          </Box>

          <Box padding="none" header="Line Items">
            <Collapse
              bordered={false}
              items={invoice.lines.map((line, index) => ({
                key: line.id,
                label: (
                  <>
                    Item {index + 1}
                    {line.description && (
                      <Text type="secondary" inline>
                        : {line.description}
                      </Text>
                    )}
                  </>
                ),
                children: (
                  <Text style={{ paddingLeft: "24px" }}>
                    <Form.Item label="Quantity">{line.quantity}</Form.Item>
                    <Form.Item label="Rate / Unit Cost">
                      <CurrencyDisplay value={line.unitAmount.value} currency={line.unitAmount.currency} />
                    </Form.Item>
                    {new BigNumber(line.discountAmount.value).gt(0) && (
                      <Form.Item label="Discount">
                        <CurrencyDisplay value={line.discountAmount.value} currency={line.discountAmount.currency} />
                      </Form.Item>
                    )}

                    {new BigNumber(line.taxAmount.value).gt(0) && (
                      <Form.Item label="Tax">
                        <CurrencyDisplay value={line.taxAmount.value} currency={line.taxAmount.currency} />
                      </Form.Item>
                    )}

                    <Form.Item label="Subtotal">
                      <CurrencyDisplay value={line.totalAmount.value} currency={line.totalAmount.currency} />
                    </Form.Item>

                    <Form.Item label="Paid Amount">
                      <CurrencyDisplay value={line.paidAmount.value} currency={line.paidAmount.currency} />
                    </Form.Item>

                    <Form.Item label={<LabelTooltip type="category" />}>{getPaymentCategoryLabel(line.category)}</Form.Item>

                    <Form.Item label="Tax Reportable">{line.taxReportable ? "Tax Reportable" : "Exempt"}</Form.Item>

                    <Form.Item label={<LabelTooltip type="forceUsTaxActivity" />}>{line.forceUsTaxActivity ? "Yes" : "No"}</Form.Item>

                    {line.itemUrl && <Form.Item label="Item URL">{line.itemUrl}</Form.Item>}

                    {line.externalId && <Form.Item label="External ID">{line.externalId}</Form.Item>}
                  </Text>
                ),
              }))}
            />
          </Box>

          <Box padding="small" header="Related Payments">
            <InvoicePaymentsLoader id={invoice.id}>{({ data }) => <InvoicePaymentsTimeline invoicePayments={data} />}</InvoicePaymentsLoader>
          </Box>
        </Form>
      )}
    </Flyout>
  );
}
