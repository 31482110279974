import React from "react";
import css, { createUseStyle } from "style/classname";

export type CalculationRow = {
  symbol: any;
  label: any;
  value: any;
  isSubtotal?: boolean;
};

interface Props {
  dataSource: CalculationRow[];
}

export default function TableSum(props: Props) {
  const { dataSource } = props;
  const styledTable = useStyledTable();

  return (
    <table className={styledTable}>
      <tbody>
        {dataSource.map((row) => (
          <TableRow key={row.label} row={row} />
        ))}
      </tbody>
    </table>
  );
}

const useStyledTable = createUseStyle(({ theme }) =>
  css`
    width: 100%;
    border: 0;
    margin: 0;
    background-color: ${theme.colorBgBase};
    color: ${theme.colorTextBase};
    border-radius: ${theme.borderRadius}px;
  `(),
);

function TableRow({ row }: { row: CalculationRow }) {
  const styledRow = useStyledRow({ row });

  return (
    <tr key={row.label} className={styledRow}>
      <td className="row-label">{row.label}</td>
      <td className="row-symbol">{row.symbol}</td>
      <td className="row-value">{row.value}</td>
    </tr>
  );
}

const useStyledRow = createUseStyle<{ row: CalculationRow }>(({ theme, row }) =>
  css`
    &:first-child > td {
      padding-top: 12px;
    }
    & > td {
      white-space: nowrap;
      padding-left: 4px;
      padding-right: 4px;
      &:first-child {
        padding-left: 8px;
      }
      &:last-child {
        padding-right: 8px;
      }
    }
    ${row.isSubtotal &&
    `
      & > td {
        border-top: ${theme.lineWidth}px solid ${theme.colorBorderSecondary};
        font-weight: bold;
        padding-bottom: 12px;
      }
    `}
    .row-label {
      text-align: left;
    }
    .row-symbol {
      text-align: center;
    }
    .row-value {
      text-align: right;
    }
  `(),
);
