import { useState } from "react";
import { useDeepCompareEffect } from "utils/hooks";
import { useShallowSelector } from ".";
import { loadDac7EoySummary } from "store/actions/dac7EoySummary";
import { DAC7EoyTaxQuery } from "../actions/dac7EoyTaxForms";

export function useDac7EoySummary(queries: DAC7EoyTaxQuery[]) {
  const [fetchId, setFetchId] = useState("");

  useDeepCompareEffect(() => {
    setFetchId(loadDac7EoySummary(queries));
  }, [queries]);

  return useShallowSelector((state) => {
    if (fetchId && state.dac7EoySummary.fetchStatus[fetchId] === undefined) {
      loadDac7EoySummary(queries);
    }

    return {
      data: state.dac7EoySummary.entities[fetchId] || [],
      status: state.dac7EoySummary.fetchStatus[fetchId],
      error: state.dac7EoySummary.errors[fetchId],
    };
  });
}
