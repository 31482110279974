import { RoyaltyCalculatorIntegrations, UploadType } from "@trolley/common-frontend";
import { Text, UploadWindow } from "components";
import { MAX_FILE_SIZE } from "components/UploadWindow";
import React from "react";
import { useHistory } from "react-router-dom";
import { fileUpload } from "store/actions/files";
import { notifyError } from "store/actions/notifications";
import { useShallowSelector } from "store/hooks";
import { BaseStatus } from "store/reducers/standardReducer";
import XLSX from "xlsx";

interface Props {
  visible: boolean;
  calculator: RoyaltyCalculatorIntegrations | null;
  onClose(): void;
}

const ALLOWED_ROYALTY_MIME_TYPES = [
  ".csv",
  "text/csv",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.ms-excel",
  ".xlsx",
  "application/vnd.ms-excel.sheet.macroEnabled.12",
  "application/vnd.ms-excel.template.macroEnabled.12",
  "application/vnd.ms-excel.addin.macroEnabled.12",
  "application/vnd.ms-excel.sheet.binary.macroEnabled.12",
];

export default function AddRoyaltiesPopup(props: Props) {
  const { visible, calculator, onClose } = props;
  const history = useHistory();
  const royaltyBatchUploadStatus = useShallowSelector((state) => state.files.fetchStatus.data);

  async function onReadyToSubmit(formDataFiles: FormData) {
    if (calculator) {
      try {
        const id = await fileUpload(
          calculator === RoyaltyCalculatorIntegrations.CURVE ? UploadType.ROYALTY_CURVE_UPLOAD : UploadType.ROYALTY_VISTEX_UPLOAD,
          formDataFiles,
        );
        onClose();
        history.push(`/payments/upload/${calculator.toLowerCase()}/${id}`);
      } catch (e) {
        notifyError("Royalties Upload failed");
      }
    }
  }

  async function onUpload(formDataFiles: FormData) {
    if (calculator) {
      formDataFiles.forEach(async (file, key) => {
        const uploadedFile = file as File;
        if (uploadedFile.type === "text/csv") {
          // this logic works uploading one file at the time, if multiple files are allowed in the future, this logic should change
          await onReadyToSubmit(formDataFiles);

          return;
        }
        const reader = new FileReader();
        reader.readAsArrayBuffer(uploadedFile);
        reader.onload = async (e) => {
          try {
            const data = new Uint8Array(e.target?.result as ArrayBuffer);
            const workbook = XLSX.read(data, { type: "array" });
            const csv = XLSX.utils.sheet_to_csv(workbook.Sheets[workbook.SheetNames[0]]);
            const blob = new Blob([csv], { type: "text/csv;charset=utf-8," });
            formDataFiles.set(key, blob);
            await onReadyToSubmit(formDataFiles);
          } catch (e) {
            notifyError("Cannot upload spreadsheet file");
          }
        };
      });
    }
  }

  return (
    <UploadWindow
      title="Upload royalties"
      onClose={onClose}
      onSubmit={onUpload}
      loading={royaltyBatchUploadStatus === BaseStatus.LOADING}
      visible={visible}
      maxSize={MAX_FILE_SIZE}
      mimeTypes={ALLOWED_ROYALTY_MIME_TYPES}
    >
      <Text padded align="center">
        <p>
          {calculator === RoyaltyCalculatorIntegrations.CURVE && (
            <Text inline>
              Please ensure that the import includes a{" "}
              <Text inline weight="bold">
                Payee foreign ID
              </Text>{" "}
              column that is identical to Trolley's Recipient Reference Id.{" "}
              <Text inline weight="bold">
                Currency
              </Text>{" "}
              and{" "}
              <Text inline weight="bold">
                Closing Balance
              </Text>{" "}
              will also be imported.
            </Text>
          )}
          {calculator === RoyaltyCalculatorIntegrations.VISTEX && (
            <Text inline>
              Please ensure that the import includes the{" "}
              <Text inline weight="bold">
                Accounting System ID
              </Text>{" "}
              column that is identical to Trolley's Recipient Reference Id.{" "}
              <Text inline weight="bold">
                Royaltor Currency Code
              </Text>{" "}
              and{" "}
              <Text inline weight="bold">
                Balance
              </Text>{" "}
              will also be imported.
            </Text>
          )}
        </p>
        File Requirements: CSV or XLSX, {MAX_FILE_SIZE} MB limit.
      </Text>
    </UploadWindow>
  );
}
