import { Box, CopyToClipboard, DateDisplay, Divider, Status, Flyout, Form, FormItem } from "components";
import { RecipientPreview, RecipientProfile } from "features/recipient";
import React, { useState } from "react";
import { PhoneVerification } from "store/actions/verifications";
import { parsePhoneNumber } from "components/Form/InputPhone";
import { renderCountry } from ".";
import { PhoneVerificationChannels } from "@trolley/common-frontend";

interface Props {
  PhoneVerification?: PhoneVerification;
  onClose(): void;
}

export default function PhoneVerificationPreview(props: Props) {
  const { PhoneVerification, onClose } = props;
  const [recipientId, setRecipientId] = useState<string | undefined>();

  return (
    <Flyout
      visible={!!PhoneVerification}
      onClose={onClose}
      title={
        PhoneVerification && (
          <>
            Phone Verification <Status type={PhoneVerification.status} size="small" right />
          </>
        )
      }
      subtitle={
        PhoneVerification && (
          <>
            <Divider margin="medium" />
            <RecipientProfile
              recipientId={PhoneVerification.recipientId}
              showLink={() => {
                setRecipientId(PhoneVerification.recipientId);
              }}
              size="large"
              showEmail
              showAddress
            />
          </>
        )
      }
    >
      {PhoneVerification && (
        <Form layout="horizontal" compact>
          <Box padding="large" header="Verification Details">
            <FormItem label="Verification ID">
              <CopyToClipboard value={PhoneVerification.id} />
            </FormItem>
            <FormItem label="Phone Number">{PhoneVerification.verifiedData.phone || "-"}</FormItem>
            <FormItem label="Country">{renderCountry(parsePhoneNumber(PhoneVerification.verifiedData.phone).country)}</FormItem>
            <FormItem label="Verification Type">{PhoneVerification.verifiedData.channel === PhoneVerificationChannels.CALL ? "Call" : "SMS"}</FormItem>
            <FormItem label="Submitted At">
              <DateDisplay value={PhoneVerification.submittedAt} icon={false} />
            </FormItem>
            <FormItem label="Decision On">
              <DateDisplay value={PhoneVerification.decisionAt} icon={false} />
            </FormItem>
          </Box>
        </Form>
      )}
      <RecipientPreview
        recipientId={recipientId}
        onClose={() => {
          setRecipientId(undefined);
        }}
      />
    </Flyout>
  );
}
