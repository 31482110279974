import React, { ReactElement } from "react";
import { useHistory } from "react-router-dom";
import styled, { classnames, createUseStyle } from "style/classname";
import { ANIMATION } from "style/variables";

interface Props {
  id: string; // if location state highlighted word match, then highlight
  children: ReactElement;
}
export default function Highlight({ id, children }: Props) {
  const history = useHistory<{ highlightId?: string } | null>();
  const styledHighlight = useStyledHighlight();
  const highlighted = history.location.state?.highlightId === id;

  return highlighted
    ? React.cloneElement(children, {
        ...children.props,
        className: classnames(children.props.className, "highlight", { [styledHighlight]: highlighted }),
      })
    : children;
}

const useStyledHighlight = createUseStyle(({ theme }) =>
  styled`
  &.highlight {
    padding: 8px;
    border-radius: ${theme.borderRadius}px;
    background-color: ${theme.colorPrimaryBg};
    animation: ${ANIMATION.flash} 1s linear;
    animation-delay: 1s;
  }
`(),
);
