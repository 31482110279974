import { Switch as SwitchAnt, SwitchProps } from "antd";
import React from "react";
import css, { classnames, createUseStyle } from "style/classname";

interface Props extends SwitchProps {}

export default function Switch(props: Props) {
  const { className, ...rest } = props;
  const styleSwitch = useStyleSwitch();

  return <SwitchAnt {...rest} className={classnames(styleSwitch, className)} />;
}

Switch.__ANT_SWITCH = true; // fixes problem where wrapped tooltip won't show. https://github.com/ant-design/ant-design/blob/master/components/tooltip/index.tsx#L82

const useStyleSwitch = createUseStyle(({ theme }) =>
  css`
    .${theme.prefixCls}-switch-inner {
      box-shadow: ${theme.boxShadowInset};
      text-transform: uppercase;
      white-space: nowrap;
    }
  `(),
);
