import { Access, getReturnReasonText, ComplianceStatus, PaymentStatus, PayoutMethodType } from "@trolley/common-frontend";
import { CurrencyDisplay, DateDisplay, Icon, Text, Timeline } from "components";
import { UserAccess } from "features/user";
import dayjs from "dayjs";
import React from "react";
import { Payment } from "store/actions/payments";
import { useReturnPayment } from "store/hooks/payments";
import PaymentUpdateReturned from "./PaymentUpdateReturned";

export enum TimelineStep {
  "PAYOUT",
  "INITIATED",
  "COMPLIANCE",
  "SUBMITTED",
  "PROCESSED",
  "ESTIMATED",
  "RETURNED",
}

export function getPendingStep(payment: Payment): TimelineStep {
  if (!payment.account) {
    return TimelineStep.PAYOUT;
  }
  if (!payment.initiatedAt) {
    return TimelineStep.INITIATED;
  }
  if (payment.compliance.status !== "verified") {
    return TimelineStep.COMPLIANCE;
  }

  if (!payment.processedAt) {
    return TimelineStep.SUBMITTED;
  }

  if (payment.status === PaymentStatus.FAILED) {
    return TimelineStep.PROCESSED;
  }

  if (payment.status === PaymentStatus.RETURNED) {
    return TimelineStep.RETURNED;
  }

  if (!payment.settledAt) {
    return TimelineStep.PROCESSED;
  }

  // processed
  return TimelineStep.ESTIMATED;
}

function getCurrentIcon(pendingStep: TimelineStep, timelineStep: TimelineStep, errorCondition?: boolean) {
  if (pendingStep < timelineStep) {
    return <Icon type="circle" color="grey" />;
  }
  if (pendingStep > timelineStep) {
    return <Icon.Status type="success" />;
  }
  if (errorCondition) {
    return <Icon.Status type="error" />;
  }

  return <Icon type="clock" color="yellow" />;
}

type Props = {
  payment: Payment | undefined;
};

export default function PaymentTimeline(props: Props) {
  const { payment } = props;
  const canReturn = useReturnPayment(payment);

  if (!payment) {
    return null;
  }

  const pendingStep = getPendingStep(payment);

  return (
    <Timeline>
      {/* PAYMENT CREATED */}
      <Timeline.Item dot={<Icon.Status type="success" />}>
        <Text weight="bold">Payment Created</Text>
        <DateDisplay icon={false} value={payment.createdAt} />
      </Timeline.Item>

      {/* PAYOUT METHOD */}
      <Timeline.Item dot={getCurrentIcon(pendingStep, TimelineStep.PAYOUT)}>
        <Text weight="bold" type={pendingStep < TimelineStep.PAYOUT ? "secondary" : undefined}>
          Payout Method Setup
        </Text>
        {payment.payoutMethod ? "Yes" : "No"}
      </Timeline.Item>

      {/* PAYMENT INITIATED */}
      <Timeline.Item dot={getCurrentIcon(pendingStep, TimelineStep.INITIATED, payment.status === PaymentStatus.FAILED)}>
        <Text weight="bold" type={pendingStep < TimelineStep.INITIATED ? "secondary" : undefined}>
          Payment Initiated
        </Text>
        {pendingStep >= TimelineStep.INITIATED && payment.initiatedAt && <DateDisplay icon={false} value={payment.initiatedAt} />}
      </Timeline.Item>

      {/* COMPLIANCE SCREENED */}
      <Timeline.Item dot={getCurrentIcon(pendingStep, TimelineStep.COMPLIANCE, payment.compliance.status === ComplianceStatus.BLOCKED)}>
        <Text weight="bold" type={pendingStep < TimelineStep.COMPLIANCE ? "secondary" : undefined}>
          Compliance Screened
        </Text>
        {pendingStep >= TimelineStep.COMPLIANCE && (
          <>
            {payment.compliance.status === ComplianceStatus.PENDING && "Unscreened"}
            {payment.compliance.status === ComplianceStatus.REVIEW && "In Review"}
            {payment.compliance.status === ComplianceStatus.VERIFIED && <DateDisplay icon={false} value={payment.compliance.checkedAt} />}
            {payment.compliance.status === ComplianceStatus.BLOCKED && "Blocked"}
          </>
        )}
      </Timeline.Item>

      {/* SUBMITTED TO BANK */}
      <Timeline.Item dot={getCurrentIcon(pendingStep, TimelineStep.SUBMITTED)}>
        <Text weight="bold" type={pendingStep < TimelineStep.SUBMITTED ? "secondary" : undefined}>
          Submitted{" "}
          {payment.payoutMethod === PayoutMethodType.CHECK ? "for Printing" : payment.payoutMethod === PayoutMethodType.PAYPAL ? "to PayPal" : "to Bank"}
        </Text>
        {pendingStep >= TimelineStep.SUBMITTED && <DateDisplay icon={false} value={payment.processedAt} emptyText="" />}
      </Timeline.Item>

      {/* PROCESSED PAYMENT */}
      <Timeline.Item dot={getCurrentIcon(pendingStep, TimelineStep.PROCESSED, payment.status === PaymentStatus.FAILED)}>
        <Text weight="bold" type={pendingStep < TimelineStep.PROCESSED ? "secondary" : undefined}>
          Payment Processed
          {canReturn && payment.payoutMethod === PayoutMethodType.CHECK && (
            <Icon
              theme="solid"
              type="circle-exclamation"
              color="grey"
              tooltip="Update the user that the status of returned checks can only be updated manually by merchant"
              tooltipProps={{ placement: "right" }}
            />
          )}
        </Text>
        {pendingStep !== TimelineStep.RETURNED && <DateDisplay icon={false} value={payment.settledAt} emptyText="" />}
        <UserAccess type={Access.PAYMENTS_WRITE}>
          <PaymentUpdateReturned payment={payment} />
        </UserAccess>
      </Timeline.Item>

      {/* ESTIMATED DELIVERY */}
      {pendingStep === TimelineStep.ESTIMATED && (
        <Timeline.Item
          dot={
            payment.estimatedDeliveryAt && dayjs().isAfter(dayjs(payment.estimatedDeliveryAt)) ? (
              <Icon.Status type="success" />
            ) : (
              <Icon type="clock" color="yellow" />
            )
          }
        >
          <Text weight="bold">Estimated Delivery</Text>
          <DateDisplay icon={false} value={payment.estimatedDeliveryAt} time={false} />
        </Timeline.Item>
      )}

      {/* RETURNED PAYMENT */}
      {pendingStep === TimelineStep.RETURNED && (
        <Timeline.Item dot={<Icon.Status type="error" />}>
          <Text weight="bold">Payment Returned</Text>
          <DateDisplay icon={false} value={payment.returnedAt} />
          <Text>
            <CurrencyDisplay value={payment.returnedAmount} currency={payment.sourceCurrency} />
            <Icon.Hint
              tooltip="Shown here is the amount of the payment that was returned back to us. Sometimes the amount returned is less than the amount sent. This may be due to the sending fee being deducted when we sent the payment (as we still incur the same costs to send the payment even if it was returned); and/or maybe due to a currency conversion taking place when sending the payment - and then a further currency conversion taking place by the bank when they return the payment back to the sending currency. As FX rates are constantly fluctuating this can impact how much is returned back to you."
              right
            />
          </Text>
          {payment.returnedReason && payment.returnedReason.length > 0 && (
            <Text size="small" type="secondary">
              {payment.returnedReason.length > 1 ? (
                <ul>
                  {payment.returnedReason.map((reason) => (
                    <li key={reason}>{getReturnReasonText(reason)}</li>
                  ))}
                </ul>
              ) : (
                getReturnReasonText(payment.returnedReason[0])
              )}
            </Text>
          )}
        </Timeline.Item>
      )}
    </Timeline>
  );
}
