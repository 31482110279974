import { TaxFormVoidReason, TaxStatus } from "@trolley/common-frontend";
import { Status, Text } from "components";
import { StatusProps } from "components/Status";
import React from "react";
import { TaxProfile } from "store/actions/taxProfiles";

type Props = Omit<StatusProps, "type" | "tooltip"> & {
  taxProfile: TaxProfile | undefined;
  showTooltipWarnings?: boolean;
};

export default function StatusTaxProfile(props: Props) {
  const { taxProfile, showTooltipWarnings, ...rest } = props;

  if (!taxProfile) {
    return null;
  }

  const voidReason = taxProfile.status === TaxStatus.VOIDED && taxProfile.voidReason;

  let tooltip;

  if (showTooltipWarnings) {
    if (voidReason) {
      tooltip = (
        <>
          <Text type="label">Tax Profile Voided Reason:</Text>
          {voidReasonText[voidReason] || voidReason}
        </>
      );
    }
  }

  return <Status {...rest} tooltipProps={tooltip ? { title: tooltip } : undefined} type={taxProfile.status} />;
}

export const voidReasonText: Record<TaxFormVoidReason, string> = {
  [TaxFormVoidReason.NEW_SUBMISSION]: "A new tax profile was submitted",
  [TaxFormVoidReason.INVALID_DATA]: "Invalid Information",
  [TaxFormVoidReason.INVALID_SIGNATURE]: "Invalid Signature",
  [TaxFormVoidReason.INVALID_TIN]: "Invalid Tax Identification Number",
};
