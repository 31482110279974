import { Affix } from "antd";
import { Box, Grid, Icon } from "components";
import { getDirectorLabel } from "pages/BusinessProfile/SigningOfficer";
import React from "react";
import { NavLink } from "react-router-dom";
import { useOnboarding } from "store/hooks/onboarding";
import { createUseStyle, css } from "style/classname";
import { useWindowSize } from "utils/hooks";
import { BANK_ONBOARDING_PATH, BankOnboardingPages } from ".";
import { getShareRegisters } from "./ShareRegister";
import { isPersonComplete } from "./variables";

type LinkProps = {
  label: string;
  url: string;
  exact?: boolean;
  status: null | "completed";
  children?: {
    label: string;
    url: string;
    status: null | "completed";
  }[];
};
export default function BankOnboardingSteps() {
  const { data: onboarding } = useOnboarding();
  const { isMobile } = useWindowSize();
  const styledAnchor = useStyledAnchor();
  const styledMobileAnchor = useStyledMobileAnchor();
  const directors = onboarding?.persons.filter((p) => p.isDirector) || [];
  const owners = onboarding?.persons.filter((p) => p.isOwner) || [];
  const isSigningOfficersComplete = directors.length > 0 && directors.every((p) => isPersonComplete(p, "directors"));
  const isOwnersComplete = !!onboarding?.noOwnership || (owners.length > 0 && owners.every((p) => isPersonComplete(p, "owners")));

  const links: LinkProps[] = onboarding
    ? [
        {
          label: "Business Structure",
          url: BANK_ONBOARDING_PATH,
          exact: true,
          status: isSigningOfficersComplete && isOwnersComplete ? "completed" : null,
          children: [
            {
              label: `${getDirectorLabel(onboarding)}s`,
              url: [BANK_ONBOARDING_PATH, BankOnboardingPages.SIGNING_OFFICERS].join("/"),
              status: isSigningOfficersComplete ? "completed" : null,
            },
            ...(onboarding.noOwnership
              ? []
              : ([
                  {
                    url: [BANK_ONBOARDING_PATH, BankOnboardingPages.BENEFICIAL_OWNERS].join("/"),
                    label: "Beneficial Owners",
                    status: isOwnersComplete ? "completed" : null,
                  },
                ] as LinkProps[])),
          ],
        },
        {
          label: "Shareholder Register",
          url: [BANK_ONBOARDING_PATH, BankOnboardingPages.SHARE_REGISTER].join("/"),
          status: getShareRegisters(onboarding).length > 0 ? "completed" : null,
        },
        {
          url: [BANK_ONBOARDING_PATH, BankOnboardingPages.REVIEW].join("/"),
          label: !!onboarding.bankOnboardingCompletedAt ? "Submitted Summary" : "Review Submission",
          status: !!onboarding.bankOnboardingCompletedAt ? "completed" : null,
        },
      ]
    : [];

  if (isMobile) {
    return (
      <Affix className={styledMobileAnchor}>
        <Grid padding="small">
          {links.map((link) => (
            <Grid.Item key={link.url} span={12}>
              <NavLink exact to={link.url}>
                <Box padding="xsmall" color="grey">
                  {link.label}
                  {link.status && <Icon.Status type={link.status === "completed" ? "success" : "warning"} right />}
                </Box>
              </NavLink>
            </Grid.Item>
          ))}
        </Grid>
      </Affix>
    );
  }

  return (
    <Affix offsetTop={24} className={styledAnchor}>
      <div className="affix">
        {links.map((link, index) => (
          <StepLink link={link} index={index} />
        ))}
      </div>
    </Affix>
  );
}

function StepLink({ link, index }: { link: LinkProps; index: number }) {
  const styledStepperIcon = useStyledStepperIcon({ status: link.status });

  return (
    <div key={link.url} className={styledStepperIcon}>
      <NavLink exact={link.exact} to={link.url}>
        <div className="bullet">
          {link.status ? <Icon type={link.status === "completed" ? "check" : "exclamation"} theme="solid" fixedWidth={false} /> : String(index + 1)}
        </div>
        {link.label}
      </NavLink>
      {link.children?.map((sublink) => (
        <div key={sublink.url} className="sublink">
          <NavLink to={sublink.url}>
            <Icon type={sublink.status ? "circle-check" : "circle"} theme="solid" fixedWidth={false} color={sublink.status ? "green" : "grey"} />
            {sublink.label}
          </NavLink>
        </div>
      ))}
    </div>
  );
}

const useStyledAnchor = createUseStyle(({ theme }) =>
  css`
    margin-left: 48px;
    .affix {
      position: relative;
      &::before {
        content: " ";
        position: absolute;
        display: block;
        width: 36px;
        height: 100%;
        margin: 0 auto;
        background-color: ${theme.grey2};
        left: -44px;
        top: 0;
        border-radius: 24px;
      }
    }
  `(),
);

const useStyledMobileAnchor = createUseStyle(({ theme }) =>
  css`
    .${theme.prefixCls}-affix {
      background-color: ${theme.colorWhite};
      border-bottom: 1px solid ${theme.colorBorder};
    }
    .grid {
      padding: 16px 0px;
      overflow: "visible";
    }
    a {
      &:hover {
        color: ${theme.colorLink};
      }
      &:active {
        color: ${theme.colorLinkActive};
      }
      &:not(.active) {
        color: ${theme.colorText};
      }
    }
  `(),
);

const useStyledStepperIcon = createUseStyle<{ status: null | "completed" | "partial" }>(({ theme, status }) =>
  css`
    a {
      border-radius: ${theme.borderRadius}px;
      position: relative;
      display: inline-block;
      font-weight: bold;
      overflow: visible;
      padding: 8px 16px;
      transition: all 0.3s ease;
      color: ${theme.colorText};
      &:hover {
        color: ${theme.colorLinkHover};
      }
      &:active {
        color: ${theme.colorLinkActive};
      }
      &.active {
        background-color: ${theme.controlItemBgActive};
        .bullet {
          background-color: ${theme.purple7};
          color: ${theme.colorWhite};
        }
      }
    }
    .bullet {
      width: 28px;
      height: 28px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      left: -40px;
      top: 5px;
      border-radius: 50%;
      transition: all 0.3s ease;
      background-color: ${() => {
        switch (status) {
          case "completed":
            return theme.colorSuccess;
          case "partial":
            return theme.colorWarning;
          default:
            return theme.colorBgContainer;
        }
      }};
      .icon {
        color: ${theme.colorWhite};
      }
    }
    .sublink {
      .icon {
        transition: all 0.3s ease;
        position: absolute;
        left: -41px;
        top: 12px;
      }
      &:hover {
        .icon {
          color: ${theme.colorLinkHover};
        }
      }
      a {
        margin-left: 8px;
        font-weight: normal;
        &.active {
          .icon {
            color: ${theme.purple7};
          }
        }
      }
    }
  `(),
);
