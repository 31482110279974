import Icon, { IconName, IconProps } from "components/Icon";
import React from "react";
import colors from "style/colors";

export interface IconStatusProps extends Omit<IconProps, "type" | "theme" | "color" | "backgroundColor"> {
  type: "success" | "warning" | "error" | "info";
}

const ICON_MAP: Record<IconStatusProps["type"], IconName> = {
  success: "circle-check",
  info: "circle-info",
  warning: "triangle-exclamation",
  error: "circle-exclamation",
};

const COLOR_MAP: Record<IconStatusProps["type"], keyof typeof colors> = {
  success: "green",
  info: "blue",
  warning: "yellow",
  error: "red",
};

export default function IconStatus(props: IconStatusProps) {
  const { type, ...rest } = props;

  return <Icon theme="solid" type={ICON_MAP[type]} color={COLOR_MAP[type]} {...rest} />;
}
