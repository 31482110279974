import { Access, CountryCode, CurrencyCode, formatCountry, PayoutMethodType } from "@trolley/common-frontend";
import { Alert, Button, Checkbox, Divider, Form, FormInstance, Link } from "components";
import InputPhone, { parsePhoneNumber, phoneNumberValidator } from "components/Form/InputPhone";
import { UserAccess } from "features/user";
import { CountryCode as PhoneCountryCode } from "libphonenumber-js";
import React, { forwardRef, useEffect } from "react";
import { isVenmoSettings } from "store/actions/payoutMethods";
import { RecipientAccount, VenmoAccountUpdate } from "store/actions/recipientAccount";
import { StoreRecipient } from "store/actions/recipients";
import { usePayoutMethods } from "store/hooks/payoutMethods";
import { handleFormErrors } from "utils/helpers";

type Props = {
  recipientCountry: StoreRecipient["address"]["country"];
  recipientAccount: RecipientAccount | undefined;
  onSubmit(changes?: VenmoAccountUpdate): Promise<void>;
};

interface FormFields {
  currency: CurrencyCode.USD;
  phoneNumber: string;
  phoneNumberConfirmation: string;
  primary?: boolean;
}

const SUPPORTED_COUNTRY_FOR_VENMO = CountryCode.US;

export default forwardRef<FormInstance<FormFields>, Props>((props, ref) => {
  const { recipientCountry, recipientAccount, onSubmit } = props;
  const [form] = Form.useForm<FormFields>();
  const { data: payoutMethods } = usePayoutMethods();
  const venmoPayout = payoutMethods.find((pm) => pm.integration === PayoutMethodType.VENMO);
  const venmoAllowedCountries =
    venmoPayout?.settings && isVenmoSettings(venmoPayout.settings) ? venmoPayout.settings.allowedCountries : [SUPPORTED_COUNTRY_FOR_VENMO];
  const selectedCountry = recipientAccount?.country || recipientCountry || SUPPORTED_COUNTRY_FOR_VENMO;

  useEffect(() => {
    if (typeof ref === "function") {
      ref(form);
    }
  }, [form]);

  async function onFinish({ phoneNumberConfirmation, ...changes }: FormFields) {
    try {
      await onSubmit({
        ...changes,
        phoneNumber: parsePhoneNumber(changes.phoneNumber, selectedCountry as PhoneCountryCode).internationalFormat,
        type: PayoutMethodType.VENMO,
      });
    } catch (errors) {
      handleFormErrors(errors, form);
    }
  }

  const isNotAllowedPayoutMethod = () => {
    return (
      recipientCountry && venmoPayout?.enabled && isVenmoSettings(venmoPayout.settings) && !venmoPayout.settings.allowedCountries.includes(recipientCountry)
    );
  };

  return (
    <Form layout="vertical" compact form={form} onFinish={onFinish}>
      <Form.Item noStyle name="currency" initialValue={CurrencyCode.USD} />
      {!venmoPayout?.enabled && (
        <Alert type="warning">
          Venmo is disabled.{" "}
          <UserAccess type={Access.SETTINGS_WRITE}>
            Go to <Link to="/settings/payout-methods/venmo">Venmo Settings</Link> to enable it.
          </UserAccess>
        </Alert>
      )}

      {isNotAllowedPayoutMethod() && (
        <Alert type="warning">
          <strong>{formatCountry(recipientCountry)}</strong> is an unsupported country by Venmo. Venmo supports recipients in US, Puerto Rico, Virgin Islands
          (US) and Guam only.
        </Alert>
      )}

      <Form.Item
        initialValue={recipientAccount?.phoneNumber}
        style={{ width: "320px" }}
        label="Phone Number Associated with Venmo"
        name="phoneNumber"
        rules={[{ required: true, message: "Phone Number is required" }, phoneNumberValidator(venmoAllowedCountries)]}
      >
        <InputPhone defaultCountry={selectedCountry} allowedCountries={venmoAllowedCountries} name="phoneNumber" />
      </Form.Item>

      <Form.Item
        style={{ width: "320px" }}
        label="Confirm Phone Number Associated with Venmo"
        name="phoneNumberConfirmation"
        rules={[
          { required: true, message: "Confirm the phone number" },
          (form) => ({
            async validator(rule, value) {
              if (value && value !== form.getFieldValue("phoneNumber")) {
                throw "Phone numbers must be the same";
              }
            },
          }),
        ]}
      >
        <InputPhone defaultCountry={selectedCountry} allowedCountries={venmoAllowedCountries} name="phoneNumberConfirmation" />
      </Form.Item>

      {!recipientAccount && (
        <>
          <Divider margin="small" transparent />
          <Form.Item name="primary" valuePropName="checked" noStyle>
            <Checkbox>Set Account as Active</Checkbox>
          </Form.Item>
        </>
      )}
      <Button hidden htmlType="submit" />
    </Form>
  );
});
