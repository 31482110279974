import { BaseError, TFAType, TransactionInfo } from "@trolley/common-frontend";
import React, { useState } from "react";

import { Button, Container, CurrencyDisplay, Form, InputNumber, Paragraph, RecordCount, Text } from "components";
import TfaSmsTimer from "./TFASmsTimer";

interface Props {
  tfaParams: {
    tfaType: string;
    challenge?: any;
    transactionInfo?: TransactionInfo;
  };
  submitText?: string;
  onValidate: (code: string) => Promise<any>;
  onTimeout: () => void;
}

export default function TFAForm(props: Props) {
  const { tfaParams, submitText, onValidate, onTimeout } = props;
  const [form] = Form.useForm();
  const [busy, setBusy] = useState(false);
  const [previousCode, setPreviousCode] = useState("");

  return (
    <Form
      form={form}
      validateTrigger="onSubmit"
      onFinish={async ({ code }) => {
        try {
          setBusy(true);
          setPreviousCode(code);
          await onValidate(code);
          setBusy(false);
        } catch (errors) {
          setBusy(false);
          form.setFields([{ name: "code", errors: [errors?.find?.((e: BaseError) => e.message)?.message ?? "Invalid Code"] }]);
        }
      }}
    >
      <Container align="left" padding="none">
        {tfaParams?.transactionInfo?.batchGuid && (
          <Paragraph>
            You are authorizing to process batch ID
            <br />
            {tfaParams.transactionInfo.batchGuid}
            {typeof tfaParams.transactionInfo.totalPayments === "number" && (
              <RecordCount value={tfaParams.transactionInfo.totalPayments} one=" with 1 payment" other=" with # payments" />
            )}
            .
            {tfaParams.transactionInfo.amount && (
              <Text>
                Total Payout: <CurrencyDisplay value={tfaParams.transactionInfo.amount.value} currency={tfaParams.transactionInfo.amount.currency} />
              </Text>
            )}
          </Paragraph>
        )}
        <Form.Item
          name="code"
          label={tfaParams.tfaType === TFAType.SMS ? "Enter the 6-digit code sent to your device" : "Enter the 6-digit code"}
          validateFirst
          rules={[
            { required: true, pattern: /^[0-9]{6}$/, message: "Please enter 6 digits" },
            {
              async validator(rule, value) {
                if (previousCode && value === previousCode) {
                  throw "Enter a different code";
                }
              },
            },
          ]}
        >
          <InputNumber
            placeholder="000000"
            size="large"
            maxLength={6}
            autoFocus
            autoComplete="one-time-code"
            name="authCode"
            style={{ letterSpacing: "2px", height: "40px", lineHeight: "40px", fontSize: "28px", textAlign: "left", fontFamily: "monospace" }}
          />
        </Form.Item>

        {tfaParams.tfaType === TFAType.SMS && <TfaSmsTimer loading={busy} onTimeout={onTimeout} />}

        <Button htmlType="submit" block type="primary" loading={busy} size="large">
          {submitText || "Verify Code"}
        </Button>
      </Container>
    </Form>
  );
}
