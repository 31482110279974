import { useState } from "react";
import { Dac7EoyTaxExportsQuery, loadDac7EoyTaxExports } from "store/actions/dac7EoyTaxExports";
import { emptyList } from "store/reducers/standardReducer";
import { useShallowSelector } from ".";
import { useDeepCompareEffect } from "utils/hooks";

export function useDac7EoyTaxExports(query: Dac7EoyTaxExportsQuery) {
  const [fetchId, setFetchId] = useState("");

  useDeepCompareEffect(() => {
    if (query.taxYear) {
      setFetchId(loadDac7EoyTaxExports(query));
    } else {
      setFetchId("");
    }
  }, [query]);

  return useShallowSelector((state) => {
    if (fetchId && state.dac7EoyTaxExports.fetchStatus[fetchId] === undefined) {
      loadDac7EoyTaxExports(query);
    }

    return {
      data: state.dac7EoyTaxExports.entities[fetchId] || emptyList,
      status: state.dac7EoyTaxExports.fetchStatus[fetchId],
      error: state.dac7EoyTaxExports.errors[fetchId],
    };
  });
}
