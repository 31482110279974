import { LOCALE_LABELS, SupportedLocales } from "@trolley/common-frontend";
import { Form, Select } from "components";
import React from "react";
import { changeLocale } from "store/actions/auth";
import { useLocale } from "store/hooks/locale";
import { classnames, createUseStyle, css } from "style/classname";

const SUPPORTED_LOCALE_OPTIONS = Object.entries(LOCALE_LABELS)
  .filter(([value]: [SupportedLocales, string]) => value === SupportedLocales.EN || value === SupportedLocales.FR)
  .map(([value, label]: [SupportedLocales, string]) => ({
    label,
    value,
  }));

export default function LanguageSelector(props: { className?: string }) {
  const { className } = props;
  const locale = useLocale();
  async function handleSelect(value: string) {
    if (value) {
      changeLocale(value as SupportedLocales);
    }
  }

  const styledSelector = useStyledPortalLanguageButton();

  return (
    <Form.Item>
      <Select
        bordered={false}
        size="small"
        showSearch
        className={classnames(styledSelector, className)}
        value={locale}
        options={SUPPORTED_LOCALE_OPTIONS}
        showArrow={false}
        placeholder="Language"
        onChange={handleSelect}
        popupMatchSelectWidth={false}
        placement="topRight"
      />
    </Form.Item>
  );
}

const useStyledPortalLanguageButton = createUseStyle(({ theme }) =>
  css`
    .${theme.prefixCls}-select-selector {
      align-items: center;
      &::before {
        font-family: "Font Awesome 6 Pro";
        content: "\\f1ab";
        margin-right: 6px;
      }
      .${theme.prefixCls}-select-selection-search {
        margin-left: 24px;
      }
      .${theme.prefixCls}-select-selection-item {
        padding-inline: 0 !important;
      }
    }
  `(),
);
