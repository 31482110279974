import { groupPayments } from "store/actions/actionUtils";
import { getQuote, updateBatch } from "store/actions/batches";
import { OpCode, standardDispatch } from "store/dispatcher";
import { Query } from "utils/hooks";
import * as request from "services/request";
import * as types from "@trolley/common-frontend";
import { Payment } from "./payments";

export interface BatchPaymentsQuery extends Query {}

export interface BatchPaymentCreate {
  recipient: {
    id: string;
  };
  amount?: string;
  currency?: string;
  coverFees?: boolean;
  taxReportable?: boolean;
  category?: types.PaymentCategory;
  memo?: string;
}

export interface BatchPaymentUpdate {
  // if new keys are added in the future, DO NOT FORGET to requote if it's related to payments
  amount?: string;
  currency?: string; // must be submitted with amount
  coverFees?: boolean;
  category?: types.PaymentCategory;
  taxReportable?: boolean;
  forceUsTaxActivity?: boolean;
  memo?: string;
  tags?: string[];
  externalId?: string;
}

export async function deleteBatchPayments(batchId: string, paymentIds: string[]) {
  const ids = types.uniqueList(paymentIds);
  if (ids.length > 0) {
    try {
      standardDispatch(OpCode.LOADING, "batch", { id: batchId });

      await request.POST("/v1/payments/delete", {
        query: {
          id: ids,
        },
      });

      standardDispatch(OpCode.LOADING, "batch", { id: batchId, loading: false });
    } catch (errors) {
      standardDispatch(OpCode.ERROR, "batch", {
        errors,
        id: batchId,
      });
      throw errors;
    }
  }
}

export async function addBatchPayment(batchId: string, newPayment: BatchPaymentCreate[]) {
  if (newPayment) {
    try {
      standardDispatch(OpCode.LOADING, "batch", { id: batchId });

      await updateBatch(batchId, {
        payments: newPayment,
      });

      standardDispatch(OpCode.LOADING, "batch", { id: batchId, loading: false });
    } catch (errors) {
      standardDispatch(OpCode.ERROR, "batch", {
        errors,
        id: batchId,
      });
      throw errors;
    }
  }
}

const REFRESHABLE_KEYS: (keyof Payment)[] = ["currency", "amount", "coverFees", "taxReportable", "forceUsTaxActivity", "category"];

export async function editBatchPaymentAndRequote(batchId: string, paymentId: string, editedPayment: BatchPaymentUpdate) {
  try {
    standardDispatch(OpCode.LOADING, "payment", { id: paymentId });
    standardDispatch(OpCode.LOADING, "batch", { id: batchId });

    const requiresQuoteRefresh = REFRESHABLE_KEYS.some((k) => k in editedPayment);
    const { body } = await request.PATCH<types.PaymentResult>(`/v1/batches/${batchId}/payments/${paymentId}`, { query: editedPayment });
    const { payments } = groupPayments([body.payment], batchId);
    standardDispatch(OpCode.DATA, "payment", { bulk: payments });

    if (requiresQuoteRefresh) {
      getQuote(batchId);
    } else {
      standardDispatch(OpCode.LOADING, "batch", { id: batchId, loading: false });
    }
  } catch (errors) {
    standardDispatch(OpCode.LOADING, "batch", { id: batchId, loading: false });
    standardDispatch(OpCode.ERROR, "payment", {
      errors,
      id: paymentId,
    });
    throw errors;
  }
}
