import { Drawer, DrawerProps } from "antd";
import { Button, Grid, Icon, Space, Stop } from "components";
import React, { ReactNode } from "react";
import styled, { classnames } from "style/classname";
import colors from "style/colors";
import { MEDIA_BREAKPOINTS } from "style/variables";

export const styledFlyout = styled`
[class*="-drawer-header"] {
  background-color: ${colors.white};
  [class*="-drawer-title"] {
    max-width: 100%;
  }
}

[class*="-drawer-content-wrapper"] {
  max-width: 95%;
  ${MEDIA_BREAKPOINTS.mdUp} {
    max-width: initial;
  }
}
`();

function getWidth(width: Props["width"]) {
  switch (width) {
    case "large":
      return 780;
    case "small":
      return 420;
    default:
      return 520;
  }
}

type Props = Omit<DrawerProps, "width" | "destroyOnClose" | "closeIcon" | "maskClosable" | "onClose"> & {
  width?: "large" | "small";
  subtitle?: ReactNode;
  onClose?(): void;
  children?: ReactNode;
};

export default function Flyout(props: Props) {
  const { title, onClose, closable = true, className, width: widthStr, subtitle, extra, ...rest } = props;
  const width = getWidth(widthStr);

  return (
    <Stop>
      <Drawer
        {...rest}
        maskClosable
        destroyOnClose
        closable={false}
        width={width}
        rootClassName={classnames(styledFlyout, className)}
        onClose={() => {
          onClose?.();
        }}
        title={
          <>
            <Grid padding="small" wrap={false} style={{ marginBottom: "4px" }}>
              <Grid.Item flex={1} style={{ fontSize: "20px" }} alignSelf="center">
                {title}
              </Grid.Item>
              <Grid.Item>
                <Space wrap={false}>
                  {extra}
                  {closable && (
                    <Button
                      icon={<Icon type="xmark" theme="regular" />}
                      onClick={(e) => {
                        e?.stopPropagation?.();
                        onClose?.();
                      }}
                    />
                  )}
                </Space>
              </Grid.Item>
            </Grid>
            {subtitle}
          </>
        }
      />
    </Stop>
  );
}
