import { useOnValueChange } from "@shopify/react-hooks";
import { Input, Popover } from "antd";
import { TextAreaProps } from "antd/lib/input";
import { Button, Form, ItemProps, Space, TooltipProps } from "components";
import debounce from "lodash.debounce";
import React, { MouseEvent, useCallback, useState } from "react";

const DEFAULT_NAME = "data";

interface Props extends Omit<TextAreaProps, "onKeyDown" | "onBlur" | "onFocus" | "onPressEnter" | "type" | "value"> {
  formOptions?: Omit<ItemProps, "children" | "label" | "tooltip">;
  value: string;
  okText?: string;
  enablePopup?: boolean;
  placement?: TooltipProps["placement"];
  onSave(value: string): Promise<void>;
}

export default function TextareaLive(props: Props) {
  const {
    formOptions,
    name = DEFAULT_NAME,
    readOnly,
    value,
    okText = "Save",
    enablePopup = true,
    placement = "bottomRight",
    onSave,
    ...restTextAreaProps
  } = props;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const onSaveDebounce = useCallback(
    debounce(async (values: any) => {
      if (name in values) {
        setLoading(true);
        try {
          await onSave(values[name]);
          onDone();
        } catch (errors) {
          const errMessages = [];
          if (typeof errors === "string") {
            errMessages.push(errors);
          } else if (errors && errors.message) {
            errMessages.push(errors.message);
          } else if (Array.isArray(errors)) {
            const err = errors.find((e) => e.message);
            if (err) {
              errMessages.push(err.message);
            }
          }
          form.setFields([{ name, value: values[name], errors: errMessages }]);
        }
        setLoading(false);
      } else {
        onDone();
      }
    }, 200),
    [form],
  );

  useOnValueChange(value, () => {
    if (!form.isFieldsTouched()) {
      // don't update if user is already updating
      form.resetFields();
    }
  });

  function onDone(e?: MouseEvent<HTMLElement>) {
    onSaveDebounce.cancel(); // Canceling the delayed saving because clicking "cancel" button triggers 'onSave' due to 'onBlur' event.
    e?.stopPropagation?.();
    form.resetFields();
    setLoading(false);
  }

  return (
    <Form form={form} initialValues={{ [name]: value }} validateTrigger="onSubmit" onFinish={onSaveDebounce}>
      <Form.Control shouldUpdate>
        {/* ISSUE TO FIX: https://github.com/ant-design/ant-design/issues/26888 */}
        {({ getFieldError, isFieldTouched }) => {
          const error = getFieldError(name).join(", ");
          const isTouched = isFieldTouched(name);
          const textAreaProps: TextAreaProps = {
            readOnly: loading || readOnly,
            onKeyDown: (e) => {
              if (e) {
                if (e.key === "Escape") {
                  onDone();
                }
              }
            },
            ...restTextAreaProps,
          };

          return (
            <Popover
              open={enablePopup ? isTouched : !!error}
              placement={placement}
              title={
                <Space direction={/right/gi.test(placement) ? "row-reverse" : "row"}>
                  <Button
                    onClick={(e) => {
                      e?.stopPropagation?.();
                      form.submit();
                    }}
                    type="primary"
                    loading={loading}
                    style={{ minWidth: "90px" }}
                  >
                    {okText}
                  </Button>
                  <Button onClick={onDone}>Cancel</Button>
                </Space>
              }
            >
              <Form.Item noStyle name={name} normalize={(val) => String(val ?? "").trim()} {...formOptions}>
                <Input.TextArea {...textAreaProps} />
              </Form.Item>
            </Popover>
          );
        }}
      </Form.Control>
    </Form>
  );
}
