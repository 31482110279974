import { useEffect } from "react";
import { loadInvoicePayments } from "store/actions/invoicePayments";
import { useShallowSelector } from "store/hooks";

export function useInvoicePayments(rawId: string | undefined) {
  const id = rawId && /^(I|P)-\w+/.test(rawId) ? rawId : "";

  useEffect(() => {
    if (id) {
      loadInvoicePayments(id);
    }
  }, [id]);

  return useShallowSelector((state) => {
    if (id && state.invoicePayments.fetchStatus[id] === undefined) {
      loadInvoicePayments(id);
    }

    return {
      data: state.invoicePayments.entities[id] ?? [],
      status: state.invoicePayments.fetchStatus[id],
      error: state.invoicePayments.errors[id],
    };
  });
}
