import { Badge, Button, Checkbox, Divider, Form, Heading, Icon, Text } from "components";
import React, { useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { authAcceptInvite, authResetPassword } from "store/actions/auth";
import { useShallowSelector } from "store/hooks";
import { BaseError, BaseStatus } from "store/reducers/standardReducer";
import { handleFormErrors } from "utils/helpers";
import FullscreenAuth from "./FullscreenAuth";
import PasswordField from "./PasswordField";
import { translateErrorMessages } from "./LoginPage";
import { useIntl } from "utils/context";
import { useLocale } from "store/hooks/locale";
import { getPublicPath, PublicModulesEnum, TermsOfServiceLink } from "pages/LoginNotRequired";

type FormFields = {
  password: string;
  terms: boolean;
};

type Params = {
  userId: string;
  code: string;
};

export default function ResetPassword() {
  const locale = useLocale();
  const [form] = Form.useForm<FormFields>();
  const { userId, code } = useParams<Params>();
  const { formatMessage } = useIntl();
  const location = useLocation();
  const userStatus = useShallowSelector((state) => state.user.fetchStatus.data);
  const [status, setStatus] = useState<"complete" | "invalidCode" | undefined>();
  const isInvite = /\/invite\//g.test(location.pathname);

  async function onSubmit(values: FormFields) {
    try {
      if (isInvite) {
        await authAcceptInvite(userId, code, values.password, values.terms);
      } else {
        await authResetPassword(userId, code, values.password);
      }

      setStatus("complete");
    } catch (errors) {
      if (errors && Array.isArray(errors) && errors.find((error: BaseError) => error.code === "invalid_field" && error.field === "code")) {
        setStatus("invalidCode");
      }
      handleFormErrors(translateErrorMessages(errors, formatMessage), form);
    }
  }

  return (
    <FullscreenAuth>
      {status ? (
        <Text align="center" size="large">
          {status === "complete" && (
            <>
              <Text align="center" style={{ margin: "24px" }}>
                <Badge count={<Icon.Status type="success" size="large" />}>
                  <Icon type="user-lock" size="3x" color="grey" />
                </Badge>
              </Text>
              {formatMessage({ id: "auth.password.reset.passwordUpdated" })}
            </>
          )}
          {status === "invalidCode" && (
            <>
              <Text align="center" style={{ margin: "24px" }}>
                <Badge count={<Icon type="circle-xmark" color="red" size="large" />}>
                  <Icon type="lock" size="3x" color="red" />
                </Badge>
              </Text>
              {formatMessage({ id: "auth.password.reset.passwordAlreadyUpdated" })}
            </>
          )}
        </Text>
      ) : (
        <>
          <Heading align="center">
            {isInvite ? formatMessage({ id: "auth.password.reset.inviteAccept" }) : formatMessage({ id: "auth.password.reset.setPassword" })}
          </Heading>
          <Form onFinish={onSubmit} form={form}>
            <PasswordField label={formatMessage({ id: "auth.password.reset.setYourPassword" })} applyTranslations />
            {isInvite && (
              <Form.Item
                name="terms"
                valuePropName="checked"
                rules={[
                  {
                    async validator(rule, checked) {
                      if (!checked) {
                        throw formatMessage({ id: "auth.password.reset.acceptTerms" });
                      }
                    },
                  },
                ]}
              >
                <Checkbox name="terms">
                  <div style={{ display: "inline-block", maxWidth: "300px", verticalAlign: "top", lineHeight: "21px" }}>
                    {formatMessage(
                      {
                        id: "auth.password.reset.agreement.iAgree",
                      },
                      {
                        termsOfService: (
                          <TermsOfServiceLink locale={locale}> {formatMessage({ id: "auth.password.reset.agreement.termsOfService" })}</TermsOfServiceLink>
                        ),
                        privacyPolicy: (
                          <TermsOfServiceLink locale={locale}> {formatMessage({ id: "auth.password.reset.agreement.privacyPolicy" })}</TermsOfServiceLink>
                        ),
                      },
                    )}
                  </div>
                </Checkbox>
              </Form.Item>
            )}

            <Divider transparent margin="small" />

            <Button type="primary" block htmlType="submit" size="large" loading={userStatus === BaseStatus.LOADING}>
              {formatMessage({ id: "auth.password.reset.setNewPassword" })}
            </Button>
          </Form>
        </>
      )}
      <Divider transparent margin="small" />
      <Text align="center">
        <Link to={getPublicPath(PublicModulesEnum.Login, locale)}>
          {formatMessage({ id: "auth.password.reset.loginNow" })}
          <Icon type="angle-right" right />
        </Link>
      </Text>
    </FullscreenAuth>
  );
}
