import { Box, ButtonLink, Divider, ExternalLink, Icon, Radio, Text } from "components";
import config from "config";
import { useRecipientEditable } from "features/recipient";
import UserAccess from "features/user/UserAccess";
import React from "react";
import { notifyError, notifySuccess } from "store/actions/notifications";
import { StoreRecipient, updateRecipient } from "store/actions/recipients";
import { useIframeConfig } from "store/hooks/iframeConfig";
import { useWhiteLabel } from "store/hooks/whiteLabel";
import { Access } from "@trolley/common-frontend";

interface Props {
  recipient: StoreRecipient;
}

export default function RecipientWhiteLabelEmail(props: Props) {
  const { recipient } = props;
  const { data: whiteLabel } = useWhiteLabel();
  const { data: iframeConfig } = useIframeConfig();
  const profileEditable = useRecipientEditable();

  /// isPortalUser has no effect if enableEmailOverride is not enabled
  if (whiteLabel?.enableEmailOverride && iframeConfig?.portalDomain && !iframeConfig.portalEnabled) {
    return (
      <Box
        header="Recipient White Label Emails"
        extraActions={
          <UserAccess type={Access.SETTINGS_WRITE}>
            <ButtonLink
              path={{ pathname: "/settings/white-label/widget-portal", state: { highlightId: "enableEmailOverride" } }}
              tooltipProps={{ title: "Settings" }}
              icon={<Icon type="gear" />}
            />
          </UserAccess>
        }
      >
        Emails sent to {recipient.name} will open the:
        <br />
        <Radio.Group
          optionType="button"
          disabled={!profileEditable}
          onChange={async (e) => {
            if (recipient) {
              try {
                await updateRecipient(recipient.id, { isPortalUser: !!e.target.value });
                notifySuccess("Recipient updated");
              } catch (errors) {
                notifyError("Unable to set widget/portal status");
              }
            }
          }}
          value={!!recipient.isPortalUser}
          options={[
            {
              value: false,
              label: "Widget",
            },
            {
              value: true,
              label: "Portal",
            },
          ]}
        />
        <Divider transparent margin="small" />
        <Text padded>
          <strong>Widget: </strong>
          {whiteLabel.widgetUrl || "Emails will ask recipients to login to their account on your website or app."}
        </Text>
        <Text padded>
          <strong>Portal: </strong>
          <ExternalLink href={`https://${iframeConfig?.portalDomain}.portal.${config.DOMAIN}`}>
            {iframeConfig?.portalDomain}.portal.{config.DOMAIN}
            <Icon type="external-link-alt" right size="small" />
          </ExternalLink>
        </Text>
      </Box>
    );
  }

  return null;
}
