import { Button, Icon, Input, Modal, Text } from "components";
import React, { useState } from "react";
import { notifySuccess, notifyError } from "store/actions/notifications";
import { Payment, returnPayments } from "store/actions/payments";
import { useReturnPayment } from "store/hooks/payments";

type Props = {
  payment: Payment | undefined;
};

export default function PaymentUpdateReturned(props: Props) {
  const [openDialog, setOpenDialog] = useState(false);
  const [notes, setNotes] = useState("");
  const { payment } = props;
  const showButton = useReturnPayment(payment);

  const onConfirmReturn = async () => {
    try {
      if (payment) {
        await returnPayments(payment.id, notes);
        notifySuccess("Payment returned");
      }
    } catch (errors) {
      notifyError("Failed to return payment", { errors });
    } finally {
      setOpenDialog(false);
    }
  };
  const onReturnButtonClick = () => {
    Modal.confirm({
      title: "Are you sure?",
      type: "danger",
      okText: "Yes, update",
      cancelText: "No, cancel",
      onOk: onConfirmReturn,
      icon: (
        <Icon.Status
          type="error"
          style={{
            fontSize: "24px",
            position: "absolute",
            left: "24px",
          }}
        />
      ),
    });
  };

  return (
    <>
      {showButton && (
        <>
          <Button
            onClick={() => {
              setOpenDialog(true);
            }}
            type="link"
            style={{
              padding: 0,
            }}
          >
            Update as returned
          </Button>
          <Modal
            visible={openDialog}
            onCancel={() => {
              setNotes("");
              setOpenDialog(false);
            }}
            title={<Text weight="bold">Are you sure you want to update this payment as returned?</Text>}
            okText="Update payment as returned"
            okButtonProps={{
              disabled: !notes,
            }}
            onOk={onReturnButtonClick}
          >
            <Text style={{ whiteSpace: "pre-line" }}>
              {"This action is irreversible.\nWe recommend marking payments as returned when there is clear evidence for the returned payment."}
            </Text>
            <br />
            <Text style={{ opacity: 0.6, marginBottom: 5 }} weight="bold">
              Notes
            </Text>
            <Input.TextArea
              rows={3}
              style={{ resize: "none" }}
              onChange={(e) => {
                setNotes(e.target.value);
              }}
              maxLength={1024}
            />
          </Modal>
        </>
      )}
    </>
  );
}
