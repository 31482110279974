import { RiskScoreLabelType } from "@trolley/common-frontend";
import React from "react";

import { Divider, Icon, Text } from "components";
import Status, { StatusProps } from "components/Status";
import { useRecipient } from "store/hooks/recipients";
import colors from "style/colors";

const COLORMAP: Record<RiskScoreLabelType, keyof typeof colors> = {
  [RiskScoreLabelType.LOW]: "green",
  [RiskScoreLabelType.MEDIUM]: "yellow",
  [RiskScoreLabelType.HIGH]: "red",
};
type Props = Omit<StatusProps, "type"> & {
  recipientId: string | undefined;
};

export default function RecipientRiskScore(props: Props) {
  const { recipientId, style, ...restProps } = props;
  const { data: recipient } = useRecipient(recipientId);

  if (!recipient) {
    return null;
  }

  const scoreLabel = recipient?.riskScoreLabel;

  return (
    <Status
      colors={COLORMAP}
      type={scoreLabel || "-"}
      prefix={
        <Icon
          type={scoreLabel === RiskScoreLabelType.LOW ? "shield-check" : "shield"}
          theme="solid"
          color={(scoreLabel && COLORMAP[scoreLabel]) ?? "grey"}
          left
        />
      }
      tooltipProps={{
        title: (
          <>
            <Text type="label">Risk Score</Text>
            <Text capitalize>{scoreLabel ?? " - "}</Text>
            {typeof scoreLabel === "string" && (
              <>
                <Divider inverse margin="small" />
                <Text size="small">
                  <b>Risk Score</b> is the first step in our future Fraud Prevention product that will be built-in throughout the whole dashboard. It is loosely
                  based on recipient session login to widget or portal using info such as: IP Address, geolocation info, email address, device fingerprint,
                  known bad user lists, proxies, etc.
                  <br />
                  <br />
                  If you see{" "}
                  <Text inline type="error">
                    High Risk
                  </Text>
                  , maybe have a second look. It is not a reason on its own today to believe a recipient is sketchy, just an indicator that something might not
                  be right. If you see a higher score you may wish to take a closer look at the recipient details but it's just a warning at the moment but
                  currently it won't stop you from processing the payment.
                </Text>
              </>
            )}
          </>
        ),
      }}
      {...restProps}
    />
  );
}
