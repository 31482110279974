import { CurrencyCode } from "@trolley/common-frontend";
import BigNumber from "bignumber.js";
import React from "react";
import { FormatNumberOptions, useIntl } from "react-intl";
import css from "style/classname";
import colors from "style/colors";

type Props = React.HTMLAttributes<HTMLSpanElement> & {
  value: BigNumber | string | number | null | undefined;
  currency: CurrencyCode | string | null | undefined;
  showCurrency?: boolean | "symbol";
  showDecimal?: boolean;
  emptyText?: string;
  dataTestId?: string;
};

export default function CurrencyDisplay(props: Props) {
  const { value, currency, showCurrency = true, emptyText = " - ", showDecimal = true, style, dataTestId, ...rest } = props;
  const intl = useIntl();
  const number = BigNumber.isBigNumber(value) ? (value as BigNumber) : new BigNumber(String(value));

  if (number.isNaN()) {
    return <span>{emptyText}</span>;
  }

  const currencySuffix = currency && (
    <span className={styledCurrencySuffix} data-testid="targetCurrencySuffix">
      {" "}
      {String(currency)}
    </span>
  );

  try {
    const formatOptions: FormatNumberOptions = {
      style: "currency",
      currency: currency || CurrencyCode.USD,
    };

    if (!showDecimal) {
      formatOptions.maximumFractionDigits = 0;
    }

    return (
      <span style={{ whiteSpace: "nowrap", color: number.isNegative() ? colors.red : undefined, ...style }} data-testid={dataTestId} {...rest}>
        {showCurrency === "symbol" ? (
          intl.formatNumber(number.toNumber(), { ...formatOptions, currencyDisplay: "symbol" })
        ) : (
          <>
            <span data-testid="targetCurrencyAmount">
              {intl.formatNumber(number.toNumber(), { ...formatOptions, currencyDisplay: "code" }).replace(/[A-Z]{3}\s?/gi, "")}
            </span>
            {currencySuffix}
          </>
        )}
      </span>
    );
  } catch (err) {
    return (
      <span style={{ whiteSpace: "nowrap", ...style }} {...rest}>
        <span data-testid="targetCurrencyAmount">{value}</span>
        {currencySuffix}
      </span>
    );
  }
}

const styledCurrencySuffix = css`
  font-size: 0.857em;
  font-size: min(0.857em, 14px);
  opacity: 0.65;
`();
