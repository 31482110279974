import { isSupportedOnboardingCountry } from "@trolley/common-frontend";
import { RcFile } from "antd/lib/upload";
import { Form, UploadDragger } from "components";
import { downloadPOSTFile } from "components/FileDownload";
import React, { useEffect } from "react";
import { notifyError, notifySuccess } from "store/actions/notifications";
import { Onboarding, removeOnboardingFile, uploadOnboardingFileV2 } from "store/actions/onboarding";
import { useOnboarding } from "store/hooks/onboarding";
import { BaseStatus } from "store/reducers/standardReducer";
import { createFormData } from "utils/helpers";
import { FormNames } from ".";
import OnboardingSection from "./OnboardingSection";
import { mapToUploadFile } from "./variables";

export function isCompanyCertificateComplete(onboarding: Onboarding | undefined): null | "completed" {
  return Object.values(onboarding?.files || {}).some((f) => f.type === "crc") ? "completed" : null;
}

export default function CompanyRegistrationCertificate() {
  const { data: onboarding, status: onboardingStatus } = useOnboarding();
  const [form] = Form.useForm();

  useEffect(() => {
    if (onboarding) {
      form.resetFields(); // this is required after file has been uploaded or deleted;
    }
  }, [onboarding]);

  return onboarding?.businessCountry && onboarding.businessType && isSupportedOnboardingCountry(onboarding.businessCountry, "NA") ? (
    <OnboardingSection title="Business Certificate" id={FormNames.COMPANY_CERTIFICATE}>
      <Form form={form} name={FormNames.COMPANY_CERTIFICATE}>
        <Form.Item
          label="Please upload one or more copies of your business certificate."
          name="crc"
          initialValue={Object.values(onboarding?.files || {})
            .filter((file) => file.type === "crc")
            .map(mapToUploadFile)}
          valuePropName="fileList"
          getValueFromEvent={(e) => {
            if (Array.isArray(e)) {
              return e;
            }

            return e?.fileList;
          }}
          rules={[
            {
              required: true,
              message: "A business certificate is required",
            },
            {
              async validator(rule, value) {
                if (!value.length) {
                  throw "A business certificate is required";
                }
              },
            },
          ]}
        >
          <UploadDragger
            customRequest={async (options) => {
              const file = options.file as RcFile;
              try {
                await uploadOnboardingFileV2(createFormData([file], { type: "crc" }));
                notifySuccess("Document Received");
              } catch (err) {
                options.onError?.(err);
              }
            }}
            onRemove={async (file) => {
              const uploadId = file.url && file.uid;
              if (uploadId) {
                try {
                  await removeOnboardingFile(uploadId);
                  notifySuccess("Document removed");
                } catch (errors) {
                  notifyError("Uploaded Document failed");

                  return false;
                }
              }

              return true;
            }}
            onDownload={async (file) => {
              const uploadId = file.url && file.uid;
              if (uploadId) {
                try {
                  await downloadPOSTFile("/v1/onboarding/documents/download", { uploadId }, file.name);
                } catch {
                  notifyError("Download failed");
                }
              }
            }}
            loading={onboardingStatus === BaseStatus.LOADING}
          />
        </Form.Item>
      </Form>
    </OnboardingSection>
  ) : null;
}
