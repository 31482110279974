import { Dropdown } from "antd";
import { DropdownButtonProps } from "antd/lib/dropdown/dropdown-button";
import { Icon } from "components";
import React, { useState } from "react";
import css, { classnames } from "style/classname";

interface ButtonDropdownProps extends Omit<DropdownButtonProps, "icon" | "onOpenChange"> {}

export default function ButtonDropdown(props: ButtonDropdownProps) {
  const [visibleDropdown, setDropdownVisiblity] = useState(false);
  const { trigger = ["click"], className, ...rest } = props;

  return (
    <Dropdown.Button
      trigger={trigger}
      mouseLeaveDelay={0.5}
      icon={<Icon type="angle-down" style={{ transform: `scaleY(${visibleDropdown ? "-1" : "1"})`, transition: "transform 0.15s linear" }} />}
      onOpenChange={setDropdownVisiblity}
      className={classnames(className, styledDropdownButton)}
      {...rest}
    />
  );
}

ButtonDropdown.__ANT_BUTTON = true;

const styledDropdownButton = css`
  [class*="-btn-compact-first-item"] {
    flex: 1;
  }
`();
