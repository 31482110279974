import { useDebouncedValue } from "@shopify/react-hooks";
import { Button as AntButton, ButtonProps as AntButtonProps } from "antd";
import debounce from "lodash.debounce";
import React, { MouseEvent, useMemo, useState } from "react";

import { Tooltip, TooltipProps } from "components";
import css, { classnames, createUseStyle } from "style/classname";
import Delete from "./ButtonDelete";
import Dropdown from "./ButtonDropdown";

export type ButtonProps = AntButtonProps & {
  tooltipProps?: TooltipProps;
  debounceTimer?: number;
};

export default function Button(props: ButtonProps) {
  const { loading, className, onClick, debounceTimer = 400, children, tooltipProps, ...rest } = props;
  const [debouncing, setDebouncing] = useState(false);
  const delayedDebouncing = useDebouncedValue(debouncing, { timeoutMs: 1 }); // this is needed because button type "button" will not allow forms to be submited

  const styledButton = useStyledButton(props);

  const { onDebounceClick, setDebouncingFalse } = useMemo(
    () => ({
      onDebounceClick: debounce(
        (e: MouseEvent<HTMLElement>) => {
          onClick?.(e);
          setDebouncing(true);
        },
        debounceTimer,
        { leading: true, trailing: false },
      ),
      setDebouncingFalse: debounce(() => {
        setDebouncing(false);
      }, debounceTimer),
    }),
    [onClick, setDebouncing, debounceTimer],
  );

  const buttonProps: ButtonProps = {
    ...rest,
    className: classnames(styledButton, className),
    onClick:
      loading || rest.disabled
        ? undefined
        : (e) => {
            onDebounceClick(e);
            setDebouncingFalse();
          },
    role: "button",
    children: children ? <span>{children}</span> : undefined,
    htmlType:
      "href" in rest
        ? undefined
        : delayedDebouncing || loading || rest.disabled
        ? "button" // this prevents forms from being submitted multiple times on doubleclck
        : rest.htmlType,
  };

  return tooltipProps?.title ? (
    <Tooltip {...tooltipProps}>
      <AntButton {...buttonProps} />
    </Tooltip>
  ) : (
    <AntButton {...buttonProps} />
  );
}

Button.__ANT_BUTTON = true; // fixes problem where wrapped tooltip won't show. https://github.com/ant-design/ant-design/blob/master/components/tooltip/index.tsx#L82
Button.Group = AntButton.Group;
Button.Delete = Delete;
Button.Dropdown = Dropdown;

const useStyledButton = createUseStyle<ButtonProps>(({ theme, ...props }) =>
  css`
    & > .${theme.prefixCls}-btn, // in the case of disabled button, ant wraps the button with a span
    &.${theme.prefixCls}-btn {
      .icon,
      .anticon {
        font-size: ${theme.fontSizeIcon}px;
      }
      &.${theme.prefixCls}-btn-sm {
        font-size: ${theme.fontSizeSM}px; // should be 12px
        line-height: ${theme.lineHeightSM};
        .icon,
        .anticon {
          font-size: ${theme.fontSizeIcon - 2}px; // should be 10px
        }
      }
      &::after {
        transition-duration: 0s;
      }
      ${() =>
        props.loading &&
        `
        & > * {
          visibility: hidden;
        }
        &::after {
          font-family: "Font Awesome 6 Pro";
          content: "\\f3f4";
          animation: spinner 1s linear infinite normal forwards;
          font-size: 1.25em;
          position: absolute;
          top: 50%;
          left: 50%;
          right: auto;
          bottom: auto;
          pointer-events: none;
          opacity: 1;
        }
      `}
    }
    @keyframes spinner {
      0% {
        transform: translate(-50%, -50%) rotate(0deg);
      }
      100% {
        transform: translate(-50%, -50%) rotate(360deg);
      }
    }
  `(),
);
