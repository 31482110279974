import { useEffect } from "react";
import { useShallowSelector } from ".";
import { loadFees } from "store/actions/fees";

export function useFees() {
  useEffect(() => {
    loadFees();
  }, []);

  return useShallowSelector((state) => {
    if (state.fees.fetchStatus.data === undefined) {
      loadFees();
    }

    return {
      data: state.fees.entities,
      status: state.fees.fetchStatus.data,
      error: state.fees.errors.data,
    };
  });
}
