import { Access, RecipientStatus } from "@trolley/common-frontend";
import { DateDisplay, Grid, GridProps, Icon, InputTags, Link, Spinner, Typography } from "components";
import { useStyledLink } from "components/Link";
import { TextProps as TypographyTextProps } from "components/Typography/Text";
import { PayoutMethodDisplay } from "features/payoutMethod";
import { AvatarRecipient, RecipientProfileAddress, RecipientRiskScore, RecipientVerificationStatus, StatusRecipient } from "features/recipient";
import { RecipientTaxFormType } from "features/taxForm";
import { TicketAlert } from "features/ticket";
import { hasRemainingTicketTasks } from "features/ticket/TicketAlert";
import React, { MouseEvent, ReactNode } from "react";
import { notifyError } from "store/actions/notifications";
import { updateRecipient } from "store/actions/recipients";
import { useMerchantSettings } from "store/hooks/merchantSettings";
import { useRecipient } from "store/hooks/recipients";
import { useRecipientTickets } from "store/hooks/tickets";
import { useAccess } from "store/hooks/user";
import { BaseStatus } from "store/reducers/standardReducer";
import styled from "style/classname";

interface Props extends Omit<GridProps, "padding"> {
  recipientId: string | undefined;
  size?: "large" | "small";
  showAvatar?: boolean;
  onlyName?: boolean;
  nameSuffix?: ReactNode;
  showStatus?: true | "dot";
  showEmail?: true;
  showAddress?: true;
  showLink?: boolean | ((e: MouseEvent<HTMLAnchorElement>) => void);
  showTags?: true | "editable";
  showRisk?: true;
  showTicket?: true;
  showTaxForm?: true;
  showPayoutMethod?: boolean;
  showUpdatedAt?: boolean;
  showVerificationStatus?: boolean;
}

export default function RecipientProfile({
  recipientId,
  size,
  showAvatar = true,
  showStatus,
  showEmail,
  showAddress,
  showLink,
  showTags,
  showRisk,
  showTicket,
  showTaxForm,
  children,
  align = "middle",
  onlyName,
  nameSuffix,
  showPayoutMethod,
  showVerificationStatus,
  showUpdatedAt,
  style,
  ...rest
}: Props) {
  const { features } = useMerchantSettings();
  const { data: recipient, status } = useRecipient(recipientId);
  const accessRecipientWrite = useAccess(Access.RECIPIENT_WRITE);
  const recipientTickets = useRecipientTickets(showTicket ? recipientId : undefined);
  const merchantSettings = useMerchantSettings();
  const idvEnabled = features.idv && merchantSettings.data?.trust?.requireIDV;
  const styledLink = useStyledLink();

  const name = (
    <Typography.Text weight="bold" inline>
      {recipient ? (
        (recipient.type === "business" ? recipient.name : [recipient.firstName, recipient.lastName].filter((v) => v).join(" ")) || "[no name]"
      ) : (
        <span style={{ whiteSpace: "nowrap" }}>{recipientId}</span>
      )}
      {!recipient && status === BaseStatus.LOADING ? <Spinner size="small" right /> : null}
    </Typography.Text>
  );
  const recipientName =
    showLink && recipient ? (
      typeof showLink === "function" ? (
        <a
          role="button"
          onClick={(e) => {
            e?.stopPropagation?.();
            showLink(e);
          }}
          className={styledLink}
        >
          {name}
        </a>
      ) : (
        <Link style={{ minWidth: "min-content" }} to={`/recipients/${recipientId}`}>
          {name}
        </Link>
      )
    ) : (
      name
    );

  if (onlyName) {
    return recipientName;
  }

  const secondaryTextProps: TypographyTextProps = {
    size: "base",
    type: "secondary",
  };

  return (
    <>
      <Grid {...rest} align={align} wrap={false} padding={size === "small" ? "small" : undefined} style={style}>
        {showAvatar && (
          <Grid.Item alignSelf={size === "small" ? undefined : "flex-start"} style={{ paddingTop: size === "large" ? "4px" : "0px" }}>
            <AvatarRecipient size={size === "small" ? "small" : "large"} src={recipient?.gravatarUrl} recipient={recipient} showStatus={showStatus === "dot"} />
          </Grid.Item>
        )}
        <Grid.Item flex={1} style={{ lineHeight: "calc(1em + 2px)", minWidth: "20ch" }}>
          <Typography.Text size={size === "small" ? undefined : "large"} className={styledRecipientName}>
            {recipientName}

            {recipient && showStatus === true && (
              <StatusRecipient recipient={recipient} size={showStatus === true && size === "small" ? ("small" as "small") : undefined} />
            )}
            {!!showRisk && recipient && <RecipientRiskScore recipientId={recipient.id} />}
            {!!showVerificationStatus && idvEnabled && recipient && recipient.idvStatus && (
              <RecipientVerificationStatus status={recipient.idvStatus} source={recipient.idvSource} />
            )}
            {nameSuffix}
          </Typography.Text>

          {showEmail && (
            <Typography.Text
              data-test="recipient-email"
              ellipsis={{ tooltip: { title: recipient?.email } }}
              {...secondaryTextProps}
              style={{ lineHeight: size === "small" ? "16px" : undefined }}
            >
              {size !== "small" && <Icon type="envelope" left theme="solid" />}
              {recipient?.email}
            </Typography.Text>
          )}
          {showAddress && (
            <Typography.Text {...secondaryTextProps} style={{ lineHeight: size === "small" ? "16px" : undefined }}>
              <RecipientProfileAddress recipientId={recipientId} icon={size !== "small"} />
            </Typography.Text>
          )}

          {/* EXTRA FIELD FOR MEDIUM/LARGE VIEWS */}
          {size !== "small" && (
            <>
              {!!showTaxForm && features.tax && (
                // Note: RecipientTaxFormType will render null if tax is not enabled
                <Typography.Text {...secondaryTextProps}>{recipient && <RecipientTaxFormType recipientId={recipient.id} labelType="text" />}</Typography.Text>
              )}

              {!!showTicket && recipient && hasRemainingTicketTasks(recipientTickets) && (
                <Icon.Status type="warning" tooltip={<TicketAlert relatedItemId={recipient.id} />} />
              )}

              {!!showPayoutMethod && (
                <Typography.Text {...secondaryTextProps}>{recipient && <PayoutMethodDisplay value={recipient.payoutMethod} showLabel />}</Typography.Text>
              )}

              {!!showUpdatedAt && <Typography.Text {...secondaryTextProps}>{recipient && <DateDisplay icon value={recipient.updatedAt} />}</Typography.Text>}

              {!!showTags && recipient && (
                <div style={{ paddingBottom: "6px" }}>
                  <InputTags
                    editable={accessRecipientWrite && showTags === "editable" && recipient.status !== RecipientStatus.ARCHIVED}
                    value={recipient.tags}
                    onChange={async (tags) => {
                      if (status !== BaseStatus.LOADING) {
                        try {
                          await updateRecipient(recipient.id, { tags });
                        } catch {
                          notifyError("Deleting tag failed");
                        }
                      }
                    }}
                  />
                </div>
              )}
            </>
          )}
          {children}
        </Grid.Item>
      </Grid>
    </>
  );
}

const styledRecipientName = styled`
  display: flex;
  white-space: normal;
  align-items: center;
  word-break: normal;
  gap: 4px;
  flex-wrap: wrap;
`();
