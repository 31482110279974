import { batch as reduxBatch } from "react-redux";
import { OpCode, standardDispatch } from "store/dispatcher";
import * as request from "services/request";
import * as types from "@trolley/common-frontend";
import { loadRecipient } from "./recipients";

export type RecipientAccount = types.RecipientAccount;
export interface BaseRecipientAccountUpdate {
  primary?: boolean;
  type: types.PayoutMethodType;
}

export interface BankTransferAccountUpdate extends BaseRecipientAccountUpdate {
  currency: types.CurrencyCode;
  country: types.CountryCode;
  iban?: string;
  accountNum?: string;
  accountHolderName?: string;
  swiftBic?: string;
  branchId?: string;
  bankId?: string;
  bankName?: string;
  bankAddress?: string;
  bankCity?: string;
  bankRegionCode?: string;
}

export interface CheckAccountUpdate extends BaseRecipientAccountUpdate {
  mailing: {
    name: string;
    street1: string;
    street2?: string;
    city: string;
    region: string;
    postal: string;
    country: string;
  };
}

export interface InteracAccountUpdate extends BaseRecipientAccountUpdate {
  currency: types.CurrencyCode.CAD;
  emailAddress: string;
}

export interface PayPalAccountUpdate extends BaseRecipientAccountUpdate {
  currency: types.CurrencyCode.USD;
  emailAddress: string;
}

export interface BitcoinAccountUpdate extends BaseRecipientAccountUpdate {
  wallet: string;
}

export interface VenmoAccountUpdate extends BaseRecipientAccountUpdate {
  currency: types.CurrencyCode.USD;
  phoneNumber: string;
}

export type RecipientAccountUpdate =
  | BankTransferAccountUpdate
  | CheckAccountUpdate
  | InteracAccountUpdate
  | PayPalAccountUpdate
  | BitcoinAccountUpdate
  | VenmoAccountUpdate;

export async function addRecipientPayoutMethod(id: string, change: RecipientAccountUpdate) {
  try {
    standardDispatch(OpCode.LOADING, "recipient", { id });
    const { body } = await request.POST<types.RecipientAccountResponse>(`/v1/recipients/${id}/accounts`, { query: change });

    reduxBatch(() => {
      standardDispatch(OpCode.LOADING, "recipient", { id, loading: false });
      standardDispatch(OpCode.DATA, "recipientAccount", { id: body.account.id, data: body.account });
    });

    loadRecipient(id, true); // websocket does not emit recipient on account update
  } catch (errors) {
    standardDispatch(OpCode.ERROR, "recipient", {
      id,
      errors,
    });
    throw errors;
  }
}

export async function updateRecipientPayoutMethod(id: string, change: Partial<RecipientAccountUpdate>, accountId: string) {
  try {
    standardDispatch(OpCode.LOADING, "recipient", { id });
    const { body } = await request.PATCH<types.RecipientAccountResponse>(`/v1/recipients/${id}/accounts/${accountId}`, { query: change });

    if (body.account.id === accountId) {
      standardDispatch(OpCode.DATA, "recipientAccount", { id: accountId, data: body.account });
    } else {
      // remove old account id, and add new id
      reduxBatch(() => {
        standardDispatch(OpCode.DELETE, "recipientAccount", { id: accountId });
        standardDispatch(OpCode.DATA, "recipientAccount", { id: body.account.id, data: body.account });
      });
    }

    loadRecipient(id, true); // websocket does not emit recipient on account update
  } catch (errors) {
    standardDispatch(OpCode.ERROR, "recipient", {
      id,
      errors,
    });
    throw errors;
  }
}

export async function deleteRecipientPayoutMethod(id: string, accountId: string) {
  try {
    standardDispatch(OpCode.LOADING, "recipient", { id });
    await request.DELETE<{}>(`/v1/recipients/${id}/accounts/${accountId}`);

    standardDispatch(OpCode.DELETE, "recipientAccount", { id: accountId });

    loadRecipient(id, true); // websocket does not emit recipient on account update
  } catch (errors) {
    standardDispatch(OpCode.ERROR, "recipient", {
      id,
      errors,
    });
    throw errors;
  }
}
