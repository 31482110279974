import { countryHasPostalCode, UsUploadTaxFormType } from "@trolley/common-frontend";
import { Checkbox, Form, Grid, Input, InputDate, SelectCountry, SelectRegion } from "components";
import { validatePastDate } from "components/Form/InputDate";
import React from "react";
import { UsUpload } from "store/actions/taxForms";
import { translateRegionByCountry } from "utils/helpers";
import { isRegionInCountryAsyncValidator } from "../Info";
import { irsRule, latinRule, latinRuleIgnoreStar } from "./variables";

interface Props {
  data: UsUpload;
}

export default function OtherUploadForm(props: Props) {
  const { data } = props;
  const form = Form.useFormInstance();
  const uploadKind = Form.useWatch(["data", "uploadKind"], form);

  return (
    <>
      <Grid.Item xs={24} md={12}>
        <Form.Item label="First Name" name={["data", "firstName"]} rules={[{ required: true, message: "Enter the First Name" }, irsRule]}>
          <Input name="data.firstName" />
        </Form.Item>
      </Grid.Item>
      <Grid.Item xs={24} md={12}>
        <Form.Item label="Last Name" name={["data", "lastName"]} rules={[{ required: true, message: "Enter the Last Name" }, irsRule]}>
          <Input name="data.lastName" />
        </Form.Item>
      </Grid.Item>
      {uploadKind === UsUploadTaxFormType.W8ECI && (
        <Grid.Item xs={24}>
          <Form.Item label="Date of Birth" name={["data", "dob"]} rules={[{ required: true, message: "Select a Date of Birth" }, validatePastDate]}>
            <InputDate />
          </Form.Item>
        </Grid.Item>
      )}

      <Grid.Item xs={24}>
        <Form.Item label="Business Name" name={["data", "organizationName"]} rules={[irsRule]}>
          <Input name="data.organizationName" />
        </Form.Item>
      </Grid.Item>
      <Grid.Item xs={24}>
        <Form.Item label="Mailing Country" name={["data", "mailingCountry"]} rules={[{ required: true, message: "Enter the Country" }]}>
          <SelectCountry type="all" />
        </Form.Item>
      </Grid.Item>
      <Form.Control dependencies={[["data", "mailingCountry"]]}>
        {({ getFieldValue }) => {
          const mailingCountry = getFieldValue(["data", "mailingCountry"]);

          return (
            <>
              <Grid.Item xs={24} md={12}>
                <Form.Item label="Mailing Address" name={["data", "mailingAddress"]} rules={[{ required: true, message: "Enter the Address" }, latinRule]}>
                  <Input name="data.mailingAddress" />
                </Form.Item>
              </Grid.Item>
              <Grid.Item xs={24} md={12}>
                <Form.Item label="Mailing City" name={["data", "mailingCity"]} rules={[{ required: true, message: "Enter the City" }, latinRule]}>
                  <Input name="data.mailingCity" />
                </Form.Item>
              </Grid.Item>
              <Grid.Item xs={24} md={12}>
                <Form.Item
                  label={`Mailing ${translateRegionByCountry(mailingCountry)}`}
                  name={["data", "mailingRegion"]}
                  dependencies={["data", "mailingCountry"]}
                  rules={[
                    { required: true, message: `Enter the ${translateRegionByCountry(mailingCountry)}` },
                    isRegionInCountryAsyncValidator(["data", "mailingCountry"]),
                  ]}
                >
                  <SelectRegion country={mailingCountry} />
                </Form.Item>
              </Grid.Item>
              <Grid.Item xs={24} md={12}>
                <Form.Item
                  label="Mailing Postal Code"
                  name={["data", "mailingPostalCode"]}
                  dependencies={["data", "mailingCountry"]}
                  rules={[{ required: countryHasPostalCode(mailingCountry), message: "Enter the postal code" }, latinRule]}
                >
                  <Input name="data.mailingPostalCode" />
                </Form.Item>
              </Grid.Item>
            </>
          );
        }}
      </Form.Control>

      <Form.Control shouldUpdate>
        {({ getFieldValue }) => {
          const noTaxId = !!getFieldValue(["data", "noTaxId"]);

          return (
            <>
              <Grid.Item xs={24} md={12}>
                <Form.Item
                  label="U.S. Taxpayer Identification Number (TIN)"
                  name={["data", "taxPayerUsId"]}
                  dependencies={[
                    ["data", "noTaxId"],
                    ["data", "taxPayerForeignId"],
                  ]}
                  rules={[
                    (form) => ({
                      required: !form.getFieldValue(["data", "noTaxId"]) && !form.getFieldValue(["data", "taxPayerForeignId"]),
                      message: "An U.S. or Foreign Tax Identification Number is required",
                    }),
                    latinRuleIgnoreStar,
                  ]}
                >
                  <Input name="data.w8Data.taxPayerUsId" placeholder="XX-XXXXXXXX or XXX-XX-XXXX" disabled={noTaxId} />
                </Form.Item>
              </Grid.Item>
              <Grid.Item xs={24} md={12}>
                <Form.Item
                  label="Foreign Tax Identification Number"
                  name={["data", "taxPayerForeignId"]}
                  dependencies={[
                    ["data", "noTaxId"],
                    ["data", "taxPayerUsId"],
                  ]}
                  rules={[
                    (form) => ({
                      required: !form.getFieldValue(["data", "noTaxId"]) && !form.getFieldValue(["data", "taxPayerUsId"]),
                      message: "An U.S. or Foreign Tax Identification Number is required",
                    }),
                    latinRuleIgnoreStar,
                  ]}
                >
                  <Input name="data.w8Data.taxPayerForeignId" disabled={noTaxId} />
                </Form.Item>
              </Grid.Item>
              <Grid.Item xs={24}>
                <Form.Item
                  name={["data", "noTaxId"]} // this field cannot be saved
                  valuePropName="checked"
                  initialValue={!data.taxPayerForeignId && !data.taxPayerUsId}
                >
                  <Checkbox
                    onChange={(checked) => {
                      if (checked) {
                        form.setFields([
                          { name: ["data", "taxPayerUsId"], value: "" },
                          { name: ["data", "taxPayerForeignId"], value: "" },
                        ]);
                      }
                    }}
                  >
                    I am unable to provide a Tax Identification Number.
                  </Checkbox>
                </Form.Item>
              </Grid.Item>
            </>
          );
        }}
      </Form.Control>
    </>
  );
}
