import { Layout as AntLayout } from "antd";
import { BasicProps } from "antd/lib/layout/layout";
import { OverrideToken } from "antd/lib/theme/interface";
import { AliasToken } from "antd/lib/theme/internal";
import assignDeep from "assign-deep";
import React, { ReactNode } from "react";

import { useThemeToken } from "style/classname";
import colors from "style/colors";
import { ThemeProvider } from "utils/context";
import { useWindowSize } from "utils/hooks";
import Grid from "../Grid";

interface LayoutProps extends BasicProps {
  token?: Partial<AliasToken>;
  components?: (token: Partial<AliasToken>) => OverrideToken;
  header?: ReactNode;
  size?: "small" | "large";
  sidebar?: ReactNode;
}

export default function SinglePage({
  prefixCls,
  size,
  token: overrideToken,
  components: overrideComponents,
  header,
  sidebar,
  children,
  style,
  ...rest
}: LayoutProps) {
  const { isMobile } = useWindowSize();
  const theme = useThemeToken();

  return (
    <ThemeProvider
      token={{
        fontSize: 16,
        fontSizeHeading1: 36,
        fontSizeHeading2: 32,
        fontSizeHeading3: 24,
        fontSizeHeading4: 20,
        fontSizeHeading5: 18,
        lineHeightHeading1: (36 + 8) / 36,
        lineHeightHeading2: (32 + 8) / 32,
        lineHeightHeading3: (24 + 8) / 24,
        lineHeightHeading4: (20 + 8) / 20,
        lineHeightHeading5: (18 + 8) / 18,
        colorBgContainer: colors.white,
        ...overrideToken,
      }}
      components={(tok) =>
        assignDeep(
          {
            Layout: {
              colorBgHeader: tok.purple8,
              controlHeight: 55, // header height = controlHeight x 2
              colorBgBody: tok.colorWhite,
            },
          },
          overrideComponents?.(tok),
        )
      }
      prefixCls={prefixCls}
    >
      <AntLayout {...rest} style={{ minHeight: "100%", ...style }}>
        {header && (
          <AntLayout.Header style={{ borderBottom: `1px solid ${theme.grey3}`, padding: "0", margin: "0" }}>
            <section style={{ padding: "0 16px", margin: "0 auto", maxWidth: sidebar ? "auto" : "960px" }}>{header}</section>
          </AntLayout.Header>
        )}
        <AntLayout.Content style={{ padding: "24px 16px", margin: sidebar ? "0" : "0 auto", maxWidth: "960px", width: "100%" }}>
          <Grid padding="large" justify="space-between" wrap={isMobile}>
            {sidebar && (
              <Grid.Item flex={isMobile ? "100%" : 1} style={{ maxWidth: isMobile ? "100%" : "240px" }} alignSelf="flex-start">
                {sidebar}
              </Grid.Item>
            )}
            <Grid.Item flex={isMobile ? "100%" : 1} style={{ maxWidth: isMobile ? "100%" : getBodyMaxWidth(size) }}>
              {children}
            </Grid.Item>
          </Grid>
        </AntLayout.Content>
      </AntLayout>
    </ThemeProvider>
  );
}

function getBodyMaxWidth(size?: LayoutProps["size"]) {
  switch (size) {
    case "small":
      return "450px";
    case "large":
      return "960px";
    default:
      return "650px";
  }
}
