import { CountryCode, formatCountry } from "@trolley/common-frontend";
import { InputRef } from "antd";
import DisabledContext from "antd/lib/config-provider/DisabledContext";
import Flag from "components/Flag";
import Input, { InputProps, styledRemoveIcon } from "components/Form/Input";
import Icon from "components/Icon";
import Space from "components/Space";
import * as PhoneNumber from "libphonenumber-js";
import examples from "libphonenumber-js/mobile/examples";
import React, { useContext, useLayoutEffect, useRef, useState } from "react";
import css, { classnames, createUseStyle } from "style/classname";
import Select from "./Select";

export interface PhoneNumberErrorTranslations {
  invalidCountry?: string;
  tooShort?: string;
  tooLong?: string;
  invalidPhone?: string;
  supportedCountry?: string;
}

export function phoneNumberValidator(allowedCountries?: CountryCode[], errorTranslations?: PhoneNumberErrorTranslations) {
  return {
    async validator(rule: any, fullPhoneNumber: string) {
      if (fullPhoneNumber) {
        try {
          const parsed = PhoneNumber.parsePhoneNumberWithError(fullPhoneNumber);

          if (!parsed.country || !parsed.isValid()) {
            throw errorTranslations?.invalidPhone ?? "Enter a valid phone number";
          }

          if (allowedCountries?.length && !allowedCountries.includes(parsed.country as CountryCode)) {
            throw errorTranslations?.supportedCountry ?? "Enter a phone number from a supported country";
          }
        } catch (error) {
          if (error instanceof PhoneNumber.ParseError) {
            switch (error.message) {
              case "INVALID_COUNTRY":
                throw errorTranslations?.invalidCountry ?? "The phone number does not seem to belong to any country";
              case "TOO_SHORT":
                throw errorTranslations?.tooShort ?? "The phone number is too short";
              case "TOO_LONG":
                throw errorTranslations?.tooLong ?? "The phone number is too long";
              case "NOT_A_NUMBER":
              default:
                throw errorTranslations?.invalidPhone ?? "Enter a valid phone number";
            }
          } else {
            throw error;
          }
        }
      }
    },
  };
}

export function getCountryDialCode(country: CountryCode | PhoneNumber.CountryCode | null | undefined) {
  if (country) {
    const dialCode = PhoneNumber.getCountryCallingCode(country as PhoneNumber.CountryCode);

    if (dialCode) {
      return `+${dialCode}`;
    }
  }

  return "";
}

export function parsePhoneNumber(value: string | null | undefined, defaultCountry?: PhoneNumber.CountryCode | undefined) {
  try {
    const parsed = PhoneNumber.parsePhoneNumber(value ?? "", defaultCountry);
    if (parsed.countryCallingCode) {
      const internationalFormat = parsed.formatInternational();
      const prefix = `+${parsed.countryCallingCode}`;
      const lineNumber = internationalFormat.substring(prefix.length).trim();
      /**
       * National phone format if you were to call within the country. It will sometimes include a "trunk prefix".
       * https://en.wikipedia.org/wiki/Trunk_prefix
       */
      const nationalFormat = parsed.formatNational();
      const possibleCountries = parsed.getPossibleCountries();

      return {
        prefix,
        country: parsed.country || possibleCountries.find((c) => c === defaultCountry) || possibleCountries[0],
        lineNumber,
        internationalFormat,
        nationalFormat,
      };
    }
  } catch {
    // unable to parse due to number being incomplete
  }

  /**
   * FALLBACK parsing
   */
  const internationalFormat = PhoneNumber.formatIncompletePhoneNumber(value ?? "", defaultCountry);
  const prefix = /^\+\d+/.test(value ?? "") && defaultCountry ? `+${PhoneNumber.getCountryCallingCode(defaultCountry)}` : "";
  const lineNumber = internationalFormat.substring(prefix.length).trim();

  return {
    country: defaultCountry,
    prefix,
    lineNumber,
    internationalFormat,
    nationalFormat: lineNumber,
  };
}

interface InputPhoneProps extends Omit<InputProps, "addonBefore" | "prefix" | "value" | "onChange" | "placeholder"> {
  value?: string;
  onChange?(value: string): void;
  allowedCountries?: CountryCode[];
  defaultCountry?: CountryCode;
  placeholder?: string;
}

export default function InputPhone({
  value,
  defaultCountry,
  allowedCountries,
  className,
  readOnly,
  onChange,
  disabled: customDisabled,
  allowClear,
  suffix,
  placeholder: defaultPlaceholder,
  ...props
}: InputPhoneProps) {
  // https://github.com/ant-design/ant-design/blob/4.24.7/components/input/Input.tsx#L159
  const disabledContext = useContext(DisabledContext);
  const disabled = disabledContext ?? customDisabled;
  const inputRef = useRef<InputRef | null>(null);
  const [country, setCountry] = useState<PhoneNumber.CountryCode | undefined>();
  const { lineNumber, nationalFormat } = parsePhoneNumber(value, country);
  const prefix = country && PhoneNumber.getCountryCallingCode(country) ? `+${PhoneNumber.getCountryCallingCode(country)}` : "";
  const styledCountrySelector = useStyledCountrySelector();
  const styledInput = useStyledInput({ disabled });
  const placeholder = defaultPlaceholder
    ? defaultPlaceholder
    : country
    ? PhoneNumber.getExampleNumber(country as PhoneNumber.CountryCode, examples)
        ?.formatInternational()
        .substring(prefix.length)
        .trim() || ""
    : "Select a country";

  useLayoutEffect(() => {
    if (value) {
      const parsed = parsePhoneNumber(value, country);
      if (parsed?.country && parsed.country in CountryCode && (!allowedCountries?.length || allowedCountries.includes(parsed.country as CountryCode))) {
        // set new country based on the parser.
        setCountry(parsed.country);
      } else if (country && allowedCountries?.length && !allowedCountries.includes(country as CountryCode)) {
        // reset back to undefined selector picked a country that is not allowed
        setCountry(undefined);
      }
    } else if (!country && defaultCountry) {
      setCountry(defaultCountry as PhoneNumber.CountryCode);
    }
  }, [value, country, defaultCountry]);

  if (readOnly || disabled) {
    return <Input value={value} disabled={disabled} readOnly={readOnly} prefix={country && <Flag code={country as CountryCode} showLabel={false} />} />;
  }

  return (
    <Input
      {...props}
      addonBefore={
        <Select
          placeholder={<Icon type="globe" color="grey" fixedWidth={false} />}
          defaultActiveFirstOption
          value={country}
          onBlur={props.onBlur}
          showSearch
          aria-label={`${props.id ?? "Phone"} AreaCode`}
          optionFilterProp="title"
          className={styledCountrySelector}
          popupMatchSelectWidth={false}
          options={PhoneNumber.getCountries()
            .filter((code) => CountryCode[code] && (!allowedCountries?.length || allowedCountries.includes(code as CountryCode)))
            .map((code) => ({
              value: code,
              title: `+${PhoneNumber.getCountryCallingCode(code)} ${code} ${formatCountry(code)}`,
              label: <Flag code={code as CountryCode} suffix={`+${PhoneNumber.getCountryCallingCode(code)}`} />,
            }))}
          onChange={(code) => {
            if (CountryCode[code as CountryCode]) {
              const parsed = parsePhoneNumber([`+${PhoneNumber.getCountryCallingCode(code as PhoneNumber.CountryCode)}`, lineNumber].join(""), code);
              setCountry(code);
              onChange?.(parsed.internationalFormat);
              inputRef?.current?.focus?.();
            }
          }}
        />
      }
      ref={inputRef}
      prefix={prefix}
      value={lineNumber}
      disabled={!country}
      onChange={(e) => {
        const parsed = parsePhoneNumber(`${prefix}${e.target.value}`, country);
        onChange?.(parsed.internationalFormat);
        if (parsed.country && country !== parsed.country && (!allowedCountries?.length || allowedCountries.includes(parsed.country as CountryCode))) {
          setCountry(parsed.country);
        }
      }}
      placeholder={placeholder}
      className={classnames(styledRemoveIcon, styledInput, className)}
      suffix={
        !!country && (
          <Space>
            {suffix}
            {nationalFormat.replace(/[^0-9]/g, "").length !== lineNumber.replace(/[^0-9]/g, "").length && (
              <Icon.Status type="info" tooltip={`To make a domestic call in ${formatCountry(country)}, the phone number is ${nationalFormat}`} />
            )}
            {allowClear && value && (
              <Icon
                type="circle-xmark"
                onClick={(e) => {
                  e?.stopPropagation?.();
                  e?.preventDefault?.();
                  setCountry(allowClear ? undefined : (defaultCountry as PhoneNumber.CountryCode));
                  onChange?.("");
                  props.onBlur?.(e as any); // this sometimes help triggering the validation
                }}
                color="grey"
                theme="solid"
              />
            )}
          </Space>
        )
      }
    />
  );
}
const useStyledInput = createUseStyle<{ disabled: boolean | undefined }>(({ theme, ...props }) =>
  css`
    .${theme.prefixCls}-input-group-addon {
      ${!props.disabled && `background-color: ${theme.colorBgContainer};`}
    }
  `(),
);

const useStyledCountrySelector = createUseStyle(({ theme }) =>
  css`
    text-align: left;
    &.${theme.prefixCls}-select {
      width: 65px;
      transition: width 200ms ease;
      &.${theme.prefixCls}-select-focused {
        width: 120px;
        .flag-label {
          display: inherit;
        }
      }
    }

    .${theme.prefixCls}-select-selection-item {
      .flag-label,
      .flag-suffix {
        display: none;
      }
    }
  `(),
);
