import { allMappedCountries } from "@trolley/common-frontend";
import { Select, SelectProps } from "components";
import React from "react";
import { translateRegionByCountry } from "utils/helpers";

const sortRegions = (entries: { name: string; shortCode?: string }[], country: string): { label: string; value: string }[] => {
  try {
    const sortedArray = entries.map((region) => {
      return {
        label: ["US", "CA"].includes(country) ? `${region.name} - ${region.shortCode}` : region.name,
        value: region.shortCode || region.name,
      };
    });

    sortedArray.sort((a, b) => {
      const nameA = a.label.toLocaleLowerCase();
      const nameB = b.label.toLocaleLowerCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      return 0;
    });

    return sortedArray;
  } catch {
    return [];
  }
};

interface Props extends Omit<SelectProps<string>, "children" | "options"> {
  country: string | undefined | null;
}

export default function SelectRegion(props: Props) {
  const { showSearch = true, country, placeholder, value, ...rest } = props;

  return (
    <Select
      showSearch={showSearch}
      placeholder={placeholder || `Select a ${translateRegionByCountry(country)}`}
      value={value || undefined}
      options={(country && allMappedCountries[country] && sortRegions(allMappedCountries[country].regions, country).map((region) => region)) || []}
      optionFilterProp="label"
      {...rest}
    />
  );
}
