import { CurrencyDisplay, DateDisplay, Icon, Spinner, Text, TextProps } from "components";
import { PaymentPreview, StatusPayment } from "features/payment";
import React from "react";
import { usePayment } from "store/hooks/payments";
import { BaseStatus } from "store/reducers/standardReducer";

interface Props {
  paymentId: string | undefined;
  showPreview?: boolean;
  showStatus?: boolean;
  showDate?: boolean;
  size?: TextProps["size"];
}

export default function PaymentDisplay(props: Props) {
  const { paymentId, showDate, showPreview = true, showStatus, size } = props;
  const { data: payment, status } = usePayment(paymentId);
  const title = <CurrencyDisplay value={payment?.amount} currency={payment?.currency} />;

  if (payment) {
    return (
      <>
        <Text size={size}>
          {showPreview ? <PaymentPreview paymentId={paymentId}>{title}</PaymentPreview> : title}
          {showStatus && <StatusPayment payment={payment} right size="small" />}
        </Text>
        {showDate && (
          <Text type="secondary" size="small">
            {payment.initiatedAt ? "Sent " : "Updated "}
            <DateDisplay value={payment.initiatedAt || payment.updatedAt} time={false} icon={false} />
          </Text>
        )}
      </>
    );
  }

  if (status === BaseStatus.LOADING) {
    return (
      <>
        Loading payment...
        <Spinner right />
      </>
    );
  }

  if (status !== undefined) {
    return (
      <>
        <Icon.Status
          type="error"
          left
          tooltip={
            <>
              <strong>{paymentId}</strong>
              <br />
              cannot be found
            </>
          }
        />
        Payment Not Found
      </>
    );
  }

  return null;
}
