import { Breakpoints } from "@trolley/common-frontend";
import Grid from "components/Grid";
import React, { ReactNode } from "react";
import styled, { classnames } from "style/classname";
import { ANIMATION, BREAKPOINTS, MEDIA_BREAKPOINTS } from "style/variables";

export const DEFAULT_CONTAINER_SIDEBAR_WIDTH = 230;

const styledWrapper = styled`
  animation: ${ANIMATION.fadeInDown} 0.375s ease;
  ${(props: Props) => props.align && `text-align: ${props.align};`}
  ${(props: Props) => props.maxWidth && `max-width: ${BREAKPOINTS[props.maxWidth]}px;`}
  padding: ${(props: Props) => (props.padding === "none" ? "0px" : "16px")};
  ${MEDIA_BREAKPOINTS.mdUp} {
    padding: ${(props: Props) => {
      switch (props.padding) {
        case "none":
          return `0px`;
        case "small":
          return `16px`;
        case "large":
          return `24px 48px`;
        default:
          return `16px 32px`;
      }
    }};
  }

  & > .container__content {
    flex: 1 1 0;
  }
  & > .container__sidebar {
    flex: 1 0 0;
    min-width: 100%;
  }
  ${(props) => {
    const breakpoint = props.maxWidth ? BREAKPOINTS[props.maxWidth] : BREAKPOINTS.lg;

    return `
        @media screen and (min-width: ${breakpoint}) {
          flex-wrap: nowrap;
          & > .container__content {
            max-width: calc(100% - ${props.sidebarWidth}px);
          }
          & > .container__sidebar {
            min-width: ${props.sidebarWidth}px;
            max-width: ${props.sidebarWidth}px !important;
          }
        }
      `;
  }}
`;

type Props = React.HTMLAttributes<HTMLDivElement> & {
  maxWidth?: Breakpoints;
  padding?: "none" | "small" | "large";
  align?: "left" | "center" | "right";
  sidebar?: ReactNode;
  sidebarWidth?: number;
};

export default function Container(props: Props) {
  const { className, align, padding, maxWidth, sidebar, sidebarWidth = DEFAULT_CONTAINER_SIDEBAR_WIDTH, ...rest } = props;

  return sidebar ? (
    <Grid className={classnames("container", styledWrapper({ ...props, sidebarWidth, maxWidth }))} direction="row-reverse">
      <Grid.Item className="container__sidebar">{sidebar}</Grid.Item>
      <Grid.Item align={align} className={classnames("container__content", className)} {...rest} />
    </Grid>
  ) : (
    <div className={classnames("container", className, styledWrapper({ ...props, sidebarWidth }))} {...rest} />
  );
}
