import {
  Alert,
  Box,
  ButtonLink,
  CopyToClipboard,
  CurrencyDisplay,
  DateDisplay,
  Flyout,
  Form,
  GovernmentIdsInput,
  Grid,
  Icon,
  Link,
  LogoLoader,
  Text,
  Timeline,
} from "components";
import { RecipientType } from "@trolley/common-frontend";
import PayoutMethodsList from "pages/RecipientsPage/Details/Info/PayoutMethodsList";
import { DEFAULT_QUERY } from "pages/RecipientsPage/Details/RecipientPayments";
import { StatusPayment } from "features/payment";
import { RecipientProfile, RecipientProfileAddress } from "features/recipient";
import { TicketAlert } from "features/ticket";
import dayjs from "dayjs";
import React from "react";
import { Payment } from "store/actions/payments";
import { useRecipientPaymentList } from "store/hooks/payments";
import { useRecipient } from "store/hooks/recipients";
import { BaseStatus } from "store/reducers/standardReducer";

const MAX_TIMELINE_ITEMS = 5;
function formatPaymentsTimeline(payments: Payment[]) {
  const output: (string | Payment)[] = [];
  let lastDate: string;

  payments.forEach((pid, index: number) => {
    if (index < MAX_TIMELINE_ITEMS) {
      const currentDate = dayjs(pid.initiatedAt || pid.updatedAt).format("YYYY-MM-DD");
      if (!lastDate || currentDate !== lastDate) {
        lastDate = currentDate;
        output.push(currentDate);
      }
      output.push(pid);
    }
  });

  return output;
}

interface Props {
  recipientId?: string;
  onClose(e?: any): void;
}

export default function RecipientPreview(props: Props) {
  const { recipientId, onClose } = props;
  const {
    data: { records: recipientPayments },
    status: recipientPaymentListStatus,
  } = useRecipientPaymentList(recipientId, DEFAULT_QUERY);
  const { data: recipient } = useRecipient(recipientId);
  const paymentsTimeline = formatPaymentsTimeline(recipientPayments);

  return (
    <Flyout
      visible={!!recipient}
      width="small"
      onClose={onClose}
      title={<RecipientProfile size="large" wrap recipientId={recipientId} showEmail showStatus showAddress showTags="editable" showRisk showTaxForm />}
      subtitle={
        recipient && (
          <ButtonLink size="large" type="primary" path={`/recipients/${recipient.id}`} block>
            View Details
          </ButtonLink>
        )
      }
    >
      {recipient && (
        <Form compact>
          <TicketAlert relatedItemId={recipient.id} showLink>
            {(el) => <Alert type="warning">{el}</Alert>}
          </TicketAlert>

          {/* GENERAL */}
          <Box header="Contact Information" padding="small">
            <Form.Item label="Recipient ID">
              <CopyToClipboard value={recipient.id} />
            </Form.Item>

            {recipient.referenceId !== recipient.id && (
              <Form.Item label="Reference ID">
                <CopyToClipboard value={recipient.referenceId} />
              </Form.Item>
            )}

            <Form.Item label="Email">
              <CopyToClipboard value={recipient.email} />
            </Form.Item>

            {recipient.address.phone && <Form.Item label="Phone">{recipient.address.phone}</Form.Item>}

            <Form.Item label="Address">
              <RecipientProfileAddress recipientId={recipientId} detailed />
            </Form.Item>

            {recipient.address.country && !!recipient.governmentIds?.length && (
              <Form.Item label="Government ID Numbers">
                <GovernmentIdsInput
                  formCountry={recipient.address.country}
                  value={recipient.governmentIds}
                  recipientType={recipient.type as RecipientType}
                  recipientCountry={recipient.address.country}
                  readOnly
                />
              </Form.Item>
            )}

            {recipient.dob && (
              <Form.Item label="Date of Birth">
                <DateDisplay value={recipient.dob} showUtc time={false} />
              </Form.Item>
            )}
          </Box>

          {/* PAYOUT METHODS */}
          <Box header="Payout Methods" padding="small">
            <PayoutMethodsList recipient={recipient} />
          </Box>

          {/* PAYMENTS */}
          <LogoLoader spinning={recipientPaymentListStatus === BaseStatus.LOADING}>
            <Box header="Recent Payments" padding="small">
              {paymentsTimeline.length > 0 ? (
                <Timeline>
                  {paymentsTimeline.map((payment) =>
                    typeof payment === "string" ? (
                      <Timeline.Item key={payment} dot={<Icon type="clock" color="grey" />}>
                        <Text weight="bold" style={{ paddingTop: "2px" }}>
                          {dayjs(payment).format("MMMM D")}
                        </Text>
                      </Timeline.Item>
                    ) : (
                      <Timeline.Item key={payment.id}>
                        <Grid justify="space-between" padding={["small", "none"]} wrap={false}>
                          <Grid.Item>
                            <strong>
                              <CurrencyDisplay value={payment.amount} currency={payment.currency} />
                            </strong>
                            <Text type="secondary" size="small">
                              {payment.id}
                            </Text>
                            <Text type="secondary" size="small">
                              {payment.initiatedAt ? (
                                <>Sent at {dayjs(payment.updatedAt).format("h:mma")}</>
                              ) : (
                                <>Updated at {dayjs(payment.updatedAt).format("h:mma")}</>
                              )}
                            </Text>
                          </Grid.Item>
                          <Grid.Item>
                            <StatusPayment payment={payment} />
                          </Grid.Item>
                        </Grid>
                      </Timeline.Item>
                    ),
                  )}
                  <Timeline.Item>
                    <Link to={`/recipients/${recipient.id}/payments`}>See all payments</Link>
                  </Timeline.Item>
                </Timeline>
              ) : (
                <Text align="center" padded>
                  No payments sent
                </Text>
              )}
            </Box>
          </LogoLoader>
        </Form>
      )}
    </Flyout>
  );
}
