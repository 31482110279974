import {
  Access,
  CurrencyCode,
  PaymentCategory,
  PaymentTaxesPayoutMethods,
  PayoutMethodType,
  TaxFormType,
  TaxStatus,
  TaxWithholdingReasons,
} from "@trolley/common-frontend";
import BigNumber from "bignumber.js";
import {
  Button,
  ButtonDelete,
  Checkbox,
  CurrencyDisplay,
  DateDisplay,
  DatePicker,
  Divider,
  Flag,
  Form,
  getLabelTooltip,
  Input,
  InputNumber,
  InputTags,
  Modal,
  Select,
  Space,
  Text,
} from "components";
import { PayoutMethodDisplay } from "features/payoutMethod";
import { RecipientProfile } from "features/recipient";
import { TaxFormDisplay } from "features/taxForm";
import dayjs from "dayjs";
import React, { useEffect } from "react";
import { notifyError, notifySuccess } from "store/actions/notifications";
import { createPaymentTax, deletePaymentTaxes } from "store/actions/offlinePayments";
import { PaymentTax, updatePaymentTax } from "store/actions/paymentTaxes";
import { WithholdingReasonLabels } from "store/actions/recipientTaxForms";
import { useShallowSelector } from "store/hooks";
import { useMerchantSettings } from "store/hooks/merchantSettings";
import { useRecipientTaxForms } from "store/hooks/recipientTaxForms";
import { useAccess } from "store/hooks/user";
import { BaseStatus } from "store/reducers/standardReducer";
import { CATEGORY_DETAILS, FORCE_US_TAX_CATEGORIES, NON_TAXABLE_CATEGORIES } from "utils/constants";
import { handleFormErrors, omitEmpty } from "utils/helpers";

interface Props {
  paymentTax: Partial<PaymentTax & { recipientId: string }> | undefined;
  onClose(): void;
}

type FormFields = {
  payoutMethod: PayoutMethodType;
  category: PaymentCategory;
  taxReportable: boolean;
  forceUsTaxActivity: boolean;
  currency: string;
  amount: string;
  withholdingAmount: string;
  taxWithholdingReason?: string;
  processedAt: string;
  externalId?: string;
  memo: string;
  tags: string[];
  taxFormId: string | null;
};

export default function PaymentTaxRecordEdit(props: Props) {
  const [form] = Form.useForm<FormFields>();
  const { paymentTax, onClose } = props;
  const recipientId = paymentTax?.recipientId;
  const visible = !!paymentTax;
  const accessPaymentWrite = useAccess(Access.PAYMENTS_WRITE);
  const { data: merchantSettings, features } = useMerchantSettings();
  const taxFormsNeeded = !!features.tax && !!merchantSettings?.tax?.enabled && !!paymentTax?.id; // we auto find tax form
  const showForceUsTaxActivities = Object.entries(merchantSettings?.payment?.categories || {}) // only show field when those categories are enbaled
    .filter(([k, v]) => v)
    .some(([enabledCategory]: [PaymentCategory, boolean]) => FORCE_US_TAX_CATEGORIES.includes(enabledCategory));
  const { data: recipientTaxForms } = useRecipientTaxForms(
    recipientId,
    {
      pageSize: 1000,
      status: [TaxStatus.REVIEWED, TaxStatus.SUBMITTED, TaxStatus.EXPIRED, TaxStatus.VOIDED],
    },
    taxFormsNeeded,
  );
  const paymentTaxStatus = useShallowSelector((state) => state.paymentTaxes.fetchStatus.LOADING);

  const paymentCategories = Object.entries(merchantSettings?.payment?.categories || {}) as [PaymentCategory, boolean][];
  const enabledCategories = paymentCategories.filter(([c, enabled]) => enabled && CATEGORY_DETAILS[c]);

  useEffect(() => {
    if (paymentTax) {
      const initialValues = omitEmpty({
        ...paymentTax,
        taxReportable: paymentTax.id ? !!paymentTax.taxReportable : true,
        category: paymentTax.category ?? merchantSettings?.payment?.defaultCategory,
        currency: paymentTax.entered?.currency ?? merchantSettings?.primaryCurrency,
        amount: paymentTax.entered?.value,
        withholdingAmount: paymentTax.withholding?.value,
      } as any);
      // use setFieldsValue instead of Form's initialValues. because form instance is kept when switching between accounts
      form.setFieldsValue(initialValues);
    } else {
      form.resetFields();
    }
  }, [paymentTax]);

  async function onSubmit({ currency, calculateBox11, ...values }: any) {
    if (recipientId) {
      try {
        const post = {
          ...values,
          currency,
          withholdingCurrency: currency, // should always be same as currency
        };

        if (paymentTax?.id) {
          await updatePaymentTax(paymentTax.id, post);
          notifySuccess("Payment Updated");
        } else {
          await createPaymentTax({ ...post, recipientId, calculateBox11 });
          notifySuccess("Offline Payment Updated");
        }
        onClose();
      } catch (errors) {
        handleFormErrors(errors, form);
      }
    }
  }

  return (
    <Modal
      width={600}
      title={paymentTax?.paymentId ? "Payment Tax Record" : "Offline Payment"}
      visible={visible}
      onCancel={onClose}
      footer={
        <Space direction="row-reverse">
          <Button
            type="primary"
            onClick={form.submit}
            loading={paymentTaxStatus === BaseStatus.LOADING}
            disabled={!accessPaymentWrite}
            tooltipProps={
              accessPaymentWrite
                ? undefined
                : {
                    title: "You do not have access permission. Please contact your administrators to change your role to gain permission.",
                    placement: "topRight",
                  }
            }
          >
            {`${paymentTax?.id ? "Save" : "Add"} ${paymentTax?.paymentId ? "Payment Tax Record" : "Offline Payment"}`}
          </Button>
          <Button onClick={onClose}>Cancel</Button>
          {accessPaymentWrite && paymentTax?.id && !paymentTax.paymentId && (
            <div style={{ flex: "1", textAlign: "left" }}>
              <ButtonDelete
                ghost
                title="Delete Offline Payment?"
                onConfirm={async () => {
                  if (paymentTax.id) {
                    try {
                      await deletePaymentTaxes(paymentTax.recipientId as string, [paymentTax.id]);
                      onClose();
                    } catch (errors) {
                      notifyError("Delete Failed", { errors });
                    }
                  }
                }}
              >
                Delete
              </ButtonDelete>
            </div>
          )}
        </Space>
      }
    >
      {paymentTax && (
        <Form
          layout="horizontal"
          labelAlign="right"
          labelCol={{ flex: "220px" }}
          form={form}
          onFinish={onSubmit}
          validateTrigger="onChange"
          disabled={!accessPaymentWrite}
        >
          {paymentTax.recipientId && (
            <>
              <RecipientProfile recipientId={paymentTax.recipientId} showStatus showAddress showEmail />
              <Divider margin="medium" />
            </>
          )}

          <Form.Item label="Payout Method" name="payoutMethod">
            <Select>
              {Object.values(PaymentTaxesPayoutMethods).map((pm) => (
                <Select.Option key={pm} value={pm}>
                  <PayoutMethodDisplay value={pm} showLabel />
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item {...getLabelTooltip("category")} name="category">
            <Select<PaymentCategory>
              style={{ width: "100%" }}
              popupMatchSelectWidth={false}
              onChange={(value) => {
                const updates: Partial<FormFields> = {};
                if (value) {
                  if (NON_TAXABLE_CATEGORIES.includes(value)) {
                    // force value to false as it does not apply to category
                    updates.taxReportable = false;
                  } else if (!FORCE_US_TAX_CATEGORIES.includes(value)) {
                    updates.forceUsTaxActivity = false;
                  }
                }

                if (Object.keys(updates).length) {
                  form.setFieldsValue(updates);
                }
              }}
            >
              {enabledCategories.map(([categoryOption]) => (
                <Select.Option key={categoryOption}>{CATEGORY_DETAILS[categoryOption]?.name || categoryOption}</Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Control dependencies={["category"]}>
            {({ getFieldValue }) => {
              const category = getFieldValue("category");

              return (
                <>
                  {category && (
                    <Form.Item {...getLabelTooltip("taxReportable")} name="taxReportable" valuePropName="checked">
                      <Checkbox disabled={!accessPaymentWrite || NON_TAXABLE_CATEGORIES.includes(category)}>
                        {NON_TAXABLE_CATEGORIES.includes(category) && <Text size="small">{CATEGORY_DETAILS[category]?.name} are non-taxable</Text>}
                      </Checkbox>
                    </Form.Item>
                  )}

                  {showForceUsTaxActivities && (
                    <Form.Item {...getLabelTooltip("forceUsTaxActivity")} name="forceUsTaxActivity" valuePropName="checked">
                      <Checkbox disabled={!accessPaymentWrite || !FORCE_US_TAX_CATEGORIES.includes(category)}>
                        {!FORCE_US_TAX_CATEGORIES.includes(category) && <Text size="small">Does not apply to {CATEGORY_DETAILS[category]?.name}</Text>}
                      </Checkbox>
                    </Form.Item>
                  )}
                </>
              );
            }}
          </Form.Control>

          <Form.Item label="Currency" name="currency" rules={[{ required: true, message: "Select a currency" }]}>
            <Select popupMatchSelectWidth={false}>
              {Object.keys(CurrencyCode).map((c: CurrencyCode) => (
                <Select.Option key={c} value={c}>
                  <Flag code={c} showLabel />
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Control dependencies={["currency"]}>
            {({ getFieldValue }) => {
              const currency = getFieldValue("currency");

              return (
                <>
                  <Form.Item label="Payment Amount" name="amount" rules={[{ required: true, message: "Payment amount is required" }]}>
                    <InputNumber inputMode="decimal" allowNegative addonAfter={currency} />
                  </Form.Item>

                  <Form.Item label="Tax Withholding" name="withholdingAmount">
                    <InputNumber inputMode="decimal" allowNegative addonAfter={currency} />
                  </Form.Item>
                </>
              );
            }}
          </Form.Control>

          <Form.Item label="Tax Withholding Reason" name="taxWithholdingReason" normalize={(v) => v ?? null}>
            <Select popupMatchSelectWidth={false} allowClear>
              {Object.values(TaxWithholdingReasons).map((reason) => (
                <Select.Option value={reason} key={reason}>
                  {WithholdingReasonLabels[reason] || reason}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="Processed On"
            name="processedAt"
            normalize={(newValue: string | undefined) => {
              return newValue ? (dayjs.utc(newValue).isSame(paymentTax.processedAt, "date") ? paymentTax.processedAt : newValue) : undefined;
            }}
            rules={[{ required: true, message: "Select a date" }]}
          >
            <DatePicker allowClear={false} type="past" />
          </Form.Item>

          {merchantSettings?.tax?.enabledTaxPaidByWithholdingAgents && (
            <Form.Control shouldUpdate>
              {({ getFieldsValue }) => {
                const { taxFormId, taxReportable, withholdingAmount, calculateBox11, currency } = getFieldsValue();
                const selectedW8Form = recipientTaxForms.records.find(
                  (tf) => tf.id === taxFormId && [TaxFormType.W8BEN, TaxFormType.W8BENE].includes(tf.taxFormType as TaxFormType),
                );
                const showTPBWA = !taxFormId || selectedW8Form; // so... don't show if tax form is not W8
                const enableBox11 = new BigNumber(withholdingAmount || "0").eq(0) && !!taxReportable;
                const enableTPBWA = enableBox11 && !calculateBox11;

                return (
                  showTPBWA && (
                    <>
                      {!paymentTax?.id && (
                        <Form.Item
                          label="Calculate Tax Paid by Withholding Agent"
                          tooltip={<>When enabled, it will automatically calculate the "Tax paid by withholding agent".</>}
                          name={enableBox11 ? "calculateBox11" : undefined}
                          valuePropName="checked"
                          initialValue={enableBox11}
                        >
                          <Checkbox disabled={!enableBox11}>
                            {!enableBox11 && (
                              <Text size="small">
                                To enable automatic calculation:
                                <ul>
                                  {!taxReportable && <li>Payment must be Tax Reportable</li>}
                                  {!new BigNumber(withholdingAmount || "0").eq(0) && (
                                    <li>
                                      Tax Withholding must be <CurrencyDisplay value={0} currency={currency} />
                                    </li>
                                  )}
                                </ul>
                              </Text>
                            )}
                          </Checkbox>
                        </Form.Item>
                      )}

                      <Form.Item
                        {...getLabelTooltip("taxPaidByWithholdingAgents")}
                        name={enableTPBWA ? "taxPaidByWithholdingAgents" : undefined}
                        extra={
                          !enableTPBWA && (
                            <>
                              To manually edit:
                              <ul>
                                {!taxReportable && <li>Payment must be Tax Reportable</li>}
                                {!new BigNumber(withholdingAmount || "0").eq(0) && (
                                  <li>
                                    Tax Withholding must be <CurrencyDisplay value={0} currency={currency} />
                                  </li>
                                )}
                                {!paymentTax?.id && !!calculateBox11 && <li>Calculate Tax Paid by Withholding Agent must be disabled</li>}
                              </ul>
                            </>
                          )
                        }
                      >
                        <InputNumber inputMode="decimal" addonAfter={CurrencyCode.USD} disabled={!enableTPBWA} />
                      </Form.Item>
                    </>
                  )
                );
              }}
            </Form.Control>
          )}

          <Form.Item label="External ID" name="externalId">
            <Input autoComplete="off" />
          </Form.Item>

          <Form.Item label="Memo" name="memo">
            <Input autoComplete="off" />
          </Form.Item>

          <Form.Item label="Tags" name="tags">
            <InputTags editable={accessPaymentWrite} />
          </Form.Item>

          {taxFormsNeeded && (
            <Form.Item label="Tax Form" name="taxFormId" normalize={(v) => v ?? null}>
              <Select allowClear style={{ width: "100%" }}>
                {recipientTaxForms.records
                  .filter((tf) => !!tf.signedAt)
                  .map((tf) => (
                    <Select.Option key={tf.id} value={tf.id}>
                      <div>
                        <TaxFormDisplay taxFormId={tf.id} showIcon showStatus />
                        <br />
                        <Text type="secondary" size="small">
                          Signed on <DateDisplay value={tf.signedAt} icon={false} time={false} />
                          {tf.status === TaxStatus.VOIDED && (
                            <>
                              , Voided on <DateDisplay value={tf.signedAt} icon={false} time={false} />
                            </>
                          )}
                        </Text>
                      </div>
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          )}
        </Form>
      )}
    </Modal>
  );
}
