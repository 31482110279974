import { RecipientType, TaxProfileClassification } from "@trolley/common-frontend";
import { Box, DateDisplay, Divider, Flag, Flyout, Form, FormItem, Grid, Space, Text } from "components";
import { RecipientPreview, RecipientProfile } from "features/recipient";
import { TaxProfileActions, TaxProfileDisplay } from "features/taxProfile";
import React, { useState } from "react";
import { useTaxProfile } from "store/hooks/taxProfiles";
import { stringifyAddress } from "utils/helpers";
import getFTinLabel from "utils/helpers/ftins";

interface Props {
  taxProfileId?: string;
  showActions?: boolean;
  onClose(): void;
}

export default function TaxProfilePreview(props: Props) {
  const { taxProfileId, showActions, onClose } = props;
  const [recipientId, setRecipientId] = useState<string | undefined>();
  const { data: taxProfile } = useTaxProfile(taxProfileId);

  function renderIndividualTaxpayerDetails() {
    return (
      taxProfile && (
        <>
          <RenderTaxProfileSection
            title="Taxpayer Details"
            items={[
              {
                label: "First Name",
                value: <Text>{taxProfile.data.firstName ?? "-"}</Text>,
              },
              {
                label: "Last Name",
                value: <Text>{taxProfile.data.lastName ?? "-"}</Text>,
              },
              {
                label: "Date of Birth",
                value: <Text>{taxProfile.data.dob ? <DateDisplay value={taxProfile.data.dob} showUtc icon={false} time={false} /> : "-"}</Text>,
              },
              ...(taxProfile.data.birthCountry && taxProfile.data.birthCity
                ? [
                    {
                      label: "City of Birth",
                      value: <Text>{`${taxProfile.data.birthCity}, ${taxProfile.data.birthCountry}`}</Text>,
                    },
                  ]
                : []),
              {
                label: "Address",
                value: (
                  <Text>
                    {stringifyAddress(
                      {
                        street1: taxProfile.data.primaryStreet1,
                        street2: taxProfile.data.primaryStreet2,
                        city: taxProfile.data.primaryCity,
                        country: taxProfile.primaryCountry,
                        region: taxProfile.data.primaryRegionCode,
                        postalCode: taxProfile.data.primaryPostalCode,
                      },
                      true,
                    )}
                  </Text>
                ),
              },
            ]}
          />
          <Divider transparent />
        </>
      )
    );
  }

  function renderBusinessTaxpayerDetails() {
    return (
      taxProfile && (
        <>
          <RenderTaxProfileSection
            title="Taxpayer Details"
            items={[
              {
                label: "Business Name",
                value: <Text>{taxProfile.data.businessName}</Text>,
              },
              {
                label: "Address",
                value: (
                  <Text>
                    {stringifyAddress(
                      {
                        street1: taxProfile.data.primaryStreet1,
                        street2: taxProfile.data.primaryStreet2,
                        city: taxProfile.data.primaryCity,
                        country: taxProfile.primaryCountry,
                        region: taxProfile.data.primaryRegionCode,
                        postalCode: taxProfile.data.primaryPostalCode,
                      },
                      true,
                    )}
                  </Text>
                ),
              },
            ]}
          />
          <Divider transparent />
        </>
      )
    );
  }

  return (
    <Flyout
      width="small"
      visible={!!taxProfile}
      onClose={onClose}
      title={taxProfile && <TaxProfileDisplay taxProfileId={taxProfile.id} showStatus />}
      subtitle={
        taxProfile && (
          <>
            <Divider margin="small" />
            <RecipientProfile
              recipientId={taxProfile.recipientId}
              showLink={() => {
                setRecipientId(taxProfile.recipientId);
              }}
              size="small"
              showEmail
            />
            <Divider transparent margin="small" />
            <Text type="secondary" size="small" style={{ marginLeft: "32px" }}>
              Signed on <DateDisplay value={taxProfile.signedAt} time={false} />
            </Text>
            {taxProfile && showActions && (
              <>
                <Divider transparent margin="small" />
                <Space direction="row-reverse">
                  <TaxProfileActions taxProfileId={taxProfile.id} showMenu={false} />
                </Space>
              </>
            )}
          </>
        )
      }
    >
      {taxProfile && (
        <Form layout="horizontal" compact>
          <RenderTaxProfileSection
            title="Taxpayer Profile Status"
            items={[
              {
                label: "DAC7 Tax Classification",
                value: (
                  <>
                    <Text>{taxProfile.classification ?? "-"}</Text>
                    {taxProfile.excludeReason && <Text type="secondary">{taxProfile.excludeReason}</Text>}
                  </>
                ),
              },
              {
                label: "Reportable",
                value: <Text>{taxProfile.classification === TaxProfileClassification.IN_SCOPE_REPORTABLE ? "True" : "False"}</Text>,
              },
            ]}
          />
          <Divider transparent />
          <RenderTaxProfileSection
            title="Tax Profile Details"
            items={[
              {
                label: "Recipient Type",
                value: <Text>{taxProfile.recipientType ? (taxProfile.recipientType === RecipientType.BUSINESS ? "Business" : "Individual") : "-"}</Text>,
              },
              {
                label: "Recipient ID",
                value: <Text>{taxProfile.recipientId}</Text>,
              },
              {
                label: "Address",
                value: (
                  <Text>
                    {stringifyAddress(
                      {
                        street1: taxProfile.data.primaryStreet1,
                        street2: taxProfile.data.primaryStreet2,
                        city: taxProfile.data.primaryCity,
                        country: taxProfile.primaryCountry,
                        region: taxProfile.data.primaryRegionCode,
                        postalCode: taxProfile.data.primaryPostalCode,
                      },
                      true,
                    )}
                  </Text>
                ),
              },
            ]}
          />
          <Divider transparent />
          {taxProfile.recipientType === RecipientType.INDIVIDUAL && renderIndividualTaxpayerDetails()}
          {taxProfile.recipientType === RecipientType.BUSINESS && renderBusinessTaxpayerDetails()}
          <RenderTaxProfileSection
            title="Taxpayer Identification Details"
            items={[
              ...(taxProfile.recipientType === RecipientType.BUSINESS
                ? [
                    {
                      label: "Business Registration Number",
                      value: <Text>{taxProfile.data.businessNumber ?? "-"}</Text>,
                    },
                  ]
                : []),
              ...(taxProfile.data.vatNumber && taxProfile.vatCountry
                ? [
                    {
                      label: "VAT Number",
                      value: (
                        <Grid>
                          <Grid.Item xs={8}>
                            <Text>{taxProfile.data.vatNumber}</Text>
                          </Grid.Item>
                          <Grid.Item>
                            <Flag code={taxProfile.vatCountry} />
                          </Grid.Item>
                        </Grid>
                      ),
                    },
                  ]
                : [
                    {
                      label: "VAT Number",
                      value: "-",
                    },
                  ]),
              ...(taxProfile.tins.length > 0
                ? taxProfile.tins.map((tin) => ({
                    label: "TIN Number",
                    value: (
                      <Grid direction="column">
                        <Grid.Item>
                          <Space>
                            <Flag code={tin.tinCountry} showLabel={false} />
                            <Text>{getFTinLabel(tin.tinCountry, tin.tinType)}</Text>
                          </Space>
                        </Grid.Item>
                        <Grid.Item>
                          <Text>{tin.tinNumber}</Text>
                        </Grid.Item>
                      </Grid>
                    ),
                  }))
                : [
                    {
                      label: "TIN Number",
                      value: "-",
                    },
                  ]),
            ]}
          />
          <Divider transparent />
        </Form>
      )}
      <RecipientPreview
        recipientId={recipientId}
        onClose={() => {
          setRecipientId(undefined);
        }}
      />
    </Flyout>
  );
}

function RenderTaxProfileSection({ title, items }: { title: React.ReactNode; items: { label: string; value: React.ReactNode }[] }) {
  return (
    <Box padding="small" header={title}>
      {items.map(({ label, value }) => (
        <React.Fragment key={label}>
          <Text weight="bold">{label}</Text>
          <FormItem>{value}</FormItem>
          <Divider transparent />
        </React.Fragment>
      ))}
    </Box>
  );
}
