import * as types from "@trolley/common-frontend";
import { POST } from "services/request";
import store from "store";
import { OpCode, standardDispatch } from "store/dispatcher";
import { queueFactory } from "utils/factories";
import { groupBy } from "utils/helpers";
import { isLoaded } from "./actionUtils";
import { resetPayments } from "./payments";

function getInvoicePaymentType(id: string): "invoiceIds" | "paymentIds" | "" {
  if (/^I-\w+/.test(id)) {
    return "invoiceIds";
  }
  if (/^P-\w+/.test(id)) {
    return "paymentIds";
  }

  return "";
}

const invoicePaymentQueueLoader = queueFactory(
  (ids) => {
    const { invoiceIds, paymentIds } = groupBy(ids, (id) => getInvoicePaymentType(id) as "paymentIds" | "invoiceIds");
    if (invoiceIds?.length) {
      // load by invoiceIds and paymentIds in different calls otherwise results would be the intersecting data set.
      POST<types.InvoicePaymentResponseList>("/v1/invoices/payment/search", {
        query: {
          invoiceIds,
          pageSize: 1000, // needs to be max because we don't don't how many invoicepayment are linked
        },
      })
        .then(({ body }) => {
          standardDispatch(OpCode.DATA, "invoicePayments", {
            bulk: invoiceIds.reduce((acc, id) => {
              acc[id] = body.invoicePayments.filter((ip) => ip.invoiceId === id);

              return acc;
            }, {}),
          });
        })
        .catch((errors) => {
          standardDispatch(OpCode.ERROR, "invoicePayments", {
            ids: invoiceIds,
            errors,
          });
        });
    }
    if (paymentIds?.length) {
      POST<types.InvoicePaymentResponseList>("/v1/invoices/payment/search", {
        query: {
          paymentIds,
          pageSize: 1000,
        },
      })
        .then(({ body }) => {
          standardDispatch(OpCode.DATA, "invoicePayments", {
            bulk: paymentIds.reduce((acc, id) => {
              acc[id] = body.invoicePayments.filter((ip) => ip.paymentId === id);

              return acc;
            }, {}),
          });
        })
        .catch((errors) => {
          standardDispatch(OpCode.ERROR, "invoicePayments", {
            ids: paymentIds,
            errors,
          });
        });
    }
  },
  (id) => !!getInvoicePaymentType(id),
);

export interface InvoicePayment extends types.InvoicePayment {}
export type InvoicePaymentQuery =
  | {
      invoiceIds?: string[];
      paymentIds?: never;
    }
  | {
      invoiceIds?: never;
      paymentIds?: string[];
    };

export function loadInvoicePayments(id: string): void {
  const { invoicePayments } = store.getState();
  const idType = getInvoicePaymentType(id);

  if (idType && !isLoaded(invoicePayments.fetchStatus[id])) {
    standardDispatch(OpCode.LOADING, "invoicePayments", { id });
    invoicePaymentQueueLoader.add(id);
  }
}

export async function createInvoicePayments(query: {
  batchId?: string | undefined;
  ids: {
    invoiceId?: string;
    invoiceLineId?: string;
    amount?: { currency: types.CurrencyCode; value: string };
  }[];
}) {
  try {
    const { body } = await POST<{
      invoicePayments: {
        batchId: string;
        paymentId: string;
        invoicePayments: InvoicePayment[];
      };
    }>("/v1/invoices/payments/create", { query });

    resetPayments();
    standardDispatch(OpCode.RESET, "invoicePayments");

    return body.invoicePayments.batchId;
  } catch (errors) {
    standardDispatch(OpCode.RESET, "invoicePayments");

    throw errors;
  }
}

export async function updateInvoicePayments(query: { paymentId: string; invoiceLineId: string; amount: { currency: types.CurrencyCode; value: string } }[]) {
  await Promise.allSettled(query.map((item) => POST("/v1/invoices/payment/update", { query: item })));
  standardDispatch(OpCode.RESET, "invoicePayments");
}

export async function deleteInvoicePayments(query: { invoiceLineIds: string[]; paymentId: string }) {
  await POST("/v1/invoices/payment/delete", {
    query,
  });
  standardDispatch(OpCode.RESET, "invoicePayments");
}
