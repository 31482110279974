import dayjs from "dayjs";

const TODAY = dayjs().format("YYYY-MM-DD");

export enum DateRange {
  LAST_7DAYS = "last7Days",
  LAST_30DAYS = "last30Days",
  LAST_90DAYS = "last90Days",
  THIS_MONTH = "thisMonth",
  THIS_QUARTER = "thisQuarter",
  THIS_YEAR = "thisYear",
  LAST_MONTH = "lastMonth",
  LAST_QUARTER = "lastQuarter",
  LAST_YEAR = "lastYear",
  CUSTOM = "custom",
}

const DATE_RANGE_MAP: Record<DateRange, { label: string; startDate: string | undefined; endDate: string | undefined }> = {
  [DateRange.THIS_MONTH]: {
    label: "This Month",
    startDate: dayjs().startOf("month").format("YYYY-MM-DD"),
    endDate: dayjs().endOf("month").format("YYYY-MM-DD"),
  },
  [DateRange.THIS_QUARTER]: {
    label: "This Quarter",
    startDate: dayjs().startOf("quarter").format("YYYY-MM-DD"),
    endDate: dayjs().endOf("quarter").format("YYYY-MM-DD"),
  },
  [DateRange.THIS_YEAR]: {
    label: "This Year",
    startDate: dayjs().startOf("year").format("YYYY-MM-DD"),
    endDate: dayjs().endOf("year").format("YYYY-MM-DD"),
  },
  [DateRange.LAST_MONTH]: {
    label: "Last Month",
    startDate: dayjs().add(-1, "month").startOf("month").format("YYYY-MM-DD"),
    endDate: dayjs().add(-1, "month").endOf("month").format("YYYY-MM-DD"),
  },
  [DateRange.LAST_QUARTER]: {
    label: "Last Quarter",
    startDate: dayjs().add(-1, "quarter").startOf("quarter").format("YYYY-MM-DD"),
    endDate: dayjs().add(-1, "quarter").endOf("quarter").format("YYYY-MM-DD"),
  },
  [DateRange.LAST_YEAR]: {
    label: "Last Year",
    startDate: dayjs().add(-1, "year").startOf("year").format("YYYY-MM-DD"),
    endDate: dayjs().add(-1, "year").endOf("year").format("YYYY-MM-DD"),
  },
  [DateRange.LAST_7DAYS]: {
    label: "Last 7 Days",
    startDate: dayjs().add(-7, "days").format("YYYY-MM-DD"),
    endDate: TODAY,
  },
  [DateRange.LAST_30DAYS]: {
    label: "Last 30 Days",
    startDate: dayjs().add(-30, "days").format("YYYY-MM-DD"),
    endDate: TODAY,
  },
  [DateRange.LAST_90DAYS]: {
    label: "Last 90 Days",
    startDate: dayjs().add(-90, "days").format("YYYY-MM-DD"),
    endDate: TODAY,
  },
  [DateRange.CUSTOM]: {
    label: "Custom Date Range",
    startDate: undefined,
    endDate: undefined,
  },
};

export const DATE_RANGE_GROUPS: DateRange[][] = [
  [DateRange.LAST_7DAYS, DateRange.LAST_30DAYS, DateRange.LAST_90DAYS],
  [DateRange.THIS_MONTH, DateRange.THIS_QUARTER, DateRange.THIS_YEAR],
  [DateRange.LAST_MONTH, DateRange.LAST_QUARTER, DateRange.LAST_YEAR],
  [DateRange.CUSTOM],
];

export default function getDateRange(key: DateRange) {
  return key && DATE_RANGE_MAP[key] && { ...DATE_RANGE_MAP[key], key };
}
