import { Configuration } from "config";

const config: Configuration = {
  DOMAIN: "127.0.0.1:3000",
  WS_SERVER_ADDRESS: "ws://127.0.0.1:3000",
  SERVER_ADDRESS: "http://127.0.0.1:3000",
  WIDGET_ADDRESS: "http://127.0.0.1:7000",
  SALESFORCE: {
    recordType: "0125Y000001wR9bQAE", // for WebtoCase
    apiUrl: "https://trolley--prodstage.sandbox.my.salesforce.com",
    siteUrl: "https://trolley--prodstage.sandbox.my.salesforce-sites.com/",
    id: "00D760000008hkm",
    deployment: "Live_Chat",
    eswUrl: "https://trolley--prodstage.sandbox.my.salesforce.com/embeddedservice/5.0/esw.min.js",
    chatDetails: {
      baseLiveAgentContentURL: "https://c.la5-c1cs-ia4.salesforceliveagent.com/content",
      deploymentId: "5725Y000000ozTt",
      buttonId: "5735Y000000ozXj",
      baseLiveAgentURL: "https://d.la5-c1cs-ia4.salesforceliveagent.com/chat",
      eswLiveAgentDevName: "EmbeddedServiceLiveAgent_Parent04I5Y000000orGoUAI_17fb2ca1583",
      isOfflineSupportEnabled: false,
    },
  },
};

export default config;
