import { Dropdown as AntDropdown, DropDownProps as Props } from "antd";
import React, { ReactNode } from "react";

interface DropdownProps extends Omit<Props, "trigger"> {
  children?: ReactNode;
}

export default function Dropdown(props: DropdownProps) {
  return <AntDropdown {...props} trigger={["click"]} />;
}
