import React, { ReactNode } from "react";
import { useAccess } from "store/hooks/user";
import { Access } from "@trolley/common-frontend";

export interface AccessProps {
  type: Access;
  children: ReactNode;
}

export default function UserAccess(props: AccessProps) {
  const hasAccess = useAccess(props.type);

  return hasAccess ? <>{props.children}</> : null;
}

UserAccess.useAccess = useAccess;
