import { Batch } from "store/actions/batches";
import { Payment } from "store/actions/payments";
import { BaseStatus } from "store/reducers/standardReducer";
import * as types from "@trolley/common-frontend";
import { RecipientAccount } from "./recipientAccount";
import { StoreRecipient } from "./recipients";

export function computeID(options: { [key: string]: any }) {
  return JSON.stringify(
    Object.keys(options)
      .sort()
      .map((key) => [key, options[key]]),
  );
}

/**
 * @param status
 * Will return true only is status is LOADING or LOADED. undefined or ERROR will return false.
 */
export function isLoaded(status: BaseStatus | undefined) {
  return !!status && [BaseStatus.LOADING, BaseStatus.LOADED].includes(status);
}

export function groupRecipients(rawRecipients: types.Recipient[]) {
  const recipientAccounts: Record<string, RecipientAccount> = {};
  const ids: string[] = [];
  const recipients: Record<string, StoreRecipient> = rawRecipients.reduce((acc, r) => {
    ids.push(r.id);
    acc[r.id] = {
      ...r,
      accounts: r.accounts.map((ra) => {
        recipientAccounts[ra.id] = ra;

        return ra.id;
      }),
    };

    return acc;
  }, {});

  return { recipients, recipientAccounts, ids };
}

export function groupPayments(records: types.Payment[] = [], batchID?: string) {
  const payments: Record<string, Payment> = {};
  const batches: Record<string, Batch | types.PublicBatch> = {};
  const { recipients, recipientAccounts } = groupRecipients(records.map((p) => p.recipient));
  const ids = records.map((p) => {
    const batch = p.batch;
    const batchId = (batch && batch.id) || batchID;
    if (batch) {
      batches[batch.id] = batch;
    }

    const payment: Payment = Object.keys(p).reduce(
      (acc, key: string) => {
        if (!["recipient", "batch"].includes(key)) {
          acc[key] = p[key];
        }

        return acc;
      },
      {
        recipientId: p.recipient.id,
        batchId,
      } as Payment,
    );

    payments[payment.id] = payment;

    return payment.id;
  });

  return {
    payments,
    recipients,
    recipientAccounts,
    batches,
    ids,
  };
}
