import * as types from "@trolley/common-frontend";
import { EndOfYear, EoyTaxReportStatus, SerializerMetaBlock, TaxDeliveryType } from "@trolley/common-frontend";
import { batch } from "react-redux";
import * as request from "services/request";
import { POST } from "services/request";
import store from "store";
import { OpCode, standardDispatch } from "store/dispatcher";
import { Mapped } from "store/reducers/standardReducer";
import { queueFactory } from "utils/factories";

import { Query } from "utils/hooks";
import { computeID, isLoaded } from "./actionUtils";

export interface DAC7EoyTax extends EndOfYear.DAC7RecipientEoyTax {}

export interface DAC7EoyTaxQuery extends Query<DAC7EoyTax> {
  search?: string;
  taxYear?: number;
  status?: EoyTaxReportStatus[];
  recipientType?: types.RecipientType[];
  reportable?: boolean;
  totalEarningsAmountGt?: number;
  totaltaxedAmountGt?: number;
  totalFeeAmountGt?: number;
  totalActivityAmountGt?: number;
  downloadedByRecipient?: boolean;
  submitted?: boolean;
  recipientId?: string;
  dac7ProfileStatus?: types.DAC7TaxProfileStatus;
  ids?: string[];
  isAmended?: boolean;
  type?: types.PaymentCategory[];
}

export interface DAC7EoyTaxUpdate {
  recipientEoyTaxIds: {
    id: string;
    hash: string;
  }[];
  status: EoyTaxReportStatus.APPROVED | EoyTaxReportStatus.HOLD | EoyTaxReportStatus.DO_NOT_SEND | EoyTaxReportStatus.VOID;
}

export const DEFAULT_QUERY = {
  page: 1,
  pageSize: 10,
};

const dac7EoyTaxFormQueueLoader = queueFactory(
  (dac7EoyTaxFormIds) => {
    request
      .POST<types.EndOfYear.ListDAC7RecipientEoyTax>("/v1/tax-year/dac7-eoy/search", {
        query: {
          ids: dac7EoyTaxFormIds,
          pageSize: dac7EoyTaxFormIds.length,
        },
      })
      .then(({ body }) => {
        const mappedDac7EoyTaxes = body.dac7RecipientEoyTaxes.reduce((acc, tf) => {
          acc[tf.id] = tf;

          return acc;
        }, {});
        standardDispatch(OpCode.UPDATE, "dac7EoyTaxForm", {
          bulk: dac7EoyTaxFormIds.reduce((acc, id) => {
            acc[id] = mappedDac7EoyTaxes[id] ?? undefined;

            return acc;
          }, {}),
        });
      })
      .catch((errors) => {
        standardDispatch(OpCode.ERROR, "dac7EoyTaxForm", {
          ids: dac7EoyTaxFormIds,
          errors,
        });
      });
  },
  (id) => /^DRET-\w+/.test(id),
);

export function loadDac7EoyTaxForm(id: string, force?: boolean) {
  const { recipient } = store.getState();

  if (force || !isLoaded(recipient.fetchStatus[id])) {
    standardDispatch(OpCode.LOADING, "dac7EoyTaxForm", { id });
    dac7EoyTaxFormQueueLoader.add(id);
  }
}

export function loadDac7EoyTaxFormsByIds(ids: string[], force: boolean = false) {
  const data = store.getState().eoyTaxForm;
  const loadIds = types.uniqueList(ids).filter((id) => force || !isLoaded(data.fetchStatus[id]));

  if (loadIds.length > 0) {
    standardDispatch(OpCode.LOADING, "dac7EoyTaxForm", {
      ids: loadIds,
    });
    POST<{ dac7RecipientEoyTaxes: DAC7EoyTax[]; meta: SerializerMetaBlock }>("/v1/tax-year/dac7-eoy/search", {
      query: {
        ids: loadIds,
        pageSize: loadIds.length,
      },
    })
      .then(({ body }) => {
        const mappedDac7EoyTaxes = body.dac7RecipientEoyTaxes.reduce((acc, tf) => {
          acc[tf.id] = tf;

          return acc;
        }, {});
        standardDispatch(OpCode.UPDATE, "dac7EoyTaxForm", {
          bulk: loadIds.reduce((acc, id) => {
            acc[id] = mappedDac7EoyTaxes[id] ?? undefined;

            return acc;
          }, {}),
        });
      })
      .catch((errors) => {
        standardDispatch(OpCode.LOADING, "dac7EoyTaxForm", {
          loading: false,
          ids: loadIds,
        });
      });
  }
}

export function resetDac7EoyTaxForms() {
  standardDispatch(OpCode.RESET, "dac7EoyTaxForms");
}

export function loadDac7EoyTaxForms(query: DAC7EoyTaxQuery, force?: boolean): string {
  const data = store.getState().eoyTaxForms;
  const id = computeID(query);

  if (force || !isLoaded(data.fetchStatus[id])) {
    standardDispatch(OpCode.LOADING, "dac7EoyTaxForms", { id });
    POST<{ dac7RecipientEoyTaxes: DAC7EoyTax[]; meta: SerializerMetaBlock }>("/v1/tax-year/dac7-eoy/search", { query })
      .then(({ body }) => {
        batch(() => {
          const dac7RecipientEoyTax: Mapped<DAC7EoyTax> = {};
          const ids: string[] = body.dac7RecipientEoyTaxes.map((ret) => {
            dac7RecipientEoyTax[ret.id] = ret;

            return ret.id;
          });
          standardDispatch(OpCode.DATA, "dac7EoyTaxForm", {
            bulk: dac7RecipientEoyTax,
          });

          standardDispatch(OpCode.DATA, "dac7EoyTaxForms", {
            id,
            data: {
              records: ids,
              meta: body.meta,
            },
          });
        });
      })
      .catch((errors) => {
        standardDispatch(OpCode.ERROR, "dac7EoyTaxForms", {
          errors,
          id,
        });
      });
  }

  return id;
}

export async function updateDac7EoyTaxForms(taxYear: number, query: DAC7EoyTaxUpdate) {
  try {
    standardDispatch(OpCode.LOADING, "dac7EoyTaxForms");

    const { body } = await POST<{ ids: string[]; errors: string[] }>("/v1/tax-year/dac7-eoy/update", {
      query: {
        ...query,
        taxYear,
      },
    });
    standardDispatch(OpCode.LOADING, "dac7EoyTaxForms", {
      loading: false,
    });

    if (body.ids.length) {
      loadDac7EoyTaxFormsByIds(body.ids, true);
    }

    if (body.errors.length) {
      throw body.errors.map((message) => ({ code: "api_error", message }));
    }

    return body.ids;
  } catch (errors) {
    standardDispatch(OpCode.ERROR, "dac7EoyTaxForms", { errors });
    throw errors;
  }
}

type MarkSendQuery = {
  ids?: string[];
};

export async function sendDac7EoyTaxForms(taxYear: number, query: MarkSendQuery) {
  try {
    standardDispatch(OpCode.LOADING, "dac7EoyTaxForms");

    const { body } = await POST<{ ids: string[]; errors: string[] }>("/v1/tax-year/dac7-eoy/mark-sent", {
      query: {
        ...query,
        taxYear,
      },
    });
    standardDispatch(OpCode.LOADING, "dac7EoyTaxForms", {
      loading: false,
    });

    if (body.ids.length) {
      loadDac7EoyTaxFormsByIds(body.ids, true);
    }

    if (body.errors.length) {
      throw body.errors.map((message) => ({ code: "api_error", message }));
    }

    return body.ids;
  } catch (errors) {
    standardDispatch(OpCode.ERROR, "dac7EoyTaxForms", {
      errors,
    });
    throw errors;
  }
}

type ReSendQuery = {
  ids?: string[];
  taxDeliveryType?: TaxDeliveryType;
};

// TODO: Endpoint not implemented yet! Verify query, response and endpoint path when it's ready!
export async function resendDac7EoyTaxForms(taxYear: number, query: ReSendQuery) {
  try {
    standardDispatch(OpCode.LOADING, "dac7EoyTaxForms");

    const { body } = await POST<{ ids: string[]; errors: string[] }>("/v1/tax-year/dac7-eoy/resend", {
      query: {
        ...query,
        taxYear,
      },
    });
    standardDispatch(OpCode.LOADING, "dac7EoyTaxForms", {
      loading: false,
    });

    if (body.ids.length) {
      loadDac7EoyTaxFormsByIds(body.ids, true);
    }

    if (body.errors.length > 0 || body.ids.length === 0) {
      throw body.errors.map((message) => ({ code: "api_error", message }));
    }

    return body.ids;
  } catch (errors) {
    standardDispatch(OpCode.ERROR, "dac7EoyTaxForms", {
      errors,
    });
    throw errors;
  }
}

// TODO: Endpoint not implemented yet! Verify query, response and endpoint path when it's ready!
export async function bulkUpdate(action: "approve-all", query: DAC7EoyTaxQuery) {
  const id = computeID(query);
  try {
    standardDispatch(OpCode.LOADING, "eoyTaxForms", { id });
    await POST(`/v1/tax-year/dac7-eoy/${action}`, {
      query,
    });
    standardDispatch(OpCode.RESET, "dac7EoyTaxForms");
  } catch (errors) {
    standardDispatch(OpCode.ERROR, "dac7EoyTaxForms", { id, errors });
    throw errors;
  }
}
