import React, { ReactNode } from "react";
import { StoreRecipient } from "store/actions/recipients";
import { useRecipient } from "store/hooks/recipients";

interface Props {
  id: string | undefined;
  children: (recipient?: StoreRecipient) => ReactNode;
}
export default function RecipientLoader({ id, children }: Props) {
  const recipientId = id && /^R-\w+$/.test(id) ? id : "";
  const { data } = useRecipient(recipientId);

  return <>{children(data)}</>;
}
