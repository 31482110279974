import { Icon } from "components";
import React from "react";
import { useRecipient } from "store/hooks/recipients";
import { stringifyAddress } from "utils/helpers";

type Props = {
  recipientId: string | undefined;
  icon?: boolean;
  detailed?: boolean;
};

export default function RecipientProfileAddress(props: Props) {
  const { recipientId, icon, detailed } = props;
  const { data: recipient } = useRecipient(recipientId);

  return (
    <span>
      {icon && <Icon type="map-marker-alt" theme="solid" left />}
      {recipient ? stringifyAddress(recipient.address, detailed) : "-"}
    </span>
  );
}
