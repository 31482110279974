import { Divider, Icon, Space, Text } from "components";
import React from "react";
import css, { createUseStyle } from "style/classname";

import TrolleyColor from "assets/images/Trolley_color.svg";
import Amplifinity from "./amplifinity1.png";
import Dosandco from "./dosandco.png";
import HasOffers from "./hasoffers-logo-home.png";
import LinkTrust from "./link-trust-logo.png";
import Paladin from "./paladin.png";
import Plunet from "./plunet-logo.png";
import Refersion from "./refersion2.png";
import Tapfiliate from "./tapfiliate.png";

export enum Partners {
  HAS_OFFERS = "hasOffers",
  REFERSION = "refersion",
  PALADIN = "paladin",
  LINK_TRUST = "linkTrust",
  PLUNET = "plunet",
  AMPLIFINITY = "amplifinity",
  DOS_AND_CO = "dosandco",
  TAPFILIATE = "tapfiliate",
}
const partnerImages: Record<Partners, any> = {
  hasOffers: HasOffers,
  refersion: Refersion,
  paladin: Paladin,
  linkTrust: LinkTrust,
  plunet: Plunet,
  amplifinity: Amplifinity,
  dosandco: Dosandco,
  tapfiliate: Tapfiliate,
};

export const partnerLabels: Record<Partners, string> = {
  hasOffers: "HasOffers",
  refersion: "Refersion",
  paladin: "Paladin",
  linkTrust: "LinkTrust",
  plunet: "Plunet",
  amplifinity: "Amplifinity",
  dosandco: "Dos & Co.",
  tapfiliate: "Tapfiliate",
};

interface Props {
  partner?: string;
  title?: string;
}

export default function PartnerIcon(props: Props) {
  const { partner } = props;
  const prLogo = <img className="trolley-logo" alt="Trolley logo" src={TrolleyColor} style={{ height: "32px" }} />;
  const partnerImage = partner && partnerImages[partner];
  const styledWrapper = useStyledWrapper({ partner: partnerImage });

  return (
    <Space justify={partnerImage ? "center" : "flex-start"} className={styledWrapper}>
      {partnerImage ? (
        <>
          <img src={partnerImage} className="partner-logo" alt={partnerLabels[partner]} data-testid="partner" />
          <Icon size="large" type="plus" color="grey" />
          {prLogo}
        </>
      ) : (
        <>
          {prLogo}
          <Divider type="vertical" style={{ height: "2.5em" }} />
        </>
      )}
      <Text type="secondary" inline>
        {props.title ?? "The Payouts Platform for the Internet Economy"}
      </Text>
    </Space>
  );
}

const useStyledWrapper = createUseStyle<Props>(({ theme, ...props }) =>
  css`
    width: 100%;
    ${props.partner
      ? `
      gap: 0px ${theme.sizeMD}px !important;

    `
      : `
      gap: 0px ${theme.size}px !important;
      position: absolute;
      top: 40px;
      left: 0;
      padding: 0 40px;
    `}
    .partner-logo {
      height: 40px;
      border-radius: ${theme.borderRadius}px;
    }
  `(),
);
