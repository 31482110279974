import { Ticket, TicketDocumentRequestStatus, TicketDocumentType, TicketReasonList, uniqueList } from "@trolley/common-frontend";
import { Link, Text } from "components";
import { TicketPreview } from "features/ticket";
import React, { ReactNode, useState } from "react";
import { usePaymentTickets, useRecipientTickets } from "store/hooks/tickets";
import { stringifyQuery } from "utils/helpers";
import { getTicketDocumentInstructions, getTicketInstruction } from "./TicketPreview";

export function hasRemainingTicketTasks(tickets: Ticket[]) {
  return getUniqueActiveTicketReasons(tickets).length > 0 || getRequestedDocuments(tickets).length > 0;
}

function getUniqueActiveTicketReasons(tickets: Ticket[]) {
  return uniqueList(
    tickets
      .flatMap((t) => Object.entries(t.reasons))
      .filter(([reason, resolvedDate]) => !resolvedDate)
      .map(([reason]) => reason),
  );
}

function getRequestedDocuments(tickets: Ticket[]) {
  return uniqueList(
    tickets
      .flatMap((t) => t.documentRequests)
      .filter(({ status }) => status === TicketDocumentRequestStatus.OPEN)
      .map(({ documentType }) => documentType),
  );
}

type AlertProps = {
  showLink?: boolean;
  relatedItemId: string; // recipientId | paymentId;
  children?: (children: ReactNode) => JSX.Element | null;
};

export default function TicketAlert({ showLink, relatedItemId, children }: AlertProps) {
  const recipientId = /^R-\w+/.test(relatedItemId) ? relatedItemId : undefined;
  const paymentId = /^P-\w+/.test(relatedItemId) ? relatedItemId : undefined;
  const recipientTickets = useRecipientTickets(recipientId);
  const paymentTickets = usePaymentTickets(paymentId);
  const [showTicketId, setShowTicketId] = useState<string | undefined>();
  const tickets = [...recipientTickets, ...paymentTickets];

  if (tickets.length === 0) {
    return null;
  }

  if (!hasRemainingTicketTasks(tickets)) {
    return null;
  }

  const uniqueActiveReasons = getUniqueActiveTicketReasons(tickets);
  const requestedDocuments = getRequestedDocuments(tickets);

  const content = (
    <>
      <Text weight="bold">There are issues with the recipient, resulting in held payments:</Text>
      <ul>
        {uniqueActiveReasons.map((reason: TicketReasonList) => (
          <li key={reason}>{getTicketInstruction(reason)}</li>
        ))}
        {requestedDocuments.map((documentType: TicketDocumentType) => (
          <li key={documentType}>{getTicketDocumentInstructions({ status: TicketDocumentRequestStatus.OPEN, documentType })}</li>
        ))}
      </ul>
      {showLink &&
        (tickets.length > 1 ? (
          <Link
            tooltip={false}
            to={{
              pathname: "/tickets",
              search: stringifyQuery({
                search: relatedItemId,
              }),
            }}
          >
            View Tickets
          </Link>
        ) : (
          <>
            <a
              role="button"
              onClick={(e) => {
                e.stopPropagation();
                setShowTicketId(tickets[0].id);
              }}
            >
              View Ticket
            </a>
            <TicketPreview showPayment={!paymentId} ticketId={showTicketId} onClose={() => setShowTicketId(undefined)} />
          </>
        ))}
    </>
  );

  // const child: JSX.Element = showAlert ? (
  //   <Alert type="warning" style={{ marginBottom: "0px" }}>
  //     {content}
  //   </Alert>
  // ) : (
  //   content
  // );

  return <>{children?.(content) || content}</>;
}
