import { Configuration } from "config";

const config: Configuration = {
  DOMAIN: "trolley.com",
  WS_SERVER_ADDRESS: "wss://api.trolley.com",
  SERVER_ADDRESS: "https://api.trolley.com",
  WIDGET_ADDRESS: "https://widget.trolley.com",
  GTAG_ID: "GTM-WPPQ7C",
  SATISMETER_WRITE_KEY: "N3fZqWihJdvvUlAC",
  ADYEN: {
    environment: "live",
    clientKey: "live_UYCEPACSARAH3KWXB7DCODHOT4RTPZOF",
  },
  CHARGEBEE: {
    site: "paymentrails",
    publishableKey: "live_RbiGOGMsGtQ3EtLVqQWelo87CI1wonHK",
  },
  PARDOT_ACCOUNT_ID: "926663", // Pardot generated account code = (pAId - 1e3)
  USERPILOT_APP_TOKEN: "NX-66pn27k2",
  SALESFORCE: {
    apiUrl: "https://trolley.my.salesforce.com",
    siteUrl: "https://trolley.my.salesforce-sites.com",
    id: "00D5Y000001bkRK",
    recordType: "0125Y000001wR9bQAE", // for WebtoCase
    deployment: "Live_Chat",
    eswUrl: "https://trolley.my.salesforce.com/embeddedservice/5.0/esw.min.js",
    chatDetails: {
      baseLiveAgentContentURL: "https://c.la3-c2-ia4.salesforceliveagent.com/content",
      deploymentId: "5725Y000000ozTt",
      buttonId: "5735Y000000ozXj",
      baseLiveAgentURL: "https://d.la3-c2-ia4.salesforceliveagent.com/chat",
      eswLiveAgentDevName: "EmbeddedServiceLiveAgent_Parent04I5Y000000orGoUAI_17fb2ca1583",
      isOfflineSupportEnabled: false,
    },
  },
  DATADOG: {
    applicationId: "8fca1f59-92f1-434e-805d-4f94df044aca",
    clientToken: "pubf0f5a4531beda807b77dc4995e698f5c",
    site: "datadoghq.com",
    service: "merchant-dashboard",
  },
};
export default config;
