import {
  Access,
  formatCountry,
  MessageStatus,
  PayoutMethodType,
  TicketDocumentRequest,
  TicketDocumentRequestStatus,
  TicketDocumentType,
  TicketDocumentUploadStatus,
  TicketReasonList,
  TicketStatus,
} from "@trolley/common-frontend";
import { Alert, Box, Button, CurrencyDisplay, DateDisplay, Divider, Flag, Flyout, Form, FormItem, Grid, Icon, Status, Text } from "components";
import { PaymentPreview } from "features/payment";
import { RecipientProfile } from "features/recipient";
import { UserAccess } from "features/user";
import { useWhiteLabelWarning } from "features/whiteLabel";
import IssueMessageBox from "pages/RecipientsPage/Details/IssueMessageBox";
import React, { ReactNode, useState } from "react";
import { useLocation } from "react-router-dom";
import { Message } from "store/actions/messages";
import { notifyError, notifySuccess } from "store/actions/notifications";
import { RecipientAccount } from "store/actions/recipientAccount";
import { StoreRecipient } from "store/actions/recipients";
import { sendTicketNotification } from "store/actions/tickets";
import { useMerchantSettings } from "store/hooks/merchantSettings";
import { useEmailMessages } from "store/hooks/messages";
import { usePayment } from "store/hooks/payments";
import { useRecipientAccounts } from "store/hooks/recipientAccount";
import { useRecipient } from "store/hooks/recipients";
import { useTicket } from "store/hooks/tickets";
import { stringifyAddress } from "utils/helpers";
import { TicketDocumentUploadPopup } from "features/ticket";
import { TICKET_DOCUMENT_REQUESTS } from "features/ticket/TicketDocumentUploadPopup";

interface Props {
  ticketId: string | undefined;
  onClose(): void;
  showPayment?: boolean;
}

const MAX_FILE_LIMIT = 5;

const TicketTypeLabel: Record<TicketReasonList, string> = {
  // INFO page
  [TicketReasonList.MISSING_DOB]: "The Date of Birth is missing",
  [TicketReasonList.CHECK_PROFILE_NAME]: "The Profile Name needs to be confirmed.",
  [TicketReasonList.CHECK_ID_NUMBER]: "The Government ID needs to be confirmed.",
  [TicketReasonList.CHECK_PASSPORT_NUMBER]: "The Passport Number needs to be confirmed.",
  [TicketReasonList.CHECK_ADDRESS]: "The Address needs to be confirmed",
  [TicketReasonList.CHECK_PHONE_NUMBER]: "The Phone Number needs to be confirmed.",
  [TicketReasonList.CHECK_FULL_NAME]: "The Account Holder Name needs to be confirmed on the active payout method.",
  [TicketReasonList.CHECK_ACCOUNT_NUMBER]: "The Account Number needs to be confirmed on the active payout method.",

  // COMPLIANCE PAGE
  [TicketReasonList.CHECK_LEGAL_NAME]: "The Full Legal Name needs to be provided.",
  [TicketReasonList.CHECK_OCCUPATION]: "The Occupation needs to be provided.",
  [TicketReasonList.CHECK_COUNTRY_OF_BIRTH]: "The Place of Birth needs to be provided.",
  [TicketReasonList.CHECK_CITY_REGION_OF_BIRTH]: "The Place of Birth needs to be provided.", // DEPRECATED
  [TicketReasonList.CHECK_CITIZENSHIPS]: "The Citizenships need to be provided.",
};

export function getTicketInstruction(reason: string | undefined): ReactNode {
  const reasonLabel: string | undefined = reason && TicketTypeLabel[reason];

  return reasonLabel || reason || null;
}

export function getTicketDocumentInstructions({ status, documentType }: Pick<TicketDocumentRequest, "status" | "documentType">) {
  return status === TicketDocumentRequestStatus.OPEN
    ? `${TICKET_DOCUMENT_REQUESTS[documentType]} needs to be provided`
    : `${TICKET_DOCUMENT_REQUESTS[documentType]} has been uploaded`;
}

function renderRelatedField(recipient: StoreRecipient, accounts: RecipientAccount[], reason: string) {
  const primaryAccount = accounts.find((a) => a.primary && a.type === PayoutMethodType.BANKTRANSFER);

  return (
    {
      [TicketReasonList.MISSING_DOB]: (
        <>
          Date of Birth:{" "}
          <b>
            <DateDisplay value={recipient?.dob} time={false} icon={false} />
          </b>
        </>
      ),
      [TicketReasonList.CHECK_FULL_NAME]: (
        <>
          Account Holder Name: <b>{primaryAccount?.accountHolderName || " - "}</b>
        </>
      ),
      [TicketReasonList.CHECK_ACCOUNT_NUMBER]: (
        <>
          Account Number: <b>{primaryAccount?.accountNum || " - "}</b>
        </>
      ),
      [TicketReasonList.CHECK_ADDRESS]: (
        <>
          Address: <b>{stringifyAddress(recipient?.address)}</b>
        </>
      ),
      [TicketReasonList.CHECK_CITIZENSHIPS]: (
        <>
          Citizenships: <b>{recipient.citizenships.map((c) => formatCountry(c)).join(", ")}</b>
        </>
      ),
      [TicketReasonList.CHECK_CITY_REGION_OF_BIRTH]: (
        <>
          Place of Birth: <b>{stringifyAddress(recipient?.birthplace)}</b>
        </>
      ),
      [TicketReasonList.CHECK_COUNTRY_OF_BIRTH]: (
        <>
          Place of Birth: <b>{stringifyAddress(recipient?.birthplace)}</b>
        </>
      ),
      [TicketReasonList.CHECK_ID_NUMBER]: (
        <>
          Government IDs:{" "}
          {recipient.governmentIds.map((id) => (
            <>
              <b>
                <Flag showTooltip code={id.country} showLabel={false} /> {id.type}: {id.value}
              </b>
              <br />
            </>
          ))}
        </>
      ),
      [TicketReasonList.CHECK_LEGAL_NAME]: (
        <>
          Full Legal Name: <b>{recipient.legalName}</b>
        </>
      ),
      [TicketReasonList.CHECK_OCCUPATION]: (
        <>
          Occupation: <b>{recipient.occupation}</b>
        </>
      ),
      [TicketReasonList.CHECK_PASSPORT_NUMBER]: (
        <>
          Passport Number(s):{" "}
          {recipient.governmentIds
            .filter((id) => id.type === "passport")
            .map((id) => (
              <>
                <b>
                  <Flag showTooltip code={id.country} showLabel={false} /> {id.value}
                </b>
              </>
            ))}
        </>
      ),
      [TicketReasonList.CHECK_PHONE_NUMBER]: (
        <>
          Phone Number: <b>{recipient?.address.phone}</b>
        </>
      ),
      [TicketReasonList.CHECK_PROFILE_NAME]: (
        <>
          Profile Name:{" "}
          <b>
            {recipient.firstName} {recipient.lastName}
          </b>
        </>
      ),
    }[reason] || reason
  );
}

export default function TicketPreview(props: Props) {
  const { ticketId, showPayment = true } = props;
  const { data: merchantSettings } = useMerchantSettings();
  const { pathname } = useLocation();
  const { data: ticket } = useTicket(ticketId);
  const { data: payment } = usePayment(ticket?.paymentId || "");
  const { data: recipient } = useRecipient(ticket?.parentRecipientId || ticket?.recipientId);
  const accounts = useRecipientAccounts(ticket?.parentRecipientId || ticket?.recipientId);
  const [previewPaymentId, setPreviewPaymentId] = useState<string | undefined>();
  const [selectedDocumentUploadType, setSelectedDocumentUploadType] = useState<TicketDocumentType | undefined>();

  const { data: emails } = useEmailMessages({ recipientId: recipient?.id, relatedItemId: ticket?.id });
  const showRecipient = ticket && !pathname.includes(`/recipients/${ticket?.parentRecipientId || ticket?.recipientId}`); // hide recipient info if on recipient profile
  const ticketEntries = ticket ? Object.entries(ticket.reasons) : [];

  return (
    <Flyout visible={!!ticket && !!recipient} width="large" onClose={props.onClose} title="Ticket Summary">
      {ticket && recipient && (
        <Grid padding="small" style={{ height: "100%" }}>
          <Grid.Item xs={24} md={12}>
            {showRecipient && (
              <RecipientProfile
                wrap
                recipientId={ticket.recipientId || undefined}
                showEmail
                showStatus
                showLink
                showAddress
                style={{ marginRight: "24px" }}
                showTags="editable"
                showRisk
              />
            )}
            {[TicketStatus.OPEN, TicketStatus.PROVIDED].includes(ticket.status) && ticket.recipientId && ticket.merchantId === merchantSettings?.merchantId && (
              <UserAccess type={Access.TICKET_WRITE}>
                <NotifyTicket recipientId={ticket.recipientId} ticketId={ticket.id} emails={emails} />
                <Divider transparent margin="small" />
              </UserAccess>
            )}

            {ticketEntries.length > 0 && (
              <Box header="Requested Information" padding="xsmall">
                {ticketEntries.map(([reason, resolvedDate]) => {
                  return (
                    <Alert key={reason} showIcon type={resolvedDate ? "success" : "info"} header={TicketTypeLabel[reason]}>
                      {resolvedDate ? (
                        <>
                          <Text size="small" padded={false}>
                            {renderRelatedField(recipient, accounts, reason)}
                          </Text>
                          <Text size="small" type="secondary">
                            Submitted <DateDisplay value={resolvedDate} />
                          </Text>
                        </>
                      ) : (
                        <Text size="small" type="secondary">
                          Requested
                        </Text>
                      )}
                    </Alert>
                  );
                })}
              </Box>
            )}

            {ticket.documentRequests.length > 0 && (
              <Box header="Requested Documents" padding="xsmall">
                {ticket.documentRequests.map((documentRequest) => {
                  return (
                    <Alert
                      key={documentRequest.documentType}
                      showIcon
                      type={documentRequest.status === TicketDocumentRequestStatus.OPEN ? "info" : "success"}
                      header={getTicketDocumentInstructions(documentRequest)}
                      action={
                        ticket?.status !== TicketStatus.CLOSED &&
                        documentRequest.fileUploads.length < MAX_FILE_LIMIT && (
                          <Button onClick={() => setSelectedDocumentUploadType(documentRequest.documentType)}>Upload</Button>
                        )
                      }
                    >
                      {documentRequest.status === TicketDocumentRequestStatus.OPEN && (
                        <Text size="small" type="secondary">
                          Requested
                        </Text>
                      )}

                      {documentRequest.fileUploads.map((fileUpload) => (
                        <>
                          <Text size="small">{fileUpload.fileName}</Text>
                          <Text size="small" type="secondary">
                            {fileUpload.status === TicketDocumentUploadStatus.APPROVED ? "Approved" : "Uploaded"} <DateDisplay value={fileUpload.createdAt} />
                          </Text>
                        </>
                      ))}
                    </Alert>
                  );
                })}
              </Box>
            )}

            <Box header="Ticket Information" padding="small">
              {ticket.status === TicketStatus.PROVIDED && <Alert type="info">This ticket is currently in review</Alert>}
              <Form compact form={undefined}>
                <FormItem label="Status">
                  <Status type={ticket.status} />
                </FormItem>
                <FormItem label="Date Issued">
                  <DateDisplay value={ticket.createdAt} />
                </FormItem>
              </Form>
            </Box>

            {showPayment && payment && (
              <Box header="Payment Information" padding="small">
                <Form compact>
                  <FormItem label="Payment ID">
                    <a role="button" onClick={() => setPreviewPaymentId(payment.id)}>
                      {payment.id}
                    </a>
                  </FormItem>
                  <FormItem label="Amount">
                    <CurrencyDisplay value={payment.amount} currency={payment.currency} />
                  </FormItem>
                  <FormItem label="Initiated At">
                    <DateDisplay value={payment.initiatedAt} />
                  </FormItem>
                </Form>
              </Box>
            )}
          </Grid.Item>
          <Grid.Item xs={24} md={12}>
            <IssueMessageBox ticket={ticket} />
          </Grid.Item>
          <PaymentPreview showTicket={false} paymentId={previewPaymentId} onClose={() => setPreviewPaymentId(undefined)} />
        </Grid>
      )}
      {ticket && (
        <TicketDocumentUploadPopup ticket={ticket} documentType={selectedDocumentUploadType} onClose={() => setSelectedDocumentUploadType(undefined)} />
      )}
    </Flyout>
  );
}

interface NotifyProps {
  recipientId: string;
  ticketId: string;
  emails: Message[];
}

function NotifyTicket({ recipientId, ticketId, emails }: NotifyProps) {
  const { warning } = useWhiteLabelWarning();
  const hasSentEmails = emails.some((e) => e.status === MessageStatus.SENT);

  function onNotify(e: any) {
    e?.stopPropagation?.();
    if (ticketId && recipientId) {
      try {
        sendTicketNotification(ticketId, recipientId);
        notifySuccess("Recipient Notified");
      } catch (errors) {
        notifyError("Failed to notify recipient", { errors });
      }
    }
  }

  return hasSentEmails ? (
    <Text align="center" type="secondary" padded={false}>
      The recipient has been notified.
      <Button
        type="link"
        disabled={!!warning}
        tooltipProps={{ title: warning }}
        onClick={onNotify}
        icon={warning ? <Icon.Status type="warning" /> : <Icon type="paper-plane" />}
      >
        Notify again.
      </Button>
    </Text>
  ) : (
    <Button
      size="large"
      onClick={onNotify}
      block
      disabled={!!warning}
      tooltipProps={{ title: warning || "Send a white-label email to the recipient requesting that they update the relevant information." }}
      icon={warning ? <Icon.Status type="warning" /> : <Icon type="paper-plane" />}
    >
      Request Information
    </Button>
  );
}
