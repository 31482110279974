import store from "store";
import { OpCode, standardDispatch } from "store/dispatcher";
import * as request from "services/request";
import * as types from "@trolley/common-frontend";
import { isLoaded } from "./actionUtils";

export type CountryCurrency = types.Country.CountryCurrency;

export function loadCurrencies() {
  const data = store.getState().currencies;

  if (!isLoaded(data.fetchStatus.data)) {
    standardDispatch(OpCode.LOADING, "currencies");

    request
      .GET<types.Country.ListResult>("/v1/geography/countries")
      .then((res) => {
        standardDispatch(OpCode.DATA, "currencies", {
          data: Object.values(res.body.countries).reduce((acc: any, country: types.Country.Country) => {
            acc[country.code] = country.currencies;

            return acc;
          }, {}),
        });
      })
      .catch((errors) => {
        standardDispatch(OpCode.ERROR, "currencies", {
          errors,
        });
      });
  }
}
