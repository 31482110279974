import { isSupportedOnboardingCountry } from "@trolley/common-frontend";
import { Box, Button, Divider, Form, Grid, Heading, Icon, Input, Space, Status } from "components";
import InputDate, { validatePastDate } from "components/Form/InputDate";
import dayjs from "dayjs";
import React from "react";
import { Onboarding } from "store/actions/onboarding";
import { OnboardingPerson } from "store/actions/onboardingPersons";
import { useOnboarding } from "store/hooks/onboarding";
import { isEmpty } from "utils/helpers";
import { FormNames, onFinishFailedOnboarding, onFinishOnboarding } from ".";
import OnboardingSection from "./OnboardingSection";

export function getSigningOfficersCompletion(onboarding: Onboarding | undefined): null | "partial" | "completed" {
  const directors = onboarding?.persons.filter((p) => p.isDirector) || [];

  if (!directors.length) {
    return null;
  }

  if (directors.every((p) => isDirectorComplete(p))) {
    return "completed";
  }

  return "partial";
}

export function isDirectorComplete(person: Partial<OnboardingPerson> | undefined): boolean {
  if (!person) {
    return false;
  }

  const requiredFields = ["firstName", "lastName", "dateOfBirth"];

  return requiredFields.every((field) => !isEmpty(person[field]));
}

export const FIELD_DIRECTORS = "directors";

export function getDirectorLabel(onboarding?: Onboarding): string {
  if (onboarding) {
    const businessCountry = onboarding.businessCountry;
    if (isSupportedOnboardingCountry(businessCountry, "NA")) {
      return "Signing Officer";
    } else if (isSupportedOnboardingCountry(businessCountry, "OTHER") && onboarding?.businessType === "llp") {
      return "Partnership";
    } else if (isSupportedOnboardingCountry(businessCountry, "OTHER") && onboarding?.businessType === "sole_proprietor") {
      return "Owner";
    }
  }

  return "Director";
}
export default function SigningOfficer() {
  const [form] = Form.useForm();
  const { data: onboarding, status } = useOnboarding();
  const signingOfficers = (onboarding?.persons ?? []).filter((person) => person.isDirector);
  const signingLabel = getDirectorLabel(onboarding);

  const directorTitle = signingLabel === "Partnership" ? "Partner" : signingLabel;

  return (
    <OnboardingSection
      title={`${signingLabel}s`}
      id={FormNames.SIGNING_OFFICER}
      description={
        <>
          Please enter the names of all {signingLabel}(s), as they appear in public records.
          <Icon.Hint
            right
            tooltip={`A ${signingLabel} is a person legally authorized to act on behalf of the business (i.e. Board Director, Officer, CEO, CFO, COO).`}
          />
        </>
      }
    >
      <Form
        form={form}
        name={FormNames.SIGNING_OFFICER}
        onFinish={onFinishOnboarding(onboarding, form)}
        onFinishFailed={onFinishFailedOnboarding(onboarding, form)}
      >
        <Form.List
          name={FIELD_DIRECTORS}
          initialValue={signingOfficers}
          rules={[
            {
              async validator(rule, directors: OnboardingPerson[]) {
                if (!directors.length) {
                  throw `Please add a ${directorTitle}`;
                }
              },
            },
          ]}
        >
          {(fields, operation, meta) => (
            // div id is needed for the form scroll to field to work. otherwise, Form.List doesn't generate an element with that ID
            <div id={[FormNames.SIGNING_OFFICER, FIELD_DIRECTORS].join("_")}>
              {fields.map((field, index) => (
                <Form.Control shouldUpdate key={field.key}>
                  {(form) => {
                    const director: Partial<OnboardingPerson> | undefined = form.getFieldValue([FIELD_DIRECTORS, field.name]);

                    return !director?.id ? null : (
                      <Box padding="small" flat>
                        <Heading
                          tag="h3"
                          extraActions={
                            <Space>
                              {!isDirectorComplete(director) && <Status type="incomplete" />}
                              <Button
                                size="small"
                                tooltipProps={{ title: "Remove" }}
                                danger
                                icon={<Icon type="trash" theme="solid" />}
                                onClick={() => {
                                  operation.remove(field.name);
                                }}
                              />
                            </Space>
                          }
                        >
                          {director?.firstName || director?.lastName
                            ? `${directorTitle}: ${[director.firstName, director.lastName].join(" ")}`
                            : `${directorTitle} ${index + 1}`}
                        </Heading>
                        <Form.Item noStyle name={[field.name, "id"]} hidden>
                          <Input />
                        </Form.Item>

                        <Grid padding={["medium", "none"]}>
                          <Grid.Item xs={24} lg={12}>
                            <Form.Item
                              name={[field.name, "firstName"]}
                              label="First and Middle Names "
                              rules={[{ required: true, message: "First & Middle Names are required" }]}
                            >
                              <Input name="firstName" />
                            </Form.Item>
                          </Grid.Item>
                          <Grid.Item xs={24} lg={12}>
                            <Form.Item name={[field.name, "lastName"]} label="Last Name" rules={[{ required: true, message: "Last Name is required" }]}>
                              <Input name="lastName" />
                            </Form.Item>
                          </Grid.Item>
                        </Grid>
                        <Form.Item
                          name={[field.name, "dateOfBirth"]}
                          label="Date Of Birth"
                          rules={[{ required: true, message: "Date of Birth is required" }, validatePastDate]}
                        >
                          <InputDate />
                        </Form.Item>
                      </Box>
                    );
                  }}
                </Form.Control>
              ))}

              <Button
                onClick={() => {
                  operation.add({ id: `add-${dayjs().unix()}`, firstName: "", lastName: "" });
                }}
                icon={<Icon type="plus" />}
              >
                Add {signingOfficers.length > 0 ? "another" : "a"} {directorTitle}
              </Button>
              <Divider transparent margin="small" />
              <Form.ErrorList errors={meta.errors} />
            </div>
          )}
        </Form.List>
        <Form.SubmitButtons status={status} allowReset saveText={onboarding?.completedAt ? "Save" : "Save & Continue"} />
      </Form>
    </OnboardingSection>
  );
}
