import * as patterns from "@trolley/common-frontend/lib/validators";
import { Form, Icon, Input, Text, Tooltip } from "components";
import React, { useState } from "react";
import { useIntl } from "utils/context";
import { useWindowSize } from "utils/hooks";

export interface Props {
  label: string;
  size?: "large" | "small";
  applyTranslations?: boolean;
}

export default function PasswordField(props: Props) {
  const { label, size } = props;
  const { formatMessage } = useIntl();
  const [focus, setFocus] = useState(false);
  const { isMobile } = useWindowSize();
  const password = Form.useWatch(["password"]);
  const validPassword = {
    length: !!password && patterns.hasMinPasswordLength.test(password),
    lowercase: !!password && patterns.hasLowercase.test(password),
    uppercase: !!password && patterns.hasUppercase.test(password),
    number: !!password && patterns.hasDigit.test(password),
    special: !!password && patterns.hasSpecialChar.test(password),
  };
  const hasError = !validPassword.length || !validPassword.lowercase || !validPassword.uppercase || !validPassword.number || !validPassword.special;
  function getLabel(translatedLabel: string, defaultLabel: string) {
    return !!props.applyTranslations ? translatedLabel : defaultLabel;
  }
  const tooltipTitle = (
    <>
      <Text padded weight="bold">
        {getLabel(formatMessage({ id: "auth.password.field.tooltip.passwordMustHave" }), "Password must have at least:")}
      </Text>
      {!validPassword.lowercase && (
        <Text>
          <Icon.Status type="error" size="small" left /> {getLabel(formatMessage({ id: "auth.password.field.tooltip.aLowerCase" }), "a lower case letter")}
        </Text>
      )}
      {!validPassword.uppercase && (
        <Text>
          <Icon.Status type="error" size="small" left /> {getLabel(formatMessage({ id: "auth.password.field.tooltip.aCapitalLetter" }), "a capital letter")}
        </Text>
      )}
      {!validPassword.number && (
        <Text>
          <Icon.Status type="error" size="small" left /> {getLabel(formatMessage({ id: "auth.password.field.tooltip.aNumber" }), "a number")}
        </Text>
      )}
      {!validPassword.special && (
        <Text>
          <Icon.Status type="error" size="small" left />{" "}
          {getLabel(formatMessage({ id: "auth.password.field.tooltip.aSpecialCharacter" }), "a special character")}
        </Text>
      )}
      {!validPassword.length && (
        <Text>
          <Icon.Status type="error" size="small" left /> {getLabel(formatMessage({ id: "auth.password.field.tooltip.eightCharacters" }), "8 characters")}
        </Text>
      )}
    </>
  );

  return (
    <Form.Item
      validateTrigger="onChange"
      label={
        hasError ? (
          <Tooltip visible={focus} placement={isMobile ? "topLeft" : "leftTop"} title={tooltipTitle}>
            {label}
          </Tooltip>
        ) : (
          label
        )
      }
      name="password"
      rules={[
        { required: true, message: getLabel(formatMessage({ id: "auth.password.field.rules.required" }), "Password is required. ") },
        {
          pattern: patterns.hasLowercase,
          message: getLabel(formatMessage({ id: "auth.password.field.rules.aLowerCase" }), "A lower case letter is required. "),
        },
        {
          pattern: patterns.hasUppercase,
          message: getLabel(formatMessage({ id: "auth.password.field.rules.aCapitalLetter" }), "A capital letter is required. "),
        },
        { pattern: patterns.hasDigit, message: getLabel(formatMessage({ id: "auth.password.field.rules.aNumber" }), "A number is required. ") },
        {
          pattern: patterns.hasSpecialChar,
          message: getLabel(formatMessage({ id: "auth.password.field.rules.aSpecialCharacter" }), "A special character is required. "),
        },
        {
          pattern: patterns.hasMinPasswordLength,
          message: getLabel(formatMessage({ id: "auth.password.field.rules.eightCharacters" }), "At least 8 characters are required. "),
        },
      ]}
    >
      <Input
        autoComplete="new-password"
        size={size}
        type="password"
        data-testid="password"
        onFocus={() => {
          setFocus(true);
        }}
        onBlur={() => {
          setFocus(false);
        }}
        suffix={
          password && Object.values(validPassword).some((v) => !v) ? (
            <Icon.Status type="error" />
          ) : (
            <span /> // this is need otherwise the input will lose focus
          )
        }
      />
    </Form.Item>
  );
}
