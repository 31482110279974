import { useEffect, useState } from "react";
import { computeID } from "store/actions/actionUtils";
import { loadAllTickets, loadTicket, loadTickets, TicketsQuery } from "store/actions/tickets";
import { emptyList } from "store/reducers/standardReducer";
import { Ticket } from "@trolley/common-frontend";
import { useShallowSelector } from ".";

export function useTicket(ticketId?: string | undefined | null) {
  const id = ticketId && /^TK-\w+/.test(ticketId) ? ticketId : "";

  useEffect(() => {
    if (id) {
      loadTicket(id);
    }
  }, [id]);

  return useShallowSelector((state) => {
    if (id && state.ticket.fetchStatus[id] === undefined) {
      loadTicket(id);
    }

    return {
      data: state.ticket.entities[id],
      status: state.ticket.fetchStatus[id],
      error: state.ticket.errors[id],
    };
  });
}

export function useTicketList(query: TicketsQuery, optionalLoad = true) {
  const [fetchId, setFetchId] = useState("");

  useEffect(() => {
    if (optionalLoad) {
      setFetchId(loadTickets(query));
    } else {
      setFetchId("");
    }
  }, [computeID(query), optionalLoad]);

  return useShallowSelector((state) => {
    if (fetchId && optionalLoad && state.tickets.fetchStatus[fetchId] === undefined) {
      loadTickets(query);
    }

    const data = state.tickets.entities[fetchId];

    return {
      data: data
        ? {
            records: data.records.map((id) => state.ticket.entities[id]).filter((v) => v) as Ticket[],
            meta: data.meta,
          }
        : emptyList,
      status: state.tickets.fetchStatus[fetchId],
      error: state.tickets.errors[fetchId],
    };
  });
}

export function useAllTickets(query: TicketsQuery | undefined) {
  const [fetchId, setFetchId] = useState("");

  useEffect(() => {
    if (query) {
      setFetchId(loadAllTickets(query));
    } else {
      setFetchId("");
    }
  }, [computeID(query || {})]);

  return useShallowSelector((state) => {
    if (fetchId && query && state.tickets.fetchStatus[fetchId] === undefined) {
      loadAllTickets(query);
    }

    const { [fetchId]: data } = state.tickets.entities;

    return {
      data: data
        ? {
            records: data.records.map((id) => state.ticket.entities[id]).filter((v) => v && (!query?.status || query.status.includes(v.status))) as Ticket[],
            meta: data.meta,
          }
        : emptyList,
      status: state.tickets.fetchStatus[fetchId],
      error: state.tickets.errors[fetchId],
    };
  });
}

export function useRecipientTickets(recipientId: string | undefined) {
  return useShallowSelector(
    (state) => (recipientId && state.recipientTickets.entities[recipientId]?.map((tId) => state.ticket.entities[tId] as Ticket).filter((v) => v)) || [],
  );
}

export function usePaymentTickets(paymentId: string | undefined) {
  return useShallowSelector(
    (state) => (paymentId && state.paymentTickets.entities[paymentId]?.map((tId) => state.ticket.entities[tId] as Ticket).filter((v) => v)) || [],
  );
}
