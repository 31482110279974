import React from "react";
import { Empty as AntEmpty, EmptyProps } from "antd";

interface Props extends EmptyProps {}

export default function Empty(props: Props) {
  return <AntEmpty {...props} />;
}

Empty.PRESENTED_IMAGE_DEFAULT = AntEmpty.PRESENTED_IMAGE_DEFAULT;
Empty.PRESENTED_IMAGE_SIMPLE = AntEmpty.PRESENTED_IMAGE_SIMPLE;
