import { TaxFormType } from "@trolley/common-frontend";
import { Tooltip, Icon } from "components";
import React, { CSSProperties } from "react";
import { getTaxFormLabel } from "store/actions/recipientTaxForms";
import { useMerchantSettings } from "store/hooks/merchantSettings";
import { useRecipient } from "store/hooks/recipients";

type Props = {
  recipientId: string | undefined | null;
  labelType?: "text" | "tooltip";
  size?: "large";
  style?: CSSProperties;
};

export default function RecipientTaxFormType(props: Props) {
  const { recipientId, labelType = "tooltip", size, style } = props;
  const { data: recipient } = useRecipient(recipientId);
  const { features, data: merchantSettings } = useMerchantSettings();

  if (!recipient || !features.tax || !merchantSettings?.tax?.enabled) {
    return null;
  }

  const type = recipient?.taxForm as TaxFormType | undefined;
  const status = recipient?.taxFormStatus || "missing";

  const title = (
    <>
      {getTaxFormLabel(type) || "Tax Form"} - <span style={{ textTransform: "capitalize" }}>{status}</span>
    </>
  );

  let icon = (
    <Icon
      size={size}
      left={labelType === "text"}
      type="file"
      color={
        { block: "red" as "red", warn: "yellow" as "yellow", allow: "grey" as "grey" }[
          (merchantSettings.tax.paymentBehaviour as "block" | "warn" | "allow") || "allow"
        ]
      }
      style={style}
    />
  );

  if (status === "reviewed") {
    icon = <Icon size={size} left={labelType === "text"} type="file-alt" style={style} />;
  } else if (status === "submitted") {
    icon = <Icon size={size} left={labelType === "text"} type="file-exclamation" color="yellow" style={style} />;
  } else if (status === "voided") {
    icon = <Icon size={size} left={labelType === "text"} type="file-minus" color="grey" style={style} />;
  } else if (status === "incomplete") {
    icon = <Icon size={size} left={labelType === "text"} type="file-edit" color="grey" style={style} />;
  }

  return labelType === "tooltip" ? (
    <Tooltip
      title={
        <>
          {title}
          {getExtraWarnings(status, merchantSettings.tax.paymentBehaviour)}
        </>
      }
    >
      {icon}
    </Tooltip>
  ) : (
    <>
      {icon}
      {title}
    </>
  );
}

function getExtraWarnings(status: string, paymentBehavior: undefined | string) {
  if (["submitted", "incomplete", "missing"].includes(status)) {
    const warnings: string[] = [];

    if (status === "submitted") {
      warnings.push("Submitted tax form needs to be reviewed.");
    } else if (status === "incomplete") {
      warnings.push("Recipient has not completed the tax form.");
    }

    if (paymentBehavior === "block") {
      warnings.push("Recipient not payable until a tax form is provided and approved.");
    }

    return warnings.length ? <div>{warnings.join(" ")}</div> : null;
  }

  return null;
}
