import { Radio } from "antd";
import { Container } from "components";
import React, { CSSProperties } from "react";
import { useHistory, useLocation } from "react-router-dom";
import css, { createUseStyle } from "style/classname";

type Props = {
  tabs: {
    title: string | React.ReactNode;
    key: string;
    url: string;
  }[];
  style?: CSSProperties;
};

export default function Subnav(props: Props) {
  const { tabs, style } = props;
  const { push } = useHistory();
  const { pathname } = useLocation();
  const styledNav = useStyledNav();

  const activeTab = [...tabs].sort((a, b) => b.url.length - a.url.length).find((t) => pathname.includes(t.url));

  return (
    <Container
      style={{
        paddingTop: "16px",
        paddingBottom: "16px",
        overflowX: "auto",
        ...style,
      }}
    >
      <Radio.Group
        className={styledNav}
        size="large"
        value={activeTab?.key}
        onChange={(e) => {
          const value = e.target.value;
          push({ pathname: tabs.find((t) => t.key === value)?.url, state: { scrollToTop: false } });
        }}
      >
        {tabs.map((t) => (
          <Radio.Button value={t.key} key={t.key}>
            {t.title}
          </Radio.Button>
        ))}
      </Radio.Group>
    </Container>
  );
}

const useStyledNav = createUseStyle(({ theme }) =>
  css`
    box-shadow: 0 ${theme.controlOutlineWidth}px 0 ${theme.controlTmpOutline};
    & > .${theme.prefixCls}-radio-button-wrapper {
      min-width: 100px;
      text-align: center;
      background-color: transparent;
    }
    & > .${theme.prefixCls}-radio-button-wrapper-checked {
      background-color: ${theme.colorInfoBgHover};
      color: ${theme.colorPrimary};
    }
  `(),
);
