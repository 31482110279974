import { Timeline as TimelineAnt, TimelineProps as AntTimelineProps } from "antd";
import React, { FunctionComponent } from "react";

interface TimelineProps extends AntTimelineProps {}

const Timeline: FunctionComponent<TimelineProps> & {
  Item: typeof TimelineAnt.Item;
} = (props) => {
  return <TimelineAnt {...props} />;
};

Timeline.Item = TimelineAnt.Item;

export default Timeline;
