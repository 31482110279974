/* istanbul ignore file */
import { POST } from "services/request";
import store from "store";
import { standardDispatch, OpCode } from "store/dispatcher";
import * as types from "@trolley/common-frontend";
import { isLoaded } from "./actionUtils";

export function loadWithholdingTable() {
  const { withholdingTable } = store.getState();

  if (!isLoaded(withholdingTable.fetchStatus.data)) {
    standardDispatch(OpCode.LOADING, "withholdingTable");
    POST<{ withholdingTable: types.TaxForm.W8UsWithholdingTable }>(`/v1/tax/us-w8-withholding-table`)
      .then((res) => {
        standardDispatch(OpCode.DATA, "withholdingTable", { data: res.body.withholdingTable });
      })
      .catch((errors) => {
        standardDispatch(OpCode.ERROR, "withholdingTable", { errors });
      });
  }
}
