import { Icon, Status } from "components";
import { UserAccess } from "features/user";
import React, { ReactNode } from "react";
import { Link } from "react-router-dom";
import { InvoiceStatus } from "store/actions/invoices";
import { useMerchantSettings } from "store/hooks/merchantSettings";
import { Access, BatchStatus, EoyTaxReportStatus, PaymentStatus, RecipientStatus, TicketStatus } from "@trolley/common-frontend";
import Text from "./Typography/Text";
import { TAX_COMPLIANCE_PATHS } from "pages/SettingsPage/TaxCompliance";
import { StatusInvoice } from "features/invoice";

type LabelDescriptionType =
  | "coverFees"
  | "taxReportable"
  | "forceUsTaxActivity"
  | "category"
  | "recipientStatus"
  | "paymentStatus"
  | "paymentMemo"
  | "paymentExternalId"
  | "taxPaidByWithholdingAgents"
  | "batchStatus"
  | "ticketStatus"
  | "eoyTaxFormStatus"
  | "formActions"
  | "profileActions"
  | "eoyFormActions"
  | "invoiceLineStatus"
  | "invoiceStatus"
  | "dac7Classification"
  | "dac7FieldsRelevantActivities";

export function getLabelTooltip(key: LabelDescriptionType): { label: string; tooltip: ReactNode } | undefined {
  switch (key) {
    case "coverFees":
      return {
        label: "Cover Fees",
        tooltip: "If you cover the fees for a payment, you will pay both merchant and recipient fees. Fees splitting will not be applied.",
      };
    case "taxReportable":
      return {
        label: "Tax Reportable",
        tooltip:
          "Indicate if payment should be tax reportable for end of year tax reporting purposes, as well as for calculating any potential withholding. If the payment is exempt, it will not be included in your EOY tax reporting.",
      };
    case "forceUsTaxActivity":
      return {
        label: "US Activities Override",
        tooltip:
          "Indicate if payment's default withholding rates should be overridden, and be taxed as a U.S. Activity, subjecting it to US tax withholding rates. This override only applies to recipients who certified that they do not perform any US Activities.",
      };
    case "category":
      return {
        label: "Payment Purpose",
        tooltip: "The purpose of the payment will be used to categorize the payment correctly on the banking network.",
      };
    case "recipientStatus":
      return {
        label: "Recipient Status",
        tooltip: (
          <ul>
            <li>
              <Status stronger type={RecipientStatus.INCOMPLETE} style={{ minWidth: "75px" }} size="small" left />
              Recipient is missing some information. Incomplete recipients are not payable.
            </li>
            <li>
              <Status stronger type={RecipientStatus.ACTIVE} style={{ minWidth: "75px" }} size="small" left />
              Recipient profile is complete and is payable
            </li>
            <li>
              <Status stronger type={RecipientStatus.DISABLED} style={{ minWidth: "75px" }} size="small" left />
              Recipient is temporarily disabled and is not payable. You can re-enable the recipient.
            </li>
            <li>
              <Status stronger type={RecipientStatus.ARCHIVED} style={{ minWidth: "75px" }} size="small" left />
              Recipient has been archived and is not longer payable. Profile information and past records are kept. You can un-archive the recipient or you can
              re-use the recipient’s email address to recreate a new recipient.
            </li>
            <li>
              <Status stronger type={RecipientStatus.BLOCKED} style={{ minWidth: "75px" }} size="small" left />
              Recipient has been blocked by compliance. The email address can no longer be used with another recipient.
            </li>
            <li>
              <Status stronger type={RecipientStatus.SUSPENDED} style={{ minWidth: "75px" }} size="small" left />
              Recipient has been suspended by you. The email address can no longer be used with another recipient. Contact support if you need to un-suspend a
              recipient due to an error.
            </li>
          </ul>
        ),
      };
    case "paymentStatus":
      return {
        label: "Payment Status",
        tooltip: (
          <ul>
            <li>
              <Status stronger type={PaymentStatus.PENDING} style={{ minWidth: "80px" }} size="small" /> Payment has not been sent yet. To process the payment,
              navigate to the batch page and click “Send Payments”.
            </li>
            <li>
              <Status stronger type={PaymentStatus.PROCESSING} style={{ minWidth: "80px" }} size="small" /> Payment is processing, it may take a few seconds to
              a few hours.
            </li>
            <li>
              <Status stronger type={PaymentStatus.PROCESSED} style={{ minWidth: "80px" }} size="small" /> Payment was successfully processed.
            </li>
            <li>
              <Status stronger type={PaymentStatus.FAILED} style={{ minWidth: "80px" }} size="small" /> Payment has failed to process.
            </li>
            <li>
              <Status stronger type={PaymentStatus.RETURNED} style={{ minWidth: "80px" }} size="small" /> Payment was returned.
            </li>
          </ul>
        ),
      };
    case "paymentMemo":
      return {
        label: "Memo",
        tooltip: "This memo will be sent along with the payment to the beneficiary bank.",
      };
    case "paymentExternalId":
      return {
        label: "External ID",
        tooltip: "The payment reference ID as assigned by your business (your internal payment reference number).",
      };
    case "taxPaidByWithholdingAgents":
      return {
        label: "Tax Paid By Withholding Agent",
        tooltip: (
          <>
            The total amount of tax paid by you and not withheld from the payment to the recipient. The amounts reported is the amounts paid by the withholding
            agent from its own funds rather than through withholding from the payment to the recipient.
            <br />
            This amount is used for the end of year tax form 1042-S (Box 11).
          </>
        ),
      };
    case "batchStatus":
      return {
        label: "Batch Status",
        tooltip: (
          <ul>
            <li>
              <Status stronger type={BatchStatus.OPEN} style={{ minWidth: "80px" }} size="small" /> Batch has not been sent yet. Navigate to the batch page and
              click “Send Payments”.
            </li>
            <li>
              <Status stronger type={BatchStatus.APPROVAL} style={{ minWidth: "80px" }} size="small" /> Batch is waiting for approval. See{" "}
              <Link to="/settings/approval-settings">Approval Settings</Link>
            </li>
            <li>
              <Status stronger type={BatchStatus.PROCESSING} style={{ minWidth: "80px" }} size="small" /> At least 1 payment in the batch is in processing
              status.
            </li>
            <li>
              <Status stronger type={BatchStatus.COMPLETE} style={{ minWidth: "80px" }} size="small" /> All payments in the batch have completed processing, and
              at least 1 payment was successful.
            </li>
            <li>
              <Status stronger type={BatchStatus.FAILED} style={{ minWidth: "80px" }} size="small" /> All payments in the batch have failed.
            </li>
          </ul>
        ),
      };
    case "ticketStatus":
      return {
        label: "Ticket Status",
        tooltip: (
          <ul>
            <li>
              <Status stronger type={TicketStatus.OPEN} style={{ minWidth: "75px" }} size="small" left />
              This is a new information request and it is still waiting for the information to be provided.
            </li>
            <li>
              <Status stronger type={TicketStatus.PROVIDED} style={{ minWidth: "75px" }} size="small" left />
              The requested information has been provided by either the recipient or merchant, and is awaiting review by Trolley staff.
            </li>
            <li>
              <Status stronger type={TicketStatus.CLOSED} style={{ minWidth: "75px" }} size="small" left />
              The information requested has been provided and reviewed, and ticket has been successfully closed.
            </li>
            <li>
              <Status stronger type={TicketStatus.CANCELED} style={{ minWidth: "75px" }} size="small" left />
              The information request has been cancelled and is not longer required.
            </li>
          </ul>
        ),
      };
    case "eoyTaxFormStatus":
      return {
        label: "Earning Status",
        tooltip: (
          <ul>
            <li>
              <Status stronger type={EoyTaxReportStatus.NEEDS_REVIEW} style={{ minWidth: "75px" }} size="small" left />
              The earnings need to be reviewed.
            </li>
            <li>
              <Status stronger type={EoyTaxReportStatus.HOLD} style={{ minWidth: "75px" }} size="small" left />
              The approval of the earnings has been put on hold, usually due to undecided course of action.
            </li>
            <li>
              <Status stronger type={EoyTaxReportStatus.APPROVED} style={{ minWidth: "75px" }} size="small" left />
              The earnings have been approved and the Tax Statement is ready to be sent to the recipient.
            </li>
            <li>
              <Status stronger type={EoyTaxReportStatus.SENT} style={{ minWidth: "75px" }} size="small" left />
              The Tax Statement has been marked as sent to the recipient.
            </li>
            <li>
              <Status stronger type={EoyTaxReportStatus.DO_NOT_SEND} style={{ minWidth: "75px" }} size="small" left />
              The Tax Statement will not be sent to the recipient.
            </li>
            <li>
              <Status stronger type={EoyTaxReportStatus.VOID} style={{ minWidth: "75px" }} size="small" left />
              The Tax Statement has been voided, usually due to a change in the earnings amount in the tax year, if the earnings has been previously approved,
              or marked as sent to the recipient. New earnings and an amended tax statement will likely need to be reviewed.
            </li>
          </ul>
        ),
      };
    case "formActions":
      return {
        label: "Actions",
        tooltip: (
          <ul>
            <li>
              <Text inline weight="bold" type="secondary">
                Mark as Reviewed
              </Text>{" "}
              will change a submitted tax form status into "reviewed".
            </li>
            <li>
              <Text inline weight="bold" type="label">
                Edit
              </Text>{" "}
              an uploaded tax form to add additional information.
            </li>
            <li>
              <Text inline weight="bold" type="label">
                Void
              </Text>{" "}
              a tax form to invalidate it. You can still view it for reference.
            </li>
          </ul>
        ),
      };
    case "profileActions":
      return {
        label: "Actions",
        tooltip: (
          <ul>
            <li>
              <Text inline weight="bold" type="secondary">
                Mark as Reviewed
              </Text>{" "}
              will change a submitted tax profile status into "reviewed".
            </li>
            <li>
              <Text inline weight="bold" type="label">
                Void
              </Text>{" "}
              a tax profile to invalidate it. You can still view it for reference.
            </li>
          </ul>
        ),
      };
    case "eoyFormActions":
      return {
        label: "Actions",
        tooltip: (
          <ul>
            <li>
              <Text inline type="label" weight="bold">
                Approve
              </Text>{" "}
              the earnings.
            </li>
            <li>
              <Text inline type="label" weight="bold">
                Hold
              </Text>{" "}
              the approval of the earnings,
              <br />
              usually due to undecided course of action.
            </li>
            <li>
              <Text inline type="label" weight="bold">
                Do Not Send
              </Text>{" "}
              the tax statement.
            </li>
            <li>
              <Text inline type="label" weight="bold">
                Send Statement
              </Text>{" "}
              to the recipient.
            </li>
            <li>
              <Text inline type="label" weight="bold">
                Void Statement.
              </Text>{" "}
              This will recreate a replacement statement.
            </li>
          </ul>
        ),
      };
    case "invoiceStatus":
      return {
        label: "Invoice Status",
        tooltip: (
          <ul>
            <li>
              <StatusInvoice type={InvoiceStatus.OPEN} style={{ minWidth: "80px" }} size="small" left stronger />
              Invoice is unpaid.
            </li>
            <li>
              <StatusInvoice type={InvoiceStatus.PENDING} style={{ minWidth: "80px" }} size="small" left stronger />
              There exists at least one pending or processing payment allocation to the invoice.
            </li>
            <li>
              <StatusInvoice type={InvoiceStatus.PARTIAL_PAID} style={{ minWidth: "80px" }} size="small" left stronger />
              Invoice is partially paid.
            </li>
            <li>
              <StatusInvoice type={InvoiceStatus.PAID} style={{ minWidth: "80px" }} size="small" left stronger />
              Invoice is fully paid.
            </li>
          </ul>
        ),
      };
    case "invoiceLineStatus":
      return {
        label: "Invoice Line Status",
        tooltip: (
          <ul>
            <li>
              <StatusInvoice type={InvoiceStatus.OPEN} style={{ minWidth: "80px" }} size="small" left stronger />
              Invoice line item is unpaid.
            </li>
            <li>
              <StatusInvoice type={InvoiceStatus.PENDING} style={{ minWidth: "80px" }} size="small" left stronger />
              There exists at least one pending or processing payment allocated to the invoice line item.
            </li>
            <li>
              <StatusInvoice type={InvoiceStatus.PARTIAL_PAID} style={{ minWidth: "80px" }} size="small" left stronger />
              Invoice line item is partially paid.
            </li>
            <li>
              <Status type={InvoiceStatus.PAID} style={{ minWidth: "80px" }} size="small" left stronger />
              Invoice line item is fully paid.
            </li>
          </ul>
        ),
      };
    case "dac7Classification":
      return {
        label: "DAC7 Tax Classification",
        tooltip: (
          <ul>
            <li>
              <Text weight="bold">Out of Scope</Text>
              Out of scope of DAC7 reporting
            </li>
            <li>
              <Text weight="bold">In Scope Excluded</Text>
              In scope of DAC7 reporting, but excluded from being reportable under DAC7s exclusions (ex. Small Goods Seller)
            </li>
            <li>
              <Text weight="bold">In Scope Out Of Jurisdiction</Text>
              In scope of DAC7 activities, but outside of the EU
            </li>
            <li>
              <Text weight="bold">In Scope Reportable</Text>
              In scope of DAC7 - will be reported at end of year
            </li>
          </ul>
        ),
      };
    case "dac7FieldsRelevantActivities":
      return {
        label: "Relevant Activities",
        tooltip:
          "Use relevant activities to report the number of activities that have been performed on a single transactions (ex. If the payment is for the sales related to 6 marketplace transactions, then you should enter 6).",
      };
    default:
      return undefined;
  }
}

interface Props {
  type: LabelDescriptionType;
}

export default function LabelTooltip({ type }: Props) {
  const { features } = useMerchantSettings();

  const labeTooltip = getLabelTooltip(type);
  let tooltipTitle = labeTooltip?.tooltip;

  if (type === "coverFees") {
    tooltipTitle = (
      <span>
        {tooltipTitle}
        <UserAccess type={Access.SETTINGS_WRITE}>
          <Link to="/settings/fees">
            <Icon type="gear" right />
          </Link>
        </UserAccess>
      </span>
    );
  }
  if (type === "category") {
    tooltipTitle = (
      <span>
        {features.tax
          ? "The purpose of the payment will be used to report the payment correctly for tax purposes based on the income code type, as well as to categorize the payment correctly on the banking network."
          : tooltipTitle}
        <UserAccess type={Access.SETTINGS_WRITE}>
          <Link to={features.tax ? TAX_COMPLIANCE_PATHS.TAX : "/settings"}>
            <Icon type="gear" right />
          </Link>
        </UserAccess>
      </span>
    );
  }

  return (
    <>
      {labeTooltip?.label}
      <Icon.Hint right tooltip={tooltipTitle} />
    </>
  );
}
