import { Spin, SpinProps } from "antd";
import TrolleyLoaderSvg from "assets/images/Trolley_loader.svg";
import React, { FunctionComponent } from "react";
import css, { createUseStyle } from "style/classname";

const useStyledLoader = createUseStyle(({ theme }) =>
  css`
    &.${theme.prefixCls}-spin {
      width: 100%;
      height: 100%;
      min-height: 80px;
      position: relative;
      img {
        width: 72px;
        height: 72px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
      }
    }
  `(),
);

interface Props extends Omit<SpinProps, "indicator" | "className" | "wrapperClassName"> {}

const LogoLoader: FunctionComponent<Props> = (props) => {
  const styledLoader = useStyledLoader();

  return <Spin {...props} className={styledLoader} indicator={<img src={TrolleyLoaderSvg} alt="Trolley Loader" />} />;
};

export default LogoLoader;
