import { allMappedCountries, formatCountry, LOCALE_LABELS, PayoutMethodType, RecipientStatus } from "@trolley/common-frontend";
import {
  Box,
  Container,
  CopyToClipboard,
  Divider,
  Form,
  FormItem,
  GovernmentIdsInput,
  Grid,
  Heading,
  Input,
  InputDate,
  InputPhone,
  phoneNumberValidator,
  Radio,
  Select,
  SelectCountry,
  SelectRegion,
} from "components";
import { NamePath, Rule } from "components/Form";
import { governmentIdsValidator } from "components/Form/GovernmentIdsInput";
import { validatePastDate } from "components/Form/InputDate";
import { useRecipientEditable } from "features/recipient";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { notifySuccess } from "store/actions/notifications";
import { RecipientUpdate, StoreRecipient, updateRecipient } from "store/actions/recipients";
import { useRecipientAccounts } from "store/hooks/recipientAccount";
import { useRecipient } from "store/hooks/recipients";
import { handleFormErrors, pickDiff, translateRegionByCountry } from "utils/helpers";
import CompletionSteps from "./CompletionSteps";
import ContactEmails from "./ContactEmails";
import PayoutMethodsList from "./PayoutMethodsList";
import RecipientWhiteLabelEmails from "./RecipientWhiteLabelEmails";
import { useRecipientConfig } from "store/hooks/recipientConfig";
import { loadRecipientConfig } from "store/actions/recipientConfig";

type Params = { recipientId: string };

const SUPPORTED_LOCALE_OPTIONS = Object.entries(LOCALE_LABELS).map(([value, label]) => ({
  label: `${label} (${value})`,
  value,
}));

export function isRegionInCountryAsyncValidator(countryField: NamePath): Rule {
  return (form) => ({
    async validator(rule, region) {
      // Changing country does not clear region now.
      // region cannot be cleared.
      // Make sure the select region is a region of the country.
      const country = form.getFieldValue(countryField);
      if (region && country && !allMappedCountries[country]?.regions.some((r) => r.shortCode === region || r.name === region)) {
        throw `Region does not exist in ${formatCountry(country)}`;
      }
    },
  });
}

export default function Info() {
  const { recipientId } = useParams<Params>();
  const [form] = Form.useForm();
  const type = Form.useWatch("type", form);
  const country = Form.useWatch(["address", "country"], form);
  const { data: recipient, status: recipientStatus } = useRecipient(recipientId);
  const { data: recipientConfig } = useRecipientConfig(recipientId);
  const accounts = useRecipientAccounts(recipientId);
  const profileEditable = useRecipientEditable();
  const primaryBankAccount = accounts.find((a) => a.primary && a.type === PayoutMethodType.BANKTRANSFER);

  useEffect(() => {
    if (recipient && !form.isFieldsTouched()) {
      form.resetFields();
    }
  }, [recipient]);

  async function onSubmit(values: RecipientUpdate) {
    const updates = pickDiff(values, recipient, true);
    if (updates) {
      try {
        await updateRecipient(recipientId, updates);
        notifySuccess("Recipient information saved");
        loadRecipientConfig(recipientId, true);
      } catch (errors) {
        handleFormErrors(errors, form);
      }
    } else {
      notifySuccess("Recipient information saved");
    }
  }

  function renderContactInfoBox(recipient: StoreRecipient) {
    return (
      <Box header="Contact Information">
        <Form form={form} onFinish={onSubmit} validateTrigger={["onBlur"]} initialValues={recipient} disabled={!profileEditable} requiredMark>
          <Grid padding={["medium", "none"]}>
            <Grid.Item xs={24} md={12}>
              <Form.Item label="Recipient ID">
                <CopyToClipboard value={recipientId} />
              </Form.Item>
            </Grid.Item>
            <Grid.Item xs={24} md={12}>
              <Form.Item label="Reference ID" name="referenceId">
                <Input name="referenceId" />
              </Form.Item>
            </Grid.Item>

            <Grid.Item xs={24} md={12}>
              <Form.Item label="Type" name="type">
                <Radio.Group
                  style={{ height: "32px" }}
                  options={[
                    { value: "individual", label: "Individual" },
                    { value: "business", label: "Business" },
                  ]}
                />
              </Form.Item>
            </Grid.Item>
            <Grid.Item xs={24} md={12}>
              <Form.Item
                label="Email"
                rules={[
                  { required: true, message: "Email address is required" },
                  { type: "email", message: "Enter a valid email address" },
                ]}
                name="email"
              >
                <Input disabled={!profileEditable || [RecipientStatus.BLOCKED, RecipientStatus.SUSPENDED].includes(recipient.status)} />
              </Form.Item>
            </Grid.Item>
          </Grid>
          <Grid padding={["medium", "none"]}>
            <Grid.Item xs={24} md={12}>
              <Form.Item
                name="firstName"
                dependencies={["type"]}
                label="First Name"
                rules={[
                  {
                    transform: (val) => String(val ?? "").trim(),
                    required: type === "individual",
                    message: "First name is required",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Grid.Item>
            <Grid.Item xs={24} md={12}>
              <Form.Item
                name="lastName"
                dependencies={["type"]}
                label="Last Name"
                rules={[
                  {
                    transform: (val) => String(val ?? "").trim(),
                    required: type === "individual",
                    message: "Last name is required",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Grid.Item>
          </Grid>

          <Grid padding={["medium", "none"]}>
            {type === "individual" && (
              <Grid.Item xs={24} md={12}>
                <Form.Item label="Date of Birth" name="dob" rules={[validatePastDate]}>
                  <InputDate allowClear />
                </Form.Item>
              </Grid.Item>
            )}
            {type === "business" && (
              <Grid.Item xs={24} md={12}>
                <Form.Item
                  label="Business name"
                  name="name"
                  rules={[
                    {
                      transform: (val) => String(val ?? "").trim(),
                      required: true,
                      message: "Business name is required",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Grid.Item>
            )}
            <Grid.Item xs={24} md={12}>
              <Form.Item label="Phone Number" name={["address", "phone"]} rules={[phoneNumberValidator()]}>
                <InputPhone name="phone" allowClear data-testid="phone" />
              </Form.Item>
            </Grid.Item>

            <Grid.Item xs={24} md={12}>
              <FormItem
                label="Preferred Language"
                name="language"
                tooltip="Set the recipient's preferred language for all automated Emails, the Widget, and Portal. English is the default language if not specified."
              >
                <Select showSearch options={SUPPORTED_LOCALE_OPTIONS} />
              </FormItem>
            </Grid.Item>
            <Grid.Item xs={24} md={12}>
              <Form.Item label="Country" name={["address", "country"]} validateTrigger={["onBlur", "onChange"]}>
                <SelectCountry
                  type="address"
                  onChange={() => {
                    form.setFieldsValue({
                      address: {
                        region: "", // reset region
                      },
                    });
                  }}
                />
              </Form.Item>
            </Grid.Item>

            {country && (
              <>
                <Grid.Item xs={24}>
                  <FormItem
                    label="Government ID Numbers"
                    tooltip="ID number belonging to the Account Holder of the account being paid"
                    name="governmentIds"
                    validateTrigger="onSubmit"
                    rules={[governmentIdsValidator]}
                  >
                    <GovernmentIdsInput
                      formCountry={(recipient.inactiveReasons?.governmentId && primaryBankAccount?.country) || country}
                      recipientConfig={recipientConfig}
                      recipientType={type}
                      recipientCountry={recipient.address.country}
                    />
                  </FormItem>
                </Grid.Item>
                <Grid.Item xs={24}>
                  <Divider margin="medium" />
                  <Heading tag="h2">Address</Heading>
                </Grid.Item>
                <Grid.Item xs={24} md={12}>
                  <Form.Item label="Street" name={["address", "street1"]}>
                    <Input name="street1" />
                  </Form.Item>
                </Grid.Item>
                <Grid.Item xs={24} md={12}>
                  <Form.Item label="Street 2" name={["address", "street2"]}>
                    <Input name="street2" />
                  </Form.Item>
                </Grid.Item>
                <Grid.Item xs={24} md={12}>
                  <Form.Item label="City" name={["address", "city"]}>
                    <Input name="city" />
                  </Form.Item>
                </Grid.Item>
                <Grid.Item xs={24} md={7}>
                  <Form.Item
                    label={translateRegionByCountry(country)}
                    name={["address", "region"]}
                    dependencies={[["address", "country"]]}
                    validateTrigger={["onBlur", "onChange"]}
                    rules={[isRegionInCountryAsyncValidator(["address", "country"])]}
                  >
                    <SelectRegion country={country} />
                  </Form.Item>
                </Grid.Item>
                <Grid.Item xs={24} md={5}>
                  <Form.Item label="Postal/Zip code" name={["address", "postalCode"]}>
                    <Input name="postalCode" />
                  </Form.Item>
                </Grid.Item>
              </>
            )}
          </Grid>

          {profileEditable && <Form.SubmitButtons status={recipientStatus} saveText="Save Contact Information" />}
        </Form>
      </Box>
    );
  }

  return (
    <Container>
      {recipient?.status === RecipientStatus.INCOMPLETE && recipient.inactiveReasons && profileEditable && (
        <CompletionSteps recipient={recipient} recipientConfig={recipientConfig} />
      )}
      {recipient && (
        <Grid>
          <Grid.Item flex="10 1 400px">{renderContactInfoBox(recipient)}</Grid.Item>
          <Grid.Item flex="1 0 350px">
            <Box header="Payout Methods">
              <PayoutMethodsList editable recipient={recipient} />
            </Box>

            <ContactEmails recipient={recipient} />

            <RecipientWhiteLabelEmails recipient={recipient} />
          </Grid.Item>
        </Grid>
      )}
    </Container>
  );
}
