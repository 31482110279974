import { ConfigProvider, theme as antTheme } from "antd";
import { ConfigProviderProps } from "antd/lib/config-provider";
import { OverrideToken } from "antd/lib/theme/interface";
import { AliasToken } from "antd/lib/theme/internal";
import assignDeep from "assign-deep";
import React from "react";
import colors from "style/colors";
import defaultTokens from "style/defaultTokens";
import { useDeepCompareMemo } from "utils/hooks";

interface ThemeProviderProps extends Omit<ConfigProviderProps, "theme"> {
  token?: Partial<AliasToken>;
  components?: (token: AliasToken) => OverrideToken;
}

export default function ThemeProvider({ children, token: overrideToken, components: overrideComponents = () => ({}), ...rest }: ThemeProviderProps) {
  const { token, components } = useDeepCompareMemo(() => {
    const token = antTheme.getDesignToken({
      token: {
        ...defaultTokens,
        ...overrideToken,
      },
    });

    const components: OverrideToken = assignDeep(
      {
        Avatar: {
          colorTextLightSolid: token.colorTextDescription,
          colorTextPlaceholder: token.colorFillContent, // background color
          controlHeightSM: 24,
          controlHeight: 40,
          controlHeightLG: 64,
          fontSize: 12, // for small. fontSize should be half of controheight
          fontSizeLG: 16, // for default. fontSize should be half of controheight
          fontSizeHeading3: 32, // for large. fontSize should be half of controheight
          lineWidth: 0, // make border 0 so control height is calculated correctly
        },
        Button: {
          marginXS: 4, // icon margin
          fontSizeLG: token.fontSize,
          controlHeightSM: 20,
        },
        Checkbox: {
          borderRadiusSM: token.sizeXXS,
        },
        Collapse: {
          fontSizeLG: token.fontSizeHeading3,
          lineHeightLG: token.lineHeightHeading3,
        },
        Drawer: {
          colorBgElevated: token.colorBgLayout,
          paddingLG: token.sizeSM,
        },
        Form: {
          colorTextHeading: token.colorTextSecondary, // label color: ;
          marginLG: 16, // form item marginBottom
          paddingXS: 4, // space between label/input on vertical layout
        },
        Input: {
          colorTextPlaceholder: token.colorTextTertiary,
        },
        Layout: {
          colorBgHeader: token.colorBgLayout,
          controlHeight: 36, // header height
          controlHeightLG: 0,
        },
        Menu: {
          controlHeightLG: 32, // item height
          colorItemText: token.colorTextSecondary,
        },
        Modal: {
          fontSize: 16, // make body text bigger in modal
          lineHeight: (16 + 8) / 16,
          colorTextDescription: colors.black, // icon color
          padding: 8, // icon position from top right
          paddingMD: 24, // vertical body padding: ;
          paddingContentHorizontalLG: 24, // horizontal body padding
          fontSizeHeading5: 18, // modal title font size
          lineHeightHeading5: (18 + 8) / 18,
          marginSM: 24, // gap before footer
        },
        Switch: {
          fontSizeSM: 10,
          lineHeight: 20 / token.fontSize,
          colorPrimary: token.colorSuccess,
          colorPrimaryHover: token.colorSuccessHover,
        },
        Table: {
          colorTextHeading: token.colorTextSecondary,
          padding: token.sizeSM, // cell horizontal padding.. and more
          paddingContentVerticalLG: token.sizeSM, // cell vertical padding
          borderRadiusLG: token.borderRadius,
        },
        Tabs: {
          colorFillAlter: token.colorFillSecondary,
          marginXXS: 8, // space betwwen tabs
        },
        Tag: {
          fontSizeSM: 12,
          colorFillQuaternary: token.colorFillSecondary,
        },
        Timeline: {
          dotBg: "transparent",
          dotBorderWidth: 2,
          itemPaddingBottom: 16,
        },
        Typography: {
          titleMarginTop: "32px",
          titleMarginBottom: "20px",
        },
      } as OverrideToken,
      overrideComponents(token),
    );

    return {
      components,
      token,
    };
  }, [overrideToken, overrideComponents]);

  return (
    <ConfigProvider theme={{ token, components }} {...rest}>
      {children}
    </ConfigProvider>
  );
}
