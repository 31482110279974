import { useState } from "react";
import { DAC7Tax, DAC7TaxQuery, loadDAC7Tax } from "store/actions/paymentDac7Taxes";

import { emptyList } from "store/reducers/standardReducer";
import { useDeepCompareEffect } from "utils/hooks";
import { useShallowSelector } from ".";

export function usePaymentDac7Taxes(query: DAC7TaxQuery, optionalLoad: boolean = true) {
  const [fetchId, setFetchId] = useState("");

  useDeepCompareEffect(() => {
    if (optionalLoad) {
      setFetchId(loadDAC7Tax(query));
    } else {
      setFetchId("");
    }
  }, [query, optionalLoad]);

  return useShallowSelector((state) => {
    if (fetchId && optionalLoad && state.paymentDac7TaxList.fetchStatus[fetchId] === undefined) {
      loadDAC7Tax(query);
    }

    const data = state.paymentDac7TaxList.entities[fetchId];

    return {
      data: data
        ? {
            records: data.records.map((id) => state.paymentDac7Taxes.entities[id]).filter((v) => v) as DAC7Tax[],
            meta: data.meta,
          }
        : emptyList,
      status: state.paymentDac7TaxList.fetchStatus[fetchId],
      error: state.paymentDac7TaxList.errors[fetchId],
    };
  });
}
