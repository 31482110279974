import { Access, PayoutMethodStatus, PayoutMethodType } from "@trolley/common-frontend";
import { ButtonLink, Icon } from "components";
import { UserAccess } from "features/user";
import { BANK_ONBOARDING_PATH } from "pages/BankTransferOnboarding";
import React from "react";
import { useMerchantSettings } from "store/hooks/merchantSettings";
import { useOnboarding } from "store/hooks/onboarding";
import { usePayoutMethods } from "store/hooks/payoutMethods";
import styled, { createUseStyle } from "style/classname";

export default function ActivateBankAlert() {
  const { data: merchantSettings } = useMerchantSettings();
  const { data: onboarding, bankingEligibility } = useOnboarding();
  const { data: payoutMethods } = usePayoutMethods();
  const styledButton = useStyledButton();
  const bankTransfer = payoutMethods.find((pm) => pm.integration === PayoutMethodType.BANKTRANSFER);

  if (!merchantSettings || !bankTransfer) {
    return null;
  }

  if (bankingEligibility && bankTransfer?.status !== PayoutMethodStatus.APPROVED && !onboarding?.bankOnboardingCompletedAt) {
    return (
      <UserAccess type={Access.ONBOARDING_WRITE}>
        <ButtonLink size="small" path={BANK_ONBOARDING_PATH} className={styledButton}>
          Activate Bank Transfer
          <Icon type="angle-right" right />
        </ButtonLink>
      </UserAccess>
    );
  }

  return null;
}

const useStyledButton = createUseStyle(({ theme }) =>
  styled`
  &.${theme.prefixCls}-btn {
    font-weight: bold;
    border-color: transparent !important;
    color: ${theme.colorText};
    background-color: ${theme.colorWarning};
    &:hover, &:focus, &:active {
      color: ${theme.colorText} !important;
    }
    &:hover {
      background-color: ${theme.colorWarningHover};
    }
    &:active {
      background-color: ${theme.colorWarningActive};
    }
  }
`(),
);
