import { useLayoutEffect, useRef } from "react";

export default function useInterval(callback: Function, delay?: number | null) {
  const savedCallback = useRef<Function>(() => {
    // empty
  });

  useLayoutEffect(() => {
    savedCallback.current = callback;
  });

  useLayoutEffect(() => {
    if (delay !== null) {
      const interval = window.setInterval(() => savedCallback.current(), delay || 0);

      return () => window.clearInterval(interval);
    }

    return undefined;
  }, [delay]);
}
