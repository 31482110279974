import { Spinner, Tag, Text, TextProps } from "components";
import React from "react";
import { useTeamMember } from "store/hooks/teamMemberList";
import { useUser } from "store/hooks/user";
import { BaseStatus } from "store/reducers/standardReducer";

interface Props extends Omit<TextProps, "children"> {
  userId: string | undefined | null;
  showRole?: true;
  showEmail?: true;
  userName?: string;
}

export default function UserProfile(props: Props) {
  const { userId, showRole, showEmail, userName, ...rest } = props;
  const { data: authUser } = useUser();
  const { data, status } = useTeamMember(userId);
  const user = authUser?.id === userId && userId ? authUser : data;

  let baseStatus: BaseStatus | undefined = BaseStatus.LOADED;
  let name: string | undefined;

  if (authUser?.id === userId) {
    name = authUser?.name;
  } else if (userName) {
    name = userName;
  } else {
    name = data?.name;
    baseStatus = status;
  }

  return (
    <Text {...rest}>
      <span data-test="name" style={{ marginRight: "6px" }}>
        {name || userId}
        {baseStatus === BaseStatus.LOADING ? <Spinner size="small" right /> : null}
      </span>
      {!!showRole && user && <Tag>{user.role.toLocaleUpperCase()}</Tag>}
      {showEmail && !userName && (
        <Text type="secondary" data-test="email">
          {user?.email}
        </Text>
      )}
    </Text>
  );
}
