import { Collapse } from "antd";
import { Button, ButtonDelete, ButtonLink, Divider, Icon, Space, Status, Text } from "components";
import React, { useState } from "react";
import { Onboarding } from "store/actions/onboarding";
import { createOnboardingPerson, deleteOnboardingPerson } from "store/actions/onboardingPersons";
import { useOnboarding } from "store/hooks/onboarding";
import { BaseStatus } from "store/reducers/standardReducer";
import { useThemeToken } from "style/classname";
import { BANK_ONBOARDING_PATH, BankOnboardingPages, OnboardingSection } from ".";
import PersonForm, { PersonFormField } from "./PersonForm";
import { styledCollapse } from "./SigningOfficer";
import { isPersonComplete } from "./variables";

function validateBeneficialOwners(onboarding: Onboarding | undefined): string | null {
  if (onboarding) {
    const owners = onboarding.persons.filter((p) => p.isOwner);
    if (!onboarding.noOwnership) {
      if (!owners.length) {
        return "An owner is required";
      }
      if (owners.some((p) => !isPersonComplete(p, "owners"))) {
        return "One or more owner is incomplete";
      }
    }
  }

  return null;
}

export default function BenefinialOwners() {
  const [activeKey, setActiveKey] = useState<undefined | string>();
  const { data: onboarding, status: onboardingStatus } = useOnboarding();
  const token = useThemeToken();
  const onboardingPersons = onboarding?.persons ?? [];
  const busy = onboardingStatus === BaseStatus.LOADING;
  const owners = onboardingPersons.filter((person) => person.isOwner);
  const error = validateBeneficialOwners(onboarding);

  async function onAddOwner() {
    const owner = await createOnboardingPerson({ isOwner: true });
    setActiveKey(owner.id);
  }

  return (
    <OnboardingSection
      title="Beneficial Owners Details"
      description={
        <>
          Add all physical persons (yourself included) or businesses who directly or indirectly own more than 25% or have a controlling interest in your
          company.
        </>
      }
    >
      <Collapse
        ghost
        activeKey={activeKey}
        accordion
        onChange={(key) => {
          if (typeof key === "string") {
            setActiveKey(key);
          } else if (typeof key[0] === "string") {
            setActiveKey(key[0]);
          }
        }}
        items={owners.map((owner, index) => {
          let name = "New Owner";
          if (owner.type === "individual" && (owner.firstName || owner.lastName)) {
            name = [owner.firstName, owner.lastName].filter((v) => v).join(" ");
          } else if (owner.type === "business" && owner.name) {
            name = owner.name;
          }

          return {
            key: owner.id,
            className: styledCollapse({ hidden: !!activeKey && activeKey !== owner.id, token }),
            label: (
              <>
                <Text size="large">
                  Owner #{index + 1}: {name}
                </Text>
                {owner.percentOfOwnership && (
                  <Text type="secondary">
                    Ownership: &nbsp;{owner.percentOfOwnership}% {owner.ownershipType} shares
                  </Text>
                )}
              </>
            ),
            extra: (
              <Space>
                <Status type={isPersonComplete(owner, PersonFormField.OWNERS) ? "completed" : "incomplete"} />
                {activeKey === owner.id ? (
                  <Button
                    onClick={() => {
                      setActiveKey(undefined);
                    }}
                    tooltipProps={{ title: "Close" }}
                    type="text"
                    icon={<Icon type="times" />}
                  />
                ) : (
                  <>
                    <Button
                      size="small"
                      icon={<Icon type="pencil" />}
                      tooltipProps={{ title: "Edit" }}
                      onClick={() => {
                        setActiveKey(owner.id);
                      }}
                    />
                    <ButtonDelete
                      title={`Do you want to delete ${name}?`}
                      confirmType="modal"
                      tooltipProps={{ title: "Delete" }}
                      size="small"
                      onConfirm={async () => {
                        await deleteOnboardingPerson([owner.id]);
                      }}
                    />
                  </>
                )}
              </Space>
            ),
            showArrow: false,
            children: (
              <PersonForm
                person={owner}
                personLabel="Owner"
                onboardingType={PersonFormField.OWNERS}
                onClose={() => {
                  setActiveKey(undefined);
                }}
              />
            ),
          };
        })}
      />
      {!activeKey && (
        <>
          <Button onClick={onAddOwner} loading={busy} icon={<Icon type="plus" />}>
            {owners.length > 0 ? "Add another Owner" : "Add a Beneficial Owner"}
          </Button>
          <Divider transparent margin="small" />
          <Text type="error">{error}</Text>
        </>
      )}
      <Divider margin="medium" transparent />
      {!activeKey && (
        <ButtonLink size="large" block type="primary" path={[BANK_ONBOARDING_PATH, BankOnboardingPages.SHARE_REGISTER].join("/")}>
          Continue
          <Icon type="angle-right" />
        </ButtonLink>
      )}
    </OnboardingSection>
  );
}
