import * as types from "@trolley/common-frontend";
import { batch } from "react-redux";
import { POST } from "services/request";
import store from "store";
import { OpCode, standardDispatch } from "store/dispatcher";
import { Mapped } from "store/reducers/standardReducer";
import { queueFactory } from "utils/factories";
import { Query } from "utils/hooks";
import { computeID, isLoaded } from "./actionUtils";

export interface TaxFormsQuery extends Query {
  orderBy?: string[];
  sortBy?: ("asc" | "desc")[];
  recipientId?: string[];
  id?: string[];
  kind?: types.TaxFormType[];
  status?: types.TaxStatus[];
  tinStatus?: string[];
  ftinStatus?: string[];
  search?: string;
}

export type TaxForm = types.TaxForm.TaxForm;
export type W9Form = types.TaxForm.W9Form;
export type W8BENForm = types.TaxForm.W8BENForm;
export type W8BENEForm = types.TaxForm.W8BENEForm;
export type UsUpload = types.TaxForm.UsUpload;
export interface W8UsWithholdingTable extends types.TaxForm.W8UsWithholdingTable {}

export function resetTaxForms() {
  standardDispatch(OpCode.RESET, "recipientTaxForms");
  standardDispatch(OpCode.RESET, "taxForms");
}

const taxFormQueueLoader = queueFactory(
  (taxFormIds) => {
    POST<types.TaxForm.ListResult>("/v1/tax-form/search", { query: { id: taxFormIds, pageSize: taxFormIds.length } })
      .then(({ body }) => {
        const mappedTaxForms = body.taxForms.reduce((acc, tf) => {
          acc[tf.id] = tf;

          return acc;
        }, {});
        standardDispatch(OpCode.DATA, "taxForm", {
          bulk: taxFormIds.reduce((acc, id) => {
            acc[id] = mappedTaxForms[id] ?? undefined;

            return acc;
          }, {}),
        });
      })
      .catch((errors) => {
        standardDispatch(OpCode.ERROR, "taxForm", {
          ids: taxFormIds,
          errors,
        });
      });
  },
  (id) => /^TX-\w+/.test(id),
);

export function loadTaxForm(id: string, force?: boolean) {
  const { taxForm } = store.getState();

  if (force || !isLoaded(taxForm.fetchStatus[id])) {
    standardDispatch(OpCode.LOADING, "taxForm", { id });
    taxFormQueueLoader.add(id);
  }
}

export function loadTaxForms(options: TaxFormsQuery, force?: boolean) {
  const { taxForms } = store.getState();
  const id = computeID(options);

  if (force || !isLoaded(taxForms.fetchStatus[id])) {
    standardDispatch(OpCode.LOADING, "taxForms", { id });

    POST<types.TaxForm.ListResult>("/v1/tax-form/search", { query: options })
      .then((res) => {
        const mapTaxForms: Mapped<TaxForm> = {};
        const ids = res.body.taxForms.map((tf) => {
          mapTaxForms[tf.id] = tf;

          return tf.id;
        });
        batch(() => {
          standardDispatch(OpCode.DATA, "taxForms", {
            id,
            data: {
              records: ids,
              meta: res.body.meta,
            },
          });

          standardDispatch(OpCode.DATA, "taxForm", {
            bulk: mapTaxForms,
          });
        });
      })
      .catch((errors) => {
        standardDispatch(OpCode.ERROR, "taxForms", {
          errors,
          id,
        });
      });
  }

  return id;
}

export async function unmaskFtin(taxFormId: string) {
  try {
    const {
      body: { tinNumber },
    } = await POST<{ tinNumber: string }>("/v1/tax-form/unmask-tin", {
      query: {
        id: taxFormId,
      },
    });

    return tinNumber;
  } catch (errors) {
    standardDispatch(OpCode.ERROR, "taxForms", {
      errors,
    });
    throw errors;
  }
}
