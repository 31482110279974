import { BaseError } from "@trolley/common-frontend";
import { Badge, Box, FormItem, Icon, LogoLoader, Text } from "components";
import React, { ReactNode, useEffect, useState } from "react";
import { BankInfo } from "store/actions/bankInfo";
import { MerchantBankAccount } from "store/actions/directDebit";
import { RecipientAccount } from "store/actions/recipientAccount";
import { BaseStatus } from "store/reducers/standardReducer";
import styled from "style/classname";
import colors from "style/colors";
import { stringifyAddress } from "utils/helpers";

const styledBox = styled<{ loading?: boolean }>`
  &.box {
    * {
      visibility: ${({ loading }) => (loading ? "hidden" : "visible")};
    }
    border-width: 2px;
  }
`;

const styledBankLogo = styled`
  height: 32px;
  img {
    max-height: 100%;
    max-width: 100%;
  }
`();

const styledNotice = styled`
  position: absolute;
  bottom: 10px;
  right: 10px;
`();

interface Props {
  bankInfo?: BankInfo;
  bankInfoStatus?: BaseStatus;
  account?: Partial<RecipientAccount> | MerchantBankAccount;
  showAddress?: boolean; // for swiftbic countries, it should be false;
  errors?: BaseError[] | BaseError;
}

const ErrorBox = ({ children }: { children: ReactNode }) => (
  <Badge.Ribbon color={colors.red} text={<Icon type="xmark" />}>
    <LogoLoader spinning={false}>
      <Box className={styledBox({ loading: false })} color="red">
        <Text type="secondary" align="center" style={{ margin: "20px 0" }}>
          {children}
        </Text>
      </Box>
    </LogoLoader>
  </Badge.Ribbon>
);

export default function BankDisplay(props: Props) {
  const { account, bankInfo, bankInfoStatus, showAddress = true, errors } = props;
  const [showDefaultLogo, setShowDefaultLogo] = useState(false);
  const bankDetails = bankInfo || account;

  useEffect(() => {
    setShowDefaultLogo(false);
  }, [bankInfo]);

  if (bankInfoStatus === BaseStatus.ERROR) {
    if (errors?.some?.((x: BaseError) => x.code === "bad_request")) {
      return (
        <ErrorBox>
          This financial institution is currently not supported.
          <br />
          Please try entering different financial institution details.
        </ErrorBox>
      );
    } else {
      return (
        <ErrorBox>
          No bank address information found.
          <br />
          Please verify the banking details
        </ErrorBox>
      );
    }
  }

  if (!bankDetails) {
    if (bankInfoStatus === BaseStatus.LOADING) {
      return (
        <LogoLoader>
          <Box style={{ height: "220px" }} color="grey" />
        </LogoLoader>
      );
    }

    return null;
  }

  return (
    <Badge.Ribbon color={colors.green} text={<Icon type="check" />}>
      <LogoLoader spinning={bankInfoStatus === BaseStatus.LOADING}>
        <Box className={styledBox({ loading: bankInfoStatus === BaseStatus.LOADING })} color="green">
          <div className={styledBankLogo}>
            {!showDefaultLogo && bankInfo?.logoURL ? (
              <img
                alt="bank logo"
                src={bankInfo?.logoURL}
                onError={() => {
                  setShowDefaultLogo(true);
                }}
              />
            ) : (
              <Icon type="bank" size="xlarge" />
            )}
          </div>

          <FormItem label="Bank name">{bankDetails.bankName}</FormItem>
          {showAddress && <FormItem label="Bank address">{stringifyAddress(bankDetails, true)}</FormItem>}
          <Icon.Hint
            className={styledNotice}
            tooltipProps={{ placement: "topRight" }}
            tooltip={
              <>
                We provide the bank name, logo and address for indicative purposes to help you confirm you entered the correct bank account details. However,
                sometimes this information may not always be accurate, such as when a smaller bank is using another larger bank&apos;s Institution, SWIFT/BIC,
                Sort Code, Routing or Branch number.
              </>
            }
          />
        </Box>
      </LogoLoader>
    </Badge.Ribbon>
  );
}
