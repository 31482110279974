import { CountryCode, countryHasPostalCode } from "@trolley/common-frontend";
import { Alert, Checkbox, Form, Grid, Input, InputMask, Select, SelectCountry, SelectRegion } from "components";
import { isRegionInCountryAsyncValidator } from "pages/RecipientsPage/Details/Info";
import { default as React } from "react";
import { W9Form } from "store/actions/taxForms";
import { translateRegionByCountry } from "utils/helpers";
import { irsRule, latinRule, taxTypeLabelArray } from "./variables";

function getName(key: keyof W9Form) {
  return ["data", "w9Data", key];
}

export default function W9UploadForm() {
  const form = Form.useFormInstance();
  const country = Form.useWatch(getName("country"), form); // W9 recipient can reside outside of USA.

  return (
    <>
      <Grid.Item xs={24} md={12}>
        <Form.Item label="Name" name={getName("name")} rules={[{ required: true, message: "Enter the Name" }, irsRule]}>
          <Input name="name" />
        </Form.Item>
      </Grid.Item>
      <Grid.Item xs={24} md={12}>
        <Form.Item label="Business Name" name={getName("businessName")} rules={[irsRule]}>
          <Input name="businessName" />
        </Form.Item>
      </Grid.Item>
      <Grid.Item xs={24}>
        <Form.Item label="Country" name={getName("country")} rules={[{ required: true, message: "Enter the Country" }]}>
          <SelectCountry type="all" />
        </Form.Item>
      </Grid.Item>
      <Grid.Item xs={24} md={12}>
        <Form.Item label="Address" name={getName("address")} rules={[{ required: true, message: "Enter the Address" }, latinRule]}>
          <Input name="address" />
        </Form.Item>
      </Grid.Item>
      <Grid.Item xs={24} md={12}>
        <Form.Item label="City" name={getName("city")} rules={[{ required: true, message: "Enter the City" }, latinRule]}>
          <Input name="city" />
        </Form.Item>
      </Grid.Item>

      <Grid.Item xs={24} md={12}>
        <Form.Item
          label={translateRegionByCountry(country)}
          name={getName("state")}
          dependencies={[getName("country")]}
          rules={[{ required: true, message: `Enter the ${translateRegionByCountry(country)}` }, isRegionInCountryAsyncValidator(getName("country"))]}
        >
          <SelectRegion country={country} />
        </Form.Item>
      </Grid.Item>
      <Grid.Item xs={24} md={12}>
        <Form.Item
          label={country === CountryCode.US ? "Zip" : "Postal Code"}
          name={getName("zip")}
          dependencies={[getName("country")]}
          rules={[(form) => ({ required: countryHasPostalCode(form.getFieldValue(getName("country"))), message: "Enter the Zip" }), latinRule]}
        >
          <Input name="zip" />
        </Form.Item>
      </Grid.Item>

      <Grid.Item xs={24} md={12}>
        <Form.Item label="Federal Tax Classification" name={getName("taxType")} rules={[{ required: true, message: "Enter the Classification" }]}>
          <Select
            onChange={(val: string) => {
              if (!["trust", "sole_prop", "llc_single"].includes(val)) {
                form.setFields([{ name: getName("idType"), value: undefined }]);
              }
            }}
          >
            {taxTypeLabelArray.map(([entityType, label]) => (
              <Select.Option key={entityType} value={entityType}>
                {label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Grid.Item>
      <Form.Control dependencies={[getName("taxType"), getName("idType")]}>
        {(form) => {
          const taxType = form.getFieldValue(getName("taxType"));
          const idType = form.getFieldValue(getName("idType"));
          const isSingleEntity = ["trust", "sole_prop", "llc_single"].includes(taxType);
          const tinType = taxType === "individual" || (isSingleEntity && idType === "ssn") ? "SSN" : "EIN";

          return (
            <>
              <Grid.Item xs={24} md={12}>
                <Form.Item
                  label="Tax Identifier Type"
                  name={getName("idType")}
                  dependencies={[getName("taxType")]}
                  rules={[{ required: isSingleEntity, message: "Enter the ID Type" }]}
                >
                  <Select disabled={!isSingleEntity}>
                    {[
                      { label: "Social Security Number (SSN)", value: "ssn" },
                      { label: "Employer Identification Number (EIN)", value: "tin" },
                    ].map((option) => (
                      <Select.Option key={option.value} value={option.value}>
                        {option.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Grid.Item>
              <Grid.Item xs={isSingleEntity ? 24 : 12} key="taxId">
                <Form.Item
                  label={`Taxpayer ID Number (${tinType})`}
                  name={getName("taxId")}
                  dependencies={[getName("taxType"), getName("idType")]}
                  rules={[
                    { required: true, message: "Enter the Taxpayer ID Number" },
                    {
                      async validator(rule: any, value: string) {
                        if (value && value.replace(/[^\d*]/g, "").length < 9) {
                          throw `${tinType} must be 9 digits long`;
                        }
                      },
                    },
                  ]}
                >
                  <InputMask
                    formatChars={{
                      "9": "[0-9*]", // must accept "*" masked char
                    }}
                    style={{ letterSpacing: "1px" }}
                    mask={tinType === "SSN" ? "999-99-9999" : "99-9999999"}
                    name="taxId"
                  />
                </Form.Item>
              </Grid.Item>
            </>
          );
        }}
      </Form.Control>
      <Grid.Item xs={24}>
        <Form.Item
          label="Subject to Withholding"
          name={getName("subjectToWithholding")}
          initialValue
          valuePropName="checked"
          rules={[{ required: true }, { type: "boolean" }]}
          tooltip={
            <>
              The recipient is not subject to backup withholding because:
              <ol type="a">
                <li>they are exempt from backup withholding, or</li>
                <li>
                  they have not been notified by the Internal Revenue Service (IRS) that they are subject to backup withholding as a result of a failure to
                  report all interest or dividends, or
                </li>
                <li>the IRS has notified the recipient that they are no longer subject to backup withholding.</li>
              </ol>
            </>
          }
        >
          <Checkbox>Recipient is subject to a backup withholding of 24%.</Checkbox>
        </Form.Item>

        <Form.Control>
          {({ getFieldsValue }) => {
            return (
              getFieldsValue(getName("subjectToWithholding")) && (
                <Alert type="warning">Payments to this recipient will be subject to a 24% Backup Withholding rate.</Alert>
              )
            );
          }}
        </Form.Control>
      </Grid.Item>
    </>
  );
}
