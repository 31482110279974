import * as types from "@trolley/common-frontend";
import { POST } from "services/request";
import store from "store";
import { OpCode, standardDispatch } from "store/dispatcher";
import { Mapped } from "store/reducers/standardReducer";
import { pick } from "utils/helpers";
import { computeID, isLoaded } from "./actionUtils";

export type UploadFile = types.UploadFile.UploadFile;
export type UploadLine = types.UploadFile.UploadLine;
export type UploadType = types.UploadType;

export type PaymentLine = Omit<UploadLine, "data"> & {
  data: {
    sourceAmount?: string;
    sourceCurrency?: types.CurrencyCode | null;
    amount?: string;
    currency?: types.CurrencyCode;
    forceUsTaxActivity?: boolean;
    coverFees?: boolean;
    taxReportable?: boolean;
    category?: types.PaymentCategory;
    memo?: string | null;
    externalId?: string | null;
    recipientId?: string; // present if recipient found
    tags?: null | string[] | string;
    recipient: {
      // data provided by CSV
      email: string | null;
      id: string | null;
      referenceId: string | null;
    };
  };
};
export type PaymentFile = Omit<UploadFile, "lines"> & {
  lines: PaymentLine[];
};

export type OfflineUploadLine = Omit<types.UploadFile.UploadLine, "data"> & {
  data: {
    id?: string; // user recipient ID or reference ID submitted by the merchant CSV
    email?: string; // recipient email from the CSV
    recipientId?: string; // recipientID if a recipient exists
    amount: string;
    currency: string;
    processedAt: string;
    payoutMethod?: types.PaymentTaxesPayoutMethods;
    calculateBox11?: boolean;
    category?: types.PaymentCategory;
    taxReportable: boolean;
    forceUsTaxActivity: boolean;
    withholdingAmount: string;
    withholdingCurrency: string;
    externalId?: string; // external payment reference Id
    memo?: string;
    tags?: string;
    referenceId?: string;
  };
};

export type DAC7OfflineUploadLine = Omit<types.UploadFile.UploadLine, "data"> & {
  data: {
    id?: string; // user recipient ID or reference ID submitted by the merchant CSV
    email?: string; // recipient email from the CSV
    recipientId?: string; // recipientID if a recipient exists
    enteredAmount?: string;
    enteredCurrency?: string;
    processedAt: string;
    payoutMethod?: types.PaymentTaxesPayoutMethods;
    category?: types.PaymentCategory;
    taxReportable: boolean;
    externalId?: string; // external payment reference Id
    memo?: string;
    tags?: string;
    referenceId?: string;
    feeAmount?: string;
    feeCurrency?: string;
    taxAmount?: string;
    taxCurrency?: string;
    relevantActivities?: string;
  };
};

export type OfflineUploadFile = Omit<types.UploadFile.UploadFile, "lines"> & {
  lines: OfflineUploadLine[];
};

export type FileListQuery = {
  page?: number;
  pageSize?: number;
};

export function loadFile(fileId: string, force?: boolean) {
  const { files } = store.getState();
  if (force || !isLoaded(files.fetchStatus[fileId])) {
    standardDispatch(OpCode.LOADING, "files", { id: fileId });
    POST<{ upload: types.UploadFile.UploadFile }>("/v1/files/get", {
      query: {
        id: fileId,
        includeLines: true,
        includeStats: true,
      },
    })
      .then(({ body }) => {
        standardDispatch(OpCode.DATA, "files", {
          id: fileId,
          data: body.upload,
        });
      })
      .catch((errors) => {
        standardDispatch(OpCode.ERROR, "files", {
          id: fileId,
          errors,
        });
      });
  }
}

export function loadFileStats(fileId: string) {
  standardDispatch(OpCode.LOADING, "files", { id: fileId });
  POST<{ upload: types.UploadFile.UploadFile }>("/v1/files/get", {
    query: {
      id: fileId,
      includeLines: false,
      includeStats: true,
    },
  })
    .then(({ body }) => {
      standardDispatch<UploadFile>(OpCode.UPDATE, "files", {
        id: fileId,
        update: pick(body.upload, ["stats", "updatedAt", "status", "startedAt", "completedAt"]),
      });
    })
    .catch((errors) => {
      standardDispatch(OpCode.ERROR, "files", {
        id: fileId,
        errors,
      });
    });
}

export function importFile(fileId: string) {
  standardDispatch(OpCode.LOADING, "files", { id: fileId });
  POST<{ upload: types.UploadFile.UploadFile }>("/v1/files/start-processing", {
    query: {
      id: fileId,
    },
  })
    .then(({ body }) => {
      standardDispatch<UploadFile>(OpCode.UPDATE, "files", {
        id: fileId,
        update: pick(body.upload, ["updatedAt", "status", "startedAt"]),
      });
    })
    .catch((errors) => {
      standardDispatch(OpCode.ERROR, "files", {
        id: fileId,
        errors,
      });
    });
}

export function loadFileList(type: UploadType, query: FileListQuery, force?: boolean) {
  const id = computeID({ ...query, type });
  const { fileList } = store.getState();

  if (force || !isLoaded(fileList.fetchStatus[id])) {
    standardDispatch(OpCode.LOADING, "fileList", { id });
    POST<types.UploadFile.ListResult>("/v1/files/search", {
      query: {
        ...query,
        type,
      },
    })
      .then(({ body }) => {
        const files: Mapped<UploadFile> = {};
        const ids = body.uploads.map((file) => {
          files[file.id] = file;

          return file.id;
        });
        standardDispatch(OpCode.DATA, "files", {
          bulk: files,
        });

        standardDispatch(OpCode.DATA, "fileList", {
          id,
          data: {
            records: ids,
            meta: body.meta,
          },
        });
      })
      .catch((errors) => {
        standardDispatch(OpCode.ERROR, "fileList", {
          id,
          errors,
        });
      });
  }

  return id;
}

export async function fileUpload(type: UploadType, file: FormData) {
  try {
    standardDispatch(OpCode.LOADING, "files");
    file.set("type", type);

    const { body } = await POST<types.UploadFile.Result>("/v1/files/create", {
      body: file,
      isUpload: true,
    });
    const { id } = body.upload;
    standardDispatch(OpCode.RESET, "fileList");
    standardDispatch(OpCode.DATA, "files", {
      id,
      data: body.upload,
    });

    return id;
  } catch (errors) {
    standardDispatch(OpCode.ERROR, "files", {
      errors,
    });
    throw errors;
  }
}
