import React, { HTMLAttributes } from "react";

interface Props extends Omit<HTMLAttributes<HTMLAnchorElement>, "onClick"> {
  href: string | undefined;
}

export default function ExternalLink(props: Props) {
  if (props.href) {
    return (
      <a
        role="link"
        rel="noopener noreferrer"
        target="_blank"
        tabIndex={-1}
        onClick={(e) => {
          // to prevent event bubbling when component is inside another "clickable" component. ie. table
          e?.stopPropagation?.();
        }}
        {...props}
      >
        {props.children}
      </a>
    );
  }

  return <span>{props.children}</span>;
}
