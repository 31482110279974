import { Tag as AntTag, TagProps as AntTagProps } from "antd";
import { Icon, Spinner } from "components";
import React, { CSSProperties, ReactNode, useState } from "react";
import css, { createUseStyle } from "style/classname";
import { ANIMATION } from "style/variables";
import InputTags from "./InputTags";
import New from "./New";

const useStyledTag = createUseStyle<TagProps>(({ theme, ...props }) =>
  css`
    &.${theme.prefixCls}-tag {
      animation: ${ANIMATION.flipInY} 0.25s cubic-bezier(0.5, 1.5, 0.4, 1);
      position: relative;
      border-radius: 10px;
      border: 0;
      cursor: ${props.onClick ? "pointer" : "default"};
    }
  `(),
);
export type TagProps = {
  children: ReactNode;
  style?: CSSProperties;
  onDelete?: (() => Promise<void> | void) | undefined;
  onClick?: AntTagProps["onClick"];
};

export default function Tag(props: TagProps) {
  const [busy, setBusy] = useState(false);
  const { onDelete, children, style, onClick } = props;
  const styledTag = useStyledTag(props);
  async function onClose(e: any) {
    if (!busy && onDelete) {
      try {
        setBusy(true);
        await onDelete();
      } catch (errors) {
        //
      }
      setBusy(false);
    }
  }

  return (
    <AntTag
      style={style}
      className={styledTag}
      closable={!!onDelete && !busy}
      onClose={onClose}
      onClick={onClick}
      closeIcon={<Icon type="xmark" color="black" />}
    >
      {children}
      {busy && <Spinner right />}
    </AntTag>
  );
}

Tag.New = New;
Tag.Input = InputTags;
