import { Collapse } from "antd";
import { Button, ButtonDelete, ButtonLink, Divider, Form, Icon, Space, Status, Text } from "components";
import { getDirectorLabel } from "pages/BusinessProfile/SigningOfficer";
import React, { useEffect, useState } from "react";
import { notifyError } from "store/actions/notifications";
import { OnboardingPerson, createOnboardingPerson, deleteOnboardingPerson } from "store/actions/onboardingPersons";
import { useOnboarding } from "store/hooks/onboarding";
import styled, { ThemedToken, useThemeToken } from "style/classname";
import { BANK_ONBOARDING_PATH, BankOnboardingPages, OnboardingSection } from ".";
import PersonForm, { PersonFormField } from "./PersonForm";
import { isPersonComplete } from "./variables";

function validateSigningOfficers(directors: OnboardingPerson[], directorTitle: string): string | null {
  if (!directors.length) {
    return `Please add a ${directorTitle}`;
  }

  if (directors.some((p) => !isPersonComplete(p, "directors"))) {
    return `One or more ${directorTitle}s are incomplete `;
  }

  return null;
}

export default function SigningOfficer() {
  const [activeKey, setActiveKey] = useState<string>();
  const [form] = Form.useForm();
  const { data: onboarding } = useOnboarding();
  const token = useThemeToken();
  const signingOfficers = (onboarding?.persons ?? []).filter((person) => person.isDirector);

  useEffect(() => {
    form.resetFields();
    form.validateFields([PersonFormField.DIRECTORS]).catch(() => {
      //
    });
  }, [onboarding?.persons]);

  async function onAddDirector() {
    try {
      const director = await createOnboardingPerson({ type: "individual", isDirector: true });
      setActiveKey(director.id);
    } catch (errors) {
      notifyError("Creating Signing Officer failed");
    }
  }

  const signingLabel = getDirectorLabel(onboarding);
  const directorTitle = signingLabel === "Partnership" ? "Partner" : signingLabel;
  const error = validateSigningOfficers(signingOfficers, directorTitle);

  return (
    <OnboardingSection
      title={`${signingLabel}s Details`}
      description={
        <>
          Please enter the home address of the person(s) legally authorized to act on behalf of the business, as it appears in public records. A {signingLabel}{" "}
          is a person legally authorized to act on behalf of the business (e.g. Board Director, Officer, CEO, CFO, COO).
        </>
      }
    >
      <Collapse
        ghost
        activeKey={activeKey}
        accordion
        onChange={(key) => {
          if (typeof key === "string") {
            setActiveKey(key);
          } else if (typeof key[0] === "string") {
            setActiveKey(key[0]);
          }
        }}
        items={signingOfficers.map((director, index) => ({
          key: director.id,
          className: styledCollapse({ hidden: !!activeKey && activeKey !== director.id, token }),
          label: (
            <Text size="large">
              {directorTitle} #{index + 1}: {[director?.firstName, director?.lastName].filter((v) => v).join(" ")}
            </Text>
          ),
          showArrow: false,
          extra: (
            <Space gap="small" wrap={false}>
              {!isPersonComplete(director, PersonFormField.DIRECTORS) && <Status type="incomplete" />}
              {activeKey === director.id ? (
                <Button
                  onClick={() => {
                    setActiveKey(undefined);
                  }}
                  tooltipProps={{ title: "Close" }}
                  type="text"
                  icon={<Icon type="times" />}
                />
              ) : (
                <>
                  <Button
                    size="small"
                    icon={<Icon type="pencil" />}
                    tooltipProps={{ title: "Edit" }}
                    onClick={() => {
                      setActiveKey(director.id);
                    }}
                  />
                  <ButtonDelete
                    confirmType="modal"
                    tooltipProps={{ title: "Delete" }}
                    title={`Do you want to delete ${[director.firstName, director.lastName].join(" ")}?`}
                    size="small"
                    onConfirm={async () => {
                      await deleteOnboardingPerson([director.id]);
                    }}
                  />
                </>
              )}
            </Space>
          ),
          children: (
            <PersonForm
              person={director}
              personLabel={signingLabel}
              onboardingType={PersonFormField.DIRECTORS}
              noOwnership={onboarding?.noOwnership}
              onClose={() => {
                setActiveKey(undefined);
              }}
            />
          ),
        }))}
      />

      {!activeKey && (
        <>
          <Button onClick={onAddDirector} icon={<Icon type="plus" />}>
            Add {signingOfficers.length > 0 ? "another" : "a"} {directorTitle}
          </Button>
          <Divider transparent margin="small" />
          <Text type="error">{error}</Text>
        </>
      )}
      <Divider margin="medium" transparent />
      {!activeKey && (
        <ButtonLink
          size="large"
          block
          type="primary"
          path={[BANK_ONBOARDING_PATH, onboarding?.noOwnership ? BankOnboardingPages.SHARE_REGISTER : BankOnboardingPages.BENEFICIAL_OWNERS].join("/")}
        >
          Continue
          <Icon type="angle-right" />
        </ButtonLink>
      )}
    </OnboardingSection>
  );
}

export const styledCollapse = styled<{ token: ThemedToken; hidden: boolean }>`
    ${({ token, hidden }) => {
      const prefixCls = token.prefixCls;

      return `
        &.${prefixCls}-collapse-item.${prefixCls}-collapse-item {
          border-radius: ${token.borderRadius}px !important;
          border: 1px solid ${token.colorBorder};
          border-bottom: 1px solid ${token.colorBorder};
          transition: all 0.2s ease;
          margin-bottom: 16px;
          opacity: ${hidden ? "0.4" : "1"};
          &:hover {
            opacity: 1;
          }
          .${prefixCls}-collapse-header {
            align-items: center;

          }
          &:not(.${prefixCls}-collapse-item-active) {
            background-color:  ${token.colorFillTertiary};
            &:hover {
              background-color:  ${token.controlItemBgHover};
            }
          }
          &.${prefixCls}-collapse-item-active {
            border-width: 0px;
            padding: 0px;
            .${prefixCls}-collapse-header {
              cursor: auto;
              padding-left: 0;
              padding-right: 0;
              font-weight: bold;
            }
          }
          .${prefixCls}-collapse-content-box {
            padding: 0;
          }
        }

      `;
    }}

`;
