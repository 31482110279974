import { Tabs } from "antd";
import React from "react";
import { Redirect, Route, Switch, useHistory, useParams } from "react-router-dom";
import { DAC7TaxMerchantYear } from "store/actions/dac7TaxMerchantYear";
import { Box, Button, Container, DateDisplay, Dropdown, Heading, Icon, Menu, Text } from "components";
import MerchantDAC7TaxInfo from "pages/TaxCenter/EndOfYear/DAC7/MerchantDAC7TaxInfo";
import ReviewEarnings from "pages/TaxCenter/EndOfYear/DAC7/ReviewEarnings";
import css, { createUseStyle } from "style/classname";
import { TAX_PATHS, DAC7_TAX_YEARS, DAC7_INTRO_YEAR } from "../..";
import DAC7Statement from "./DAC7Statement";
import dayjs from "dayjs";

export enum Pages {
  EARNINGS = "review-earnings",
  DELIVERYDAC7 = "dac7-statements",
  PROFILE = "business-tax-profile",
}

export function getDAC7LastYear() {
  return Math.max(dayjs().subtract(1, "year").year(), DAC7_INTRO_YEAR);
}

// /**
//  *
//  * @param taxMerchantYear
//  * @param eFiling for downloading the FIRE E-File format
//  */
export function isProfileComplete(dac7TaxMerchantYear?: DAC7TaxMerchantYear): boolean {
  if (!dac7TaxMerchantYear) {
    return false;
  }

  return dac7TaxMerchantYear.complete;
}

// const QUERIES: EoyTaxQuery[] = [
//   // inReview:
//   {
//     pageSize: 0,
//     status: [EoyTaxReportStatus.NEEDS_REVIEW, EoyTaxReportStatus.HOLD],
//     requireTaxForm: true,
//   },
//   // inReview1099:
//   {
//     pageSize: 0,
//     formTypes: [EndOfYearFormType.F1099],
//     status: [EoyTaxReportStatus.NEEDS_REVIEW, EoyTaxReportStatus.HOLD],
//     requireTaxForm: true,
//   },
//   // inProgress1099:
//   {
//     pageSize: 0,
//     formTypes: [EndOfYearFormType.F1099],
//     status: [EoyTaxReportStatus.APPROVED, EoyTaxReportStatus.SENT],
//     requireIrsSubmittedAt: false,
//   },
//   // completed1099:
//   {
//     pageSize: 0,
//     formTypes: [EndOfYearFormType.F1099],
//     status: [EoyTaxReportStatus.SENT, EoyTaxReportStatus.VOID],
//     requireIrsSubmittedAt: true,
//   },

//   // inReview1099-K:
//   {
//     pageSize: 0,
//     formTypes: [EndOfYearFormType.F1099_K],
//     status: [EoyTaxReportStatus.NEEDS_REVIEW, EoyTaxReportStatus.HOLD],
//     // requireTaxForm: true,
//   },
//   // inProgress1099-K:
//   {
//     pageSize: 0,
//     formTypes: [EndOfYearFormType.F1099_K],
//     status: [EoyTaxReportStatus.APPROVED, EoyTaxReportStatus.SENT],
//     requireIrsSubmittedAt: false,
//   },
//   // completed1099-K:
//   {
//     pageSize: 0,
//     formTypes: [EndOfYearFormType.F1099_K],
//     status: [EoyTaxReportStatus.SENT, EoyTaxReportStatus.VOID],
//     requireIrsSubmittedAt: true,
//   },

//   // inReview1099NEC:
//   {
//     pageSize: 0,
//     formTypes: [EndOfYearFormType.F1099_NEC],
//     status: [EoyTaxReportStatus.NEEDS_REVIEW, EoyTaxReportStatus.HOLD],
//     requireTaxForm: true,
//   },
//   // inProgress1099NEC:
//   {
//     pageSize: 0,
//     formTypes: [EndOfYearFormType.F1099_NEC],
//     status: [EoyTaxReportStatus.APPROVED, EoyTaxReportStatus.SENT],
//     requireIrsSubmittedAt: false,
//   },
//   // completed1099NEC:
//   {
//     pageSize: 0,
//     formTypes: [EndOfYearFormType.F1099_NEC],
//     status: [EoyTaxReportStatus.SENT, EoyTaxReportStatus.VOID],
//     requireIrsSubmittedAt: true,
//   },

//   // inReview1042:
//   {
//     pageSize: 0,
//     formTypes: [EndOfYearFormType.F1042],
//     status: [EoyTaxReportStatus.NEEDS_REVIEW, EoyTaxReportStatus.HOLD],
//     requireTaxForm: true,
//   },
//   // inProgress1042:
//   {
//     pageSize: 0,
//     formTypes: [EndOfYearFormType.F1042],
//     status: [EoyTaxReportStatus.APPROVED, EoyTaxReportStatus.SENT],
//     requireIrsSubmittedAt: false,
//   },
//   // completed1042:
//   {
//     pageSize: 0,
//     formTypes: [EndOfYearFormType.F1042],
//     status: [EoyTaxReportStatus.SENT, EoyTaxReportStatus.VOID],
//     requireIrsSubmittedAt: true,
//   },
// ];

export function getDueDate(taxYear: number) {
  const currentYear = taxYear + 1;
  // single due date

  return `${currentYear}-01-31`;
}

export default function EndOfYearPage() {
  const styledTabs = useStyledTabs();
  const {
    push,
    replace,
    location: { pathname, search },
  } = useHistory();
  const params = useParams<{ taxYear?: string }>();
  const taxYear = Number(params.taxYear);
  // const { data: merchantTaxYear } = useMerchantTaxYear(taxYear);
  // const { data: eoySummary, status: summaryStatus } = useEoySummary(QUERIES.map((q) => ({ ...q, taxYear })));
  // const [
  //   inReviewCount,
  // ] = eoySummary;
  // const isBusinessProfileComplete = isProfileComplete(merchantTaxYear);
  const isBusinessProfileComplete = false;
  const baseUrl = `${TAX_PATHS.EOY_REPORTING_DAC7}/${taxYear}`;
  const basePath = `${TAX_PATHS.EOY_REPORTING_DAC7}/:taxYear(\\d{4})`;

  if (Number.isNaN(taxYear)) {
    replace({ pathname: [TAX_PATHS.EOY_REPORTING_DAC7, getDAC7LastYear()].join("/") });
  }

  return (
    <Container>
      <Heading>
        End of Year Review &amp; Filing for&nbsp;
        <Dropdown
          overlay={
            <Menu
              onClick={(e) => {
                const newTaxYear = e.key;
                push({
                  pathname: pathname.replace(String(taxYear), newTaxYear),
                  search,
                });
              }}
            >
              {DAC7_TAX_YEARS.map((year) => {
                return (
                  <Menu.Item key={year} disabled={taxYear === year}>
                    Tax Year {year}
                  </Menu.Item>
                );
              })}
            </Menu>
          }
        >
          <Button type="text" size="large" style={{ fontSize: "inherit", lineHeight: 1 }}>
            Tax Year {taxYear}
            <Icon type="angle-down" right />
          </Button>
        </Dropdown>
      </Heading>

      <Tabs
        type="card"
        activeKey={pathname}
        onChange={(id: Pages) => {
          push(id, {
            scrollToTop: false,
          });
        }}
        className={styledTabs}
      >
        <Tabs.TabPane
          key={`${baseUrl}/${Pages.EARNINGS}`}
          tab={
            <div>
              Review Earnings
              <Icon.Status type="success" right />
              {/* {summaryStatus === BaseStatus.LOADED &&
                (inReviewCount > 0 ? <Badge count={inReviewCount} offset={[4, -6]} /> : <Icon.Status type="success" right />)} */}
            </div>
          }
        />
        {taxYear >= DAC7_INTRO_YEAR && (
          <Tabs.TabPane
            key={`${baseUrl}/${Pages.DELIVERYDAC7}`}
            tab={
              <div>
                DAC7 Reporting
                <Icon.Status type="success" right />
                {/* {summaryStatus === BaseStatus.LOADED &&
                  (incomplete1099KCount > 0 ? <Badge count={incomplete1099KCount} offset={[4, -6]} /> : <Icon.Status type="success" right />)} */}
                <Text size="small" type="secondary">
                  Due <DateDisplay value={getDueDate(taxYear)} time={false} icon={false} />
                </Text>
              </div>
            }
          />
        )}
        <Tabs.TabPane
          key={`${baseUrl}/${Pages.PROFILE}`}
          tab={
            <div>
              Business Tax Profile
              {isBusinessProfileComplete && <Icon.Status type="success" right />}
            </div>
          }
        />
      </Tabs>

      <Box className={styledTabContent}>
        <Switch>
          <Route path={`${basePath}/${Pages.DELIVERYDAC7}`} render={() => <DAC7Statement />} />

          <Route path={`${basePath}/${Pages.PROFILE}`} component={MerchantDAC7TaxInfo} />
          <Route path={`${basePath}/${Pages.EARNINGS}`} component={ReviewEarnings} />
          <Redirect to={`${basePath}/${Pages.EARNINGS}`} />
        </Switch>
      </Box>
    </Container>
  );
}

const useStyledTabs = createUseStyle(({ theme }) =>
  css`
    &.${theme.prefixCls}-tabs {
      .${theme.prefixCls}-tabs-nav {
        margin-bottom: 0;
        &::before {
          display: none;
        }
        .${theme.prefixCls}-tabs-nav-wrap {
          .${theme.prefixCls}-tabs-tab {
            align-items: flex-start;
            border-radius: 8px 8px 0 0;
            border-width: 0px;
            box-shadow: ${theme.boxShadowTertiary};
          }
        }
      }
    }
  `(),
);

const styledTabContent = css`
  &.box {
    border-top-left-radius: 0;
    margin-top: 0;
  }
`();
