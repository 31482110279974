import {
  addressMappedCountries,
  allMappedCountries,
  bankingMappedCountries,
  CountryCode,
  MappedCountry,
  onboardingMappedCountries,
} from "@trolley/common-frontend";
import { Flag, Select, SelectProps } from "components";
import React from "react";
import { useThemeToken } from "style/classname";

const DEFAULT_TOP_COUNTRIES = [CountryCode.AU, CountryCode.CA, CountryCode.GB, CountryCode.US];

const sortCountries = (areas: MappedCountry, topCountries: CountryCode[] = DEFAULT_TOP_COUNTRIES) => {
  const keys = Object.keys(areas);
  if (!keys.length) {
    return [];
  }
  try {
    const sortedArray = keys.map((key) => ({
      label: areas[key].name,
      value: key as CountryCode,
    }));

    sortedArray.sort((a, b) => {
      if (topCountries.includes(a.value) && topCountries.includes(b.value)) {
        return topCountries.indexOf(a.value) < topCountries.indexOf(b.value) ? -1 : 1;
      }

      if (topCountries.includes(a.value)) {
        return -1;
      }
      if (topCountries.includes(b.value)) {
        return 1;
      }

      const nameA = a.label.toLocaleUpperCase();
      const nameB = b.label.toLocaleUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      return 0;
    });

    return sortedArray;
  } catch {
    return [];
  }
};

export const allCountriesOptions = sortCountries(allMappedCountries);
export const onboardingCountriesOptions = sortCountries(onboardingMappedCountries);
export const addressCountriesOptions = sortCountries(addressMappedCountries);
export const bankingCountriesOptions = sortCountries(bankingMappedCountries);

function getCountrySet({ type, includes, excludes, sort, topCountries }: ExtraProps) {
  let countrySet: CountryOption[];

  switch (type) {
    case "all":
      countrySet = sortCountries(allMappedCountries, topCountries);
      break;
    case "address":
      countrySet = sortCountries(addressMappedCountries, topCountries);
      break;
    case "onboarding":
      countrySet = sortCountries(onboardingMappedCountries, topCountries);
      break;
    default:
      countrySet = sortCountries(bankingMappedCountries, topCountries);
      break;
  }

  if (includes || excludes) {
    countrySet = countrySet.filter(
      (option) => (!includes?.length || includes.includes(option.value)) && (!excludes?.length || !excludes.includes(option.value)),
    );
  }

  if (sort) {
    countrySet.sort(sort);
  }

  return countrySet;
}

type CountryOption = { label: any; value: CountryCode };

interface ExtraProps {
  type?: "all" | "address" | "onboarding" | "banking"; // defaults to accepted countries
  includes?: CountryCode[];
  excludes?: CountryCode[];
  sort?(a: CountryOption, b: CountryOption): number;
  topCountries?: CountryCode[];
}

interface Props extends Omit<SelectProps<CountryCode[] | CountryCode>, "children" | "options">, ExtraProps {}

export default function SelectCountry(props: Props) {
  const {
    type = "banking",
    includes,
    excludes,
    sort,
    showSearch = true,
    placeholder = "Select a country",
    value,
    topCountries = DEFAULT_TOP_COUNTRIES,
    ...rest
  } = props;
  const theme = useThemeToken();
  const includedTopCountries = topCountries.filter((c) => (!includes || includes.includes(c)) && !excludes?.includes(c));

  return (
    <Select
      data-testid="select-country"
      showSearch={showSearch}
      placeholder={placeholder}
      optionFilterProp="title"
      value={value || undefined}
      options={getCountrySet({ type, includes, excludes, sort, topCountries }).map((country, i) => ({
        value: country.value,
        title: `${country.value} ${country.label}`,
        style: i === includedTopCountries.length - 1 ? { borderBottom: `2px solid ${theme.colorSplit}` } : undefined,
        label: <Flag code={country.value} />,
      }))}
      {...rest}
    />
  );
}
