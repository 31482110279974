import { TaxForm } from "@trolley/common-frontend";
import { useEffect } from "react";
import { loadWithholdingTable } from "store/actions/withholdingTable";
import { useShallowSelector } from ".";

export function useWithholdingTable() {
  useEffect(() => {
    loadWithholdingTable();
  }, []);

  return useShallowSelector((state) => {
    if (state.withholdingTable.fetchStatus.data === undefined) {
      loadWithholdingTable();
    }

    return {
      data: state.withholdingTable.entities.data || ({} as Partial<TaxForm.W8UsWithholdingTable>),
      status: state.withholdingTable.fetchStatus.data,
      error: state.withholdingTable.errors.data,
    };
  });
}
