import { Status, StatusProps } from "components";
import React from "react";
import { InvoiceStatus } from "store/actions/invoices";

interface Props extends Omit<StatusProps, "type" | "colors"> {
  type: InvoiceStatus | "deleted";
}

export default function StatusInvoice({ type, ...props }: Props) {
  return (
    <Status
      type={type}
      {...props}
      colors={{
        [InvoiceStatus.OPEN]: "yellow",
        [InvoiceStatus.PAID]: "green",
        [InvoiceStatus.PENDING]: "blue",
        [InvoiceStatus.PARTIAL_PAID]: "blue",
      }}
    />
  );
}
