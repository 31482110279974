import { useEffect } from "react";
import { loadIframeConfig } from "store/actions/iframeConfig";
import { useShallowSelector } from ".";

export function useIframeConfig() {
  useEffect(() => {
    loadIframeConfig();
  }, []);

  return useShallowSelector((state) => {
    if (state.iframeConfig.fetchStatus.data === undefined) {
      loadIframeConfig();
    }

    return {
      data: state.iframeConfig.entities.data,
      status: state.iframeConfig.fetchStatus.data,
      error: state.iframeConfig.errors.data,
    };
  });
}
