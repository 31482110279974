import { RcFile } from "antd/lib/upload";
import { Button, UploadDragger } from "components";
import { downloadPOSTFile } from "components/FileDownload";
import React, { useEffect, useState } from "react";
import { notifyError, notifySuccess } from "store/actions/notifications";
import { Onboarding, removeOnboardingFile, uploadOnboardingFileV2 } from "store/actions/onboarding";
import { useOnboarding } from "store/hooks/onboarding";
import { BaseStatus } from "store/reducers/standardReducer";
import { createFormData } from "utils/helpers";
import { FormNames } from ".";
import OnboardingSection from "./OnboardingSection";
import { mapToUploadFile } from "./variables";

function getAdditionalDocs(onboarding: Onboarding | undefined) {
  return Object.values(onboarding?.files || {})
    .filter((file) => file.type === "additionalDoc")
    .map(mapToUploadFile);
}

export default function AdditionalDoc() {
  const { data: onboarding, status: onboardingStatus } = useOnboarding();
  const [additionalDocs, setAdditionalDocs] = useState(getAdditionalDocs(onboarding));

  useEffect(() => {
    if (onboarding) {
      setAdditionalDocs(getAdditionalDocs(onboarding));
    }
  }, [onboarding]);

  return (
    <OnboardingSection
      id={FormNames.ADDITIONAL_DOC}
      title="Additional Documentation"
      description="In some cases, after submitting the forms, you will be asked to upload additional documents. This is where you would do so."
    >
      <UploadDragger
        fileList={additionalDocs}
        beforeUpload={(rcFile) => {
          setAdditionalDocs((state) => [
            ...state,
            {
              name: rcFile.name,
              status: "uploading",
              uid: rcFile.uid,
              size: rcFile.size,
            },
          ]);
        }}
        customRequest={async (options) => {
          const file = options.file as RcFile;
          try {
            await uploadOnboardingFileV2(createFormData([file], { type: "additionalDoc" }));
            notifySuccess("Document Uploaded");
          } catch (err) {
            options.onError?.(err);
          }
        }}
        onRemove={async (file) => {
          const uploadId = file.url && file.uid;
          if (uploadId) {
            try {
              await removeOnboardingFile(uploadId);
              notifySuccess("Document removed");
            } catch (errors) {
              notifyError("Uploaded Document failed");

              return false;
            }
          }

          return true;
        }}
        onDownload={async (file) => {
          const uploadId = file.url && file.uid;
          if (uploadId) {
            try {
              await downloadPOSTFile("/v1/onboarding/documents/download", { uploadId }, file.name);
            } catch {
              notifyError("Download failed");
            }
          }
        }}
        loading={onboardingStatus === BaseStatus.LOADING}
      >
        <Button>Download</Button>
      </UploadDragger>
    </OnboardingSection>
  );
}
