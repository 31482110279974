import { PopconfirmProps as AntPopconfirmProps, Popconfirm as PopconfirmAnt } from "antd";
import { Stop } from "components";
import React from "react";
import styled, { classnames } from "style/classname";

export interface PopconfirmProps extends AntPopconfirmProps {}

export default function Popconfirm(props: PopconfirmProps) {
  const { okButtonProps, className, cancelButtonProps, ...rest } = props;

  return (
    <Stop>
      <PopconfirmAnt
        destroyTooltipOnHide
        overlayClassName={classnames(styledOverlay, className)}
        okButtonProps={{
          size: "middle",
          ...okButtonProps,
        }}
        cancelButtonProps={{
          size: "middle",
          ...cancelButtonProps,
        }}
        {...rest}
      />
    </Stop>
  );
}

const styledOverlay = styled`
[class*="-popover-buttons"] {
  white-space: nowrap;
  clear: both;
}
`();
