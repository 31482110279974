import React from "react";
import Button, { ButtonProps } from "components/Button";
import { useHistory } from "react-router-dom";
import { LocationDescriptor, Path } from "history";

export type ButtonLinkProps = Omit<ButtonProps, "onClick"> & {
  path: Path | LocationDescriptor;
};

export default function ButtonLink(props: ButtonLinkProps) {
  const { path, ...btnProps } = props;
  const history = useHistory();

  return (
    <Button
      {...btnProps}
      href={typeof path === "string" ? path : path?.pathname}
      onClick={(e) => {
        e?.preventDefault?.();
        e?.stopPropagation?.(); // to stop progagation on a table row, which may have a onRowClick
        history.push(path);
      }}
    />
  );
}
