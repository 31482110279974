import { getPayoutMethodLabel, PaymentTaxesPayoutMethods, PayoutMethodType } from "@trolley/common-frontend";
import InteracLogo from "assets/images/interac.svg";
import VenmoLogo from "assets/images/venmo.svg";
import { Icon } from "components";
import { IconProps } from "components/Icon";
import React from "react";

type Props = {
  value: string | null | undefined;
  showLabel?: boolean;
  showTooltip?: boolean;
  style?: React.CSSProperties;
} & Omit<IconProps, "type">;

const IMG_SIZE: Record<"xsmall" | "small" | "large" | "xlarge" | "3x" | "4x" | "5x" | "6x" | "7x" | "8x" | "9x" | "10x", string> = {
  xsmall: "0.75em",
  small: "0.875em",
  large: "1.3333em",
  xlarge: "2em",
  "3x": "3em",
  "4x": "4em",
  "5x": "5em",
  "6x": "6em",
  "7x": "7em",
  "8x": "8em",
  "9x": "9em",
  "10x": "10em",
};
export default function PayoutMethodDisplay(props: Props) {
  const { value, showTooltip, showLabel, style, ...rest } = props;

  if (!value) {
    return null;
  }

  let icon = null;
  const label = getPayoutMethodLabel(value, true);
  const iconProps: Partial<IconProps> = {
    ...rest,
    left: rest.left ?? !!showLabel,
    tooltip: showTooltip ? label : undefined,
  };

  if (value === PayoutMethodType.BANKTRANSFER) {
    icon = <Icon {...iconProps} type="bank" />;
  }

  if (value === PayoutMethodType.PAYPAL) {
    icon = <Icon {...iconProps} theme="brands" type="paypal" />;
  }

  if (value === PayoutMethodType.CHECK) {
    icon = <Icon {...iconProps} type="money-check-dollar-pen" />;
  }

  if (value === PayoutMethodType.BITCOIN) {
    icon = <Icon {...iconProps} theme="brands" type="bitcoin" />;
  }

  if (value === PayoutMethodType.INTERAC) {
    icon = (
      <div
        style={{
          // verticalAlign: "baseline",
          marginBottom: "-0.15em",
          display: "inline-block",
          borderRadius: "2px",
          height: iconProps.size ? IMG_SIZE[iconProps.size] : "1em",
          width: iconProps.size ? IMG_SIZE[iconProps.size] : "1em",
          backgroundImage: `url(${InteracLogo})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          marginLeft: iconProps.right ? "6px" : "0px",
          marginRight: iconProps.left || showLabel ? "6px" : "0px",
          ...style,
        }}
      />
    );
  }

  if (value === PaymentTaxesPayoutMethods.MOBILE) {
    icon = <Icon {...iconProps} type="mobile-screen-button" />;
  }

  if (value === PaymentTaxesPayoutMethods.DEBIT_CARD) {
    icon = <Icon {...iconProps} type="credit-card-front" />;
  }

  if (value === PaymentTaxesPayoutMethods.OTHER) {
    icon = <Icon {...iconProps} type="coins" />;
  }

  if (value === PayoutMethodType.VENMO) {
    icon = (
      <div
        style={{
          marginBottom: "-0.15em",
          display: "inline-block",
          borderRadius: "2px",
          height: iconProps.size ? IMG_SIZE[iconProps.size] : "1em",
          width: iconProps.size ? IMG_SIZE[iconProps.size] : "1em",
          backgroundImage: `url(${VenmoLogo})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          marginLeft: iconProps.right ? "6px" : "0px",
          marginRight: iconProps.left || showLabel ? "6px" : "0px",
          ...style,
        }}
      />
    );
  }

  return (
    <>
      {icon}
      {showLabel && label}
    </>
  );
}
