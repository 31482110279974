/* istanbul ignore file */
const loadScript = require("load-script");

module.exports = function () {
  window.satismeter = function () {
    (window.satismeter.q = window.satismeter.q || []).push(arguments);
  };
  window.satismeter.l = 1 * new Date();
  loadScript("https://app.satismeter.com/satismeter.js", () => {
    //
  });
};
