import Input, { InputProps } from "components/Form/Input";
import React from "react";
import ReactInputMask, { Props } from "react-input-mask";

export interface InputMaskProps extends Omit<Props, "size" | "type" | "prefix">, Omit<InputProps, "children"> {}

export default function InputMask({ size, prefix, ...props }: InputMaskProps) {
  return (
    <ReactInputMask {...props}>
      {({ disabled, ...inputProps }: InputProps) => <Input {...inputProps} size={size} prefix={prefix} disabled={props.disabled} />}
    </ReactInputMask>
  );
}
