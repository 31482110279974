import {
  MessageVisibility,
  Ticket as TicketOrig,
  TicketCreateGroup,
  TicketDocumentType,
  TicketListResponse,
  TicketResponse,
  TicketStatus,
} from "@trolley/common-frontend";
import { batch as reduxBatch } from "react-redux";
import * as request from "services/request";
import store from "store";
import { OpCode, standardDispatch } from "store/dispatcher";
import { queueFactory } from "utils/factories";
import { Query } from "utils/hooks";
import { computeID, isLoaded } from "./actionUtils";
import { loadEmailMessages } from "./messages";

export interface Ticket extends TicketOrig {
  parentRecipientId?: null | string;
}

export interface TicketsQuery extends Query<Ticket> {
  id?: string[];
  search?: string;
  paymentId?: string[];
  recipientIds?: string[];
  // assignee?: TicketAssigneeGroup[];
  // createdByGroup?: TicketCreateGroup[];
  status?: TicketStatus[];
  // withMessages?: boolean;
  // visibility: MessageVisibility;
  includeSubmerchantTicket?: boolean; // only for parent merchants with features.syncRecipients
}

export const TicketsQueryArrayKeys = ["id", "paymentId", "recipientIds"];

export function loadAllTickets(rawQuery: Omit<TicketsQuery, "pageSize">, force: boolean = false, records: Ticket[] = []) {
  const { tickets } = store.getState();
  const { page = 1, ...rest } = rawQuery;
  const id = computeID(rest);

  if (force || page !== 1 || !isLoaded(tickets.fetchStatus[id])) {
    const query = { ...rest, withMessages: true, visibility: MessageVisibility.MERCHANT, page, pageSize: 1000 };
    if (page === 1) {
      standardDispatch(OpCode.LOADING, "tickets", { id });
    }

    request
      .POST<TicketListResponse>("/v1/ticket/search", { query })
      .then(({ body }) => {
        const mergedTickets = [...records, ...body.tickets];

        if (page < body.meta.pages) {
          loadAllTickets(query, force, mergedTickets);
        } else {
          const ticketIds: string[] = [];
          const recipientTickets = {};
          const paymentTickets = {};

          const bulkTickets = body.tickets.reduce((acc, ticket: Ticket) => {
            acc[ticket.id] = ticket;
            ticketIds.push(ticket.id);

            if (ticket.paymentId) {
              if (!paymentTickets[ticket.paymentId]) {
                paymentTickets[ticket.paymentId] = [];
              }
              paymentTickets[ticket.paymentId].push(ticket.id);
            }

            const recipientId = ticket.parentRecipientId ?? ticket.recipientId;
            if (recipientId) {
              if (!recipientTickets[recipientId]) {
                recipientTickets[recipientId] = [];
              }
              recipientTickets[recipientId].push(ticket.id);
            }

            return acc;
          }, {});

          reduxBatch(() => {
            standardDispatch(OpCode.DATA, "tickets", {
              id,
              data: {
                records: ticketIds,
                meta: {
                  page: 1,
                  pages: 1,
                  records: ticketIds.length,
                },
              },
            });
            standardDispatch(OpCode.DATA, "ticket", { bulk: bulkTickets });
            standardDispatch(OpCode.DATA, "recipientTickets", { bulk: recipientTickets });
            standardDispatch(OpCode.DATA, "paymentTickets", { bulk: paymentTickets });
          });
        }
      })
      .catch((errors) => {
        standardDispatch(OpCode.ERROR, "tickets", {
          errors,
          id,
        });
      });
  }

  return id;
}

export function loadTickets(rawQuery: TicketsQuery, force?: boolean): string {
  const { tickets } = store.getState();
  const id = computeID(rawQuery);

  if (force || !isLoaded(tickets.fetchStatus[id])) {
    standardDispatch(OpCode.LOADING, "tickets", { id });

    request
      .POST<TicketListResponse>("/v1/ticket/search", { query: { withMessages: true, visibility: MessageVisibility.MERCHANT, ...rawQuery } })
      .then(({ body }) => {
        const bulkTickets = {};

        const records = body.tickets.map((ticket) => {
          bulkTickets[ticket.id] = ticket;

          return ticket.id;
        });

        reduxBatch(() => {
          standardDispatch(OpCode.DATA, "tickets", {
            id,
            data: { records, meta: body.meta },
          });
          standardDispatch(OpCode.DATA, "ticket", {
            bulk: bulkTickets,
          });
        });
      })
      .catch((errors) => {
        standardDispatch(OpCode.ERROR, "tickets", {
          errors,
          id,
        });
      });
  }

  return id;
}

const ticketQueueLoader = queueFactory(
  (ticketIds) => {
    request
      .POST<TicketListResponse>("/v1/ticket/search", {
        query: { withMessages: true, visibility: MessageVisibility.MERCHANT, id: ticketIds, pageSize: ticketIds.length },
      })
      .then(({ body }) => {
        const mappedTickets = body.tickets.reduce((acc, ticket) => {
          acc[ticket.id] = ticket;

          return acc;
        }, {});

        standardDispatch(OpCode.DATA, "ticket", {
          bulk: ticketIds.reduce((acc, id) => {
            acc[id] = mappedTickets[id] ?? undefined;

            return acc;
          }, {}),
        });
      })
      .catch((errors) => {
        standardDispatch(OpCode.ERROR, "ticket", {
          ids: ticketIds,
          errors,
        });
      });
  },
  (id) => /^TK-\w+/.test(id),
);

export function loadTicket(id: string, force?: boolean) {
  const data = store.getState().ticket;

  if (force || !isLoaded(data.fetchStatus[id])) {
    standardDispatch(OpCode.LOADING, "ticket", { id });
    ticketQueueLoader.add(id);
  }
}

export function sendTicketMessage(id: string, text: string) {
  standardDispatch(OpCode.LOADING, "ticket", { id });

  request
    .POST<TicketResponse>("/v1/ticket/message/create", {
      query: { ticketId: id, creatorGroup: TicketCreateGroup.MERCHANT, text, visibility: MessageVisibility.MERCHANT },
    })
    .then(() => {
      standardDispatch(OpCode.LOADING, "ticket", { id, loading: false });
    })
    .catch((errors) => {
      standardDispatch(OpCode.ERROR, "ticket", {
        errors,
        id,
      });
    });
}

export function sendTicketNotification(ticketId: string, recipientId: string) {
  request
    .POST<TicketResponse>("/v1/ticket/notify", { query: { ticketId } })
    .then(() => {
      loadEmailMessages({ recipientId, relatedItemId: ticketId }, true);
    })
    .catch((errors) => {
      standardDispatch(OpCode.ERROR, "ticket", {
        errors,
        id: ticketId,
      });

      throw errors;
    });
}

export async function uploadTicketDocument(id: string, documentType: TicketDocumentType, formData: FormData) {
  formData.set("ticketId", id);
  formData.set("documentType", documentType);

  try {
    standardDispatch(OpCode.LOADING, "ticket", { id });
    await request.POST<TicketResponse>("/v1/ticket/documents", { isUpload: true, body: formData });
    await loadTicket(id, true);
  } catch (errors) {
    standardDispatch(OpCode.ERROR, "ticket", {
      errors,
      id,
    });

    throw errors;
  }
}
