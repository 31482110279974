import { LookupData } from "@trolley/common-frontend";
import { POST } from "services/request";
import store from "store";
import { computeID } from "store/actions/actionUtils";
import { OpCode, standardDispatch } from "store/dispatcher";
import { omitEmpty } from "utils/helpers";
import { Query } from "utils/hooks";

export type BankInfo = LookupData["lookupData"];

export interface BankParameters {
  country?: string | null;
  currency?: string | null;
  iban?: string | null;
  swiftBic?: string | null;
  bankId?: string | null;
  branchId?: string | null;
}

export interface BankQuery extends Query, BankParameters {}

export async function loadBankInfo(query: BankQuery) {
  const id = computeID(query);
  const { bankInfo } = store.getState();
  const data = bankInfo.entities[id];

  if (data) {
    return data;
  }

  try {
    const { body } = await POST<LookupData>("/v1/bank/lookup", { query: omitEmpty(query) });
    standardDispatch(OpCode.DATA, "bankInfo", {
      id,
      data: body.lookupData,
    });

    return body.lookupData;
  } catch (errors) {
    standardDispatch(OpCode.ERROR, "bankInfo", {
      id,
      errors,
    });
    throw errors;
  }
}
