import Input, { InputProps } from "./Input";
import React, { useEffect, useState } from "react";

export type InputNumberProps = Omit<InputProps, "type" | "inputMode" | "onChange"> & {
  inputMode?: "numeric" | "decimal";
  allowNegative?: boolean;
  onChange?(value: string): void;
};

export default function InputNumber({ onFocus, ...props }: InputNumberProps) {
  const { onChange, inputMode = "numeric", allowNegative, ...rest } = props;
  const [currentValue, setCurrentValue] = useState(rest.value);

  useEffect(() => {
    setCurrentValue(rest.value);
  }, [rest.value]);

  return (
    <Input
      inputMode={inputMode}
      value={currentValue}
      onChange={(e) => {
        const formattedValue = String(e.target.value ?? "").replace(allowNegative ? /[^-\d.]/g : /[^\d.]/g, "");

        if ((inputMode === "decimal" && /^-?\d*\.?\d*$/.test(formattedValue)) || (inputMode === "numeric" && /^-?\d*$/.test(formattedValue))) {
          if (currentValue !== formattedValue) {
            onChange?.(formattedValue);
            setCurrentValue(formattedValue);
          }
        }
      }}
      {...rest}
    />
  );
}
