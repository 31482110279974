import { ButtonLink, Divider, Icon, LogoLoader, Radio, Space, Text } from "components";
import React from "react";
import { notifyError, notifySuccess } from "store/actions/notifications";
import { updateOnboarding } from "store/actions/onboarding";
import { useOnboarding } from "store/hooks/onboarding";
import css, { createUseStyle } from "style/classname";
import { BANK_ONBOARDING_PATH, BankOnboardingPages, OnboardingSection } from ".";
import { getAlphaColor } from "antd/lib/theme/themes/default/colorAlgorithm";

export default function OwnershipStructure() {
  const { data: onboarding } = useOnboarding();
  const radioStyle = useRadioStyle();

  return (
    <OnboardingSection
      title="What does your ownership structure look like?"
      description={
        <>
          In order to comply with anti-money laundering regulations, we need to understand the ownership structure of the company and if there are any
          individuals who have a controlling interest.
        </>
      }
    >
      {onboarding ? (
        <>
          <Radio.Group
            size="large"
            style={{ width: "100%" }}
            value={!!onboarding?.noOwnership}
            onChange={async (e) => {
              const noOwnership = !!e.target.value;

              if (!!onboarding?.noOwnership !== noOwnership) {
                try {
                  await updateOnboarding({ noOwnership });
                  notifySuccess("Account Information saved");
                } catch (errors) {
                  notifyError("Saving Account Information failed");
                }
              }
            }}
            className={radioStyle}
            options={[
              {
                value: false,
                label: (
                  <Space wrap={false} justify="flex-start" align="start" gap="large">
                    <div className="icon-wrapper">
                      <Icon type="circle-three-quarters" theme="duotone" size="xlarge" />
                    </div>
                    <div>
                      <Text weight="bold">One or several owners own 25% or more,</Text>
                      <Text>directly or indirectly, or have a controlling interest.</Text>
                    </div>
                  </Space>
                ),
              },
              {
                value: true,
                label: (
                  <Space wrap={false} justify="flex-start" align="start" gap="large">
                    <div className="icon-wrapper">
                      <span className="fa-stack">
                        <Icon type="circle-three-quarters" theme="duotone" className="fa-stack-1x" />
                        <Icon type="ban" className="fa-stack-2x" />
                      </span>
                    </div>
                    <div>
                      <Text weight="bold">No owner owns more that 25%,</Text>
                      <Text>directly or indirectly, nor has a controlling interest.</Text>
                    </div>
                  </Space>
                ),
              },
            ]}
          />
          <Divider margin="medium" transparent />
          <ButtonLink size="large" block type="primary" path={[BANK_ONBOARDING_PATH, BankOnboardingPages.SIGNING_OFFICERS].join("/")}>
            Continue
            <Icon type="angle-right" />
          </ButtonLink>
        </>
      ) : (
        <LogoLoader />
      )}
    </OnboardingSection>
  );
}

const useRadioStyle = createUseStyle(({ theme }) =>
  css`
    .${theme.prefixCls}-radio-wrapper {
      display: flex;
      flex-wrap: nowrap;
      line-height: 24px;
      background-color: ${theme.colorWhite};
      padding: 16px;
      border-radius: ${theme.borderRadius}px;
      border: ${theme.lineWidth}px ${theme.lineType} ${theme.colorBorder};
      margin-bottom: 8px;
      width: 100%;
      transition: all 0.3s ease;
      font-weight: normal;
      .icon-wrapper {
        transition: all 0.3s ease;
        padding: 8px;
        background-color: ${theme.colorBgLayout};
        border-radius: 50%;
        height: 50px;
        width: 50px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }
      .${theme.prefixCls}-radio {
        display: none;
      }
      &:hover {
        border: ${theme.lineWidth}px ${theme.lineType} ${theme.colorPrimaryHover};
        .icon-wrapper {
          background-color: ${getAlphaColor(theme.colorWhite, 0.7)};
        }
      }
      &.${theme.prefixCls}-radio-wrapper-checked {
        background-color: ${theme.controlItemBgActive};
        border: ${theme.lineWidth}px ${theme.lineType} ${theme.colorPrimary};
        .icon-wrapper {
          color: ${theme.colorPrimaryActive};
          background-color: ${getAlphaColor(theme.colorWhite, 0.8)};
        }
      }
    }
  `(),
);
