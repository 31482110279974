import { formatCountry, getRegionLabel, PaymentCategory, Role, TaxFormType, TaxFormWarnings } from "@trolley/common-frontend";
import { Box, Button, DateDisplay, Flag, Form, Icon, Space, Text } from "components";
import { default as React, useState } from "react";
import { notifyError } from "store/actions/notifications";
import { TaxForm, unmaskFtin, W8BENEForm, W8BENForm } from "store/actions/taxForms";
import { useMerchantSettings } from "store/hooks/merchantSettings";
import { useUser } from "store/hooks/user";
import { useWithholdingTable } from "store/hooks/withholdingTable";
import { CATEGORY_DETAILS } from "utils/constants";
import { translateRegionByCountry } from "utils/helpers";
import getFTinLabel from "utils/helpers/ftins";
import { w8limitationTypes, w8taxOrgTypes } from "./W8UploadForm";

type Props = {
  data: W8BENForm | W8BENEForm;
  form: Omit<TaxForm, "data" | "taxFormData">;
};

export default function W8Summary({ form, data }: Props) {
  const { data: authUser } = useUser();
  const { data: withholdingTable } = useWithholdingTable();
  const { warnings, taxFormType: formType } = form;
  const { data: merchant } = useMerchantSettings();
  const [unmaskedFtin, setUnmaskedFtin] = useState("");
  const [showUnmasked, setShowUnmasked] = useState(false);

  const hasTreatySection = data.residenceCountry && withholdingTable[data.residenceCountry] && !data.noTaxId;
  const showUnmaskButton = warnings && warnings.some((warning) => warning === TaxFormWarnings.FOREIGN_TIN_INVALID);

  return (
    <>
      <Box padding="small" header="Identification of Beneficial Owner">
        {formType === TaxFormType.W8BEN && (
          <>
            <Form.Item label="Full Name">{data.name || [data.firstName, data.lastName].join(" ")}</Form.Item>
            <Form.Item label="Date of Birth">
              <DateDisplay value={data.dob} icon={false} showUtc time={false} />
            </Form.Item>
          </>
        )}

        {formType === TaxFormType.W8BENE && (
          <>
            <Form.Item label="Name of Organization">{data.name || data.organizationName}</Form.Item>
            <Form.Item label="Type of Organization Entity">
              {(data.organizationType && w8taxOrgTypes[data.organizationType]) || data.organizationType}
            </Form.Item>
            <Form.Item label="Beneficial Owner Limitation Type">
              {(data.beneficialOwnerLimitationType && w8limitationTypes[data.beneficialOwnerLimitationType]) || data.beneficialOwnerLimitationType}
            </Form.Item>
          </>
        )}

        <Form.Item label={formType === TaxFormType.W8BEN ? "Country of Citizenship" : "Country of Incorporation or Organization"}>
          {formatCountry(data.organizationCountry)}
        </Form.Item>
      </Box>

      <Box padding="small" header="Permanent Residence">
        <Form.Item label="Country">{formatCountry(data.residenceCountry) || " - "}</Form.Item>
        {data.residenceRegion && (
          <Form.Item label={translateRegionByCountry(data.residenceCountry)}>{getRegionLabel(data.residenceRegion, data.residenceCountry)}</Form.Item>
        )}
        <Form.Item label="Address">{data.residenceAddress}</Form.Item>
        <Form.Item label="City">{data.residenceCity}</Form.Item>
        <Form.Item label="Postal Code">{data.residencePostalCode}</Form.Item>
      </Box>

      <Box padding="small" header="Mailing Address">
        <Form.Item label="Country">{formatCountry(data.mailingCountry) || " - "}</Form.Item>
        {data.mailingRegion && (
          <Form.Item label={translateRegionByCountry(data.mailingCountry)}>{getRegionLabel(data.mailingRegion, data.mailingCountry)}</Form.Item>
        )}
        <Form.Item label="Address">{data.mailingAddress}</Form.Item>
        <Form.Item label="City">{data.mailingCity}</Form.Item>
        <Form.Item label="Postal Code">{data.mailingPostalCode}</Form.Item>
      </Box>

      <Box padding="small" header="Tax Identification Numbers">
        <Form.Item label="U.S. Taxpayer Identification Number">{data.taxPayerUsId || " - "}</Form.Item>
        <Form.Item label="FTIN Type">
          {data.residenceCountry && data.ftinType ? (
            <Space>
              <Flag code={data.residenceCountry} showLabel={false} />
              {getFTinLabel(data.residenceCountry, data.ftinType)}
            </Space>
          ) : (
            " - "
          )}
        </Form.Item>
        <Form.Item label="Foreign Tax ID Number">
          <Space>
            {showUnmaskButton && authUser && ([Role.TAX_MANAGER, Role.OWNER] as string[]).includes(authUser?.role) && (
              <Button
                style={{ width: "unset" }}
                icon={<Icon type={showUnmasked ? "eye-slash" : "eye"} />}
                type="text"
                shape="default"
                onClick={async () => {
                  if (unmaskedFtin) {
                    setShowUnmasked((showUnmasked) => !showUnmasked);

                    return;
                  }
                  try {
                    const ftin = await unmaskFtin(form.id);
                    if (ftin) {
                      setUnmaskedFtin(ftin);
                      setShowUnmasked(true);
                    }
                  } catch (errors) {
                    notifyError("Unmasking failed", { errors });
                  }
                }}
              />
            )}
            {showUnmasked && <FTINGrid value={unmaskedFtin} />}
            {!showUnmasked && <FTINGrid value={data.taxPayerForeignId || " - "} />}
          </Space>
        </Form.Item>
        <Form.Item label="Certified to provide services exclusively outside of the United States">
          {data.noUsCertification ? (
            <>
              <Icon.Status type="success" left />
              Certified
              <Text type="secondary" size="small">
                {data.noUsSignature && data.certificationDate ? (
                  <>
                    Signed by {data.noUsSignature || " - "} on {data.certificationDate}
                  </>
                ) : (
                  // noUsSignature and date does not exist for uploaded forms. use form signed and signedAt
                  <>
                    Signed by {form.signed || " - "} on <DateDisplay value={form.signedAt} icon={false} preserveFormat time={false} />
                  </>
                )}
              </Text>
            </>
          ) : (
            "No certification"
          )}
        </Form.Item>
      </Box>

      {hasTreatySection && merchant?.payment?.categories && (
        <Box padding="small" header="Claim of Tax Treaty Benefits">
          <Form.Item label="Treaty Country">{formatCountry(data.residenceCountry) || " - "}</Form.Item>
          <Form.Item label="Special Withholding">
            {Object.keys(merchant.payment.categories)
              .filter((key) => !!merchant?.payment?.categories[key] && CATEGORY_DETAILS[key]?.incomeCode)
              .map((key: PaymentCategory) => (
                <Text key={key}>
                  {form.calculatedWithholdings[CATEGORY_DETAILS[key].incomeCode || ""]?.withholdingPercentage}% for {CATEGORY_DETAILS[key].name}
                </Text>
              ))}
          </Form.Item>
        </Box>
      )}
    </>
  );
}

function FTINGrid({ value }: { value: string }) {
  if (!value) return null;

  return (
    <div style={{ display: "inline-block" }}>
      {value.split("").map((c, index) => (
        <div key={value.substring(0, index)} style={{ width: "11px", display: "inline-block", textAlign: "center" }}>
          {c}
        </div>
      ))}
    </div>
  );
}
