import { Access, Message, MessageVisibility, Ticket, TicketCreateGroup } from "@trolley/common-frontend";
import { TextAreaRef } from "antd/lib/input/TextArea";
import { Box, Button, DateDisplay, Divider, Form, Grid, Icon, Input, Text } from "components";
import { UserAccess } from "features/user";
import React, { ReactNode, useRef } from "react";
import { sendTicketMessage } from "store/actions/tickets";
import { useMerchantSettings } from "store/hooks/merchantSettings";
import { useTeamMember } from "store/hooks/teamMemberList";
import { useTicket } from "store/hooks/tickets";
import { BaseStatus } from "store/reducers/standardReducer";
import styled, { ThemedToken, useThemeToken } from "style/classname";
import palette from "style/palette";
import { handleFormErrors } from "utils/helpers";

export const teamTypeToLabel = {
  compliance: "Trolley Support",
  treasury: "Trolley Support",
  recipient: "Recipient",
  merchant: "Merchant",
};

type FormFields = {
  message: string;
};

type Props = {
  ticket: Ticket | undefined;
};

function renderText(text: string) {
  const lines = text.split(/\n/);

  return lines.map((l) => <div key={l}>{l}</div>);
}

function getMessageType(message: Message) {
  if (message.visibility === MessageVisibility.RECIPIENT) {
    return "recipient";
  }

  if (message.creatorGroup === TicketCreateGroup.SYSTEM) {
    return "system";
  }

  if (message.creatorGroup === TicketCreateGroup.MERCHANT) {
    return "merchant";
  }

  if ([TicketCreateGroup.COMPLIANCE, TicketCreateGroup.TREASURY].includes(message.creatorGroup)) {
    return "pr";
  }

  return "system";
}

export default function IssueMessageBox({ ticket }: Props) {
  const [form] = Form.useForm<FormFields>();
  const inputRef = useRef<TextAreaRef | null>(null);
  const { data: merchantSettings } = useMerchantSettings();
  const { status: ticketStatus } = useTicket(ticket?.id);
  const theme = useThemeToken();

  async function onFinish(values: FormFields) {
    if (ticket) {
      try {
        await sendTicketMessage(ticket.id, values.message);
        form.setFieldsValue({ message: "" });
        inputRef.current?.focus?.();
      } catch (errors) {
        handleFormErrors(errors, form);
      }
    }
  }

  return (
    <Box header="Messages" style={{ height: "100%" }} padding="small">
      <Form form={form} onFinish={onFinish}>
        <div>
          {ticket?.messages?.map((m, i) => {
            const isMerchant = m.creatorGroup === TicketCreateGroup.MERCHANT;
            const isToRecipient = m.visibility === MessageVisibility.RECIPIENT;

            const isFirst = i === 0;
            const isLast = i + 1 === ticket.messages?.length || false;
            const isFirstInChain = isFirst || ticket.messages?.[i - 1].creatorId !== m.creatorId || ticket.messages?.[i - 1].visibility !== m.visibility;
            const isLastInChain = isLast || ticket.messages?.[i + 1].creatorId !== m.creatorId || ticket.messages?.[i + 1].visibility !== m.visibility;

            const messageType = getMessageType(m);

            return (
              <Grid justify={isMerchant ? "end" : "start"} padding="small" key={m.id} style={{ marginBottom: "4px" }}>
                {isFirstInChain && (
                  <Grid.Item xs={24}>
                    <Text padded={false} type="label" size="small" style={{ textAlign: isMerchant ? "right" : "left" }}>
                      {isMerchant && m.creatorId ? (
                        <Text inline>
                          <DisplayTeamMember userId={m.creatorId} defaultValue={teamTypeToLabel[m.creatorGroup]} />
                        </Text>
                      ) : (
                        <Text inline>{teamTypeToLabel[m.creatorGroup]}</Text>
                      )}
                    </Text>
                  </Grid.Item>
                )}

                <Grid.Item>
                  <div className={styledComment({ type: messageType, theme })}>
                    {isToRecipient && (
                      <Text weight="bold">
                        <Icon type="circle-info" size="small" color="grey" theme="solid" left />
                        Message to the recipient
                      </Text>
                    )}
                    {messageType === "system" && (
                      <>
                        <Text weight="bold">
                          <Icon type="circle-info" size="small" color="grey" theme="solid" left />
                          Ticket Information Updated
                        </Text>
                        <Text type="secondary">{renderText(m.text)}</Text>
                      </>
                    )}
                    {messageType !== "system" && renderText(m.text)}
                  </div>
                </Grid.Item>
                {isLastInChain && (
                  <Grid.Item xs={24}>
                    <Text padded={false} type="secondary" size="small" style={{ textAlign: isMerchant ? "right" : "left" }}>
                      <Icon type="paper-plane" left size="small" />
                      <DateDisplay icon={false} value={m.createdAt} />
                    </Text>
                  </Grid.Item>
                )}
              </Grid>
            );
          })}
        </div>
        <Divider margin="small" transparent />
        {ticket?.merchantId === merchantSettings?.merchantId && (
          <UserAccess type={Access.TICKET_WRITE}>
            <Form.Item name="message" noStyle>
              <Input.TextArea
                maxLength={255}
                ref={(input) => {
                  inputRef.current = input;
                }}
                onKeyDown={(e) => {
                  if (!e.shiftKey && e.key === "Enter") {
                    e.preventDefault?.();
                    form.submit();
                  }
                }}
                name="message"
                placeholder="Write a message to support"
                autoSize={{ minRows: 1, maxRows: 4 }}
              />
            </Form.Item>

            <Form.Control shouldUpdate>
              {(form) => {
                const message = form.getFieldValue("message");

                return (
                  <Text align="right" padded>
                    <Button
                      loading={ticketStatus === BaseStatus.LOADING}
                      htmlType="submit"
                      type="primary"
                      disabled={!message}
                      icon={<Icon type="paper-plane" />}
                    >
                      Send
                    </Button>
                  </Text>
                );
              }}
            </Form.Control>
          </UserAccess>
        )}
      </Form>
    </Box>
  );
}

export function DisplayTeamMember({ userId, defaultValue }: { userId: string; defaultValue: ReactNode }) {
  const { data: teamMember } = useTeamMember(userId);

  return <span>{teamMember?.name || defaultValue}</span>;
}

export const styledComment = styled<{ type: "merchant" | "recipient" | "pr" | "system"; theme: ThemedToken }>`
  ${({ type, theme }) => {
    if (type === "merchant") {
      return `
      background: ${theme.colorPrimary};
        color: ${theme.colorTextLightSolid};
        `;
    } else if (type === "recipient") {
      return `background: ${theme.colorInfoBg};`;
    } else if (type === "pr") {
      return `background: ${palette["grey-alpha-1"]};`;
    } else {
      // system
      return `background: ${palette["yellow-alpha-2"]};`;
    }
  }}
  padding: 8px;
  border-radius: 8px;
  width: fit-content;
  box-shadow: ${({ theme }) => theme.boxShadow};
`;
