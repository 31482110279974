import { CountryCode, CurrencyCode, formatCurrency, SUPPORTED_PRIMARY_CURRENCIES } from "@trolley/common-frontend";
import { Box, Checkbox, Flag, Form, FormItem, Grid, InputLive, InputTags, Radio, RangePicker, Select, SelectCountry } from "components";
import dayjs, { Dayjs } from "dayjs";
import React from "react";
import css, { createUseStyle } from "style/classname";
import { ANIMATION } from "style/variables";
import { stringifyQuery } from "utils/helpers";
import { Query } from "utils/hooks";
import { TableFilterField } from ".";

const MAX_TAG_COUNT = 6;

const RANGES: Record<string, [Dayjs, Dayjs]> = {
  "This month": [dayjs().startOf("month"), dayjs().endOf("month")],
  "Last month": [dayjs().add(-1, "month").startOf("month"), dayjs().add(-1, "month").endOf("month")],
  "This quarter": [dayjs().startOf("quarter"), dayjs().endOf("quarter")],
  "Last quarter": [dayjs().add(-1, "quarter").startOf("quarter"), dayjs().add(-1, "quarter").endOf("quarter")],
  "This year": [dayjs().startOf("year"), dayjs().endOf("year")],
  "Last year": [dayjs().add(-1, "year").startOf("year"), dayjs().add(-1, "year").endOf("year")],
};

const useStyledBox = createUseStyle(({ theme }) =>
  css`
    overflow: visible !important;
    position: relative;
    animation: ${ANIMATION.fadeInDown} 300ms ease;
    transform-origin: top center;
    .grid-item {
      min-width: max-content;
      flex: 1 0 260px;
      ${theme.screenUp("lg")} {
        flex: 0 0 auto;
        width: 33.33%;
      }
      ${theme.screenUp("xl")} {
        flex: 0 0 260px;
      }
    }

    .${theme.prefixCls}-select-selection-overflow {
      .selected-hidden {
        // hide when selected
        display: none;
      }
    }
  `(),
);

interface Props {
  fields: TableFilterField[];
  query: Query & { [key: string]: any };
  onChange(updates: any): void;
}

export default function FilterBar(props: Props) {
  const { fields, query, onChange } = props;
  const styledBox = useStyledBox();
  function onSelectValues(filterKey: string | undefined) {
    return (selectedValues: any[]) => {
      onChange({ [filterKey || ""]: selectedValues.length ? selectedValues : undefined });
    };
  }

  function getValues(filterKey: string): string[] {
    const val = query[filterKey];
    if (typeof val === "string" || typeof val === "number") {
      return [String(val)];
    } else if (Array.isArray(val)) {
      return val as string[];
    }

    return [];
  }

  function renderField(field: TableFilterField) {
    switch (field.type) {
      case "input":
        const key = `${stringifyQuery(query)}${field.filterKey}`;

        return (
          <Grid.Item key={key}>
            <FormItem label={field.label} tooltip={field.tooltip}>
              <InputLive
                enablePopup={false}
                inputMode={field.inputMode === "text" ? undefined : field.inputMode}
                value={query[field.filterKey] as string}
                onSave={async (val) => {
                  onChange({
                    [field.filterKey]: val,
                  });
                }}
              />
            </FormItem>
          </Grid.Item>
        );
      case "checkbox":
        return (
          <Grid.Item key={field.filterKey}>
            <FormItem label={field.label} tooltip={field.tooltip}>
              <Checkbox
                style={{ marginTop: "4px" }}
                checked={!!query[field.filterKey]}
                onChange={(e) => {
                  onChange({
                    [field.filterKey]: !!e.target.checked,
                  });
                }}
              />
            </FormItem>
          </Grid.Item>
        );
      case "radio":
        return (
          <Grid.Item key={field.filterKey}>
            <FormItem label={field.label} tooltip={field.tooltip}>
              <Radio.Group
                value={query[field.filterKey] ?? undefined}
                onChange={(e) => {
                  onChange({
                    [field.filterKey]: e.target.value,
                  });
                }}
                optionType="button"
                options={field.options}
              />
            </FormItem>
          </Grid.Item>
        );
      case "select":
        return (
          <Grid.Item key={field.filterKey}>
            <FormItem label={field.label} tooltip={field.tooltip}>
              <Select
                mode="multiple"
                allowClear
                popupMatchSelectWidth={false}
                maxTagCount={MAX_TAG_COUNT}
                placeholder="Select"
                onChange={onSelectValues(field.filterKey)}
                value={getValues(field.filterKey)}
                optionFilterProp="label"
                options={field.options}
              />
            </FormItem>
          </Grid.Item>
        );
      case "tags":
        return (
          <Grid.Item key={field.filterKey}>
            <FormItem label={field.label} tooltip={field.tooltip}>
              <InputTags
                value={getValues(field.filterKey)}
                editable
                maxLength={field.maxLength}
                placeholder={field.placeholder}
                renderTag={field.renderTag}
                onChange={onSelectValues(field.filterKey)}
              />
            </FormItem>
          </Grid.Item>
        );
      case "select-countries":
        return (
          <Grid.Item key={field.filterKey}>
            <FormItem label={field.label} tooltip={field.tooltip}>
              <SelectCountry
                type="address"
                mode="multiple"
                maxTagCount={MAX_TAG_COUNT}
                placeholder="Select"
                allowClear
                popupMatchSelectWidth={false}
                value={getValues(field.filterKey) as CountryCode[]}
                onChange={onSelectValues(field.filterKey)}
                topCountries={field.topCountries}
              />
            </FormItem>
          </Grid.Item>
        );
      case "select-currencies":
        return (
          <Grid.Item key={field.filterKey}>
            <FormItem label={field.label} tooltip={field.tooltip}>
              <Select
                mode="multiple"
                placeholder="Select"
                maxTagCount={MAX_TAG_COUNT}
                allowClear
                popupMatchSelectWidth={false}
                value={getValues(field.filterKey)}
                optionFilterProp="title"
                onChange={onSelectValues(field.filterKey)}
                options={Object.values(field.currencyType === "primary" ? SUPPORTED_PRIMARY_CURRENCIES : CurrencyCode).map((code) => {
                  const currencyLabel = formatCurrency(code);

                  return {
                    title: `${code} ${currencyLabel}`,
                    value: code,
                    label: (
                      <>
                        <Flag code={code} showLabel={false} /> {code}
                        <span className="selected-hidden"> - {currencyLabel}</span>
                      </>
                    ),
                  };
                })}
              />
            </FormItem>
          </Grid.Item>
        );
      case "date-range":
        const [startDateKey, endDateKey] = field.filterKey;

        return (
          <Grid.Item key={startDateKey}>
            <FormItem label={field.label} tooltip={field.tooltip}>
              <RangePicker
                allowClear
                autoComplete="date-range"
                ranges={RANGES}
                format={field.format}
                value={[query[startDateKey], query[endDateKey]] as string[]}
                onChange={([startDate, endDate]) => {
                  onChange({ startDate, endDate });
                }}
              />
            </FormItem>
          </Grid.Item>
        );
      default:
        return null;
    }
  }

  return (
    <Box padding="small" className={styledBox}>
      <Form compact>
        <Grid padding="small">{fields.map((f) => renderField(f))}</Grid>
      </Form>
    </Box>
  );
}
