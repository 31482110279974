import { BaseError, TaxStatus } from "@trolley/common-frontend";
import { Container, Heading, RecordCount, Reloader } from "components";
import React from "react";
import Helmet from "react-helmet";
import { TaxProfilesQuery, resetTaxProfiles } from "store/actions/taxProfiles";
import { useTaxProfiles } from "store/hooks/taxProfiles";
import { useQuery } from "utils/hooks";
import TaxProfilesTable from "./TaxProfilesTable";

const DEFAULT_QUERY: TaxProfilesQuery = {
  page: 1,
  pageSize: 10,
  orderBy: ["createdAt"],
  sortBy: ["desc"],
  status: [TaxStatus.SUBMITTED],
};

// temporary until API supports search parameters
const NOT_FOUND_ERROR = "not_found";
const INVALID_FIELD = "invalid_field";

export default function SubmittedTaxProfiles() {
  const [query, setQuery] = useQuery<TaxProfilesQuery>(DEFAULT_QUERY, {
    arrayKeys: ["status", "primaryCountry", "tinCountry", "recipientType", "tinStatus"],
  });
  const { data: taxProfiles, status: taxProfilesStatus, error: taxProfilesErrors } = useTaxProfiles(query);

  // temporary until search is implemented in the api
  function filterError(taxProfileErrors: BaseError[] | undefined) {
    if (taxProfileErrors && taxProfileErrors.some((error) => [NOT_FOUND_ERROR, INVALID_FIELD].includes(error.code ?? ""))) {
      return undefined;
    }

    return taxProfileErrors;
  }

  // // temporary until api supports a search parameter, once the search is implemented, setQuery will be called directly
  function onChangeQuery(newQuery: TaxProfilesQuery) {
    setQuery({
      ...newQuery,
      ...(!!newQuery.search && { recipientId: newQuery.search }),
    });
  }

  return (
    <Container>
      <Helmet>
        <title>{`Tax Profiles (${query.page}/${taxProfiles.meta.pages})`}</title>
      </Helmet>

      <Heading description="Review and approve the tax profiles submitted by recipients.">
        <RecordCount
          prefix="Showing"
          value={taxProfiles.meta.records}
          one="1 submitted tax profile"
          other="# submitted tax profiles"
          equalZero="submitted tax profiles"
        />
        <Reloader onClick={resetTaxProfiles} status={taxProfilesStatus} />
      </Heading>
      <TaxProfilesTable
        showRecipients
        showFilters
        status={taxProfilesStatus}
        errors={filterError(taxProfilesErrors)}
        taxProfiles={taxProfiles}
        query={query}
        onChangeQuery={onChangeQuery}
      />
    </Container>
  );
}
