import { batch } from "react-redux";
import * as request from "services/request";
import { userpilotReset } from "services/thirdParty/userpilot";
import store from "store";
import { SESSION_NEW } from "store/actions/session";
import { OpCode, standardDispatch } from "store/dispatcher";
import { BaseStatus } from "store/reducers/standardReducer";
import { authToken } from "utils/helpers";
import { Query } from "utils/hooks";
import * as types from "@trolley/common-frontend";
import { isLoaded } from "./actionUtils";

export const DEFAULT_QUERY = {
  page: 1,
  pageSize: 1000,
};

export type SubMerchant = types.Auth.MerchantSummary;

export interface SubMerchantCreate {
  merchant: {
    name: string;
    country: string;
    currency: string;
    phone?: string;
    website?: string;
  };
  onboarding: {
    businessLegalName?: string;
    businessPhone?: string;
    businessWebsite?: string;
    businessCountry?: string;
    businessAddress?: string;
    businessCity?: string;
    businessZip?: string;
    businessRegion?: string;
  };
}

export interface SubMerchantQuery extends Query {
  page?: number;
  pageSize?: number;
}

// Note: API pagination query does not work.
export function loadSubMerchants(force?: boolean): string {
  const data = store.getState().subMerchants;
  const id = "data";

  if (force || !isLoaded(data.fetchStatus[id])) {
    standardDispatch(OpCode.LOADING, "subMerchants", { id });

    request
      .GET<types.Auth.ListMerchantSummary>("/auth/merchants", { query: DEFAULT_QUERY })
      .then(({ body }) => {
        const records = body.merchants;
        records.sort((a, b) => {
          if (!a.parentID) {
            return -1;
          }
          if (!b.parentID) {
            return 1;
          }
          if (a.sandbox && b.sandbox) {
            return 0;
          }
          if (a.sandbox) {
            return -1;
          }
          if (b.sandbox) {
            return 1;
          }

          return 0;
        });
        standardDispatch(OpCode.DATA, "subMerchants", {
          id,
          data: { records, meta: body.meta },
        });
      })
      .catch((errors) => {
        standardDispatch(OpCode.ERROR, "subMerchants", {
          errors,
          id,
        });
      });
  }

  return id;
}

export async function createSubMerchant(query: SubMerchantCreate, sandbox: boolean = false) {
  const { subMerchants } = store.getState();
  if (subMerchants.fetchStatus.data !== BaseStatus.LOADING) {
    try {
      standardDispatch(OpCode.LOADING, "subMerchants");
      await request.POST<types.SubMerchant.CreateResult>(`/v1/merchant/${sandbox ? "sandbox" : "submerchant"}/create`, { query });
      standardDispatch(OpCode.RESET, "subMerchants");
    } catch (errors) {
      standardDispatch(OpCode.ERROR, "subMerchants", {
        errors,
      });
      throw errors;
    }
  }
}

export async function deleteSandboxMerchant(subMerchantId: string) {
  try {
    standardDispatch(OpCode.LOADING, "subMerchants");
    await request.POST("/v1/merchant/sandbox/delete", {
      query: { id: subMerchantId },
    });
    standardDispatch(OpCode.RESET, "subMerchants");
  } catch (errors) {
    standardDispatch(OpCode.ERROR, "subMerchants", {
      errors,
    });
    throw errors;
  }
}

export async function switchMerchant(merchantId: string) {
  const { body } = await request.POST<types.Auth.AuthResult>("/auth/switch", { query: { merchantId } });

  if (body.authStatus === "token") {
    userpilotReset();
    authToken.set(body.token); // 1. set new token
    batch(() => {
      store.dispatch({ type: SESSION_NEW });
      standardDispatch(OpCode.DATA, "user", {
        data: body.user,
      });
    });
  }
}
