import { Alert, Box } from "components";
import config from "config";
import React from "react";
import styled from "style/classname";

interface Props {
  value: any;
}

export default function ObjectInspector(props: Props) {
  if (config.ENV === "production") {
    return null;
  }

  try {
    return (
      <Box color="grey" padding="xsmall" className={styledCode}>
        <Alert type="info" showIcon>
          Debugger: this is only visible on non-production environment
        </Alert>
        <pre>{JSON.stringify(props.value, null, 2)}</pre>
      </Box>
    );
  } catch (errors) {
    return <pre>{JSON.stringify(errors, null, 2)}</pre>;
  }
}

const styledCode = styled`
  margin-top: 16px;
  margin-bottom: 16px;
  pre {
    max-height: 200px;
    overflow: auto;
    font-size: 12px;
  }
`();
