import store from "store";
import { OpCode, standardDispatch } from "store/dispatcher";
import { POST } from "services/request";
import { computeID, isLoaded } from "./actionUtils";
import { DAC7EoyTaxQuery } from "./dac7EoyTaxForms";

export function resetDac7EoySummary() {
  standardDispatch(OpCode.RESET, "dac7EoySummary");
}
export function loadDac7EoySummary(queries: DAC7EoyTaxQuery[], force?: boolean) {
  const { dac7EoySummary } = store.getState();
  const id = computeID(queries);

  if (force || !isLoaded(dac7EoySummary.fetchStatus[id])) {
    standardDispatch(OpCode.LOADING, "dac7EoySummary", { id });
    POST<{ counts: number[] }>("/v1/tax-year/dac7-eoy/count", {
      query: {
        queries,
      },
    })
      .then(({ body }) => {
        standardDispatch(OpCode.DATA, "dac7EoySummary", { id, data: body.counts });
      })
      .catch((errors) => {
        standardDispatch(OpCode.ERROR, "dac7EoySummary", { id, errors });
      });
  }

  return id;
}
