import { batch } from "react-redux";
import store from "store";
import { OpCode, standardDispatch } from "store/dispatcher";
import { Mapped } from "store/reducers/standardReducer";
import { Query } from "utils/hooks";
import * as request from "services/request";
import * as types from "@trolley/common-frontend";
import { computeID, isLoaded } from "./actionUtils";
import { loadPayment } from "./payments";

export interface PaymentTaxUpdate {
  recipientId?: string;
  externalId?: string;
  memo?: string;
  amount?: string;
  currency?: string;
  payoutMethod?: types.PayoutMethodType;
  category?: types.PaymentCategory;
  taxReportable?: boolean;
  forceUsTaxActivity?: boolean;
  taxWithholdingReason?: types.TaxWithholdingReasons;
  processedAt?: string;
  taxFormId?: string | null;
  withholdingAmount: string;
  withholdingCurrency: types.CurrencyCode;
  tags?: string[];
}

export interface PaymentTax extends types.PaymentTax {}

export interface PaymentTaxesQuery extends Query {
  ids?: string[];
  recipientId?: string;
  search?: string;
  withTaxWithholding?: boolean;
  taxReportable?: boolean;
  forceUsTaxActivity?: boolean;
  category?: types.PaymentCategory[];
  payoutMethod?: types.PayoutMethodType[];
  withPayment?: boolean;
}

export function resetPaymentTaxes() {
  standardDispatch(OpCode.RESET, "paymentTaxList");
}

export function loadPaymentTaxes(query: PaymentTaxesQuery, force?: boolean) {
  const id = computeID(query);
  const { offlinePaymentList: offlinePayments } = store.getState();

  if (force || !isLoaded(offlinePayments.fetchStatus[id])) {
    standardDispatch(OpCode.LOADING, "paymentTaxList", { id });
    request
      .POST<types.PaymentTaxListResponse>("/v1/payment-taxes/search", { query })
      .then(({ body }) => {
        batch(() => {
          const mappedOfflinePayments: Mapped<PaymentTax> = {};
          const ids = body.offlinePayments.map((op) => {
            mappedOfflinePayments[op.id] = op;

            return op.id;
          });

          standardDispatch(OpCode.DATA, "paymentTaxList", {
            id,
            data: {
              records: ids,
              meta: body.meta,
            },
          });

          standardDispatch(OpCode.DATA, "paymentTaxes", {
            bulk: mappedOfflinePayments,
          });
        });
      })
      .catch((errors) => {
        standardDispatch(OpCode.ERROR, "paymentTaxList", { id, errors });
      });
  }

  return id;
}

export async function updatePaymentTax(id: string, query: PaymentTaxUpdate) {
  standardDispatch(OpCode.LOADING, "paymentTaxes", { id });

  try {
    const { body } = await request.POST<{ offlinePayment: PaymentTax }>("/v1/payment-taxes/update", { query: { ...query, id } });
    standardDispatch(OpCode.DATA, "paymentTaxes", {
      id,
      data: body.offlinePayment,
    });

    const paymentId = body.offlinePayment.paymentId;
    if (paymentId) {
      loadPayment(paymentId, true);
    }

    return body.offlinePayment;
  } catch (errors) {
    standardDispatch(OpCode.ERROR, "paymentTaxes", {
      id,
      errors,
    });
    throw errors;
  }
}

export async function updateBulkPaymentTaxes(search: PaymentTaxesQuery, update: PaymentTaxUpdate, pageQuery: PaymentTaxesQuery) {
  const id = computeID(pageQuery); // only to show loading UI
  standardDispatch(OpCode.LOADING, "paymentTaxList", { id });

  try {
    await request.POST<{ offlinePayment: PaymentTax }>("/v1/payment-taxes/update-batch", {
      query: {
        search,
        update,
      },
    });
    standardDispatch(OpCode.RESET, "paymentTaxList");
    standardDispatch(OpCode.RESET, "offlinePaymentList");
  } catch (errors) {
    standardDispatch(OpCode.ERROR, "paymentTaxList", { id, errors });
    throw errors;
  }
}
