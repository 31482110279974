import React from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";

// Authentiation related pages
import LoginPage from "pages/Authentication/LoginPage";
import RegisterPage from "pages/Authentication/RegisterPage";
import RequestResetPasswordPage from "pages/Authentication/RequestResetPasswordPage";
import ResetPassword from "pages/Authentication/ResetPassword";
import VerifyEmail from "pages/Authentication/VerifyEmail";
import PageNotFound from "pages/PageNotFound";
import { useUser } from "store/hooks/user";
import { ConnectedIntlProvider } from "utils/context";
import { SupportedLocales } from "@trolley/common-frontend";
import { ExternalLink } from "components";

export enum PublicModulesEnum {
  Login = "login",
  Register = "register",
  VerifyEmail = "verifyEmail",
  RequestReset = "requestReset",
  Reset = "reset",
  Invite = "invite",
}

const LOGIN_NOT_REQUIRED_PATHS: { [key in PublicModulesEnum]: { [key in "en" | "fr"]: string } } = {
  [PublicModulesEnum.Login]: {
    en: "/login",
    fr: "/fr/login",
  },
  [PublicModulesEnum.Register]: {
    en: "/register",
    fr: "/fr/register",
  },
  [PublicModulesEnum.VerifyEmail]: {
    en: "/auth/verify/:userId/:code",
    fr: "/fr/auth/verify/:userId/:code",
  },
  [PublicModulesEnum.RequestReset]: {
    en: "/auth/forgot",
    fr: "/fr/auth/forgot",
  },
  [PublicModulesEnum.Reset]: {
    en: "/auth/validate-reset/:userId/:code",
    fr: "/fr/auth/validate-reset/:userId/:code",
  },
  [PublicModulesEnum.Invite]: {
    en: "//auth/validate-reset/:userId/:code",
    fr: "/fr/auth/invite/:userId/:code",
  },
};

export function getPublicPath(module: PublicModulesEnum, locale: SupportedLocales | undefined) {
  const localeUsed = locale === SupportedLocales.FR ? locale : SupportedLocales.EN;

  return LOGIN_NOT_REQUIRED_PATHS[module][localeUsed];
}

export function TermsOfServiceLink({ locale, children }: { locale: SupportedLocales | undefined; children: React.ReactNode }) {
  const url = locale === SupportedLocales.FR ? "https://www.trolley.com/fr/legal-agreements" : "https://www.trolley.com/legal-agreements";

  return <ExternalLink href={url}>{children}</ExternalLink>;
}

export default function LoginNotRequired() {
  const { state } = useLocation<{ redirect?: { pathname?: string } } | null>();
  const { data: user } = useUser();

  return user ? (
    <Redirect to={state?.redirect?.pathname ? state.redirect : "/"} />
  ) : (
    <ConnectedIntlProvider>
      <Switch>
        <Route path={["/login", "/fr/login"]} component={LoginPage} />
        <Route path={["/register", "/fr/register"]} component={RegisterPage} />
        <Route path={["/auth/verify/:userId/:code", "/fr/auth/verify/:userId/:code"]} component={VerifyEmail} />

        <Route path={["/auth/forgot", "/fr/auth/forgot"]} component={RequestResetPasswordPage} />
        <Route path={["/auth/validate-reset/:userId/:code", "/fr/auth/validate-reset/:userId/:code"]} component={ResetPassword} />
        <Route path={["/auth/invite/:userId/:code", "/fr/auth/invite/:userId/:code"]} component={ResetPassword} />
        <Route component={PageNotFound} />
      </Switch>
    </ConnectedIntlProvider>
  );
}
