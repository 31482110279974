import { Box, Heading, Paragraph } from "components";
import React, { ReactNode } from "react";

export default function OnboardingSection(props: { id?: string; title: ReactNode; description?: ReactNode; children: ReactNode }) {
  return (
    <>
      <Heading tag="h2" id={props.id}>
        {props.title}
      </Heading>
      <Box>
        {props.description && <Paragraph type="secondary">{props.description}</Paragraph>}
        {props.children}
      </Box>
    </>
  );
}
