import { Menu as AntMenu, MenuProps as AntMenuProps } from "antd";
import React from "react";

export { ItemType } from "antd/lib/menu/hooks/useItems";

export interface MenuProps extends AntMenuProps {}
export default function Menu(props: MenuProps) {
  return <AntMenu {...props} />;
}

Menu.Item = AntMenu.Item;
Menu.Divider = AntMenu.Divider;
Menu.ItemGroup = AntMenu.ItemGroup;
Menu.SubMenu = AntMenu.SubMenu;
