module.exports = {
  primary: "#0095FF",
  blue: "#2C7FD8",
  green: "#35B58B",
  red: "#EE3737",
  yellow: "#E39424",
  purple: "#914393",
  black: "#0C1720",
  grey: "#B5B5B5",
  white: "#ffffff",
};
