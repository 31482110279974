import { Col, ColProps } from "antd";
import { ColSize } from "antd/lib/grid";
import React from "react";
import css, { classnames, createUseStyle } from "style/classname";

type ColSpanType = string | number;
export type GridItemProps = Omit<ColProps, "xs" | "sm" | "md" | "lg" | "xl" | "xxl"> & {
  align?: "left" | "center" | "right";
  alignSelf?: "auto" | "flex-start" | "flex-end" | "center" | "baseline" | "stretch";
  xs?: ColSpanType | Omit<ColSize, "flex">;
  sm?: ColSpanType | Omit<ColSize, "flex">;
  md?: ColSpanType | Omit<ColSize, "flex">;
  lg?: ColSpanType | Omit<ColSize, "flex">;
  xl?: ColSpanType | Omit<ColSize, "flex">;
  xxl?: ColSpanType | Omit<ColSize, "flex">;
};

export default function Item(props: GridItemProps) {
  const { className, alignSelf, align, ...rest } = props;
  const styledCol = useStyledCol(props);

  return <Col className={classnames("grid-item", styledCol, className)} {...rest} />;
}

const useStyledCol = createUseStyle<GridItemProps>(({ theme, ...props }) =>
  css`
    &.${theme.prefixCls}-col {
      font-size: inherit;
      ${props.alignSelf && `align-self: ${props.alignSelf};`}
      ${props.align && `text-align: ${props.align};`};
    }
  `(),
);
