import {
  CurrencyCode,
  formatCountry,
  formatCurrency,
  getGovIdProps,
  getPayoutMethodLabel,
  IncompleteReasonType,
  RecipientType,
} from "@trolley/common-frontend";
import { Steps } from "antd";
import { Alert, Icon, Link, Text } from "components";
import React from "react";
import { RecipientConfig } from "store/actions/recipientConfig";
import { StoreRecipient } from "store/actions/recipients";
import { useMerchantSettings } from "store/hooks/merchantSettings";
import { useRecipientAccounts } from "store/hooks/recipientAccount";
import css, { createUseStyle } from "style/classname";
import { capitalize, translateRegionByCountry } from "utils/helpers";

const useStyledSteps = createUseStyle(({ theme }) =>
  css`
    .${theme.prefixCls}-steps {
      display: flex;
      flex-wrap: wrap;
      .${theme.prefixCls}-steps-item {
        padding: 8px 12px 4px 0;
        flex-grow: 0;
        overflow: visible;
        .${theme.prefixCls}-steps-item-icon {
          border-color: ${theme.colorWarning};
          background-color: ${theme.colorWarning};
          .${theme.prefixCls}-steps-icon {
            top: 0;
          }
        }
        .${theme.prefixCls}-steps-item-content {
          min-height: auto;
          .${theme.prefixCls}-steps-item-title::after {
            display: none;
          }
        }
      }
    }
  `(),
);

type Props = {
  recipient: StoreRecipient;
  recipientConfig?: RecipientConfig | undefined;
};

export default function CompletionSteps(props: Props) {
  const styledSteps = useStyledSteps();
  const {
    recipient: { id, type, inactiveReasons = {}, address },
  } = props;
  const accounts = useRecipientAccounts(id);
  const { features, data: merchantSettings } = useMerchantSettings();
  const primaryAccount = accounts.find((a) => a.primary);
  const description: any[] = [];
  const incompleteAddress = Object.entries({
    street: inactiveReasons.street1,
    city: inactiveReasons.city,
    region: inactiveReasons.regionCode,
    "postal/ZIP code": inactiveReasons.postalCode,
    country: inactiveReasons.countryCode,
  })
    .filter(([key, reason]) => reason && [IncompleteReasonType.INVALID, IncompleteReasonType.MISSING].includes(reason))
    .map(([key, reason]) => `${reason} ${key === "region" ? translateRegionByCountry(address.country).toLocaleLowerCase() : key}`);

  if (inactiveReasons.countryCode === IncompleteReasonType.NOT_SUPPORTED) {
    /**
     * countryCode = "not-supported" is when recipient country:
     * - has a bank transfer and either:
     *    - recipient country is in a banned banking country
     *    - or bank account country is in a banned banking country
     * - or, paypal non-supported country
     * - or, venmo non-supported country
     * */
    description.push({
      key: IncompleteReasonType.NOT_SUPPORTED,
      title: (
        <span>
          {formatCountry(primaryAccount?.country || address.country)} is not supported{" "}
          {getPayoutMethodLabel(primaryAccount?.type, true) || "the payout account"}.
        </span>
      ),
    });
  }
  if (incompleteAddress.length > 0) {
    description.push({
      key: "address",
      title: (
        <span>
          Enter a valid address:
          <ul>
            {incompleteAddress.map((field) => (
              <li key={field}>{capitalize(field)}</li>
            ))}
          </ul>
        </span>
      ),
    });
  }

  if (inactiveReasons.primaryAccount) {
    description.push({
      key: "payout",
      title:
        inactiveReasons.primaryAccount === IncompleteReasonType.DISABLED ? (
          <span>
            Verify the payout account
            <Icon.Status type="error" right tooltip="A payment has been returned. Please validate recipient's payout method." />
          </span>
        ) : inactiveReasons.primaryAccount === IncompleteReasonType.INVALID ? (
          <span>Add a valid payout method</span>
        ) : (
          <span>Add a payout method</span>
        ),
    });
  }

  // this should never happen.
  if (inactiveReasons.accountCurrencyCode) {
    description.push({
      key: "accountCurrencyCode",
      title: (
        <span>
          Add a valid payout method.
          <br />
          {inactiveReasons.accountCurrencyCode === IncompleteReasonType.INVALID ? (
            primaryAccount ? (
              <>
                {formatCurrency(primaryAccount.currency as CurrencyCode)} ({primaryAccount.currency}) is not available in{" "}
                {formatCountry(primaryAccount.country)}.
              </>
            ) : (
              "The primary account currency is not supported."
            )
          ) : (
            // accountCurrenCode = MISSING should never happen. if it does... there's a bug in API
            "The primary account currency is missing."
          )}
        </span>
      ),
    });
  }

  /**
   * Explanation: InactiveReason enabledCountry: CountryCode is when the RecipientAccount is not payable due
   * the a disabled payout method country (PayoutMethod['enabledCountries'])
   */
  if (inactiveReasons.enabledCountry) {
    description.push({
      key: "enabledCountry",
      title: (
        <span>
          Add a valid payout method.
          <br />
          {formatCountry(inactiveReasons.enabledCountry)} is disabled for <Link to="/settings/payout-methods/bank-transfer">Bank Transfer</Link>
        </span>
      ),
    });
  }

  if (inactiveReasons.governmentId) {
    if (inactiveReasons.governmentId === IncompleteReasonType.COUNTRY_MISMATCH) {
      description.push({
        key: IncompleteReasonType.COUNTRY_MISMATCH,
        title: (
          <span>
            Add a valid payout method
            <Icon.Hint
              right
              tooltip={
                <>
                  A recipient in {formatCountry(address.country)} cannot setup a bank account in {formatCountry(primaryAccount?.country)}
                </>
              }
            />
          </span>
        ),
      });
    } else {
      // use primary account country. The goverment ID should match the account country.
      const govIdCountry = primaryAccount?.country || address.country;
      const defaultGovIdProps = getGovIdProps(govIdCountry, type as "individual" | "business");

      const countryDocumentTypes =
        type === RecipientType.INDIVIDUAL ? props.recipientConfig?.documentTypes?.individual : props.recipientConfig?.documentTypes?.business;
      const newGovIdProps = { label: countryDocumentTypes?.map((doc) => doc.value).join(" / "), tooltip: undefined };

      const govIdProps = newGovIdProps.label !== undefined ? newGovIdProps : defaultGovIdProps;

      description.push({
        key: "govid",
        title: (
          <>
            {inactiveReasons.governmentId === IncompleteReasonType.INVALID ? "Verify your " : "Add a "}
            government ID{govIdCountry && ` for ${formatCountry(govIdCountry)}`}
            {govIdProps?.label && (
              <Text>
                {govIdProps.label}
                {govIdProps.tooltip && <Icon.Hint tooltip={govIdProps.tooltip} right />}
              </Text>
            )}
          </>
        ),
      });
    }
  }

  if (inactiveReasons.type) {
    description.push({
      key: "accountType",
      title: (
        <>
          Change the recipient type. "{type}" is not supported in {formatCountry(address.country)}.
        </>
      ),
    });
  }

  if (inactiveReasons.firstName || inactiveReasons.lastName) {
    description.push({
      key: "name",
      title: "Add a first and last name",
    });
  }

  if (inactiveReasons.phone) {
    description.push({
      key: "phone",
      title: `Add a ${inactiveReasons.phone === "invalid" ? "valid " : ""}phone number`,
    });
  }

  if (inactiveReasons.dob) {
    description.push({
      key: "dob",
      title: "Add a date of birth",
    });
  }

  if (inactiveReasons.taxForm) {
    switch (inactiveReasons.taxForm) {
      case IncompleteReasonType.TAXFORM_REQUIRES_REVIEW:
        description.push({
          key: "taxForm",
          title: (
            <>
              Review a submitted <Link to={`/recipients/${id}/tax`}>tax form</Link>.
            </>
          ),
        });
        break;
      case IncompleteReasonType.TAXFORM_EXPIRED:
        description.push({
          key: "taxForm",
          title: (
            <>
              Tax form expired. A new <Link to={`/recipients/${id}/tax`}>tax form</Link> is required.
            </>
          ),
        });
        break;
      default:
        description.push({
          key: "taxForm",
          title: (
            <>
              A <Link to={`/recipients/${id}/tax`}>tax form</Link> is required.
            </>
          ),
        });
    }
  }

  if (inactiveReasons.taxProfile) {
    switch (inactiveReasons.taxProfile) {
      case IncompleteReasonType.TAXFORM_REQUIRES_REVIEW:
        description.push({
          key: "taxProfile",
          title: "Review a submitted tax profile.",
        });
        break;
      case IncompleteReasonType.TAXFORM_EXPIRED:
        description.push({
          key: "taxProfile",
          title: "Tax profile expired. A new tax profile is required.",
        });
        break;
      case IncompleteReasonType.TAX_PROFILE_THRESHOLD_REACHED:
        description.push({
          key: "taxProfile",
          title: "The recipient has crossed the Small Goods Seller Threshold, they must submit a new tax profile",
        });
        break;
      default:
        description.push({
          key: "taxProfile",
          title: "A tax profile is required.",
        });
    }
  }

  if (inactiveReasons.approvedIdVerification) {
    const dsaEnabled = !!features.dsa && !!merchantSettings?.trust?.requireDSA;
    description.push({
      key: "approvedIdVerification",
      title: dsaEnabled && type === RecipientType.BUSINESS ? "Approved business verification is required" : "Approved individual verification is required",
    });
  }

  return (
    <Alert showIcon={false} size="large" type="warning" header="Incomplete profile">
      <div className={styledSteps}>
        <Text>Complete the following steps to activate the recipient:</Text>
        {description.length > 0 ? (
          <Steps size="small" direction="vertical">
            {description.map((d) => (
              <Steps.Step key={d.key} title={d.title} description={d.description} status="process" />
            ))}
          </Steps>
        ) : (
          <Text padded>
            <Icon color="red" type="circle-exclamation" theme="solid" left />
            Unable to display the incomplete reasons correctly. Please contact support.
            {Object.entries(inactiveReasons).map(([key, value]) => (
              <div key={key}>
                <strong>{key}: </strong>
                {value}
              </div>
            ))}
          </Text>
        )}
      </div>
    </Alert>
  );
}
