import { Access, CurrencyCode, formatCountry, PayoutMethodType } from "@trolley/common-frontend";
import { Alert, Button, Checkbox, Divider, Form, FormInstance, Grid, Input, Link } from "components";
import { UserAccess } from "features/user";
import React, { forwardRef, useEffect } from "react";
import { isPaypalSettings } from "store/actions/payoutMethods";
import { PayPalAccountUpdate, RecipientAccount } from "store/actions/recipientAccount";
import { StoreRecipient } from "store/actions/recipients";
import { usePayoutMethods } from "store/hooks/payoutMethods";
import { handleFormErrors } from "utils/helpers";

type Props = {
  recipientCountry: StoreRecipient["address"]["country"];
  recipientAccount: RecipientAccount | undefined;
  onSubmit(changes?: PayPalAccountUpdate): Promise<void>;
};

interface FormFields {
  currency: CurrencyCode.USD;
  emailAddress: string;
  emailAddressConfirm: string;
  primary?: boolean;
}

export default forwardRef<FormInstance<FormFields>, Props>((props, ref) => {
  const { recipientCountry, recipientAccount, onSubmit } = props;
  const [form] = Form.useForm<FormFields>();
  const { data: payoutMethods } = usePayoutMethods();
  const paypalPayout = payoutMethods.find((pm) => pm.integration === PayoutMethodType.PAYPAL);

  useEffect(() => {
    if (typeof ref === "function") {
      ref(form);
    }
  }, [form]);

  async function onFinish({ emailAddressConfirm, ...changes }: FormFields) {
    try {
      await onSubmit({
        ...changes,
        type: PayoutMethodType.PAYPAL,
      });
    } catch (errors) {
      handleFormErrors(errors, form);
    }
  }

  return (
    <Form form={form} onFinish={onFinish} initialValues={recipientAccount}>
      <Form.Item noStyle name="currency" initialValue={CurrencyCode.USD} />
      {!paypalPayout?.enabled && (
        <Alert type="warning">
          PayPal is disabled.{" "}
          <UserAccess type={Access.SETTINGS_WRITE}>
            Go to <Link to="/settings/payout-methods/paypal">Paypal Settings</Link> to enable it.
          </UserAccess>
        </Alert>
      )}

      {recipientCountry &&
        paypalPayout?.enabled &&
        isPaypalSettings(paypalPayout.settings) &&
        !paypalPayout.settings.allowRestrictedCountries &&
        paypalPayout.settings.restrictedCountries.includes(recipientCountry) && (
          <Alert type="warning">
            <strong>{formatCountry(recipientCountry)}</strong> is an unsupported country by PayPal.{" "}
            <UserAccess type={Access.SETTINGS_WRITE}>
              Go to{" "}
              <Link
                to={{
                  pathname: "/settings/payout-methods/paypal",
                  state: {
                    highlightId: "allowRestrictedCountries",
                  },
                }}
              >
                PayPal Settings
              </Link>{" "}
              to allow payments to unsupported countries.
            </UserAccess>
          </Alert>
        )}

      <Grid padding={["medium", "small"]}>
        <Grid.Item xs={24} md={12}>
          <Form.Item
            label="PayPal Email Address"
            tooltip="The email address you use for PayPal"
            name="emailAddress"
            rules={[
              { required: true, message: "Email address is required" },
              { type: "email", message: "Enter a valid email address" },
            ]}
          >
            <Input name="emailAddress" placeholder="name@example.com" autoComplete="new-email" />
          </Form.Item>
        </Grid.Item>
        <Grid.Item xs={24} md={12}>
          <Form.Item
            label="Confirm PayPal Email Address"
            name="emailAddressConfirm"
            dependencies={["emailAddress"]}
            rules={[
              { required: true, message: "Confirm the email" },
              (form) => ({
                async validator(rule, value) {
                  if (value && value !== form.getFieldValue("emailAddress")) {
                    throw "Email must be the same";
                  }
                },
              }),
            ]}
          >
            <Input name="emailAddressConfirm" placeholder="name@example.com" autoComplete="confirm-email" />
          </Form.Item>
        </Grid.Item>
      </Grid>

      {!recipientAccount && (
        <>
          <Divider margin="small" transparent />
          <Form.Item name="primary" valuePropName="checked" noStyle>
            <Checkbox>Set Account as Active</Checkbox>
          </Form.Item>
        </>
      )}
      <Button hidden htmlType="submit" />
    </Form>
  );
});
