import { useEffect, useState } from "react";
import { useShallowSelector } from ".";
import { loadRecipientTaxForms } from "store/actions/recipientTaxForms";
import { emptyList } from "store/reducers/standardReducer";
import { TaxForm, TaxFormsQuery } from "store/actions/taxForms";
import { computeID } from "store/actions/actionUtils";

export function useRecipientTaxForms(recipientId: string | undefined, query: TaxFormsQuery, optionalLoad: boolean = true) {
  const id = recipientId && /^R-\w+/.test(recipientId) ? recipientId : "";
  const [fetchId, setFetchId] = useState("");

  useEffect(() => {
    if (id && optionalLoad) {
      setFetchId(loadRecipientTaxForms(id, query));
    } else {
      setFetchId("");
    }
  }, [id, optionalLoad, computeID(query)]);

  return useShallowSelector((state) => {
    if (id && optionalLoad && fetchId && state.recipientTaxForms.fetchStatus[fetchId] === undefined) {
      loadRecipientTaxForms(id, query);
    }

    const data = state.recipientTaxForms.entities[fetchId];

    return {
      data: data
        ? {
            records: data.records.map((taxId) => state.taxForm.entities[taxId]).filter((v) => v) as TaxForm[],
            meta: data.meta,
          }
        : emptyList,
      status: state.recipientTaxForms.fetchStatus[fetchId],
      error: state.recipientTaxForms.errors[fetchId],
    };
  });
}
