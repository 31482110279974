import { PaymentCategory, TaxIncomeCode } from "@trolley/common-frontend/lib/types";

// Payment categories on which US Tax Override can be applied to. Otherwise, forceUsTaxActivity is ignored
export const FORCE_US_TAX_CATEGORIES = Object.freeze([PaymentCategory.SERVICES, PaymentCategory.RENT]);

// Payment categories that are not tax reportable, and thus should ignore the 'taxReportable' flag.
export const NON_TAXABLE_CATEGORIES = Object.freeze([PaymentCategory.REFUNDS, PaymentCategory.EDUCATION, PaymentCategory.GOODS]);

// Payment categories that are not tax reportable for DAC7, and thus should ignore the 'taxReportable' flag.
export const NON_TAXABLE_CATEGORIES_DAC7 = Object.freeze([PaymentCategory.REFUNDS, PaymentCategory.EDUCATION]);

export const CATEGORY_DETAILS: Record<
  PaymentCategory,
  { name: string; incomeCode?: TaxIncomeCode; nonUsLabel?: string; usLabel?: string; nonTaxLabel?: string }
> = {
  [PaymentCategory.SERVICES]: {
    name: "Services",
    incomeCode: TaxIncomeCode.INDEPENDENT_PERSONAL_SERVICE_17,
    nonUsLabel: "Income code 17 - Independant personal services",
    usLabel: "1099-NEC Box 1 - Nonemployee Compensation",
  },
  [PaymentCategory.RENT]: {
    name: "Rent",
    incomeCode: TaxIncomeCode.REAL_PROPERTY_INCOME,
    nonUsLabel: "Income code 14 - Rent property income and natural resources royalties",
    usLabel: "1099-MISC Box 1 - Rents",
  },
  [PaymentCategory.ROYALTIES]: {
    name: "Royalties - Copyright",
    incomeCode: TaxIncomeCode.ROYALTIES_COPYRIGHTS_12,
    nonUsLabel: "Income code 12 - Royalties » Copyright",
    usLabel: "1099-MISC Box 2 - Royalties",
  },
  [PaymentCategory.ROYALTIES_FILM]: {
    name: "Royalties - Film & TV",
    incomeCode: TaxIncomeCode.ROYALTIES_FILM_TV,
    nonUsLabel: "Income code 11 - Royalties » Film and TV",
    usLabel: "1099-MISC Box 2 - Royalties",
  },
  [PaymentCategory.SERVICES_MARKETPLACE]: {
    name: "Third Party Settlement",
    nonTaxLabel: "International Payments: Not Tax Reportable",
    usLabel: "1099-K - Box 1a & 1b",
  },
  [PaymentCategory.PRIZES]: {
    name: "Prizes and Awards",
    incomeCode: TaxIncomeCode.OTHER_INCOME,
    nonUsLabel: "Income code 23 - Other Income",
    usLabel: "1099-MISC Box 3 - Other Income",
  },
  [PaymentCategory.GOODS]: {
    name: "Goods",
    nonTaxLabel: "Goods are non-taxable in the U.S.",
  },
  [PaymentCategory.REFUNDS]: {
    name: "Refunds",
    nonTaxLabel: "Refunds are non-taxable in the U.S.",
  },
  [PaymentCategory.EDUCATION]: {
    name: "Education",
    nonTaxLabel: "Education fees are non-taxable in the U.S.",
  },
};
