import { Box, CopyToClipboard, DateDisplay, Divider, FileDownload, Flyout, Form, FormItem, Text } from "components";
import { RecipientPreview, RecipientProfile } from "features/recipient";
import { TaxFormActions, TaxFormDisplay } from "features/taxForm";
import { InvalidTINWarnings, voidReasonText } from "features/taxForm/StatusTaxForm";
import { UserProfile } from "features/user";
import React, { useState } from "react";
import { getTaxFormLabel, WithholdingReasonLabels } from "store/actions/recipientTaxForms";
import { TaxForm, UsUpload } from "store/actions/taxForms";
import { useMerchantSettings } from "store/hooks/merchantSettings";
import { useTaxForm } from "store/hooks/taxForms";
import { CATEGORY_DETAILS } from "utils/constants";
import { PaymentCategory, TaxFormType, TaxIncomeCode, TaxStatus } from "@trolley/common-frontend";
import TaxWarning from "./TaxWarning";
import UploadSummary from "./UploadSummary";
import W8Summary from "./W8Summary";
import W9Summary from "./W9Summary";

interface Props {
  taxFormId?: string;
  showActions?: boolean;
  onClose(): void;
}

export default function TaxDocumentPreview(props: Props) {
  const { taxFormId, showActions, onClose } = props;
  const [recipientId, setRecipientId] = useState<string | undefined>();
  const { data: taxForm } = useTaxForm(taxFormId);
  const { data: merchant } = useMerchantSettings();

  function renderFormSummary(form: TaxForm) {
    if (form.taxFormData) {
      if (form.taxFormType === TaxFormType.W9) {
        return <W9Summary data={form.taxFormData} />;
      } else if ([TaxFormType.W8BENE, TaxFormType.W8BEN].includes(form.taxFormType as TaxFormType)) {
        return <W8Summary data={form.taxFormData} form={form} />;
      }
    }

    if (form.kind === TaxFormType.US_UPLOAD) {
      return <UploadSummary data={form.data as UsUpload} />;
    }

    return null;
  }

  function renderWithholding(calculatedWithholdings: TaxForm["calculatedWithholdings"]) {
    const enabledCategories = Object.entries(merchant?.payment?.categories || {})
      .filter(([category, enabled]) => enabled && CATEGORY_DETAILS[category]?.incomeCode) // enabled and has income code
      .map(([category, enabled]) => category) as PaymentCategory[];

    const firstCategoryWithIncome = enabledCategories.find((c) => CATEGORY_DETAILS[c]?.incomeCode);
    const firstCategoryWithholding = firstCategoryWithIncome && calculatedWithholdings[CATEGORY_DETAILS[firstCategoryWithIncome].incomeCode as TaxIncomeCode];
    const sameRate = // if all rate and reasons are the same, just show 1 rate and same reasons
      firstCategoryWithholding &&
      enabledCategories
        .filter((category) => CATEGORY_DETAILS[category]?.incomeCode)
        .every((category, i) => {
          const income = CATEGORY_DETAILS[category].incomeCode as TaxIncomeCode;

          return (
            calculatedWithholdings[income]?.withholdingPercentage === firstCategoryWithholding.withholdingPercentage &&
            calculatedWithholdings[income]?.reason.join("") === firstCategoryWithholding.reason.join("")
          );
        });

    if (sameRate && firstCategoryWithholding) {
      return (
        <div>
          {firstCategoryWithholding.withholdingPercentage}%
          {firstCategoryWithholding.reason.map((reason) => (
            <Text size="small" type="secondary" key={reason}>
              {WithholdingReasonLabels[reason] || reason}
            </Text>
          ))}
        </div>
      );
    }

    return enabledCategories.map((key) => {
      const reasons: string[] = calculatedWithholdings[CATEGORY_DETAILS[key].incomeCode || ""]?.reason || [];

      return (
        <div key={key}>
          {calculatedWithholdings[CATEGORY_DETAILS[key].incomeCode || ""]?.withholdingPercentage}% for {CATEGORY_DETAILS[key]?.name}
          {reasons.map((reason) => (
            <Text size="small" type="secondary" key={reason}>
              {WithholdingReasonLabels[reason] || reason}
            </Text>
          ))}
        </div>
      );
    });
  }

  return (
    <Flyout
      width="small"
      visible={!!taxForm}
      onClose={onClose}
      title={taxForm && <TaxFormDisplay taxFormId={taxForm.id} showStatus />}
      subtitle={
        taxForm && (
          <>
            <Divider margin="small" />
            <RecipientProfile
              recipientId={taxForm.recipientId}
              showLink={() => {
                setRecipientId(taxForm.recipientId);
              }}
              size="small"
              showEmail
            />
          </>
        )
      }
      extra={taxForm && showActions && <TaxFormActions taxFormId={taxForm.id} />}
    >
      {taxForm && (
        <Form layout="horizontal" compact>
          {taxForm.warnings.length > 0 && <TaxWarning taxForm={taxForm} />}
          <Box padding="small" header="Tax Form Details">
            {taxForm.status === TaxStatus.VOIDED && taxForm.voidReason && (
              <FormItem label="Tax Form Voided Reason" data-test="void-reason">
                {voidReasonText[taxForm.voidReason] || taxForm.voidReason}
              </FormItem>
            )}

            {taxForm?.tinStatus === "not_valid" && (
              <FormItem label="US TIN Status" data-test="tin-status">
                <Text type="error">Invalid</Text>
                <InvalidTINWarnings taxForm={taxForm} />
              </FormItem>
            )}

            <FormItem label="Download Form">
              {getTaxFormLabel(taxForm.taxFormType as TaxFormType)}
              <FileDownload
                url={`/v1/recipients/${taxForm.recipientId}/tax/${taxForm.id}/download`}
                fileName={`PR_${taxForm.recipientId}_taxform_${taxForm.id}`}
                tooltip="Download PDF"
                icon
                right
              />
            </FormItem>

            <FormItem label="Created On">
              <DateDisplay value={taxForm.createdAt} time={false} icon={false} />
            </FormItem>
            <FormItem label="Updated On">
              <DateDisplay value={taxForm.updatedAt} time={false} icon={false} />
            </FormItem>
            <FormItem label="Withholding">{renderWithholding(taxForm.calculatedWithholdings)}</FormItem>
            <FormItem label="Form ID">
              <CopyToClipboard value={taxForm.id} />
            </FormItem>

            {taxForm.signedAt && (
              <>
                <FormItem label="Signed On">
                  <DateDisplay value={taxForm.signedAt} time={false} showUtc />
                </FormItem>
                <FormItem label="Signed By">{taxForm.signed || " - "}</FormItem>
              </>
            )}

            {taxForm.reviewedAt && (
              <>
                <FormItem label="Reviewed On">
                  <DateDisplay value={taxForm.reviewedAt} time={false} showUtc />
                </FormItem>
                <FormItem label="Reviewed By">{taxForm.reviewedBy ? <UserProfile userId={taxForm.reviewedBy} /> : " - "}</FormItem>
              </>
            )}

            {taxForm.voidedAt && (
              <>
                <FormItem label="Voided On">
                  <DateDisplay value={taxForm.voidedAt} time={false} />
                </FormItem>
                <FormItem label="Voided By">{taxForm.voidedBy ? <UserProfile userId={taxForm.voidedBy} /> : " - "}</FormItem>
              </>
            )}
          </Box>

          {renderFormSummary(taxForm)}
        </Form>
      )}
      <RecipientPreview
        recipientId={recipientId}
        onClose={() => {
          setRecipientId(undefined);
        }}
      />
    </Flyout>
  );
}
