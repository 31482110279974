import store from "store";
import { OpCode, standardDispatch } from "store/dispatcher";
import { POST } from "services/request";
import * as types from "@trolley/common-frontend";
import { isLoaded } from "./actionUtils";

export interface Onboarding extends types.Onboarding {}

export type OnboardingUpdate = Partial<
  Pick<
    Onboarding,
    | "businessCountry"
    | "businessLegalName"
    | "businessAsName"
    | "businessWebsite"
    | "businessCategory"
    | "businessPurpose"
    | "businessPpm"
    | "businessTotalMonthly"
    | "businessType"
    | "businessTaxId"
    | "businessAddress"
    | "businessCity"
    | "businessRegion"
    | "businessZip"
    | "businessPhone"
    | "corporateStructure"
    | "noOwnership"
    | "completedAt"
    | "primaryCurrency"
    | "expectedPayoutCountries"
    | "businessIntlPercentage"
  >
>;

export function loadOnboarding(force?: boolean) {
  const { onboarding } = store.getState();

  if (force || !isLoaded(onboarding.fetchStatus.data)) {
    standardDispatch(OpCode.LOADING, "onboarding");

    POST<types.OnboardingResult>("/v1/onboarding/get")
      .then((res) => {
        standardDispatch(OpCode.DATA, "onboarding", {
          data: {
            ...res.body.profile,
            persons: res.body.profile.persons?.sort((a, b) => {
              const dateA = new Date(a.createdAt);
              const dateB = new Date(b.createdAt);

              return dateA < dateB ? -1 : dateA > dateB ? 1 : 0;
            }),
          },
        });
      })
      .catch((errors) => {
        standardDispatch(OpCode.ERROR, "onboarding", {
          errors,
        });
      });
  }
}

export async function updateOnboarding(change: OnboardingUpdate) {
  try {
    standardDispatch(OpCode.LOADING, "onboarding");
    const { body } = await POST<types.OnboardingResult>("/v1/onboarding/update", { query: change });
    standardDispatch(OpCode.DATA, "onboarding", {
      data: body.profile,
    });

    return body.profile;
  } catch (errors) {
    standardDispatch(OpCode.ERROR, "onboarding", {
      errors,
    });
    throw errors;
  }
}

export async function completeOnboarding() {
  try {
    standardDispatch(OpCode.LOADING, "onboarding");
    const { body } = await POST<types.OnboardingResult>("/v1/onboarding/complete");
    standardDispatch(OpCode.DATA, "onboarding", {
      data: body.profile,
    });

    return body.profile;
  } catch (errors) {
    standardDispatch(OpCode.ERROR, "onboarding", {
      errors,
    });
    throw errors;
  }
}

export async function uploadOnboardingFile(file: FormData, type: "crc" | "shareRegister" | "additionalDoc") {
  file.set("type", type);
  const { body } = await POST<{ uploadId: string }>("/v1/onboarding/documents/upload", {
    isUpload: true,
    body: file,
  });
  loadOnboarding(true);

  return body.uploadId;
}

export async function uploadOnboardingFileV2(file: FormData) {
  const { body } = await POST<{ uploadId: string }>("/v1/onboarding/documents/upload", {
    isUpload: true,
    body: file,
  });
  loadOnboarding(true);

  return body.uploadId;
}

export async function removeOnboardingFile(uploadId: string) {
  await POST("/v1/onboarding/documents/delete", { query: { uploadId } });
  loadOnboarding(true);
}

export async function completeBankOnboarding() {
  try {
    standardDispatch(OpCode.LOADING, "onboarding");
    const { body } = await POST<types.OnboardingResult>("/v1/onboarding/bank/complete");
    standardDispatch(OpCode.DATA, "onboarding", {
      data: body.profile,
    });
  } catch (errors) {
    standardDispatch(OpCode.ERROR, "onboarding", {
      errors,
    });
    throw errors;
  }
}
