import { CountryCode } from "@trolley/common-frontend";
import { Breadcrumb } from "antd";
import { Icon } from "components";
import { RecipientProfile } from "features/recipient";
import React, { ReactNode } from "react";
import { Link, useLocation } from "react-router-dom";
import css, { createUseStyle } from "style/classname";
import { capitalize } from "utils/helpers";

function isRecipient(id: string): boolean {
  return /R-\w+/.test(id);
}

interface Props {
  startIndex?: number;
  showLinks?: boolean;
}
export default function Breadcrumbs(props: Props) {
  const { showLinks = true, startIndex = 0 } = props;
  const location = useLocation();
  const styledBreadcrumbs = useStyledBreadcrumbs();

  let pathSnippets = location.pathname.split("/").filter((v) => v);

  if (!pathSnippets.length) {
    pathSnippets = ["dashboard"];
  } else if (pathSnippets[0] === "reports") {
    pathSnippets.splice(1, pathSnippets.length - 1);
  }

  if (pathSnippets.length > 1 && startIndex > 0) {
    pathSnippets = pathSnippets.slice(startIndex);
  }

  const lastIndex = pathSnippets.length - 1;

  return (
    <Breadcrumb separator={<Icon type="angle-right" fixedWidth={false} />} className={styledBreadcrumbs}>
      {pathSnippets.map((_, index) => {
        const url = `/${pathSnippets.slice(0, index + 1).join("/")}`;
        let title: ReactNode = capitalize(
          pathSnippets[index]?.replace?.(/[-_]/g, " ").replace(/^(\w)\s([\w]+)$/i, "$1-$2"), // for entity ID. like paument ID, recipient ID R-123456
        );
        if (typeof title === "string" && isRecipient(title)) {
          title = <RecipientProfile recipientId={title} onlyName />;
        }

        if (typeof title === "string" && (title.toLocaleUpperCase() === "EU" || title.toLocaleUpperCase() in CountryCode)) {
          title = title.toLocaleUpperCase();
        }

        return <Breadcrumb.Item key={url}>{index === lastIndex || !showLinks ? title : <Link to={url}>{title}</Link>}</Breadcrumb.Item>;
      })}
    </Breadcrumb>
  );
}

const useStyledBreadcrumbs = createUseStyle(({ theme }) =>
  css`
    display: inline-block;
    .${theme.prefixCls}-breadcrumb-link {
      color: ${theme.colorTextSecondary};
      a {
        color: ${theme.colorPrimaryText};
      }
    }
  `(),
);
