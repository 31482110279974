import store from "store";
import { OpCode, standardDispatch } from "store/dispatcher";
import { POST } from "services/request";
import * as types from "@trolley/common-frontend";
import { isLoaded } from "./actionUtils";

export type TaxMerchantYear = types.TaxMerchantYear.TaxMerchantYear;

export type TaxMerchantYearUpdate = {
  contactName: string;
  contactPhone: string;
  contactEmail: string;
  organizationName: string;
  mailingAddress: string;
  mailingCity: string;
  mailingCountry: string;
  mailingRegion: string;
  mailingPostalCode: string;
  nonUSentity: boolean;
  // irsControlCode?: string; // Depreacted. TCC for 1099. Trolley has its own
  taxUsId?: string; // required for 1099
  taxForeignId?: string; // optional for 1042
  // irsControlCode1042?: string; //  Depreacted. TCC for 1042 for E-File Generation. Trolley has its own
  tax1042chapter3code: string; // required for 1042
  tax1042chapter4code: string; // required for 1042
  tax1099KFilerType?: string; // required for 1099-K, available since 2023
};

export function loadTaxMerchantYear(taxYear: number, force?: boolean) {
  const data = store.getState().taxMerchantYear;

  if (force || !isLoaded(data.fetchStatus[taxYear])) {
    standardDispatch(OpCode.LOADING, "taxMerchantYear", {
      id: taxYear,
    });

    POST<types.TaxMerchantYear.Result>("/v1/tax-year/config/get", { query: { taxYear } })
      .then((res) => {
        standardDispatch(OpCode.DATA, "taxMerchantYear", {
          id: taxYear,
          data: res.body.taxYear,
        });
      })
      .catch((errors) => {
        standardDispatch(OpCode.ERROR, "taxMerchantYear", {
          id: taxYear,
          errors,
        });
      });
  }
}

export async function updateTaxMerchantYear(change: TaxMerchantYearUpdate, taxYear: number) {
  standardDispatch(OpCode.LOADING, "taxMerchantYear", {
    id: taxYear,
  });

  try {
    const { body } = await POST<types.TaxMerchantYear.Result>("/v1/tax-year/config/update", { query: { ...change, taxYear } });
    standardDispatch(OpCode.DATA, "taxMerchantYear", {
      id: taxYear,
      data: body.taxYear,
    });

    return body.taxYear;
  } catch (errors) {
    standardDispatch(OpCode.ERROR, "taxMerchantYear", {
      id: taxYear,
      errors,
    });
    throw errors;
  }
}
