import { Grid, TitleProps } from "components";
import React, { HTMLAttributes, ReactNode } from "react";
import css, { classnames, createUseStyle } from "style/classname";
import colors from "style/colors";
import palette from "style/palette";
import { MEDIA_BREAKPOINTS } from "style/variables";

type Props = {
  header?: ReactNode;
  extraActions?: TitleProps["extraActions"];
  disabled?: boolean;
  footer?: ReactNode;
  footerProps?: Exclude<HTMLAttributes<HTMLDivElement>, "children" | "className">;
  padding?: "none" | "xsmall" | "small" | "large" | "xlarge";
  color?: keyof typeof colors;
  flat?: boolean;
} & HTMLAttributes<HTMLElement>;

export default function Box(props: Props) {
  const { header, extraActions, footer, footerProps, padding, className, color, flat, children, onClick, disabled, ...rest } = props;
  const styledBox = useStyledBox(props);

  return (
    <section
      {...rest}
      className={classnames("box", styledBox, className)}
      onClick={disabled ? undefined : onClick}
      role={onClick && !disabled ? "button" : undefined}
    >
      {(header || extraActions) && (
        <div className="box-header">
          {extraActions ? (
            <Grid align="middle" wrap={false}>
              <Grid.Item flex={1}>{header}</Grid.Item>
              <Grid.Item className="box-header__actions">{extraActions}</Grid.Item>
            </Grid>
          ) : (
            header
          )}
        </div>
      )}
      {children && <div className="box-content">{children}</div>}
      {footer && (
        <div {...footerProps} className="box-footer">
          {footer}
        </div>
      )}
    </section>
  );
}

const useStyledBox = createUseStyle<Props>(({ theme, ...props }) =>
  css`
    :not(:first-child) {
      margin-top: 16px;
    }
    :not(:last-child) {
      margin-bottom: 16px;
    }
    box-shadow: ${props.flat ? `none` : theme.boxShadowTertiary};
    overflow: hidden;
    display: flex;
    flex-direction: column;
    border-radius: ${theme.borderRadius}px;
    transition: background-color 0.2s ease;
    border-width: ${theme.lineWidth}px;
    border-style: ${theme.lineType};
    &.box {
      ${() => {
        if (props.disabled) {
          return `
          cursor: not-allowed;
          border-color: ${theme.colorBorder};
          background: ${palette["grey-alpha-1"]};
          & > .box-content {
            opacity: 0.7;
          }
        `;
        }

        if (props.color && colors[props.color]) {
          return `
          border-color: ${palette[`${props.color}-4`] ?? theme.colorBorder};
          background-color: ${palette[`${props.color}-alpha-2`]};
        `;
        }

        if (props.flat) {
          return `
          border-color: ${theme.colorBorder};
          background-color: ${theme.colorFillAlter};
        `;
        }

        return `
        border-color: transparent;
        background-color: ${theme.colorBgContainer};
    `;
      }}
    }

    ${() =>
      !props.disabled &&
      props.onClick &&
      `
        &.box {
          cursor: pointer;
          &:hover {
            border-color: ${props.color ? palette[`${props.color}-3`] : palette["primary-3"]};
            background-color: ${props.color ? palette[`${props.color}-alpha-1`] ?? palette[`${props.color}-1`] : palette["primary-alpha-1"]};
            &:active {
              border-color: ${props.color ? palette[`${props.color}-4`] : palette["primary-4"]};
              background-color: ${props.color ? palette[`${props.color}-alpha-3`] ?? palette[`${props.color}-3`] : palette["primary-alpha-2"]};
            }
          }
        }

  `};

    & > .box-header {
      font-size: ${theme.fontSizeHeading3}px;
      line-height: ${theme.lineHeightHeading3};
      font-weight: bold;
      ${props.children && `border-bottom: ${theme.lineWidth}px ${theme.lineType} ${theme.colorBorderSecondary};`}
      .box-header__actions {
        font-size: ${theme.fontSize}px;
      }
    }
    & > .box-content {
      flex: 1 1 auto;
      padding: ${() => {
        switch (props.padding) {
          case "none":
            return "0";
          case "xsmall":
            return "8px";
          default:
            return "16px";
        }
      }};
      ${MEDIA_BREAKPOINTS.mdUp} {
        padding: ${() => {
          switch (props.padding) {
            case "none":
              return `0px`;
            case "xsmall":
              return `8px`;
            case "small":
              return `16px`;
            case "large":
              return `16px 32px`;
            case "xlarge":
              return `32px 48px`;
            default:
              return `16px 24px`;
          }
        }};
      }
    }
    & > .box-footer {
      border-top: 1px solid ${theme.colorBorderSecondary};
    }

    & > .box-header,
    & > .box-footer {
      padding: 8px 16px;
      ${MEDIA_BREAKPOINTS.mdUp} {
        padding: ${() => {
          switch (props.padding) {
            case "xsmall":
            case "none":
            case "small":
              return `12px 16px`;
            case "large":
              return `12px 32px`;
            default:
              return `12px 24px`;
          }
        }};
      }
    }
  `(),
);
