import { Typography as AntTypography } from "antd";
import { TitleProps as AntTitleProps } from "antd/lib/typography/Title";
import { Space } from "components";
import React, { PropsWithChildren, ReactNode } from "react";
import css, { classnames, createUseStyle } from "style/classname";

export interface TitleProps extends AntTitleProps {
  extraActions?: ReactNode;
  padded?: boolean;
  capitalize?: boolean;
}

export default function Title(props: PropsWithChildren<TitleProps>) {
  const { children, extraActions, className, padded, ...rest } = props;
  const styledTitle = useStyledTitle(props);

  return (
    <AntTypography.Title {...rest} className={classnames(styledTitle, className)}>
      {extraActions ? (
        <Space justify="space-between">
          <span>{children}</span>
          <span>{extraActions}</span>
        </Space>
      ) : (
        children
      )}
    </AntTypography.Title>
  );
}

const useStyledTitle = createUseStyle<TitleProps>(({ theme, ...props }) =>
  css`
    &.${theme.prefixCls}-typography.${theme.prefixCls}-typography {
      ${props.capitalize && `text-transform: capitalize;`}
      ${props.padded === false &&
      `
        margin-top: 0;
        margin-bottom: 0;
      `}
      &:first-child {
        margin-top: 0;
      }
    }
  `(),
);
