import { CountryCode, getPayoutMethodLabel, PayoutMethodType, UsTeritories } from "@trolley/common-frontend";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

import { Alert, Button, ButtonDelete, Grid, Modal, Radio, Space } from "components";
import { FormInstance } from "components/Form";
import CurrenciesLoader from "features/currencies/CurrenciesLoader";
import { PayoutMethodDisplay } from "features/payoutMethod";
import { notifyError, notifySuccess } from "store/actions/notifications";
import { isVenmoSettings } from "store/actions/payoutMethods";
import {
  addRecipientPayoutMethod,
  deleteRecipientPayoutMethod,
  RecipientAccount,
  RecipientAccountUpdate,
  updateRecipientPayoutMethod,
} from "store/actions/recipientAccount";
import { StoreRecipient } from "store/actions/recipients";
import { useMerchantSettings } from "store/hooks/merchantSettings";
import { usePayoutMethods } from "store/hooks/payoutMethods";
import css, { createUseStyle } from "style/classname";
import { MEDIA_BREAKPOINTS } from "style/variables";
import PayoutBankTransferForm from "./PayoutBankTransferForm";
import PayoutBitcoinForm from "./PayoutBitcoinForm";
import PayoutCheckForm from "./PayoutCheckForm";
import PayoutInteracForm from "./PayoutInteracForm";
import PayoutPaypalForm from "./PayoutPaypalForm";
import PayoutVenmoForm from "./PayoutVenmoForm";

interface Props {
  recipient: StoreRecipient;
  account?: RecipientAccount | {};
  onClose(): void;
}

function isAccount(account: RecipientAccount | {} | undefined): account is RecipientAccount {
  return !!account && "id" in account;
}

export default function PayoutMethodsForm(props: Props) {
  const { account, recipient } = props;
  const formRef = useRef<FormInstance | null>();
  const { features } = useMerchantSettings();
  const [busy, setBusy] = useState(false);
  const [type, setType] = useState<PayoutMethodType>();
  const { data: payoutMethods } = usePayoutMethods();
  const styledPayouts = useStyledPayouts();
  const venmoPayout = payoutMethods.find((pm) => pm.integration === PayoutMethodType.VENMO);
  const savedAccount = isAccount(account) ? account : undefined;

  useEffect(() => {
    if (account && payoutMethods.length) {
      if ("id" in account) {
        setType(account.type as PayoutMethodType);
      } else if (payoutMethods.some((pm) => pm.integration === PayoutMethodType.BANKTRANSFER && pm.enabled)) {
        setType(PayoutMethodType.BANKTRANSFER);
      } else if (payoutMethods.some((pm) => pm.integration === PayoutMethodType.PAYPAL && pm.enabled)) {
        setType(PayoutMethodType.PAYPAL);
      } else if (
        recipient.address.country &&
        UsTeritories.includes(recipient?.address?.country) &&
        features.payoutCheck &&
        payoutMethods.some((pm) => pm.integration === PayoutMethodType.CHECK && pm.enabled)
      ) {
        setType(PayoutMethodType.CHECK);
      } else if (
        recipient.address.country === CountryCode.CA &&
        features.payoutInterac &&
        payoutMethods.some((pm) => pm.integration === PayoutMethodType.INTERAC && pm.enabled)
      ) {
        setType(PayoutMethodType.INTERAC);
      } else if (features.payoutBitcoin && payoutMethods.some((pm) => pm.integration === PayoutMethodType.BITCOIN && pm.enabled)) {
        setType(PayoutMethodType.BITCOIN);
      } else if (features.payoutVenmo && payoutMethods.some((pm) => pm.integration === PayoutMethodType.VENMO && pm.enabled && isVenmoEligible())) {
        setType(PayoutMethodType.VENMO);
      } else {
        setType(undefined);
      }
    } else {
      setType(undefined);
    }
  }, [account, payoutMethods]);

  function isVenmoEligible() {
    return (
      !!recipient.address.country &&
      !!venmoPayout &&
      isVenmoSettings(venmoPayout.settings) &&
      venmoPayout.settings.allowedCountries.includes(recipient.address.country)
    );
  }

  async function onFinish(formValues?: RecipientAccountUpdate) {
    if (type && formValues) {
      try {
        setBusy(true);

        if (savedAccount) {
          await updateRecipientPayoutMethod(recipient.id, formValues, savedAccount.id);
        } else {
          await addRecipientPayoutMethod(recipient.id, formValues);
        }
        notifySuccess(`Payout method ${savedAccount ? "saved" : "added"}`);
        setBusy(false);
        props.onClose();
      } catch (errors) {
        setBusy(false);
        throw errors;
      }
    } else {
      if (savedAccount) {
        notifySuccess("Payout method saved");
      }
      props.onClose();
    }
  }

  async function onSetActive() {
    setBusy(true);
    try {
      if (savedAccount && !savedAccount.primary) {
        await updateRecipientPayoutMethod(recipient.id, { primary: true }, savedAccount.id);
      }
      notifySuccess("Primary payout method saved");
      setBusy(false);
      props.onClose();
    } catch (errors) {
      setBusy(false);
      notifyError("Setting primary payout method failed", { errors });
    }
  }

  async function onDelete() {
    if (savedAccount) {
      setBusy(true);
      try {
        await deleteRecipientPayoutMethod(recipient.id, savedAccount.id);
        notifySuccess("Payout method deleted");
        setBusy(false);
        props.onClose();
      } catch (err) {
        setBusy(false);
        notifyError("Deleting payout method failed");
      }
    }
  }

  function renderPayoutSelection() {
    if (!account || savedAccount) {
      return null;
    }

    return (
      <Radio.Group
        className={styledPayouts}
        value={type}
        defaultValue={PayoutMethodType.BANKTRANSFER}
        optionType="button"
        onChange={(e) => {
          setType(e.target.value);
        }}
        options={Object.values(PayoutMethodType)
          .filter((integration) => {
            const accessMap: Record<PayoutMethodType, boolean> = {
              [PayoutMethodType.CHECK]: !!features.payoutCheck && !!recipient.address.country && UsTeritories.includes(recipient.address.country),
              [PayoutMethodType.INTERAC]: !!features.payoutInterac && recipient.address.country === CountryCode.CA,
              [PayoutMethodType.BITCOIN]: !!features.payoutBitcoin,
              [PayoutMethodType.PAYPAL]: true,
              [PayoutMethodType.BANKTRANSFER]: true,
              [PayoutMethodType.VENMO]: !!features.payoutVenmo && isVenmoEligible(),
            };

            return accessMap[integration];
          })
          .map((integration) => ({
            value: integration,
            label: (
              <>
                <PayoutMethodDisplay value={integration} size="xlarge" />
                <div>{getPayoutMethodLabel(integration, true)}</div>
              </>
            ),
          }))}
      />
    );
  }

  return (
    <Modal
      width={640}
      title={savedAccount ? <>Update {getPayoutMethodLabel(savedAccount.type, true) || "payout method"}</> : "Add a payout method"}
      visible={!!account}
      onCancel={props.onClose}
      footer={
        <Grid direction="row-reverse" padding={["small", "large"]}>
          <Space direction="row-reverse">
            <Button
              type="primary"
              onClick={() => {
                formRef?.current?.submit();
              }}
              loading={busy}
            >
              {savedAccount ? "Save" : "Add"} Payout Method
            </Button>

            {savedAccount && !savedAccount.primary && (
              <Button disabled={busy} onClick={onSetActive}>
                Set Active
              </Button>
            )}

            <Button type="text" onClick={props.onClose}>
              Cancel
            </Button>
          </Space>
          {savedAccount && (
            <Grid.Item flex="1" align="left">
              <ButtonDelete confirmType="modal" title="Delete this payout method?" onConfirm={onDelete} disabled={busy}>
                Delete
              </ButtonDelete>
            </Grid.Item>
          )}
        </Grid>
      }
    >
      <>
        {renderPayoutSelection()}

        {type === PayoutMethodType.BANKTRANSFER && (
          <CurrenciesLoader>
            {(currencies) => (
              <PayoutBankTransferForm
                currencies={currencies}
                recipient={recipient}
                recipientAccount={savedAccount}
                ref={(form) => {
                  formRef.current = form;
                }}
                onSubmit={onFinish}
              />
            )}
          </CurrenciesLoader>
        )}

        {type === PayoutMethodType.PAYPAL && (
          <PayoutPaypalForm
            recipientCountry={recipient.address.country}
            recipientAccount={savedAccount}
            onSubmit={onFinish}
            ref={(form) => {
              formRef.current = form;
            }}
          />
        )}
        {type === PayoutMethodType.VENMO && (
          <PayoutVenmoForm
            recipientCountry={recipient.address.country}
            recipientAccount={savedAccount}
            onSubmit={onFinish}
            ref={(form) => {
              formRef.current = form;
            }}
          />
        )}
        {type === PayoutMethodType.CHECK && (
          <PayoutCheckForm
            recipientCountry={recipient.address.country}
            recipientAccount={savedAccount}
            onSubmit={onFinish}
            ref={(form) => {
              formRef.current = form;
            }}
          />
        )}

        {type === PayoutMethodType.INTERAC && (
          <PayoutInteracForm
            recipientAccount={savedAccount}
            onSubmit={onFinish}
            ref={(form) => {
              formRef.current = form;
            }}
          />
        )}

        {type === PayoutMethodType.BITCOIN && (
          <PayoutBitcoinForm
            recipientAccount={savedAccount}
            onSubmit={onFinish}
            ref={(form) => {
              formRef.current = form;
            }}
          />
        )}

        {!type && (
          <Alert type="warning" title="You have not enabled any payout methods.">
            Make sure the payout methods are enabled in <Link to="/settings/payout-methods">Settings</Link>
          </Alert>
        )}
      </>
    </Modal>
  );
}

const useStyledPayouts = createUseStyle(({ theme }) =>
  css`
    &.${theme.prefixCls}-radio-group {
      display: flex;
      flex-wrap: wrap;
      row-gap: 4px;
      width: fit-content;
      margin-bottom: 24px;
      .${theme.prefixCls}-radio-button-wrapper {
        min-width: 100px;
        ${MEDIA_BREAKPOINTS.mdUp} {
          min-width: 130px;
        }
        padding: 16px 8px 8px;
        transition: all 0.175s ease;
        line-height: inherit;
        min-height: 75px;
        justify-content: center;
        align-items: center;
      }
    }
  `(),
);
