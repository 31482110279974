/* eslint-disable import/no-mutable-exports */
import { Modal as AntModal, App, ModalFuncProps, ModalProps as Props } from "antd";
import { ModalStaticFunctions } from "antd/lib/modal/confirm";
import { ConnectedRouter } from "connected-react-router";
import React, { PropsWithChildren, ReactNode } from "react";
import { IntlProvider } from "react-intl";
import { Provider } from "react-redux";

import { Icon } from "components";
import history from "services/history";
import store from "store";
import styled, { classnames } from "style/classname";
import { defaultLocale } from "utils/helpers";

export function defaultModalWrapper(node: ReactNode) {
  return (
    <Provider key="app" store={store}>
      <IntlProvider locale={defaultLocale}>
        <ConnectedRouter history={history}>{node}</ConnectedRouter>
      </IntlProvider>
    </Provider>
  );
}

export interface ModalProps extends Omit<Props, "destroyOnClose" | "open" | "visible" | "closeIcon"> {
  /**
   * Make the Modal visible
   */
  visible?: boolean;
  // theme?: ThemeConfig;
  // children?: ReactNode;
}

export default function Modal({ visible: open, maskClosable = true, modalRender = defaultModalWrapper, ...props }: ModalProps) {
  return (
    <AntModal
      destroyOnClose
      maskClosable={maskClosable}
      closeIcon={<Icon type="xmark" fixedWidth={false} />}
      modalRender={modalRender}
      open={!!open}
      {...props}
    />
  );
}

export type PickedModalFncProps = Pick<
  ModalFuncProps,
  | "modalRender"
  | "title"
  | "icon"
  | "maskClosable"
  | "width"
  | "content"
  | "bodyStyle"
  | "okText"
  | "onOk"
  | "okButtonProps"
  | "wrapClassName"
  | "afterClose"
  | "cancelText"
  | "cancelButtonProps"
  | "onCancel"
>;

export interface ConfirmProps extends PickedModalFncProps {
  type?:
    | "delete" // red modal with Trash icon
    | "danger"; // red modal
}

let modalStaticFn: Omit<ModalStaticFunctions, "warn">;

/**
 * ModalContextWrapper allows to use the config theme on the modal
 * https://ant.design/components/app#global-scene-redux-scene
 * this is the only way to render the modal based on the ConfigProvider theme
 * */
export function ModalContextWrapper(props: PropsWithChildren<any>) {
  modalStaticFn = App.useApp().modal;

  return <>{props.children}</>;
}

Modal.confirm = ({ type, width = 520, icon, maskClosable = true, okButtonProps, cancelButtonProps, wrapClassName, ...rest }: ConfirmProps) =>
  (modalStaticFn?.confirm || AntModal.confirm)?.({
    width,
    closable: true,
    closeIcon: <Icon type="xmark" fixedWidth={false} />,
    maskClosable,
    autoFocusButton: null,
    icon: icon ?? (
      <Icon
        type={type === "delete" ? "circle-trash" : "circle-question"}
        theme="solid"
        color={type && ["danger", "delete"].includes(type) ? "red" : "yellow"}
      />
    ),
    cancelButtonProps: {
      tabIndex: -1,
      ...cancelButtonProps,
    },
    okButtonProps: {
      icon: type === "delete" ? <Icon type="trash" theme="solid" left /> : undefined,
      danger: type === "delete" || type === "danger",
      ghost: type === "delete" || type === "danger",
      ...okButtonProps,
    },
    wrapClassName: classnames(wrapClassName, styledModalWrapper),
    ...rest,
  });

Modal.info = ({ icon = <Icon.Status type="info" />, width = 520, bodyStyle, maskClosable = true, wrapClassName, ...rest }: PickedModalFncProps) =>
  (modalStaticFn?.info ?? AntModal.info)?.({
    closable: true,
    autoFocusButton: null,
    width,
    wrapClassName: classnames(wrapClassName, styledModalWrapper),
    maskClosable,
    icon,
    closeIcon: <Icon type="xmark" fixedWidth={false} />,
    okType: "default",
    ...rest,
  });

Modal.success = ({ icon = <Icon.Status type="success" />, width = 520, bodyStyle, maskClosable = true, wrapClassName, ...rest }: PickedModalFncProps) => {
  return modalStaticFn?.success?.({
    closable: true,
    autoFocusButton: null,
    width,
    wrapClassName: classnames(wrapClassName, styledModalWrapper),
    maskClosable,
    icon,
    closeIcon: <Icon type="xmark" fixedWidth={false} />,
    okType: "default",
    ...rest,
  });
};

Modal.warning = ({ icon = <Icon.Status type="warning" />, width = 520, bodyStyle, maskClosable = true, wrapClassName, ...rest }: PickedModalFncProps) =>
  (modalStaticFn?.warning ?? AntModal.warning)?.({
    closable: true,
    autoFocusButton: null,
    width,
    wrapClassName: classnames(wrapClassName, styledModalWrapper),
    maskClosable,
    icon,
    closeIcon: <Icon type="xmark" fixedWidth={false} />,
    okType: "default",
    ...rest,
  });

Modal.destroyAll = AntModal.destroyAll;

const styledModalWrapper = styled`
  [class*='-modal-confirm-body-wrapper'] {
    text-align: center;
    [class*='-modal-confirm-body'] {
      &>.icon {
        font-size: 48px;
        margin: 0 auto 16px;
      }
      [class*='-modal-confirm-title'] {
        // in the deisgn system, the normal modal header fontsize is 18px, and confirm header is 24.
        // since the component variables share the same font size, we have to overwrite one.
        font-size: 24px;
        line-height: calc(32 / 24);
      }
      [class*='-modal-confirm-content'] {
        max-width: 100%; // by default it's calc(100% - ##px)
      }
    }
    [class*='-modal-confirm-btns'] {
      text-align: center;
    }
  }
`();
