import { useEffect } from "react";
import { loadBalances } from "store/actions/balances";
import { useShallowSelector } from "store/hooks";
import { Account, PayoutMethodType } from "@trolley/common-frontend";
import { MerchantSettings } from "./merchantSettings";

const PAYOUT_METHOD_ORDER: Account["providerType"][] = [
  PayoutMethodType.BANKTRANSFER,
  PayoutMethodType.PAYPAL,
  PayoutMethodType.VENMO,
  PayoutMethodType.INTERAC,
  PayoutMethodType.CHECK,
  PayoutMethodType.BITCOIN,
];

function sortAccounts(a: Account, b: Account) {
  if (a.providerType !== b.providerType) {
    return PAYOUT_METHOD_ORDER.indexOf(a.providerType) - PAYOUT_METHOD_ORDER.indexOf(b.providerType);
  }

  if (a.primary || b.primary) {
    if (a.primary) {
      return -1;
    }
    if (b.primary) {
      return 1;
    }
  }

  return Number(b.amount.value) - Number(a.amount.value);
}

function getDemoAccount(merchantSettings: MerchantSettings): Account {
  const currency = merchantSettings.primaryCurrency;

  return {
    id: "",
    accountNumber: "demo",
    primary: true,
    provider: merchantSettings.bankProvider,
    amountCleared: {
      value: "0.00",
      currency,
    },
    amountPending: {
      value: "0.00",
      currency,
    },
    pendingCredit: {
      value: "0.00",
      currency,
    },
    pendingDebit: {
      value: "0.00",
      currency,
    },
    amount: {
      value: "0.00",
      currency,
    },
    providerType: PayoutMethodType.BANKTRANSFER,
    visible: true,
    active: true,
    adminVisible: true,
  };
}

export function useBalances() {
  useEffect(() => {
    loadBalances();
  }, []);

  return useShallowSelector((state) => {
    if (state.balances.fetchStatus.data === undefined) {
      loadBalances();
    }

    const merchantSettings = state.merchantSettings.entities.data as MerchantSettings;
    const balances = Object.values(state.balances.entities);

    return {
      data: balances.length ? (balances.sort(sortAccounts) as Account[]) : [getDemoAccount(merchantSettings)],
      status: state.balances.fetchStatus.data,
      error: state.balances.errors.data,
    };
  });
}
