import { useEffect, useState } from "react";
import { loadRecipientVerifications } from "../actions/verifications";
import { useShallowSelector } from ".";

export function useRecipientVerifications(recipientId: string, force?: boolean) {
  const id = recipientId && /^R-\w+/.test(recipientId) ? recipientId : "";
  const [fetchId, setFetchId] = useState("");

  useEffect(() => {
    setFetchId(loadRecipientVerifications(id, force));
  }, [id]);

  return useShallowSelector((state) => {
    if (id && fetchId && state.verifications.fetchStatus[fetchId] === undefined) {
      loadRecipientVerifications(id);
    }

    return {
      data: state.verifications.entities[fetchId],
      status: state.verifications.fetchStatus[fetchId],
      error: state.verifications.errors[fetchId],
    };
  });
}
