import React from "react";
import { useMerchantSettings } from "store/hooks/merchantSettings";
import Alert, { AlertProps } from ".";

const ALERTS = {
  recipientCreate: "Are you sure? ",
  recipientsUpload: "Are you sure you want to import recipients to this sub-merchant account? ",
};

export const DEFAULT_SYNC_ALERT_MESSAGE =
  "Changes made to recipients at the sub-merchant level do not synchronize with the parent account or any other sub-merchant accounts.";

interface Props extends Omit<AlertProps, "title" | "header" | "children"> {
  messageType?: keyof typeof ALERTS;
}

export default function AlertSyncRecipients(props: Props) {
  const { type = "warning", messageType = "recipientCreate", ...rest } = props;
  const { data: merchantSettings } = useMerchantSettings();

  return !!merchantSettings?.parentMerchantId && !merchantSettings?.sandbox && merchantSettings?.inheritedSettings?.syncRecipients ? (
    <Alert type={type} {...rest} header={ALERTS[messageType]}>
      {DEFAULT_SYNC_ALERT_MESSAGE}
    </Alert>
  ) : null;
}
