import { isSupportedOnboardingCountry } from "@trolley/common-frontend";
import { Anchor, Box, Grid, Icon } from "components";
import React from "react";
import { useOnboarding } from "store/hooks/onboarding";
import css, { createUseStyle } from "style/classname";
import { useWindowSize } from "utils/hooks";
import { FormNames } from ".";
import { getCompanyDetailsCompletion } from "./CompanyDetails";
import { isCompanyCertificateComplete } from "./CompanyRegistrationCertificate";
import { getPayoutInformationCompletion } from "./PayoutInformation";
import { getDirectorLabel, getSigningOfficersCompletion } from "./SigningOfficer";

export default function OnboardingSteps() {
  const { isMobile } = useWindowSize();
  const { data: onboarding } = useOnboarding();
  const styledAnchor = useStyledAnchor();
  const styledMobileAnchor = useStyledMobileAnchor();
  const baseFieldSet = !!onboarding?.businessCountry && !!onboarding.businessType;

  const links: {
    key: string;
    label: string;
    status: null | "partial" | "completed";
  }[] = onboarding
    ? [
        {
          key: "app",
          label: "Company Details",
          status: getCompanyDetailsCompletion(onboarding),
        },
        ...(isSupportedOnboardingCountry(onboarding.businessCountry, "NA")
          ? [
              {
                key: FormNames.COMPANY_CERTIFICATE,
                label: "Business Certificate",
                status: isCompanyCertificateComplete(onboarding),
              },
            ]
          : []),
        {
          key: FormNames.SIGNING_OFFICER,
          label: `${getDirectorLabel(onboarding)}s`,
          status: getSigningOfficersCompletion(onboarding),
        },
        {
          key: FormNames.PAYOUT_INFORMATION,
          label: "Payout Information",
          status: getPayoutInformationCompletion(onboarding),
        },
      ]
    : [];

  if (isMobile) {
    // small screen
    return (
      <Anchor className={styledMobileAnchor} targetOffset={110}>
        <Grid padding="small">
          {links.map((link) => (
            <Grid.Item key={link.key} span={12}>
              <Box padding="none" color="grey" disabled={!baseFieldSet}>
                <Anchor.Link
                  href={`#${link.key}`}
                  className={styledAnchorLink({ enabled: baseFieldSet })}
                  title={
                    <>
                      {link.label}
                      {baseFieldSet && link.status && <Icon.Status type={link.status === "completed" ? "success" : "warning"} right />}
                    </>
                  }
                />
              </Box>
            </Grid.Item>
          ))}
        </Grid>
      </Anchor>
    );
  }

  // return (
  return (
    <Anchor targetOffset={80} offsetTop={88} className={styledAnchor} showInkInFixed={false}>
      {links.map((link) => (
        <Anchor.Link
          key={link.key}
          href={`#${link.key}`}
          className={styledAnchorLink({ enabled: baseFieldSet })}
          title={
            <>
              {link.label}
              {baseFieldSet && link.status && <Icon.Status type={link.status === "completed" ? "success" : "warning"} right />}
            </>
          }
        />
      ))}
    </Anchor>
  );
}

const useStyledMobileAnchor = createUseStyle(({ theme }) =>
  css`
    &.${theme.prefixCls}-anchor-wrapper {
      overflow: visible;
      background-color: ${theme.colorBgBase};
      padding: 16px 0;
      .grid {
        margin: "-8px -16px";
        overflow: "visible";
      }
      .${theme.prefixCls}-anchor {
        &::before,
        .${theme.prefixCls}-anchor-ink {
          display: none !important;
        }
        .${theme.prefixCls}-anchor-link {
          padding: 4px 8px;
        }
      }
    }
  `(),
);

const useStyledAnchor = createUseStyle(({ theme }) =>
  css`
    .${theme.prefixCls}-anchor {
      &::before,
      .${theme.prefixCls}-anchor-ink {
        display: none !important;
      }
      .${theme.prefixCls}-anchor-link {
        padding: ${theme.paddingXXS}px 0;
        .${theme.prefixCls}-anchor-link-title {
          display: inline-block;
          font-weight: bold;
          overflow: visible;
          padding: ${theme.paddingXS}px;
          &.${theme.prefixCls}-anchor-link-title-active {
            background-color: ${theme.controlItemBgActive};
            border-radius: ${theme.borderRadius}px;
          }
        }
      }
    }
  `(),
);

const styledAnchorLink = css<{ enabled: boolean }>`
  ${(props) =>
    !props.enabled &&
    `
    pointer-events: none;
  `}
`;
