import { CountryCode, CurrencyCode, formatCountry, getCurrencyLabel } from "@trolley/common-frontend";
import { Tooltip } from "components";
import React, { ReactNode } from "react";
import { classnames } from "style/classname";
import { styledFlagIcon, styledFlagWrapper } from "./style";

export type Props = {
  code: CurrencyCode | CountryCode | null | undefined | "EU";
  size?: "large";
  showLabel?: boolean;
  showTooltip?: boolean;
  flagStyle?: React.CSSProperties;
  suffix?: ReactNode;
};

export default function Flag({ showLabel = true, ...props }: Props) {
  if (!props.code || (props.code !== "EU" && !(props.code in CountryCode) && !(props.code in CurrencyCode))) {
    return null;
  }

  const flagCode = getFlagCode(props.code);
  const isCurrency = props.code in CurrencyCode;
  const labelstr = isCurrency ? getCurrencyLabel(props.code as CurrencyCode, true) : props.code === "EU" ? "Europe" : formatCountry(props.code);
  const icon = <i className={classnames("flag", `flag-${flagCode}`)} style={props.flagStyle} />;
  const label = isCurrency ? (
    <>
      <strong>{props.code}</strong> - {labelstr}
    </>
  ) : (
    <>{labelstr}</>
  );

  return (
    <span className={classnames("flag-wrapper", styledFlagWrapper)}>
      <div className={classnames("flag-icon", styledFlagIcon(props))}>{props.showTooltip && labelstr ? <Tooltip title={labelstr}>{icon}</Tooltip> : icon}</div>
      {showLabel && <span className="flag-label">{label}</span>}
      {props.suffix && <span className="flag-suffix">{props.suffix}</span>}
    </span>
  );
}

function getFlagCode(text: string) {
  const code = String(text).toLocaleUpperCase().substring(0, 2);

  if (code in CountryCode || code === "EU") {
    return code.toLowerCase();
  }

  return "xx";
}
