import { Space as AntSpace, SpaceProps as AntSpaceProps } from "antd";
import React, { CSSProperties } from "react";

import css, { classnames, createUseStyle } from "style/classname";

const GAPS = {
  small: 4,
  middle: 8,
  large: 16,
  xlarge: 48,
  xxlarge: 128,
};
export interface SpaceProps extends Omit<AntSpaceProps, "direction" | "align"> {
  gap?: keyof typeof GAPS;
  direction?: CSSProperties["flexDirection"];
  justify?: CSSProperties["justifyContent"];
  align?: AntSpaceProps["align"] | "stretch";
  inline?: boolean;
}

export default function Space(props: SpaceProps) {
  const { className, direction, justify, inline, align = "center", gap = "small", wrap = true, ...rest } = props;
  const styledSpace = useStyledSpace({ ...rest, inline, direction, justify, align, gap });

  return <AntSpace className={classnames("space", styledSpace, className)} size={GAPS[gap]} wrap={wrap} {...rest} />;
}

const useStyledSpace = createUseStyle<SpaceProps>(({ theme, ...props }) =>
  css`
    &.${theme.prefixCls}-space {
      display: ${props.inline ? "inline-flex" : "flex"};
      ${props.align && `align-items: ${props.align};`}
      ${props.direction && `flex-direction: ${props.direction};`}
      ${props.direction && props.direction.includes("column") && `min-height: 100%;`};
      ${props.justify && `justify-content: ${props.justify};`}
      ${props.justify === "stretch" && // this won't work if the component has flexible width (ie. width: auto | % )
      // the workaround is to make inner spaceitem grow
      `
        &>.${theme.prefixCls}-space-item {
          flex-grow: 1;
        }
      `}
    }
  `(),
);
