import Tooltip, { TooltipProps } from "components/Tooltip";
import React, { CSSProperties, ReactNode } from "react";
import styled, { classnames, createUseStyle, useThemeToken } from "style/classname";
import colors from "style/colors";

const COLOR_TABLE: Record<string, keyof typeof colors> = {
  processed: "green",
  200: "green",
  202: "green",
  yes: "green",
  verified: "green",
  complete: "green",
  completed: "green",
  enabled: "green",
  active: "green",
  reviewed: "green",
  approved: "green",
  sent: "green",
  success: "green",
  valid: "green", // for tax form tinStatus
  paid: "green", // chargebee invoice status
  posted: "green", // chargebee invoice status
  opened: "green", // sendgrid email status
  delivered: "green", // sendgrid email status
  read: "green", // sendgrid email status
  mailed: "green", // lob email status
  rerouted: "green", // lob email status

  partial_paid: "yellow", // invoice
  submitted: "yellow",
  incomplete: "yellow",
  in_progress: "yellow",
  pending: "yellow",
  awaiting_approval: "yellow",
  open: "yellow",
  invite: "yellow",
  invited: "yellow",
  sandbox: "yellow",
  needs_review: "yellow", // for recipientEoyTax status
  new: "yellow", // for tax form tinStatus
  hold: "yellow",
  draft: "yellow", // invoice
  subscription_required: "yellow",
  retry: "yellow", // verification status
  review: "yellow", // verification status

  400: "red",
  401: "red",
  403: "red",
  404: "red",
  500: "red",
  no: "red",
  important: "red",
  returned: "red", // in payment
  blocked: "red",
  error: "red",
  suspended: "red",
  failed: "red",
  not_submitted: "red",
  // voided: "red",
  not_valid: "red", // for tax form tinStatus
  payment_due: "red", // chargebee invoice status
  not_paid: "red", // chargebee invoice status
  bounced: "red", // sendgrid email status
  rejected: "red", // verification status

  primary: "blue",
  default: "blue",
  processing: "black",
  provided: "blue", // resolution ticket status
  display: "blue", // merchant feed
  recommended: "blue",
};

const getColor = (status: StatusProps["type"], colorsMap: StatusProps["colors"]): keyof typeof colors => {
  return (status && colorsMap?.[status]) || COLOR_TABLE[String(status).toLowerCase()] || "grey";
};

export type StatusProps = {
  className?: string;
  style?: CSSProperties;
  colors?: Record<string, keyof typeof colors>; // override colors
  type: string | number | null | undefined;
  fullwidth?: boolean;
  left?: boolean;
  right?: boolean;
  dot?: boolean;
  /**
   * stronger color intensity
   * Typically used in a tooltip where background is dark
   *  */
  stronger?: boolean;
  tooltipProps?: TooltipProps;
  size?: "small" | "large";
  prefix?: ReactNode;
  suffix?: ReactNode;
  formatString?: (str: string | number) => string;
  children?: ReactNode;
};

export default function Status(props: StatusProps) {
  const {
    type,
    colors,
    left,
    right,
    className,
    stronger: opaque,
    dot,
    style,
    suffix,
    prefix,
    tooltipProps,
    formatString = (text: string | number) => {
      return String(text).replace(/[_-]/g, " ");
    },
    children,
  } = props;
  const token = useThemeToken();
  const styledTag = useStyledTag({ ...props, color: getColor(type, colors), stronger: opaque });
  if (!type) {
    return null;
  }

  const element = dot ? (
    <div
      className={className}
      style={{
        width: "calc(1em - 4px)",
        height: "calc(1em - 4px)",
        display: "inline-block",
        lineHeight: "inherit",
        backgroundColor: token[`${getColor(type, colors)}-5`],
        marginLeft: right ? "6px" : undefined,
        marginRight: left ? "6px" : undefined,
        borderRadius: "50%",
        ...style,
      }}
    />
  ) : (
    <div className={classnames("status", styledTag, className)} style={style}>
      {prefix}
      {children || formatString(type)}
      {suffix}
      {!!tooltipProps && <div style={{ color: token.colorError, height: "10px", width: "10px", position: "absolute", top: "-2px", right: "-5px" }} />}
    </div>
  );

  return tooltipProps?.title ? <Tooltip {...tooltipProps}>{element}</Tooltip> : element;
}

const useStyledTag = createUseStyle<StatusProps & { color: string; opaque?: boolean }>(({ theme, color, ...props }) =>
  styled`
    text-transform: uppercase !important;
    font-size: ${theme.fontSize - 4}px;
    line-height: ${props.size === "small" ? `${theme.fontSizeSM + 8}px` : `${theme.fontSize + 6}px`};
    font-weight: bold;
    white-space: nowrap;
    padding: 0 8px;
    box-sizing: border-box;
    position: relative;
    border-radius: 20px;
    border: 0;
    color: ${theme[`${color}8`]};
    background-color: ${theme[`${color}${props.stronger ? 3 : 2}`]};
    ${
      props.type === "processing" &&
      `
      color: ${theme.green8};
      background-image: linear-gradient(90deg, ${[
        theme.colorWhite,
        props.stronger ? theme.green4 : theme.green3,
        theme.colorWhite,
        props.stronger ? theme.green4 : theme.green3,
      ].join(", ")});
      background-size: 300% auto;
      animation: wave 3s linear infinite;
      @keyframes wave {
        0% { background-position: 100% 0%;}
        100% {background-position: 0% 0%;}
      }
    `
    }
    cursor: inherit;
    text-align: center;
    display: ${props.fullwidth ? "block" : "inline-block"};
    margin-right: ${props.left ? "6px" : "0"};
    margin-left: ${props.right ? "6px" : "0"};
    vertical-align: middle;
  `(),
);
