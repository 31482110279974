import * as types from "@trolley/common-frontend";
import { batch } from "react-redux";
import * as request from "services/request";
import store from "store";
import { OpCode, standardDispatch } from "store/dispatcher";
import { queueFactory } from "utils/factories";
import { Query } from "utils/hooks";
import { computeID, groupPayments, isLoaded } from "./actionUtils";

export interface Payment extends Omit<types.Payment, "recipient" | "batch" | "status"> {
  status: types.PaymentStatus | "deleted"; // delete status only exists in store
  recipientId: string;
  batchId: string;
}

export { PaymentStatus } from "@trolley/common-frontend";

export interface PaymentsQuery extends Query<Payment> {
  search?: string;

  status?: string[];
  country?: string[];
  paymentMethod?: string[];
  sourceCurrency?: string[];
  targetCurrency?: string[];
  batchId?: string[];
  // complianceStatus?: string[];
}

export const PaymentsQueryArrayKeys = ["status", "country", "paymentMethod", "sourceCurrency", "targetCurrency"];

export function resetPayments() {
  batch(() => {
    standardDispatch(OpCode.RESET, "payments");
    standardDispatch(OpCode.RESET, "batches");
  });
}

const paymentQueueLoader = queueFactory(
  (paymentIds) => {
    request
      .POST<types.PaymentListResult>("/v1/payments/search", { query: { id: paymentIds, pageSize: paymentIds.length } })
      .then(({ body }) => {
        const { payments, recipients, batches, recipientAccounts } = groupPayments(body.payments);
        batch(() => {
          standardDispatch(OpCode.DATA, "payment", {
            bulk: paymentIds.reduce((acc, id) => {
              acc[id] = payments[id] ?? undefined; // which can be undefined. this will remove the Loading state from the unfound id.

              return acc;
            }, {}),
          });
          standardDispatch(OpCode.DATA, "recipient", {
            bulk: recipients,
          });
          standardDispatch(OpCode.DATA, "recipientAccount", {
            bulk: recipientAccounts,
          });
          standardDispatch(OpCode.DATA, "batch", {
            bulk: batches,
          });
        });
      })
      .catch((errors) => {
        standardDispatch(OpCode.ERROR, "payment", {
          ids: paymentIds,
          errors,
        });
      });
  },
  (id) => /^P-\w+/.test(id),
);

export function loadPayment(paymentId: string, force?: boolean) {
  const { payment } = store.getState();
  const id = paymentId;

  if (force || !isLoaded(payment.fetchStatus[id])) {
    standardDispatch(OpCode.LOADING, "payment", { id });
    paymentQueueLoader.add(paymentId);
  }
}

export function loadPayments(rawQuery: PaymentsQuery, force?: boolean) {
  const data = store.getState().payments;
  const query = { ...rawQuery };
  if (!rawQuery.startDate && !rawQuery.endDate && rawQuery.dateColumn) {
    // if startDate and endDate is not set, querying with dateColumn is pointless
    delete query.dateColumn;
  }

  const id = computeID(query);

  if (force || !isLoaded(data.fetchStatus[id])) {
    standardDispatch(OpCode.LOADING, "payments", { id });

    request
      .GET<types.PaymentListResult>("/v1/payments", { query })
      .then(({ body }) => {
        batch(() => {
          const { payments, recipients, recipientAccounts, batches, ids } = groupPayments(body.payments);

          if (ids.length) {
            standardDispatch(OpCode.DATA, "payment", {
              bulk: payments,
            });
            standardDispatch(OpCode.DATA, "recipient", {
              bulk: recipients,
            });
            standardDispatch(OpCode.DATA, "recipientAccount", {
              bulk: recipientAccounts,
            });
            standardDispatch(OpCode.DATA, "batch", {
              bulk: batches,
            });
          }
          standardDispatch(OpCode.DATA, "payments", {
            id,
            data: { records: ids, meta: body.meta },
          });
        });
      })
      .catch((errors) => {
        standardDispatch(OpCode.ERROR, "payments", {
          errors,
          id,
        });
      });
  }

  return id;
}

export function loadRecipientPayments(recipientId: string, query: PaymentsQuery, force?: boolean) {
  const id = computeID({ ...query, id: recipientId });
  const data = store.getState().payments;

  if (force || !isLoaded(data.fetchStatus[id])) {
    standardDispatch(OpCode.LOADING, "payments", { id });

    request
      .GET<types.PaymentListResult>(`/v1/recipients/${recipientId}/payments`, { query })
      .then(({ body }) => {
        batch(() => {
          const { payments, recipients, batches, recipientAccounts, ids } = groupPayments(body.payments);

          if (ids.length) {
            standardDispatch(OpCode.DATA, "recipient", {
              bulk: recipients,
            });
            standardDispatch(OpCode.DATA, "recipientAccount", {
              bulk: recipientAccounts,
            });
            standardDispatch(OpCode.DATA, "payment", {
              bulk: payments,
            });

            standardDispatch(OpCode.DATA, "batch", {
              bulk: batches,
            });
          }
          standardDispatch(OpCode.DATA, "payments", {
            id,
            data: { records: ids, meta: body.meta },
          });
        });
      })
      .catch((errors) => {
        standardDispatch(OpCode.ERROR, "payments", {
          id,
          errors,
        });
      });
  }

  return id;
}

export async function copyPayments(batchId: string, search: { sourceBatchId?: string; paymentIds?: string[] }, recipientOnly?: boolean) {
  await request.POST("/v1/payments/copy", {
    query: {
      batchId,
      search,
      recipientOnly,
    },
  });

  resetPayments();
}

export async function movePayments(batchId: string, paymentIds: string[]) {
  await request.POST("/v1/payments/move", {
    query: {
      batchId,
      search: {
        paymentIds,
      },
    },
  });
  resetPayments();

  return batchId;
}

export async function cancelPayments(paymentIds: string[]) {
  await request.POST("/v1/payments/cancel", {
    query: { paymentIds },
  });
}

export async function returnPayments(id: string, note: string) {
  standardDispatch(OpCode.LOADING, "payment", {
    id,
  });
  try {
    await request.POST("/v1/payments/return", {
      query: { paymentIds: [id], note },
    });
    standardDispatch(OpCode.UPDATE, "payment", {
      id,
      update: {
        status: types.PaymentStatus.RETURNED,
      },
    });
  } catch (errors) {
    standardDispatch(OpCode.ERROR, "payment", {
      errors,
      id,
    });
  }
}
