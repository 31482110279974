import TrolleyWhite from "assets/images/Trolley_white.svg";
import { SinglePage } from "components";
import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import QuickBooksDisconnection from "./QuickBooksDisconnection";

export enum IntegrationPaths {
  QuickbooksDisconnection = "/integration/quickbooks/disconnection",
}

export default function SyncExternalPage() {
  return (
    <>
      <SinglePage header={<img className="trolley-logo" alt="Trolley logo" src={TrolleyWhite} style={{ height: "32px" }} />}>
        <Switch>
          <Route path={IntegrationPaths.QuickbooksDisconnection} component={QuickBooksDisconnection} />
          <Redirect to="/" />
        </Switch>
      </SinglePage>
    </>
  );
}
