import { useEffect, useState } from "react";
import { EmailMessagesQuery, loadEmailMessages, loadLobMessages } from "store/actions/messages";
import { useShallowSelector } from ".";

export function useEmailMessages(query: EmailMessagesQuery | undefined) {
  const [fetchId, setFetchId] = useState("");

  useEffect(() => {
    if (query?.userId || query?.recipientId) {
      setFetchId(loadEmailMessages(query));
    } else {
      setFetchId("");
    }
  }, [JSON.stringify(query)]);

  return useShallowSelector((state) => {
    if (fetchId && (query?.recipientId || query?.userId) && state.messages.fetchStatus[fetchId] === undefined) {
      loadEmailMessages(query);
    }

    return {
      data: state.messages.entities[fetchId] || [],
      status: state.messages.fetchStatus[fetchId],
      error: state.messages.errors[fetchId],
    };
  });
}

export function useLobMessages(relatedItemId: string | undefined) {
  const [fetchId, setFetchId] = useState("");

  useEffect(() => {
    if (relatedItemId) {
      setFetchId(loadLobMessages({ relatedItemId }));
    } else {
      setFetchId("");
    }
  }, [relatedItemId]);

  return useShallowSelector((state) => {
    if (fetchId && relatedItemId && state.messages.fetchStatus[fetchId] === undefined) {
      loadLobMessages({ relatedItemId });
    }

    return {
      data: state.messages.entities[fetchId] || [],
      status: state.messages.fetchStatus[fetchId],
      error: state.messages.errors[fetchId],
    };
  });
}
