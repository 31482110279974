import qs from "qs";
import { Query } from "utils/hooks";

export default function stringifyQuery<T extends Query>(query: T | undefined | null) {
  if (!query || typeof query !== "object") {
    return "";
  }

  const formattedQuery = Object.entries(query).reduce((acc: Query, [k, v]) => {
    if (Array.isArray(v)) {
      /**
       * // To FIX in the future:
       *
       * Currently qs.stringify does not meet our requirement
       * qs.stringify({ a: ["x,y", "z"], b: "other"}, { arrayFormat: "comma" })
       * Expect: 'a=x%2Cy,z&b=other'
       * Actual: 'a=x%2Cy%2Cz&b=other'
       *
       * At the moment, we need to encodeURIComponent each query element, and stringify using encode=false
       * */
      acc[k] = v.length ? v.map(encodeURIComponent) : ""; // to be changed when qs bug is fixed
    } else if (typeof v === "object") {
      acc[k] = v;
    } else if (v === undefined) {
      acc[k] = "";
    } else {
      acc[k] = encodeURIComponent(v);
    }

    return acc;
  }, {});

  return qs.stringify(formattedQuery, {
    addQueryPrefix: true,
    arrayFormat: "comma",
    encode: false, // to be removed when qs is fixed
  });
}
