/* istanbul ignore file */
import { Auth } from "@trolley/common-frontend";
import { promptTFA } from "features/user";
import { RequestOptions, Response } from "services/request";
import { getDeviceId } from "store/actions/auth";
import { BaseError } from "store/reducers/standardReducer";
import requestCore from "./requestCore";

/** Error 403 */
export default async function handleForbiddenAccess<T>(error: any, url: string, options: RequestOptions) {
  const tfaRequiredError: (Auth.TFAResult & BaseError) | undefined = Array.isArray(error.body.errors)
    ? error.body.errors.find((e: BaseError) => e.code === "tfa_code_required")
    : undefined;

  if (tfaRequiredError) {
    const { tfaType, challenge, transactionInfo } = tfaRequiredError;

    const deviceId = getDeviceId();

    return await promptTFA<Response<T>>(
      {
        tfaType,
        challenge,
        transactionInfo,
        url,
        deviceId,
      },
      async (tfaOtp: string) =>
        await requestCore<T>(url, {
          ...options,
          body: JSON.stringify({
            ...options.query,
            tfaOtp,
            tfaType,
            deviceId,
          }),
        }),
    );
  }

  return undefined;
}
