import { uniqueList } from "@trolley/common-frontend";
import debounce from "lodash.debounce";

/**
 * Creates a debounced invocation for a group of ids that were added.
 * The function invokes callback after the "wait" period (in ms) following
 * the last added id.
 * @param cb callback function after waiting period has passed.
 * @param filter [optional] is a way to make sure the ids match some conditions
 * @param waitTime [optional. default: 50ms] is the debouncing wait wait
 * @returns an object with an function to "add" an id, and to "cancel" the invocation
 */
export default function queueFactory(cb: (ids: string[]) => void, validator: (v: string) => boolean = () => true, waitTime: number = 50) {
  const _queue: string[] = [];

  const _debounceAdd = debounce(
    () => {
      const ids = _queue.splice(0, _queue.length);
      if (ids.length) {
        cb(uniqueList(ids));
      }
    },
    waitTime,
    {
      leading: false,
      trailing: true,
    },
  );

  function add(id: string) {
    if (typeof id === "string" && validator(id)) {
      _queue.push(id);
      _debounceAdd();
    }
  }

  function cancel() {
    _debounceAdd.cancel();
  }

  return {
    add,
    cancel,
  };
}
