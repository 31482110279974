import { ButtonLink, Subnav } from "components";
import PageNotFound from "pages/PageNotFound";
import React from "react";
import Helmet from "react-helmet";
import { Redirect, Route, Switch } from "react-router-dom";
import EndOfYear, { getLastYear } from "./EndOfYear/USTax";
import EndOfYearDAC7, { getDAC7LastYear } from "./EndOfYear/DAC7";
import PaymentTaxes from "./PaymentTaxes";
import PaymentTaxesDAC7 from "./PaymentTaxesDAC7";
import SubmittedTaxForms from "./SubmittedTaxForms";
import SubmittedTaxProfiles from "./SubmittedTaxProfiles";
import { useMerchantSettings } from "store/hooks/merchantSettings";

export const LAST_YEAR = getLastYear();
export const DAC7_INTRO_YEAR = 2023;
export const DAC7_LAST_YEAR = getDAC7LastYear();
export const US_TAX_YEARS = Array.from({ length: LAST_YEAR - 2017 }).map((_, i) => LAST_YEAR - i);
export const DAC7_TAX_YEARS = Array.from({ length: Math.max(DAC7_LAST_YEAR - DAC7_INTRO_YEAR, 1) }).map((_, i) =>
  DAC7_LAST_YEAR === DAC7_INTRO_YEAR ? DAC7_INTRO_YEAR : DAC7_LAST_YEAR - i,
);

export enum TAX_PATHS {
  TAX_FORMS = "/tax-center/tax-forms",
  TAX_FORMS_UPLOAD = "/tax-center/tax-forms/upload",
  TAX_PROFILES = "/tax-center/tax-profiles",
  EOY_REPORTING = "/tax-center/tax-reporting",
  EOY_REPORTING_DAC7 = "/tax-center/dac7/tax-reporting",
  PAYMENT_TAXES = "/tax-center/payment-taxes",
  PAYMENT_TAXES_DAC7 = "/tax-center/payment-taxes-dac7",
}

export default function TaxesPage() {
  const { data: merchantSettings, features } = useMerchantSettings();
  const displayEUTaxPages = features.euTax && merchantSettings?.euTax?.enabled;
  const displayUSTaxPages = !displayEUTaxPages;

  const subnavTabs = [
    ...(displayUSTaxPages ? [{ title: "Tax Forms", key: "tax-forms", url: TAX_PATHS.TAX_FORMS }] : []),
    ...(displayEUTaxPages ? [{ title: "Tax Profiles", key: "tax-profiles", url: TAX_PATHS.TAX_PROFILES }] : []),
    ...(displayUSTaxPages ? [{ title: "Tax Reporting", key: "tax-reporting", url: `${TAX_PATHS.EOY_REPORTING}/${LAST_YEAR}` }] : []),
    ...(displayEUTaxPages ? [{ title: "Tax Reporting", key: "tax-reporting-dac7", url: `${TAX_PATHS.EOY_REPORTING_DAC7}/${DAC7_LAST_YEAR}` }] : []),
    ...(displayUSTaxPages ? [{ title: "Payment Tax Records", key: "payment-taxes", url: TAX_PATHS.PAYMENT_TAXES }] : []),
    ...(displayEUTaxPages ? [{ title: "Payment Tax Records", key: "payment-taxes-dac7", url: TAX_PATHS.PAYMENT_TAXES_DAC7 }] : []),
  ];

  return (
    <>
      <Subnav tabs={subnavTabs} />
      <Helmet titleTemplate="%s - Tax Center - Trolley" defaultTitle="Tax Center - Trolley" />
      <Switch>
        <Redirect path="/tax-center" exact to={displayEUTaxPages ? TAX_PATHS.TAX_PROFILES : TAX_PATHS.TAX_FORMS} />
        {displayUSTaxPages && <Route path={TAX_PATHS.TAX_FORMS} component={SubmittedTaxForms} />}
        {displayEUTaxPages && <Route path={TAX_PATHS.TAX_PROFILES} component={SubmittedTaxProfiles} />}
        {displayUSTaxPages && <Route path={`${TAX_PATHS.EOY_REPORTING}/:taxYear(\\d{4})`} component={EndOfYear} />}
        {displayUSTaxPages && <Redirect exact path={TAX_PATHS.EOY_REPORTING} to={`${TAX_PATHS.EOY_REPORTING}/${LAST_YEAR}`} />}
        {displayEUTaxPages && <Route path={`${TAX_PATHS.EOY_REPORTING_DAC7}/:taxYear(\\d{4})`} component={EndOfYearDAC7} />}
        {displayEUTaxPages && <Redirect exact path={TAX_PATHS.EOY_REPORTING_DAC7} to={`${TAX_PATHS.EOY_REPORTING_DAC7}/${DAC7_LAST_YEAR}`} />}
        {displayUSTaxPages && <Route path={TAX_PATHS.PAYMENT_TAXES} component={PaymentTaxes} />}
        {displayEUTaxPages && <Route path={TAX_PATHS.PAYMENT_TAXES_DAC7} component={PaymentTaxesDAC7} />}
        <Route
          render={() => (
            <PageNotFound
              extra={
                <ButtonLink path={displayEUTaxPages ? TAX_PATHS.TAX_PROFILES : TAX_PATHS.TAX_FORMS} type="primary" ghost>
                  Return to Tax Center
                </ButtonLink>
              }
            />
          )}
        />
      </Switch>
    </>
  );
}
