/* eslint-disable @typescript-eslint/no-floating-promises */
import { App } from "antd";
import { Icon } from "components";
import React, { useEffect } from "react";
import { resetNotification } from "store/actions/notifications";
import { useShallowSelector } from "store/hooks";

export default function Notifier() {
  const notifications = useShallowSelector((state) => state.notifications);
  const { message } = App.useApp();

  useEffect(() => {
    if (notifications.length > 0) {
      notifications.forEach((n, i) => {
        const key = [n.type, i, new Date().toString()].join("-");
        const content = (
          <>
            {n.message}
            {n.closable && (
              <Icon
                type="xmark"
                color="black"
                right
                onClick={() => {
                  message.destroy(key);
                }}
              />
            )}
          </>
        );
        switch (n.type) {
          case "warning":
            message.warning({ key, content, duration: n.duration, icon: <Icon.Status type="warning" left /> });
            break;
          case "error":
            message.error({ key, content, duration: n.duration, icon: <Icon.Status type="error" left /> });
            break;
          default:
            message.success({ key, content, duration: n.duration, icon: <Icon.Status type="success" left /> });
        }
      });
      resetNotification();
    }
  }, [notifications]);

  return null;
}
