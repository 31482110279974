import { Checkbox as AntCheckbox } from "antd";
import { CheckboxGroupProps as AntCheckboxGroupProps } from "antd/lib/checkbox/Group";
import { ComponentToken } from "antd/lib/checkbox/style";
import { AliasToken } from "antd/lib/theme/internal";
import React from "react";
import css, { classnames, createUseStyle } from "style/classname";
import colors from "style/colors";

interface CheckboxGroupProps extends AntCheckboxGroupProps {}

export const BigCheckboxTheme: Partial<ComponentToken> & Partial<AliasToken> = {
  colorBorder: colors.black,
  lineWidth: 2,
  fontSize: 24,
  lineHeight: (24 + 8) / 24,
  fontSizeLG: 24,
  lineHeightLG: (24 + 8) / 24,
  controlInteractiveSize: 24, // size of checkbox
  marginXS: 0, // margin between groups of checkbox
  paddingXS: 16, // space between checkbox and text
};
export default function BigCheckboxGroup(props: CheckboxGroupProps) {
  const { className, ...rest } = props;
  const styledCheckboxGroups = useStyledCheckboxGroups(props);

  return <AntCheckbox.Group {...rest} className={classnames("big-checkbox-group", styledCheckboxGroups, className)} />;
}

const useStyledCheckboxGroups = createUseStyle<CheckboxGroupProps>(({ theme, ...props }) =>
  css`
    &.big-checkbox-group {
      display: flex;
      flex-wrap: nowrap;
      flex-direction: column;
      gap: ${theme.padding}px;
      .${theme.prefixCls}-checkbox-wrapper {
        padding: ${theme.padding}px;
        font-weight: initial; // this is to nullify the legacy app.less style
        flex: 1 1 100%;
        border-radius: ${theme.borderRadius}px;
        border-color: ${theme.colorBorder};
        border-width: ${theme.lineWidth}px;
        border-style: ${theme.lineType};
        & > .${theme.prefixCls}-checkbox {
          margin: 4px 0;
          & > span::after {
            // the check
            inset-inline-start: 30%;
            width: 28%;
            height: 50%;
          }
        }
        &:not(.${theme.prefixCls}-checkbox-wrapper-disabled) {
          &:hover {
            background-color: ${theme.colorInfoBgHover};
          }
        }
        &.${theme.prefixCls}-checkbox-wrapper-disabled {
          background-color: ${theme.colorBgContainerDisabled};
          .${theme.prefixCls}-checkbox-inner {
            border-color: ${theme.colorTextDisabled};
          }
        }
      }
      .${theme.prefixCls}-checkbox-wrapper-checked {
        background-color: ${theme.colorInfoBg};
        border-color: ${theme.colorInfoBorder};
      }
    }
  `(),
);
