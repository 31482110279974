import { OpCode, standardDispatch } from "store/dispatcher";
import { POST } from "services/request";
import * as types from "@trolley/common-frontend";
import { loadBatchSummary } from "./batchSummary";

export type ApprovalSetting = types.Approval.Settings;
export type Approval = types.Approval.Approval;
export type Approver = types.Approver.Approver;

export const URL_BATCH_APPROVE = "/v1/batches/approval/approve";

export async function approveBatch(batchId: string, approval: boolean, comment?: string) {
  const id = batchId;

  try {
    standardDispatch(OpCode.LOADING, "batch", { id });
    await POST<types.Approval.Result>(URL_BATCH_APPROVE, { query: { id, approval, comment } });
    standardDispatch(OpCode.LOADING, "batch", { id, loading: false });
    loadBatchSummary(batchId, true);
  } catch (errors) {
    standardDispatch(OpCode.ERROR, "batch", { id, errors });
    throw errors;
  }
}

interface ApproveBatchCodeParams {
  tfaOtp: string;
  tfaType: string;
  deviceId?: string;
  comment?: string;
}

export async function approveBatchWithCode(
  batchId: string,
  approval: boolean,
  { tfaOtp, tfaType, deviceId, comment }: ApproveBatchCodeParams,
  thowErrors: boolean = true,
) {
  const id = batchId;

  try {
    standardDispatch(OpCode.LOADING, "batch", { id });
    await POST<types.Approval.Result>(URL_BATCH_APPROVE, { query: { id, approval, tfaOtp, tfaType, deviceId, comment } });
    standardDispatch(OpCode.LOADING, "batch", { id, loading: false });
    loadBatchSummary(batchId, true);
  } catch (errors) {
    if (!thowErrors) {
      standardDispatch(OpCode.ERROR, "batch", { id, errors });
    } else {
      throw errors;
    }
  }
}
