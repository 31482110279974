import { Divider, Icon, LogoLoader, Text } from "components";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { authVerifyEmail } from "store/actions/auth";
import { MerchantStatus } from "@trolley/common-frontend";
import FullscreenAuth from "./FullscreenAuth";
import { useIntl } from "utils/context";
import { useLocale } from "store/hooks/locale";
import { getPublicPath, PublicModulesEnum } from "pages/LoginNotRequired";

type Params = {
  code: string;
  userId: string;
};

export default function VerifyEmail() {
  const { userId, code } = useParams<Params>();
  const [status, setStatus] = useState<undefined | "validating" | "valid" | "invalid">();
  const { formatMessage } = useIntl();
  const locale = useLocale();

  useEffect(() => {
    setStatus("validating");
    authVerifyEmail(userId, code)
      .then((merchant: { status: MerchantStatus }) => {
        setStatus("valid");
      })
      .catch(() => {
        setStatus("invalid");
      });
  }, []);

  return (
    <>
      <FullscreenAuth>
        <LogoLoader spinning={status === "validating"}>
          {status && ["valid", "invalid"].includes(status) && (
            <Text align="center" style={{ paddingTop: "24px" }}>
              <Icon type={status === "valid" ? "user-check" : "user-times"} size="3x" color={status === "valid" ? "green" : "red"} theme="solid" />
              <Text size="large" style={{ margin: "16px 0" }}>
                {status === "valid" ? formatMessage({ id: "auth.verifyEmail.thankYou" }) : formatMessage({ id: "auth.verifyEmail.emailFailed" })}
              </Text>
              {status === "valid" ? formatMessage({ id: "auth.verifyEmail.emailVerified" }) : formatMessage({ id: "auth.verifyEmail.invalidLink" })}
            </Text>
          )}
          <Divider transparent margin="small" />
          <Text align="center">
            <Link to={getPublicPath(PublicModulesEnum.Login, locale)}>
              {formatMessage({ id: "auth.verifyEmail.loginNow" })}
              <Icon type="angle-right" right />
            </Link>
          </Text>
        </LogoLoader>
      </FullscreenAuth>
    </>
  );
}
