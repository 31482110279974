import { Row, RowProps } from "antd";
import React, { CSSProperties } from "react";
import css, { classnames, createUseStyle } from "style/classname";
import { useWindowSize } from "utils/hooks";
import Item from "./Item";

enum DEFAULT_PADDING {
  none = 0,
  xsmall = 4,
  small = 8,
  medium = 16,
  large = 24,
}

enum MOBILE_PADDING {
  none = 0,
  xsmall = 4,
  small = 8,
  medium = 12,
  large = 16,
}

function getGutter(padding: GridSpacing | undefined, isMobile: boolean): [number, number] | number {
  const PADDING = isMobile ? MOBILE_PADDING : DEFAULT_PADDING;

  if (Array.isArray(padding)) {
    return [PADDING[padding[0]], PADDING[padding[1]]];
  }

  const gutter = (padding && PADDING[padding]) ?? PADDING.medium;

  return [gutter, gutter];
}

type PaddingType = keyof typeof DEFAULT_PADDING;
type GridSpacing = PaddingType | [PaddingType, PaddingType];

export interface GridProps extends Omit<RowProps, "gutter"> {
  direction?: CSSProperties["flexDirection"];
  /**
   * Padding between each grid item.
   * Default is medium 16px (12px on mobile)
   */
  padding?: GridSpacing;
}

export default function Grid(props: GridProps) {
  const { className, direction, padding, ...rest } = props;
  const { isMobile } = useWindowSize();
  const styledRow = useStyledRow(props);

  return <Row className={classnames("grid", styledRow, className)} gutter={getGutter(padding, isMobile)} {...rest} />;
}

Grid.Item = Item;

const useStyledRow = createUseStyle<GridProps>(({ theme, ...props }) =>
  css`
    &.${theme.prefixCls}-row {
      font-size: inherit; // to overwrite the genCommonStyle in antd/lib/style/index.js
      ${props.direction && `flex-direction: ${props.direction};`}
      ${props.direction && props.direction.includes("column") && `min-height: 100%;`};
    }
  `(),
);
