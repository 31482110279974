/* istanbul ignore file */
import Intl from "intl";
import PluralRules from "intl-pluralrules";

export const defaultLocale = "en";

export default function intlPolyfill(locale: string = defaultLocale) {
  global.Intl = global.Intl || Intl;

  if (global.Intl && !(global.Intl as any).PluralRules) {
    // PluralRules polyfill
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/PluralRules
    (global.Intl as any).PluralRules = PluralRules;
  }
}
