import { Access } from "@trolley/common-frontend";
import { Result } from "antd";
import { Button, Icon, LogoLoader } from "components";
import React, { Suspense, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { endSession } from "store/actions/session";
import { useUser } from "store/hooks/user";
import { BaseStatus } from "store/reducers/standardReducer";
import { lazyComponentLoader } from "utils/helpers";
import SalesforceChat from "./App/SalesforceChat";

const App = lazyComponentLoader(() => import("pages/App"));

export default function LoginRequired() {
  const { data: user, status: userStatus } = useUser();
  const history = useHistory();

  useEffect(() => {
    if (userStatus === BaseStatus.ERROR) {
      history.push({
        pathname: "/login",
        state: {
          redirect: {
            pathname: history.location.pathname,
            search: history.location.search,
          },
        },
      });
    }
  }, [userStatus]);

  if (!user) {
    return <LogoLoader />;
  }

  if (user.access.includes(Access.SUPER)) {
    return (
      <Result
        status="403"
        title="Access Denied"
        subTitle={
          <>
            <p>Super users cannot access the merchant dashboard.</p>
            <Button
              onClick={() => {
                endSession("logout");
              }}
              size="large"
              icon={<Icon type="sign-out" theme="solid" />}
            >
              Logout
            </Button>
          </>
        }
      />
    );
  }

  return (
    <>
      <SalesforceChat />
      <Suspense fallback={<LogoLoader />}>
        <App />
      </Suspense>
    </>
  );
}
