import { Access, RecipientStatus, TaxFormType, TaxStatus } from "@trolley/common-frontend";
import { Button, Dropdown, Icon, Menu, Space, Stop } from "components";
import { UserAccess } from "features/user";
import { useWhiteLabelWarning } from "features/whiteLabel";
import UploadTaxDocument from "pages/RecipientsPage/Details/Tax/UploadTaxDocument";
import VoidFormPopup from "pages/TaxCenter/VoidFormPopup";
import React, { useEffect, useState } from "react";
import { notifyError, notifySuccess } from "store/actions/notifications";
import { approveRecipientTaxForm } from "store/actions/recipientTaxForms";
import { TaxForm } from "store/actions/taxForms";
import { useRecipient } from "store/hooks/recipients";
import { useTaxForm } from "store/hooks/taxForms";

enum ButtonActions {
  APPROVE = "approve",
  VOID = "void",
  VOID_SOLICIT = "void-solicit",
  EDIT = "edit",
}

interface Props {
  taxFormId: string | undefined;
  compact?: boolean;
}

export default function TaxFormActionsWrapper(props: Props) {
  return (
    <UserAccess type={Access.TAX_WRITE}>
      <TaxFormsActions {...props} />
    </UserAccess>
  );
}

export function TaxFormsActions(props: Props) {
  const { taxFormId, compact } = props;
  const { data: taxForm } = useTaxForm(taxFormId);
  const { data: recipient } = useRecipient(taxForm?.recipientId);
  const [editUploadedForm, setEditUploadForm] = useState<TaxForm | undefined>();
  const [voidForm, setVoidForm] = useState<{ taxForms: TaxForm[]; solicit: boolean } | undefined>();
  const accessRecipientWrite = UserAccess.useAccess(Access.RECIPIENT_WRITE);
  const { whiteLabel, warning } = useWhiteLabelWarning();

  useEffect(() => {
    if (!taxFormId) {
      setEditUploadForm(undefined);
    }
  }, [taxFormId]);

  if (!taxForm) {
    return null;
  }

  function onAction(mainAction: ButtonActions) {
    if (taxForm) {
      switch (mainAction) {
        case ButtonActions.EDIT:
          if (taxForm) {
            setEditUploadForm(taxForm);
          }
          break;
        case ButtonActions.VOID_SOLICIT:
          setVoidForm({ taxForms: [taxForm], solicit: true });
          break;
        case ButtonActions.VOID:
          setVoidForm({ taxForms: [taxForm], solicit: false });
          break;
        case ButtonActions.APPROVE:
          if (taxForm) {
            approveRecipientTaxForm(taxForm.recipientId, taxForm.id)
              .then(() => {
                notifySuccess("Tax form marked as reviewed");
              })
              .catch(() => {
                notifyError("Approving tax form failed");
              });
          }
          break;
        default:
          break;
      }
    }
  }

  function getMenu() {
    if (!taxForm) {
      return null;
    }
    const recipientDeleted = recipient?.status === RecipientStatus.ARCHIVED;
    const enableApprove = taxForm.status === TaxStatus.SUBMITTED && !recipientDeleted;
    const enableVoid = taxForm.status !== TaxStatus.VOIDED && !recipientDeleted;
    const enableVoidSolicit = taxForm.status !== TaxStatus.VOIDED && !recipientDeleted;
    const enableEdit = taxForm.status !== TaxStatus.VOIDED && !recipientDeleted;

    return (
      <Dropdown
        overlay={
          <Menu
            items={[
              {
                key: ButtonActions.APPROVE,
                onClick: () => {
                  onAction(ButtonActions.APPROVE);
                },
                disabled: !enableApprove,
                label: (
                  <>
                    <Icon type="file-check" color={enableApprove ? "blue" : undefined} left />
                    Mark as Reviewed
                  </>
                ),
              },

              taxForm?.kind === TaxFormType.US_UPLOAD
                ? {
                    key: ButtonActions.EDIT,
                    disabled: !enableEdit,
                    onClick: () => {
                      onAction(ButtonActions.EDIT);
                    },
                    label: (
                      <>
                        <Icon type="pencil" left />
                        Edit Tax Form
                      </>
                    ),
                  }
                : null,
              accessRecipientWrite && whiteLabel?.taxRequired
                ? {
                    key: ButtonActions.VOID_SOLICIT,
                    onClick: () => {
                      onAction(ButtonActions.VOID_SOLICIT);
                    },
                    disabled: !(enableVoidSolicit && !warning),
                    label: (
                      <>
                        <Icon type="file-slash" left color={enableVoidSolicit && !warning ? undefined : "grey"} />
                        Void & Request Tax Form
                        {warning && <Icon type="circle-exclamation" color="red" right tooltip={warning} />}
                      </>
                    ),
                  }
                : null,

              {
                key: ButtonActions.VOID,
                onClick: () => {
                  onAction(ButtonActions.VOID);
                },
                disabled: !enableVoid,
                label: (
                  <>
                    <Icon type="file-slash" left color={enableVoid ? undefined : "grey"} />
                    Void Tax Form
                  </>
                ),
              },
            ]}
          />
        }
      >
        <Button icon={<Icon type="ellipsis" />} />
      </Dropdown>
    );
  }

  return taxForm && taxForm.status !== TaxStatus.VOIDED ? (
    <Stop>
      <Space wrap={false}>
        {recipient && recipient.status !== RecipientStatus.ARCHIVED && (
          <>
            {taxForm.status === TaxStatus.SUBMITTED && (
              <Button
                tooltipProps={compact ? { title: "Mark as Reviewed" } : undefined}
                type="primary"
                onClick={() => {
                  onAction(ButtonActions.APPROVE);
                }}
                icon={compact ? <Icon type="file-check" theme="solid" /> : undefined}
              >
                {compact ? undefined : "Mark as Reviewed"}
              </Button>
            )}
            {taxForm.status === TaxStatus.INCOMPLETE && taxForm.kind === TaxFormType.US_UPLOAD && (
              <Button
                tooltipProps={compact ? { title: "Edit Tax Form" } : undefined}
                onClick={() => {
                  onAction(ButtonActions.EDIT);
                }}
                icon={<Icon type="pencil" theme="solid" />}
              >
                {compact ? undefined : "Edit"}
              </Button>
            )}
          </>
        )}
        {getMenu()}

        <UploadTaxDocument
          taxForm={editUploadedForm}
          onUpdate={setEditUploadForm}
          onClose={() => {
            setEditUploadForm(undefined);
          }}
        />
        <VoidFormPopup
          taxForms={voidForm?.taxForms}
          solicit={voidForm?.solicit}
          onClose={() => {
            setVoidForm(undefined);
          }}
        />
      </Space>
    </Stop>
  ) : null;
}
