import { lazy } from "react";
import { storageFactory } from "utils/factories";

const storage = storageFactory(() => localStorage);

export default function lazyComponentLoader(componentImport: any) {
  return lazy(async () => {
    const pageHasAlreadyBeenForceRefreshed = JSON.parse(storage.getItem("lazy-refreshed") || "false");

    try {
      const component = await componentImport();

      storage.setItem("lazy-refreshed", "false");

      return component;
    } catch (error) {
      if (!pageHasAlreadyBeenForceRefreshed) {
        // Assuming that the user is not on the latest version of the application.
        // Let's refresh the page immediately.
        storage.setItem("lazy-refreshed", "true");

        return window.location.reload();
      }

      // The page has already been reloaded
      // Assuming that user is already using the latest version of the application.
      // Let's let the application crash and raise the error.
      throw error;
    }
  });
}
