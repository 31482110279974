import { EndOfYear, EndOfYearFormType, SerializerMetaBlock } from "@trolley/common-frontend";
import store from "store";
import { OpCode, standardDispatch } from "store/dispatcher";
import { POST } from "services/request";
import { computeID, isLoaded } from "./actionUtils";

export enum ExportType {
  F1099_NEC = "f1099_nec",
  F1099 = "f1099",
  F1042_AMENDED = "f1042_amended",
  F1042_ORIG = "f1042_orig",
}

export enum EoyExportStatusMerchant {
  PENDING = "pending",
  PROCESSED = "processed",
  PROCESSING = "processing",
}

export interface EoyTaxFilingQuery {
  taxYear?: number;
  formType?: Exclude<EndOfYearFormType, EndOfYearFormType.NONE>;
  isAmendedFile?: boolean; // only applies for 1042s
}

export async function startEFile(query: EoyTaxFilingQuery) {
  await POST<{
    eoyTaxExport: {
      exportGuid: string;
      recipientEoyTaxGuids: string[];
      taxYear: number;
      type: string;
      count: number;
    };
  }>("/v1/tax-year/eoy-form/filing", {
    query,
  });
}

export interface EoyTaxExport extends EndOfYear.EoyTaxExport {}

export type EoyTaxExportsQuery = {
  taxYear: number;
  formTypes?: Exclude<EndOfYearFormType, EndOfYearFormType.NONE>[];
  isAmended?: boolean;
};

export function loadEoyTaxExports(query: EoyTaxExportsQuery, force?: boolean) {
  const { eoyTaxExports } = store.getState();
  const id = computeID(query);

  if (force || !isLoaded(eoyTaxExports.fetchStatus[id])) {
    standardDispatch(OpCode.LOADING, "eoyTaxExports", { id });
    POST<{ eoyTaxExports: EoyTaxExport[]; meta: SerializerMetaBlock }>("/v1/tax-year/eoy-form/export/search", {
      query,
    })
      .then(({ body }) => {
        standardDispatch(OpCode.DATA, "eoyTaxExports", {
          id,
          data: {
            records: body.eoyTaxExports,
            meta: body.meta,
          },
        });
      })
      .catch((errors) => {
        standardDispatch(OpCode.ERROR, "eoyTaxExports", { id, errors });
      });
  }

  return id;
}
