import { Access, CountryCode, DAC7TaxProfileStatus, EoyTaxReportStatus, PaymentCategory, RecipientType, TaxFormType } from "@trolley/common-frontend";
import { Avatar } from "antd";
import {
  Alert,
  Button,
  ButtonDropdown,
  ColumnsDisplay,
  Container,
  CurrencyDisplay,
  DateDisplay,
  Divider,
  Dropdown,
  Flag,
  Grid,
  Heading,
  Icon,
  LabelTooltip,
  Menu,
  MenuProps,
  Modal,
  RecordCount,
  Reloader,
  Space,
  Status,
  Stop,
  Table,
  TableColumnsType,
  TableFilterField,
  TableFilters,
  TableName,
  Text,
  Tooltip,
  tableColumnFilter,
  useColumnsDisplay,
} from "components";
import { RecipientProfile } from "features/recipient";
import { TAX_ENTITY_TYPE_LABELS } from "pages/RecipientsPage/Details/Tax/variables";
import TaxProfilePreview from "pages/RecipientsPage/Details/TaxProfile/TaxProfilePreview";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useHistory, useParams } from "react-router-dom";
import { computeID } from "store/actions/actionUtils";
import { DAC7EoyTax, DAC7EoyTaxQuery, bulkUpdate, resetDac7EoyTaxForms, sendDac7EoyTaxForms, updateDac7EoyTaxForms } from "store/actions/dac7EoyTaxForms";
import { DAC7TaxMerchantYear } from "store/actions/dac7TaxMerchantYear";
import { MerchantSettings } from "store/actions/merchantSettings";
import { notify, notifyError, notifySuccess } from "store/actions/notifications";
import { useDac7EoyTaxForms } from "store/hooks/dac7EoyTaxForms";
import { useDAC7TaxMerchantYear } from "store/hooks/dac7TaxMerchantYear";
import { useMerchantSettings } from "store/hooks/merchantSettings";
import { useTaxForm } from "store/hooks/taxForms";
import { useAccess } from "store/hooks/user";
import { BaseError } from "store/reducers/standardReducer";
import styled from "style/classname";
import { BREAKPOINTS } from "style/variables";
import { getPaymentCategoryLabel, pick } from "utils/helpers";
import { useQuery, useWindowSize } from "utils/hooks";
import { Pages, isProfileComplete } from ".";
import { LAST_YEAR, TAX_PATHS } from "../..";
import { TaxDeliveryCostWarning } from "../USTax/TaxStatements";
import EarningPreview from "./EarningPreview";
import EoyStatementDownload from "./EoyStatementDownload";

const styledTableRow = styled`
  height: 60px !important;
`();

const FILTER_FIELDS: TableFilterField[] = [
  {
    type: "select",
    label: "Earnings Status",
    filterKey: "status",
    options: [
      { label: "Needs Review", value: EoyTaxReportStatus.NEEDS_REVIEW },
      { label: "Approved", value: EoyTaxReportStatus.APPROVED },
      { label: "Hold", value: EoyTaxReportStatus.HOLD },
      { label: "Do Not Send", value: EoyTaxReportStatus.DO_NOT_SEND },
      { label: "Sent to Recipient", value: EoyTaxReportStatus.SENT },
      { label: "Voided", value: EoyTaxReportStatus.VOID },
    ],
  },
  {
    type: "select",
    label: "Earnings Type",
    filterKey: "type",
    options: [
      { label: "Services", value: PaymentCategory.SERVICES },
      { label: "Goods", value: PaymentCategory.GOODS },
    ],
  },
  {
    type: "select",
    label: "Recipients Type",
    filterKey: "recipientType",
    options: [
      { label: "Business", value: RecipientType.BUSINESS },
      { label: "Individual", value: RecipientType.INDIVIDUAL },
    ],
  },
  {
    type: "radio",
    label: "Reporting Status",
    filterKey: "reportable",
    options: [
      { label: "All", value: undefined },
      { label: "Reportable", value: true },
      { label: "Not Reportable", value: false },
    ],
  },
  {
    type: "input",
    inputMode: "numeric",
    label: "Earnings Greater Than",
    filterKey: "totalEarningsAmountGt",
  },
  {
    type: "input",
    inputMode: "numeric",
    label: "Taxes Greater Than",
    filterKey: "totaltaxedAmountGt",
  },
  {
    type: "input",
    inputMode: "numeric",
    label: "Fees Greater Than",
    filterKey: "totalFeeAmountGt",
  },
  {
    type: "input",
    inputMode: "numeric",
    label: "Relevant Activities Greater Than",
    filterKey: "totalActivityAmountGt",
  },
  {
    type: "radio",
    label: "Downloaded By Recipient",
    tooltip: "Recipient has downloaded the Tax statement through the Widget/Portal",
    filterKey: "downloadedByRecipient",
    options: [
      {
        label: "All",
        value: undefined,
      },
      {
        label: "Yes",
        value: true,
      },
      {
        label: "No",
        value: false,
      },
    ],
  },
  {
    type: "radio",
    label: "Marked as DAC7 Filed",
    tooltip: "Earnings have been sent to recipients",
    filterKey: "submitted",
    options: [
      {
        label: "All",
        value: undefined,
      },
      {
        label: "Yes",
        value: true,
      },
      {
        label: "No",
        value: false,
      },
    ],
  },
  {
    label: "Recipient ID",
    type: "tags",
    filterKey: "recipientId",
    placeholder: "Recipient ID",
    maxLength: 1,
    renderTag: (id) => <RecipientProfile onlyName recipientId={id} />,
  },
  {
    type: "radio",
    label: "DAC7 Profile",
    filterKey: "dac7ProfileStatus",
    options: [
      {
        label: "All",
        value: undefined,
      },
      {
        label: "Provided",
        value: DAC7TaxProfileStatus.TAX_PROFILE_PROVIDED,
      },
      {
        label: "Missing",
        value: DAC7TaxProfileStatus.TAX_PROFILE_MISSING,
      },
      {
        label: "Incomplete",
        value: DAC7TaxProfileStatus.TAX_PROFILE_INCOMPLETE,
      },
    ],
  },
];

export function OffLinePaymentsInstruction() {
  return (
    <p>
      If there were payments made outside of Trolley to a recipient during the tax year that need to be reported, you can add or upload{" "}
      <Link to="/payments/offline-payments-dac7">Offline Payments</Link> data to make the DAC7 reported data accurate.
    </p>
  );
}

export function ApprovalInstruction() {
  return (
    <p>
      You can mark any recipients that you don't need to report under DAC7 as <q>Do Not Send</q>, or mark them as Hold if you need to verify their earnings
      numbers before you're ready to approve them.
    </p>
  );
}

export function UnsupportedTaxForms() {
  return (
    <p>
      The below earnings are aggregated from Payment Tax Records which represent individual payments (Trolley-processed or Offline Payments) Each row represents
      a unique combination of Recipients & Earnings Type (eg. Services, Goods). Earnings values are broken down by calendar quarter on the Recipients Earnings
      Summaries & DAC7 XML Files.
    </p>
  );
}

export function TaxEntityDisplay({ taxFormId }: { taxFormId: string | undefined | null }) {
  const { data: taxForm } = useTaxForm(taxFormId);

  if (taxForm?.taxFormType === TaxFormType.W9) {
    const taxType = (taxForm?.taxFormData as any)?.taxType;
    if (taxType) {
      return <span>{TAX_ENTITY_TYPE_LABELS[taxType] || taxType}</span>;
    }
  }

  return <span>-</span>;
}

const DEFAULT_QUERY: DAC7EoyTaxQuery = {
  page: 1,
  pageSize: 10,
  status: [EoyTaxReportStatus.NEEDS_REVIEW, EoyTaxReportStatus.HOLD],
};

enum ColumnKeys { // also used for sortBy query if exists
  STATUS = "status",
  DAC7PROFILE = "dac7Profile",
  RECIPIENT = "recipient",
  EARNINGS = "equivalentPaymentAmountTotal",
  DAC7_STATEMENT = "dac7Statement",
  PLATFORM_FEES = "equivalentPlatformFeeAmountTotal",
  TAXES = "equivalentTaxAmountTotal",
  RELEVANT_ACTIVITIES = "relevantActivityCountTotal",
  REPORTABLE = "reportable",
  DOWNLOADED_BY_RECIPIENT = "downloadedByRecipient",
  REPORTABLE_JURISDICTION = "recipientTins",
  FILED = "dac7SubmittedAt",
  EARNINGS_TYPE = "paymentCategory",
  UPDATED_AT = "updatedAt",
  ACTIONS = "actions",
}
const defaultColumnsDisplay = {
  [ColumnKeys.DAC7PROFILE]: {
    label: "DAC7 Profile",
    value: true,
  },
  [ColumnKeys.EARNINGS]: {
    label: "Total Earnings",
    value: true,
  },
  [ColumnKeys.DAC7_STATEMENT]: {
    label: "DAC7 Statement",
    value: true,
  },
  [ColumnKeys.PLATFORM_FEES]: {
    label: "Total Platform Fees",
    value: true,
  },
  [ColumnKeys.TAXES]: {
    label: "Total Taxes",
    value: true,
  },
  [ColumnKeys.RELEVANT_ACTIVITIES]: {
    label: "Relevant Activities",
    value: true,
  },
  [ColumnKeys.REPORTABLE]: {
    label: "Reporting Status",
    value: true,
  },
  [ColumnKeys.DOWNLOADED_BY_RECIPIENT]: {
    label: "Downloaded By Recipient",
    value: true,
  },
  [ColumnKeys.REPORTABLE_JURISDICTION]: {
    label: "Reportable Jurisdiction",
    value: false,
  },
  [ColumnKeys.FILED]: {
    label: "Marked as Filed",
    value: false,
  },
  [ColumnKeys.EARNINGS_TYPE]: {
    label: "Earnings Type",
    value: false,
  },
  [ColumnKeys.UPDATED_AT]: {
    label: "Updated On",
    value: false,
  },
};

function isApprovable(dac7EoyTax: DAC7EoyTax) {
  return [EoyTaxReportStatus.NEEDS_REVIEW, EoyTaxReportStatus.HOLD, EoyTaxReportStatus.DO_NOT_SEND].includes(dac7EoyTax.status);
}

function isHoldable(dac7EoyTax: DAC7EoyTax) {
  return [EoyTaxReportStatus.NEEDS_REVIEW, EoyTaxReportStatus.APPROVED, EoyTaxReportStatus.DO_NOT_SEND].includes(dac7EoyTax.status);
}

function isDNSendable(dac7EoyTax: DAC7EoyTax) {
  return [EoyTaxReportStatus.NEEDS_REVIEW, EoyTaxReportStatus.APPROVED, EoyTaxReportStatus.HOLD].includes(dac7EoyTax.status);
}

function isSendable(taxMerchantYear: DAC7TaxMerchantYear | undefined, dac7EoyTax: DAC7EoyTax) {
  return dac7EoyTax.status === EoyTaxReportStatus.APPROVED && isProfileComplete(taxMerchantYear);
}

function isResendable(taxMerchantYear: DAC7TaxMerchantYear | undefined, dac7EoyTax: DAC7EoyTax) {
  return dac7EoyTax.status === EoyTaxReportStatus.SENT && isProfileComplete(taxMerchantYear);
}

function getHiddenColumns(merchantSettings: MerchantSettings | undefined, ongoingYear: boolean, accessTaxEOYWrite: boolean, width: number) {
  const excludedColumns: string[] = [];

  if (!ongoingYear || width < BREAKPOINTS.md || !accessTaxEOYWrite) {
    excludedColumns.push(ColumnKeys.ACTIONS);
  }

  return excludedColumns;
}

export default function ReviewEarnings() {
  const history = useHistory();
  const { width = 0 } = useWindowSize();
  const { data: merchantSettings } = useMerchantSettings();
  const accessTaxEOYWrite = useAccess(Access.TAX_EOY_WRITE);
  const params = useParams<{ taxYear: string }>();
  const taxYear = Number(params.taxYear);
  const ongoingYear = taxYear >= LAST_YEAR;
  const [urlQuery, setQuery] = useQuery<DAC7EoyTaxQuery>(DEFAULT_QUERY, {
    arrayKeys: ["ids", "status", "type", "recipientType"],
    numberKeys: ["totalEarningsAmountGt", "totaltaxedAmountGt", "totalFeeAmountGt", "totalActivityAmountGt"],
  });

  const query = urlQuery.ids?.length ? pick(urlQuery, ["page", "pageSize", "orderBy", "sortBy", "ids"]) : urlQuery;
  const [selected, setSelected] = useState<DAC7EoyTax[]>([]);
  const [previewDAC7EoyTax, setPreviewDAC7EoyTax] = useState<DAC7EoyTax | undefined>();
  const [previewTaxProfile, setPreviewTaxProfile] = useState("");
  const [selectAll, setSelectAll] = useState(false);
  const { data: dac7TaxMerchantYear } = useDAC7TaxMerchantYear(taxYear);
  const [displayedColumns, setDisplayedColumns] = useColumnsDisplay(
    TableName.TAX_EARNINGS,
    defaultColumnsDisplay,
    getHiddenColumns(merchantSettings, ongoingYear, accessTaxEOYWrite, width),
  );
  const { data: dac7EoyTaxForms, status: dac7EoyTaxFormsStatus, error: dac7EoyTaxFormsErrors } = useDac7EoyTaxForms(taxYear, query);
  const { records, meta } = dac7EoyTaxForms;
  const selectedApprovable = selected.filter(isApprovable);
  const selectedHoldable = selected.filter(isHoldable);
  const selectedDoNotSendable = selected.filter(isDNSendable);
  const selectedSendable = selected.filter((t) => isSendable(dac7TaxMerchantYear, t));
  const selectedResendable = selected.filter((t) => isResendable(dac7TaxMerchantYear, t));
  const selectedVoidable = selected.filter((t) => t.status === EoyTaxReportStatus.SENT);

  useEffect(() => {
    setSelected([]);
    setSelectAll(false);
  }, [computeID(query)]);

  function onChangeStatus(
    status: EoyTaxReportStatus.APPROVED | EoyTaxReportStatus.HOLD | EoyTaxReportStatus.DO_NOT_SEND | EoyTaxReportStatus.VOID,
    dac7EoyTax?: DAC7EoyTax,
  ) {
    return async (e?: any) => {
      e?.stopPropagation?.();
      try {
        if (dac7EoyTax) {
          await updateDac7EoyTaxForms(taxYear, {
            recipientEoyTaxIds: [
              {
                id: dac7EoyTax.id,
                hash: dac7EoyTax.hash,
              },
            ],
            status,
          });
        } else {
          let selectedTaxForms = selectedApprovable;
          if (status === EoyTaxReportStatus.HOLD) {
            selectedTaxForms = selectedHoldable;
          } else if (status === EoyTaxReportStatus.DO_NOT_SEND) {
            selectedTaxForms = selectedDoNotSendable;
          } else if (status === EoyTaxReportStatus.VOID) {
            selectedTaxForms = selectedVoidable;
          }

          if (selectedTaxForms.length > 0) {
            await updateDac7EoyTaxForms(taxYear, {
              recipientEoyTaxIds: selectedTaxForms.map((t) => ({
                id: t.id,
                hash: t.hash,
              })),
              status,
            });
          }
        }
        notifySuccess("Status updated");
        setSelected([]);
      } catch (errors) {
        notifyError("Updating status failed", { errors });
      }
    };
  }

  function getMenu(dac7EoyTax?: DAC7EoyTax) {
    const enableApprove = dac7EoyTax ? isApprovable(dac7EoyTax) : selectedApprovable.length > 0;
    const enableHold = dac7EoyTax ? isHoldable(dac7EoyTax) : selectedHoldable.length > 0;
    const enableDNS = dac7EoyTax ? isDNSendable(dac7EoyTax) : selectedDoNotSendable.length > 0;
    const enableSend = dac7EoyTax ? isSendable(dac7TaxMerchantYear, dac7EoyTax) : selectedSendable.length > 0;
    const enableResend = dac7EoyTax ? isResendable(dac7TaxMerchantYear, dac7EoyTax) : selectedResendable.length > 0;
    const enableVoid = dac7EoyTax ? dac7EoyTax.status === EoyTaxReportStatus.SENT : selectedVoidable.length > 0;

    return (
      <Menu onClick={onClickMenuStatus(dac7EoyTax)}>
        {(!dac7EoyTax || ![EoyTaxReportStatus.SENT, EoyTaxReportStatus.VOID].includes(dac7EoyTax.status)) && [
          <Menu.Item key={EoyTaxReportStatus.APPROVED} disabled={!enableApprove}>
            <Icon type="check" theme="solid" color={enableApprove ? "green" : undefined} left />
            Approve Earnings{!dac7EoyTax && ` (${selectedApprovable.length})`}
          </Menu.Item>,
          <Menu.Item key={EoyTaxReportStatus.HOLD} disabled={!enableHold}>
            <Icon type="pause" theme="solid" color={enableHold ? "yellow" : undefined} left />
            Hold Approval{!dac7EoyTax && `s (${selectedHoldable.length})`}
          </Menu.Item>,
          <Menu.Item key={EoyTaxReportStatus.DO_NOT_SEND} disabled={!enableDNS}>
            <Icon type="stop" theme="solid" color={enableDNS ? "grey" : undefined} left />
            Do Not Send{!dac7EoyTax && ` (${selectedDoNotSendable.length})`}
          </Menu.Item>,
        ]}
        {(!dac7EoyTax || dac7EoyTax.status === EoyTaxReportStatus.APPROVED) && (
          <Menu.Item key={EoyTaxReportStatus.SENT} disabled={!enableSend}>
            <Icon type="paper-plane" theme="solid" color={enableSend ? "blue" : undefined} left />
            Send Statement{!dac7EoyTax && `s (${selectedSendable.length})`}
            {!isProfileComplete(dac7TaxMerchantYear) && (
              <Text type="error" size="small" style={{ marginLeft: "22px" }}>
                Unable to send due to incomplete business tax profile
              </Text>
            )}
          </Menu.Item>
        )}
        {(!dac7EoyTax || dac7EoyTax.status === EoyTaxReportStatus.SENT) && (
          <Menu.SubMenu
            title={
              <>
                <Icon type="paper-plane" theme="solid" color={enableResend ? "blue" : undefined} left />
                Resend Statement{!dac7EoyTax && `s (${selectedResendable.length})`}
              </>
            }
            disabled={!enableResend}
          >
            <Menu.Item key="resend">By Recipient{dac7EoyTax ? "'s" : "s'"} Preference</Menu.Item>
            {/* <Menu.Item key={`resend-${TaxDeliveryType.E_DELIVERY}`}>By {translateDeliveryMethod(TaxDeliveryType.E_DELIVERY)}</Menu.Item>
            <Menu.Item key={`resend-${TaxDeliveryType.MAIL}`}>By {translateDeliveryMethod(TaxDeliveryType.MAIL)}</Menu.Item> */}
          </Menu.SubMenu>
        )}

        {(!dac7EoyTax || dac7EoyTax?.status === EoyTaxReportStatus.SENT) && (
          // Only show void action on each table row when already "Sent"
          <Menu.Item key={EoyTaxReportStatus.VOID} disabled={!enableVoid}>
            <Icon type="ban" theme="solid" left />
            Void Statement {!dac7EoyTax && `(${selectedVoidable.length})`}
          </Menu.Item>
        )}
      </Menu>
    );
  }

  function onClickMenuStatus(dac7EoyTax: DAC7EoyTax | undefined): MenuProps["onClick"] {
    return async (params) => {
      params.domEvent.stopPropagation();
      const newStatus = params.key as
        | EoyTaxReportStatus.APPROVED
        | EoyTaxReportStatus.HOLD
        | EoyTaxReportStatus.DO_NOT_SEND
        | EoyTaxReportStatus.SENT
        | EoyTaxReportStatus.VOID
        | "resend"
        | "resend-e-delivery"
        | "resend-mail";

      try {
        if (newStatus === EoyTaxReportStatus.SENT) {
          Modal.confirm({
            title: "Send DAC7 Statements",
            width: 480,
            content: <TaxDeliveryCostWarning showAdditionalCostWarning />,
            okText: "Ok, Send statements",
            onOk: async () => {
              try {
                const statementsToBeSent = dac7EoyTax ? [dac7EoyTax.id] : selectedSendable.map((tx) => tx.id);
                const ids = await sendDac7EoyTaxForms(taxYear, { ids: statementsToBeSent });
                setSelected([]);
                if (dac7EoyTax) {
                  if (ids.includes(dac7EoyTax.id)) {
                    notifySuccess("Statement sent");
                  } else {
                    notify("Statement cannot be sent", { type: "warning" });
                  }
                } else if (ids.length > 0) {
                  notifySuccess(`${ids.length} statements were sent`);
                } else {
                  notify("Statements cannot be sent", { type: "warning" });
                }
              } catch (errors) {
                if (!!errors?.length) {
                  if (errors.some((e: BaseError) => e.code === "empty_field")) {
                    Modal.confirm({
                      title: "Incomplete business tax profile",
                      content: "You cannot send statements because the business tax profile is not complete. Do you want to complete it?",
                      okText: "Yes, bring me there",
                      onOk: () => {
                        history.push(`${TAX_PATHS.EOY_REPORTING}/${taxYear}/${Pages.PROFILE}`);
                      },
                    });
                  } else {
                    notifyError(errors[0]?.message || "Failed to send form");
                  }
                }
              }
            },
          });
        } else if (newStatus === "resend" || newStatus === "resend-e-delivery" || newStatus === "resend-mail") {
          // const taxDeliveryType =
          //   newStatus === "resend-e-delivery" ? TaxDeliveryType.E_DELIVERY : newStatus === "resend-mail" ? TaxDeliveryType.MAIL : undefined;
          Modal.confirm({
            width: 480,
            title: "Resend Tax Statements",
            content: (
              <TaxDeliveryCostWarning
                showAdditionalCostWarning={["resend", "resend-mail"].includes(newStatus)} // show warning only for Postal Mail
              />
            ),
            okText: "Ok, Resend statements",
            onOk: async () => {
              try {
                const statementsToBeSent = dac7EoyTax ? [dac7EoyTax.id] : selectedResendable.map((tx) => tx.id);
                // TODO: To be implemented
                // const ids = await resendDac7EoyTaxForms(taxYear, { ids: statementsToBeSent, taxDeliveryType });
                const ids = [...statementsToBeSent];
                setSelected([]);
                if (dac7EoyTax) {
                  if (ids.includes(dac7EoyTax.id)) {
                    notifySuccess("Statement resent");
                  } else {
                    notify("Statement cannot be resent", { type: "warning" });
                  }
                } else if (ids.length > 0) {
                  notifySuccess(`${ids.length} statements were resent`);
                } else {
                  notify("Statements cannot be resent", { type: "warning" });
                }
              } catch (errors) {
                if (!!errors?.length) {
                  if (errors.some((e: BaseError) => e.code === "empty_field")) {
                    Modal.confirm({
                      title: "Incomplete business tax profile",
                      content: "You cannot send statements because the business tax profile is not complete. Do you want to complete it?",
                      okText: "Yes, bring me there",
                      onOk: () => {
                        history.push(`${TAX_PATHS.EOY_REPORTING}/${taxYear}/${Pages.PROFILE}`);
                      },
                    });
                  } else {
                    notifyError(errors[0]?.message || "Failed to resent form");
                  }
                }
              }
            },
          });
        } else if (newStatus === EoyTaxReportStatus.VOID) {
          Modal.confirm({
            title: "Void Tax Statements",
            content: (
              <>
                <p>Would you like to Void your statements?</p>
              </>
            ),
            okText: "Ok, Void statements",
            onOk: async () => {
              try {
                await onChangeStatus(newStatus, dac7EoyTax)();
                setSelected([]);

                notifySuccess("Statements voided");
              } catch {
                notifyError("Voiding statements failed");
              }
            },
          });
        } else {
          await onChangeStatus(newStatus, dac7EoyTax)();
          notifySuccess("Status updated");
          setSelected([]);
        }
      } catch (errors) {
        notifyError("Updating failed", { errors });
      }
    };
  }

  function renderActions(dac7EoyTax: DAC7EoyTax) {
    if (dac7EoyTax.status === EoyTaxReportStatus.VOID) {
      return null;
    }

    return (
      <Stop>
        <Space wrap={false} justify="flex-end">
          {isApprovable(dac7EoyTax) && (
            <Tooltip title="Approve Earnings">
              <Button
                type={dac7EoyTax.status === EoyTaxReportStatus.NEEDS_REVIEW ? "primary" : "default"}
                onClick={onChangeStatus(EoyTaxReportStatus.APPROVED, dac7EoyTax)}
                icon={<Icon theme="solid" type="check" style={{ verticalAlign: "middle" }} />}
              />
            </Tooltip>
          )}
          {isDNSendable(dac7EoyTax) && (
            <Button
              onClick={onChangeStatus(EoyTaxReportStatus.DO_NOT_SEND, dac7EoyTax)}
              icon={<Icon type="stop" theme="solid" color="grey" />}
              tooltipProps={{ title: "Do Not Send" }}
            />
          )}
          <Dropdown overlay={getMenu(dac7EoyTax)}>
            <Button icon={<Icon type="ellipsis" />} />
          </Dropdown>
        </Space>
      </Stop>
    );
  }

  const columns: TableColumnsType<DAC7EoyTax> = [
    {
      key: ColumnKeys.STATUS,
      title: <LabelTooltip type="eoyTaxFormStatus" />,
      align: "center",
      dataIndex: "status",
      render: (status: DAC7EoyTax["status"]) => <Status type={status} style={{ minWidth: "96px" }} />,
    },
    {
      key: ColumnKeys.DAC7PROFILE,
      title: "Progress",
      render: (eoyTax: DAC7EoyTax) => {
        const { taxProfileStatus, taxProfileId } = eoyTax;

        return (
          <a
            role="button"
            onClick={(e) => {
              e?.preventDefault?.();
              e?.stopPropagation?.();
              setPreviewTaxProfile(taxProfileId as string);
            }}
          >
            <>
              {taxProfileStatus}
              {taxProfileStatus === DAC7TaxProfileStatus.TAX_PROFILE_MISSING && <Icon type="triangle-exclamation" color="yellow" theme="solid" />}
            </>
          </a>
        );
      },
    },
    {
      key: ColumnKeys.RECIPIENT,
      title: "Recipient",
      dataIndex: "recipientId",
      render: (id: string) => <RecipientProfile size="small" recipientId={id} showAddress showLink showStatus="dot" />,
    },
    {
      key: ColumnKeys.EARNINGS,
      title: "Total Earnings",
      align: "right",
      dataIndex: "equivalentPaymentAmountTotal",
      sorter: true,
      sortOrder: query.orderBy?.[0] === "equivalentPaymentAmountTotal" ? (query.sortBy?.[0] === "asc" ? "ascend" : "descend") : undefined,
      render: (equivalentPaymentAmountTotal: string, eoyTax: DAC7EoyTax) => (
        <CurrencyDisplay value={equivalentPaymentAmountTotal} currency={eoyTax.equivalentPaymentCurrency} />
      ),
    },
    {
      key: ColumnKeys.DAC7_STATEMENT,
      title: "DAC7 Statement",
      render(dac7EoyTax: DAC7EoyTax) {
        return <EoyStatementDownload dac7EoyTax={dac7EoyTax} />;
      },
    },
    {
      key: ColumnKeys.PLATFORM_FEES,
      title: "Total Platform Fees",
      align: "right",
      dataIndex: "equivalentPlatformFeeAmountTotal",
      sorter: true,
      render: (equivalentPlatformFeeAmountTotal: string, eoyTax: DAC7EoyTax) => (
        <CurrencyDisplay value={equivalentPlatformFeeAmountTotal} currency={eoyTax.equivalentPlatformFeeCurrency} />
      ),
    },
    {
      key: ColumnKeys.TAXES,
      title: "Total Taxes",
      align: "right",
      dataIndex: "equivalentTaxAmountTotal",
      sorter: true,
      render: (equivalentTaxAmountTotal: string, eoyTax: DAC7EoyTax) => (
        <CurrencyDisplay value={equivalentTaxAmountTotal} currency={eoyTax.equivalentTaxCurrency} />
      ),
    },
    {
      key: ColumnKeys.RELEVANT_ACTIVITIES,
      title: "Relevant Activities",
      align: "right",
      dataIndex: "relevantActivityCountTotal",
      sorter: true,
      render: (relevantActivityCountTotal: DAC7EoyTax["relevantActivityCountTotal"]) => relevantActivityCountTotal,
    },
    {
      key: ColumnKeys.REPORTABLE,
      title: "Reporting Status",
      dataIndex: "reportable",
      render: (reportable: DAC7EoyTax["reportable"]) => (reportable ? "Reportable" : "Not Reportable"),
    },
    {
      key: ColumnKeys.DOWNLOADED_BY_RECIPIENT,
      title: (
        <Tooltip title="The recipient has downloaded the earnings summary through the Widget/Portal">
          <span>
            Downloaded By Recipient
            <Icon.Hint right />
          </span>
        </Tooltip>
      ),
      dataIndex: "downloadedByRecipient",
      render: (downloadedByRecipient: DAC7EoyTax["downloadedByRecipient"]) => (downloadedByRecipient ? "Downloaded" : " - "),
    },
    {
      title: "Earnings Type",
      dataIndex: "paymentCategory",
      render: (category: DAC7EoyTax["paymentCategory"]) => getPaymentCategoryLabel(category),
    },
    {
      key: ColumnKeys.REPORTABLE_JURISDICTION,
      title: "Reportable Jurisdiction",
      dataIndex: "recipientTins",
      render: (recipientTins: DAC7EoyTax["recipientTins"]) => {
        const countries = [...new Set(recipientTins.filter(({ tinCountry }) => !!tinCountry).map(({ tinCountry }) => tinCountry as CountryCode))];

        return countries.length > 0 ? (
          <Avatar.Group maxCount={countries.length > 2 ? 1 : 2} size="small">
            {countries.map((countryCode) => (
              <Avatar src={<Flag code={countryCode} showLabel={false} />} />
            ))}
          </Avatar.Group>
        ) : (
          "-"
        );
      },
    },
    {
      key: ColumnKeys.FILED,
      title: (
        <Tooltip title="DAC7 has been submitted">
          <span>
            Marked as Filed
            <Icon.Hint right />
          </span>
        </Tooltip>
      ),
      dataIndex: "dac7SubmittedAt",
      render: (dac7SubmittedAt: DAC7EoyTax["dac7SubmittedAt"]) => (dac7SubmittedAt ? "Submitted" : " - "),
    },
    {
      key: ColumnKeys.UPDATED_AT,
      title: "Updated On",
      dataIndex: "updatedAt",
      sorter: true,
      sortOrder: query.orderBy?.[0] === "updatedAt" ? (query.sortBy?.[0] === "asc" ? "ascend" : "descend") : undefined,
      render: (date: string) => <DateDisplay value={date} />,
    },
    {
      key: ColumnKeys.ACTIONS,
      title: <LabelTooltip type="eoyFormActions" />,
      align: "right",
      fixed: "right",
      render: renderActions,
    },
  ].filter(tableColumnFilter(displayedColumns)) as TableColumnsType<DAC7EoyTax>;

  return (
    <Container padding="none">
      <Helmet>
        <title>Recipient Earnings Review</title>
      </Helmet>
      {ongoingYear && (
        <Alert type="info" header="Offline Payments">
          <OffLinePaymentsInstruction />
        </Alert>
      )}
      <Heading tag="h2">
        <RecordCount prefix="Showing" value={meta.records} one="1 recipient earnings record" other="# recipient earnings records" />
        <Reloader status={dac7EoyTaxFormsStatus} onClick={resetDac7EoyTaxForms} />
      </Heading>
      <div>Review and approve the earnings below before sending earnings summaries to your recipients.</div>
      <ApprovalInstruction />
      <UnsupportedTaxForms />

      <TableFilters
        showSearch
        fields={FILTER_FIELDS}
        query={query}
        onChange={(changes: DAC7EoyTaxQuery) => {
          setQuery(changes, {
            scrollToTop: false,
          });
        }}
        suffix={
          <Space direction="row-reverse">
            <ColumnsDisplay columns={displayedColumns} onChange={setDisplayedColumns} />
          </Space>
        }
      >
        {selected.length > 0 && (
          <Grid padding="small">
            <Grid.Item>
              {selectAll ? (
                <Button
                  type="primary"
                  disabled // aprove all endpoint is not implemented yet
                  onClick={() => {
                    Modal.confirm({
                      title: "Approve all selected earnings?",
                      content: (
                        <>
                          Approving all earnings can only approve records in the following statuses:
                          <p>
                            <Status type={EoyTaxReportStatus.NEEDS_REVIEW} />, <Status type={EoyTaxReportStatus.HOLD} />,{" "}
                            <Status type={EoyTaxReportStatus.DO_NOT_SEND} />
                          </p>
                          <p>Do you want to approve all selected earnings?</p>
                        </>
                      ),
                      okText: "Yes, Approve All",
                      onOk: async () => {
                        try {
                          // TODO: to be implemented
                          await bulkUpdate("approve-all", { ...query, taxYear });
                          setSelectAll(false);
                          setSelected([]);
                        } catch (errors) {
                          notifyError("Failed to approve all earnings", { errors });
                        }
                      },
                    });
                  }}
                >
                  <RecordCount value={dac7EoyTaxForms.meta.records} other="Approve all # Earnings Records" />
                </Button>
              ) : (
                <ButtonDropdown onClick={onChangeStatus(EoyTaxReportStatus.APPROVED)} overlay={getMenu()} type="primary">
                  {" "}
                  <RecordCount value={selectedApprovable.length} other="Approve Earnings (#)" />
                </ButtonDropdown>
              )}
            </Grid.Item>

            {!selectAll && selected.length === dac7EoyTaxForms.records.length && dac7EoyTaxForms.meta.records > selected.length && (
              <Grid.Item>
                <Button
                  type="link"
                  onClick={() => {
                    setSelectAll(true);
                  }}
                >
                  <RecordCount value={dac7EoyTaxForms.meta.records} other="Select all # recipient earnings records" />
                </Button>
              </Grid.Item>
            )}

            {selectAll && (
              <Grid.Item>
                <Button
                  type="link"
                  onClick={() => {
                    setSelected([]);
                    setSelectAll(false);
                  }}
                >
                  Clear Selection
                </Button>
              </Grid.Item>
            )}
          </Grid>
        )}
      </TableFilters>

      <Table<DAC7EoyTax>
        status={dac7EoyTaxFormsStatus}
        errors={dac7EoyTaxFormsErrors}
        columns={columns}
        rowClassName={() => styledTableRow}
        dataSource={records}
        rowSelection={
          ongoingYear && accessTaxEOYWrite
            ? {
                fixed: true,
                selectedRowKeys: selected.map((s) => s.id),
                onChange: (selectedRowKeys, selectedRows) => {
                  setSelected(selectedRows);
                },
                onSelect(record, isSelected) {
                  if (!isSelected) {
                    setSelectAll(false);
                  }
                },
                onSelectAll(selected: boolean) {
                  if (!selected) {
                    setSelectAll(false);
                  }
                },
              }
            : undefined
        }
        onRow={(eoyTax) => ({
          onClick() {
            setPreviewDAC7EoyTax(eoyTax);
          },
        })}
        pagination={{
          current: query.page,
          pageSize: query.pageSize,
          total: dac7EoyTaxForms.meta.records,
        }}
        onQueryChange={setQuery}
      />
      <Divider margin="small" transparent />

      <EarningPreview
        onClose={() => {
          setPreviewDAC7EoyTax(undefined);
        }}
        dac7EoyTaxId={previewDAC7EoyTax?.id}
      />
      <TaxProfilePreview
        taxProfileId={previewTaxProfile}
        onClose={() => {
          setPreviewTaxProfile("");
        }}
      />
    </Container>
  );
}
