import { TaxFormVoidReason } from "@trolley/common-frontend";
import { Form, Modal, Select } from "components";
import React, { useEffect } from "react";
import { notifyError, notifySuccess } from "store/actions/notifications";
import { voidRecipientTaxProfiles } from "store/actions/recipientTaxProfiles";
import { TaxProfile } from "store/actions/taxProfiles";

const VOID_REASONS = [
  { value: TaxFormVoidReason.INVALID_DATA, label: "Invalid Information" },
  { value: TaxFormVoidReason.INVALID_SIGNATURE, label: "Invalid Signature" },
  { value: TaxFormVoidReason.INVALID_TIN, label: "Invalid Tax Identification Number" },
];

interface Props {
  taxProfiles?: TaxProfile[];
  onClose(): void;
}

export default function VoidFormPopup(props: Props) {
  const { taxProfiles, onClose } = props;
  const [form] = Form.useForm();

  useEffect(() => {
    if (taxProfiles?.length) {
      form.resetFields();
    }
  }, [taxProfiles]);

  async function onVoidProfile({ voidReason }: { voidReason: TaxFormVoidReason }) {
    try {
      if (taxProfiles?.length) {
        const recipientIds = [...new Set(taxProfiles.map((tp) => tp.recipientId))];
        await Promise.allSettled(
          recipientIds.map((recipientId) => {
            const recipientTaxProfileIds = taxProfiles.filter((tp) => tp.recipientId === recipientId).map((tp) => tp.id);

            return voidRecipientTaxProfiles(recipientId, recipientTaxProfileIds, voidReason);
          }),
        );
        notifySuccess("Tax Profiles Voided");
        onClose();
      }
    } catch (errors) {
      notifyError("Voiding Tax Profiles failed");
    }
  }

  return (
    <Modal
      visible={!!taxProfiles}
      title={document ? "Void this tax profile?" : "Void all selected tax profiles?"}
      onOk={form.submit}
      onCancel={onClose}
      okText="Yes, Void"
      okType="danger"
    >
      <Form form={form} onFinish={onVoidProfile}>
        <p>A voided tax profile will still be accessible, but cannot be used anymore.</p>
        <Form.Item label="Select a void reason" name="voidReason" rules={[{ required: true, message: "A reason is required" }]}>
          <Select showSearch optionFilterProp="children">
            {VOID_REASONS.map((type) => (
              <Select.Option key={type.value} value={type.value}>
                {type.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
}
