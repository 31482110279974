import { generate } from "@ant-design/colors";
import { keyframes } from "@emotion/css";

import * as colors from "./colors";
import * as palette from "./palette";

/** --------------------------- BREAKPOINTS ------------------------------ */

export type GreyColors = Record<`grey${1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10}`, string>;

export const GREYS = generate(colors.grey).reduce((prev, color, i) => {
  prev[`grey${i + 1}`] = color;

  return prev;
}, {} as GreyColors);

export const BREAKPOINTS = {
  xs: 480,
  sm: 576,
  md: 768,
  lg: 960,
  xl: 1200,
  xxl: 1600,
};

export const MEDIA_BREAKPOINTS = {
  smDown: `@media screen and (max-width: ${BREAKPOINTS.sm - 0.02}px)`,
  smUp: `@media screen and (min-width: ${BREAKPOINTS.sm}px)`,

  mdDown: `@media screen and (max-width: ${BREAKPOINTS.md - 0.02}px)`,
  mdUp: `@media screen and (min-width: ${BREAKPOINTS.md}px)`,

  lgDown: `@media screen and (max-width: ${BREAKPOINTS.lg - 0.02}px)`,
  lgUp: `@media screen and (min-width: ${BREAKPOINTS.lg}px)`,

  xlDown: `@media screen and (max-width: ${BREAKPOINTS.xl - 0.02}px)`,
  xlUp: `@media screen and (min-width: ${BREAKPOINTS.xl}px)`,
};

export const ANIMATION = {
  highlight: keyframes`
    0% {
      background-color: transparent;
    }
    25% {
      background-color: ${palette["yellow-alpha-3"]};
    }
    50% {
      background-color: transparent;
    }
    75% {
      background-color: ${palette["yellow-alpha-3"]};
    }
    100% {
      background-color: transparent;
    }
  `,
  hightlightFade: keyframes`
    0% {
      background-color: transparent;
    }

    10% {
      background-color: ${palette["primary-alpha-1"]};
    }

    100% {
      background-color: transparent;
    }
  `,
  flash: keyframes`
    0% {
      opacity: 1;
    }
    25% {
      opacity: 0.2;
    }
    50% {
      opacity: 1;
    }
    75% {
      opacity: 0.2;
    }
    100% {
      opacity: 1;
    }
  `,
  fadeInDown: keyframes`
    0% {
      opacity: 0;
      transform: translateY(-8px);
    }
    100% {
      opacity: 1;
      transform: translateY(0px);
    }
  `,
  flipInY: keyframes`
    0% {
      opacity: 0;
      transform: scaleX(0.4);
    }
    100% {
      opacity: 1;
      transform: scaleX(1);
    }
  `,
};
