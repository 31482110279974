/* istanbul ignore file */
import config from "config";
import { MerchantSettings } from "store/actions/merchantSettings";
import { User } from "store/actions/user";
import satismeter from "./script";

export function satismeterInit() {
  if (config.SATISMETER_WRITE_KEY) {
    satismeter();
  }
}

export function satismeterOpen(user: User, merchant: MerchantSettings) {
  if (config.SATISMETER_WRITE_KEY && !user.isImpersonated && window.satismeter && user.merchantId === merchant.merchantId) {
    window.satismeter({
      writeKey: config.SATISMETER_WRITE_KEY,
      userId: user.id,
      traits: {
        name: user.name,
        email: user.email,
        role: user.role,
        createdAt: user.createdAt,
        merchantId: merchant.merchantId,
        merchantName: merchant.name,
        merchantCountry: merchant.country,
        merchantStatus: merchant.status,
      },
    });

    return true;
  }

  return false;
}

export function satismeterClose() {
  if (config.SATISMETER_WRITE_KEY && window.satismeter) {
    window.satismeter("close");
  }
}
