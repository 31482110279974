/* istanbul ignore file */
import { SerializerMetaBlock, uniqueList } from "@trolley/common-frontend";
import { POST } from "services/request";
import store from "store";
import { OpCode, standardDispatch } from "store/dispatcher";
import { queueFactory } from "utils/factories";
import { Query } from "utils/hooks";
import { computeID, isLoaded } from "./actionUtils";
import { User } from "./user";

export type TeamMember = User;

export interface TeamQuery extends Query<TeamMember> {}

const teamMemberQueueLoader = queueFactory(
  (userIds) => {
    POST<{ meta: SerializerMetaBlock; users: User[] }>("/v1/users/search", {
      query: {
        ids: userIds,
        pageSize: userIds.length,
      },
    })
      .then(({ body }) => {
        const mappedUsers = body.users.reduce((acc, tm) => {
          acc[tm.id] = tm;

          return acc;
        }, {});

        standardDispatch(OpCode.DATA, "teamMembers", {
          bulk: userIds.reduce((acc, id) => {
            acc[id] = mappedUsers[id] ?? undefined;

            return acc;
          }, {}),
        });
      })
      .catch((errors) => {
        standardDispatch(OpCode.ERROR, "teamMembers", { ids: userIds, errors });
      });
  },
  (id) => /^U-\w+/.test(id),
);

export function loadTeamMember(userId: string, force?: boolean) {
  if (userId) {
    const { teamMembers } = store.getState();
    if (force || !isLoaded(teamMembers.fetchStatus[userId])) {
      standardDispatch(OpCode.LOADING, "teamMembers", { id: userId });
      teamMemberQueueLoader.add(userId);
    }
  }
}

export function loadTeamMemberList(options: TeamQuery, force?: boolean) {
  const id = computeID(options);
  const { teamMemberList } = store.getState();

  if (force || !isLoaded(teamMemberList.fetchStatus[id])) {
    standardDispatch(OpCode.LOADING, "teamMemberList", { id });

    POST<{ meta: SerializerMetaBlock; users: User[] }>("/v1/users/search", { query: options })
      .then(({ body }) => {
        const teamMembers: Record<string, User> = {};
        const records = body.users.map((tm) => {
          teamMembers[tm.id] = tm;

          return tm.id;
        });

        standardDispatch(OpCode.DATA, "teamMembers", {
          bulk: teamMembers,
        });

        standardDispatch(OpCode.DATA, "teamMemberList", {
          id,
          data: {
            records,
            meta: body.meta,
          },
        });
      })
      .catch((errors) => {
        standardDispatch(OpCode.ERROR, "teamMemberList", { id, errors });
      });
  }

  return id;
}

export async function inviteTeamMember(id: string) {
  await POST(`/v1/users/resend-invite`, { query: { id } });
}

export interface TeamMemberUpsert {
  firstName: string;
  lastName: string;
  email: string;
  role: string;
}

export async function createTeamMember(query: TeamMemberUpsert) {
  try {
    standardDispatch(OpCode.LOADING, "teamMembers");
    const { body } = await POST<{ user: User }>("/v1/users/create", { query });
    standardDispatch(OpCode.DATA, "teamMembers", { id: body.user.id, data: body.user });
    standardDispatch(OpCode.RESET, "teamMemberList");

    return body.user;
  } catch (errors) {
    standardDispatch(OpCode.ERROR, "teamMembers", { errors });
    throw errors;
  }
}

export async function updateTeamMember(id: string, changes: TeamMemberUpsert) {
  try {
    standardDispatch(OpCode.LOADING, "teamMembers", { id });
    const { body } = await POST<{ user: User }>("/v1/users/update", { query: { id, ...changes } });
    standardDispatch(OpCode.DATA, "teamMembers", { id, data: body.user });
  } catch (errors) {
    standardDispatch(OpCode.ERROR, "teamMembers", { id, errors });
    throw errors;
  }
}

export async function deleteTeamMembers(rawIds: string[]) {
  const ids = uniqueList(rawIds);
  if (ids.length > 0) {
    try {
      standardDispatch(OpCode.LOADING, "teamMembers", { ids });
      await POST("/v1/users/delete", { query: { ids } });
      standardDispatch(OpCode.RESET, "teamMemberList");
      standardDispatch(OpCode.LOADING, "teamMembers", { ids, loading: false });
    } catch (errors) {
      standardDispatch(OpCode.LOADING, "teamMembers", { ids, loading: false });
      throw errors;
    }
  }
}

export async function resetTeamMemberTFA(id: string) {
  try {
    standardDispatch(OpCode.LOADING, "teamMembers", { id });
    const { body } = await POST<{ user: User }>("/v1/users/tfa/disable", { query: { ids: [id] } });
    standardDispatch(OpCode.DATA, "teamMembers", { id, data: body.user });
  } catch (errors) {
    standardDispatch(OpCode.ERROR, "teamMembers", { id, errors });
    throw errors;
  }
}

export async function unblockTeamMember(id: string) {
  try {
    standardDispatch(OpCode.LOADING, "teamMembers", { id });
    await POST("/v1/users/unblock", { query: { id } });
    standardDispatch(OpCode.LOADING, "teamMembers", { id, loading: false });
    loadTeamMember(id, true);
  } catch (errors) {
    standardDispatch(OpCode.ERROR, "teamMembers", { id, errors });
    throw errors;
  }
}
