import { useEffect } from "react";
import { useShallowSelector } from "store/hooks";
import { loadPayoutMethods } from "store/actions/payoutMethods";

export function usePayoutMethods() {
  useEffect(() => {
    loadPayoutMethods();
  }, []);

  return useShallowSelector((state) => {
    if (state.payoutMethods.fetchStatus.data === undefined) {
      loadPayoutMethods();
    }

    return {
      data: state.payoutMethods.entities.data || [],
      status: state.payoutMethods.fetchStatus.data,
      error: state.payoutMethods.errors.data,
    };
  });
}
