import React, { HTMLAttributes, ReactNode } from "react";
import css, { classnames, createUseStyle } from "style/classname";
import palette from "style/palette";
import { useWindowSize } from "utils/hooks";
import Icon from "../Icon";
import { IconStatusProps } from "../Icon/Status";

export interface AlertProps extends HTMLAttributes<HTMLDivElement> {
  banner?: boolean;
  size?: "large";
  type?: IconStatusProps["type"];
  showIcon?: boolean;
  icon?: ReactNode;
  header?: ReactNode;
  action?: ReactNode;
  children: ReactNode;
}

export default function Alert(props: AlertProps) {
  const { isMobile } = useWindowSize();
  const { banner, size, header, action, type, children, icon, showIcon = !isMobile, className, ...rest } = props;
  const styledAlert = useStyledAlert({ ...props, size: isMobile ? undefined : size, showIcon });

  return (
    <div className={classnames("alert", styledAlert, className)} {...rest}>
      <div className="alert-content">
        {showIcon && (icon ?? (type && <Icon.Status type={type} />))}
        {header && <span className="alert-header">{header}</span>}
        <div className="alert-description">{children}</div>
      </div>
      {action && <div className="alert-action">{action}</div>}
    </div>
  );
}

const useStyledAlert = createUseStyle<AlertProps>(({ theme, ...props }) =>
  css`
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: nowrap;
    gap: ${theme.marginSM}px;
    border-radius: ${props.banner ? "0" : theme.borderRadius}px;
    background-color: ${() => {
      switch (props.type) {
        case "success":
          return palette["green-alpha-2"];
        case "warning":
          return palette["yellow-alpha-2"];
        case "error":
          return palette["red-alpha-2"];
        case "info":
        default:
          return palette["grey-alpha-1"];
      }
    }};
    padding: ${props.size === "large" ? "16px" : "8px 12px"};
    ${!props.banner &&
    `
      &:not(:first-child) {
        margin-top: ${theme.marginXS}px;
      }
      &:not(:last-child) {
        margin-bottom: ${theme.marginXS}px;
      }
    `}
    & > .alert-content {
      position: relative;
      ${!props.banner && `flex: 1;`}
      align-self: center;
      ${props.showIcon && props.type && (props.size === "large" ? "padding-left: 36px;" : "padding-left: 24px;")}
      font-size: ${props.size === "large" ? "18px" : "14px"};
      & > .icon {
        position: absolute;
        left: 0px;
        line-height: inherit;
      }
      & > .alert-header {
        font-size: ${props.size === "large" ? theme.fontSizeHeading3 : theme.fontSizeHeading4}px;
        line-height: inherit;
        margin-bottom: 4px;
      }
      & > .alert-description {
        font-size: ${theme.fontSize}px;
        display: ${props.header ? "block" : "inline"};
      }
    }
    & > .alert-action {
      align-self: center;
    }
  `(),
);
