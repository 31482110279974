import store from "store";
import { OpCode, standardDispatch } from "store/dispatcher";
import * as request from "services/request";
import * as types from "@trolley/common-frontend";
import { computeID, isLoaded } from "./actionUtils";
import { Query } from "utils/hooks";
import { batch } from "react-redux";
import { PaymentTax } from "./paymentTaxes";
import { Mapped } from "store/reducers/standardReducer";

export type PaymentTaxCreate = {
  recipientId: string;
  amount: string;
  currency: string;
  externalId?: string;
  memo?: string;
  payoutMethod?: types.PayoutMethodType;
  taxReportable?: boolean;
  forceUsTaxActivity?: boolean;
  taxWithholdingReason?: types.TaxWithholdingReasons;
  processedAt?: string;
  taxFormId?: string;
};

export interface OfflinePaymentsQuery extends Query {
  recipientId?: string;
  search?: string;
}

export function resetOfflinePayments() {
  standardDispatch(OpCode.RESET, "offlinePaymentList");
}

export function loadOfflinePayments(query: OfflinePaymentsQuery, force: boolean = false) {
  const id = computeID(query);
  const data = store.getState().offlinePaymentList;
  if (force || !isLoaded(data.fetchStatus[id])) {
    standardDispatch(OpCode.LOADING, "offlinePaymentList", { id });
    request
      .POST<types.PaymentTaxListResponse>("/v1/payment-taxes/search", { query: { ...query, withPayment: false } })
      .then(({ body }) => {
        batch(() => {
          const mappedOfflinePayments: Mapped<PaymentTax> = {};
          const ids = body.offlinePayments.map((op) => {
            mappedOfflinePayments[op.id] = op;

            return op.id;
          });
          standardDispatch(OpCode.DATA, "offlinePaymentList", {
            id,
            data: {
              records: ids,
              meta: body.meta,
            },
          });

          standardDispatch(OpCode.DATA, "paymentTaxes", {
            bulk: mappedOfflinePayments,
          });
        });
      })
      .catch((errors) => {
        standardDispatch(OpCode.ERROR, "offlinePaymentList", {
          id,
          errors,
        });
      });
  }

  return id;
}

export async function createPaymentTax(query: PaymentTaxCreate): Promise<PaymentTax> {
  try {
    standardDispatch(OpCode.LOADING, "paymentTaxes");

    const { body } = await request.POST<{ offlinePayment: PaymentTax }>("/v1/offline-payments/create", { query });
    standardDispatch(OpCode.DATA, "paymentTaxes", {
      id: body.offlinePayment.id,
      data: body.offlinePayment,
    });

    standardDispatch(OpCode.RESET, "offlinePaymentList");

    return body.offlinePayment;
  } catch (errors) {
    standardDispatch(OpCode.ERROR, "paymentTaxes", {
      errors,
    });
    throw errors;
  }
}

export async function deletePaymentTaxes(recipientId: string, ids: string[]) {
  try {
    standardDispatch(OpCode.LOADING, "offlinePaymentList");
    await request.POST("/v1/offline-payments/delete", {
      query: {
        recipientGuid: recipientId,
        opaymentGuids: ids,
      },
    });

    standardDispatch(OpCode.DELETE, "paymentTaxes", { ids });
  } catch (errors) {
    standardDispatch(OpCode.ERROR, "offlinePaymentList", { errors });
    throw errors;
  }
}
