import { EmailTemplate, EoyTaxReportStatus, TaxDeliveryType } from "@trolley/common-frontend";
import { DateDisplay, Text } from "components";
import React, { useMemo } from "react";
import { EoyTax } from "store/actions/eoyTaxForms";
import { useEmailMessages, useLobMessages } from "store/hooks/messages";
import { useRecipient } from "store/hooks/recipients";

export default function EoyTaxDeliveryStatus(props: { eoyTax: EoyTax | undefined }) {
  const { eoyTax } = props;
  const isSent = [EoyTaxReportStatus.SENT, EoyTaxReportStatus.VOID].includes(eoyTax?.status as EoyTaxReportStatus);
  const { data: recipient } = useRecipient(isSent ? eoyTax?.recipientId : "");
  const query = useMemo(() => {
    if (isSent) {
      return { recipientId: eoyTax?.recipientId, relatedItemId: eoyTax?.id };
    }

    return undefined;
  }, [isSent, eoyTax]);

  const { data: emailMessages } = useEmailMessages(query);
  const { data: lobMessages } = useLobMessages(isSent ? eoyTax?.id : undefined);
  const lobMessage = lobMessages.find((m) => m.lobGuid);
  const emailMessage = emailMessages.find((m) => m.templateId === EmailTemplate.WL_TAX_STATEMENT_AVAILABLE);
  const message = recipient?.taxDeliveryType === TaxDeliveryType.E_DELIVERY ? emailMessage || lobMessage : lobMessage || emailMessage;
  const lastEvent = message?.events?.[message.events.length - 1];

  return (
    <>
      <Text type="label">Statement Delivery to Recipient</Text>
      {eoyTax?.status === EoyTaxReportStatus.SENT ? (
        lastEvent ? (
          <Text capitalize>
            {lastEvent.type}&nbsp;
            <DateDisplay value={lastEvent.date} icon={false} />
          </Text>
        ) : (
          "Sent"
        )
      ) : (
        "Not Sent"
      )}
    </>
  );
}
