import { RadioGroupProps as AntRadioGroupProps, Radio as RadioAnt } from "antd";
import { CheckboxOptionType } from "antd/lib/checkbox";
import { getAlphaColor } from "antd/lib/theme/themes/default/colorAlgorithm";
import Status from "components/Status";
import React from "react";
import css, { classnames, createUseStyle } from "style/classname";
import palette from "style/palette";
import Radio from ".";
import Space, { SpaceProps } from "../../Space";
import { Grid } from "components";

export declare type CustomRadioValueType = string | number | boolean | undefined; // custom because we should accept undefined

export interface RadioOptionType extends Omit<CheckboxOptionType, "value"> {
  value: CustomRadioValueType;
  recommended?: boolean;
}

export interface GroupProps extends Omit<AntRadioGroupProps, "options" | "buttonStyle" | "optionType"> {
  options?: Array<RadioOptionType | string | number>;
  optionType?: "default" | "button" | "card";

  /**
   * Style of card radios
   * inline: (default) each card is as wide as its content
   * horizontal: all cards will stretch to fit the entire row
   * vertical. each card is stacked vertically, 100% width
   */
  direction?: "inline" | "horizontal" | "vertical";

  /**
   * gap between the radios if "options" props is used intead of children
   */
  gap?: SpaceProps["size"];

  isGrid?: boolean;
}

export default function RadioGroup(props: GroupProps) {
  const { options, direction = "inline", size, className, children, optionType, isGrid, gap = "middle", ...rest } = props;
  const styledRadioGroup = useStyledRadioGroup(props);

  const radioGroup = options?.map((option) => {
    if (typeof option === "object") {
      const { label, recommended, ...optionRest } = option;

      return (
        <Radio {...optionRest} key={String(optionRest.value)}>
          {recommended && (
            <Status
              type="RECOMMENDED"
              colors={{
                RECOMMENDED: "blue",
              }}
              size="large"
              style={{ float: "right", margin: "4px" }}
            />
          )}
          {label}
        </Radio>
      );
    }

    return (
      <Radio value={option} key={option}>
        {String(option)}
      </Radio>
    );
  });

  return (
    <RadioAnt.Group
      {...rest}
      optionType={optionType === "card" ? "default" : optionType}
      buttonStyle={optionType === "button" ? "solid" : "outline"}
      size={size ?? optionType === "button" ? "small" : undefined}
      className={classnames(styledRadioGroup, className)}
    >
      {children ??
        (optionType !== "button" && radioGroup ? (
          !!isGrid ? (
            <Grid>
              {radioGroup.map((radioElement, index) => (
                <Grid.Item
                  xs={24}
                  sm={12}
                  key={`radio-element-${options ? (typeof options[index] === "object" ? (options[index] as RadioOptionType).label : options[index]) : ""}`}
                >
                  {radioElement}
                </Grid.Item>
              ))}
            </Grid>
          ) : (
            <Space size={gap} direction={direction === "vertical" ? "column" : "row"} align="stretch" justify="stretch" inline={direction === "inline"}>
              {radioGroup}
            </Space>
          )
        ) : (
          radioGroup
        ))}
    </RadioAnt.Group>
  );
}

const useStyledRadioGroup = createUseStyle<GroupProps>(({ theme, optionType, direction, ...props }) =>
  css`
    &.${theme.prefixCls}-radio-group {
      display: ${optionType === "button" && direction === "horizontal" ? "flex" : "block"};
      .${theme.prefixCls}-radio-wrapper {
        ${direction === "horizontal" &&
        `
          flex: 1;
          width: 100%;
        `}
      }
      .${theme.prefixCls}-space-item, .${theme.prefixCls}-radio-wrapper {
        margin: 0;
        ${(direction === "horizontal" || direction === "vertical") &&
        `
          flex: 1;
          width: 100%;
        `}
      }

      .${theme.prefixCls}-radio-wrapper {
        height: 100%;
        .${theme.prefixCls}-radio {
          align-self: flex-start;
          margin: 2px 0;
          &:last-child {
            width: 100%;
            padding-right: 0px;
          }
        }
        ${optionType === "card" &&
        `
          padding: ${theme.padding}px;
          border-radius: ${theme.borderRadius}px;
          border: ${theme.lineType} ${theme.lineWidth}px ${theme.colorBorder};
          background-color: ${getAlphaColor(theme.colorBgContainer, 0.5)};
          &.${theme.prefixCls}-radio-wrapper-checked {
            background-color: ${theme.colorInfoBg};
            border-color: ${theme.colorInfo};
            &.${theme.prefixCls}-radio-wrapper-disabled {
              border-color: ${theme.colorBorder};
            }
          }
          &.${theme.prefixCls}-radio-wrapper-disabled {
            background-color: ${theme.colorBgContainerDisabled};
          }
          &:not(.${theme.prefixCls}-radio-wrapper-disabled) {
            &.${theme.prefixCls}-radio-wrapper-checked {
              background-color: ${theme.controlItemBgActive};
              &:hover {
                background-color: ${theme.controlItemBgActiveHover};
              }
            }
            &:hover {
              border-color: ${theme.colorPrimaryHover};
              &:active {
                border-color: ${theme.colorPrimaryActive};
              }
            }
          }
        `}
      }
      &.${theme.prefixCls}-radio-group-solid {
        padding: 4px;
        border-radius: ${theme.borderRadius}px;
        background-color: ${palette["grey-alpha-1"]};
        .${theme.prefixCls}-radio-button-wrapper {
          &::before {
            display: none;
          }
          &:not(:first-child) {
            margin-left: 4px;
          }
          border-width: 1px;
          text-align: center;
          border-color: transparent;
          color: ${theme.colorTextSecondary};
          border-radius: ${theme.borderRadius}px;
          &:focus-within {
            box-shadow: none;
          }
          border-color: transparent;
          background-color: transparent;

          &:not(.${theme.prefixCls}-radio-button-wrapper-disabled) {
            &:hover {
              color: ${theme.colorPrimaryTextHover};
              border-color: transparent;
              background-color: ${theme.colorBgContainer};
            }
          }
          &-checked {
            color: ${theme.colorText};
            &.${theme.prefixCls}-radio-button-wrapper-disabled {
              background-color: ${theme.colorBgContainerDisabled};
            }
            &:not(.${theme.prefixCls}-radio-button-wrapper-disabled) {
              background-color: ${theme.colorBgContainer};
              .${theme.prefixCls}-radio-button {
                border-radius: ${theme.borderRadiusSM}px;
                box-shadow: ${theme.boxShadowTertiary};
              }
            }
          }
        }
      }
    }
  `(),
);
