import RecipientsTemplate from "assets/recipient_upload_template.2024.08.08.zip";
import { AlertSyncRecipients, Text, UploadWindow } from "components";
import { MAX_FILE_SIZE } from "components/UploadWindow";
import React from "react";
import { useHistory } from "react-router-dom";
import { fileUpload } from "store/actions/files";
import { useShallowSelector } from "store/hooks";
import { BaseStatus } from "store/reducers/standardReducer";
import { UploadType } from "@trolley/common-frontend";

interface Props {
  visible: boolean;
  onClose(): void;
}

export default function AddRecipientsPopup(props: Props) {
  const { visible, onClose } = props;
  const history = useHistory();
  const loading = useShallowSelector((state) => state.files.fetchStatus.data);

  async function onUpload(file: FormData) {
    const id = await fileUpload(UploadType.RECIPIENT, file);
    onClose();
    history.push(`/recipients/upload/${id}`);
  }

  return (
    <UploadWindow
      title="Upload Recipients"
      onClose={onClose}
      onSubmit={onUpload}
      loading={loading === BaseStatus.LOADING}
      visible={visible}
      maxSize={MAX_FILE_SIZE}
      addOnBefore={<AlertSyncRecipients />}
    >
      <Text padded align="center">
        <p>
          <a href={RecipientsTemplate}>Download Recipient CSV Template</a>
        </p>
        <p>Please download the sample CSV file and re-upload with all the recipient information you wish to add.</p>
        Maximum upload file size: {MAX_FILE_SIZE} MB.
      </Text>
    </UploadWindow>
  );
}
