import { createContext, useContext } from "react";

const RecipientEditableContext = createContext(false);

const RecipientEditableProvider = RecipientEditableContext.Provider;

export default RecipientEditableProvider;

export const useRecipientEditable = (): boolean => {
  return !!useContext(RecipientEditableContext);
};
