import { Text } from "components";
import React, { useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";

const TFA_TIMER_SECONDS = 3 * 60;

interface TimerProps {
  loading: boolean;
  onTimeout: () => void;
}
export default function SMSTimer({ loading, onTimeout }: TimerProps) {
  const [time, setTime] = useState(TFA_TIMER_SECONDS);
  const timer = useRef<number | undefined>();

  useEffect(() => {
    if (!loading) {
      timer.current = window.setInterval(() => {
        setTime((i) => {
          const newCount = i - 1;
          if (newCount >= 0) {
            return newCount;
          }

          return i;
        });
      }, 1000);
    }

    return () => {
      if (!loading && timer.current) {
        window.clearInterval(timer.current);
        timer.current = undefined;
      }
    };
  }, [loading]);

  useEffect(() => {
    if (time < 1) {
      onTimeout();
    }
  }, [time]);

  const min = Math.floor(time / 60);
  const sec = Math.floor(time % 60);

  return (
    <Text padded type={loading ? "secondary" : "text"}>
      Please submit your code in the next
      <br />
      <FormattedMessage
        id="tfatimer"
        values={{
          min,
          sec,
        }}
        defaultMessage={`
          {min, plural,
            =0 {}
            one {# minute}
            other {# minutes}
          }
          {sec, plural,
            one {# second}
            other {# seconds}
          }.
      `}
      />
    </Text>
  );
}
