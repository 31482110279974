import "@trolley/common-frontend/lib/icons/css/brands.css";
import "@trolley/common-frontend/lib/icons/css/duotone.css";
import "@trolley/common-frontend/lib/icons/css/fontawesome.css";
import "@trolley/common-frontend/lib/icons/css/regular.css";
import "@trolley/common-frontend/lib/icons/css/solid.css";
import { IconName } from "@trolley/common-frontend/lib/icons/types";
import { Tooltip } from "components";
import { TooltipProps } from "components/Tooltip";
import React, { MouseEventHandler, ReactNode } from "react";
import css, { classnames, createUseStyle } from "style/classname";
import IconGhost from "./Ghost";
import IconHint from "./Hint";
import Reloader from "./Reloader";
import Spinner from "./Spinner";
import IconStatus from "./Status";
import "./icon.less";
import colors from "style/colors";

export { IconName };

const useStyledIcon = createUseStyle<IconProps>(({ theme, ...props }) =>
  css`
    &.icon {
      display: inline-block;
      vertical-align: baseline;
      ${props.right ? "margin-left: 4px;" : ""}
      ${props.left ? "margin-right: 4px;" : ""}
    ${props.onClick && `cursor: pointer;`}
    ${() => {
        if (props.color) {
          return `
          color: ${theme[`${props.color}5`] || theme[props.color]};
        `;
        }

        return (
          props.onClick &&
          `
          color: ${theme.colorLink};
          &:hover {
            color: ${theme.colorLinkHover};
          }
          &:active {
            color: ${theme.colorLinkActive};
          }
        `
        );
      }}
    }
  `(),
);

export type IconProps = {
  className?: string;
  type: IconName;
  tooltip?: ReactNode;
  tooltipProps?: Omit<TooltipProps, "title">;
  theme?: "solid" | "regular" | "brands" | "duotone";
  size?: "xsmall" | "small" | "large" | "xlarge" | "3x" | "4x" | "5x" | "6x" | "7x" | "8x" | "9x" | "10x";
  fixedWidth?: boolean;
  left?: boolean;
  right?: boolean;
  spin?: boolean;
  color?: keyof typeof colors;
  inverse?: boolean;
  style?: React.CSSProperties;
  onClick?: MouseEventHandler<HTMLElement>;
};

export default function Icon(props: IconProps) {
  const { className, type, theme = "regular", size, fixedWidth = true, left, right, spin, color, inverse, tooltip, tooltipProps, ...rest } = props;
  const styledIcon = useStyledIcon({ ...props, theme, fixedWidth });

  const icon = (
    <i
      className={classnames("icon", styledIcon, className, `fa-${theme}`, `fa-${type}`, {
        [`fa-${size}`]: size && /x$/.test(size),
        "fa-xs": size === "xsmall",
        "fa-sm": size === "small",
        "fa-lg": size === "large",
        "fa-2x": size === "xlarge",
        "fa-fw": fixedWidth,
        "fa-spin": spin,
        "fa-inverse": inverse,
      })}
      role={rest.onClick ? "button" : undefined}
      {...rest}
    />
  );

  return tooltip ? (
    <Tooltip title={tooltip} {...tooltipProps}>
      {icon}
    </Tooltip>
  ) : (
    icon
  );
}

Icon.Status = IconStatus;
Icon.Hint = IconHint;
Icon.Ghost = IconGhost;
Icon.Reloader = Reloader;
Icon.Spinner = Spinner;
