import { Form as FormAnt, FormItemProps } from "antd";
import { Icon } from "components";
import React, { ReactNode } from "react";
import css, { classnames, createUseStyle } from "style/classname";

export interface ItemProps<V = any> extends Omit<FormItemProps<V>, "tooltip" | "children"> {
  tooltip?: ReactNode;
  children?: ReactNode;
}

export default function FormItem<V = any>(props: ItemProps<V>) {
  const { tooltip, rootClassName, validateFirst = true, ...rest } = props;
  const styledFormItem = useStyledFormItem();

  return (
    <FormAnt.Item
      validateFirst={validateFirst}
      tooltip={
        tooltip && {
          title: tooltip,
          icon: <Icon.Hint />,
        }
      }
      rootClassName={classnames(styledFormItem, rootClassName)}
      {...rest}
    />
  );
}

const useStyledFormItem = createUseStyle(({ theme }) =>
  css`
    .${theme.prefixCls}-form-item-label {
      font-weight: bold;
    }
    .${theme.prefixCls}-form-item-extra, .${theme.prefixCls}-form-item-explain {
      font-size: ${theme.fontSize - 1}px;
      line-height: ${theme.fontSize + 2}px;
    }
  `(),
);
