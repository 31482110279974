/* istanbul ignore file */
import "core-js"; // polyfills. this needs to be on the first line
import "style/app.less";

import { App as AntApp } from "antd";
import { ConnectedRouter } from "connected-react-router";
import React from "react";
import { render } from "react-dom";
import Helmet from "react-helmet";
import { IntlProvider } from "react-intl";
import { Provider } from "react-redux";
import { Route, Switch } from "react-router-dom";

import { ModalContextWrapper } from "components/Modal";
import ErrorBoundary from "pages/App/ErrorBoundary";
import Notifier from "pages/App/Notifier";
import Demo from "pages/Demo";
import LoginNotRequired from "pages/LoginNotRequired";
import LoginRequired from "pages/LoginRequired";
import Swatch from "pages/Swatch";
import SyncExternalPage from "pages/SyncExternalPage";
import history from "services/history";
import { salesforceInit } from "services/thirdParty/salesforce";
import { satismeterInit } from "services/thirdParty/satismeter";
import { userpilotInit } from "services/thirdParty/userpilot";
import store from "store";
import { ThemeProvider } from "utils/context";
import { defaultLocale, intlPolyfill } from "utils/helpers";

(function initialize() {
  try {
    intlPolyfill();

    salesforceInit();

    userpilotInit();

    satismeterInit();

    // EXPERIMENTAL. https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Content-Security-Policy/trusted-types
    // window.trustedTypes = window.trustedTypes || trustedTypes;
    // window.trustedTypes?.createPolicy("default", {
    //   createHTML: (val: string) => DOMPurify.sanitize(val, { RETURN_TRUSTED_TYPE: true }).toString(),
    // });

    (module as any)?.hot?.accept?.(); // hot module reload
  } catch {}
})();

render(
  <Provider key="app" store={store}>
    <IntlProvider locale={defaultLocale}>
      <ConnectedRouter history={history}>
        <ThemeProvider prefixCls="TR" form={{ requiredMark: false, colon: false, scrollToFirstError: true }}>
          <AntApp style={{ height: "100%" }}>
            <ModalContextWrapper>
              <ErrorBoundary>
                <Notifier />
                <Helmet titleTemplate="%s - Trolley" defaultTitle="Trolley" />
                <Switch>
                  <Route path="/demo" component={Demo} />
                  <Route path="/integration" component={SyncExternalPage} />
                  <Route path="/swatch" component={Swatch} />
                  <Route path={["/login", "/register", "/auth/", "/fr/"]} component={LoginNotRequired} />
                  <Route component={LoginRequired} />
                </Switch>
              </ErrorBoundary>
            </ModalContextWrapper>
          </AntApp>
        </ThemeProvider>
      </ConnectedRouter>
    </IntlProvider>
  </Provider>,
  document.getElementById("app"),
);
