import { formatCountry, getRegionLabel, TaxFormType } from "@trolley/common-frontend";
import { Box, DateDisplay, FormItem } from "components";
import React from "react";
import { getTaxFormLabel } from "store/actions/recipientTaxForms";
import { UsUpload } from "store/actions/taxForms";
import { translateRegionByCountry } from "utils/helpers";

interface Props {
  data: UsUpload;
}

export default function UploadSummary({ data }: Props) {
  if ([TaxFormType.W8BEN, TaxFormType.W8BENE, TaxFormType.W9].includes(data.uploadKind as TaxFormType)) {
    return null;
  }

  return (
    <>
      <Box padding="small" header="Uploaded Form Type">
        <FormItem label="Tax Form Type">{getTaxFormLabel(data.uploadKind) || "-"}</FormItem>
        <FormItem label="Revision">{data.revision || "-"}</FormItem>
      </Box>

      <Box padding="small" header="Taxpayer Details">
        <FormItem label="First Name">{data.firstName}</FormItem>
        <FormItem label="Last Name">{data.lastName}</FormItem>
        <FormItem label="Business Name">{data.organizationName}</FormItem>
        <FormItem label="Mailing Address">{data.mailingAddress}</FormItem>
        <FormItem label="Mailing City">{data.mailingCity}</FormItem>
        <FormItem label="Mailing Country">{formatCountry(data.mailingCountry)}</FormItem>
        <FormItem label={`Mailing ${translateRegionByCountry(data.mailingCountry)}`}>{getRegionLabel(data.mailingRegion, data.mailingCountry)}</FormItem>
        <FormItem label="Mailing Postal Code">{data.mailingPostalCode}</FormItem>
      </Box>

      <Box padding="small" header="Tax Identification Details">
        <FormItem label="U.S. Taxpayer Identification Number (TIN)">{data.taxPayerUsId}</FormItem>
        <FormItem label="Foreign Tax ID Number">{data.taxPayerForeignId}</FormItem>
        {data.dob && (
          <FormItem label="Date of Birth">
            <DateDisplay value={data.dob} time={false} />
          </FormItem>
        )}
      </Box>
    </>
  );
}
