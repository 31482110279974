import { EmailTemplate, EoyTaxReportDeliveryStatusEmail, EoyTaxReportDeliveryStatusMail, EoyTaxReportStatus, TaxDeliveryType } from "@trolley/common-frontend";
import { Tabs } from "antd";
import BigNumber from "bignumber.js";
import { Box, Container, CopyToClipboard, CurrencyDisplay, DateDisplay, Flyout, Form, Grid, Icon, Status, Text, Timeline } from "components";
import Table, { TableColumnsType } from "components/Table";
import { RecipientDeliveryMethod, RecipientProfile } from "features/recipient";
import { translateDeliveryMethod } from "features/recipient/RecipientDeliveryMethod";
import { TaxFormDisplay } from "features/taxForm";
import { UserProfile } from "features/user";
import TaxDocumentPreview from "pages/RecipientsPage/Details/Tax/TaxDocumentPreview";
import React, { useState } from "react";
import { EoyTax } from "store/actions/eoyTaxForms";
import { MessageEvent } from "store/actions/messages";
import { getTaxFormLabel } from "store/actions/recipientTaxForms";
import { useEoyTaxForm } from "store/hooks/eoyTaxForms";
import { useEmailMessages, useLobMessages } from "store/hooks/messages";
import { useRecipient } from "store/hooks/recipients";
import { CATEGORY_DETAILS } from "utils/constants";
import { stringifyAddress } from "utils/helpers";

const COLSPAN = {
  xs: 24,
  md: 12,
};

const CATEGORY_LABELS_BY_INCOME = Object.fromEntries(
  Object.entries(CATEGORY_DETAILS)
    .filter(([category, group]) => group.incomeCode)
    .map(([category, group]) => [group.incomeCode, { ...group, category }]),
);

const COLUMNS: TableColumnsType<{ id: string; tooltip?: string; amount: string; withholdingAmount: string }> = [
  {
    dataIndex: "id",
    render: (id: string, record) => (
      <Text type="label">
        {id}
        {record.tooltip && <Icon.Hint right tooltip={record.tooltip} />}
      </Text>
    ),
  },
  {
    title: "Earnings",
    dataIndex: "amount",
    align: "right",
    render: (amount: string) => (new BigNumber(amount).gt(0) ? <CurrencyDisplay value={amount} currency="USD" /> : " - "),
  },
  {
    title: "Withholdings",
    dataIndex: "withholdingAmount",
    align: "right",
    render: (amount: string) => (new BigNumber(amount).gt(0) ? <CurrencyDisplay value={amount} currency="USD" /> : " - "),
  },
];

function getEarnings(reportBlocks: EoyTax["reportData"]["reportBlocks"]) {
  return reportBlocks
    .filter((block) => CATEGORY_LABELS_BY_INCOME[block.incomeCode])
    .map((block) => {
      return {
        id: CATEGORY_LABELS_BY_INCOME[block.incomeCode].name,
        tooltip: CATEGORY_LABELS_BY_INCOME[block.incomeCode].nonUsLabel,
        amount: block.equivUntaxedAmount,
        withholdingAmount: block.equivWithholdingAmount,
      };
    });
}

function renderEventTimeline(type: string, events: MessageEvent[]) {
  return (
    <Container padding="small">
      <Timeline>
        {events.map((event) => (
          <Timeline.Item key={event.date} dot={<Status size="small" type={event.type} dot />}>
            <Text style={{ lineHeight: "24px", height: "24px" }}>
              {type} {event.type}
            </Text>
            <DateDisplay value={event.date} icon={false} />
          </Timeline.Item>
        ))}
      </Timeline>
    </Container>
  );
}

interface Props {
  eoyTaxId?: string;
  onClose(): void;
}

export default function EarningPreview(props: Props) {
  const { eoyTaxId } = props;
  const { data: eoyTax } = useEoyTaxForm(eoyTaxId);
  const { data: recipient } = useRecipient(eoyTax?.recipientId);
  const { data: emailMessages } = useEmailMessages({ recipientId: eoyTax?.recipientId, relatedItemId: eoyTax?.id });
  const { data: lobMessages } = useLobMessages(eoyTax?.id);
  const [showTaxForm, setShowTaxForm] = useState("");
  const reportData = eoyTax?.reportData;
  const emailMessage = emailMessages.find((m) => m.templateId === EmailTemplate.WL_TAX_STATEMENT_AVAILABLE);
  const lobMessage = lobMessages.find((m) => m.lobGuid);

  return (
    <Flyout
      visible={!!eoyTax}
      onClose={props.onClose}
      title={
        eoyTax && (
          <>
            {getTaxFormLabel(eoyTax?.formType) || "Missing Form"} <Status type={eoyTax.status} right />
          </>
        )
      }
    >
      {eoyTax && (
        <Form compact>
          <Box padding="small" header="Earnings Details">
            <Grid padding={["small", "none"]}>
              <Grid.Item {...COLSPAN}>
                <Form.Item label="Updated On">
                  <DateDisplay value={eoyTax.updatedAt} />
                </Form.Item>
              </Grid.Item>
              {eoyTax.reviewedBy && (
                <Grid.Item {...COLSPAN}>
                  <Form.Item label="Updated By">
                    <UserProfile userId={eoyTax.reviewedBy} wrap />
                  </Form.Item>
                </Grid.Item>
              )}
            </Grid>
            <Grid padding={["small", "none"]}>
              <Grid.Item {...COLSPAN}>
                <Form.Item label="Tax Statement ID">
                  <CopyToClipboard value={eoyTax.id} />
                </Form.Item>
              </Grid.Item>
              <Grid.Item {...COLSPAN}>
                <Form.Item label="Related Tax Form">
                  <TaxFormDisplay
                    showSignedDate
                    showIcon
                    taxFormId={eoyTax?.lastTaxFormId}
                    onClick={(e) => {
                      setShowTaxForm(eoyTax.lastTaxFormId as string);
                    }}
                  />
                </Form.Item>
              </Grid.Item>
              {recipient && (
                <Grid.Item {...COLSPAN}>
                  <Form.Item label="Delivery Method">
                    <RecipientDeliveryMethod recipientId={recipient.id} />
                    {eoyTax.deliveryStatusEmail === EoyTaxReportDeliveryStatusEmail.SEND_REQUEST_FAILED_GENERIC && (
                      <Icon.Status type="error" left tooltip="Email Delivery failed" />
                    )}
                    {eoyTax.deliveryStatusMail === EoyTaxReportDeliveryStatusMail.SEND_REQUEST_FAILED_GENERIC && (
                      <Icon.Status type="error" left tooltip="Postal Mail Delivery failed" />
                    )}
                    {eoyTax.deliveryStatusMail === EoyTaxReportDeliveryStatusMail.SEND_REQUEST_FAILED_BAD_ZIP_CODE && (
                      <Icon.Status type="error" left tooltip="Postal Mail Delivery failed due to incorrect ZIP code format" />
                    )}
                  </Form.Item>
                </Grid.Item>
              )}
              {eoyTax.correction > 0 && (
                <Grid.Item {...COLSPAN}>
                  <Form.Item
                    label="Corrected Statement"
                    tooltip="A Corrected Statement is issued when a statement has been updated (i.e. corrected) and re-issued to the recipient. This may happen multiple times to the same recipient’s statement. The number indicates which version of the corrected statement this is."
                  >
                    {eoyTax.correction}
                  </Form.Item>
                </Grid.Item>
              )}

              {[EoyTaxReportStatus.SENT, EoyTaxReportStatus.VOID].includes(eoyTax.status) && (
                <Grid.Item {...COLSPAN}>
                  <Form.Item
                    label="Downloaded by the Recipient"
                    tooltip={`The recipient has downloaded the ${getTaxFormLabel(eoyTax.formType)} Tax Statement from the widget or recipient portal.`}
                  >
                    {eoyTax.downloadedByRecipient ? (
                      <>
                        <Icon.Status type="success" left />
                        Downloaded
                      </>
                    ) : (
                      "No"
                    )}
                  </Form.Item>
                </Grid.Item>
              )}
              <Grid.Item {...COLSPAN}>
                <Form.Item label="IRS Filing" tooltip="When downloading an E-File, statements will automatically be marked as E-Filed.">
                  {eoyTax.irsSubmittedAt ? (
                    <>
                      Marked as E-Filed <DateDisplay value={eoyTax.irsSubmittedAt} />
                    </>
                  ) : (
                    "Not E-Filed"
                  )}
                </Form.Item>
              </Grid.Item>
            </Grid>
            {reportData && (
              <>
                <Grid padding={["small", "none"]}>
                  <Grid.Item {...COLSPAN}>
                    <Form.Item label="Full Name">{reportData.name}</Form.Item>
                  </Grid.Item>
                </Grid>
                <Form.Item label="Address">{stringifyAddress(reportData, true)}</Form.Item>
                {reportData.ssn && <Form.Item label="Social Security Number">{reportData.ssn}</Form.Item>}
                {reportData.tin && <Form.Item label="Taxpayer Identification Number (EIN, ITIN, TIN)">{reportData.tin}</Form.Item>}
                {reportData.ftin && <Form.Item label="Foreign Tax ID Number">{reportData.ftin}</Form.Item>}
              </>
            )}
          </Box>
          {reportData?.reportBlocks && (
            <Table rowKey="id" dataSource={getEarnings(reportData.reportBlocks)} columns={COLUMNS} pagination={false} style={{ marginBottom: "16px" }} />
          )}
          {recipient && (
            <>
              <Box padding="small" header="Recipient Profile">
                <RecipientProfile recipientId={recipient.id} showEmail showAddress showLink showStatus />
              </Box>
            </>
          )}
          {(emailMessage || lobMessage) && (
            <Box padding="small" header="Notification Event History">
              <Tabs defaultActiveKey={emailMessage ? TaxDeliveryType.E_DELIVERY : TaxDeliveryType.MAIL} tabBarStyle={{ marginBottom: 0 }}>
                {emailMessage && (
                  <Tabs.TabPane tab={translateDeliveryMethod(TaxDeliveryType.E_DELIVERY)} key={TaxDeliveryType.E_DELIVERY}>
                    {renderEventTimeline("Email", emailMessage.events)}
                  </Tabs.TabPane>
                )}
                {lobMessage && (
                  <Tabs.TabPane tab={translateDeliveryMethod(TaxDeliveryType.MAIL)} key={TaxDeliveryType.MAIL}>
                    {renderEventTimeline("Postal Mail", lobMessage.events)}
                  </Tabs.TabPane>
                )}
              </Tabs>
            </Box>
          )}
          <TaxDocumentPreview
            taxFormId={showTaxForm}
            onClose={() => {
              setShowTaxForm("");
            }}
          />
        </Form>
      )}
    </Flyout>
  );
}
