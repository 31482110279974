import { Access, getPayoutMethodLabel, PayoutMethodType } from "@trolley/common-frontend";
import { Box, Button, Divider, Grid, Icon, Status, Text } from "components";
import { PayoutMethodDisplay } from "features/payoutMethod";
import { useRecipientEditable } from "features/recipient";
import { UserAccess } from "features/user";
import React, { useState } from "react";
import { RecipientAccount } from "store/actions/recipientAccount";
import { StoreRecipient } from "store/actions/recipients";
import { useRecipientAccounts } from "store/hooks/recipientAccount";
import styled, { ThemedToken, useThemeToken } from "style/classname";
import PayoutMethodsForm from "./PayoutMethodsForm";

const DEFAULT_NUMBER_OF_ACCOUNTS_TO_SHOW = 4;

const DETAILS_BY_PAYOUTMETHOD: Record<PayoutMethodType, (account: RecipientAccount) => string> = {
  [PayoutMethodType.BANKTRANSFER]: (account) => {
    if (account.accountNum) {
      return `${account.bankName} - ***${account.accountNum.substring(account.accountNum.length - 2)}`;
    } else if (account.iban) {
      return `${account.bankName} - ***${account.iban.substring(account.iban.length - 2)}`;
    } else {
      return account.bankName || "[Unknown Bank]";
    }
  },
  [PayoutMethodType.BITCOIN]: (account) => (account.wallet ? `***${account.wallet.substring(account.wallet.length - 4)}` : "*****"),
  [PayoutMethodType.CHECK]: (account) => account.mailing?.name || "",
  [PayoutMethodType.PAYPAL]: (account) => account.emailAddress || "******@***********",
  [PayoutMethodType.INTERAC]: (account) => account.emailAddress || "******@***********",
  [PayoutMethodType.VENMO]: (account) => {
    if (account.phoneNumber) {
      const dialCode = account.phoneNumber.replace(/^(\+\d+).+/, "$1");
      const splitIndex = account.phoneNumber.length - 4;

      return [
        account.phoneNumber.slice(0, dialCode.length), // show dial code
        account.phoneNumber.slice(dialCode.length, splitIndex).replace(/\d/g, "#"),
        account.phoneNumber.slice(splitIndex), // show last 4 digits
      ].join("");
    } else {
      return "+##############";
    }
  },
};

export function formatPayoutMethodDetails(account: RecipientAccount) {
  return DETAILS_BY_PAYOUTMETHOD[account.type]?.(account) || "";
}

interface Props {
  recipient: StoreRecipient;
  editable?: boolean;
  onChange?(): void;
}

export default function PayoutMethodsList(props: Props) {
  const { recipient, editable } = props;
  const [showMore, setShowMore] = useState(false);
  const theme = useThemeToken();
  const accounts = useRecipientAccounts(recipient.id);
  const [selectedMethod, setSelectedMethod] = useState<RecipientAccount | {} | undefined>();
  const profileEditable = useRecipientEditable();
  const allowEdit = editable && profileEditable;
  const records = accounts
    .map((account) => {
      return {
        id: account.id,
        payoutMethod: account.type,
        display: getPayoutMethodLabel(account.type, true),
        details: formatPayoutMethodDetails(account),
        accountHolderName: account.accountHolderName,
        status: account.status === "primary" ? "active" : account.status,
      };
    })
    .sort((a, b) => {
      if (a.status === "active") {
        return -1;
      }
      if (b.status === "active") {
        return 1;
      }

      return 0;
    })
    .filter((a, index: number) => showMore || index < DEFAULT_NUMBER_OF_ACCOUNTS_TO_SHOW);

  return (
    <>
      {accounts.length > 0 ? (
        <>
          <Grid direction="column" padding="small">
            {records.map((item) => (
              <Grid.Item key={item.id} className={styledItem({ ...item, allowEditing: allowEdit, theme })}>
                <Box
                  padding="small"
                  flat
                  color={item.status === "active" ? "green" : undefined}
                  onClick={
                    allowEdit
                      ? () => {
                          setSelectedMethod(accounts.find((a) => a.id === item.id));
                        }
                      : undefined
                  }
                >
                  <Grid wrap={false} padding="small">
                    <Grid.Item>
                      <div className="payout-icon">
                        <PayoutMethodDisplay value={item.payoutMethod} />
                      </div>
                    </Grid.Item>
                    <Grid.Item flex="1 1 200px">
                      <Text>{item.display}</Text>
                      <Text type="secondary" size="small">
                        {item.details}
                        {item.accountHolderName && <div>{item.accountHolderName}</div>}
                      </Text>
                    </Grid.Item>
                    <Grid.Item>
                      <Status type={item.status} />
                    </Grid.Item>
                  </Grid>
                </Box>
              </Grid.Item>
            ))}
          </Grid>
          {!showMore && accounts.length > DEFAULT_NUMBER_OF_ACCOUNTS_TO_SHOW && (
            <Button
              block
              onClick={() => {
                setShowMore((state) => !state);
              }}
              type="text"
              icon={<Icon type="chevron-down" />}
              style={{ margin: "8px 0" }}
            >
              Show all
            </Button>
          )}
        </>
      ) : (
        <Text padded align="center" style={{ margin: "16px 0" }}>
          No payout method setup
        </Text>
      )}

      {allowEdit && (
        <UserAccess type={Access.RECIPIENT_WRITE}>
          <Divider transparent margin="small" />
          <Button
            onClick={() => {
              setSelectedMethod({});
            }}
            icon={<Icon type="plus" />}
          >
            Add New Payout Method
          </Button>
        </UserAccess>
      )}

      <PayoutMethodsForm
        onClose={() => {
          setSelectedMethod(undefined);
          props.onChange?.();
        }}
        account={selectedMethod}
        recipient={recipient}
      />
    </>
  );
}

const styledItem = styled<Partial<RecipientAccount> & { allowEditing?: boolean; theme: ThemedToken }>`
.box {


}
.payout-icon {
  height: 28px;
  width: 28px;
  display: flex;
  justify-content: center;
  border-radius: 50%;
  align-items: center;
  background: ${(props) => props.theme.colorFillSecondary};
  color: ${(props) => (props.status === "active" ? props.theme.colorSuccess : props.theme.colorTextSecondary)};
}
`;
