import { Icon } from "components";
import React from "react";
import css, { classnames } from "style/classname";

import dayjs, { Dayjs, isDayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import localizedFormat from "dayjs/plugin/localizedFormat";
import customParseFormat from "dayjs/plugin/customParseFormat";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import calendar from "dayjs/plugin/calendar";
import objectSupport from "dayjs/plugin/objectSupport";

dayjs.extend(utc);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(localizedFormat);
dayjs.extend(customParseFormat);
dayjs.extend(quarterOfYear);
dayjs.extend(calendar);
dayjs.extend(objectSupport);

type Props = {
  icon?: boolean;
  value: string | number | undefined | null;
  date?: string;
  time?: string | false;
  className?: string;
  showUtc?: boolean;
  preserveFormat?: boolean; // preserve the given format. don't localize to 'yesterday' or 'today'
  isUnix?: boolean;
  emptyText?: string;
};

export default function DateDisplay(props: Props) {
  const { icon = true, date = "ll", time = "LTS", emptyText = " - ", value, className, showUtc, isUnix, preserveFormat } = props;
  let str;

  const yesterday = (showUtc ? dayjs.utc() : dayjs()).subtract(1, "day").startOf("day");
  const today = (showUtc ? dayjs.utc() : dayjs()).endOf("day");

  if (!!value) {
    let dayjsDate: Dayjs;
    if (isDayjs(value)) {
      dayjsDate = showUtc ? value.utc() : value.local();
    } else if (isUnix && typeof value === "number") {
      dayjsDate = dayjs.unix(value);
    } else {
      dayjsDate = showUtc ? dayjs.utc(value, undefined, true) : dayjs(value, undefined, true);
    }

    if (Number.isNaN(new Date(value).getTime())) {
      // if (!dayjsDate.isValid()) { // this doesn't work
      str = "Invalid date";
    } else {
      const withinADay = dayjsDate.isSameOrAfter(yesterday, "day") && dayjsDate.isSameOrBefore(today, "day");
      str = (
        <>
          <span className="date">
            {!preserveFormat && withinADay
              ? dayjsDate.calendar(undefined, {
                  lastDay: `[Yesterday${time ? " at" : ""}]`,
                  sameDay: `[Today${time ? " at" : ""}]`,
                  nextDay: `[Tomorrow${time ? " at" : ""}]`,
                })
              : dayjsDate.format(date)}
          </span>
          {!!time && (
            <span className="time" style={{ opacity: 0.8, fontSize: "0.857em" }}>
              {" "}
              {dayjsDate.format(time)}
              {showUtc && " UTC"}
            </span>
          )}
        </>
      );
    }
  } else {
    str = emptyText;
  }

  return (
    <div className={classnames(styledWrapper, className)}>
      {icon && <Icon type="clock" size="small" left style={{ opacity: "0.6" }} />}
      {str}
    </div>
  );
}

const styledWrapper = css`
  display: inline-block;
  white-space: nowrap;
`();
