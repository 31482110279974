import { datadogRum } from "@datadog/browser-rum";
import config from "config";
import { User } from "store/actions/user";

const { ENV, DATADOG, BUILD_VERSION } = config;

function init() {
  if (DATADOG && BUILD_VERSION) {
    datadogRum.init({
      ...DATADOG,
      env: ENV,
      version: BUILD_VERSION,
      sampleRate: 100,
      sessionReplaySampleRate: 1, // if not included, the default is 100
      trackResources: true,
      trackLongTasks: true,
      trackInteractions: true,
    });
  }
}

init();

export default {
  start(user: User) {
    if (DATADOG && BUILD_VERSION) {
      datadogRum.setUser({
        id: user.id,
        name: user.name,
        email: user.email,
        viewportWidth: Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0),
        viewportHeight: Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0),
      });
      datadogRum.startSessionReplayRecording();
    }
  },
  end() {
    if (DATADOG && BUILD_VERSION) {
      datadogRum.stopSessionReplayRecording();
      datadogRum.clearUser();
    }
  },
  error(error: any) {
    if (DATADOG && BUILD_VERSION) {
      datadogRum.addError(error);
    }
  },
};
