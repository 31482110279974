import store from "store";
import { OpCode, standardDispatch } from "store/dispatcher";
import * as request from "services/request";
import * as types from "@trolley/common-frontend";
import { isLoaded } from "./actionUtils";

export interface WhiteLabel extends types.WhiteLabel.SettingsAttributes {}
export interface WhiteLabelDns extends types.WhiteLabel.DnsAttributes {}
export interface DnsRecord extends types.WhiteLabel.DnsRecord {
  valid?: boolean;
}

export function loadWhiteLabel(force?: boolean) {
  const { whiteLabel } = store.getState();

  if (force || !isLoaded(whiteLabel.fetchStatus.data)) {
    standardDispatch(OpCode.LOADING, "whiteLabel");

    request
      .POST<types.WhiteLabel.Settings>("/v1/white-label/get")
      .then(({ body }) => {
        standardDispatch(OpCode.DATA, "whiteLabel", {
          data: body.whiteLabelSettings,
        });
      })
      .catch((errors) => {
        standardDispatch(OpCode.ERROR, "whiteLabel", {
          errors,
        });
      });
  }
}

const RELOAD_DNS: (keyof WhiteLabel)[] = ["domain", "subdomain", "email"];

export async function updateWhiteLabel(change: Partial<WhiteLabel>) {
  standardDispatch(OpCode.LOADING, "whiteLabel");

  try {
    const { body } = await request.POST<types.WhiteLabel.Settings>("/v1/white-label/update", { query: change });
    standardDispatch(OpCode.DATA, "whiteLabel", {
      data: body.whiteLabelSettings,
    });
    if (Object.keys(change).some((field: keyof WhiteLabel) => RELOAD_DNS.includes(field))) {
      loadDnsRecord(true);
    }

    return body.whiteLabelSettings;
  } catch (errors) {
    standardDispatch(OpCode.ERROR, "whiteLabel", {
      errors,
    });
    throw errors;
  }
}

export function loadDnsRecord(force?: boolean) {
  const { whiteLabelDns } = store.getState();

  if (force || !isLoaded(whiteLabelDns.fetchStatus.data)) {
    standardDispatch(OpCode.LOADING, "whiteLabelDns");

    request
      .POST<types.WhiteLabel.Dns>("/v1/white-label/dns-records/get")
      .then(({ body }) => {
        standardDispatch(OpCode.DATA, "whiteLabelDns", {
          data: body.dnsRecords,
        });
      })
      .catch((errors) => {
        standardDispatch(OpCode.ERROR, "whiteLabelDns", {
          errors,
        });
      });
  }
}

export async function validateDns() {
  try {
    standardDispatch(OpCode.LOADING, "whiteLabelDns");
    const res = await request.POST<{ valid: boolean }>("/v1/white-label/dns-records/validate");
    loadDnsRecord(true);
    standardDispatch(OpCode.LOADING, "whiteLabelDns", { loading: false });

    return res.body.valid;
  } catch (errors) {
    standardDispatch(OpCode.ERROR, "whiteLabelDns", { errors });
    throw errors;
  }
}

export async function sendTestEmail(type: types.EmailTemplate) {
  standardDispatch(OpCode.LOADING, "whiteLabel");
  try {
    await request.POST(`/v1/white-label/test`, { query: { type } });
    standardDispatch(OpCode.LOADING, "whiteLabel", { loading: false });
  } catch (errors) {
    standardDispatch(OpCode.ERROR, "whiteLabel", { errors });
    throw errors;
  }
}

export async function uploadWhiteLabelIcon(file: FormData) {
  standardDispatch(OpCode.LOADING, "whiteLabel");
  try {
    const res = await request.POST<types.WhiteLabel.Settings>("/v1/white-label/upload-icon", { body: file, isUpload: true });
    if (!res.body.whiteLabelSettings) {
      throw [{ code: "unhandled_error", message: "Settings not found" }];
    }
    standardDispatch(OpCode.UPDATE, "whiteLabel", {
      update: { icon: res.body.whiteLabelSettings.icon },
    });
  } catch (errors) {
    standardDispatch(OpCode.ERROR, "whiteLabel", { errors });
  }
}

export async function resetWhiteLabelEmailsSettings() {
  standardDispatch(OpCode.LOADING, "whiteLabel");
  try {
    const { body } = await request.POST<types.WhiteLabel.Settings>("/v1/white-label/reset-email");
    standardDispatch(OpCode.DATA, "whiteLabel", {
      data: body.whiteLabelSettings,
    });
    loadDnsRecord(true);
  } catch (errors) {
    standardDispatch(OpCode.ERROR, "whiteLabel", { errors });
  }
}
