import { Access } from "@trolley/common-frontend";
import { AlertSyncRecipients, Button, ButtonDropdown, Checkbox, Form, Grid, Icon, Input, Modal, Radio, SelectCountry, Space } from "components";
import { UserAccess } from "features/user";
import { useWhiteLabelWarning } from "features/whiteLabel";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { notifySuccess } from "store/actions/notifications";
import { createRecipient, Recipient, solicitRecipients } from "store/actions/recipients";
import { handleFormErrors } from "utils/helpers";

interface Props {
  visible: boolean;
  showExtraAddOptions?: boolean;
  onAdded?(recipient: Recipient): void;
  onClose?(): void;
}

export default function AddRecipientPopup(props: Props) {
  const { visible, onAdded, showExtraAddOptions = true, onClose } = props;
  const [form] = Form.useForm();
  const type = Form.useWatch(["type"], form);
  const [key, setKey] = useState<string>();
  const history = useHistory();
  const { whiteLabel, warning } = useWhiteLabelWarning();

  useEffect(() => {
    if (visible) {
      setKey(undefined);
      form.resetFields();
    }
  }, [visible]);

  function onSubmit(key?: string) {
    setKey(key);
    form.submit();
  }

  async function onCreateRecipient({ solicit, ...values }: any) {
    try {
      const recipient = await createRecipient({
        address: {
          country: values.country,
        },
        ...values,
      });

      notifySuccess(
        <span>
          <a
            role="button"
            onClick={() => {
              history.push(`/recipients/${recipient.id}`);
            }}
          >
            {recipient.name || recipient.id}
          </a>{" "}
          was added
        </span>,
      );

      if (solicit) {
        await solicitRecipients([recipient.id]);
      }

      onAdded?.(recipient);
      if (key === "edit") {
        history.push(`/recipients/${recipient.id}`);
      } else if (key === "new") {
        form.resetFields();
      } else if (onClose) {
        onClose();
      }
    } catch (errors) {
      handleFormErrors(errors, form, "Adding recipient failed");
    }
    setKey(undefined);
  }

  return (
    <Modal
      centered
      title="Create New Recipient"
      visible={props.visible}
      onCancel={onClose}
      footer={
        <Space direction="row-reverse">
          {showExtraAddOptions ? (
            <ButtonDropdown
              type="primary"
              onClick={() => {
                onSubmit();
              }}
              menu={{
                onClick: ({ key }) => {
                  onSubmit(key);
                },
                items: [
                  { key: "edit", label: "Create & Edit" },
                  { key: "new", label: "Create & Add Another" },
                ],
              }}
            >
              Create Recipient
            </ButtonDropdown>
          ) : (
            <Button
              type="primary"
              onClick={() => {
                onSubmit();
              }}
            >
              Create Recipient
            </Button>
          )}
          <Button onClick={onClose}>Cancel</Button>
        </Space>
      }
    >
      <AlertSyncRecipients />
      <Form form={form} onFinish={onCreateRecipient} initialValues={{ type: "individual" }} requiredMark="optional">
        <Grid padding={["medium", "none"]}>
          <Grid.Item xs={24} lg={type === "business" ? 12 : 24}>
            <Form.Item label="Type" name="type" validateTrigger="onChange" rules={[{ required: true }]}>
              <Radio.Group
                options={[
                  { value: "individual", label: "Individual" },
                  { value: "business", label: "Business" },
                ]}
              />
            </Form.Item>
          </Grid.Item>
          {type === "business" && (
            <Grid.Item xs={24} lg={12} key="name">
              <Form.Item label="Business Name" name="name" rules={[{ required: true, message: "Business name is required" }]}>
                <Input autoComplete="off" name="name" autoFocus />
              </Form.Item>
            </Grid.Item>
          )}
          <Grid.Item xs={24} lg={12} key="first">
            <Form.Item
              label="First Name"
              name="firstName"
              dependencies={["type"]}
              rules={[{ required: type === "individual", message: "First name is required" }]}
            >
              <Input autoComplete="off" name="firstName" />
            </Form.Item>
          </Grid.Item>
          <Grid.Item xs={24} lg={12} key="last">
            <Form.Item
              label="Last Name"
              name="lastName"
              dependencies={["type"]}
              rules={[{ required: type === "individual", message: "Last name is required" }]}
            >
              <Input autoComplete="off" name="lastName" />
            </Form.Item>
          </Grid.Item>

          <Grid.Item xs={24} lg={12}>
            <Form.Item
              label="Email"
              name="email"
              rules={[
                { required: true, message: "Email address is required" },
                { type: "email", message: "Enter a valid email address" },
              ]}
            >
              <Input autoComplete="off" name="email" />
            </Form.Item>
          </Grid.Item>
          <Grid.Item xs={24} lg={12}>
            <Form.Item label="Reference ID" name="referenceId">
              <Input name="referenceId" />
            </Form.Item>
          </Grid.Item>
        </Grid>

        <Form.Item label="Country" name="country" validateTrigger="onChange">
          <SelectCountry type="address" />
        </Form.Item>
        <UserAccess type={Access.RECIPIENT_WRITE}>
          {whiteLabel?.paymentMethod ? (
            <Form.Item name="solicit" valuePropName="checked" validateTrigger="onChange">
              <Checkbox disabled={!!warning}>
                Request recipient's account information through email
                {warning && <Icon.Status type="warning" right tooltip={warning} />}
              </Checkbox>
            </Form.Item>
          ) : null}
        </UserAccess>
      </Form>
    </Modal>
  );
}
