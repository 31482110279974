import { EoyTaxReportStatus } from "@trolley/common-frontend";
import { Badge, FileDownload, Text, Tooltip } from "components";
import { LAST_YEAR, TAX_PATHS } from "pages/TaxCenter";
import React, { ReactNode } from "react";
import { Link } from "react-router-dom";
import { DAC7EoyTax } from "store/actions/dac7EoyTaxForms";
import { useDAC7TaxMerchantYear } from "store/hooks/dac7TaxMerchantYear";
import { Pages, isProfileComplete } from ".";

interface Props {
  dac7EoyTax: DAC7EoyTax;
}
export default function EoyStatementDownload(props: Props) {
  const { dac7EoyTax } = props;
  const { data: dac7TaxMerchantYear } = useDAC7TaxMerchantYear(dac7EoyTax && dac7EoyTax.taxYear < LAST_YEAR ? undefined : dac7EoyTax.taxYear);

  if (!dac7EoyTax) {
    return null;
  }

  const isComplete =
    dac7EoyTax.taxYear < LAST_YEAR || // everything before last tax year can be shown.
    isProfileComplete(dac7TaxMerchantYear);
  const taxFormLabel = "DAC7 Statement";
  const downloadIcon = dac7TaxMerchantYear &&
    dac7EoyTax.status === EoyTaxReportStatus.SENT && ( // on all accounts, only show icon if sent
      <span>
        <FileDownload
          url="/v1/tax-year/dac7-eoy/single-pdf"
          method="POST"
          query={{ id: dac7EoyTax.id, taxYear: dac7EoyTax.taxYear }}
          fileName={`PR_eoyTaxForm_${dac7EoyTax.id}`}
          defaultExtension="pdf"
          icon
          disabled={!isComplete}
          right
        />
      </span>
    );
  let tooltipTitle: ReactNode = "Download Statement";
  if (!isComplete) {
    tooltipTitle = (
      <>
        Tax Statement is missing information.
        <br />
        Please complete <Link to={`${TAX_PATHS.EOY_REPORTING_DAC7}/${dac7EoyTax.taxYear}/${Pages.PROFILE}`}>the business tax profile </Link>
        related to Tax Statement {taxFormLabel}.
      </>
    );
  } else if (dac7EoyTax.status === EoyTaxReportStatus.VOID) {
    tooltipTitle = "Download Voided Statement";
  } else if (dac7EoyTax.correction > 0) {
    tooltipTitle = (
      <>
        Corrected statement #{dac7EoyTax.correction}.
        <Text type="secondary" size="small">
          A Corrected Statement is issued when a statement has been updated (i.e. corrected) and re-issued to the recipient. This may happen multiple times to
          the same recipient’s statement. The number indicates which version of the corrected statement this is.
        </Text>
      </>
    );
  }

  return (
    <span>
      {taxFormLabel}
      {downloadIcon && (
        <Tooltip title={tooltipTitle && <>{tooltipTitle}</>}>{dac7EoyTax.correction > 0 ? <Badge dot>{downloadIcon}</Badge> : downloadIcon}</Tooltip>
      )}
      {dac7EoyTax.status === EoyTaxReportStatus.VOID && (
        <Text size="small" type="secondary">
          Voided
        </Text>
      )}
    </span>
  );
}
