import { Rule } from "antd/lib/form";
import { InputMask, Select, Space } from "components";
import dayjs from "dayjs";
import React from "react";
import Input, { InputProps } from "./Input";

interface InputDateProps {
  value?: string | undefined;
  size?: InputProps["size"];
  onChange?: (value: string) => void;
  onBlur?: () => void;
  readOnly?: boolean;
  disabled?: boolean;
  allowClear?: boolean;
}

const MONTHS = [
  { value: "01", label: "January", title: "January 01" },
  { value: "02", label: "February", title: "February 02" },
  { value: "03", label: "March", title: "March 03" },
  { value: "04", label: "April", title: "April 04" },
  { value: "05", label: "May", title: "May 05" },
  { value: "06", label: "June", title: "June 06" },
  { value: "07", label: "July", title: "July 07" },
  { value: "08", label: "August", title: "August 08" },
  { value: "09", label: "September", title: "September 09" },
  { value: "10", label: "October", title: "October 10" },
  { value: "11", label: "November", title: "November 11" },
  { value: "12", label: "December", title: "December 12" },
];
export default function InputDate(props: InputDateProps) {
  const { value, size, readOnly, disabled, onChange, onBlur, allowClear } = props;

  const [year, month, date] = String(value || "").split("-");
  const commonProps = {
    size,
    onBlur,
    disabled,
  };

  if (readOnly) {
    return <Input readOnly value={value ? dayjs.utc(value).format("LL") : ""} />;
  }

  return (
    <Space gap="middle" wrap={false} justify="stretch">
      <Select
        {...commonProps}
        allowClear={allowClear}
        value={month || undefined}
        options={MONTHS}
        popupMatchSelectWidth={false}
        showSearch
        placeholder="Select month"
        optionFilterProp="title"
        onChange={(val) => {
          if ([year, val, date].some((v) => v)) {
            onChange?.([year, val, date].join("-"));
          } else {
            onChange?.("");
          }
        }}
        style={{ flex: "2 1 70px" }}
      />
      <InputMask
        {...commonProps}
        mask="99"
        maskChar=""
        value={String(date || "").replace(/^0+/, "")}
        placeholder="Day"
        onChange={(e) => {
          const val = String(e.target.value || "");
          if ([year, month, val].some((v) => v)) {
            onChange?.([year || "", month || "", val.padStart(2, "0")].join("-"));
          } else {
            onChange?.("");
          }
        }}
        style={{ flex: "1 0 40px" }}
      />
      <InputMask
        {...commonProps}
        mask="9999"
        maskChar=""
        value={year}
        placeholder="Year"
        onChange={(e) => {
          const val = String(e.target.value || "");
          if ([val, month, date].some((v) => v)) {
            onChange?.([val || "", month || "", date || ""].join("-"));
          } else {
            onChange?.("");
          }
        }}
        style={{ flex: "1 0 60px" }}
      />
    </Space>
  );
}

export const validatePastDate: Rule = {
  async validator(rule: any, value: string) {
    if (value) {
      const date = dayjs(value);
      if (!date.isValid() || !/^\d{4}-\d{2}-\d{2}$/.test(value)) {
        throw "Invalid date";
      }
      if (!date.isBefore(dayjs(), "day")) {
        throw "Date must be in the past";
      }
    }
  },
};

export const validateFutureDate: Rule = {
  async validator(rule: any, value: string) {
    if (value) {
      const date = dayjs(value);
      if (!date.isValid() || !/^\d{4}-\d{2}-\d{2}$/.test(value)) {
        throw "Invalid date";
      }
      if (!date.isAfter(dayjs(), "day")) {
        throw "Date must be in the future";
      }
    }
  },
};
