import { getPayoutMethodLabel, PayoutMethodType } from "@trolley/common-frontend";
import {
  Button,
  ColumnsDisplay,
  Container,
  CurrencyDisplay,
  DateDisplay,
  Dropdown,
  Grid,
  Heading,
  Icon,
  InputTags,
  LabelTooltip,
  Menu,
  MixedColumn,
  RecordCount,
  Reloader,
  Table,
  tableColumnFilter,
  TableColumnsType,
  TableFilters,
  TableName,
  Text,
  useColumnsDisplay,
} from "components";
import dayjs from "dayjs";
import { PaymentDisplay } from "features/payment";
import { PaymentTaxRecordDAC7Edit } from "features/paymentTax";
import { PayoutMethodDisplay } from "features/payoutMethod";
import { RecipientPreview, RecipientProfile } from "features/recipient";
import { TaxProfileDisplay } from "features/taxProfile";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { MerchantSettings } from "store/actions/merchantSettings";
import { DAC7Tax, DAC7TaxQuery, resetDAC7Tax } from "store/actions/paymentDac7Taxes";
import { useMerchantSettings } from "store/hooks/merchantSettings";
import { usePaymentDac7Taxes } from "store/hooks/paymentDac7Taxes";
import { CATEGORY_DETAILS } from "utils/constants";
import { getPaymentCategoryLabel } from "utils/helpers";
import { useQuery } from "utils/hooks";

const DEFAULT_QUERY: DAC7TaxQuery = {
  page: 1,
  pageSize: 10,
  sortBy: ["desc"],
};

enum ColumnKeys {
  SEARCH = "search",
  RECIPIENT = "recipient",
  PAYMENT_ID = "paymentId",
  AMOUNT = "amount",
  TAX_PROFILE = "taxProfileId",
  CATEGORY = "category",
  TAX_REPORTABLE = "taxReportable",
  TAGS = "tags",
  MEMO = "memo",
  MODIFIED = "modifiedSinceCreation",
  CREATED_AT = "createdAt",
  UPDATED_AT = "updatedAt",
  PROCESSED_AT = "processedAt",
  PAYOUT_METHOD = "payoutMethod",
}

const defaultColumnsDisplay: Record<ColumnKeys, MixedColumn> = {
  [ColumnKeys.SEARCH]: true,
  [ColumnKeys.RECIPIENT]: true,
  [ColumnKeys.AMOUNT]: true,
  [ColumnKeys.PAYMENT_ID]: {
    label: "Related Payment",
    value: true,
  },
  [ColumnKeys.PAYOUT_METHOD]: {
    label: "Payout Method",
    value: true,
  },
  [ColumnKeys.CATEGORY]: {
    label: "Payment Purpose",
    value: true,
  },
  [ColumnKeys.TAX_REPORTABLE]: {
    label: "Taxable",
    value: true,
  },
  [ColumnKeys.TAX_PROFILE]: {
    label: "Tax Profile",
    value: true,
  },
  [ColumnKeys.TAGS]: {
    label: "Tags",
    value: false,
  },
  [ColumnKeys.MEMO]: {
    label: "Memo",
    value: false,
  },
  [ColumnKeys.MODIFIED]: {
    label: "Modified Since Creation",
    value: false,
  },
  [ColumnKeys.CREATED_AT]: {
    label: "Created On",
    value: true,
  },
  [ColumnKeys.UPDATED_AT]: {
    label: "Updated On",
    value: false,
  },
  [ColumnKeys.PROCESSED_AT]: {
    label: "Processed On",
    value: false,
  },
};

export function getExcludedKeysForDisplayColumnMenu(merchantSettings: MerchantSettings | undefined, query: DAC7TaxQuery) {
  const excludeColumns: string[] = [];

  if (query.recipientIds) {
    excludeColumns.push(ColumnKeys.SEARCH);
  }

  return excludeColumns;
}

export default function PaymentTaxesDAC7() {
  const { data: merchantSettings } = useMerchantSettings();
  const [query, setQuery] = useQuery(DEFAULT_QUERY, {
    arrayKeys: ["payoutMethods", "categories", "statuses", "recipientIds"],
  });
  const { data: dac7Taxes, status, error } = usePaymentDac7Taxes(query);
  const [selected, setSelected] = useState<DAC7Tax[]>([]);
  const [previewRecipient, setPreviewRecipient] = useState<undefined | string>();
  const [allSelected, setAllSelected] = useState(false);
  const selectedDateColumn = query.dateColumn || "createdAt";
  const [displayedColumns, setDisplayedColumns] = useColumnsDisplay(
    TableName.PAYMENT_TAXES,
    defaultColumnsDisplay,
    getExcludedKeysForDisplayColumnMenu(merchantSettings, query),
    query,
  );
  const [paymentTax, editPaymentTax] = useState<DAC7Tax | undefined>();

  useEffect(() => {
    setAllSelected(false);
    setSelected([]);
  }, [query]);

  return (
    <Container>
      <Helmet>
        <title>Payment DAC7 Records</title>
      </Helmet>
      <Heading>
        <RecordCount prefix="Showing" value={dac7Taxes.meta.records} one="1 payment tax record" other="# payment tax records" equalZero="payment tax records" />
        <Reloader onClick={resetDAC7Tax} status={status} />
      </Heading>

      <TableFilters
        showSearch
        onChange={setQuery}
        query={query}
        suffix={<ColumnsDisplay columns={displayedColumns} onChange={setDisplayedColumns} />}
        fields={[
          {
            label: "Payout Method",
            type: "select",
            filterKey: "payoutMethods",
            options: Object.values(PayoutMethodType).map((pm) => ({
              label: getPayoutMethodLabel(pm, true),
              value: pm,
            })),
          },
          {
            label: "Payment Purpose",
            type: "select",
            filterKey: "categories",
            options: Object.entries(CATEGORY_DETAILS).map(([category, item]) => ({
              label: item.name,
              value: category,
            })),
          },
          {
            label: "Payment Origin",
            type: "radio",
            filterKey: "withPayment",
            options: [
              { label: "All", value: undefined },
              { label: "Trolley", value: true },
              { label: "Offline", value: false },
            ],
          },
          {
            label: <LabelTooltip type="taxReportable" />,
            type: "radio",
            filterKey: "taxReportable",
            options: [
              { label: "All", value: undefined },
              { label: "Taxable", value: true },
              { label: "Exempt", value: false },
            ],
          },
          {
            label: (
              <Text capitalize inline>
                <Dropdown
                  placement="bottomLeft"
                  overlay={
                    <Menu
                      onClick={(params) => {
                        setQuery((state) => {
                          const dateColumn = params.key as "createdAt" | "updatedAt" | "processedAt";
                          if (state.startDate && state.endDate) {
                            return {
                              dateColumn,
                              startDate: params.key === "processedAt" ? state.startDate.substring(0, 10) : dayjs(state.startDate).startOf("date").format(),
                              endDate: params.key === "processedAt" ? state.endDate.substring(0, 10) : dayjs(state.endDate).endOf("date").format(),
                            };
                          } else {
                            return {
                              dateColumn,
                            };
                          }
                        });
                      }}
                    >
                      <Menu.Item disabled={query.dateColumn === "updatedAt"} key="updatedAt">
                        Updated Between
                      </Menu.Item>
                      <Menu.Item disabled={query.dateColumn === "createdAt"} key="createdAt">
                        Created Between
                      </Menu.Item>
                      <Menu.Item disabled={query.dateColumn === "processedAt"} key="processedAt">
                        Processed Between
                      </Menu.Item>
                    </Menu>
                  }
                >
                  <span>
                    {selectedDateColumn.replace("At", " Between")}
                    <Icon type="angle-down" size="small" right />
                  </span>
                </Dropdown>
              </Text>
            ),
            filterKey: ["startDate", "endDate"],
            type: "date-range",
            format: selectedDateColumn === "processedAt" ? "YYYY-MM-DD" : undefined,
          },
          {
            label: "Recipient ID",
            type: "tags",
            filterKey: "recipientIds",
            placeholder: "Recipient ID",
            maxLength: 1,
            renderTag: (id) => <RecipientProfile onlyName recipientId={id} />,
          },
        ]}
      >
        {selected.length > 0 && (
          <Grid padding="small">
            {!allSelected && selected.length === dac7Taxes.records.length && dac7Taxes.meta.records > selected.length && (
              <Grid.Item>
                <Button
                  type="link"
                  onClick={() => {
                    setAllSelected(true);
                  }}
                >
                  <RecordCount value={dac7Taxes.meta.records} other="Select all # payment tax records" />
                </Button>
              </Grid.Item>
            )}

            {allSelected && (
              <Grid.Item>
                <Button
                  type="link"
                  onClick={() => {
                    setSelected([]);
                    setAllSelected(false);
                  }}
                >
                  Clear Selection
                </Button>
              </Grid.Item>
            )}
          </Grid>
        )}
      </TableFilters>

      <Table
        status={status}
        errors={error}
        dataSource={dac7Taxes.records}
        emptyProps={{ description: "No payment tax records found" }}
        rowSelection={{
          fixed: true,
          selectedRowKeys: selected.map((item) => item.id),
          onChange: (_, pTaxes) => {
            setSelected(pTaxes);
          },
          onSelect(record, isSelected) {
            if (!isSelected) {
              setAllSelected(false);
            }
          },
          onSelectAll(isSelected: boolean) {
            if (!isSelected) {
              setAllSelected(false);
            }
          },
        }}
        onRow={(record) => ({
          onClick(e) {
            editPaymentTax(record);
          },
        })}
        columns={
          [
            {
              key: ColumnKeys.SEARCH,
              dataIndex: "recipientId",
              width: 10,
              render: (recipientId: string) =>
                query.recipientIds ? null : (
                  <a
                    role="button"
                    onClick={(e) => {
                      e?.stopPropagation?.();
                    }}
                  >
                    <Icon
                      type="search"
                      right
                      tooltip="Filter by Recipient"
                      onClick={() => {
                        setQuery({ recipientIds: [recipientId] });
                      }}
                    />
                  </a>
                ),
            },
            {
              title: "Recipient",
              key: ColumnKeys.RECIPIENT,
              dataIndex: "recipientId",
              render: (id: string) => (
                <RecipientProfile
                  recipientId={id}
                  showStatus="dot"
                  size="small"
                  showAddress
                  showLink={(e) => {
                    setPreviewRecipient(id);
                  }}
                />
              ),
            },
            {
              title: "Related Payment",
              key: ColumnKeys.PAYMENT_ID,
              align: "right",
              dataIndex: "paymentId",
              render: (id: string, pt: DAC7Tax) =>
                id ? (
                  <PaymentDisplay showPreview paymentId={id} showDate />
                ) : (
                  <Text type="secondary">
                    Offline Payment
                    {pt.externalId && <Text size="small">External ID: {pt.externalId}</Text>}
                  </Text>
                ),
            },
            {
              title: "Payment Tax Amount",
              key: ColumnKeys.AMOUNT,
              dataIndex: "enteredAmount",
              align: "right",
              render: (entered: DAC7Tax["enteredAmount"]) => <CurrencyDisplay value={entered.value} currency={entered.currency} />,
            },
            {
              title: "Tax Profile",
              key: ColumnKeys.TAX_PROFILE,
              dataIndex: "taxProfileId",
              render: (taxProfileId: string | null) => <TaxProfileDisplay taxProfileId={taxProfileId} showSignedDate showStatus />,
            },
            {
              title: "Payout Method",
              key: ColumnKeys.PAYOUT_METHOD,
              dataIndex: "payoutMethod",
              render: (pm: DAC7Tax["payoutMethod"]) => <PayoutMethodDisplay value={pm} size="large" showTooltip />,
            },
            {
              key: ColumnKeys.CATEGORY,
              title: <LabelTooltip type="category" />,
              dataIndex: "category",
              render: (category: DAC7Tax["category"]) => getPaymentCategoryLabel(category),
            },
            {
              title: <LabelTooltip type="taxReportable" />,
              key: ColumnKeys.TAX_REPORTABLE,
              dataIndex: "taxReportable",
              render: (taxable: boolean) => (taxable ? "Taxable" : "Exempt"),
            },
            { title: "Tags", key: ColumnKeys.TAGS, dataIndex: "tags", render: (tags: string[]) => <InputTags value={tags} /> },
            {
              title: "Memo",
              key: ColumnKeys.MEMO,
              dataIndex: "memo",
              render: (memo: string) => memo && <Icon type="sticky-note" size="large" tooltip={memo} />,
            },
            {
              title: "Processed On (UTC)",
              key: ColumnKeys.PROCESSED_AT,
              dataIndex: "processedAt",
              sorter: true,
              sortOrder: query.orderBy?.[0] === "processedAt" ? (query.sortBy?.[0] === "asc" ? "ascend" : "descend") : undefined,
              render: (date: string) => <DateDisplay value={date} time={false} showUtc />,
            },
            {
              title: "Created On",
              key: ColumnKeys.CREATED_AT,
              dataIndex: "createdAt",
              sorter: true,
              sortOrder: query.orderBy?.[0] === "createdAt" ? (query.sortBy?.[0] === "asc" ? "ascend" : "descend") : undefined,
              render: (date: string) => <DateDisplay value={date} />,
            },
            {
              title: "Updated On",
              key: ColumnKeys.UPDATED_AT,
              dataIndex: "updatedAt",
              sorter: true,
              sortOrder: query.orderBy?.[0] === "updatedAt" ? (query.sortBy?.[0] === "asc" ? "ascend" : "descend") : undefined,
              render: (date: string) => <DateDisplay value={date} />,
            },
          ].filter(tableColumnFilter(displayedColumns)) as TableColumnsType<DAC7Tax>
        }
        onQueryChange={setQuery}
        pagination={{
          current: query.page,
          pageSize: query.pageSize,
          total: dac7Taxes.meta.records,
        }}
      />
      <PaymentTaxRecordDAC7Edit
        dac7Tax={paymentTax}
        onClose={() => {
          editPaymentTax(undefined);
        }}
      />
      <RecipientPreview
        recipientId={previewRecipient}
        onClose={() => {
          setPreviewRecipient(undefined);
        }}
      />
    </Container>
  );
}
