import { generate } from "@ant-design/colors";
import { Container, CopyToClipboard, Grid, Heading, Space, Switch } from "components";
import React, { useState } from "react";
import colors from "style/colors";
import palette from "style/palette";
import tinycolor from "tinycolor2";

export default function Swatch() {
  const [dark, setDark] = useState(false);
  const [newStyle, setNewStyle] = useState(true);
  const backgroundColor = dark ? colors.black : colors.white;
  const color = dark ? colors.white : colors.black;

  return (
    <Container padding="large" style={{ height: "100%", backgroundColor, color }}>
      <Heading tag="h1" style={{ color }}>
        <Space gap="large">
          Color Palette
          <Switch checked={dark} checkedChildren="DARK MODE" unCheckedChildren="LIGHT MODE" onChange={setDark} />
          <Switch checked={newStyle} checkedChildren="NEW PALETTE" unCheckedChildren="OLD PALETTE" onChange={setNewStyle} />
        </Space>
      </Heading>
      {["primary", "blue", "green", "red", "yellow", "purple", "grey", "black", "white"].map((col) => (
        <Grid padding={["none", "medium"]} key={col} style={{ marginBottom: "8px" }} wrap={false}>
          {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((index) => {
            let color = palette[`${col}-${index}`];

            if (newStyle) {
              if (["black", "white"].includes(col)) {
                return null;
              }
              color = generate(colors[col], {
                theme: dark ? "dark" : "default",
                backgroundColor,
              })[index - 1];
            }

            return (
              <Grid.Item key={[col, index].join()} flex="1 1 10%">
                {color && (
                  <div
                    style={{
                      width: "100%",
                      padding: "8px",
                      color: tinycolor(color).isDark() ? "#FFF" : "#000",
                      backgroundColor: color,
                    }}
                  >
                    <strong>
                      {col} {index}
                    </strong>
                    <br />
                    <CopyToClipboard value={color} />
                  </div>
                )}
                {!newStyle && palette[`${col}-alpha-${index}`] && (
                  <div style={{ width: "100%", padding: "8px", backgroundColor: palette[`${col}-alpha-${index}`] }}>
                    <strong>{`${col}-alpha-${index}`}</strong>
                    <br />
                    <CopyToClipboard value={palette[`${col}-alpha-${index}`]} />
                  </div>
                )}
              </Grid.Item>
            );
          })}
        </Grid>
      ))}
    </Container>
  );
}
