import { Store } from "store";
import { shallowEqual, useSelector } from "react-redux";
import deepEqual from "fast-deep-equal";

export function customShallowEqual<T>(objA: T, objB: T): boolean {
  if (objA && objB && typeof objA === "object" && typeof objB === "object") {
    const keysA = Object.keys(objA);
    const keysB = Object.keys(objB);

    if (keysA.length !== keysB.length) return false;

    for (let i = 0; i < keysA.length; i += 1) {
      if (keysA[i] === "data") {
        if (!deepEqual(objA[keysA[i]], objB[keysA[i]])) {
          return false;
        }
      } else if (!shallowEqual(objA[keysA[i]], objB[keysA[i]])) {
        return false;
      }
    }

    return true;
  }

  if (shallowEqual(objA, objB)) {
    return true;
  }

  return false;
}

export function useShallowSelector<TSelected>(selector: (state: Store) => TSelected) {
  return useSelector<Store, TSelected>(selector, customShallowEqual);
}
