import BigNumber from "bignumber.js";
import { Alert, Button, CurrencyDisplay, Divider, Grid, Heading, Icon, InputNumber, Modal, Status, Table, Text, Tooltip, Typography } from "components";
import { InvoiceLoader, InvoicePreview } from "features/invoice";
import { PaymentPreview } from "features/payment";
import React, { FormEvent, Fragment, useEffect, useState } from "react";
import { InvoicePayment, deleteInvoicePayments, updateInvoicePayments } from "store/actions/invoicePayments";
import { notifyError, notifySuccess } from "store/actions/notifications";
import { Payment, PaymentStatus } from "store/actions/payments";
import { useInvoicePayments } from "store/hooks/invoicePayments";
import css, { createUseStyle } from "style/classname";
import { entriesGroupBy } from "utils/helpers";

interface Props {
  payment: Payment | undefined;
  onClose(): void;
}

export default function InvoicePaymentsEdit(props: Props) {
  const { payment, onClose } = props;
  const [busy, setBusy] = useState(false);
  const [previewInvoiceId, setPreviewInvoiceId] = useState("");
  const styledDeletedLine = useStyledDeletedLine();
  const { data: invoicePayments } = useInvoicePayments(payment?.id);
  const [paymentAllocation, setPaymentAllocation] = useState<Record<string, string | undefined>>({});
  const [deleteLines, setDeleteLines] = useState<string[]>([]);
  const invoicePaymentsGroupByInvoice = entriesGroupBy(invoicePayments, (ip) => ip.invoiceId);
  const paymentAllocationEntries = Object.entries(paymentAllocation);
  const totalPaymentAllocation = paymentAllocationEntries.reduce((acc, [_, amount]) => acc.plus(amount || 0), new BigNumber(0));
  const paymentIsEditable = payment?.status === PaymentStatus.PENDING;

  useEffect(() => {
    if (payment && invoicePayments.length) {
      setDeleteLines([]);
      setPaymentAllocation(
        invoicePayments.reduce((acc, ip) => {
          acc[ip.invoiceLineId] = ip.amount.value;

          return acc;
        }, {}),
      );
    }
  }, [payment, invoicePayments]);

  async function onSubmit(e: FormEvent) {
    e?.preventDefault?.();
    async function save() {
      if (payment && invoicePayments.length && paymentAllocationEntries.length) {
        setBusy(true);
        const updateData = paymentAllocationEntries
          .filter(([invoiceLineId]) => !deleteLines.includes(invoiceLineId))
          .map(([invoiceLineId, amount]) => {
            return {
              invoiceLineId,
              paymentId: payment.id,
              amount: {
                value: amount || "0",
                currency: payment.currency,
              },
            };
          });

        const changedData = updateData.filter((record) =>
          invoicePayments.some((ip) => ip.invoiceLineId === record.invoiceLineId && !new BigNumber(ip.amount.value).eq(record.amount.value)),
        );
        try {
          if (changedData.length) {
            await updateInvoicePayments(changedData);
          }

          if (deleteLines.length) {
            await deleteInvoicePayments({ paymentId: payment.id, invoiceLineIds: deleteLines });
          }

          notifySuccess("Invoice Payments Updated");
          onClose();
        } catch (errors) {
          notifyError("Updating Invoice Payments Failed");
        }
        setBusy(false);
      }
    }

    if (paymentIsEditable) {
      await save();
    } else {
      Modal.confirm({
        title: "Payment cannot be updated!",
        content: (
          <>
            <p>
              The payment is <Status type={payment?.status} />, updating the invoice payments will not update the payment.
            </p>
            Do you still want update the invoice payment allocations?
          </>
        ),
        okText: "Yes, Update",
        onOk: save,
      });
    }
  }

  return (
    <Modal
      title="Invoice Payment Allocation"
      onCancel={onClose}
      visible={!!payment}
      onOk={paymentIsEditable ? onSubmit : onClose}
      okText={paymentIsEditable ? "Update Invoice Payments" : "Close"}
      cancelButtonProps={paymentIsEditable ? undefined : { style: { display: "none" } }}
      confirmLoading={busy}
      width={600}
    >
      {payment && (
        <>
          <PaymentPreview paymentId={payment.id}>
            A payment of <CurrencyDisplay value={payment.amount} currency={payment.currency} />
          </PaymentPreview>
          {payment.status === PaymentStatus.PROCESSED ? " was processed" : " exists"}.
          {!paymentIsEditable && (
            <Alert type="info">
              The payment is <Status type={payment?.status} />, invoice payments can only be edited for <Status type="pending" /> payments.
            </Alert>
          )}
          <Text>Here is a detailed breakdown of the invoice payment allocation:</Text>
          {invoicePaymentsGroupByInvoice.map(([invoiceId, ips], index) => (
            <Fragment key={invoiceId}>
              <InvoiceLoader id={invoiceId}>
                {(invoice) => {
                  return invoice ? (
                    <>
                      <Divider margin="medium" />
                      <Heading tag="h3" description={invoice.description}>
                        <a
                          role="link"
                          onClick={() => {
                            setPreviewInvoiceId(invoiceId);
                          }}
                        >
                          <Icon type="file-invoice" left />
                          Invoice {invoice.invoiceNumber || invoiceId}
                        </a>
                      </Heading>
                      <Table
                        size="small"
                        rowKey={(ip) => [ip.invoiceId, ip.invoiceLineId].join("-")}
                        pagination={false}
                        dataSource={ips}
                        rowClassName={(row) => (deleteLines.includes(row.invoiceLineId) ? styledDeletedLine : "np")}
                        columns={[
                          {
                            title: "Line Description",
                            dataIndex: "invoiceLineId",
                            key: "description",
                            render(lineId: string, ip, index) {
                              const line = invoice.lines.find((l) => l.id === lineId);

                              if (line) {
                                return (
                                  <Typography.Text type={line.description ? "secondary" : undefined}>
                                    {line.description || `Line Item #${index + 1}`}
                                  </Typography.Text>
                                );
                              }

                              return null;
                            },
                          },
                          {
                            title: "Line Amount",
                            align: "right",
                            dataIndex: "invoiceLineId",
                            key: "total",
                            render(lineId: string, ip) {
                              const line = invoice?.lines.find((l) => l.id === lineId);
                              if (line) {
                                return (
                                  <>
                                    <CurrencyDisplay value={line.totalAmount.value} currency={line.totalAmount.currency} />
                                    <Text type="secondary" size="small">
                                      {line.quantity}&nbsp;x&nbsp;
                                      <CurrencyDisplay value={line.unitAmount.value} currency={line.unitAmount.currency} />
                                      {new BigNumber(line.discountAmount.value).gt(0) && (
                                        <>
                                          <br />
                                          Discount: <CurrencyDisplay value={line.discountAmount.value} currency={line.discountAmount.currency} />
                                        </>
                                      )}
                                      {new BigNumber(line.taxAmount.value).gt(0) && (
                                        <>
                                          <br />
                                          Taxes: <CurrencyDisplay value={line.taxAmount.value} currency={line.taxAmount.currency} />
                                        </>
                                      )}
                                    </Text>
                                  </>
                                );
                              }

                              return null;
                            },
                          },
                          {
                            title: (
                              <>
                                Payment Allocation
                                <Icon.Hint
                                  tooltip={
                                    <>
                                      The allocated portion of the total payment{" "}
                                      {payment && (
                                        <>
                                          (<CurrencyDisplay value={payment.amount} currency={payment.currency} />){" "}
                                        </>
                                      )}
                                      that is paying towards an invoice line item.
                                    </>
                                  }
                                  right
                                />
                              </>
                            ),
                            dataIndex: "amount",
                            align: "right",
                            width: 150,
                            render: (amount: InvoicePayment["amount"], ip) => (
                              <InputNumber
                                inputMode="decimal"
                                allowNegative
                                disabled={deleteLines.includes(ip.invoiceLineId) || !paymentIsEditable}
                                addonAfter={amount.currency}
                                value={paymentAllocation[ip.invoiceLineId]}
                                onChange={(value) => {
                                  setPaymentAllocation((state) => ({
                                    ...state,
                                    [ip.invoiceLineId]: value,
                                  }));
                                }}
                              />
                            ),
                          },
                          ...(paymentIsEditable
                            ? [
                                {
                                  key: "actions",
                                  width: 40,
                                  render(ip: InvoicePayment) {
                                    if (invoice) {
                                      return deleteLines.includes(ip.invoiceLineId) ? (
                                        <Tooltip title="Undo" placement="topRight">
                                          <Button
                                            size="small"
                                            onClick={() => {
                                              setDeleteLines((lines) => lines.filter((id) => id !== ip.invoiceLineId));
                                            }}
                                            icon={<Icon type="undo" style={{ marginLeft: "1px", marginTop: "1px" }} />}
                                          />
                                        </Tooltip>
                                      ) : (
                                        <Tooltip title="Delete Invoice Allocation" placement="topRight">
                                          <Button
                                            size="small"
                                            danger
                                            ghost
                                            onClick={() => {
                                              setDeleteLines((lines) => {
                                                return [ip.invoiceLineId, ...lines];
                                              });
                                            }}
                                            icon={<Icon type="trash" theme="solid" />}
                                          />
                                        </Tooltip>
                                      );
                                    }

                                    return null;
                                  },
                                },
                              ]
                            : []),
                        ]}
                      />
                    </>
                  ) : null;
                }}
              </InvoiceLoader>
            </Fragment>
          ))}
          <Grid style={{ textAlign: "right" }} padding="xsmall">
            <Grid.Item flex={1}>
              <Text type="label">
                Total Payment Allocation
                <Icon.Hint tooltip="The total amount that is paying towards an invoice" right />
              </Text>
            </Grid.Item>
            <Grid.Item xs={24} sm={6}>
              <CurrencyDisplay value={totalPaymentAllocation} currency={payment.currency} />
            </Grid.Item>
          </Grid>
        </>
      )}
      <InvoicePreview
        invoiceId={previewInvoiceId}
        onClose={() => {
          setPreviewInvoiceId("");
        }}
      />
    </Modal>
  );
}

const useStyledDeletedLine = createUseStyle(({ theme }) =>
  css`
    td {
      background-color: ${theme.controlItemBgActiveDisabled} !important;
    }
  `(),
);
