import { Tooltip as AntTooltip, ConfigProvider, theme } from "antd";
import { TooltipPropsWithTitle } from "antd/lib/tooltip";
import { Stop } from "components";
import React from "react";
import { IntlProvider } from "react-intl";
import styled, { createUseStyle } from "style/classname";
import colors from "style/colors";
import palette from "style/palette";
import { defaultLocale } from "utils/helpers";

export interface TooltipProps extends Omit<TooltipPropsWithTitle, "color" | "visible" | "open"> {
  visible?: boolean;
}

export default function Tooltip(props: TooltipProps) {
  const { title, destroyTooltipOnHide = true, visible, ...rest } = props;
  const styledTooltip = useSyledTooltip(props);

  return (
    <AntTooltip
      open={visible}
      title={
        // PREVENT event bubbling
        <IntlProvider locale={defaultLocale}>
          <ConfigProvider
            theme={{
              algorithm: [theme.darkAlgorithm],
            }}
          >
            <Stop type="section">{title}</Stop>
          </ConfigProvider>
        </IntlProvider>
      }
      destroyTooltipOnHide={destroyTooltipOnHide}
      rootClassName={styledTooltip}
      color={colors.black}
      {...rest}
    />
  );
}

const useSyledTooltip = createUseStyle<TooltipProps>(({ theme, ...props }) =>
  styled`
  &.${theme.prefixCls}-tooltip {
    max-width: 300px;
    .${theme.prefixCls}-tooltip-inner {
      .${theme.prefixCls}-typography.label,
      .${theme.prefixCls}-typography.secondary {
        color: ${palette["white-alpha-6"]};
      }
      padding: 0;
      & > * {
        padding: 8px;
        max-height: 60vh;
        overflow-y: auto;
      }
    }
  }
`(),
);
