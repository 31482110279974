import { CountryCode } from "@trolley/common-frontend/lib/types";

export default function translateRegionByCountry(country: string | null | undefined) {
  switch (country) {
    case CountryCode.GB:
      return "County";
    case CountryCode.CA:
      return "Province";
    case CountryCode.US:
      return "State";
    default:
      return "Region";
  }
}
