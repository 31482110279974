import { loadBankCodes } from "store/actions/bankCodes";
import { useEffect, useState } from "react";
import { useShallowSelector } from "store/hooks";
import { BankProvider, CountryCode, CurrencyCode } from "@trolley/common-frontend";
import { useMerchantSettings } from "./merchantSettings";

const BANK_CODE_COUNTRIES = {
  [CountryCode.AR]: [CurrencyCode.ARS],
  [CountryCode.AM]: [CurrencyCode.AMD],
  [CountryCode.BO]: [CurrencyCode.BOB],
  [CountryCode.CO]: [CurrencyCode.COP],
  [CountryCode.CL]: [CurrencyCode.CLP],
  [CountryCode.EC]: [CurrencyCode.USD],
  [CountryCode.PE]: [CurrencyCode.PEN],
  [CountryCode.PY]: [CurrencyCode.PYG],
  [CountryCode.UY]: [CurrencyCode.UYU],
  [CountryCode.BJ]: [CurrencyCode.XOF],
  [CountryCode.BF]: [CurrencyCode.XOF],
  [CountryCode.SN]: [CurrencyCode.XOF],
  [CountryCode.GW]: [CurrencyCode.XOF],
  [CountryCode.ML]: [CurrencyCode.XOF],
  [CountryCode.NE]: [CurrencyCode.XOF],
  [CountryCode.TG]: [CurrencyCode.XOF],
  [CountryCode.CI]: [CurrencyCode.XOF],
};

export function useBankCodes(countryCode: CountryCode, currencyCode: CurrencyCode) {
  const { data: merchantSettings } = useMerchantSettings();
  const [fetchId, setFetchId] = useState("");

  useEffect(() => {
    if (countryCode && currencyCode && merchantSettings?.bankProvider === BankProvider.PRL && BANK_CODE_COUNTRIES[countryCode]?.includes(currencyCode)) {
      setFetchId(loadBankCodes({ countryCode, currencyCode }));
    }
  }, [countryCode, currencyCode]);

  return useShallowSelector((state) => {
    if (fetchId && state.bankCodes.fetchStatus[fetchId] === undefined) {
      loadBankCodes({ countryCode, currencyCode });
    }

    return {
      data: state.bankCodes.entities[fetchId] || [],
      status: state.bankCodes.fetchStatus[fetchId],
      error: state.bankCodes.errors[fetchId],
    };
  });
}
