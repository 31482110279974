/* eslint-disable react/no-array-index-key */
import { CountryCode, flatten } from "@trolley/common-frontend";
import { Button, Divider, Grid, Icon, InputLive, RecordCount, Space } from "components";
import React, { ReactNode, useState } from "react";
import { Query } from "utils/hooks";
import FilterBar from "./FilterBar";

interface BaseField {
  label: ReactNode;
  tooltip?: ReactNode;
}

export type TableFilterField = BaseField &
  (
    | {
        type: "select";
        filterKey: string;
        options: { label: string; value: string }[];
      }
    | {
        type: "select-countries";
        filterKey: string;
        topCountries?: CountryCode[];
      }
    | {
        type: "select-currencies";
        currencyType: "all" | "primary";
        filterKey: string;
      }
    | {
        type: "tags";
        filterKey: string;
        placeholder?: string;
        maxLength?: number; // max number of tags
        renderTag?: (tag: string) => ReactNode;
      }
    | {
        type: "checkbox";
        filterKey: string;
      }
    | {
        type: "radio";
        filterKey: string;
        options: { label: string; value: string | boolean | undefined }[];
      }
    | {
        type: "date-range";
        filterKey: [string, string];
        format?: string;
      }
    | {
        type: "input";
        inputMode: "text" | "numeric" | "decimal";
        filterKey: string;
      }
  );

interface Props<Q> {
  fields?: TableFilterField[];
  query: Q;
  alwaysShowFields?: boolean;
  showSearch?: boolean;
  addOnBefore?: ReactNode;
  addOnAfter?: ReactNode;
  suffix?: ReactNode;
  onChange?(values: Partial<Q>): void;
  children?: ReactNode;
}

export default function TableFilters<Q extends Query>(props: Props<Q>) {
  const { alwaysShowFields, showSearch, fields = [], query, addOnBefore, addOnAfter, suffix, children, onChange } = props;
  const [showFilterBar, setShowFilterBar] = useState(false);
  const fieldsTypes = fields.map((field) => (Array.isArray(field.filterKey) ? field.filterKey[0] : field.filterKey)); // values array is for date-range values.
  const activeFilters = Object.entries(query).reduce((acc, [key, value]) => {
    if (fieldsTypes.includes(key) && value !== undefined && value !== "") {
      acc += 1;
    }

    return acc;
  }, 0);

  function onClearFilters() {
    const emptyValues = Object.keys(query).reduce((acc, field) => {
      if (fields && flatten(fields.map((f) => f.filterKey)).includes(field)) {
        acc[field] = null;
      }

      return acc;
    }, {}) as Q;
    onChange?.(emptyValues);
  }

  async function onSaveSearch(value: string) {
    onChange?.({
      search: value || undefined,
    } as any);
  }

  return (
    <>
      <Grid padding="xsmall" align="bottom">
        {children ? (
          <Grid.Item>{children}</Grid.Item>
        ) : (
          <>
            {addOnBefore && <Grid.Item>{addOnBefore}</Grid.Item>}
            {showSearch && (
              <Grid.Item flex="100 1 150px" style={{ maxWidth: "350px" }}>
                <InputLive
                  placeholder="Search..."
                  enablePopup={false}
                  value={query.search as string}
                  onSave={onSaveSearch}
                  prefix={<Icon type="search" color="grey" />}
                  allowClear
                  onClear={() => {
                    onChange?.({ search: undefined } as any);
                  }}
                />
              </Grid.Item>
            )}
            {!alwaysShowFields && fields && fields.length > 0 && (
              <Grid.Item>
                <Space>
                  <Button
                    icon={activeFilters > 0 ? <Icon type="filter" theme="solid" /> : <Icon type="filter" />}
                    onClick={() => {
                      setShowFilterBar((state) => !state);
                    }}
                  >
                    <RecordCount value={activeFilters} equalZero="Filters" one="# Filter" other="# Filters" />
                  </Button>
                  {activeFilters > 0 && (
                    <Button type="text" onClick={onClearFilters}>
                      Clear Filters
                    </Button>
                  )}
                </Space>
              </Grid.Item>
            )}
            {addOnAfter && <Grid.Item>{addOnAfter}</Grid.Item>}
            {suffix && (
              <Grid.Item flex={1} align="right">
                <Space direction="row-reverse">{suffix}</Space>
              </Grid.Item>
            )}
          </>
        )}
      </Grid>
      <Divider transparent margin="small" />

      {!children && (alwaysShowFields || showFilterBar) && (
        <FilterBar
          fields={fields}
          query={query}
          onChange={(values) => {
            onChange?.(values);
          }}
        />
      )}
    </>
  );
}
