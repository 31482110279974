import { Box, Divider, Empty, Icon, Input, Modal, Spinner, Status, Text } from "components";
import React, { ReactNode, useEffect, useState } from "react";
import { notifyError, notifySuccess } from "store/actions/notifications";
import { SubMerchant, switchMerchant } from "store/actions/subMerchants";
import { useMerchantSettings } from "store/hooks/merchantSettings";
import { useSubMerchants } from "store/hooks/subMerchants";
import { escapeRegExp } from "utils/helpers";

interface Props {
  visible: boolean;
  title?: ReactNode;
  onClose(): void;
  merchantFilter?: (m: SubMerchant) => boolean;
  disabledMerchant?: (m: SubMerchant) => boolean;
}
export default function MerchantPicker(props: Props) {
  const { visible, title, onClose, merchantFilter, disabledMerchant } = props;
  const { data: submerchants } = useSubMerchants();
  const { data: merchantSettings } = useMerchantSettings();
  const [merchantSearch, setMerchantSearch] = useState("");
  const [switchingTo, setSwitchingTo] = useState<SubMerchant | undefined>();
  const filteredMerchantId = (merchantFilter ? submerchants.records.filter(merchantFilter) : submerchants.records).filter(
    (m) => m.id !== merchantSettings?.merchantId,
  );
  const displayedMerchants = filteredMerchantId.filter((m) => new RegExp(escapeRegExp(merchantSearch), "gi").test(m.name));

  useEffect(() => {
    if (visible) {
      setSwitchingTo(undefined);
      setMerchantSearch("");
    }
  }, [visible]);

  function onSwitchMerchant(id: string) {
    return async () => {
      try {
        await switchMerchant(id);
        notifySuccess("Account switched");
        onClose();
      } catch (errors) {
        notifyError("Account switch failed", { errors, duration: 10, closable: true });
      }
    };
  }

  return (
    <Modal
      visible={visible}
      title={null}
      footer={null}
      bodyStyle={{ maxHeight: "375px", overflowY: "auto", paddingBottom: "24px" }}
      onCancel={onClose}
      closable={!switchingTo}
      maskClosable={!switchingTo}
    >
      {visible && (
        <>
          {!switchingTo && title}
          <Text uppercase type="secondary" style={{ margin: "12px 0" }}>
            {switchingTo ? "Switching you to" : merchantSettings?.parentMerchantId ? "Switch Merchant Account" : "Switch to a Sub-Merchant Account"}
          </Text>

          {switchingTo ? (
            <Box
              padding="xsmall"
              flat
              color="blue"
              header={
                <>
                  {switchingTo.name}
                  <Spinner right />
                </>
              }
              extraActions={<Status type={switchingTo.status} />}
            />
          ) : (
            <>
              <Input
                prefix={<Icon type="search" />}
                value={merchantSearch}
                allowClear
                placeholder="Search Merchant Name"
                onChange={(event) => {
                  setMerchantSearch(String(event.target.value || ""));
                }}
              />
              <Divider margin="small" transparent />

              {displayedMerchants.length > 0 ? (
                displayedMerchants.map((m) => (
                  <Box
                    padding="xsmall"
                    flat
                    onClick={() => {
                      if (!switchingTo) {
                        setSwitchingTo(m);
                        window.setTimeout(onSwitchMerchant(m.id), 750);
                      }
                    }}
                    disabled={disabledMerchant?.(m)}
                    header={
                      <>
                        {m.name}
                        {!m.parentID && <Status type="primary" size="small" right />}
                        {m.sandbox && <Status type="sandbox" size="small" right />}
                      </>
                    }
                    extraActions={<Status type={m.status} />}
                    key={m.id}
                  />
                ))
              ) : (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={merchantSearch ? `No merchants matching "${merchantSearch}" were found` : "No merchants were found"}
                />
              )}
            </>
          )}
        </>
      )}
    </Modal>
  );
}
