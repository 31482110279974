/* istanbul ignore file */
import { storageFactory } from "utils/factories";

const TOKEN = "auth-token";

const storage = storageFactory(() => sessionStorage);

export default {
  clear() {
    storage.clear();
  },
  set(value?: string | undefined) {
    if (value === undefined) {
      storage.removeItem(TOKEN);
    } else {
      storage.setItem(TOKEN, value);
    }
  },
  get() {
    return storage.getItem(TOKEN);
  },
};
