import store from "store";
import { OpCode, standardDispatch } from "store/dispatcher";
import { SerializerMetaBlock, Fees } from "@trolley/common-frontend";
import * as request from "services/request";
import { isLoaded } from "./actionUtils";

export type FeeStructure = Fees.FeeStructure;

export function loadFees(force?: boolean) {
  const { fees } = store.getState();

  if (force || !isLoaded(fees.fetchStatus.data)) {
    standardDispatch(OpCode.LOADING, "fees");

    request
      .POST<{ fees: FeeStructure[]; meta: SerializerMetaBlock }>("/v1/fees/search")
      .then((res) => {
        standardDispatch(OpCode.DATA, "fees", {
          bulk: res.body.fees.reduce((acc, fee) => {
            acc[fee.currencyCode] = fee;

            return acc;
          }, {}),
        });
      })
      .catch((errors) => {
        standardDispatch(OpCode.ERROR, "fees", {
          errors,
        });
      });
  }
}

// Internal method, please version with clean arguments
async function updateFees(currency: string, integration: string, type: string, amount: string) {
  standardDispatch(OpCode.LOADING, "fees", { id: currency });
  try {
    const { body } = await request.POST<{ fee: FeeStructure }>("/v1/fees/update", {
      query: {
        amount,
        currency,
        integration,
        type,
      },
    });

    standardDispatch(OpCode.DATA, "fees", {
      data: body.fee,
      id: currency,
    });
  } catch (errors) {
    standardDispatch(OpCode.ERROR, "fees", {
      errors,
      id: currency,
    });
    throw errors;
  }
}

export async function updateMerchantCoverAmount(
  currency: string,
  amount: string,
  payoutType: "bankTransfer" | "gateway",
  routingType: keyof FeeStructure["bankTransfer"] | keyof FeeStructure["gateway"],
) {
  return await updateFees(currency, payoutType, routingType, amount);
}
