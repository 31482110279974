import { Container, DateDisplay, Heading, RecordCount, Table, TableColumnsType, Typography, Status, Text } from "components";
import React, { useState } from "react";
import Helmet from "react-helmet";
import { BusinessVerification } from "store/actions/verifications";
import BusinessVerificationPreview from "./BusinessVerificationPreview";
import { VerificationRejectedReasons, VerificationStatus } from "@trolley/common-frontend";
import { RejectedReasonLabels, renderCountry } from ".";

export const BusinessRejectedReasons: { [key in VerificationRejectedReasons]: string } = {
  [VerificationRejectedReasons.FRAUDULENT]: "The document was flagged for signs of tampering.",
  [VerificationRejectedReasons.INVALID]: "The document provided was not from an acceptable source.",
  [VerificationRejectedReasons.MISSING_REQUIREMENTS]: "Certain details were unable to be verified.",
  [VerificationRejectedReasons.UNPROCESSABLE]:
    "The verification was not able to be processed due to an unaccepted format, not high enough quality, or lack of a processable document.",
  [VerificationRejectedReasons.OTHER]: "",
};

interface Props {
  verifications: BusinessVerification[];
}

enum ColumnKeys {
  NAME = "name",
  STATUS = "status",
  REGISTRATION_NUMBER = "RegistrationNumber",
  ID = "id",
  COUNTRY = "Country",
  SUBMITTED_AT = "submittedAt",
  DECISION_ON = "decisionOn",
}

export default function BusinessVerification(props: Props) {
  const { verifications } = props;
  const [previewBusinessVerification, setPreviewBusinessVerification] = useState<BusinessVerification | undefined>();

  const columns: TableColumnsType<BusinessVerification> = [
    {
      key: ColumnKeys.NAME,
      title: "Name",
      render: ({ verifiedData }: BusinessVerification) => <Typography.Text>{verifiedData.name || "-"}</Typography.Text>,
    },
    {
      key: ColumnKeys.STATUS,
      title: "Status",
      render: ({ status, verifiedData, reasonType }: BusinessVerification) =>
        status ? (
          <Status
            type={status}
            size="small"
            tooltipProps={
              status === VerificationStatus.REJECTED
                ? reasonType && BusinessRejectedReasons[reasonType]
                  ? {
                      title: `${RejectedReasonLabels[reasonType]} - ${BusinessRejectedReasons[reasonType]}`,
                    }
                  : verifiedData.reason
                  ? {
                      title: (
                        <>
                          <Text weight="bold">Rejected Reasons:</Text>
                          <ul>
                            {verifiedData.reason.map((reason) => (
                              <li key={reason.name}>{reason.name}</li>
                            ))}
                          </ul>
                        </>
                      ),
                    }
                  : undefined
                : undefined
            }
            right
          />
        ) : (
          "-"
        ),
    },
    {
      key: ColumnKeys.ID,
      title: "Verification ID",
      render: ({ id }: BusinessVerification) => <Typography.Text>{id || "-"}</Typography.Text>,
    },
    {
      key: ColumnKeys.REGISTRATION_NUMBER,
      title: "Registration Number",
      render: ({ verifiedData }: BusinessVerification) => <Typography.Text>{verifiedData.businessRegistrationNumber || "-"}</Typography.Text>,
    },
    {
      key: ColumnKeys.COUNTRY,
      title: "Country",
      render: ({ verifiedData }: BusinessVerification) => renderCountry(verifiedData.address.country?.toUpperCase()),
    },
    {
      key: ColumnKeys.SUBMITTED_AT,
      title: "Submitted At",
      render: ({ submittedAt }: BusinessVerification) => (submittedAt ? <DateDisplay value={submittedAt} /> : "-"),
    },
    {
      key: ColumnKeys.DECISION_ON,
      title: "Decision On",
      render: ({ decisionAt }: BusinessVerification) => (decisionAt ? <DateDisplay value={decisionAt} /> : "-"),
    },
  ];

  return (
    <Container>
      <Helmet>
        <title>Business Verification</title>
      </Helmet>
      <Heading tag="h2">
        <RecordCount prefix="Showing" value={verifications.length} one="1 business verification" other="# business verifications" />
      </Heading>

      <Table<BusinessVerification>
        columns={columns}
        dataSource={verifications}
        emptyProps={{
          description: "No business verifications submitted",
        }}
        onRow={(record) => ({
          onClick: () => {
            setPreviewBusinessVerification(record);
          },
        })}
      />

      <BusinessVerificationPreview
        BusinessVerification={previewBusinessVerification}
        onClose={() => {
          setPreviewBusinessVerification(undefined);
        }}
      />
    </Container>
  );
}
