import { PayoutMethodStatus } from "@trolley/common-frontend";
import { Card, Container, Grid, GridItemProps, Heading, LogoLoader } from "components";
import { ActivateAlert } from "features/onboarding";
import { PayoutMethodDisplay } from "features/payoutMethod";
import { BANK_ONBOARDING_PATH } from "pages/BankTransferOnboarding";
import React from "react";
import { Helmet } from "react-helmet";
import { PayoutMethodType } from "store/actions/payoutMethods";
import { useMerchantSettings } from "store/hooks/merchantSettings";
import { useOnboarding } from "store/hooks/onboarding";
import { usePayoutMethods } from "store/hooks/payoutMethods";
import styled from "style/classname";
import { MEDIA_BREAKPOINTS } from "style/variables";

export default function PayoutMethods() {
  const { features } = useMerchantSettings();
  const { data: onboarding, bankingEligibility } = useOnboarding();
  const { data: payoutMethods, status: payoutMethodsStatus } = usePayoutMethods();
  const bankTransfer = payoutMethods.find((payout) => payout.integration === PayoutMethodType.BANKTRANSFER);
  const isBankTransferApproved = bankTransfer?.status === PayoutMethodStatus.APPROVED || bankTransfer?.enabled;
  const paypal = payoutMethods.find((payout) => payout.integration === PayoutMethodType.PAYPAL);
  const interac = payoutMethods.find((payout) => payout.integration === PayoutMethodType.INTERAC);
  const check = payoutMethods.find((payout) => payout.integration === PayoutMethodType.CHECK);
  const bitcoin = payoutMethods.find((payout) => payout.integration === PayoutMethodType.BITCOIN);
  const venmo = payoutMethods.find((payout) => payout.integration === PayoutMethodType.VENMO);

  return (
    <Container>
      <Helmet>
        <title>Payout Methods</title>
      </Helmet>
      <ActivateAlert />
      <Heading>Enable your preferred payout methods</Heading>

      <LogoLoader spinning={payoutMethodsStatus === "loading"}>
        <Grid>
          {paypal && (
            <Grid.Item {...gridProps}>
              <Card
                url="/settings/payout-methods/paypal"
                enabled={paypal.enabled}
                title="PayPal"
                icon={<PayoutMethodDisplay size="large" value={PayoutMethodType.PAYPAL} color={paypal.enabled ? "green" : "grey"} />}
              />
            </Grid.Item>
          )}

          {features.payoutCheck && check && (
            <Grid.Item {...gridProps}>
              <Card
                url="/settings/payout-methods/check"
                enabled={check.enabled}
                title="Check"
                icon={<PayoutMethodDisplay size="large" value={PayoutMethodType.CHECK} color={check.enabled ? "green" : "grey"} />}
              />
            </Grid.Item>
          )}

          {bankTransfer && (
            <Grid.Item {...gridProps}>
              <Card
                url={isBankTransferApproved ? "/settings/payout-methods/bank-transfer" : bankingEligibility ? BANK_ONBOARDING_PATH : undefined}
                enabled={bankTransfer.enabled}
                title="Bank Transfer"
                icon={<PayoutMethodDisplay size="large" value={PayoutMethodType.BANKTRANSFER} color={bankTransfer.enabled ? "green" : "grey"} />}
                tooltipProps={
                  !isBankTransferApproved && !bankingEligibility
                    ? { title: "You are not eligible to enable Bank Transfer due to your estimated Annual Payout Volume.", placement: "topRight" }
                    : undefined
                }
                badgeProps={
                  isBankTransferApproved
                    ? undefined
                    : bankingEligibility
                    ? {
                        text: onboarding?.bankOnboardingCompletedAt ? "Pending Review" : "Activate Bank Transfer",
                      }
                    : {
                        text: "Not Eligible",
                        color: "grey",
                      }
                }
              />
            </Grid.Item>
          )}

          {features.payoutInterac && interac && (
            <Grid.Item {...gridProps}>
              <Card
                url="/settings/payout-methods/interac"
                enabled={interac.enabled}
                title="Interac"
                icon={<PayoutMethodDisplay size="large" value={PayoutMethodType.INTERAC} color={interac.enabled ? "green" : "grey"} />}
              />
            </Grid.Item>
          )}

          {features.payoutBitcoin && bitcoin && (
            <Grid.Item {...gridProps}>
              <Card
                url="/settings/payout-methods/bitcoin"
                enabled={bitcoin.enabled}
                title="Bitcoin"
                icon={<PayoutMethodDisplay size="large" value={PayoutMethodType.BITCOIN} color={bitcoin.enabled ? "green" : "grey"} />}
              />
            </Grid.Item>
          )}

          {features.payoutVenmo && venmo && (
            <Grid.Item {...gridProps}>
              <Card
                url="/settings/payout-methods/venmo"
                enabled={venmo.enabled}
                title="Venmo"
                icon={<PayoutMethodDisplay size="large" value={PayoutMethodType.VENMO} color={venmo.enabled ? "green" : "grey"} />}
              />
            </Grid.Item>
          )}

          <Grid.Item {...gridProps}>
            <Card.Empty
              message={
                <>
                  More payout methods <br />
                  coming soon
                </>
              }
            />
          </Grid.Item>
        </Grid>
      </LogoLoader>
    </Container>
  );
}

const styledGridItem = styled`
  &.grid-item {
    flex: 0 0 50%;
    ${MEDIA_BREAKPOINTS.smUp} {
      flex: 0 0 200px;
    }
  }
`();

export const gridProps: GridItemProps = {
  align: "center",
  className: styledGridItem,
};
