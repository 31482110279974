import { MerchantStatus } from "@trolley/common-frontend";
import { useEffect } from "react";
import { Features, loadMerchantSettings, MerchantSettings } from "store/actions/merchantSettings";
import { useShallowSelector } from ".";

export { MerchantSettings, Features };

export function useMerchantSettings() {
  useEffect(() => {
    loadMerchantSettings();
  }, []);

  return useShallowSelector((state) => {
    if (state.merchantSettings.fetchStatus.data === undefined) {
      loadMerchantSettings();
    }

    const data = state.merchantSettings.entities.data;

    return {
      isActive: isMerchantApproved(data),
      features: (state.features.entities.data || {}) as Partial<Features>,
      data,
      status: state.merchantSettings.fetchStatus.data,
      error: state.merchantSettings.errors.data,
    };
  });
}

/** Get merhcant approval Status. Typically they becomes auto-approved after completing the business profile onboarding.
 * Exception: sandbox, and light onboarding merchant are auto-approved.
 */
export function isMerchantApproved(data: MerchantSettings | undefined | null): boolean {
  return data ? [MerchantStatus.APPROVED, MerchantStatus.APPROVED_BY_PARTNER, MerchantStatus.INTERNAL].includes(data.status) : false;
}
