/* istanbul ignore file */
import { endSession } from "store/actions/session";
import { BaseError } from "store/reducers/standardReducer";

/** Error 401 */
export default async function handleUnauthorizedAccess(error: any) {
  if (Array.isArray(error?.body?.errors)) {
    if (error.body.errors.some((error: BaseError) => error.code === "invalid_ip_error")) {
      throw error.body.errors;
    } else {
      endSession("expire");
    }
  }
}
