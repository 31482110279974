import { usePrevious } from "@shopify/react-hooks";
import { Button, Divider, Form, Icon, Space } from "components";
import React, { ReactNode, useEffect, useState } from "react";
import { BaseStatus } from "store/reducers/standardReducer";

interface Props {
  status: BaseStatus | undefined;
  disabled?: boolean;
  saveText?: string;
  extraActions?: ReactNode;
  allowReset?: boolean;
  resetText?: string;
  onReset?(): void;
  addOnAfter?: ReactNode;
}

export default function SaveCancel(props: Props) {
  const { status, disabled, saveText = "Save", extraActions, allowReset = true, resetText = "Cancel", onReset, addOnAfter } = props;
  const form = Form.useFormInstance();
  const previousStatus = usePrevious(status);
  const [saved, setSaved] = useState(false);

  useEffect(() => {
    if (previousStatus === BaseStatus.LOADING && status === BaseStatus.LOADED && form.isFieldsTouched()) {
      setSaved(true);
      form.resetFields();
    }
  }, [previousStatus, status]);

  return (
    <Form.Control shouldUpdate>
      {(form) => {
        const isTouched = form.isFieldsTouched();

        return (
          <>
            <Divider margin="small" transparent />
            <Space direction="row-reverse">
              <Button
                icon={saved ? <Icon type="check-circle" color={isTouched ? "white" : "green"} theme="solid" /> : undefined}
                type={isTouched ? "primary" : "default"}
                disabled={disabled}
                loading={status === BaseStatus.LOADING}
                htmlType="submit"
              >
                {saved && !isTouched ? "Saved" : saveText}
              </Button>
              {extraActions}
              {allowReset && (onReset || isTouched) && (
                <Button
                  onClick={() => {
                    form.resetFields();
                    onReset?.();
                  }}
                >
                  {resetText}
                </Button>
              )}
              {addOnAfter}
            </Space>
          </>
        );
      }}
    </Form.Control>
  );
}
