import { AddressLookupData, CountryCode } from "@trolley/common-frontend";
import { POST } from "services/request";

type Address = {
  country: CountryCode;
  name: string;
  street1: string;
  street2: string;
  city: string;
  region: string;
  postal: string;
};

export default async function addressLookup(query: Address) {
  const { body } = await POST<AddressLookupData>("/v1/address/lookup", { query });

  return body.addressData;
}
