import { Tag as AntTag, TagProps } from "antd";
import { Icon, Input, Spinner } from "components";
import React, { ChangeEvent, KeyboardEvent, useState } from "react";
import { notifyError } from "store/actions/notifications";
import css, { classnames, createUseStyle } from "style/classname";

const useStyledNewTag = createUseStyle<NewTagProps & { newTag?: string }>(({ theme, newTag }) =>
  css`
    &.${theme.prefixCls}-tag {
      cursor: pointer;
      background-color: ${theme.colorBgBase};
      border-radius: 10px;
      height: 20px;
      line-height: 18px;
      ${newTag === undefined
        ? `
          &:hover {
            background-color: ${theme.colorWhite};
            box-shadow: ${theme.boxShadowTertiary};
          }
        `
        : `
          box-shadow: ${theme.boxShadowInset};
      `}
      .${theme.prefixCls}-input, &.${theme.prefixCls}-input {
        border: 0;
        background: transparent;
        height: 16px;
        line-height: 16px;
        vertical-align: middle;
        width: 100px;
        box-shadow: none;
      }
    }
  `(),
);

type NewTagProps = Omit<TagProps, "onClick" | "color"> & {
  placeholder?: string;
  onAddNew(tag: string): Promise<void>;
};

export default function New(props: NewTagProps) {
  const { placeholder = "Add tag", className, onAddNew, ...rest } = props;
  const [busy, setBusy] = useState<boolean>(false);
  const [newTag, setNewTag] = useState<undefined | string>();
  const styledNewTag = useStyledNewTag({ ...props, newTag });

  function onToggle(show: boolean) {
    return () => {
      setNewTag(show ? "" : undefined);
    };
  }

  function onKeyDown(e: KeyboardEvent<HTMLInputElement>) {
    if (e?.key === "Escape") {
      e?.stopPropagation?.();
      e?.preventDefault?.();
      setNewTag(undefined);
    }
  }

  function onChangeTag(e: ChangeEvent<HTMLInputElement>) {
    if (e.target.value.length <= 255) {
      setNewTag(String(e.target.value || "").replace(",", "")); // dont' allow commas. and trim before submitting
    }
  }

  function onSubmitTag() {
    if (!busy) {
      const tag = typeof newTag === "string" && newTag.trim();
      if (!!tag) {
        setBusy(true);
        props
          .onAddNew(tag)
          .then(() => {
            setBusy(false);
            setNewTag(undefined);
          })
          .catch((errors) => {
            setBusy(false);
            notifyError("Update Failed", { errors });
          });
      } else {
        setNewTag(undefined);
      }
    }
  }

  return (
    <AntTag {...rest} onClick={onToggle(true)} className={classnames(styledNewTag, className)}>
      {typeof newTag === "string" ? (
        <>
          <Input
            readOnly={!!busy}
            autoFocus
            size="small"
            value={newTag}
            onChange={onChangeTag}
            onKeyDown={onKeyDown}
            onBlur={onSubmitTag}
            onPressEnter={onSubmitTag}
          />
          <Icon type="check" color="grey" size="small" />
        </>
      ) : (
        <>
          <Icon type="plus" color="black" size="small" />
          {placeholder}
        </>
      )}
      {busy && <Spinner />}
    </AntTag>
  );
}
