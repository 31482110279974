import { formatCountry, UsTeritories, CountryCode, PayoutMethodType } from "@trolley/common-frontend";
import { Alert, Button, Checkbox, Divider, Form, Grid, Icon, Input, LogoLoader, SelectCountry, SelectRegion, Text } from "components";
import { FormInstance } from "components/Form";
import React, { forwardRef, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { CheckAccountUpdate, RecipientAccount } from "store/actions/recipientAccount";
import { StoreRecipient } from "store/actions/recipients";
import { usePayoutMethods } from "store/hooks/payoutMethods";
import { addressLookup, handleFormErrors } from "utils/helpers";
import validator from "validator";
import { isRegionInCountryAsyncValidator } from "../index";

type Props = {
  recipientAccount?: RecipientAccount;
  recipientCountry: StoreRecipient["address"]["country"];
  onSubmit(changes: CheckAccountUpdate): Promise<void>;
};

interface FormFields {
  country: CountryCode;
  name: string;
  street1: string;
  street2: string;
  city: string;
  region: string;
  postal: string;
  primary?: boolean;

  valid?: "verifying" | "deliverable" | "undeliverable";
}

export default forwardRef<FormInstance, Props>(({ recipientAccount, recipientCountry, onSubmit }, ref) => {
  const [form] = Form.useForm<FormFields>();
  const { data: payoutMethods } = usePayoutMethods();
  const checkPayout = payoutMethods.find((pm) => pm.integration === PayoutMethodType.CHECK);
  const [valid, setValid] = useState<string>("");
  useEffect(() => {
    if (typeof ref === "function") {
      ref(form);
    }
  }, [form]);

  async function onFinish({ valid, primary, ...mailing }: FormFields) {
    try {
      setValid("verifying");
      const addressData = await addressLookup(mailing);
      const deliverable = addressData.deliverability === "deliverable";
      setValid(deliverable ? "deliverable" : "undeliverable");

      if (deliverable) {
        await onSubmit({ mailing, primary, type: PayoutMethodType.CHECK });
      }
    } catch (errors) {
      setValid("undeliverable");
      handleFormErrors(errors, form);
    }
  }

  return (
    <Form form={form} onFinish={onFinish} initialValues={recipientAccount?.mailing} validateTrigger="onSubmit">
      {!checkPayout?.enabled && (
        <Alert type="warning">
          Check is disabled. Go to <Link to="/settings/payout-methods/check">Check Settings</Link> to enable it.
        </Alert>
      )}
      <Form.Item
        name="name"
        label="Name on Check (Pay to the order of)"
        extra="This will be the payee name printed on the check, please ensure it matches the recipient’s legal name or the account holder name on their bank account"
        rules={[
          { required: true, message: "Enter the name that will appear on the check" },
          // this pattern allows only spaces between alphanumeric characters
          { pattern: /^[a-zA-Z0-9]+([\s]{1}[a-zA-Z0-9]+)*$/i, message: "Please enter only alphanumeric characters with no special characters" },
        ]}
      >
        <Input name="name" maxLength={40} />
      </Form.Item>
      <Form.Item
        label="Mailing Country"
        name="country"
        initialValue={recipientCountry}
        extra="Check payments can only be sent to addresses within the United States, Puerto Rico, Guam, American Samoa, Northern Mariana Islands or the US Virgin Islands."
        rules={[{ required: true, message: "Country is required" }]} // as a safeguard to make sure a country is present
      >
        <SelectCountry type="address" includes={UsTeritories} />
      </Form.Item>
      <Grid padding={["small", "none"]} style={{ marginBottom: "16px" }}>
        <Grid.Item xs={24} md={12}>
          <Form.Item
            label="Street 1"
            name="street1"
            dependencies={["street2"]}
            rules={[
              { required: true, message: "Enter an address" },
              (form) => ({
                async validator(rule: any, value: string) {
                  const combinedStreetAddress = [value ?? "", form.getFieldValue("street2") ?? ""].join("");
                  if (combinedStreetAddress.length > 50) {
                    throw "The combined length of Address 1 and Address 2 cannot exceed 50 characters";
                  }
                },
              }),
            ]}
          >
            <Input name="street1" />
          </Form.Item>
        </Grid.Item>

        <Grid.Item xs={24} md={12}>
          <Form.Item label="Street 2" name="street2">
            <Input name="street2" maxLength={40} />
          </Form.Item>
        </Grid.Item>
        <Grid.Item xs={24} md={12}>
          <Form.Item label="City" name="city" rules={[{ required: true, message: "Enter a city" }]}>
            <Input name="city" maxLength={50} />
          </Form.Item>
        </Grid.Item>
        <Grid.Item xs={24} md={7}>
          <Form.Control dependencies={["country"]}>
            {(form) => {
              const country = form.getFieldValue("country");

              return (
                <Form.Item
                  label="State"
                  name="region"
                  dependencies={["country"]}
                  rules={[{ required: true, message: "Select a state" }, isRegionInCountryAsyncValidator("country")]}
                >
                  <SelectRegion country={country} />
                </Form.Item>
              );
            }}
          </Form.Control>
        </Grid.Item>
        <Grid.Item xs={24} md={5}>
          <Form.Item
            label="ZIP Code"
            name="postal"
            dependencies={["country"]}
            rules={[
              { required: true, message: "Enter a ZIP code" },
              (form) => ({
                async validator(rule, value) {
                  const country = form.getFieldValue("country");
                  if (value && country && !validator.isPostalCode(value, CountryCode.US)) {
                    throw "Enter a valid Zip Code. Eg. 55555 or 55555-4444";
                  }
                },
              }),
            ]}
          >
            <Input name="postal" />
          </Form.Item>
        </Grid.Item>
      </Grid>

      <Form.Control shouldUpdate>
        {({ getFieldsValue }) => {
          const mailing = getFieldsValue();

          return (
            <LogoLoader spinning={valid === "verifying"}>
              {valid === "undeliverable" ? (
                <Alert type="error" size="large" icon={<Icon type="map-marker-exclamation" color="red" theme="solid" />} header="Invalid Address">
                  This address is not deliverable, according to USPS. Please verify you provided the correct mailing address.
                </Alert>
              ) : (
                <Alert
                  type={valid === "deliverable" ? "success" : "info"}
                  size="large"
                  icon={valid === "deliverable" ? undefined : <Icon type="map-marker-alt" color="blue" theme="solid" />}
                  header="Address verification"
                >
                  The address will be verified with USPS. Checks will be delivered to:
                  <Text weight="bold">
                    <div>{mailing.name || "[Name on Check]"}</div>
                    {mailing.street1 && <div>{mailing.street1 || "[Street 1]"}</div>}
                    {mailing.street2 && <div>{mailing.street2}</div>}
                    <div>
                      {[mailing.city || "[City]", mailing.region || "[State]"].filter((v) => v).join(", ")} {mailing.postal || "[Zip Code]"}
                    </div>
                    {formatCountry(mailing.country)}
                  </Text>
                </Alert>
              )}
            </LogoLoader>
          );
        }}
      </Form.Control>

      {!recipientAccount && (
        <>
          <Divider margin="small" transparent />
          <Form.Item name="primary" valuePropName="checked" noStyle>
            <Checkbox>Set Account as Active</Checkbox>
          </Form.Item>
        </>
      )}
      <Button hidden htmlType="submit" />
    </Form>
  );
});
