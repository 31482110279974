import { PaymentTaxStatus } from "@trolley/common-frontend";
import { BigNumber } from "bignumber.js";
import { Alert, CalculationRow, CurrencyDisplay, Divider, TableSum } from "components";
import React, { Fragment } from "react";
import { Payment } from "store/actions/payments";
import { useMerchantSettings } from "store/hooks/merchantSettings";

interface Props {
  types: ("debitAmount" | "targetAmount")[];
  payment?: Payment;
}

export default function PaymentCalculations(props: Props) {
  const { types, payment } = props;
  const { features, data: merchantSettings } = useMerchantSettings();

  if (!payment) {
    return null;
  }

  const showTaxLine = (features.tax && merchantSettings?.tax?.enabled) || new BigNumber(payment.withholdingAmountOnCreation).gt(0);
  const debitAmount = new BigNumber(payment.sourceAmount).plus(payment.merchantFees);
  const paymentAmount = new BigNumber(payment.sourceAmount).plus(payment.withholdingAmountOnCreation);
  const subTotal = new BigNumber(payment.sourceAmount).minus(payment.recipientFees);
  const witholdingPercentage = paymentAmount.eq(0)
    ? "0"
    : new BigNumber(payment.withholdingAmountOnCreation).multipliedBy(100).dividedBy(paymentAmount).toFixed(1);
  const withholdingDetails = payment.taxes.length < 2 ? `(${witholdingPercentage}%)` : "";
  const calculations: CalculationRow[][] = [];

  if (types.includes("debitAmount")) {
    calculations.push(
      [
        {
          label: "Payment Amount",
          value: <CurrencyDisplay value={paymentAmount.valueOf()} currency={payment.sourceCurrency} />,
        },
        {
          symbol: "+",
          label: "Merchant Fees",
          value: <CurrencyDisplay value={payment.merchantFees} currency={payment.sourceCurrency} />,
        },
        showTaxLine && {
          symbol: "-",
          label: `Tax Withholding ${withholdingDetails}`,
          value: <CurrencyDisplay value={payment.withholdingAmountOnCreation} currency={payment.withholdingCurrency} />,
        },
        {
          label: "Debit Amount",
          value: <CurrencyDisplay value={debitAmount.valueOf()} currency={payment.sourceCurrency} />,
          isSubtotal: true,
        },
      ].filter((v) => !!v) as CalculationRow[],
    );
  }

  if (types.includes("targetAmount")) {
    calculations.push(
      [
        {
          label: "Payment Amount",
          value: <CurrencyDisplay value={paymentAmount.valueOf()} currency={payment.sourceCurrency} />,
        },
        {
          symbol: "-",
          label: "Recipient Fees",
          value: <CurrencyDisplay value={payment.recipientFees} currency={payment.sourceCurrency} />,
        },
        showTaxLine && {
          symbol: "-",
          label: `Tax Withholding ${withholdingDetails}`,
          value: <CurrencyDisplay value={payment.withholdingAmountOnCreation} currency={payment.withholdingCurrency} />,
        },
        !new BigNumber(payment.exchangeRate).isEqualTo(1) && {
          label: "Subtotal",
          value: <CurrencyDisplay value={subTotal.valueOf()} currency={payment.sourceCurrency} />,
          isSubtotal: true,
        },
        !new BigNumber(payment.exchangeRate).isEqualTo(1) && {
          symbol: "x",
          label: "Exchange Rate",
          value: payment.exchangeRate,
        },
        {
          label: "Recipient Receives",
          value: <CurrencyDisplay value={payment.targetAmount} currency={payment.targetCurrency} />,
          isSubtotal: true,
        },
      ].filter((v) => !!v) as CalculationRow[],
    );
  }

  if (calculations.length > 0) {
    return (
      <>
        {calculations.map((c, index) => (
          <Fragment key={c[1]?.label || index}>
            {index > 0 && <Divider margin="small" transparent />}
            <TableSum dataSource={c} />
          </Fragment>
        ))}

        {payment.taxes?.some((pt) => pt.status === PaymentTaxStatus.PROCESSED && pt.paymentId && pt.modifiedSinceCreation) && (
          <Alert type="warning" style={{ marginTop: "16px", marginBottom: "0" }}>
            Some details above may be inaccurate since payment tax records have been modifed after the payment has been processed.
          </Alert>
        )}
      </>
    );
  }

  return null;
}
