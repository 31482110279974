import { useEffect, useState } from "react";
import { computeID } from "store/actions/actionUtils";
import { Invoice, InvoicesQuery, loadInvoice, loadInvoices } from "store/actions/invoices";
import { useShallowSelector } from "store/hooks";
import { emptyList } from "store/reducers/standardReducer";

export function useInvoice(invoiceId: undefined | string) {
  const id = invoiceId && /^I-\w+$/.test(invoiceId) ? invoiceId : "";

  useEffect(() => {
    if (id) {
      loadInvoice(id);
    }
  }, [id]);

  return useShallowSelector((state) => {
    if (id && state.invoices.fetchStatus[id] === undefined) {
      loadInvoice(id);
    }

    return {
      data: state.invoices.entities[id],
      status: state.invoices.fetchStatus[id],
      error: state.invoices.errors[id],
    };
  });
}

export function useInvoices(query: InvoicesQuery) {
  const [fetchId, setFetchId] = useState("");

  useEffect(() => {
    if (query) {
      setFetchId(loadInvoices(query));
    } else {
      setFetchId("");
    }
  }, [computeID(query)]);

  return useShallowSelector((state) => {
    if (fetchId && state.invoiceList.fetchStatus[fetchId] === undefined) {
      loadInvoices(query);
    }

    const invoiceList = state.invoiceList.entities[fetchId];

    return {
      data: invoiceList
        ? {
            records: invoiceList.records.map((id) => state.invoices.entities[id] as Invoice).filter((v) => v),
            meta: invoiceList.meta,
          }
        : emptyList,
      status: state.invoiceList.fetchStatus[fetchId],
      error: state.invoiceList.errors[fetchId],
    };
  });
}
