import { Typography } from "components";
import React from "react";
import { FormattedMessage } from "react-intl";

type Props = {
  prefix?: string;
  value: number;
  equalZero?: string;
  one?: string;
  other: string;
};

export default function RecordCount({ prefix, value, equalZero, one, other }: Props) {
  const nonZeroVal = Math.max(0, value);
  const filter = [
    ...(prefix ? [`<sub>${prefix}</sub>`] : []),
    `{count, plural,
      ${equalZero ? `=0 {${equalZero}}` : ""}
      ${one ? `one {${one}}` : ""}
      ${other ? `other {${other}}` : ""}
    }`,
  ].join(" ");

  return (
    <FormattedMessage
      id={other}
      defaultMessage={filter}
      values={{
        count: nonZeroVal,
        b: (chunks: string) => <b>{chunks}</b>,
        sub: (chunks: string) => (
          <Typography.Text inline type="secondary">
            {chunks}
          </Typography.Text>
        ),
      }}
    />
  );
}
