import { Container, DateDisplay, Flag, Heading, RecordCount, Table, TableColumnsType, Typography, Status } from "components";
import React, { useState } from "react";
import Helmet from "react-helmet";
import { IndividualVerification } from "store/actions/verifications";
import IdVerificationPreview from "./IdVerificationPreview";
import { VerificationRejectedReasons, VerificationStatus } from "@trolley/common-frontend";
import { RejectedReasonLabels } from ".";

export const IndividualRejectedReasons: { [key in VerificationRejectedReasons]: string } = {
  [VerificationRejectedReasons.FRAUDULENT]:
    "A screen was used to display the document / portrait, multiple people were involved, or other non-accepted practices.",
  [VerificationRejectedReasons.INVALID]: "The document provided was unable to be accepted.",
  [VerificationRejectedReasons.MISSING_REQUIREMENTS]: "Unable to collect required details.",
  [VerificationRejectedReasons.UNPROCESSABLE]:
    "The verification was not able to be processed due to an unaccepted format, not high enough quality, or lack of a processable document.",
  [VerificationRejectedReasons.OTHER]: "",
};

interface Props {
  verifications: IndividualVerification[];
}

enum ColumnKeys {
  NAME = "name",
  STATUS = "status",
  ID = "id",
  DOCUMENT_ISSUING_COUNTRY = "documentIssuingCountry",
  SUBMITTED_AT = "submittedAt",
  DECISION_ON = "decisionOn",
}

export default function IdVerification(props: Props) {
  const { verifications } = props;
  const [previewIdVerification, setPreviewIdVerification] = useState<IndividualVerification | undefined>();

  const columns: TableColumnsType<IndividualVerification> = [
    {
      key: ColumnKeys.NAME,
      title: "Name",
      render: ({ verifiedData }: IndividualVerification) => (
        <Typography.Text>{verifiedData.firstName || verifiedData.lastName ? [verifiedData.firstName, verifiedData.lastName].join(" ") : "-"}</Typography.Text>
      ),
    },
    {
      key: ColumnKeys.STATUS,
      title: "Status",
      render: ({ status, verifiedData, reasonType }: IndividualVerification) =>
        status ? (
          <Status
            type={status}
            size="small"
            tooltipProps={
              status === VerificationStatus.REJECTED && ((reasonType && IndividualRejectedReasons[reasonType]) || verifiedData.reason)
                ? {
                    title: `${
                      reasonType && IndividualRejectedReasons[reasonType]
                        ? `${RejectedReasonLabels[reasonType]} - ${IndividualRejectedReasons[reasonType]}`
                        : verifiedData.reason ?? reasonType
                    }`,
                  }
                : undefined
            }
            right
          />
        ) : (
          "-"
        ),
    },
    {
      key: ColumnKeys.ID,
      title: "Verification ID",
      render: ({ id }: IndividualVerification) => <Typography.Text>{id || "-"}</Typography.Text>,
    },
    {
      key: ColumnKeys.DOCUMENT_ISSUING_COUNTRY,
      title: "Country",
      render: ({ verifiedData }: IndividualVerification) => (verifiedData.documentIssuingCountry ? <Flag code={verifiedData.documentIssuingCountry} /> : "-"),
    },
    {
      key: ColumnKeys.SUBMITTED_AT,
      title: "Submitted At",
      render: ({ submittedAt }: IndividualVerification) => (submittedAt ? <DateDisplay value={submittedAt} /> : "-"),
    },
    {
      key: ColumnKeys.DECISION_ON,
      title: "Decision On",
      render: ({ decisionAt }: IndividualVerification) => (decisionAt ? <DateDisplay value={decisionAt} /> : "-"),
    },
  ];

  return (
    <Container>
      <Helmet>
        <title>Individual Verification</title>
      </Helmet>
      <Heading tag="h2">
        <RecordCount prefix="Showing" value={verifications.length} one="1 individual verification" other="# individual verifications" />
      </Heading>

      <Table<IndividualVerification>
        columns={columns}
        dataSource={verifications}
        emptyProps={{
          description: "No individual verifications submitted",
        }}
        onRow={(record) => ({
          onClick: () => {
            setPreviewIdVerification(record);
          },
        })}
      />

      <IdVerificationPreview
        IdVerification={previewIdVerification}
        onClose={() => {
          setPreviewIdVerification(undefined);
        }}
      />
    </Container>
  );
}
