import React, { ReactNode } from "react";
import { usePayment } from "store/hooks/payments";

interface Props {
  id: string | undefined;
  children: (data: ReturnType<typeof usePayment>) => ReactNode;
}
export default function PaymentLoader({ id, children }: Props) {
  const paymentId = id && /^P-\w+$/.test(id) ? id : "";
  const data = usePayment(paymentId);

  return <>{children(data)}</>;
}
