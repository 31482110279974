import { EoyTaxReportStatus, TaxFormType, TaxFormWarnings, TaxStatus } from "@trolley/common-frontend";
import { Icon, Text, Tooltip } from "components";
import React, { memo, ReactNode } from "react";
import { EoyTax } from "store/actions/eoyTaxForms";
import { getTaxFormWarningLabel } from "store/actions/recipientTaxForms";
import { TaxForm } from "store/actions/taxForms";
import { useTaxForm } from "store/hooks/taxForms";
import EoyTaxDeliveryStatus from "./EoyTaxDeliveryStatus";

function getIcon(status?: "success" | "error" | "warning" | "disabled" | "dns" | "pause") {
  switch (status) {
    case "success":
      return <Icon.Status type="success" size="large" />;
    case "error":
      return <Icon.Status type="error" size="large" />;
    case "warning":
      return <Icon type="circle-exclamation" color="yellow" theme="solid" size="large" />;
    case "disabled":
      return <Icon type="ban" color="grey" size="large" />;
    case "dns":
      return <Icon type="circle-stop" theme="solid" color="grey" size="large" />;

    case "pause":
      return <Icon type="circle-pause" theme="solid" color="yellow" size="large" />;
    default:
      return <Icon type="circle" color="grey" size="large" />;
  }
}

function renderTINStatus(taxForm: TaxForm | undefined) {
  let status: string | ReactNode = "";
  let statusIcon: ReactNode;

  let label = "TIN Verification";
  switch (taxForm?.tinStatus || "missing") {
    case "valid":
      status = "Valid";
      statusIcon = getIcon("success");
      break;
    case "new":
    case "missing":
      status = taxForm?.tinStatus === "new" ? "New" : "Missing";
      statusIcon = getIcon("warning");
      break;
    case "not_valid":
      status = "Not Valid";
      statusIcon = getIcon("error");
      break;
    default:
      statusIcon = getIcon();
  }

  if (taxForm?.taxFormType && [TaxFormType.W8BEN, TaxFormType.W8BENE, TaxFormType.F8233].includes(taxForm.taxFormType as TaxFormType)) {
    label = "Foreign TIN Verification";
    const hasForeignTinWarnings = taxForm?.warnings?.find((warning) =>
      [TaxFormWarnings.FOREIGN_TIN_INVALID, TaxFormWarnings.FOREIGN_TIN_MISSING].includes(warning),
    );
    if (hasForeignTinWarnings) {
      status = getTaxFormWarningLabel(hasForeignTinWarnings);
      statusIcon = getIcon("warning");
    }
  }

  return (
    <Tooltip
      key="tin"
      title={
        <>
          <Text type="label">{label}</Text>
          {status}
        </>
      }
    >
      {statusIcon}
    </Tooltip>
  );
}

function renderEarningsStatus(eoyTaxStatus: EoyTax["status"]) {
  let status = "Needs Review";
  let statusIcon = <Icon type="circle" color="grey" size="large" />;

  switch (eoyTaxStatus) {
    case EoyTaxReportStatus.APPROVED:
    case EoyTaxReportStatus.SENT:
      status = "Approved";
      statusIcon = getIcon("success");
      break;
    case EoyTaxReportStatus.HOLD:
      status = "On Hold";
      statusIcon = getIcon("pause");
      break;
    case EoyTaxReportStatus.DO_NOT_SEND:
      status = "Do Not Send";
      statusIcon = getIcon("dns");
      break;
  }

  return (
    <Tooltip
      key="earnings"
      title={
        <>
          <Text type="label">Earnings Verification</Text>
          {status}
        </>
      }
    >
      {statusIcon}
    </Tooltip>
  );
}

type Props = {
  eoyTax: EoyTax;
};

function ReviewEarningProgress(props: Props) {
  const { eoyTax } = props;
  const { data: taxForm } = useTaxForm(eoyTax.lastTaxFormId);

  if ([EoyTaxReportStatus.VOID].includes(eoyTax.status)) {
    return null;
  }

  return (
    <Text wrap={false}>
      {/* TAX FORM */}
      <Tooltip
        title={
          <>
            <Text type="label">Tax Form</Text>
            <Text capitalize>{taxForm?.status || "Missing"}</Text>
          </>
        }
      >
        {getIcon(taxForm?.status === TaxStatus.REVIEWED ? "success" : "warning")}
      </Tooltip>

      <Icon key="1" type="minus" color="grey" fixedWidth={false} style={{ margin: "-2px" }} />

      {/* TIN STATUS */}
      {renderTINStatus(taxForm)}

      <Icon key="2" type="minus" color="grey" fixedWidth={false} style={{ margin: "-2px" }} />

      {/* EARNINGS STATUS */}
      {renderEarningsStatus(eoyTax.status)}

      {/* STATEMENT DELIVERY TO RECIPIENT */}
      {![EoyTaxReportStatus.DO_NOT_SEND, EoyTaxReportStatus.HOLD].includes(eoyTax.status) && (
        <>
          <Icon key="3" type="minus" color="grey" fixedWidth={false} style={{ margin: "-2px" }} />
          <Tooltip key="delivery" title={<EoyTaxDeliveryStatus eoyTax={eoyTax} />}>
            {getIcon(eoyTax.status === EoyTaxReportStatus.SENT ? "success" : undefined)}
          </Tooltip>
        </>
      )}

      {![EoyTaxReportStatus.DO_NOT_SEND, EoyTaxReportStatus.HOLD].includes(eoyTax.status) && (
        <>
          <Icon key="4" type="minus" color="grey" fixedWidth={false} style={{ margin: "-2px" }} />
          <Tooltip
            key="delivery"
            title={
              <>
                <Text type="label">IRS Filing</Text>
                {eoyTax.irsSubmittedAt ? "Marked as E-Filed" : "Not E-Filed"}
              </>
            }
          >
            {getIcon(eoyTax.irsSubmittedAt ? "success" : undefined)}
          </Tooltip>
        </>
      )}
    </Text>
  );
}

export default memo(ReviewEarningProgress);
