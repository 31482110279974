import { ExternalApp, NetSuiteSettingsContainer, RecursivePartial } from "@trolley/common-frontend";
/**
 * Integration flow
 * - dashboard caches the currentPage (sessionStorage)
 * - dashboard POST authenticate to API to receive an integration url
 * - dashboard redirects user to url
 * - integration site (slack, etc) callback(redirect) to dashboard with token
 * - if token is available, dashboard POST verification tokens to API
 * - dashboard redirects to cached currentPage
 */

import * as request from "services/request";
import store from "store";
import { OpCode, standardDispatch } from "store/dispatcher";
import { isLoaded } from "./actionUtils";
import { MerchantSettings } from "./merchantSettings";

export type IntegrationType = "slack" | "netsuite" | "codat";
export type CodatSettingsResult = {
  /**
   * @deprecated
   * No not use enabled. it does nothing
   */
  enabled: boolean;
  connected: boolean;
  settings?: CodatSettings;
};

interface CodatSettings {
  id?: string;
  name?: string;
  platform?: string;
  redirect?: string;
  dataConnections?: any[];
}
export type Integration = (ExternalApp.ExternalApp & { settings?: unknown }) | NetSuiteSettingsContainer | CodatSettingsResult;

export function loadIntegration(id: IntegrationType, force?: boolean) {
  const data = store.getState().integrations;

  if (force || !isLoaded(data.fetchStatus[id])) {
    standardDispatch(OpCode.LOADING, "integrations", { id });

    request
      .POST<Integration>(`/v1/integrations/${id}/get`)
      .then(({ body: { ok, ...data } }) => {
        standardDispatch(OpCode.DATA, "integrations", {
          id,
          data,
        });
      })
      .catch((errors) => {
        standardDispatch(OpCode.ERROR, "integrations", {
          errors,
          id,
        });
      });
  }
}

type IntegrationUpdateQuery = RecursivePartial<Integration>;

export async function updateIntegration(id: "netsuite", query: IntegrationUpdateQuery) {
  standardDispatch(OpCode.LOADING, "integrations", { id });
  try {
    const {
      body: { ok, ...data },
    } = await request.POST<Integration>(`/v1/integrations/${id}/update`, { query });
    standardDispatch(OpCode.DATA, "integrations", {
      id,
      data,
    });

    return data;
  } catch (errors) {
    standardDispatch(OpCode.ERROR, "integrations", {
      errors,
      id,
    });
    throw errors;
  }
}

export async function authenticateIntegration(id: IntegrationType) {
  standardDispatch(OpCode.LOADING, "integrations", {
    id,
  });

  try {
    sessionStorage.setItem(`${id}RedirectPath`, window.location.pathname);

    const res = await request.POST<ExternalApp.Autheticate>(`/v1/integrations/${id}/authenticate`, {
      query: { oAuthCallback: `${window.location.origin}/settings/integrations/${id}/callback` },
    });
    if (res.body.redirectUrl) {
      window.location.href = res.body.redirectUrl;
    } else {
      throw [
        {
          code: "integration_url_unavailable",
          message: `${id} integration url is not available`,
        },
      ];
    }
  } catch (errors) {
    standardDispatch(OpCode.ERROR, "integrations", {
      errors,
      id,
    });
    throw errors;
  }
}

export async function createCodatCompany() {
  try {
    standardDispatch(OpCode.LOADING, "integrations", {
      id: "codat",
    });
    const {
      body: { ok, ...data },
    } = await request.POST<CodatSettingsResult>("/v1/integrations/codat/create");

    standardDispatch(OpCode.DATA, "integrations", {
      id: "codat",
      data,
    });

    return data;
  } catch (errors) {
    standardDispatch(OpCode.ERROR, "integrations", {
      id: "codat",
      errors,
    });
    throw errors;
  }
}

export async function verifyIntegration(id: IntegrationType, query?: any) {
  if (id === "netsuite") {
    try {
      standardDispatch(OpCode.LOADING, "integrations", {
        id,
      });
      await request.POST(`/v1/integrations/netsuite/test`);
      standardDispatch(OpCode.LOADING, "integrations", {
        id,
        loading: false,
      });

      return undefined;
    } catch (errors) {
      standardDispatch(OpCode.ERROR, "integrations", {
        id,
        errors,
      });
      throw errors;
    }
  } else {
    const redirectPath = sessionStorage.getItem(`${id}RedirectPath`);
    if (query.error) {
      sessionStorage.removeItem(`${id}RedirectPath`);

      return;
    }
    try {
      standardDispatch(OpCode.LOADING, "integrations", {
        id,
      });
      await request.POST(`/v1/integrations/${id}/oAuthCallback`, {
        query: {
          ...query,
          oAuthCallback: `${window.location.origin}/settings/integrations/${id}/callback`,
        },
      });
      standardDispatch(OpCode.LOADING, "integrations", {
        id,
        loading: false,
      });
      loadIntegration(id, true);

      return redirectPath;
    } catch (errors) {
      standardDispatch(OpCode.ERROR, "integrations", {
        errors,
        id,
      });
      throw errors;
    } finally {
      sessionStorage.removeItem(`${id}RedirectPath`);
    }
  }
}

export async function deleteIntegration(id: "slack" | "codat") {
  try {
    await request.POST(`/v1/integrations/${id}/delete`);
  } catch (errors) {
    standardDispatch(OpCode.ERROR, "integrations", {
      errors,
      id,
    });
    throw errors;
  }
  loadIntegration(id, true);
}

export async function getMerchantSalesforceId() {
  const {
    body: { ok, ...data },
  } = await request.POST<MerchantSettings>("/v1/integrations/salesforce/getMerchantId");

  standardDispatch(OpCode.DATA, "merchantSettings", {
    data,
  });

  return data.support?.salesforceObjectId;
}
