import { Radio as RadioAnt, RadioProps } from "antd";
import React from "react";

import css, { classnames, createUseStyle } from "style/classname";
import RadioGroup from "./Group";

export interface Props extends RadioProps {}

export default function Radio({ className, ...props }: Props) {
  const styledRadio = useStyledRadio();

  return <RadioAnt {...props} className={classnames(styledRadio, className)} />;
}
Radio.__ANT_RADIO = true;

Radio.Group = RadioGroup;
Radio.Button = RadioAnt.Button;

const useStyledRadio = createUseStyle(({ theme }) =>
  css`
    & > .${theme.prefixCls}-radio {
      align-self: flex-start;
      margin: 2px 0;
    }
  `(),
);
