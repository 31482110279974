import { Access, BankProvider, BatchStatus, PaymentStatus, PaymentTaxStatus, PayoutMethodType } from "@trolley/common-frontend";
import { BigNumber } from "bignumber.js";
import {
  Alert,
  Box,
  ButtonLink,
  CopyToClipboard,
  CurrencyDisplay,
  Divider,
  Flag,
  Flyout,
  Form,
  FormItem,
  Grid,
  Heading,
  Icon,
  InputTags,
  LabelTooltip,
  Status,
  Tag,
  Text,
  Tooltip,
} from "components";
import { InvoiceLoader, InvoicePreview, StatusInvoice } from "features/invoice";
import { InvoicePaymentsLoader } from "features/invoicePayment";
import { PaymentCalculations, PaymentTimeline, StatusPayment } from "features/payment";
import { PaymentTaxRecordDAC7Edit, PaymentTaxRecordEdit } from "features/paymentTax";
import { PayoutMethodDisplay } from "features/payoutMethod";
import { RecipientProfile } from "features/recipient";
import { TaxFormDisplay } from "features/taxForm";
import { TaxProfileDisplay } from "features/taxProfile";
import { TicketAlert } from "features/ticket";
import React, { ReactNode, useState } from "react";
import { useLocation } from "react-router-dom";
import { editBatchPaymentAndRequote } from "store/actions/batchPayments";
import { notifyError, notifySuccess } from "store/actions/notifications";
import { DAC7Tax } from "store/actions/paymentDac7Taxes";
import { PaymentTax } from "store/actions/paymentTaxes";
import { WithholdingReasonLabels } from "store/actions/recipientTaxForms";
import { useBatch } from "store/hooks/batches";
import { useMerchantSettings } from "store/hooks/merchantSettings";
import { usePaymentDac7Taxes } from "store/hooks/paymentDac7Taxes";
import { usePayment } from "store/hooks/payments";
import { useAccess } from "store/hooks/user";
import { GREYS } from "style/variables";
import { entriesGroupBy, getPaymentCategoryLabel } from "utils/helpers";

type Props = {
  paymentId: string | undefined;
  showTicket?: boolean;
} & (
  | {
      children: ReactNode;
      onClose?: never;
    }
  | {
      onClose: () => void;
      children?: never;
    }
);

export default function PaymentPreview(props: Props) {
  const { paymentId, showTicket = true, children, onClose } = props;
  const location = useLocation();
  const accessPaymentWrite = useAccess(Access.PAYMENTS_WRITE);
  const { features, data: merchantSettings } = useMerchantSettings();
  const [showPreview, setShowPreview] = useState(false);
  const visible = !!paymentId && (!!onClose || showPreview);
  const [paymentTax, editPaymentTax] = useState<PaymentTax | undefined>();
  const [dac7Tax, editDac7Tax] = useState<DAC7Tax | undefined>();
  const [previewInvoiceId, setPreviewInvoiceId] = useState<string>();
  const { data: payment } = usePayment(visible ? paymentId : undefined);
  const { data: dac7Taxes } = usePaymentDac7Taxes({ paymentIds: [paymentId ?? ""] }, visible);
  const { data: batch } = useBatch(payment?.batchId);

  const paymentAccount = payment?.account;
  const taxEnabled = features.tax && merchantSettings?.tax?.enabled;
  const debitAmount = payment ? new BigNumber(payment.sourceAmount).plus(payment.merchantFees).valueOf() : null;

  function renderPaymentDetails() {
    return !payment ? null : (
      <Form compact>
        {[PaymentStatus.FAILED, PaymentStatus.RETURNED].includes(payment.status as PaymentStatus) && payment.returnedNote && (
          <FormItem label="Return Message">
            <Text type="error">{payment.returnedNote}</Text>
          </FormItem>
        )}

        <Grid padding={["medium", "none"]}>
          <Grid.Item xs={24}>
            <FormItem label="Payment ID">
              <CopyToClipboard value={payment.id} />
            </FormItem>
          </Grid.Item>
          {batch && (
            <Grid.Item xs={24} md={12}>
              <FormItem
                label={
                  <>
                    Batch ID <Status type={batch.status} size="small" right />
                  </>
                }
              >
                <CopyToClipboard value={batch.id} />
              </FormItem>
            </Grid.Item>
          )}
          {payment.externalId && (
            <Grid.Item xs={24} md={12}>
              <FormItem label="External ID">{payment.externalId}</FormItem>
            </Grid.Item>
          )}

          {paymentAccount && (
            <>
              <Grid.Item xs={24} md={12}>
                <FormItem label="Payment Method">
                  <PayoutMethodDisplay value={paymentAccount.type} showLabel />
                  {merchantSettings && merchantSettings.bankProvider === BankProvider.AFX && paymentAccount.type === PayoutMethodType.BANKTRANSFER && (
                    <Tooltip
                      placement="topLeft"
                      title={
                        <>
                          <Text weight="bold">AscendantFX Support</Text>
                          Phone: 1-877-452-7186
                        </>
                      }
                    >
                      <Text size="small" italic type="secondary">
                        Processed by AscendantFX
                      </Text>
                    </Tooltip>
                  )}
                </FormItem>
              </Grid.Item>

              {paymentAccount.type === PayoutMethodType.BANKTRANSFER && paymentAccount.bankName && (
                <Grid.Item xs={24} md={12}>
                  <FormItem label="Bank Name">{paymentAccount.bankName}</FormItem>
                </Grid.Item>
              )}

              {paymentAccount.type === PayoutMethodType.BANKTRANSFER && paymentAccount.country && (
                <Grid.Item xs={24} md={12}>
                  <FormItem label="Bank Country">
                    <Flag code={paymentAccount.country} />
                  </FormItem>
                </Grid.Item>
              )}

              <Grid.Item xs={24} md={12}>
                {renderPaymentAccount()}
              </Grid.Item>

              {paymentAccount.type === PayoutMethodType.BANKTRANSFER && (
                <Grid.Item xs={24} md={12}>
                  <FormItem label="Name of Account Holder">{paymentAccount.accountHolderName}</FormItem>
                </Grid.Item>
              )}
            </>
          )}

          {payment.category && (
            <Grid.Item xs={24} md={12}>
              <FormItem label="Payment Purpose">{renderMultiCategories(payment.taxes)}</FormItem>
            </Grid.Item>
          )}

          {(taxEnabled || new BigNumber(payment.withholdingAmount).gt(0)) && (
            <>
              <Grid.Item xs={24} md={12}>
                <FormItem label={<LabelTooltip type="taxReportable" />}>{renderMultiTaxReportable(payment.taxes)}</FormItem>
              </Grid.Item>

              {payment.forceUsTaxActivity || payment.taxes.some((pt) => pt.forceUsTaxActivity) ? (
                <Grid.Item xs={24} md={12}>
                  <FormItem label={<LabelTooltip type="forceUsTaxActivity" />}>{renderMultiForceUsActivities(payment.taxes)}</FormItem>
                </Grid.Item>
              ) : null}
            </>
          )}

          {payment.payoutMethod === PayoutMethodType.CHECK && (
            <Grid.Item xs={24} md={12}>
              <FormItem label="Check Number">{payment.checkNumber || " - "}</FormItem>
            </Grid.Item>
          )}

          {payment.memo && (
            <Grid.Item xs={24} md={12}>
              <FormItem label="Memo">{payment.memo}</FormItem>
            </Grid.Item>
          )}
        </Grid>
        <FormItem label="Tags">
          <InputTags
            editable={payment.status === PaymentStatus.PENDING && accessPaymentWrite}
            value={payment.tags}
            onChange={async (tags) => {
              try {
                await editBatchPaymentAndRequote(payment.batchId, payment.id, {
                  tags,
                });
                notifySuccess("Tags updated");
              } catch {
                notifyError("Updating tags failed");
              }
            }}
          />
        </FormItem>
      </Form>
    );
  }

  function renderPaymentAccount() {
    if (paymentAccount) {
      if (paymentAccount.type === PayoutMethodType.PAYPAL) {
        return <FormItem label="PayPal account">{paymentAccount.emailAddress}</FormItem>;
      } else if (paymentAccount.type === PayoutMethodType.CHECK && paymentAccount.mailing) {
        return <FormItem label="Name on Check">{paymentAccount.mailing.name}</FormItem>;
      } else if (paymentAccount.type === PayoutMethodType.BITCOIN) {
        return <FormItem label="Bitcoin Wallet">{paymentAccount.wallet}</FormItem>;
      } else if (paymentAccount.type === PayoutMethodType.INTERAC) {
        return <FormItem label="Interac e-Transfer Address">{paymentAccount.emailAddress}</FormItem>;
      } else if (paymentAccount.iban || paymentAccount.accountNum) {
        return <FormItem label="Bank Account Number">{String(paymentAccount.iban || paymentAccount.accountNum).slice(-6)}</FormItem>;
      }
    }

    return null;
  }

  return (
    <>
      {children && (
        <a
          role="button"
          onClick={(e) => {
            e?.stopPropagation?.();
            setShowPreview(true);
          }}
        >
          {children}
        </a>
      )}
      <Flyout
        visible={visible}
        width="large"
        onClose={() => {
          if (onClose) {
            onClose();
          } else {
            setShowPreview(false);
          }
        }}
        title={
          payment && (
            <Heading margin="none">
              <CurrencyDisplay value={payment.amount} currency={payment.currency} />
              <StatusPayment payment={payment} right />
            </Heading>
          )
        }
        extra={
          batch &&
          !location.pathname.includes(batch.id) && (
            <ButtonLink path={`/payments/${batch.id}`} data-test="view-batch" type="primary">
              View Batch
            </ButtonLink>
          )
        }
        subtitle={
          <Form layout="vertical" compact>
            {batch && !location.pathname.includes(batch.id) && [BatchStatus.OPEN, BatchStatus.APPROVAL].includes(batch.status) && (
              <Text padded>
                <Icon type="circle-info" theme="solid" color="blue" left />
                This batch is still open, and payments have not been sent yet.
              </Text>
            )}
            {payment && (
              <>
                <Divider margin="medium" />
                <Grid wrap={false} padding="small" align="middle">
                  <Grid.Item>
                    <FormItem label="Debit Amount" style={{ marginBottom: 0 }}>
                      <CurrencyDisplay value={debitAmount} currency={payment.sourceCurrency} />
                    </FormItem>
                  </Grid.Item>
                  <Grid.Item flex="80px" alignSelf="center" align="center">
                    <Icon color="blue" type="chevron-double-right" theme="duotone" size="large" />
                  </Grid.Item>
                  <Grid.Item>
                    <FormItem label="Recipient Receives" style={{ marginBottom: 0 }}>
                      <CurrencyDisplay value={payment.targetAmount} currency={payment.targetCurrency} />
                    </FormItem>
                  </Grid.Item>
                </Grid>
              </>
            )}
          </Form>
        }
      >
        {payment && (
          <>
            <Grid padding={["medium", "none"]}>
              <Grid.Item xs={24} md={7} style={{ paddingLeft: "16px", paddingTop: "12px" }}>
                <PaymentTimeline payment={payment} />
              </Grid.Item>

              <Grid.Item xs={24} md={17}>
                {showTicket && (
                  <TicketAlert relatedItemId={payment.id} showLink>
                    {(el) => <Alert type="warning">{el}</Alert>}
                  </TicketAlert>
                )}
                <Box padding="small" header="Payment Details">
                  {renderPaymentDetails()}
                </Box>

                <Box padding="small" header="Recipient Details">
                  <RecipientProfile recipientId={payment.recipientId} showLink showEmail showAddress showStatus showRisk showTaxForm />
                </Box>

                <InvoicePaymentsLoader id={payment.id}>
                  {({ data }) => {
                    if (data.length) {
                      const uniqueInvoices = entriesGroupBy(data, (ip) => ip.invoiceId);

                      return (
                        <Box padding="small" header="Related Invoices">
                          {uniqueInvoices.map(([invoiceId, ips]) => {
                            const totalAllocation = ips.reduce((acc, ip) => acc.plus(ip.amount.value), new BigNumber("0")).toFixed(2);

                            return (
                              <InvoiceLoader id={invoiceId} key={invoiceId}>
                                {(invoice) => (
                                  <Text key={invoiceId}>
                                    <Icon left type="file-invoice" />
                                    <a
                                      role="button"
                                      onClick={(e) => {
                                        e?.stopPropagation?.();
                                        setPreviewInvoiceId(invoiceId);
                                      }}
                                    >
                                      {invoice?.invoiceNumber || invoice?.description || invoiceId}
                                    </a>
                                    {invoice && <StatusInvoice type={invoice.status} size="small" right />}
                                    <Text type="secondary" style={{ paddingLeft: "22px" }} size="small">
                                      {invoice?.description && <div>{invoice.description}</div>}
                                      <CurrencyDisplay value={totalAllocation} currency={payment.currency} /> is paying towards the invoice
                                    </Text>
                                  </Text>
                                )}
                              </InvoiceLoader>
                            );
                          })}
                        </Box>
                      );
                    }

                    return null;
                  }}
                </InvoicePaymentsLoader>

                <Box padding="small" header="Amount Details">
                  {/* {payment.sourceCurrency !== payment.currency && ( */}
                  <>
                    <div style={{ padding: "8px", borderRadius: "8px", backgroundColor: GREYS.grey1 }}>
                      <Grid justify="space-between" padding="small">
                        <Grid.Item>
                          <Text weight="bold">Entered Amount</Text>
                        </Grid.Item>
                        <Grid.Item>
                          <CurrencyDisplay value={payment.amount} currency={payment.currency} />
                        </Grid.Item>
                      </Grid>
                    </div>
                    <Divider transparent margin="small" />
                  </>
                  {/* )} */}

                  <PaymentCalculations payment={payment} types={["debitAmount", "targetAmount"]} />
                </Box>

                {!!features.tax && merchantSettings?.tax?.enabled && (
                  <Box padding="small" header="Payment Tax Records">
                    {payment.taxes.map((pt) => (
                      <Grid padding="small" key={pt.id}>
                        <Grid.Item>
                          <PayoutMethodDisplay value={pt.payoutMethod} />
                        </Grid.Item>
                        <Grid.Item>
                          <Text weight="bold">
                            {pt.status === PaymentTaxStatus.PROCESSED ? (
                              <a
                                role="button"
                                onClick={(e) => {
                                  e?.stopPropagation?.();
                                  editPaymentTax(pt);
                                }}
                              >
                                <CurrencyDisplay value={pt.entered.value} currency={pt.entered.currency} />
                                <Icon
                                  right
                                  type={pt.modifiedSinceCreation ? "check" : "pencil"}
                                  color={pt.modifiedSinceCreation ? "green" : undefined}
                                  tooltip={pt.modifiedSinceCreation ? "Record has been modified." : undefined}
                                />
                              </a>
                            ) : (
                              <CurrencyDisplay value={pt.entered.value} currency={pt.entered.currency} />
                            )}
                          </Text>
                          <Text type="secondary" size="small">
                            {pt.taxReportable ? "Tax Reportable " : "Tax Exempt "}
                            {getPaymentCategoryLabel(pt.category)}
                            <Text>
                              Tax Withholding: <CurrencyDisplay value={pt.withholding.value} currency={pt.withholding.currency} />
                              {pt.taxWithholdingReason && (
                                <Icon type="comment-alt-lines" right tooltip={WithholdingReasonLabels[pt.taxWithholdingReason] || pt.taxWithholdingReason} />
                              )}
                            </Text>
                            <TaxFormDisplay taxFormId={pt.taxFormId} showIcon />
                            {!!pt.tags?.length && pt.tags.map((tag) => <Tag key={tag}>{tag}</Tag>)}
                          </Text>
                        </Grid.Item>
                      </Grid>
                    ))}

                    {payment.taxes.some((pt) => pt.status !== PaymentTaxStatus.PROCESSED) && (
                      <Alert showIcon type="info">
                        Only <Status type="processed" size="small" /> payment tax records can be modified.
                      </Alert>
                    )}
                  </Box>
                )}

                {!!features.euTax && merchantSettings?.euTax?.enabled && (
                  <Box padding="small" header="Payment Tax Records">
                    {dac7Taxes.records.map((d7t) => (
                      <Grid padding="small" key={d7t.id}>
                        <Grid.Item>
                          <PayoutMethodDisplay value={d7t.payoutMethod} />
                        </Grid.Item>
                        <Grid.Item>
                          <Text weight="bold">
                            {/* dac7 tax payments is not updating its status yet, that's why the payment status is being checked, once it's implemented
                            in the backend, the second part of the condition will be removed */}
                            {d7t.status === PaymentTaxStatus.PROCESSED || payment.status === PaymentStatus.PROCESSED ? (
                              <a
                                role="button"
                                onClick={(e) => {
                                  e?.stopPropagation?.();
                                  editDac7Tax(d7t);
                                }}
                              >
                                <CurrencyDisplay value={d7t.enteredAmount.value} currency={d7t.enteredAmount.currency} />
                                <Icon
                                  right
                                  type={d7t.updatedAt !== d7t.createdAt ? "check" : "pencil"}
                                  color={d7t.updatedAt !== d7t.createdAt ? "green" : undefined}
                                  tooltip={d7t.updatedAt !== d7t.createdAt ? "Record has been modified." : undefined}
                                />
                              </a>
                            ) : (
                              <CurrencyDisplay value={d7t.enteredAmount.value} currency={d7t.enteredAmount.currency} />
                            )}
                          </Text>
                          <Text type="secondary" size="small">
                            {d7t.taxReportable ? "Tax Reportable " : "Tax Exempt "}
                            {getPaymentCategoryLabel(d7t.category)}
                            <TaxProfileDisplay taxProfileId={d7t.taxProfileId} showStatus showSignedDate />
                            {!!d7t.tags?.length && d7t.tags.map((tag) => <Tag key={tag}>{tag}</Tag>)}
                          </Text>
                        </Grid.Item>
                      </Grid>
                    ))}

                    {payment.taxes.some((pt) => pt.status !== PaymentTaxStatus.PROCESSED) && (
                      <Alert showIcon type="info">
                        Only <Status type="processed" size="small" /> payment tax records can be modified.
                      </Alert>
                    )}
                  </Box>
                )}
              </Grid.Item>
            </Grid>

            {!!features.tax && merchantSettings?.tax?.enabled && (
              <PaymentTaxRecordEdit
                paymentTax={paymentTax}
                onClose={() => {
                  editPaymentTax(undefined);
                }}
              />
            )}
            {!!features.euTax && merchantSettings?.euTax?.enabled && (
              <PaymentTaxRecordDAC7Edit
                dac7Tax={dac7Tax}
                onClose={() => {
                  editDac7Tax(undefined);
                }}
              />
            )}
            <InvoicePreview
              invoiceId={previewInvoiceId}
              onClose={() => {
                setPreviewInvoiceId(undefined);
              }}
            />
          </>
        )}
      </Flyout>
    </>
  );
}

export function renderMultiTaxReportable(paymentTaxes: PaymentTax[], tooltip?: boolean) {
  const groupedTaxReportably = entriesGroupBy(paymentTaxes, (pt) => (pt.taxReportable ? "Taxable" : "Exempt")).sort((a, b) => (a[0] === "Taxable" ? -1 : 1));
  if (groupedTaxReportably.length > 1) {
    const content = (
      <>
        Payment contains multiple tax records:
        <ul>
          {groupedTaxReportably.map(([taxReportable, records]) => (
            <li key={taxReportable}>
              {taxReportable}:{" "}
              <CurrencyDisplay
                value={records.reduce((acc, pt) => {
                  return acc.plus(pt.entered.value);
                }, new BigNumber("0"))}
                currency={records[0]?.entered.currency}
              />
            </li>
          ))}
        </ul>
      </>
    );

    return tooltip ? (
      <Tooltip title={content}>
        <Icon type="circle-info" left />
        Mixed Values
      </Tooltip>
    ) : (
      content
    );
  }

  return groupedTaxReportably[0]?.[0] ?? "";
}

export function renderMultiCategories(paymentTaxes: PaymentTax[], tooltip?: boolean) {
  const groupedCategories = entriesGroupBy(paymentTaxes, (pt) => pt.category || "");

  if (groupedCategories.length > 1) {
    const content = (
      <>
        Payment contains multiple categories:
        <ul>
          {groupedCategories.map(([category, records]) => (
            <li key={category}>
              {getPaymentCategoryLabel(category)}:{" "}
              <CurrencyDisplay
                value={records.reduce((acc, pt) => {
                  return acc.plus(pt.entered.value);
                }, new BigNumber("0"))}
                currency={records[0]?.entered.currency}
              />
            </li>
          ))}
        </ul>
      </>
    );

    return tooltip ? (
      <Tooltip title={content}>
        <Icon type="circle-info" left />
        Mixed Values
      </Tooltip>
    ) : (
      content
    );
  }

  return getPaymentCategoryLabel(groupedCategories[0]?.[0]);
}

export function renderMultiForceUsActivities(paymentTaxes: PaymentTax[], tooltip?: boolean) {
  const groupedForceUSActivity = entriesGroupBy(paymentTaxes, (pt) => (pt.forceUsTaxActivity ? "Taxed as US Activities" : "Off")).sort((a, b) =>
    a[0] === "Taxed as US Activities" ? -1 : 1,
  );
  if (groupedForceUSActivity.length > 1) {
    const content = (
      <>
        Payment contains multiple tax records:
        <ul>
          {paymentTaxes.map((pt) => (
            <li key={pt.id}>
              <CurrencyDisplay value={pt.entered.value} currency={pt.entered.currency} />: {pt.forceUsTaxActivity ? "Taxed as US Activities" : "Off"}
            </li>
          ))}
        </ul>
      </>
    );

    return tooltip ? (
      <Tooltip title={content}>
        <Icon type="circle-info" left />
        Mixed Values
      </Tooltip>
    ) : (
      content
    );
  }

  return groupedForceUSActivity[0]?.[0] || "";
}
