import { Container, DateDisplay, Heading, RecordCount, Table, TableColumnsType, Typography, Status } from "components";
import React, { useState } from "react";
import Helmet from "react-helmet";
import { PhoneVerification } from "store/actions/verifications";
import PhoneVerificationPreview from "./PhoneVerificationPreview";
import { parsePhoneNumber } from "components/Form/InputPhone";
import { renderCountry } from ".";

interface Props {
  verifications: PhoneVerification[];
}

enum ColumnKeys {
  PHONE = "phone",
  STATUS = "status",
  ID = "id",
  COUNTRY = "country",
  SUBMITTED_AT = "submittedAt",
  DECISION_ON = "decisionOn",
}

export default function PhoneVerification(props: Props) {
  const { verifications } = props;
  const [previewPhoneVerification, setPreviewPhoneVerification] = useState<PhoneVerification | undefined>();

  const columns: TableColumnsType<PhoneVerification> = [
    {
      key: ColumnKeys.PHONE,
      title: "Phone",
      render: ({ verifiedData }: PhoneVerification) => <Typography.Text>{verifiedData.phone || "-"}</Typography.Text>,
    },
    {
      key: ColumnKeys.STATUS,
      title: "Status",
      render: ({ status }: PhoneVerification) => (status ? <Status type={status} size="small" right /> : "-"),
    },
    {
      key: ColumnKeys.ID,
      title: "Verification ID",
      render: ({ id }: PhoneVerification) => <Typography.Text>{id || "-"}</Typography.Text>,
    },
    {
      key: ColumnKeys.COUNTRY,
      title: "Country",
      render: ({ verifiedData }: PhoneVerification) => renderCountry(parsePhoneNumber(verifiedData.phone).country),
    },
    {
      key: ColumnKeys.SUBMITTED_AT,
      title: "Submitted At",
      render: ({ submittedAt }: PhoneVerification) => (submittedAt ? <DateDisplay value={submittedAt} /> : "-"),
    },
    {
      key: ColumnKeys.DECISION_ON,
      title: "Decision On",
      render: ({ decisionAt }: PhoneVerification) => (decisionAt ? <DateDisplay value={decisionAt} /> : "-"),
    },
  ];

  return (
    <Container>
      <Helmet>
        <title>Phone Verification</title>
      </Helmet>
      <Heading tag="h2">
        <RecordCount prefix="Showing" value={verifications.length} one="1 phone verification" other="# phone verifications" />
      </Heading>

      <Table<PhoneVerification>
        columns={columns}
        dataSource={verifications}
        emptyProps={{
          description: "No phone verifications submitted",
        }}
        onRow={(record) => ({
          onClick: () => {
            setPreviewPhoneVerification(record);
          },
        })}
      />

      <PhoneVerificationPreview
        PhoneVerification={previewPhoneVerification}
        onClose={() => {
          setPreviewPhoneVerification(undefined);
        }}
      />
    </Container>
  );
}
