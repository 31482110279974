import { Container, Space, Typography } from "components";
import { SpaceProps } from "components/Space";
import React from "react";

export interface BrandDisplayData {
  label?: string;
  image?: string;
}

export interface BrandDisplayProps {
  data: BrandDisplayData[];
  justify?: SpaceProps["justify"];
  size?: "small" | "large";
  showLabel?: boolean;
}

const IMAGE_SIZES = {
  small: 18,
  medium: 40,
  large: 96,
};

export default function BrandDisplay(props: BrandDisplayProps) {
  const { data, size, showLabel, justify = "center" } = props;
  const imageHeight = IMAGE_SIZES[size ?? "medium"];

  return (
    <Space gap={size ?? "middle"} justify={justify}>
      {data.map((item, index) => {
        return (
          <Container padding="small" align="center" key={`avatar-group-${item.label ?? index}`}>
            <Space direction="column">
              <img src={item.image} alt={item.label} height={imageHeight} />
              {!!item.label && showLabel && (
                <Typography.Text size={size} weight="bold" inline>
                  {item.label}
                </Typography.Text>
              )}
            </Space>
          </Container>
        );
      })}
    </Space>
  );
}
