import { useEffect, useState } from "react";

export default function useScroll(element: any = window) {
  const [scrolling, setScrolling] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);

  useEffect(() => {
    function onScroll(e: any) {
      const el = e.target.documentElement;
      if (el) {
        setScrollTop(el.scrollTop);
        setScrolling(el.scrollTop > scrollTop);
      }
    }
    element.addEventListener("scroll", onScroll);

    return () => element.removeEventListener("scroll", onScroll);
  }, [scrollTop]);

  return { scrolling, scrollTop };
}
