import { Configuration } from "config";

const config: Configuration = {
  DOMAIN: "railz.io",
  WS_SERVER_ADDRESS: "wss://api.railz.io",
  SERVER_ADDRESS: "https://api.railz.io",
  WIDGET_ADDRESS: "https://widget.railz.io",
  USERPILOT_APP_TOKEN: "NX-66pn27k2",
  SALESFORCE: {
    recordType: "0125Y000001wR9bQAE", // for WebtoCase
    eswUrl: "https://trolley--prodstage.sandbox.my.salesforce.com/embeddedservice/5.0/esw.min.js",
    apiUrl: "https://trolley--prodstage.sandbox.my.salesforce.com",
    siteUrl: "https://trolley--prodstage.sandbox.my.salesforce-sites.com/",
    id: "00D760000008hkm",
    deployment: "Live_Chat",
    chatDetails: {
      baseLiveAgentContentURL: "https://c.la5-c1cs-ia4.salesforceliveagent.com/content",
      deploymentId: "5725Y000000ozTt",
      buttonId: "5735Y000000ozXj",
      baseLiveAgentURL: "https://d.la5-c1cs-ia4.salesforceliveagent.com/chat",
      eswLiveAgentDevName: "EmbeddedServiceLiveAgent_Parent04I5Y000000orGoUAI_17fb2ca1583",
      isOfflineSupportEnabled: false,
    },
  },
  DATADOG: {
    applicationId: "8fca1f59-92f1-434e-805d-4f94df044aca",
    clientToken: "pubf0f5a4531beda807b77dc4995e698f5c",
    site: "datadoghq.com",
    service: "merchant-dashboard",
  },
};
export default config;
