import { ButtonDropdown, Icon, Menu } from "components";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useWindowSize } from "utils/hooks";
import UploadTaxFormsPopup from "./UploadTaxFormsPopup";

const PRIMARY_BUTTON_WIDTH = 220;

export default function UploadTaxForms() {
  const { isMobile } = useWindowSize();
  const history = useHistory();
  const [open, setOpen] = useState(false);

  function onClose() {
    setOpen(false);
  }

  return (
    <>
      <ButtonDropdown
        style={{ width: isMobile ? "100%" : PRIMARY_BUTTON_WIDTH }}
        size="large"
        onClick={() => {
          setOpen(true);
        }}
        type="primary"
        overlay={
          <Menu
            onClick={(e) => {
              switch (e.key) {
                case "upload":
                  setOpen(true);
                  break;
                case "recent-uploads":
                  history.push("/tax-center/tax-forms/upload");
                  break;
              }
            }}
            style={{ width: isMobile ? "auto" : PRIMARY_BUTTON_WIDTH }}
          >
            <Menu.Item key="upload">
              <Icon type="cloud-arrow-up" left />
              Upload Tax Forms
            </Menu.Item>
            <Menu.Item key="recent-uploads">
              <Icon type="history" left />
              Recent Uploaded files
            </Menu.Item>
          </Menu>
        }
      >
        <Icon type="cloud-arrow-up" left />
        Upload Tax Forms
      </ButtonDropdown>
      <UploadTaxFormsPopup visible={open} onClose={onClose} />
    </>
  );
}
