import { Typography as AntTypography } from "antd";
import { BaseType } from "antd/lib/typography/Base";
import { TextProps as AntTextProps } from "antd/lib/typography/Text";
import React, { PropsWithChildren } from "react";
import css, { classnames, createUseStyle } from "style/classname";

export interface TextProps extends Omit<AntTextProps, "strong" | "type"> {
  type?: Exclude<BaseType, "danger"> | "primary" | "text" | "label" | "error"; //  use "error" instead of "danger" to avoid renaming all components
  size?: "base" | "xxlarge" | "xlarge" | "large" | "small";
  padded?: boolean;
  inline?: true;
  align?: "left" | "center" | "right" | "justify" | "inherit";
  weight?: "normal" | "medium" | "bold";
  letterSpacing?: number;
  capitalize?: boolean;
  uppercase?: boolean;
  wrap?: boolean; // TO BE REMOVED AND REFACTORED
}

export default function Text(props: PropsWithChildren<TextProps>) {
  const { className, padded, type, size, inline, letterSpacing, capitalize, uppercase, weight, wrap, ...rest } = props;
  const styledText = useStyledText(props);

  return (
    <AntTypography.Text
      className={classnames(styledText, type || "", className)}
      {...rest}
      type={type === "label" || type === "text" || type === "primary" ? undefined : type === "error" ? "danger" : type}
    />
  );
}

const useStyledText = createUseStyle<TextProps>(({ theme, ...props }) =>
  css`
    word-break: normal;
    ${typeof props.wrap === "boolean" &&
    (props.inline && props.wrap
      ? `
        white-space: normal;
      `
      : `
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      `)}
    ${() => {
      switch (props.weight) {
        case "normal":
          return `font-weight: 400;`;
        case "medium":
          return `font-weight: 500;`;
        case "bold":
          return `font-weight: 600;`;
        default:
          return "";
      }
    }}
    ${() => {
      if (props.type) {
        switch (props.type) {
          case "label":
            return `color: ${(theme.Form as any)?.colorTextHeading || theme.colorTextHeading};`;
          case "text":
            return `color: ${theme.colorText};`;
          case "primary":
            return `color: ${theme.colorPrimaryText};`;
          default:
            return "";
        }
      } else {
        return `color: inherit;`;
      }
    }}
    ${!props.inline || props.padded ? `display: block;` : ""}
    ${props.padded &&
    `
      margin-top: ${theme.marginXXS}px;
      margin-bottom: ${theme.marginXXS}px;
    `}
    ${props.capitalize && `text-transform: capitalize;`}
    ${props.uppercase && `text-transform: uppercase;`}
    ${props.align && `text-align: ${props.align};`}
    ${props.letterSpacing && `letter-spacing: ${props.letterSpacing}px;`}

    ${() => {
      switch (props.size) {
        case "base":
          return `
            font-size: ${theme.fontSize}px;
            ${!props.inline && `line-height: ${theme.lineHeight};`}
          `;
        case "small":
          return `
            font-size: ${theme.fontSizeSM}px;
            ${!props.inline && `line-height: ${theme.lineHeightSM};`}
          `;
        case "large":
          return `
            font-size: ${theme.fontSizeLG}px;
            ${!props.inline && `line-height: ${theme.lineHeightLG};`}
          `;
        case "xlarge":
          return `
            font-size: ${theme.fontSizeXL}px;
            ${!props.inline && `line-height: ${(theme.fontSizeXL + 8) / theme.fontSizeXL};`}
          `;
        case "xxlarge":
          return `
            font-size: ${theme.fontSizeXL + 4}px;
            ${!props.inline && `line-height: ${(theme.fontSizeXL + 12) / (theme.fontSizeXL + 4)};`}
          `;
        default:
          return `
            font-size: inherit;
          `;
      }
    }}
  `(),
);
