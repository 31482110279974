import { getReturnReasonText, PaymentStatus, TicketStatus } from "@trolley/common-frontend";
import { Status, StatusProps, Text } from "components";
import { TicketPreview } from "features/ticket";
import React, { useEffect, useState } from "react";
import { Payment } from "store/actions/payments";
import { usePaymentTickets } from "store/hooks/tickets";

type Props = Omit<StatusProps, "type" | "tooltipProps" | "dot"> & {
  payment: Payment | undefined;
  showOpenTicket?: boolean;
};

export default function StatusPayment(props: Props) {
  const { payment, showOpenTicket, ...rest } = props;
  const [ticketPreviewId, setTicketPreviewId] = useState<string>();
  const paymentTickets = usePaymentTickets(payment?.id);

  useEffect(() => {
    if (payment) {
      setTicketPreviewId(undefined);
    }
  }, [payment]);

  if (!payment) {
    return null;
  }

  const openTicket = showOpenTicket && payment.status === PaymentStatus.PROCESSING && paymentTickets.find((t) => [TicketStatus.OPEN].includes(t.status));

  if (openTicket) {
    return (
      <span
        role="presentation"
        onClick={(e) => {
          e?.stopPropagation?.();
        }}
      >
        <Status
          {...rest}
          type={payment.status}
          tooltipProps={{
            title: (
              <>
                This payment is being held due to missing recipient information. See the associated ticket:
                <br />
                <a
                  role="button"
                  onClick={() => {
                    setTicketPreviewId(openTicket.id);
                  }}
                >
                  {openTicket.id}
                </a>
              </>
            ),
          }}
        />
        <TicketPreview
          ticketId={ticketPreviewId}
          onClose={() => {
            setTicketPreviewId(undefined);
          }}
        />
      </span>
    );
  }

  if (payment.status === PaymentStatus.RETURNED && !!payment.returnedReason?.length) {
    return (
      <Status
        {...rest}
        type={payment.status}
        tooltipProps={{
          title:
            payment.returnedReason.length > 1 ? (
              <>
                <Text weight="bold">Returned Reasons:</Text>
                <ul>
                  {payment.returnedReason.map((reason) => (
                    <li key={reason}>{getReturnReasonText(reason)}</li>
                  ))}
                </ul>
              </>
            ) : (
              <>
                <Text weight="bold">Returned Reason:</Text>
                {getReturnReasonText(payment.returnedReason[0])}
              </>
            ),
        }}
      />
    );
  }

  return <Status {...rest} type={payment.status} />;
}
