/* istanbul ignore file */
import { Modal } from "components";
import { createBrowserHistory } from "history";

export const history = createBrowserHistory<{ scrollToTop?: boolean }>({
  getUserConfirmation(message, callback) {
    const customModalInstance = Modal.confirm({
      title: "Leave page?",
      okText: "Continue",
      onOk: () => {
        customModalInstance.destroy();
        callback(true);
      },
      okButtonProps: {
        type: "default",
      },
      cancelText: "Stay on page",
      onCancel: () => {
        customModalInstance.destroy();
        callback(false);
      },
      cancelButtonProps: {
        type: "primary",
      },
      content: message,
    });
  },
});

history.listen((location) => {
  if (location.state?.scrollToTop !== false) {
    window.scrollTo(0, 0);
  }
});

export default history;
