import { useMountedRef, useOnValueChange } from "@shopify/react-hooks";
import { Popover } from "antd";
import debounce from "lodash.debounce";
import React, { MouseEvent, useCallback, useState } from "react";

import { Button, Icon, Space, Spinner, Text } from "components";
import Form from "components/Form";
import Input, { InputProps } from "components/Form/Input";
import { ItemProps } from "components/Form/Item";
import { TooltipProps } from "components/Tooltip";
import css, { createUseStyle } from "style/classname";

const DEFAULT_NAME = "data";

export const useStyledColor = createUseStyle<{ color: string }>(({ theme, color }) =>
  css`
    height: 15px;
    width: 15px;
    border: ${theme.lineWidth}px ${theme.lineType} ${theme.colorBorder};
    ${!!color && typeof color === "string" && `background-color: #${color.replace(/#/, "")};`}
  `(),
);

interface Props extends Omit<InputProps, "onKeyDown" | "onBlur" | "onPressEnter" | "type" | "value"> {
  value: string | null | undefined;
  allowNegative?: boolean;
  formOptions?: Omit<ItemProps, "children" | "tooltip">;
  okText?: string;
  enablePopup?: boolean;
  placement?: TooltipProps["placement"];
  onSave(value: string): Promise<void>;
  dataTestId?: string;
}

export default function InputLive(props: Props) {
  const {
    value,
    allowNegative,
    inputMode,
    formOptions,
    okText = "Save",
    placement = "bottomRight",
    name = DEFAULT_NAME,
    enablePopup = true,
    onSave,
    readOnly,
    dataTestId,
    ...restInputProps
  } = props;
  const [form] = Form.useForm();
  const isMounted = useMountedRef();
  const [loading, setLoading] = useState(false);
  const onSaveDebounce = useCallback(
    debounce(async (values: any) => {
      if (name in values) {
        const formattedValue = values[name] && String(values[name]).trim();
        if (value === formattedValue) {
          onDone();
        } else {
          setLoading(true);
          try {
            await props.onSave(formattedValue);
            onDone();
          } catch (errors) {
            const errMessages = [];
            if (typeof errors === "string") {
              errMessages.push(errors);
            } else if (errors && errors.message) {
              errMessages.push(errors.message);
            } else if (Array.isArray(errors)) {
              const err = errors.find((e) => e.message);
              if (err) {
                errMessages.push(err.message);
              }
            }
            form.setFields([{ name, value: formattedValue, errors: errMessages }]);
          }
          if (isMounted) {
            setLoading(false);
          }
        }
      }
    }, 200),
    [form, value],
  );

  useOnValueChange(value, () => {
    if (!form.isFieldsTouched()) {
      // don't update if user is already updating
      form.resetFields();
    }
  });

  function onDone(e?: MouseEvent<HTMLElement>) {
    onSaveDebounce.cancel(); // Canceling the delayed saving because clicking "cancel" button triggers 'onSave' due to 'onBlur' event.
    e?.stopPropagation?.();
    form.resetFields();
    if (isMounted) {
      setLoading(false);
    }
  }

  return (
    <Form form={form} initialValues={{ [name]: value }} validateTrigger="onSubmit" onFinish={onSaveDebounce}>
      <Form.Control shouldUpdate>
        {/* ISSUE TO FIX: https://github.com/ant-design/ant-design/issues/26888 */}
        {({ getFieldError, isFieldTouched }) => {
          const error = getFieldError(name).join(", ");
          const isTouched = isFieldTouched(name);
          const inputProps: InputProps = {
            ...restInputProps,
            "data-testid": dataTestId,
            inputMode,
            readOnly: loading || readOnly,
            onKeyDown: (e) => {
              if (e) {
                switch (e.key) {
                  case "Escape":
                    onDone();
                    break;
                  case "Enter":
                    e.preventDefault?.();
                    form.submit();
                    break;
                }
              }
            },
            onBlur: form.submit,
          };

          if (!enablePopup) {
            if (error) {
              inputProps[/right/gi.test(placement) ? "suffix" : "prefix"] = <Icon.Status type="error" />;
            }
            if (loading) {
              inputProps.suffix = <Spinner />;
            }
          }

          return (
            <Popover
              visible={enablePopup ? isTouched : !!error}
              placement={placement}
              content={
                <>
                  {enablePopup && (
                    <Space direction={/right/gi.test(placement) ? "row-reverse" : "row"}>
                      <Button
                        onClick={(e) => {
                          e?.stopPropagation?.();
                          form.submit();
                        }}
                        type="primary"
                        loading={loading}
                        style={{ minWidth: "90px" }}
                      >
                        {okText}
                      </Button>
                      <Button onClick={onDone} tabIndex={-1}>
                        Cancel
                      </Button>
                    </Space>
                  )}
                  {error && (
                    <Text type="error" align={/right/gi.test(placement) ? "right" : "left"} style={{ paddingTop: "4px", textTransform: "initial" }}>
                      {error}
                    </Text>
                  )}
                </>
              }
            >
              <Form.Item
                noStyle
                name={name}
                normalize={(val, prevVal) => {
                  const formattedValue = String(val ?? "").replace(allowNegative ? /[^-\d.]/g : /[^\d.]/g, "");

                  if (inputMode === "decimal") {
                    return /^-?\d*\.?\d*$/.test(formattedValue) ? formattedValue : prevVal;
                  } else if (inputMode === "numeric") {
                    return /^-?\d*$/.test(formattedValue) ? formattedValue : prevVal;
                  }

                  return val;
                }}
                {...formOptions}
              >
                <Input {...inputProps} />
              </Form.Item>
            </Popover>
          );
        }}
      </Form.Control>
    </Form>
  );
}
