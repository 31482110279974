import { useState, useEffect } from "react";
import { PaymentsQuery, loadRecipientPayments, loadPayment, loadPayments, Payment } from "store/actions/payments";
import { useShallowSelector } from ".";
import { emptyList } from "store/reducers/standardReducer";
import { computeID } from "store/actions/actionUtils";
import { PaymentStatus, PayoutMethodType } from "@trolley/common-frontend";

export function useBatchPayments(id: string | undefined, query: PaymentsQuery) {
  const batchId = id && /^B-\w+/.test(id) ? id : "";
  const [fetchId, setFetchId] = useState("");

  useEffect(() => {
    if (batchId) {
      setFetchId(loadPayments({ ...query, batchId: [batchId] }));
    } else {
      setFetchId("");
    }
  }, [batchId, computeID(query)]);

  return useShallowSelector((state) => {
    if (batchId && fetchId && state.payments.fetchStatus[fetchId] === undefined) {
      loadPayments({ ...query, batchId: [batchId] });
    }
    const payments = state.payments.entities[fetchId];

    return {
      data: payments
        ? {
            records: payments.records.map((paymentId) => state.payment.entities[paymentId]).filter((v) => v) as Payment[],
            meta: payments.meta,
          }
        : emptyList,
      status: state.payments.fetchStatus[fetchId],
      error: state.payments.errors[fetchId],
    };
  });
}

export function usePaymentList(query: PaymentsQuery) {
  const [fetchId, setFetchId] = useState("");

  useEffect(() => {
    setFetchId(loadPayments(query));
  }, [query]);

  return useShallowSelector((state) => {
    if (state.payments.fetchStatus[fetchId] === undefined) {
      loadPayments(query);
    }

    const paymentList = state.payments.entities[fetchId];

    return {
      data: paymentList
        ? { records: paymentList.records.map((id) => state.payment.entities[id]).filter((v) => v) as Payment[], meta: paymentList.meta }
        : emptyList,
      status: state.payments.fetchStatus[fetchId],
      error: state.payments.errors[fetchId],
    };
  });
}

export function usePayment(id?: string) {
  const paymentId = id && /^P-\w+/.test(id) ? id : "";

  useEffect(() => {
    if (paymentId) {
      loadPayment(paymentId);
    }
  }, [paymentId]);

  return useShallowSelector((state) => {
    if (paymentId && state.payment.fetchStatus[paymentId] === undefined) {
      loadPayment(paymentId);
    }

    return {
      data: state.payment.entities[paymentId],
      status: state.payment.fetchStatus[paymentId],
      error: state.payment.errors[paymentId],
    };
  });
}

export function useRecipientPaymentList(id: string | undefined, query: PaymentsQuery) {
  const recipientId = id && /^R-\w+/.test(id) ? id : "";
  const [fetchId, setFetchId] = useState("");

  useEffect(() => {
    if (recipientId) {
      setFetchId(loadRecipientPayments(recipientId, query));
    }
  }, [recipientId, query]);

  return useShallowSelector((state) => {
    if (recipientId && state.payments.fetchStatus[fetchId] === undefined) {
      loadRecipientPayments(recipientId, query);
    }

    const paymentList = state.payments.entities[fetchId];

    return {
      data: paymentList
        ? { records: paymentList.records.map((paymentId) => state.payment.entities[paymentId]).filter((v) => v) as Payment[], meta: paymentList.meta }
        : emptyList,
      status: state.payments.fetchStatus[fetchId],
      error: state.payments.errors[fetchId],
    };
  });
}

export function useReturnPayment(payment: Payment | undefined) {
  const canReturn =
    payment &&
    [PayoutMethodType.CHECK, PayoutMethodType.PAYPAL].includes(payment?.payoutMethod as PayoutMethodType) &&
    payment?.status === PaymentStatus.PROCESSED;

  return canReturn;
}
