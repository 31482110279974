import React, { ReactNode } from "react";
import Tag, { TagProps } from ".";

export type InputTagsProps = {
  value?: string[];
  editable?: boolean;
  placeholder?: string;
  onChange?: (val: string[]) => void;
  renderTag?: (tag: string) => ReactNode;
  maxLength?: number;
  onClick?: TagProps["onClick"];
  disabled?: boolean;
};

export default function InputTags(props: InputTagsProps) {
  const { value, maxLength = 1000, placeholder, editable, renderTag, onChange, onClick, disabled } = props;

  async function onAdd(tag: string) {
    if (tag) {
      const allTags = (value || []).reduce(
        (acc, oldTag) => {
          if (oldTag && acc.every((val) => String(val).toLocaleLowerCase() !== oldTag.toLocaleLowerCase())) {
            acc.push(oldTag);
          }

          return acc;
        },
        [tag],
      );
      onChange?.(allTags);
    }
  }

  function onDelete(tag: string) {
    if (Array.isArray(value)) {
      onChange?.(value.filter((v) => v !== tag));
    }
  }

  return (
    <>
      {(value || []).map((v) => (
        <Tag
          key={v}
          onClick={onClick}
          onDelete={
            editable && !disabled
              ? async () => {
                  onDelete(v);
                }
              : undefined
          }
        >
          {renderTag?.(v) || v}
        </Tag>
      ))}
      {!disabled && editable && (value || []).length < maxLength && <Tag.New placeholder={placeholder} onAddNew={onAdd} />}
    </>
  );
}
