import { BaseError } from "@trolley/common-frontend";
import { Button, Divider, Form, Input, Text } from "components";
import { promptTFA } from "features/user";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import { authLogin } from "store/actions/auth";
import { useShallowSelector } from "store/hooks";
import { useUser } from "store/hooks/user";
import { SessionMode } from "store/reducers/session";
import { BaseStatus } from "store/reducers/standardReducer";
import { FormatMessage, useIntl } from "utils/context";
import { handleFormErrors, parseQuery } from "utils/helpers";
import FullscreenAuth from "./FullscreenAuth";
import { PUBLIC_ERROR_LABELS } from "utils/constants";
import IntlMessages from "locale/enkeys";
import { useLocale } from "store/hooks/locale";
import { getPublicPath, PublicModulesEnum } from "pages/LoginNotRequired";

type FormFields = {
  email: string;
  password: string;
};

export function translateErrorMessages(errors: BaseError[], formatMessage: FormatMessage) {
  if (!errors?.length) return errors;
  const translatedErrors = errors.map((error: BaseError) => {
    if (!error.message || !PUBLIC_ERROR_LABELS[error.message]) return error;

    return {
      ...error,
      message: formatMessage({ id: PUBLIC_ERROR_LABELS[error.message] as keyof IntlMessages }),
    };
  });

  return translatedErrors;
}

export default function LoginPage() {
  const { formatMessage } = useIntl();
  const [form] = Form.useForm<FormFields>();
  const sessionState = useShallowSelector((state) => state.session.state);
  const { status } = useUser();
  const location = useLocation();
  const locale = useLocale();

  async function onFinish(values: FormFields) {
    try {
      const loginAttempt = await authLogin(values);

      switch (loginAttempt.status) {
        case "token":
          // authentication OK
          break;
        case "signup_pending": // Should not be happening anymore
          form.setFields([
            {
              name: "email",
              value: values.email,
              errors: [formatMessage({ id: "auth.login.errors.awaitingApproval" })],
            },
          ]);
          break;
        case "tfa_required":
          if (loginAttempt.tfaType === "email_link") {
            form.setFields([
              {
                name: "email",
                value: values.email,
                errors: [formatMessage({ id: "auth.login.errors.verifyEmailAddress" })],
              },
            ]);
          } else {
            await promptTFA(
              {
                tfaType: loginAttempt.tfaType,
                challenge: loginAttempt.challenge,
              },
              async (tfaOtp) => {
                try {
                  const { status } = await authLogin({
                    email: values.email,
                    password: values.password,
                    tfaType: loginAttempt.tfaType,
                    tfaOtp,
                  });

                  if (status !== "token") {
                    form.setFields([
                      {
                        name: "email",
                        value: values.email,
                        errors: [formatMessage({ id: "auth.login.errors.unableToAuthenticate" })],
                      },
                    ]);
                  }
                } catch (errors) {
                  const invalidIP = errors?.find((e: BaseError) => e.code === "invalid_ip_error");
                  if (invalidIP) {
                    form.setFields([
                      {
                        name: "email",
                        errors: [
                          PUBLIC_ERROR_LABELS[invalidIP.message]
                            ? formatMessage({ id: PUBLIC_ERROR_LABELS[invalidIP.message] as keyof IntlMessages })
                            : invalidIP.message,
                        ],
                      },
                    ]);
                  } else {
                    throw errors;
                  }
                }
              },
              {
                title: formatMessage({ id: "auth.login.errors.authenticationCode" }),
                submitText: formatMessage({ id: "auth.login.errors.verifyCode" }),
                errorFieldName: "email",
              },
            );
          }
          break;
      }
    } catch (errors) {
      handleFormErrors(translateErrorMessages(errors, formatMessage), form);
    }
  }

  return (
    <FullscreenAuth>
      <Text size="xxlarge" weight="bold">
        {formatMessage({ id: "auth.login.title" })}
      </Text>
      <Text type="secondary">{formatMessage({ id: "auth.login.subtitle" })}</Text>
      <Divider transparent margin="medium" />
      <Form form={form} onFinish={onFinish} initialValues={{ email: parseQuery(location?.search)?.email }} data-testid="form">
        {sessionState === SessionMode.EXPIRED && (
          <Text type="error" align="center">
            {formatMessage({ id: "auth.login.loggedOutInactivity" })}
          </Text>
        )}

        <Form.Control dependencies={["email"]}>
          {({ getFieldValue }) => (
            <Form.Item
              name="email"
              label={formatMessage({ id: "auth.login.email" })}
              rules={[{ required: true, message: formatMessage({ id: "auth.login.emailRequired" }) }]}
            >
              <Input type={/;as=>/g.test(getFieldValue("email")) ? "text" : "email"} autoFocus id="email" name="email" />
            </Form.Item>
          )}
        </Form.Control>

        <Form.Item
          label={formatMessage({ id: "auth.login.password" })}
          name="password"
          rules={[{ required: true, message: formatMessage({ id: "auth.login.passwordRequired" }) }]}
        >
          <Input type="password" id="password" name="password" />
        </Form.Item>
        <Link to={getPublicPath(PublicModulesEnum.RequestReset, locale)}>{formatMessage({ id: "auth.login.forgotPassword" })}</Link>

        <Divider transparent margin="medium" />

        <Button type="primary" block htmlType="submit" size="large" loading={status === BaseStatus.LOADING}>
          {formatMessage({ id: "auth.login.login" })}
        </Button>
      </Form>

      <Divider transparent margin="small" />
      <Text align="center">
        {formatMessage({ id: "auth.login.dontHaveAccount" })}{" "}
        <Link to={getPublicPath(PublicModulesEnum.Register, locale)}>{formatMessage({ id: "auth.login.signup" })}</Link>
      </Text>
    </FullscreenAuth>
  );
}
