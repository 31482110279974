import * as types from "@trolley/common-frontend";
import { batch } from "react-redux";
import { POST } from "services/request";
import store from "store";
import { OpCode, standardDispatch } from "store/dispatcher";
import { Mapped } from "store/reducers/standardReducer";
import { queueFactory } from "utils/factories";
import { Query } from "utils/hooks";
import { computeID, isLoaded } from "./actionUtils";

export interface TaxProfilesQuery extends Query {
  orderBy?: string[];
  sortBy?: ("asc" | "desc")[];
  recipientId?: string;
  ids?: string[];
  status?: types.TaxStatus[];
  tinStatus?: string[];
}

export interface TaxProfile extends Omit<types.TaxProfile, "data"> {
  data: Partial<types.TaxProfileData>;
}

export function resetTaxProfiles() {
  standardDispatch(OpCode.RESET, "recipientTaxProfiles");
  standardDispatch(OpCode.RESET, "taxProfiles");
}

const taxProfileQueueLoader = queueFactory(
  (taxProfileIds) => {
    POST<types.TaxProfileListResponse>("/v1/tax-profile/search", { query: { ids: taxProfileIds, pageSize: taxProfileIds.length } })
      .then(({ body }) => {
        const mappedTaxProfiles = body.taxProfiles.reduce((acc, tp) => {
          acc[tp.id] = tp;

          return acc;
        }, {});
        standardDispatch(OpCode.DATA, "taxProfile", {
          bulk: taxProfileIds.reduce((acc, id) => {
            acc[id] = mappedTaxProfiles[id] ?? undefined;

            return acc;
          }, {}),
        });
      })
      .catch((errors) => {
        standardDispatch(OpCode.ERROR, "taxProfile", {
          ids: taxProfileIds,
          errors,
        });
      });
  },
  (id) => /^TP-\w+/.test(id),
);

export function loadTaxProfile(id: string, force?: boolean) {
  const { taxProfile } = store.getState();

  if (force || !isLoaded(taxProfile.fetchStatus[id])) {
    standardDispatch(OpCode.LOADING, "taxProfile", { id });
    taxProfileQueueLoader.add(id);
  }
}

export function loadTaxProfiles(options: TaxProfilesQuery, force?: boolean) {
  const { taxProfiles } = store.getState();
  const id = computeID(options);

  if (force || !isLoaded(taxProfiles.fetchStatus[id])) {
    standardDispatch(OpCode.LOADING, "taxProfiles", { id });

    POST<types.TaxProfileListResponse>("/v1/tax-profile/search", { query: options })
      .then((res) => {
        const mapTaxProfiles: Mapped<TaxProfile> = {};
        const ids = res.body.taxProfiles.map((tp) => {
          mapTaxProfiles[tp.id] = tp;

          return tp.id;
        });
        batch(() => {
          standardDispatch(OpCode.DATA, "taxProfiles", {
            id,
            data: {
              records: ids,
              meta: res.body.meta,
            },
          });

          standardDispatch(OpCode.DATA, "taxProfile", {
            bulk: mapTaxProfiles,
          });
        });
      })
      .catch((errors) => {
        standardDispatch(OpCode.ERROR, "taxProfiles", {
          errors,
          id,
        });
      });
  }

  return id;
}
