import createEmotion from "@emotion/css/create-instance";
import { ConfigProvider, GlobalToken, theme } from "antd";
import { useContext } from "react";
import { GREYS, GreyColors } from "style/variables";

const { css: emotionCss, cx, injectGlobal, keyframes } = createEmotion({ key: "pr" });

export { GlobalToken, cx as classnames, injectGlobal, keyframes };

type CSSValue = null | undefined | boolean | number | string;

type CustomTokens = {
  boxShadowInset: string;
};

function noFalsy(value: CSSValue): CSSValue {
  if (value === undefined || value === null || value === false) {
    return "";
  }

  return value;
}

export interface ThemedToken extends GlobalToken, GreyColors, CustomTokens {
  screenUp(screenSize: "xs" | "sm" | "md" | "lg" | "xl" | "xxl"): string;
  screenDown(screenSize: "xs" | "sm" | "md" | "lg" | "xl" | "xxl"): string;
  prefixCls: string;
}

export function useThemeToken(): ThemedToken {
  const confiContext = useContext(ConfigProvider.ConfigContext);
  const token = theme.useToken().token as GlobalToken & GreyColors;

  /**
   * screenUp a helper css styling function to redact breakpoint styles
   * @param breakpoint size of the breakpoint
   * @returns "@media screen and (min-width: [   ])"
   */
  function screenUp(breakpoint: "xs" | "sm" | "md" | "lg" | "xl" | "xxl") {
    let minWidth: number;
    switch (breakpoint) {
      case "sm":
        minWidth = token.screenSM; // 576
        break;
      case "md":
        minWidth = token.screenMD; // 768
        break;
      case "lg":
        minWidth = token.screenLG; // 992
        break;
      case "xl":
        minWidth = token.screenXL; // 1200
        break;
      case "xxl":
        minWidth = token.screenXXL; // 1600
        break;
      default:
        minWidth = token.screenXS; // 480
    }

    return `@media screen and (min-width: ${minWidth}px)`;
  }

  /**
   * screenDown a helper css styling function to redact breakpoint styles
   * screen down takes 0.02px below the given breakpoint as the max limit
   * @param breakpoint size of the breakpoint
   * @returns "@media screen and (max-width: [   ])"
   */

  function screenDown(breakpoint: "xs" | "sm" | "md" | "lg" | "xl" | "xxl") {
    let minWidth: number;
    switch (breakpoint) {
      case "xs":
        minWidth = token.screenXS - 0.02;
        break;
      case "sm":
        minWidth = token.screenSM - 0.02;
        break;
      case "md":
        minWidth = token.screenMD - 0.02;
        break;
      case "lg":
        minWidth = token.screenLG - 0.02;
        break;
      case "xl":
        minWidth = token.screenXL - 0.02;
        break;
      default:
        minWidth = token.screenXXL - 0.02;
    }

    return `@media screen and (max-width: ${minWidth}px)`;
  }

  return {
    ...token,
    ...GREYS,
    screenUp,
    screenDown,
    prefixCls: confiContext.getPrefixCls(),
    boxShadowInset: `inset 0px 2px 1px ${token.controlOutline}`, // mainly for input
  };
}

interface ThemeProps {
  theme: ThemedToken;
}

export function createUseStyle(callback: (props: ThemeProps) => string): () => string;
export function createUseStyle<P>(callback: (props: P & ThemeProps) => string): (p: P) => string;
export function createUseStyle<P>(callback: (props: P & ThemeProps) => string): (p: P) => string {
  return (p: P) => {
    const theme = useThemeToken();

    return callback({ ...p, theme });
  };
}

export function css<P = undefined>(template: TemplateStringsArray, ...args: (CSSValue | ((p: P) => CSSValue))[]): (p?: P) => string {
  return (p) => {
    const formattedArgs = args.map((arg) => {
      if (typeof arg === "function") {
        return noFalsy(arg(p || ({} as P)));
      }

      return noFalsy(arg);
    });

    return emotionCss(template, ...formattedArgs);
  };
}

export default css;
