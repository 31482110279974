import React, { CSSProperties, HTMLAttributes, ReactNode } from "react";
import styled, { classnames } from "style/classname";
import "./heading.less";

export interface HeadingProps extends HTMLAttributes<HTMLDivElement> {
  tag?: "h1" | "h2" | "h3"; // | "h4"; // | "h5";
  weight?: CSSProperties["fontWeight"];
  margin?: "none";
  alignItems?: CSSProperties["alignItems"];
  wrapperClassName?: string;
  wrapperStyle?: CSSProperties;
  align?: "left" | "right" | "center";
  extraActions?: ReactNode;
  description?: ReactNode;
}

export default function Heading(props: HeadingProps) {
  const {
    tag = "h1",
    wrapperClassName,
    wrapperStyle,
    className,
    children,
    extraActions,
    weight = "bold",
    style,
    margin,
    align,
    alignItems = "center",
    description,
    ...rest
  } = props;

  return (
    <div className={classnames("heading", wrapperClassName, styledWrapper({ ...props, alignItems, weight }))} style={wrapperStyle} {...rest}>
      {React.createElement(
        tag,
        {
          className: classnames("heading-content", className),
          style,
        },
        children,
      )}
      {extraActions}
      {description && <div className="heading-description">{description}</div>}
    </div>
  );
}

const styledWrapper = styled<HeadingProps>`
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  min-height: 32px;
  row-gap: 8px;
  align-items: ${(props) => props.alignItems};
  ${(props) => props.align && `text-align: ${props.align};`}
  margin-top: ${(props) => (props.margin === "none" ? "0px" : "32px")};
  margin-bottom: ${(props) => (props.margin === "none" ? "0px" : "20px")};
  &:first-child {
    margin-top: 0;
  }

  .heading-content {
    margin: 0;
    ${(props) => props.weight && `font-weight: ${props.weight};`}
    flex: 1 2 auto;
    flex-wrap: wrap;
    display: flex;
    align-items: center;
    gap: 4px;
  }
  .heading-description {
    flex: 100%;
  }
`;
