import { loadTeamMemberList, TeamQuery, TeamMember, loadTeamMember } from "store/actions/teamMemberList";
import { useState, useEffect } from "react";
import { useShallowSelector } from ".";
import { emptyList } from "store/reducers/standardReducer";

export function useTeamMember(userId: string | undefined | null) {
  const id = userId && /^U-\w+/.test(userId) ? userId : "";

  useEffect(() => {
    if (id) {
      loadTeamMember(id);
    }
  }, [id]);

  return useShallowSelector((state) => {
    if (id && state.teamMembers.fetchStatus[id] === undefined) {
      loadTeamMember(id);
    }

    return {
      data: state.teamMembers.entities[id],
      status: state.teamMembers.fetchStatus[id],
      error: state.teamMembers.errors[id],
    };
  });
}

export function useTeamMembers(query: TeamQuery) {
  const [fetchId, setFetchId] = useState("");

  useEffect(() => {
    setFetchId(loadTeamMemberList(query));
  }, [query]);

  return useShallowSelector((state) => {
    if (fetchId && state.teamMemberList.fetchStatus[fetchId] === undefined) {
      loadTeamMemberList(query);
    }

    const data = state.teamMemberList.entities[fetchId];

    return {
      data: data
        ? {
            records: data.records.map((id) => state.teamMembers.entities[id]).filter((v) => v) as TeamMember[],
            meta: data.meta,
          }
        : emptyList,
      status: state.teamMemberList.fetchStatus[fetchId],
      error: state.teamMemberList.errors[fetchId],
    };
  });
}
