import { DatePicker as DatePickerAnt } from "antd";
import { PickerDateProps as Props } from "antd/lib/date-picker/generatePicker";
import Icon from "components/Icon";
import dayjs, { Dayjs } from "dayjs";
import React from "react";

function getDisabledDate(type: undefined | "past" | "future"): ((date: Dayjs) => boolean) | undefined {
  if (type) {
    return (date: Dayjs) => {
      if (type === "past") {
        return date.isAfter(dayjs(), "day");
      }
      if (type === "future") {
        return date.isBefore(dayjs(), "day");
      }

      return false;
    };
  }

  return undefined;
}

type DatePickerProps = Omit<Props<Dayjs>, "value" | "onChange"> & {
  type?: "past" | "future";
  value?: undefined | null | string | Dayjs;
  onChange?(value: string | undefined): void;
};

export default function DatePicker(props: DatePickerProps) {
  const { disabledDate, type, showToday, value, onChange, ...rest } = props;
  let date: undefined | Dayjs;
  if (value) {
    if (typeof value === "string") {
      if (value.includes("T00:00:00.000Z")) {
        date = dayjs(value.substring(0, 10));
      } else {
        date = dayjs(value);
      }
    } else {
      date = value.clone();
    }
  }

  return (
    <DatePickerAnt
      disabledDate={getDisabledDate(type)}
      showToday={showToday ?? !type}
      value={date}
      suffixIcon={<Icon type="calendar" fixedWidth={false} />}
      onChange={(v, dateStr) => {
        onChange?.(dateStr);
      }}
      {...rest}
    />
  );
}
