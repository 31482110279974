import { useEffect, useState } from "react";
import { loadBatch, Batch, BatchesQuery, loadBatches } from "store/actions/batches";
import { useShallowSelector } from ".";
import { emptyList } from "store/reducers/standardReducer";
import { computeID } from "store/actions/actionUtils";

export function useBatch(id: string | undefined) {
  const batchId = id && /^B-\w+/.test(id) ? id : "";

  useEffect(() => {
    if (batchId) {
      loadBatch(batchId);
    }
  }, [batchId]);

  return useShallowSelector((state) => {
    if (batchId && state.batch.fetchStatus[batchId] === undefined) {
      loadBatch(batchId);
    }

    return {
      data: state.batch.entities[batchId] as Batch | undefined,
      status: state.batch.fetchStatus[batchId],
      error: state.batch.errors[batchId],
    };
  });
}

export function useBatchList(query: BatchesQuery) {
  const [fetchId, setFetchId] = useState("");

  useEffect(() => {
    setFetchId(loadBatches(query));
  }, [computeID(query)]);

  return useShallowSelector((state) => {
    if (fetchId && state.batches.fetchStatus[fetchId] === undefined) {
      loadBatches(query);
    }

    const data = state.batches.entities[fetchId];

    return {
      data: data
        ? {
            records: data.records.map((id) => state.batch.entities[id]).filter((v) => v) as Batch[],
            meta: data.meta,
          }
        : emptyList,
      status: state.batches.fetchStatus[fetchId],
      error: state.batches.errors[fetchId],
    };
  });
}
