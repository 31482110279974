import { IconName } from "@trolley/common-frontend";
import { AlertMaintenance, Divider, Grid, Icon, Space, Text, Title } from "components";
import React, { PropsWithChildren } from "react";
import css, { createUseStyle } from "style/classname";
import colors from "style/colors";
import palette from "style/palette";
import { MEDIA_BREAKPOINTS } from "style/variables";
import PartnerIcon from "./PartnerIcon";
import DashboardScreenshot from "./dashboard.png";
import LogoEndToEnd from "./logo-end-to-end-security.png";
import ResponsiveCustomerSupport from "./logo-customer-support.png";
import LogoSoc2 from "./logo-soc2.png";
import LogoTransparentPayment from "./logo-transparent-payment.png";
import LogoTFA from "./logo-two-factor-authentication.png";
import config from "config";
import LanguageSelector from "features/LanguageSelector";
import { useIntl } from "utils/context";

export interface FullscreenAuthProps {
  size?: "large";
  partner?: string;
}

export default function FullscreenAuth(props: PropsWithChildren<FullscreenAuthProps>) {
  const { partner, children, size } = props;
  const authWrapper = useAuthWrapper();
  const { formatMessage } = useIntl();

  return (
    <div className={authWrapper}>
      <section className="auth-content-wrapper">
        <PartnerIcon partner={partner} title={formatMessage({ id: "auth.fullScreenAuth.partnerIconTitle" })} />
        <div className="auth-content" style={{ maxWidth: size === "large" ? "480px" : "280px" }}>
          <AlertMaintenance showIcon type="info" applyTranslation />
          {children}
        </div>
        {/* TODO: discuss to remove it or put it in other place when, only for testing in dev and locally for now */}
        {config.ENV !== "production" && config.ENV !== "staging" && (
          <div style={{ width: "100%" }}>
            <div style={{ maxWidth: 150 }}>
              <Divider transparent margin="large" />
              <LanguageSelector />
            </div>
          </div>
        )}
      </section>
      <section className="auth-presentation">
        <div className="ellipse-1" />
        <div className="ellipse-2" />
        <div className="ellipse-3" />
        <Title style={{ fontSize: "32px", lineHeight: 1, width: "550px", marginTop: "32px", marginBottom: "40px" }}>
          {formatMessage({ id: "auth.fullScreenAuth.title" })}
        </Title>
        <Grid className="screenshot" wrap={false} padding="none" style={{ gap: "40px" }}>
          <Grid.Item flex="0 0 450px">
            <Space direction="column" justify="space-between" align="stretch" gap="middle">
              {[
                [
                  formatMessage({ id: "auth.fullScreenAuth.features.onboard.title" }),
                  formatMessage({ id: "auth.fullScreenAuth.features.onboard.description" }),
                ],
                [formatMessage({ id: "auth.fullScreenAuth.features.pay.title" }), formatMessage({ id: "auth.fullScreenAuth.features.pay.description" })],
                [formatMessage({ id: "auth.fullScreenAuth.features.tax.title" }), formatMessage({ id: "auth.fullScreenAuth.features.tax.description" })],
                [formatMessage({ id: "auth.fullScreenAuth.features.sync.title" }), formatMessage({ id: "auth.fullScreenAuth.features.sync.description" })],
                [formatMessage({ id: "auth.fullScreenAuth.features.api.title" }), formatMessage({ id: "auth.fullScreenAuth.features.api.description" })],
              ].map(([title, description], index) => (
                <div key={title} className="feature">
                  <Text weight="bold" size="xlarge">
                    <Icon type={`circle-${index + 1}` as IconName} theme="solid" color="blue" />
                    {title}
                  </Text>
                  <Text type="secondary" className="description" style={{ lineHeight: "18px" }}>
                    {description}
                  </Text>
                </div>
              ))}
            </Space>
          </Grid.Item>
          <Grid.Item className={styledScreenshot({ image: DashboardScreenshot })} />
        </Grid>
        <Space wrap={false} className={styledLogo} align="start" justify="space-between">
          {[
            {
              img: LogoSoc2,
              label: formatMessage({ id: "auth.fullScreenAuth.logos.soc2" }),
            },
            {
              img: LogoEndToEnd,
              label: formatMessage({ id: "auth.fullScreenAuth.logos.endToEnd" }),
            },
            {
              img: LogoTFA,
              label: formatMessage({ id: "auth.fullScreenAuth.logos.tfa" }),
            },
            {
              img: ResponsiveCustomerSupport,
              label: formatMessage({ id: "auth.fullScreenAuth.logos.customerSupport" }),
            },
            {
              img: LogoTransparentPayment,
              label: formatMessage({ id: "auth.fullScreenAuth.logos.transparentPayment" }),
            },
          ].map(({ img, label }) => (
            <Text size="small" type="secondary" style={{ lineHeight: "14px" }} align="center" key={label}>
              <img src={img} alt={label} />
              {label}
            </Text>
          ))}
        </Space>
      </section>
    </div>
  );
}

const useAuthWrapper = createUseStyle(({ theme }) =>
  css`
    width: 100%;
    min-height: 100%;
    background-color: white;
    display: flex;
    flex-wrap: nowrap;
    align-items: stretch;
    .auth-content-wrapper {
      position: relative;
      padding: 40px;
      padding-top: 100px;
      display: flex;
      flex-direction: column;
      flex: 4 1 600px;
      align-self: stretch;
      justify-content: center;
      align-items: center;
      .auth-content {
        min-width: 300px;
        margin: 16px auto;
      }
      /* ${MEDIA_BREAKPOINTS.lgUp} {
        flex-grow: 0;
      } */
    }
    .auth-presentation {
      position: relative;
      overflow: hidden;
      background-color: ${theme.colorWhite};
      display: none;
      ${MEDIA_BREAKPOINTS.lgUp} {
        display: flex;
        flex: 5 1 752px;
        padding-left: 32px;
        flex-direction: column;
        align-items: bottom;
        justify-content: center;
      }
      ${MEDIA_BREAKPOINTS.xlUp} {
        flex-basis: 800px;
        padding-left: 80px;
      }
    }
    .feature {
      padding: ${theme.paddingMD}px;
      border-radius: ${theme.borderRadius}px;
      background-color: ${palette["white-alpha-8"]};
      .icon {
        margin-right: ${theme.paddingSM}px;
      }
      .description {
        margin-left: ${theme.paddingSM + 22}px; // 22 is the width of the icon typically
      }
    }

    .ellipse-1 {
      position: absolute;
      height: 720px;
      width: 720px;
      right: -490px;
      top: -181px;
      border-radius: 0px;
      background: ${colors.blue};
      filter: blur(300px);
    }
    .ellipse-2 {
      position: absolute;
      height: 400px;
      width: 400px;
      left: 33%;
      top: -21px;
      background: ${colors.purple};
      border-radius: 0px;
      opacity: 0.2;
      filter: blur(200px);
    }

    .ellipse-3 {
      width: 640px;
      height: 640px;
      position: absolute;
      left: -294px;
      top: 744px;
      /* colors/purple/500 */

      background: ${colors.purple};
      opacity: 0.2;
      filter: blur(200px);
    }
  `(),
);

const styledScreenshot = css<{ image: string }>`
  flex: 1 1 230px;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  background-image: url(${(props) => props.image});
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: left;
`;

const styledLogo = css`
  margin-top: 32px;
  width: 100%;
  max-width: 640px;
  & > * {
    width: 108px;
  }
  img {
    display: block;
    margin: 0 auto 8px;
    height: 40px;
  }
`();
