import { Divider as AntDivider, DividerProps } from "antd";
import { getAlphaColor } from "antd/lib/theme/themes/default/colorAlgorithm";
import React from "react";
import css, { classnames, createUseStyle } from "style/classname";

interface Props extends DividerProps {
  margin?: "xsmall" | "small" | "medium" | "large";
  transparent?: boolean;
  inverse?: boolean;
}

export default function Divider(props: Props) {
  const { className, transparent, inverse, ...rest } = props;
  const styledDivider = useStyledDivider(props);

  return <AntDivider className={classnames(styledDivider, className)} {...rest} />;
}

const useStyledDivider = createUseStyle<Props>(({ theme, ...props }) =>
  css`
    &.${theme.prefixCls}-divider {
      ${() => {
        if (props.transparent) {
          if (props.children) {
            return `
              &::before, &::after {
                opacity: 0;
              }
            `;
          }

          return `border-color: transparent;`;
        }

        return props.inverse ? `border-color: ${getAlphaColor(theme.colorWhite, 0.3)};` : ``;
      }};
      margin: ${() => {
        switch (props.margin) {
          case "xsmall":
            return "4px 0";
          case "small":
            return "8px 0";
          case "medium":
            return "16px 0";
          case "large":
            return "24px 0";
          default:
            return "0";
        }
      }};
    }
  `(),
);
