import { Button, Modal } from "components";
import React from "react";
import { notifyError, notifySuccess } from "store/actions/notifications";
import { cancelPayments, Payment } from "store/actions/payments";
import { PaymentStatus } from "@trolley/common-frontend";
import RecordCount from "../../components/RecordCount";

interface Props {
  payments: Payment[];
  onComplete(): void;
}

export default function ButtonCancelPayment(props: Props) {
  const { payments, onComplete } = props;

  const cancellable = payments.filter((p) => p.status === PaymentStatus.PROCESSING);

  if (cancellable.length) {
    return (
      <Button
        danger
        onClick={() => {
          Modal.confirm({
            title: "Cancel selected payments?",
            content: "Cancelling a payment will fail the payment.",
            type: "danger",
            okText: "Yes, Cancel Selected Payments",
            onOk: async () => {
              try {
                await cancelPayments(cancellable.map((p) => p.id));
                notifySuccess("Payments cancelled");
                onComplete();
              } catch (errors) {
                notifyError("Cancelling payments failed", { errors });
              }
            },
          });
        }}
      >
        <RecordCount value={cancellable.length} one="Cancel # Payment" other="Cancel # Payments" />
      </Button>
    );
  }

  return null;
}
