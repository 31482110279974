import { formatCountry, getRegionLabel } from "@trolley/common-frontend";
import { Box, FormItem } from "components";
import React from "react";
import { W9Form } from "store/actions/taxForms";
import { translateRegionByCountry } from "utils/helpers";
import { TAX_ENTITY_TYPE_LABELS } from "./variables";

type Props = {
  data: W9Form;
};

export default function W9Summary({ data }: Props) {
  let showEIN = false;
  if (data.taxType) {
    if (
      (["trust", "sole_prop", "llc_single"].includes(data.taxType) && data.idType === "tin") ||
      ["llc_c_corp", "llc_s_corp", "llc_partner", "c_corp", "s_corp", "partnership"].includes(data.taxType || "")
    ) {
      showEIN = true;
    }
  }

  return (
    <>
      <Box padding="small" header="Taxpayer Identification Information">
        <FormItem label="Subject To Withholding">{data.subjectToWithholding ? "Yes" : "No"}</FormItem>

        <FormItem label="Name">{data.name || [data.firstName, data.lastName].join(" ").trim() || " - "}</FormItem>
        {data.businessName && <FormItem label="Business Name">{data.businessName}</FormItem>}
      </Box>

      <Box padding="small" header="Taxpayer Address Information">
        <FormItem label="Country">{formatCountry(data.country) || " - "}</FormItem>
        <FormItem label="Address">{data.address}</FormItem>
        <FormItem label={translateRegionByCountry(data.country)}>{getRegionLabel(data.state, data.country) || " - "}</FormItem>
        <FormItem label="City">{data.city}</FormItem>
        <FormItem label="Zip">{data.zip}</FormItem>
      </Box>

      {(data.exemptReporting || data.exemptPayee) && (
        <Box padding="small" header="Exemptions">
          <FormItem label="Exempt payee code">{data.exemptPayee}</FormItem>
          <FormItem label="Exemptions from FATCA reporting code">{data.exemptReporting}</FormItem>
        </Box>
      )}

      <Box padding="small" header="Taxpayer Identification Number">
        <FormItem label="Federal Tax Classification">{(data.taxType && TAX_ENTITY_TYPE_LABELS[data.taxType]) ?? " - "}</FormItem>
        <FormItem label={showEIN ? "EIN" : "SSN"}>{data.taxId}</FormItem>
      </Box>
    </>
  );
}
