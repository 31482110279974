import { useEffect } from "react";
import { useShallowSelector } from ".";
import { loadTaxMerchantYear, TaxMerchantYear } from "store/actions/taxMerchantYear";

export function useMerchantTaxYear(year: number | undefined) {
  const id = year && /^\d{4}$/.test(String(year)) ? year : "";

  useEffect(() => {
    if (id) {
      loadTaxMerchantYear(id);
    }
  }, [year]);

  return useShallowSelector((state) => {
    if (id && state.taxMerchantYear.fetchStatus[id] === undefined) {
      loadTaxMerchantYear(id);
    }

    return {
      data: state.taxMerchantYear.entities[id] as TaxMerchantYear | undefined,
      status: state.taxMerchantYear.fetchStatus[id],
      error: state.taxMerchantYear.errors[id],
    };
  });
}
