import React, { ReactNode } from "react";
import { useInvoicePayments } from "store/hooks/invoicePayments";

interface Props {
  id: string; // paymentId or invoiceId
  children(data: ReturnType<typeof useInvoicePayments>): ReactNode;
}
export default function InvoicePaymentsLoader(props: Props) {
  const { id } = props;
  const invoicePayments = useInvoicePayments(id);

  return <>{props.children(invoicePayments)}</>;
}
