import { CountryCode, formatCountry, getRegionLabel } from "@trolley/common-frontend";

export default function stringifyAddress(
  addr:
    | Partial<{
        address: string | null | undefined;
        street1: string | null | undefined;
        street2: string | null | undefined;
        bankAddress: string | null | undefined;

        city: string | null | undefined;
        residenceCity: string | null | undefined;
        bankCity: string | null | undefined;

        state: string | null | undefined; // in W9 forms
        region: string | null | undefined;
        regionCode: string | null | undefined;
        residenceRegion: string | null | undefined;
        bankRegionCode: string | null | undefined;
        bankRegion: string | null | undefined;

        zip: string | null | undefined; // in W9 forms
        postalCode: string | null | undefined;
        residencePostalCode: string | null | undefined;
        bankPostalCode: string | null | undefined;

        country: CountryCode | string | null | undefined;
        countryCode: CountryCode | string | null | undefined;
        residenceCountry: string | null | undefined;
        bankCountry: string | null | undefined;
        bankCountryCode: string | null | undefined;
      }>
    | undefined,
  detailed?: boolean,
) {
  if (!addr) {
    return "";
  }

  const address = addr.address || addr.street1 || addr.bankAddress;
  const city = addr.city || addr.residenceCity || addr.bankCity;
  const region = addr.state || addr.region || addr.regionCode || addr.residenceRegion || addr.bankRegionCode || addr.bankRegion;
  const postalCode = addr.zip || addr.postalCode || addr.residencePostalCode || addr.bankPostalCode;
  const country = addr.country || addr.countryCode || addr.residenceCountry || addr.bankCountry || addr.bankCountryCode;

  if (detailed) {
    return (
      [[address, addr.street2].filter((s) => !!s).join(" "), city, getRegionLabel(region, country), postalCode, formatCountry(country)]
        .filter((v) => !!v)
        .join(", ")
        .trim() || " - "
    );
  }

  return (
    [city, country === CountryCode.US || country === CountryCode.CA ? getRegionLabel(region, country) : "", country === "US" ? "USA" : formatCountry(country)]
      .filter((v) => !!v)
      .join(", ")
      .trim() || " - "
  );
}
