import { isIrsValidChars, isLatin, TaxEntityTypeUS } from "@trolley/common-frontend";

export const latinRule = { pattern: isLatin, message: "Only characters A-Z and 0-9 are allowed" };
export const irsRule = { pattern: isIrsValidChars, message: "Only special characters &,'/#.- are allowed" };

export const TAX_ENTITY_TYPE_LABELS: Record<TaxEntityTypeUS, string> = {
  [TaxEntityTypeUS.INDIVIDUAL]: "Individual",
  [TaxEntityTypeUS.SOLE_PROP]: "Sole Proprietor",
  [TaxEntityTypeUS.LLC_SINGLE]: "LLC - Single Member",
  [TaxEntityTypeUS.LLC_C_CORP]: "LLC - C Corporation",
  [TaxEntityTypeUS.LLC_S_CORP]: "LLC - S Corporation",
  [TaxEntityTypeUS.LLC_PARTNER]: "LLC Partnership",
  [TaxEntityTypeUS.C_CORP]: "C Corporation",
  [TaxEntityTypeUS.S_CORP]: "S Corporation",
  [TaxEntityTypeUS.PARTNERSHIP]: "Partnership",
  [TaxEntityTypeUS.TRUST]: "Trust",
  [TaxEntityTypeUS.OTHER]: "Other",
};

export const taxTypeLabelArray = Object.entries(TAX_ENTITY_TYPE_LABELS) as [TaxEntityTypeUS, string][];

export const latinRuleIgnoreStar = {
  validator: (rule: any, value: string, cb: (errorMsg: string | undefined) => void) => {
    cb(value && !value.includes("*") && !isLatin.test(value) ? latinRule.message : undefined);
  },
};
