import { CountryCode, countryHasPostalCode, formatCountry } from "@trolley/common-frontend";
import { Divider, Form, Grid, Heading, Input, InputDate, InputPhone, InputNumber, phoneNumberValidator, Select, SelectCountry, SelectRegion } from "components";
import { isRegionInCountryAsyncValidator } from "pages/RecipientsPage/Details/Info";
import React from "react";
import { Onboarding } from "store/actions/onboarding";
import { useOnboarding } from "store/hooks/onboarding";
import { capitalize, isEmpty, translateRegionByCountry } from "utils/helpers";
import validator from "validator";
import { FormNames, onFinishFailedOnboarding, onFinishOnboarding } from ".";
import OnboardingSection from "./OnboardingSection";

import { getBusinessTypes } from "./variables";
import { validatePastDate } from "components/Form/InputDate";

export function getTaxIdLabel(businessCountry: CountryCode | null | undefined): string {
  return businessCountry === CountryCode.US ? "Tax Identification Number" : "Business Registration Number";
}

export function getCompanyDetailsCompletion(onboarding: Onboarding | undefined): null | "partial" | "completed" {
  if (!onboarding) {
    return null;
  }

  const requiredFields: (keyof Onboarding)[] = [
    "businessCountry",
    "businessType",
    "businessLegalName",
    "businessAsName",
    "businessTaxId",
    "businessPhone",
    "businessWebsite",
    "businessEmail",
    "businessDateFormed",
    "businessAddress",
    "businessCity",
    "businessRegion",
  ];

  if (onboarding.businessCountry && countryHasPostalCode(onboarding.businessCountry)) {
    requiredFields.push("businessZip");
  }
  if (onboarding.businessCountry === CountryCode.GB) {
    requiredFields.push("employeeCount");
    requiredFields.push("turnover");
  }

  if (requiredFields.every((field) => isEmpty(onboarding[field]))) {
    return null;
  }

  if (requiredFields.every((field) => !isEmpty(onboarding[field]))) {
    return "completed";
  }

  return "partial";
}

export default function CompanyDetails() {
  const { data: onboarding, status: onboardingStatus } = useOnboarding();
  const [form] = Form.useForm();
  const businessCountry = Form.useWatch("businessCountry", form);
  const businessType = Form.useWatch("businessType", form);
  const businessTypes = getBusinessTypes(businessCountry);

  if (!onboarding) {
    return null;
  }

  return (
    <OnboardingSection title="Company Details" description="Please enter your company registration details as they appear in official records.">
      <Form
        form={form}
        name={FormNames.COMPANY_DETAILS}
        initialValues={onboarding}
        onFinish={onFinishOnboarding(onboarding, form)}
        onFinishFailed={onFinishFailedOnboarding(onboarding, form)}
      >
        <Grid padding={["medium", "none"]}>
          <Grid.Item xs={24} lg={12}>
            <Form.Item
              name="businessCountry"
              label="Business Registration Country"
              rules={[{ required: true, message: "Select the registration country of your business" }]}
            >
              <SelectCountry type="onboarding" style={{ width: "100%" }} defaultActiveFirstOption={false} />
            </Form.Item>
          </Grid.Item>
          <Grid.Item xs={24} lg={12}>
            <Form.Item
              name="businessType"
              label="Business Structure"
              dependencies={["businessCountry"]}
              rules={[
                {
                  required: true,
                  message: "Select a business structure type",
                },
                (form) => ({
                  async validator(rule, value) {
                    const businessCountry = form.getFieldValue("businessCountry");
                    if (value && businessCountry) {
                      if (!businessTypes.some((type) => type.value === businessType)) {
                        throw `${capitalize(value.replace(/_/g, " "))} does not exist in ${formatCountry(businessCountry)}`;
                      }
                    }
                  },
                }),
              ]}
            >
              <Select
                showSearch
                style={{ width: "100%" }}
                value={businessType}
                placeholder="Select a business type"
                defaultActiveFirstOption={false}
                optionFilterProp="children"
              >
                {businessTypes.map((type: any) => (
                  <Select.Option key={type.value} value={type.value}>
                    {type.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Grid.Item>
        </Grid>

        <Grid padding={["medium", "none"]}>
          <Grid.Item xs={24} md={12}>
            <Form.Item label="Business Name" name="businessLegalName" rules={[{ required: true, message: "Business Name is required" }]}>
              <Input name="businessLegalName" />
            </Form.Item>
          </Grid.Item>
          <Grid.Item xs={24} md={12}>
            <Form.Item label={'"Doing Business As" Name'} name="businessAsName" rules={[{ required: true, message: '"Doing Business As" name is required' }]}>
              <Input name="businessAsName" />
            </Form.Item>
          </Grid.Item>
          <Grid.Item xs={24} md={12}>
            <Form.Item
              label={getTaxIdLabel(businessCountry)}
              name="businessTaxId"
              rules={[
                {
                  required: true,
                  message: `${getTaxIdLabel(businessCountry)} is required`,
                },
                {
                  min: businessCountry !== CountryCode.US ? 3 : 0,
                  message: `${getTaxIdLabel(businessCountry)} must be at least 3 digits long`,
                },
                {
                  max: 40,
                  message: `${getTaxIdLabel(businessCountry)} must be shorter than 40 digits long`,
                },
              ]}
            >
              <Input name="businessTaxId" />
            </Form.Item>
          </Grid.Item>
          <Grid.Item xs={24} md={12}>
            <Form.Item
              label="Business Phone Number"
              name="businessPhone"
              rules={[
                {
                  required: true,
                  message: "Business phone number is required",
                },
                phoneNumberValidator(),
              ]}
            >
              <InputPhone name="businessPhone" defaultCountry={businessCountry} />
            </Form.Item>
          </Grid.Item>

          <Grid.Item xs={24} md={12}>
            <Form.Item
              label="What is your business URL?"
              name="businessWebsite"
              normalize={(v) => v ?? ""}
              rules={[
                {
                  required: true,
                  message: "Website is required",
                },
                {
                  async validator(rule, value: string) {
                    if (value && !validator.isURL(value)) {
                      throw "Enter a valid website URL";
                    }
                  },
                },
              ]}
            >
              <Input name="businessWebsite" />
            </Form.Item>
          </Grid.Item>
          <Grid.Item xs={24} md={12}>
            <Form.Item
              label="Number Of Employees"
              name="employeeCount"
              rules={[{ required: businessCountry === CountryCode.GB, message: "Number Of Employees is required" }]}
            >
              <InputNumber inputMode="numeric" maxLength={11} />
            </Form.Item>
          </Grid.Item>
          <Grid.Item xs={24} md={12}>
            <Form.Item label="Turnover" name="turnover" rules={[{ required: businessCountry === CountryCode.GB, message: "Turnover is required" }]}>
              <InputNumber inputMode="decimal" maxLength={100} addonAfter="USD" />
            </Form.Item>
          </Grid.Item>

          <Grid.Item xs={24} md={12}>
            <Form.Item
              label="Business Email Address"
              name="businessEmail"
              rules={[
                { required: true, message: "Email Address is required" },
                { type: "email", message: "Enter a valid email address" },
              ]}
            >
              <Input name="email" type="email" />
            </Form.Item>
          </Grid.Item>

          <Grid.Item xs={24}>
            <Form.Item label="Date Formed" name="businessDateFormed" rules={[{ required: true, message: "Enter a Date Formed" }, validatePastDate]}>
              <InputDate />
            </Form.Item>
          </Grid.Item>

          {!!businessCountry && (
            <>
              <Grid.Item xs={24}>
                <Divider margin="medium" />
                <Heading tag="h3">Business Registered Address</Heading>
              </Grid.Item>
              <Grid.Item xs={24} md={12}>
                <Form.Item label="Street" name="businessAddress" rules={[{ required: true, message: "Address is required" }]}>
                  <Input name="businessAddress" />
                </Form.Item>
              </Grid.Item>
              <Grid.Item xs={24} md={12}>
                <Form.Item label="City" name="businessCity" rules={[{ required: true, message: "City is required" }]}>
                  <Input name="businessCity" />
                </Form.Item>
              </Grid.Item>
              <Grid.Item xs={24} md={12}>
                <Form.Item
                  label={translateRegionByCountry(businessCountry)}
                  name="businessRegion"
                  validateTrigger={["onChange", "onBlur"]}
                  rules={[
                    { required: true, message: `${translateRegionByCountry(businessCountry)} is required` },
                    isRegionInCountryAsyncValidator(["businessCountry"]),
                  ]}
                >
                  <SelectRegion country={businessCountry} />
                </Form.Item>
              </Grid.Item>
              <Grid.Item xs={24} md={12}>
                <Form.Item
                  label={businessCountry === CountryCode.US ? "ZIP Code" : "Postal Code"}
                  name="businessZip"
                  dependencies={["businessCountry"]}
                  rules={[
                    (form) => ({
                      required: countryHasPostalCode(form.getFieldValue("businessCountry")),
                      message: "Postal Code is required",
                    }),
                  ]}
                >
                  <Input name="businessZip" placeholder="Postal Code" />
                </Form.Item>
              </Grid.Item>
            </>
          )}
        </Grid>
        <Form.SubmitButtons status={onboardingStatus} saveText={onboarding?.completedAt ? "Save" : "Save & Continue"} allowReset />
      </Form>
    </OnboardingSection>
  );
}
