import { Result } from "antd";
import { Button, Form, FormItem, Icon, Input, Modal, Text } from "components";
import config from "config";
import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import { salesforceBoot, salesforceReset } from "services/thirdParty/salesforce";
import { notifyError } from "store/actions/notifications";
import { useMerchantSettings } from "store/hooks/merchantSettings";
import { useUser } from "store/hooks/user";
import "./salesforce.less";

export default function SalesforceChat() {
  const { data: user } = useUser();
  const { data: merchantSettings } = useMerchantSettings();
  const [salesforceBooted, setSalesforceBooted] = useState(false);
  const [renderCase, setRenderCase] = useState(false);
  const sfButton = useRef<Element | undefined>();

  const handleRenderCase = useCallback(() => {
    const agentAvailable = window.embedded_svc.settings.agentAvailableOnButtonClick;
    if (!agentAvailable) {
      setRenderCase(true);
    }
  }, [setRenderCase]);

  useLayoutEffect(() => {
    let buttonLookupInterval: number;

    if (!salesforceBooted && user && merchantSettings && config.SALESFORCE) {
      salesforceBoot(user, merchantSettings)
        .then((booted) => {
          setSalesforceBooted(booted);

          if (booted) {
            // Look for salesforce button
            buttonLookupInterval = window.setInterval(() => {
              const chatButton = document.getElementsByClassName("uiButton")[0];
              sfButton.current = chatButton;

              if (chatButton) {
                chatButton.addEventListener("click", handleRenderCase);
                clearInterval(buttonLookupInterval);
              }
            }, 1000);
          }
        })
        .catch(() => {
          //
        });
    }

    return () => {
      if (typeof buttonLookupInterval === "number") {
        clearInterval(buttonLookupInterval);
      }
    };
  }, [merchantSettings?.id]);

  useEffect(() => {
    return () => {
      salesforceReset(sfButton.current);
      setSalesforceBooted(false);

      if (handleRenderCase) {
        sfButton.current?.removeEventListener("click", handleRenderCase);
      }
    };
  }, []);

  return <WebToCaseForm visible={renderCase} onClose={() => setRenderCase(false)} />;
}

export function WebToCaseForm({ visible, ...rest }: { visible: boolean; onClose(): void }) {
  const [form] = Form.useForm();
  const { data: user } = useUser();
  const [status, setStatus] = useState<"submitted" | "unsubmitted">("unsubmitted");

  function onClose() {
    setStatus("unsubmitted");
    form.resetFields();
    rest.onClose();
  }

  async function onSubmit(values: any) {
    if (user && status === "unsubmitted" && config.SALESFORCE) {
      try {
        // This is done because Salesforce throws CORS errors when POSTing through a standard fetch (they don't provide CORS errors)
        // https://www.adminbooster.com/blog/hacking_web2lead_web2case
        // https://salesforce.stackexchange.com/questions/31922/how-do-i-use-javascript-or-jquery-externally-to-send-data-to-salesforce-leads

        const htmlForm = document.createElement("form");

        htmlForm.method = "POST";
        htmlForm.action = "https://webto.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8";
        htmlForm.target = "salesforce";

        htmlForm.appendChild(createHiddenInput("orgid", config.SALESFORCE.id));
        htmlForm.appendChild(createHiddenInput("recordType", config.SALESFORCE.recordType, { id: "recordType" }));
        htmlForm.appendChild(createHiddenInput("name", user?.name));
        htmlForm.appendChild(createHiddenInput("email", user?.email));
        htmlForm.appendChild(createHiddenInput("subject", values.subject));
        htmlForm.appendChild(createHiddenInput("description", values.description));

        document.body.appendChild(htmlForm);

        htmlForm.submit();

        htmlForm.remove();

        setStatus("submitted");
      } catch {
        notifyError("Failed to submit the case");
      }
    }
  }

  return (
    <>
      <Modal
        visible={visible}
        onCancel={onClose}
        footer={
          <>
            <Button onClick={onClose}>Close</Button>
            {status !== "submitted" && (
              <Button type="primary" onClick={form.submit}>
                Submit Case
              </Button>
            )}
          </>
        }
        title={status === "submitted" ? "Case submitted" : "Open a support case"}
      >
        {status === "submitted" ? (
          <Result
            icon={<Icon type="message-check" theme="solid" size="4x" color="green" />}
            title="Your case has been submitted"
            subTitle="A member of our support team will get back to you as soon as possible."
          />
        ) : (
          <Form layout="vertical" form={form} onFinish={onSubmit}>
            <Text padded type="secondary">
              Our support team is not online at the moment. Let us know what the problem is and we'll write you back as soon as we're online.
            </Text>
            <FormItem name="subject" label="Subject" rules={[{ required: true, message: "Enter a subject" }]}>
              <Input />
            </FormItem>
            <FormItem name="description" label="Description" rules={[{ required: true, message: "Enter a description" }]}>
              <Input.TextArea autoSize={{ minRows: 4 }} />
            </FormItem>
          </Form>
        )}
      </Modal>
      {/* This is used to catch the htmlForm submission (htmlForm.target = "salesforce"),
      to avoid changing pages. Refer to comment in onSubmit for why we do this */}
      <iframe title="salesforce" id="salesforce" name="salesforce" src="about:blank" style={{ display: "none " }} />
    </>
  );
}

function createHiddenInput(name: string, value: string, attrs?: Record<string, string>) {
  const input = document.createElement("input");
  input.name = name;
  input.value = value;
  input.setAttribute("type", "hidden");
  if (attrs) {
    Object.entries(attrs).forEach(([key, value]) => {
      input.setAttribute(key, value);
    });
  }

  return input;
}
