/* istanbul ignore file */
import "@formatjs/intl-locale/polyfill";
import "@formatjs/intl-relativetimeformat/polyfill";

import { getSupportedLocaleCode, SupportedLocales } from "@trolley/common-frontend";
import * as enCommonMessages from "@trolley/common-frontend/lib/locale/en";
import * as frCommonMessages from "@trolley/common-frontend/lib/locale/fr";
import { ConfigProvider } from "antd";
import { Locale } from "antd/lib/locale";
import enUS from "antd/locale/en_US";
import dayjs from "dayjs";
import flatten from "flat";
import Intl from "intl";
import PluralRules from "intl-pluralrules";
import React, { ReactNode, useEffect, useState } from "react";
import { IntlFormatters, IntlProvider, IntlShape, MessageDescriptor, useIntl as useReactIntl } from "react-intl";

import enMessages from "locale/en";
import IntlMessages from "locale/enkeys";
import frMessages from "locale/fr";
import Helmet from "react-helmet";
import { changeLocale } from "store/actions/auth";
import { useLocale } from "store/hooks/locale";
// import config from "config";
import { useLocation } from "react-router-dom";

export { IntlFormatters, IntlShape };

/**
 * Plurialization Message Formatting api with plural category: zero, one, two, few, many, other
 * https://formatjs.io/docs/core-concepts/icu-syntax/#plural-format
 *
 * Cardinal Plural Rules for each locale:
 * https://unicode-org.github.io/cldr-staging/charts/latest/supplemental/language_plural_rules.html
 *
 * */

export const messages: Partial<Record<SupportedLocales, Record<string, string>>> = {
  en: {
    ...flatten(enMessages),
    ...flatten(enCommonMessages),
  },
  fr: {
    ...flatten(frMessages),
    ...flatten(frCommonMessages),
  },
};

export type Translator = (desc: { id: IntlMessageKeys } & Omit<MessageDescriptor, "id">, values?: Record<string, any>) => string; // samish as IntlShape["formatMessage"] but typed with our keys...
// but probably should make as robust as typeFormatMessage

export type IntlMessageKeys = keyof IntlMessages;

type FormatMessageArgs = Parameters<IntlFormatters["formatMessage"]>;

type MessageValues = Record<string, any>;

export type FormatMessage = ReturnType<typeof typedFormatMessage>;

type OmitCommonElements<T> = Omit<T, "b">;

function typedFormatMessage(formatMessage: IntlShape["formatMessage"]) {
  return function typedFormatMessage<K extends IntlMessageKeys>(
    descriptor: FormatMessageArgs[0] & {
      id: K; // IntlMessageKeys
    },
    values?: IntlMessages[K] extends MessageValues // typed if key is known, otherwise accept anything
      ? IntlMessages[K] extends null
        ? never
        : OmitCommonElements<Record<keyof IntlMessages[K], React.ReactNode | ((content: string) => any)>>
      : any,
    opt?: FormatMessageArgs[2],
  ) {
    return formatMessage(descriptor, { b, ...values } as FormatMessageArgs[1], opt as FormatMessageArgs[2]) as string;
  };
}

export function useIntl() {
  const { formatMessage, ...rest } = useReactIntl();

  return {
    ...rest,
    formatMessage: typedFormatMessage(formatMessage),
  };
}

export async function initLocale(rawLocale: string = "en") {
  if (!global.Intl) {
    // Intl polyfill
    global.Intl = Intl;
  }

  if (global.Intl && !(global.Intl as any).PluralRules) {
    // PluralRules polyfill
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/PluralRules
    (global.Intl as any).PluralRules = PluralRules;
  }

  const locale = getSupportedLocaleCode(rawLocale) || SupportedLocales.EN;
  const [macro] = locale.split("-");

  // DAYJS locale import
  try {
    const dayJsLocale = locale.toLocaleLowerCase();
    if (dayJsLocale !== SupportedLocales.EN) {
      await import(`dayjs/locale/${dayJsLocale}.js`);
    }
    dayjs.locale(dayJsLocale);
  } catch (errors) {
    try {
      await import(`dayjs/locale/${macro}.js`);
      dayjs.locale(macro);
    } catch (subErrors) {
      // eslint-disable-next-line no-console
      console.warn(subErrors);
    }
  }

  try {
    await import(`@formatjs/intl-relativetimeformat/locale-data/${locale}.js`);
  } catch (errors) {
    try {
      await import(`@formatjs/intl-relativetimeformat/locale-data/${macro}.js`);
    } catch (subErrors) {
      // eslint-disable-next-line no-console
      console.warn(subErrors);
    }
  }

  let combinedMessages: any;
  let commonLocale: any;

  try {
    combinedMessages = (await import(`locale/${locale}`))?.default;
  } catch (errors) {
    try {
      combinedMessages = (await import(`locale/${macro}`))?.default;
    } catch (subErrors) {
      // eslint-disable-next-line no-console
      console.warn(`Cannot find widget locale 'locale/${macro}'`);
    }
  }

  try {
    commonLocale = await import(`@trolley/common-frontend/lib/locale/${locale}/index.js`);
  } catch (errors) {
    try {
      commonLocale = await import(`@trolley/common-frontend/lib/locale/${macro}/index.js`);
    } catch (subErrors) {
      // eslint-disable-next-line no-console
      console.warn(`Cannot find common locale 'locale/${macro}/index.js'`);
    }
  }

  messages[locale] = {
    ...messages.en,
    ...flatten(combinedMessages),
    ...flatten(commonLocale),
  };
}

type Props = { children: React.ReactNode };

// Supporting actually only FR but it needs to declare other supported declared in common-frontend
const ANT_LOCALES: Record<SupportedLocales, () => Promise<Locale>> = {
  [SupportedLocales.BG]: async () => (await import("antd/locale/bg_BG")).default,
  [SupportedLocales.BN]: async () => (await import("antd/locale/bn_BD")).default,
  [SupportedLocales.CS]: async () => (await import("antd/locale/cs_CZ")).default,
  [SupportedLocales.DA]: async () => (await import("antd/locale/da_DK")).default,
  [SupportedLocales.DE]: async () => (await import("antd/locale/de_DE")).default,
  [SupportedLocales.EL]: async () => (await import("antd/locale/el_GR")).default,
  [SupportedLocales.EN]: async () => (await import("antd/locale/en_US")).default,
  [SupportedLocales.ES]: async () => (await import("antd/locale/es_ES")).default,
  [SupportedLocales.FI]: async () => (await import("antd/locale/fi_FI")).default,
  [SupportedLocales.FR]: async () => (await import("antd/locale/fr_FR")).default,
  [SupportedLocales.HR]: async () => (await import("antd/locale/hr_HR")).default,
  [SupportedLocales.HU]: async () => (await import("antd/locale/hu_HU")).default,
  [SupportedLocales.ID]: async () => (await import("antd/locale/id_ID")).default,
  [SupportedLocales.IT]: async () => (await import("antd/locale/it_IT")).default,
  [SupportedLocales.JA]: async () => (await import("antd/locale/ja_JP")).default,
  [SupportedLocales.KO]: async () => (await import("antd/locale/ko_KR")).default,
  [SupportedLocales.LT]: async () => (await import("antd/locale/lt_LT")).default,
  [SupportedLocales.LV]: async () => (await import("antd/locale/lv_LV")).default,
  [SupportedLocales.MK_MK]: async () => (await import("antd/locale/mk_MK")).default,
  [SupportedLocales.MS]: async () => (await import("antd/locale/ms_MY")).default,
  [SupportedLocales.NL]: async () => (await import("antd/locale/ml_IN")).default,
  [SupportedLocales.NO]: async () => (await import("antd/locale/nb_NO")).default,
  [SupportedLocales.PL]: async () => (await import("antd/locale/pl_PL")).default,
  [SupportedLocales.PT]: async () => (await import("antd/locale/pt_PT")).default,
  [SupportedLocales.PT_BR]: async () => (await import("antd/locale/pt_BR")).default,
  [SupportedLocales.RO]: async () => (await import("antd/locale/ro_RO")).default,
  [SupportedLocales.RU]: async () => (await import("antd/locale/ru_RU")).default,
  [SupportedLocales.SK]: async () => (await import("antd/locale/sk_SK")).default,
  [SupportedLocales.SL]: async () => (await import("antd/locale/sl_SI")).default,
  [SupportedLocales.SV]: async () => (await import("antd/locale/sv_SE")).default,
  [SupportedLocales.TH]: async () => (await import("antd/locale/th_TH")).default,
  [SupportedLocales.TR]: async () => (await import("antd/locale/tr_TR")).default,
  [SupportedLocales.UK]: async () => (await import("antd/locale/uk_UA")).default,
  [SupportedLocales.VI]: async () => (await import("antd/locale/vi_VN")).default,
  [SupportedLocales.ZH_CN]: async () => (await import("antd/locale/zh_CN")).default,
  [SupportedLocales.ZH_TW]: async () => (await import("antd/locale/zh_TW")).default,
};

export default function ConnectedIntlProvider(props: Props) {
  const location = useLocation();
  const locale = useLocale();
  const [configLocale, setConfigLocale] = useState(enUS);

  useEffect(() => {
    if (location.pathname) {
      // not using useRouteMatch because fr is not always present and we don't know on what public page is landing
      const newLocale = location.pathname?.includes("fr/") ? SupportedLocales.FR : SupportedLocales.EN;
      if (newLocale !== locale) {
        changeLocale(newLocale);
      }
    }
  }, [location]);

  useEffect(() => {
    if (locale && ANT_LOCALES[locale]) {
      ANT_LOCALES[locale]()
        .then((newLocale: Locale) => {
          setConfigLocale(newLocale);
        })
        .catch(() => {
          setConfigLocale(enUS);
        });
    }
  }, [locale]);

  return (
    <ConfigProvider locale={configLocale}>
      <Helmet htmlAttributes={{ lang: locale }} />
      <IntlProvider locale={locale ?? SupportedLocales.EN} messages={locale ? messages[locale] : messages.en} {...props} />
    </ConfigProvider>
  );
}

const b = (c: ReactNode) => <strong>{c}</strong>;
