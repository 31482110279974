import store from "store";
import { OpCode, standardDispatch } from "store/dispatcher";
import { POST } from "services/request";
import { computeID, isLoaded } from "./actionUtils";
import { EoyTaxQuery } from "./eoyTaxForms";

export function resetEoySummary() {
  standardDispatch(OpCode.RESET, "eoySummary");
}
export function loadEoySummary(queries: EoyTaxQuery[], force?: boolean) {
  const { eoySummary } = store.getState();
  const id = computeID(queries);

  if (force || !isLoaded(eoySummary.fetchStatus[id])) {
    standardDispatch(OpCode.LOADING, "eoySummary", { id });
    POST<{ counts: number[] }>("/v1/tax-year/eoy-form/count", {
      query: {
        queries,
      },
    })
      .then(({ body }) => {
        standardDispatch(OpCode.DATA, "eoySummary", { id, data: body.counts });
      })
      .catch((errors) => {
        standardDispatch(OpCode.ERROR, "eoySummary", { id, errors });
      });
  }

  return id;
}
