import { useEffect, useState } from "react";
import { useShallowSelector } from ".";
import { TaxProfile, loadTaxProfiles, TaxProfilesQuery, loadTaxProfile } from "store/actions/taxProfiles";
import { emptyList } from "store/reducers/standardReducer";
import { computeID } from "store/actions/actionUtils";

export function useTaxProfiles(query: TaxProfilesQuery) {
  const [fetchId, setFetchId] = useState("");

  useEffect(() => {
    setFetchId(loadTaxProfiles(query));
  }, [computeID(query)]);

  return useShallowSelector((state) => {
    if (fetchId && state.taxProfiles.fetchStatus[fetchId] === undefined) {
      loadTaxProfiles(query);
    }

    const data = state.taxProfiles.entities[fetchId];

    return {
      data: data
        ? {
            records: data.records.map((id) => state.taxProfile.entities[id]).filter((v) => v) as TaxProfile[],
            meta: data.meta,
          }
        : emptyList,
      status: state.taxProfiles.fetchStatus[fetchId],
      error: state.taxProfiles.errors[fetchId],
    };
  });
}

export function useTaxProfile(taxProfileId: string | null | undefined) {
  const id = taxProfileId && /^TP-\w+/.test(taxProfileId) ? taxProfileId : "";
  useEffect(() => {
    if (id) {
      loadTaxProfile(id);
    }
  }, [id]);

  return useShallowSelector((state) => {
    if (id && state.taxProfile.fetchStatus[id] === undefined) {
      loadTaxProfile(id);
    }

    return {
      data: state.taxProfile.entities[id],
      status: state.taxProfile.fetchStatus[id],
      error: state.taxProfile.errors[id],
    };
  });
}
