import * as types from "@trolley/common-frontend";
import store from "store";
import { computeID, isLoaded } from "./actionUtils";
import { OpCode, standardDispatch } from "store/dispatcher";
import * as request from "services/request";

export type IndividualVerification = types.IndividualVerificationResponse;
export type BusinessVerification = types.KybVerificationResponse;
export type PhoneVerification = types.PhoneVerificationResponse;

export type VerificationHistory = {
  individual: IndividualVerification[];
  business: BusinessVerification[];
  phone: PhoneVerification[];
};

export function loadRecipientVerifications(recipientId: string, force?: boolean) {
  const id = computeID({ recipientId });
  const { verifications } = store.getState();
  if (recipientId && (force || !isLoaded(verifications.fetchStatus[id]))) {
    standardDispatch(OpCode.LOADING, "verifications", { id });
    request
      .POST<VerificationHistory>("/v1/verifications/get", {
        query: {
          recipientId,
        },
      })
      .then(({ body: verificationHistoryResponse }) => {
        standardDispatch(OpCode.DATA, "verifications", {
          id,
          data: verificationHistoryResponse,
        });
      })
      .catch((errors) => {
        standardDispatch(OpCode.ERROR, "verifications", { id, errors });
      });
  }

  return id;
}
