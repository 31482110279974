import { GlobalToken, Typography } from "antd";
import { ParagraphProps as AntParagraphProps } from "antd/lib/typography/Paragraph";
import React, { PropsWithChildren } from "react";
import css, { classnames, useThemeToken } from "style/classname";

const MAX_CHARACTERS_PER_LINE = 60;

export interface ParagraphProps extends AntParagraphProps {
  /**
   * the limit to number of characters per line (60ch)
   */
  limitWordPerLine?: boolean;
  size?: "initial" | "small" | "large" | "xlarge";
}

export default function Paragraph(props: PropsWithChildren<ParagraphProps>) {
  const { limitWordPerLine, className, ...rest } = props;
  const token = useThemeToken();

  return <Typography.Paragraph {...rest} className={classnames(className, styledParagraph({ props, token }))} />;
}

const styledParagraph = css<{ props: ParagraphProps; token: GlobalToken }>`
  color: inherit;
  ${({ props }) => props.limitWordPerLine && `max-width: ${MAX_CHARACTERS_PER_LINE}ch;`}
  ${({ props, token }) => {
    switch (props.size) {
      case "initial":
        return `
          font-size: ${token.fontSize}px;
        `;
      case "small":
        return `
          font-size: ${token.fontSizeSM}px;
          line-height: ${token.lineHeightSM};
        `;
      case "large":
        return `
          font-size: ${token.fontSizeLG}px;
          line-height: ${token.lineHeightLG};
        `;
      case "xlarge":
        return `
          font-size: ${token.fontSizeXL}px;
          line-height: ${(token.fontSizeXL + 8) / token.fontSizeXL};
        `;
      default:
        return `
          font-size: inherit;
          line-height: inherit;
        `;
    }
  }}
`;
