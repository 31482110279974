import * as types from "@trolley/common-frontend";
import * as request from "services/request";
import store from "store";
import { OpCode, standardDispatch } from "store/dispatcher";
import { isLoaded } from "./actionUtils";

export interface RoyaltyCalculatorSettings extends types.RoyaltyCalculatorSettings {}

export interface RoyaltyCalculatorResponse {
  ok: boolean;
  settings: RoyaltyCalculatorSettings[];
}

export function loadRoyaltyCalculatorIntegration(force?: boolean) {
  const { royaltyIntegrations } = store.getState();

  if (force || !isLoaded(royaltyIntegrations.fetchStatus.data)) {
    standardDispatch(OpCode.LOADING, "royaltyIntegrations");

    request
      .POST<RoyaltyCalculatorResponse>(`/v1/integrations/royaltyCalculator/settings/get`)
      // TODO: verify response structure!
      .then(({ body: { settings: data } }) => {
        standardDispatch(OpCode.DATA, "royaltyIntegrations", {
          data,
        });
      })
      .catch((errors) => {
        standardDispatch(OpCode.ERROR, "royaltyIntegrations", {
          errors,
        });
      });
  }
}

export function resetRoyaltyIntegrations() {
  standardDispatch(OpCode.RESET, "royaltyIntegrations");
}

type RoyaltyCalculatorIntegrationUpdateQuery = {
  name: types.RoyaltyCalculatorIntegrations;
  enabled: boolean;
};

export interface RoyaltyCalculatorUpdateResponse {
  ok: boolean;
  setting: RoyaltyCalculatorSettings;
}

export async function updateRoyaltyCalculatorIntegration(query: RoyaltyCalculatorIntegrationUpdateQuery) {
  const { royaltyIntegrations } = store.getState();
  standardDispatch(OpCode.LOADING, "royaltyIntegrations");
  try {
    const {
      body: { setting },
    } = await request.POST<RoyaltyCalculatorUpdateResponse>(`/v1/integrations/royaltyCalculator/settings/update`, { query });
    standardDispatch(OpCode.DATA, "royaltyIntegrations", {
      data:
        royaltyIntegrations.entities.data && royaltyIntegrations.entities.data.length > 0
          ? royaltyIntegrations.entities.data.map((calculator: RoyaltyCalculatorSettings) =>
              calculator.integration === setting.integration ? setting : calculator,
            )
          : [setting],
    });

    return setting;
  } catch (errors) {
    standardDispatch(OpCode.ERROR, "royaltyIntegrations", {
      errors,
    });
    throw errors;
  }
}
