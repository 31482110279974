import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

import { Alert, Button, Container, Divider, Icon, Text } from "components";
import config from "config";
import datadog from "services/thirdParty/datadog";

export interface States {
  hasError?: { message?: string; stack?: string };
}

export interface AllProps extends RouteComponentProps {}

export class ErrorBoundary extends React.Component<AllProps, States> {
  state: States = {};

  componentDidUpdate(prevProps: AllProps) {
    if (this.state.hasError && prevProps.location && prevProps.location.pathname !== this.props.location.pathname) {
      this.setState({ hasError: undefined });
    }
  }

  componentDidCatch(error: any, info: any) {
    this.setState({
      hasError: error,
    });

    datadog.error(error);
  }

  onReload = () => {
    this.setState({
      hasError: undefined,
    });
  };

  render() {
    const { hasError } = this.state;
    if (hasError) {
      return (
        <Container padding="large">
          <Alert showIcon={false} type="error" style={{ textAlign: "center", padding: "24px" }}>
            <Icon type="dizzy" size="5x" color="red" style={{ margin: "24px" }} />
            <Text weight="bold" size="large" type="error" padded>
              Oops... Something went wrong.
            </Text>

            {config.ENV !== "production" && hasError.message && (
              <>
                <Text padded type="error">
                  {hasError.message}
                </Text>
                <pre>{hasError.stack}</pre>
              </>
            )}

            <Divider transparent margin="medium" />
            <Text>We've been notified of this error. In the meantime ... </Text>
            <Divider transparent margin="small" />

            <Button onClick={this.onReload} type="primary" ghost>
              <Icon type="redo-alt" size="small" left /> Try Reloading
            </Button>
          </Alert>
        </Container>
      );
    }

    return this.props.children || null;
  }
}

export default withRouter(ErrorBoundary);
