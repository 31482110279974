import React, { ReactNode } from "react";
import { Invoice } from "store/actions/invoices";
import { useInvoice } from "store/hooks/invoices";

interface Props {
  id: string | undefined;
  children: (invoice: Invoice | undefined) => ReactNode;
}
export default function InvoiceLoader({ id, children }: Props) {
  const invoiceId = id && /^I-\w+$/.test(id) ? id : "";
  const { data } = useInvoice(invoiceId);

  return <>{children(data)}</>;
}
