import { IdvSource, VerificationRejectedReasons, VerificationStatus } from "@trolley/common-frontend";
import React from "react";
import { Text, Divider, Tooltip } from "components";
import VerificationReview from "./images/VerificationReview.svg";
import VerificationApproved from "./images/VerificationApproved.svg";
import VerificationDeclined from "./images/VerificationDeclined.svg";
import VerificationPending from "./images/VerificationPending.svg";
import VerificationManual from "./images/VerificationManual.svg";
import { IndividualRejectedReasons } from "pages/RecipientsPage/Details/Trust/IdVerificationList";

type Props = {
  status?: VerificationStatus;
  source?: IdvSource;
  hideTooltip?: boolean;
  disabled?: boolean;
  reasonType?: VerificationRejectedReasons;
};

type StatusComponent = {
  image: any;
  text: string;
  status: string;
};

const statusMapping: Record<Exclude<VerificationStatus, VerificationStatus.SUBMITTED | VerificationStatus.EXPIRED>, StatusComponent> = {
  [VerificationStatus.RETRY]: {
    image: VerificationPending,
    text: "Recipient needs to retry their identity verification. Recipient profile will remain Incomplete.",
    status: "Pending Verification",
  },
  [VerificationStatus.REVIEW]: {
    image: VerificationReview,
    text: "Recipient identity verification requires review. Recipient will need to submit a new identity verification. Recipient profile will remain Incomplete.",
    status: "Needs Review",
  },
  [VerificationStatus.PENDING]: {
    image: VerificationPending,
    text: "Recipient has not submitted their identity verification. Recipient profile will remain Incomplete.",
    status: "Pending Verification",
  },

  [VerificationStatus.REJECTED]: {
    image: VerificationDeclined,
    text: "Recipient has failed to verified their identity. The information submitted does not match their identity. Recipient profile will remain Incomplete.",
    status: "Rejected",
  },
  [VerificationStatus.APPROVED]: {
    image: VerificationApproved,
    text: "Recipient has successfully verified their identity.",
    status: "Verified",
  },
};

const getVerificationImage = (status: VerificationStatus, source: IdvSource) => {
  if (source === IdvSource.MANUAL && status === VerificationStatus.APPROVED) {
    return VerificationManual;
  }

  return statusMapping[status]?.image || VerificationPending;
};

const getStatus = (status: VerificationStatus, source: IdvSource) => {
  if (source === IdvSource.MANUAL && (status === VerificationStatus.APPROVED || status === null)) {
    return "Manually Verified";
  }

  return statusMapping[status]?.status ?? statusMapping[VerificationStatus.PENDING]?.status;
};

const getStatusDescription = (status: VerificationStatus, source: IdvSource) => {
  if (source === IdvSource.MANUAL && status === VerificationStatus.APPROVED) {
    return "Recipient has been manually verified by the merchant";
  }

  return statusMapping[status]?.text ?? statusMapping[VerificationStatus.PENDING].text;
};

export default function RecipientVerificationStatus({
  status = VerificationStatus.PENDING,
  source = IdvSource.VERIFF,
  hideTooltip = false,
  disabled = false,
  reasonType,
}: Props) {
  return !hideTooltip ? (
    <Tooltip
      title={
        <>
          <Text type="label">Verification Status</Text>
          <Text capitalize>{getStatus(status, source)}</Text>
          <Divider inverse margin="small" />
          <Text size="small">{getStatusDescription(status, source)}</Text>
          {reasonType && IndividualRejectedReasons[reasonType] && <Text size="small">Reason: {IndividualRejectedReasons[reasonType]}</Text>}
        </>
      }
    >
      <img src={getVerificationImage(status, source)} alt={status} style={disabled ? { opacity: 0.6 } : undefined} />
    </Tooltip>
  ) : (
    <img src={getVerificationImage(status, source)} alt={status} style={disabled ? { opacity: 0.6 } : undefined} />
  );
}
