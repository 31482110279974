import { Icon, Text } from "components";
import React from "react";

type Props = {
  value: string | null | undefined;
};

export default function CopyToClipboard(props: Props) {
  const { value } = props;

  if (!value) {
    return null;
  }

  return (
    <Text
      copyable={{
        icon: <Icon type="copy" style={{ display: "inline" }} />,
      }}
    >
      {value}
    </Text>
  );
}
