export default function intersect<T extends string>(...args: (T[] | undefined)[]): T[] {
  const arrays = args.filter((v) => v) as T[][];
  if (!arrays.length) {
    return [];
  }

  const [first, ...rest] = arrays;

  if (!rest.length) {
    return first;
  }

  return first.filter((item) => rest.every((arr) => arr.includes(item)));
}
