import { CountryCode, VerificationRejectedReasons } from "@trolley/common-frontend";
import { Flag, LogoLoader } from "components";
import React from "react";
import { useParams } from "react-router-dom";
import { useRecipientVerifications } from "store/hooks/verifications";
import { BaseStatus } from "store/reducers/standardReducer";
import { Params } from "..";
import BusinessVerification from "./BusinessVerificationList";
import IdVerification from "./IdVerificationList";
import PhoneVerification from "./PhoneVerificationList";

export const RejectedReasonLabels: { [key in VerificationRejectedReasons]: string } = {
  [VerificationRejectedReasons.FRAUDULENT]: "Fraudulent",
  [VerificationRejectedReasons.INVALID]: "Invalid",
  [VerificationRejectedReasons.MISSING_REQUIREMENTS]: "Missing Requirements",
  [VerificationRejectedReasons.UNPROCESSABLE]: "Unprocessable",
  [VerificationRejectedReasons.OTHER]: "Other",
};

export function renderCountry(country: string | undefined | null) {
  if (!country) {
    return "-";
  }

  return (CountryCode[country] && <Flag code={country as CountryCode} />) || country;
}

export default function RecipientTrust() {
  const { recipientId } = useParams<Params>();
  const { data: verifications, status: verificationsFetchStatus } = useRecipientVerifications(recipientId, true);

  return (
    <LogoLoader spinning={verificationsFetchStatus === BaseStatus.LOADING}>
      <IdVerification verifications={verifications?.individual ?? []} />
      <BusinessVerification verifications={verifications?.business ?? []} />
      <PhoneVerification verifications={verifications?.phone ?? []} />
    </LogoLoader>
  );
}
