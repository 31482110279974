import { Access, RecipientStatus, TaxStatus } from "@trolley/common-frontend";
import { Button, Dropdown, Icon, Menu, Space, Stop } from "components";
import { UserAccess } from "features/user";
import VoidProfilePopup from "pages/TaxCenter/VoidProfilePopup";
import React, { useState } from "react";
import { notifyError, notifySuccess } from "store/actions/notifications";
import { approveRecipientTaxProfile } from "store/actions/recipientTaxProfiles";
import { TaxProfile } from "store/actions/taxProfiles";
import { useRecipient } from "store/hooks/recipients";
import { useTaxProfile } from "store/hooks/taxProfiles";

enum ButtonActions {
  APPROVE = "approve",
  VOID = "void",
}

interface Props {
  taxProfileId: string | undefined;
  compact?: boolean;
  showMenu?: boolean;
}

export default function TaxProfileActionsWrapper(props: Props) {
  return (
    <UserAccess type={Access.TAX_WRITE}>
      <TaxProfileActions {...props} />
    </UserAccess>
  );
}

export function TaxProfileActions(props: Props) {
  const { taxProfileId, compact, showMenu = true } = props;
  const { data: taxProfile } = useTaxProfile(taxProfileId);
  const { data: recipient } = useRecipient(taxProfile?.recipientId);
  const [voidProfile, setVoidProfile] = useState<{ taxProfiles: TaxProfile[] } | undefined>();

  if (!taxProfile) {
    return null;
  }

  function onAction(mainAction: ButtonActions) {
    if (taxProfile) {
      switch (mainAction) {
        case ButtonActions.VOID:
          setVoidProfile({ taxProfiles: [taxProfile] });
          break;
        case ButtonActions.APPROVE:
          if (taxProfile) {
            approveRecipientTaxProfile(taxProfile.recipientId, taxProfile.id)
              .then(() => {
                notifySuccess("Tax profile marked as reviewed");
              })
              .catch(() => {
                notifyError("Approving tax profile failed");
              });
          }
          break;
        default:
          break;
      }
    }
  }

  function getMenu() {
    if (!taxProfile) {
      return null;
    }
    const recipientDeleted = recipient?.status === RecipientStatus.ARCHIVED;
    const enableApprove = taxProfile.status === TaxStatus.SUBMITTED && !recipientDeleted;
    const enableVoid = taxProfile.status !== TaxStatus.VOIDED && !recipientDeleted;

    return (
      <Dropdown
        overlay={
          <Menu
            items={[
              {
                key: ButtonActions.APPROVE,
                onClick: () => {
                  onAction(ButtonActions.APPROVE);
                },
                disabled: !enableApprove,
                label: (
                  <>
                    <Icon type="file-check" color={enableApprove ? "blue" : undefined} left />
                    Mark as Reviewed
                  </>
                ),
              },

              {
                key: ButtonActions.VOID,
                onClick: () => {
                  onAction(ButtonActions.VOID);
                },
                disabled: !enableVoid,
                label: (
                  <>
                    <Icon type="file-slash" left color={enableVoid ? undefined : "grey"} />
                    Void Tax Profile
                  </>
                ),
              },
            ]}
          />
        }
      >
        <Button icon={<Icon type="ellipsis" />} />
      </Dropdown>
    );
  }

  return taxProfile && taxProfile.status !== TaxStatus.VOIDED ? (
    <Stop>
      <Space wrap={false}>
        {!showMenu && recipient && recipient.status !== RecipientStatus.ARCHIVED && (
          <Button
            icon={<Icon type="xmark-large" />}
            onClick={() => {
              onAction(ButtonActions.VOID);
            }}
          >
            Void
          </Button>
        )}
        {recipient && recipient.status !== RecipientStatus.ARCHIVED && (
          <>
            {taxProfile.status === TaxStatus.SUBMITTED && (
              <Button
                tooltipProps={compact ? { title: "Mark as Reviewed" } : undefined}
                type="primary"
                onClick={() => {
                  onAction(ButtonActions.APPROVE);
                }}
                icon={compact ? <Icon type="file-check" theme="solid" /> : undefined}
              >
                {compact ? undefined : "Mark as Reviewed"}
              </Button>
            )}
          </>
        )}
        {showMenu && getMenu()}

        <VoidProfilePopup
          taxProfiles={voidProfile?.taxProfiles}
          onClose={() => {
            setVoidProfile(undefined);
          }}
        />
      </Space>
    </Stop>
  ) : null;
}
