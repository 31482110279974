import { TaxStatus } from "@trolley/common-frontend";
import { Container, Heading, RecordCount, Reloader } from "components";
import React from "react";
import Helmet from "react-helmet";
import { TaxFormsQuery, resetTaxForms } from "store/actions/taxForms";
import { useTaxForms } from "store/hooks/taxForms";
import { useQuery } from "utils/hooks";
import TaxFormsTable, { TaxFilterKeys } from "./TaxFormsTable";
import UploadTaxForms from "./UploadTaxForms";

const DEFAULT_QUERY: TaxFormsQuery = {
  page: 1,
  pageSize: 10,
  orderBy: ["createdAt"],
  sortBy: ["desc"],
  status: [TaxStatus.SUBMITTED],
};

export default function SubmittedTaxForms() {
  const [query, setQuery] = useQuery<TaxFormsQuery>(DEFAULT_QUERY, {
    arrayKeys: Object.values(TaxFilterKeys),
  });
  const { data: taxForms, status: taxFormsStatus, error: taxFormsErrors } = useTaxForms(query);

  return (
    <Container>
      <Helmet>
        <title>{`Tax Forms (${query.page}/${taxForms.meta.pages})`}</title>
      </Helmet>

      <Heading description="Review and approve the tax forms submitted by recipients." extraActions={<UploadTaxForms />}>
        <RecordCount prefix="Showing" value={taxForms.meta.records} one="1 submitted tax form" other="# submitted tax forms" equalZero="submitted tax forms" />
        <Reloader onClick={resetTaxForms} status={taxFormsStatus} />
      </Heading>
      <TaxFormsTable showRecipients showFilters status={taxFormsStatus} errors={taxFormsErrors} taxForms={taxForms} query={query} onChangeQuery={setQuery} />
    </Container>
  );
}
