import { formatCountry, TaxFormWarnings } from "@trolley/common-frontend";
import { Alert, Text } from "components";
import { RecipientProfileAddress } from "features/recipient";
import React from "react";
import { getTaxFormWarningLabel } from "store/actions/recipientTaxForms";
import { TaxForm, W8BENEForm, W8BENForm, W9Form } from "store/actions/taxForms";
import { useRecipient } from "store/hooks/recipients";
import { stringifyAddress } from "utils/helpers";

type Props = {
  taxForm: TaxForm;
};

export default function TaxWarning(props: Props) {
  const { taxForm } = props;
  const { data: recipient } = useRecipient(taxForm.recipientId);
  const { warnings } = taxForm;

  return warnings?.length ? (
    <>
      {warnings?.map((warning) => (
        <Alert key={warning} type="warning">
          {getTaxFormWarningLabel(warning)}

          {taxForm.taxFormData && warning === TaxFormWarnings.FOREIGN_TIN_INVALID && (
            <Text size="small">
              <Text weight="bold">Foreign TIN:</Text>
              {(taxForm.taxFormData as W8BENEForm | W8BENForm)?.taxPayerForeignId || " - "}
            </Text>
          )}

          {taxForm.taxFormData && warning === TaxFormWarnings.INCOMPLETE_ADDRESS && (
            <Text size="small">
              <Text weight="bold">Address on Tax Form:</Text>
              {stringifyAddress(taxForm.taxFormData, true)}
              <Text weight="bold">Address on Recipient Profile:</Text>
              <RecipientProfileAddress recipientId={taxForm.recipientId} detailed />
            </Text>
          )}

          {taxForm.taxFormData && warning === TaxFormWarnings.DIFFERENT_COUNTRY && (
            <Text size="small">
              <Text weight="bold">Country on Tax Form:</Text>
              {formatCountry((taxForm.taxFormData as W9Form).country || (taxForm.taxFormData as W8BENEForm | W8BENForm).residenceCountry)}
              <Text weight="bold">Country on Recipient Profile:</Text>
              {formatCountry(recipient?.address.country)}
            </Text>
          )}
        </Alert>
      ))}
    </>
  ) : null;
}
