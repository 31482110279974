import React from "react";
import styled from "style/classname";
import { Icon } from "components";
import { IconProps } from ".";

const ghostBox = styled`
display: inline-block;
position: relative;
.ghost {
  animation: floatY 1.6s cubic-bezier(0.455, 0.03, 0.515, 0.955) infinite alternate;
}

.shadow {
  width: 70px;
  height: 8px;
  border-radius: 50%;
  background-color: #666;
  margin: 16px auto;
  filter: blur(4px);
  animation: shrink 1.6s cubic-bezier(0.455, 0.03, 0.515, 0.955) infinite alternate;
}

@keyframes shrink {
  0 % {
    transform: scale(0.9);
  }
  100 % {
    transform: scale(1);
  }
}
@keyframes floatY {
  0% {
    transform: translateY(-5px);

  }
  100% {
    transform: translateY(5px);
  }
}
`();

export type GhostIconProps = Pick<IconProps, "color" | "theme">;

export default function GhostIcon(props: GhostIconProps) {
  const { color = "primary", theme = "duotone" } = props;

  return (
    <div className={ghostBox}>
      <Icon className="ghost" color={color} type="ghost" style={{ fontSize: "100px" }} theme={theme} {...props} />
      <div className="shadow" />
    </div>
  );
}
