import { Modal, UploadWindow, Text } from "components";
import React, { ReactNode } from "react";
import { BaseStatus } from "store/reducers/standardReducer";
import { Ticket, TicketDocumentType } from "@trolley/common-frontend";
import { uploadTicketDocument } from "store/actions/tickets";
import { useTicket } from "store/hooks/tickets";

interface Props {
  onClose(): void;
  ticket: Ticket;
  documentType?: TicketDocumentType;
}

const ALLOWED_MIME_TYPES = ["application/pdf", "image/jpeg", "image/png", "image/heic", "image/heif"];
const FILE_SIZE_LIMIT_MB = 5;

export const TICKET_DOCUMENT_REQUESTS: Record<TicketDocumentType, string> = {
  address: "Proof of Address",
  corporate_documentation: "Corporate documentation",
  identity: "Proof of Identity",
  invoice: "Invoice",
  residence_permit: "Residence permit",
};

function renderPopupContent(documentType: TicketDocumentType) {
  const content: Record<TicketDocumentType, ReactNode> = {
    address: (
      <>
        <p>
          Please provide a high quality photo or scan of a document showing your valid residential address which matches the same address that you have on your
          recipient account.
        </p>
        <p>A valid proof of address document can be:</p>
        <ul>
          <li>Utility bills: gas, electric, or landline phone</li>
          <li>A current bank or credit card statement (photo/scan of a physical letter or PDF of statement)</li>
          <li>Photo driving license showing your address and expiry date</li>
          <li>Any other current government or financial institution-issued document</li>
        </ul>
      </>
    ),
    corporate_documentation: (
      <p>
        Please provide a high quality photo or scan of a document showing your valid Company incorporation documents and Registration papers. Please note that
        we can only accept valid and not expired documents.
      </p>
    ),
    identity: (
      <>
        <p>
          Please provide a high quality photo or scan of a document proving your identity with clearly visible full name, date of birth, document expiry date
          and other details. Please note that we can only accept valid and not expired documents.{" "}
        </p>
        <p>A valid proof of identity document can be:</p>
        <ul>
          <li>Passport (photo page only)</li>
          <li>National ID card</li>
          <li>Photo driver’s licence</li>
        </ul>
      </>
    ),
    invoice: (
      <p>
        Please provide a high quality photo or scan of a document showing an invoice where we could identify the recipient, detailed line items and amounts.
      </p>
    ),
    residence_permit: (
      <p>
        Please provide a high quality photo or scan of a document showing your valid residency permit. Please note that we can only accept valid and not expired
        documents.
      </p>
    ),
  };

  return <Text>{content[documentType]}</Text>;
}

export default function TicketDocumentUploadPopup(props: Props) {
  const { onClose, ticket, documentType } = props;
  const { status } = useTicket(ticket.id);

  async function onUpload(file: FormData) {
    if (documentType) {
      await uploadTicketDocument(ticket.id, documentType, file);
    }
  }

  function onSuccess() {
    Modal.success({ title: "Success", content: "The document was uploaded and will be reviewed soon by the compliance team." });
    onClose();
  }

  const visible = !!documentType;
  const title = `Upload ${documentType ? TICKET_DOCUMENT_REQUESTS[documentType] : ""}`;

  return (
    <UploadWindow
      title={title}
      addOnBefore={documentType && renderPopupContent(documentType)}
      onClose={onClose}
      onSubmit={onUpload}
      loading={status === BaseStatus.LOADING}
      visible={visible}
      maxSize={FILE_SIZE_LIMIT_MB}
      mimeTypes={ALLOWED_MIME_TYPES}
      multiple
      onSuccess={onSuccess}
    />
  );
}
