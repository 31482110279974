import { BaseError } from "@trolley/common-frontend";
import flatten from "flat";

import { FormInstance } from "components/Form";
import { notifyError } from "store/actions/notifications";

/**
 * handleApiError set the ANT form field errors
 * @param  BaseError[]        errors [errors returned by the API]
 * @param  FormInstance   form   [form is an Ant Design form instance]
 * @return no return value
 */
export default function handleFormErrors(errors: BaseError[], form: FormInstance, notifyMessage?: string) {
  if (!!errors?.length) {
    try {
      const fieldErrors = errors.filter((e) => e.field && e.message);

      if (fieldErrors.length > 0) {
        const unflattenedFormVals = form.getFieldsValue();
        const flattenedFormVals = flatten<{ [key: string]: any }, { [key: string]: string | number | undefined }>(unflattenedFormVals);
        const formVals = { ...unflattenedFormVals, ...flattenedFormVals };
        const formFields = Object.keys(formVals);

        const newValues = fieldErrors
          .map((error: Required<BaseError>) => {
            // find matching error.
            let testField = error.field;
            let formField = formFields.find((field) => new RegExp(`${error.field}$`).test(field)); // can be dot notation

            while (!formField && error.field.includes(".")) {
              testField = String(testField).split(".").slice(0, -1).join("."); // remove last portion. eg.  "address.country" will become "address".
              // eslint-disable-next-line no-loop-func
              formField = formFields.find((field) => new RegExp(`${testField}$`).test(field));
            }

            if (formField) {
              return {
                name: formField.split("."),
                value: form.getFieldValue(formField),
                errors: [error.message as string],
              };
            }

            return undefined;
          })
          .filter((v) => v);

        if (newValues.length) {
          form.setFields(newValues as any);
        } else {
          notifyError(`${fieldErrors[0].field}: ${fieldErrors[0].message}`);
        }
      } else {
        notifyError(notifyMessage, { errors });
      }
    } catch (err) {
      // console.error(err);
    }
  }
}
