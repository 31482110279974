import store from "store";
import { OpCode, standardDispatch } from "store/dispatcher";
import { POST } from "services/request";
import * as types from "@trolley/common-frontend";
import { isLoaded } from "./actionUtils";

export type DAC7TaxMerchantYear = types.DAC7TaxMerchantYear.DAC7TaxMerchantYear;

export type DAC7TaxMerchantYearUpdate = {
  businessDBAName: string;
  businessName: string;
  businessNumber: string;
  city: string;
  contactEmail: string;
  contactName: string;
  contactPhone: string;
  country: string;
  nexus: string;
  postalCode: string;
  regionCode: string;
  street1: string;
  street2: string;
  tinNumber: string;
  vatNumber: string;
};

export function loadDAC7TaxMerchantYear(taxYear: number, force?: boolean) {
  const data = store.getState().dac7TaxMerchantYear;

  if (force || !isLoaded(data.fetchStatus[taxYear])) {
    standardDispatch(OpCode.LOADING, "dac7TaxMerchantYear", {
      id: taxYear,
    });

    POST<types.TaxMerchantYear.Result>("/v1/tax-year/dac7/config/get", { query: { taxYear } })
      .then((res) => {
        standardDispatch(OpCode.DATA, "dac7TaxMerchantYear", {
          id: taxYear,
          data: res.body.taxYear,
        });
      })
      .catch((errors) => {
        standardDispatch(OpCode.ERROR, "dac7TaxMerchantYear", {
          id: taxYear,
          errors,
        });
      });
  }
}

export async function updateDAC7TaxMerchantYear(change: DAC7TaxMerchantYearUpdate, taxYear: number) {
  standardDispatch(OpCode.LOADING, "dac7TaxMerchantYear", {
    id: taxYear,
  });

  try {
    const { body } = await POST<types.DAC7TaxMerchantYear.Result>("/v1/tax-year/dac7/config/update", { query: { ...change, taxYear } });
    standardDispatch(OpCode.DATA, "dac7TaxMerchantYear", {
      id: taxYear,
      data: body.taxYear,
    });

    return body.taxYear;
  } catch (errors) {
    standardDispatch(OpCode.ERROR, "dac7TaxMerchantYear", {
      id: taxYear,
      errors,
    });
    throw errors;
  }
}
