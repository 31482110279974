import { Badge as BadgeAnt, BadgeProps as AntBadgeProps } from "antd";
import React from "react";

export interface BadgeProps extends AntBadgeProps {}

export default function Badge({ className, style, ...props }: BadgeProps) {
  return <BadgeAnt {...props} style={{ pointerEvents: "none", ...style }} />;
}

Badge.Ribbon = BadgeAnt.Ribbon;
