import store from "store";
import { BaseError } from "store/reducers/standardReducer";

export const NOTIFICATION_ADD = "notification/ADD";
export const NOTIFICATION_RESET = "notification/RESET";

function parseErrorsMessage(e?: BaseError[]) {
  return e?.find?.((err) => err.message)?.message;
}

export function notifySuccess(defaultMessage: React.ReactNode, options?: { duration?: number; closable?: boolean }) {
  notify(defaultMessage, { type: "success", duration: options?.duration, closable: options?.closable });
}

export function notifyWarning(defaultMessage: React.ReactNode, options?: { errors?: BaseError[]; duration?: number; closable?: boolean }) {
  notify(parseErrorsMessage(options?.errors) || defaultMessage, { type: "warning", duration: options?.duration, closable: options?.closable });
}

export function notifyError(defaultMessage: React.ReactNode, options?: { errors?: BaseError[]; duration?: number; closable?: boolean }) {
  notify(parseErrorsMessage(options?.errors) || defaultMessage, { type: "error", duration: options?.duration, closable: options?.closable });
}

export function notify(
  message: React.ReactNode,
  options: { type: "success" | "warning" | "error"; duration?: number; closable?: boolean } = { type: "success" },
) {
  store.dispatch({
    type: NOTIFICATION_ADD,
    data: {
      message,
      ...options,
    },
  });
}

export function resetNotification() {
  store.dispatch({
    type: NOTIFICATION_RESET,
  });
}
