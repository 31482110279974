import { EmailTemplate, TaxFormVoidReason } from "@trolley/common-frontend";
import { Form, Modal, Select } from "components";
import React, { useEffect } from "react";
import { notifyError, notifySuccess } from "store/actions/notifications";
import { solicitRecipients } from "store/actions/recipients";
import { voidRecipientTaxForm } from "store/actions/recipientTaxForms";
import { TaxForm } from "store/actions/taxForms";

const VOID_REASONS = [
  { value: TaxFormVoidReason.INVALID_DATA, label: "Invalid Information" },
  { value: TaxFormVoidReason.INVALID_SIGNATURE, label: "Invalid Signature" },
  { value: TaxFormVoidReason.INVALID_TIN, label: "Invalid Tax Identification Number" },
];

interface Props {
  taxForms?: TaxForm[];
  onClose(): void;
  solicit?: boolean;
}

export default function VoidFormPopup(props: Props) {
  const { taxForms, solicit, onClose } = props;
  const [form] = Form.useForm();

  useEffect(() => {
    if (taxForms?.length) {
      form.resetFields();
    }
  }, [taxForms]);

  async function onVoidForm({ voidReason }: { voidReason: TaxFormVoidReason }) {
    try {
      if (taxForms?.length) {
        await Promise.allSettled(taxForms.map((f) => voidRecipientTaxForm(f.recipientId, f.id, voidReason)));
        if (solicit) {
          await solicitRecipients(
            taxForms.map((f) => f.recipientId),
            EmailTemplate.WL_TAX_REQUIRED,
          );
          notifySuccess("Tax Forms Voided and new Forms have been requested");
        } else {
          notifySuccess(`Tax Forms Voided`);
        }
        onClose();
      }
    } catch (errors) {
      notifyError("Voiding Tax Forms failed");
    }
  }

  return (
    <Modal
      visible={!!taxForms}
      title={document ? "Void this tax form?" : "Void all selected tax forms?"}
      onOk={form.submit}
      onCancel={onClose}
      okButtonProps={{
        htmlType: "submit",
      }}
      okText={solicit ? "Yes, Void & Request New Form" : "Yes, Void"}
      okType="danger"
    >
      <Form form={form} onFinish={onVoidForm}>
        <p>A voided tax form will still be accessible, but cannot be used anymore.</p>
        <Form.Item label="Select a void reason" name="voidReason" rules={[{ required: true, message: "A reason is required" }]}>
          <Select showSearch optionFilterProp="children">
            {VOID_REASONS.map((type) => (
              <Select.Option key={type.value} value={type.value}>
                {type.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
}
