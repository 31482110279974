import { useEffect, useState } from "react";
import { computeID } from "store/actions/actionUtils";
import { EoyTax, EoyTaxQuery, loadEoyTaxForm, loadEoyTaxForms } from "store/actions/eoyTaxForms";
import { emptyList } from "store/reducers/standardReducer";
import { useShallowSelector } from ".";

export function useEoyTaxForms(taxYear: number, query: EoyTaxQuery) {
  const [fetchId, setFetchId] = useState("");
  const year = taxYear && /^\d{4}$/.test(String(taxYear)) ? taxYear : "";

  useEffect(() => {
    if (year) {
      setFetchId(loadEoyTaxForms({ ...query, taxYear }));
    }
  }, [year, computeID(query)]);

  return useShallowSelector((state) => {
    if (year && fetchId && state.eoyTaxForms.fetchStatus[fetchId] === undefined) {
      loadEoyTaxForms({ ...query, taxYear: year });
    }

    const data = state.eoyTaxForms.entities[fetchId];

    return {
      data: data
        ? {
            records: data.records.map((id) => state.eoyTaxForm.entities[id]).filter((v) => v) as EoyTax[],
            meta: data.meta,
          }
        : emptyList,
      status: state.eoyTaxForms.fetchStatus[fetchId],
      error: state.eoyTaxForms.errors[fetchId],
    };
  });
}

export function useEoyTaxForm(eoyTaxId?: string) {
  const id = eoyTaxId && /^RET-\w+/.test(eoyTaxId) ? eoyTaxId : "";

  useEffect(() => {
    if (id) {
      loadEoyTaxForm(id);
    }
  }, [id]);

  return useShallowSelector((state) => {
    if (id && state.eoyTaxForm.fetchStatus[id] === undefined) {
      loadEoyTaxForm(id);
    }

    return {
      data: state.eoyTaxForm.entities[id],
      status: state.eoyTaxForm.fetchStatus[id],
      error: state.eoyTaxForm.errors[id],
    };
  });
}

export function useRecipientEoyTaxForms(recipientId: string) {
  const [fetchId, setFetchId] = useState("");

  useEffect(() => {
    if (recipientId) {
      setFetchId(loadEoyTaxForms({ recipientId }));
    }
  }, [recipientId]);

  return useShallowSelector((state) => {
    if (recipientId && fetchId && state.eoyTaxForms.fetchStatus[fetchId] === undefined) {
      loadEoyTaxForms({ recipientId });
    }

    const data = state.eoyTaxForms.entities[fetchId];

    return {
      data: data
        ? {
            records: data.records.map((id) => state.eoyTaxForm.entities[id]).filter((v) => v) as EoyTax[],
            meta: data.meta,
          }
        : emptyList,
      status: state.eoyTaxForms.fetchStatus[fetchId],
      error: state.eoyTaxForms.errors[fetchId],
    };
  });
}
