import { useShallowSelector } from ".";
import { RecipientAccount } from "store/actions/recipientAccount";

export function useRecipientAccounts(recipientId: string | null = "") {
  return useShallowSelector((state) => {
    const accountIds = (recipientId && state.recipient.entities[recipientId]?.accounts) || [];

    return accountIds.map((id) => state.recipientAccount.entities[id]).filter((v) => v) as RecipientAccount[];
  });
}
