/* istanbul ignore file */
import { produce } from "immer";

import { SESSION_NEW, SESSION_ACTIVE, SESSION_IDLE, SESSION_EXPIRED, SESSION_LOGOUT } from "store/actions/session";

export enum SessionMode {
  ACTIVE = "active",
  IDLE = "idle",
  EXPIRED = "expired",
  ENDED = "ended",
}

const initialState = {
  state: undefined,
};

export type SessionState = { state?: SessionMode };
type SessionAction = {
  type: string;
};

export default function session(state: SessionState = initialState, action: SessionAction) {
  return produce(state, (draft) => {
    switch (action.type) {
      case SESSION_NEW:
      case SESSION_ACTIVE:
        draft.state = SessionMode.ACTIVE;
        break;
      case SESSION_IDLE:
        draft.state = SessionMode.IDLE;
        break;
      case SESSION_LOGOUT:
        draft.state = SessionMode.ENDED;
        break;
      case SESSION_EXPIRED:
        draft.state = SessionMode.EXPIRED;
        break;
    }
  });
}
