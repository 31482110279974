import local from "./local";
import staging from "./staging";
import development from "./development";
import platformDevEnvConfig from "./development-platform";
import syncDevEnvConfig from "./development-sync";
import taxDevEnvConfig from "./development-tax";
import production from "./production";

const ENV = process.env.SETTINGS || "";
const BUILD_VERSION = process.env.BUILD_VERSION;

const configEnv = {
  local,
  development,
  "development-platform": platformDevEnvConfig,
  "development-sync": syncDevEnvConfig,
  "development-tax": taxDevEnvConfig,
  staging,
  production,
};

export type Configuration = {
  DOMAIN: string;
  WS_SERVER_ADDRESS: string;
  SERVER_ADDRESS: string;
  WIDGET_ADDRESS: string;
  HEADWAY_ACCOUNT_ID?: string;
  ADYEN?: {
    // https://docs.adyen.com/payment-methods/cards/custom-card-integration
    clientKey: string;
    environment: string;
  };
  CHARGEBEE?: {
    site: string;
    publishableKey: string;
  };
  DATADOG?: {
    applicationId: string;
    clientToken: string;
    site: string;
    service: string;
  };
  SATISMETER_WRITE_KEY?: string; // user satisfaction meter
  GTAG_ID?: string; // Google Tag monitoring
  PARDOT_ACCOUNT_ID?: string; // Pardot account ID
  USERPILOT_APP_TOKEN?: string; // UserPilot App Token
  SALESFORCE: {
    apiUrl: string;
    siteUrl: string;
    id: string;
    recordType: string; // we have different case “types” (Support Case, Compliance Case, etc), and this is the value recordId for Support Cases
    deployment: string;
    eswUrl: string;
    chatDetails: {
      baseLiveAgentContentURL: string;
      deploymentId: string;
      buttonId: string;
      baseLiveAgentURL: string;
      eswLiveAgentDevName: string;
      isOfflineSupportEnabled: boolean;
    };
  };
  ENV?: "local" | "staging" | "production";
  BUILD_VERSION?: string;
};

const config: Configuration = {
  ENV,
  BUILD_VERSION,
  DOMAIN: "trolley.com",
  WS_SERVER_ADDRESS: "wss://api.trolley.com",
  SERVER_ADDRESS: "https://api.trolley.com",
  WIDGET_ADDRESS: "https://widget.trolley.com",
  HEADWAY_ACCOUNT_ID: "7XkeVx",
  ADYEN: {
    environment: "test",
    clientKey: "test_NAF6GEG36FANRCRWB4D5TJZFQMRS73PZ",
    // apiKey: "AQEphmfxK4zJbBNDw0m/n3Q5qf3VfJlCAZ9PQYOhoucTeRqbqsw4XXcM4fwQwV1bDb7kfNy1WIxIIkxgBw==-gObnGWjqV2DKMm6vcLTcHg+6ad9uV5T6lngrPspMQ/w=-~CEDg94Q+ct,K7AG",
  },
  CHARGEBEE: {
    site: "paymentrails-test",
    publishableKey: "test_UI3LbQurqxIvS2Ghpo7bTUf3k9iSzK9O",
  },
  ...configEnv[ENV],
};

export default config;
