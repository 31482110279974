/* istanbul ignore file */
import { useEffect } from "react";
import { loadDnsRecord, loadWhiteLabel, WhiteLabel, WhiteLabelDns } from "store/actions/whiteLabel";
import { useShallowSelector } from "store/hooks";
import { BaseStatus } from "store/reducers/standardReducer";

export function isWhiteLabelConfigured(whiteLabel: WhiteLabel | undefined) {
  if (whiteLabel) {
    // the minimum set of information an email template requires.
    return !!(whiteLabel.email && whiteLabel.supportEmail && whiteLabel.businessName && whiteLabel.website && whiteLabel.color && whiteLabel.address);
  }

  return false;
}

export function useWhiteLabel() {
  useEffect(() => {
    loadWhiteLabel();
  }, []);

  return useShallowSelector((state) => {
    if (state.whiteLabel.fetchStatus.data === undefined) {
      loadWhiteLabel();
    }

    const whiteLabel = state.whiteLabel.entities.data;

    return {
      isConfigured: isWhiteLabelConfigured(whiteLabel),
      data: whiteLabel,
      status: state.whiteLabel.fetchStatus.data,
      error: state.whiteLabel.errors.data,
    };
  });
}

export function isWhiteLabelDnsConfigured(whiteLabelDns: WhiteLabelDns | undefined, status: BaseStatus | undefined) {
  return !!(whiteLabelDns && status !== BaseStatus.ERROR);
}

export function useDnsRecord() {
  useEffect(() => {
    loadDnsRecord();
  }, []);

  return useShallowSelector((state) => {
    if (state.whiteLabelDns.fetchStatus.data === undefined) {
      loadDnsRecord();
    }

    const whiteLabelDns = state.whiteLabelDns.entities.data;

    return {
      isConfigured: isWhiteLabelDnsConfigured(whiteLabelDns, state.whiteLabelDns.fetchStatus.data),
      data: whiteLabelDns,
      status: state.whiteLabelDns.fetchStatus.data,
      error: state.whiteLabelDns.errors.data,
    };
  });
}
