import { useEffect, useState } from "react";
import { computeID } from "store/actions/actionUtils";
import { loadRecipient, loadRecipients, RecipientsQuery, StoreRecipient } from "store/actions/recipients";
import { emptyList } from "store/reducers/standardReducer";
import { useShallowSelector } from ".";

export function useRecipient(recipientId?: string | null) {
  const id = recipientId && /^R-\w+/.test(recipientId) ? recipientId : "";

  useEffect(() => {
    if (id) {
      loadRecipient(id);
    }
  }, [id]);

  return useShallowSelector((state) => {
    if (id && state.recipient.fetchStatus[id] === undefined) {
      loadRecipient(id);
    }

    return {
      data: state.recipient.entities[id],
      status: state.recipient.fetchStatus[id],
      error: state.recipient.errors[id],
    };
  });
}

export function useRecipientList(query: RecipientsQuery, optionalLoad = true) {
  const [fetchId, setFetchId] = useState("");

  useEffect(() => {
    if (optionalLoad) {
      setFetchId(loadRecipients(query));
    } else {
      setFetchId("");
    }
  }, [computeID(query), optionalLoad]);

  return useShallowSelector((state) => {
    if (fetchId && optionalLoad && state.recipients.fetchStatus[fetchId] === undefined) {
      loadRecipients(query);
    }

    const data = state.recipients.entities[fetchId];

    return {
      data: data
        ? {
            records: data.records.map((id) => state.recipient.entities[id]).filter((v) => v) as StoreRecipient[],
            meta: data.meta,
          }
        : emptyList,
      status: state.recipients.fetchStatus[fetchId],
      error: state.recipients.errors[fetchId],
    };
  });
}
