import { Radio, SinglePage, Typography } from "components";
import React from "react";
import colors from "style/colors";

export default function Demo() {
  return (
    <SinglePage
      style={{ minHeight: "100vh" }}
      prefixCls="tr-demo" // this is required, otherwise the new style will clash with the old style.
      components={(token) => ({
        Layout: {
          colorBgHeader: colors.red, // this overwrite the default header background
        },
        Radio: {
          fontSize: 24,
          fontSizeLG: 24, // used for radioSize
        },
      })}
      header={<>Header</>}
    >
      <Typography.Title>Heading H1</Typography.Title>
      <Typography.Title level={2}>Heading H2</Typography.Title>
      <Radio.Group
        optionType="card"
        direction="horizontal"
        options={[
          { label: "Yes, do this", value: "a" },
          { label: "No, blah blah", value: "b" },
        ]}
      />
    </SinglePage>
  );
}
