import { MerchantStatus, RoyaltyCalculatorIntegrations } from "@trolley/common-frontend";
import { Button, ButtonDropdown, Icon, Menu, Text } from "components";
import { MerchantPicker } from "features/merchant";
import { AddPaymentsPopup } from "features/payment";
import { AddRoyaltiesPopup } from "features/royalty";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useMerchantSettings } from "store/hooks/merchantSettings";
import { usePayoutMethods } from "store/hooks/payoutMethods";
import { useRoyaltyCalculatorsIntegrations } from "store/hooks/royaltyIntegrations";
import { BaseStatus } from "store/reducers/standardReducer";
import { useWindowSize } from "utils/hooks";

const BUTTON_WIDTH = 220;

export default function AddPaymentButton() {
  const { isMobile } = useWindowSize();
  const history = useHistory();
  const { data: merchantSettings, features } = useMerchantSettings();
  const { data: payoutMethods } = usePayoutMethods();
  const { data: calculatorsData, status: calculatorsStatus } = useRoyaltyCalculatorsIntegrations();
  const [showCurveImport, setShowCurveImport] = useState(false);
  const [showVistexImport, setShowVistexImport] = useState(false);
  const [showUpload, setShowUpload] = useState(false);
  const [showUploadRoyalties, setShowUploadRoyalties] = useState<RoyaltyCalculatorIntegrations | null>(null);
  const [showMerchantSelector, setShowMerchantSelector] = useState(false);
  const enableMerchantSwitch =
    !!features.syncRecipients && !merchantSettings?.parentMerchantId && payoutMethods.length && payoutMethods.every((pm) => !pm.enabled);

  useEffect(() => {
    if (calculatorsStatus === BaseStatus.LOADED) {
      const showCurve = calculatorsData.some((settingsData) => settingsData.integration === RoyaltyCalculatorIntegrations.CURVE && settingsData.enabled);
      const showVistex = calculatorsData.some((settingsData) => settingsData.integration === RoyaltyCalculatorIntegrations.VISTEX && settingsData.enabled);
      setShowCurveImport(showCurve);
      setShowVistexImport(showVistex);
    }
  }, [calculatorsData, calculatorsStatus]);

  function onCreatePayment() {
    history.push("/payments/new");
  }

  return enableMerchantSwitch ? (
    <>
      <Button
        size="large"
        type="primary"
        icon={<Icon type="plus" />}
        onClick={() => {
          setShowMerchantSelector(true);
        }}
      >
        Create Payment
      </Button>
      <MerchantPicker
        title={<Text size="large">Payments can only be created from submerchants</Text>}
        visible={showMerchantSelector}
        onClose={() => {
          setShowMerchantSelector(false);
        }}
        merchantFilter={(m) => !!m.parentID && !m.sandbox}
        disabledMerchant={(m) => m.status !== MerchantStatus.APPROVED}
      />
    </>
  ) : (
    <>
      <ButtonDropdown
        onClick={onCreatePayment}
        type="primary"
        size="large"
        style={{ width: isMobile ? "100%" : BUTTON_WIDTH }}
        overlay={
          <Menu
            onClick={(e) => {
              switch (e.key) {
                case "manual":
                  onCreatePayment();
                  break;
                case "recent-uploads":
                  history.push("/payments/upload");
                  break;
                case "upload":
                  setShowUpload(true);
                  break;
                case "upload-royalty-curve":
                  setShowUploadRoyalties(RoyaltyCalculatorIntegrations.CURVE);
                  break;
                case "recent-uploads-curve":
                  history.push("/payments/upload/curve/");
                  break;
                case "upload-royalty-vistex":
                  setShowUploadRoyalties(RoyaltyCalculatorIntegrations.VISTEX);
                  break;
                case "recent-uploads-vistex":
                  history.push("/payments/upload/vistex");
                  break;
              }
            }}
            style={{ width: isMobile ? "auto" : BUTTON_WIDTH }}
          >
            <Menu.Item key="manual">
              <Icon type="plus" left />
              Create Payment Manually
            </Menu.Item>
            <Menu.Item key="upload">
              <Icon type="cloud-arrow-up" left />
              <Text inline>
                Upload{" "}
                <Text inline weight="bold">
                  Payments
                </Text>{" "}
                CSV file
              </Text>
            </Menu.Item>
            <Menu.Item key="recent-uploads">
              <Icon type="history" left />
              Recent{" "}
              <Text inline weight="bold">
                Payments
              </Text>{" "}
              Uploaded files
            </Menu.Item>
            {showCurveImport && (
              <Menu.Item key="upload-royalty-curve">
                <Icon type="cloud-arrow-up" left />
                Upload{" "}
                <Text inline weight="bold">
                  Curve
                </Text>{" "}
                file
              </Menu.Item>
            )}
            {showCurveImport && (
              <Menu.Item key="recent-uploads-curve">
                <Icon type="history" left />
                Recent{" "}
                <Text inline weight="bold">
                  Curve
                </Text>{" "}
                Uploaded files
              </Menu.Item>
            )}
            {showVistexImport && (
              <Menu.Item key="upload-royalty-vistex">
                <Icon type="cloud-arrow-up" left />
                Upload{" "}
                <Text inline weight="bold">
                  Vistex
                </Text>{" "}
                file
              </Menu.Item>
            )}
            {showVistexImport && (
              <Menu.Item key="recent-uploads-vistex">
                <Icon type="history" left />
                Recent{" "}
                <Text inline weight="bold">
                  Vistex
                </Text>{" "}
                Uploaded files
              </Menu.Item>
            )}
          </Menu>
        }
      >
        <Icon type="plus" left />
        Create Payment
      </ButtonDropdown>

      <AddPaymentsPopup
        visible={showUpload}
        onClose={() => {
          setShowUpload(false);
        }}
      />

      <AddRoyaltiesPopup
        visible={!!showUploadRoyalties}
        calculator={showUploadRoyalties}
        onClose={() => {
          setShowUploadRoyalties(null);
        }}
      />
    </>
  );
}
