import { useEffect, useState } from "react";
import { DAC7EoyTax, DAC7EoyTaxQuery, loadDac7EoyTaxForm, loadDac7EoyTaxForms } from "store/actions/dac7EoyTaxForms";
import { emptyList } from "store/reducers/standardReducer";
import { useDeepCompareEffect } from "utils/hooks";
import { useShallowSelector } from ".";

export function useDac7EoyTaxForms(taxYear: number, query: DAC7EoyTaxQuery) {
  const [fetchId, setFetchId] = useState("");
  const year = taxYear && /^\d{4}$/.test(String(taxYear)) ? taxYear : "";

  useDeepCompareEffect(() => {
    if (year) {
      setFetchId(loadDac7EoyTaxForms({ ...query, taxYear }));
    }
  }, [year, query]);

  return useShallowSelector((state) => {
    if (year && fetchId && state.dac7EoyTaxForms.fetchStatus[fetchId] === undefined) {
      loadDac7EoyTaxForms({ ...query, taxYear: year });
    }

    const data = state.dac7EoyTaxForms.entities[fetchId];

    return {
      data: data
        ? {
            records: data.records.map((id) => state.dac7EoyTaxForm.entities[id]).filter((v) => v) as DAC7EoyTax[],
            meta: data.meta,
          }
        : emptyList,
      status: state.dac7EoyTaxForms.fetchStatus[fetchId],
      error: state.dac7EoyTaxForms.errors[fetchId],
    };
  });
}

export function useDac7EoyTaxForm(dac7EoyTaxId?: string) {
  const id = dac7EoyTaxId && /^DRET-\w+/.test(dac7EoyTaxId) ? dac7EoyTaxId : "";

  useEffect(() => {
    if (id) {
      loadDac7EoyTaxForm(id);
    }
  }, [id]);

  return useShallowSelector((state) => {
    if (id && state.dac7EoyTaxForm.fetchStatus[id] === undefined) {
      loadDac7EoyTaxForm(id);
    }

    return {
      data: state.dac7EoyTaxForm.entities[id],
      status: state.dac7EoyTaxForm.fetchStatus[id],
      error: state.dac7EoyTaxForm.errors[id],
    };
  });
}

export function useRecipientDac7EoyTaxForms(recipientId: string) {
  const [fetchId, setFetchId] = useState("");

  useEffect(() => {
    if (recipientId) {
      setFetchId(loadDac7EoyTaxForms({ recipientId }));
    }
  }, [recipientId]);

  return useShallowSelector((state) => {
    if (recipientId && fetchId && state.dac7EoyTaxForms.fetchStatus[fetchId] === undefined) {
      loadDac7EoyTaxForms({ recipientId });
    }

    const data = state.dac7EoyTaxForms.entities[fetchId];

    return {
      data: data
        ? {
            records: data.records.map((id) => state.dac7EoyTaxForm.entities[id]).filter((v) => v) as DAC7EoyTax[],
            meta: data.meta,
          }
        : emptyList,
      status: state.dac7EoyTaxForms.fetchStatus[fetchId],
      error: state.dac7EoyTaxForms.errors[fetchId],
    };
  });
}
