import { CountryCode } from "@trolley/common-frontend";
import { useEffect } from "react";

import { CountryCurrency, loadCurrencies } from "store/actions/currencies";
import { useShallowSelector } from ".";

const defaultCountryCurrencies = Object.freeze(
  Object.values(CountryCode).reduce((acc, currencyCode) => {
    acc[currencyCode] = [];

    return acc;
  }, {} as Record<CountryCode, CountryCurrency[]>),
);

export function useCurrencies() {
  useEffect(() => {
    loadCurrencies();
  }, []);

  return useShallowSelector((state) => {
    if (state.currencies.fetchStatus.data === undefined) {
      loadCurrencies();
    }

    return {
      data: state.currencies.entities.data || defaultCountryCurrencies,
      status: state.currencies.fetchStatus.data,
      error: state.currencies.errors.data,
    };
  });
}
