import { InputProps as AntInputProps, Input as InputAnt, InputRef } from "antd";
import Icon from "components/Icon";
import React, { ForwardRefExoticComponent, PropsWithoutRef, Ref, forwardRef } from "react";
import css, { classnames, createUseStyle } from "style/classname";
import { MEDIA_BREAKPOINTS } from "style/variables";

export interface InputProps extends Omit<AntInputProps, "allowClear"> {
  allowClear?: boolean;
  onClear?: () => void;
}
const Input = forwardRef<InputRef, InputProps>((props, ref) => {
  const { onFocus, allowClear, onClear, className, ...rest } = props;
  const styledInput = useStyledInput(props);

  return (
    <InputAnt
      onFocus={
        onFocus ??
        ((e) => {
          if (!rest.readOnly) {
            e?.target?.select?.();
          }
        })
      }
      ref={ref}
      allowClear={
        allowClear && {
          clearIcon: (
            <Icon
              type="circle-xmark"
              onClick={(e) => {
                e?.stopPropagation?.();
                e?.preventDefault?.();
                onClear?.();
              }}
              color="grey"
              theme="solid"
            />
          ),
        }
      }
      className={classnames(styledInput, className)}
      {...rest}
    />
  );
}) as ForwardRefExoticComponent<PropsWithoutRef<InputProps> & { ref?: Ref<InputRef> }> & {
  Group: typeof InputAnt.Group;
  TextArea: typeof InputAnt.TextArea;
};

Input.displayName = "Input";
Input.Group = InputAnt.Group;
Input.TextArea = InputAnt.TextArea;
export default Input;

export const styledRemoveIcon = css`
  ${MEDIA_BREAKPOINTS.mdUp} {
    .icon.fa-circle-xmark {
      opacity: 0;
      transition: opacity 0.175s ease;
    }
    &:hover {
      .icon.fa-circle-xmark {
        opacity: 1;
      }
    }
  }
`();

const useStyledInput = createUseStyle<InputProps>(({ theme, ...props }) =>
  css`
    &:not(.${theme.prefixCls}-input-group-wrapper),
    .${theme.prefixCls}-input-affix-wrapper {
      box-shadow: ${theme.boxShadowInset};
    }

    ${() =>
      props.readOnly &&
      `
      padding-left: 0;
      padding-right: 0;
      border-color: transparent !important;
      box-shadow: none !important;
      background-color: transparent;
    `}

    &[inputmode="decimal"],
    [inputmode="decimal"] {
      text-align: right;
    }
  `(),
);
