import { Access } from "@trolley/common-frontend";
import { Space, Spinner, Timeline } from "components";
import { PaymentDisplay, PaymentLoader, StatusPayment } from "features/payment";
import React, { useState } from "react";
import { InvoicePayment } from "store/actions/invoicePayments";
import { Payment } from "store/actions/payments";
import { useAccess } from "store/hooks/user";
import { BaseStatus } from "store/reducers/standardReducer";
import { ANIMATION } from "style/variables";
import { entriesGroupBy } from "utils/helpers";
import InvoicePaymentsEdit from "./InvoicePaymentsEdit";

interface Props {
  invoicePayments: InvoicePayment[];
}

export default function InvoicePaymentsTimeline(props: Props) {
  const { invoicePayments } = props;
  const accessPaymentWrite = useAccess(Access.PAYMENTS_WRITE);
  const [editPayment, setEditPayment] = useState<Payment | undefined>();
  const uniqueInvoices = entriesGroupBy(invoicePayments, (ip) => ip.paymentId);

  if (!invoicePayments.length) {
    return null;
  }

  return (
    <>
      <Timeline
        style={{ animation: `${ANIMATION.fadeInDown} 0.375s ease`, transformOrigin: "top center", marginTop: "16px" }}
        items={uniqueInvoices.map(([paymentId, ips]) => ({
          key: paymentId,
          children: (
            <PaymentLoader id={paymentId}>
              {({ data: payment, status }) =>
                payment?.status !== "deleted" && (
                  <>
                    {payment ? (
                      <>
                        <Space justify="space-between" wrap={false} align="start">
                          <PaymentDisplay paymentId={payment.id} showDate />
                          <StatusPayment payment={payment} />
                        </Space>
                        {accessPaymentWrite && (
                          <a
                            role="button"
                            onClick={() => {
                              setEditPayment(payment);
                            }}
                          >
                            Show Payment Allocation
                          </a>
                        )}
                      </>
                    ) : status === BaseStatus.LOADING ? (
                      <>
                        Loading payment...
                        <Spinner left color="primary" />
                      </>
                    ) : null}
                  </>
                )
              }
            </PaymentLoader>
          ),
        }))}
      />

      <InvoicePaymentsEdit
        payment={editPayment}
        onClose={() => {
          setEditPayment(undefined);
        }}
      />
    </>
  );
}
