import { useEffect } from "react";
import { useShallowSelector } from ".";
import { loadDAC7TaxMerchantYear, DAC7TaxMerchantYear } from "store/actions/dac7TaxMerchantYear";

export function useDAC7TaxMerchantYear(year: number | undefined) {
  const id = year && /^\d{4}$/.test(String(year)) ? year : "";

  useEffect(() => {
    if (id) {
      loadDAC7TaxMerchantYear(id);
    }
  }, [year]);

  return useShallowSelector((state) => {
    if (id && state.dac7TaxMerchantYear.fetchStatus[id] === undefined) {
      loadDAC7TaxMerchantYear(id);
    }

    return {
      data: state.dac7TaxMerchantYear.entities[id] as DAC7TaxMerchantYear | undefined,
      status: state.dac7TaxMerchantYear.fetchStatus[id],
      error: state.dac7TaxMerchantYear.errors[id],
    };
  });
}
