import store from "store";
import { OpCode, standardDispatch } from "store/dispatcher";
import * as request from "services/request";
import * as types from "@trolley/common-frontend";
import { isLoaded } from "./actionUtils";

export { PaymentErrorType, RecipientErrorType } from "@trolley/common-frontend";
export interface SummaryInfo extends types.SummaryInfo {}
export interface BatchSummary extends Omit<types.BatchSummary, "accounts"> {}

export function loadBatchSummary(id: string, force?: boolean) {
  const state = store.getState().batchSummary;

  if (force || !isLoaded(state.fetchStatus[id])) {
    standardDispatch(OpCode.LOADING, "batchSummary", { id });

    request
      .GET<types.BatchSummaryResult>(`/v1/batches/${id}/summary`, { query: { extendedSummary: true } })
      .then((res) => {
        standardDispatch(OpCode.DATA, "batchSummary", {
          id,
          data: res.body.batchSummary,
        });
      })
      .catch((errors) => {
        standardDispatch(OpCode.ERROR, "batchSummary", { id, errors });
      });
  }
}
