import * as types from "@trolley/common-frontend";
import { uniqueList } from "@trolley/common-frontend";
import { batch as reduxBatch } from "react-redux";
import * as request from "services/request";
import store from "store";
import { OpCode, standardDispatch } from "store/dispatcher";
import { queueFactory } from "utils/factories";
import { Query } from "utils/hooks";
import { computeID, groupRecipients, isLoaded } from "./actionUtils";

export interface RecipientsQuery extends Query<Recipient> {
  id?: string[]; // recipient GUID

  search?: string;

  status?: string[];
  country?: string[];
  payoutMethod?: string[];
  payoutCurrency?: string[];
  currency?: string[];
  taxFormStatus?: string[];
}

export interface Recipient extends types.Recipient {}

export type StoreRecipient = Omit<Recipient, "accounts"> & {
  accounts: string[];
};

export type RecipientUpdate = {
  referenceId?: string;
  email?: string;
  name?: string;
  lastName?: string;
  firstName?: string;
  type?: string;
  status?: string;
  dob?: string;
  placeOfBirth?: string;
  passport?: string;
  address?: {
    street1?: string;
    street2?: string;
    city?: string;
    postalCode?: string;
    country?: string;
    region?: string;
    phone?: string;
  };
  governmentIds?: {
    country: types.CountryCode | null;
    type: string | null;
    value: string;
  }[];

  tags?: string[];
  isPortalUser?: boolean;
  contactEmails?: string[];
};

export const RecipientsQueryArrayKeys = [
  "id",
  "status",
  "country",
  "payoutMethod",
  "payoutCurrency",
  "currency",
  "complianceStatus",
  "taxFormStatus",
  "riskScoreLabel",
];

const recipientQueueLoader = queueFactory(
  (recipientIds) => {
    request
      .POST<types.RecipientListResponse>("/v1/recipients/search", {
        query: {
          ids: recipientIds,
          pageSize: recipientIds.length,
          status: [
            types.RecipientStatus.ACTIVE,
            types.RecipientStatus.INCOMPLETE,
            types.RecipientStatus.ARCHIVED, // we need to explicitly enum all status because by default it doesn't search archived recipients
            types.RecipientStatus.DISABLED,
            types.RecipientStatus.SUSPENDED,
            types.RecipientStatus.BLOCKED,
          ],
        },
      })
      .then(({ body }) => {
        const { recipients, recipientAccounts } = groupRecipients(body.recipients);

        reduxBatch(() => {
          standardDispatch(OpCode.DATA, "recipient", {
            bulk: recipientIds.reduce((acc, id) => {
              acc[id] = recipients[id] ?? undefined;

              return acc;
            }, {}),
          });
          standardDispatch(OpCode.DATA, "recipientAccount", {
            bulk: recipientAccounts,
          });
        });
      })
      .catch((errors) => {
        standardDispatch(OpCode.ERROR, "recipient", {
          ids: recipientIds,
          errors,
        });
      });
  },
  (id) => /^R-\w+/.test(id),
);

export function loadRecipient(id: string, force?: boolean) {
  const { recipient } = store.getState();

  if (force || !isLoaded(recipient.fetchStatus[id])) {
    standardDispatch(OpCode.LOADING, "recipient", { id });
    recipientQueueLoader.add(id);
  }
}

export async function createRecipient(data: RecipientUpdate) {
  standardDispatch(OpCode.LOADING, "recipient");
  try {
    const { body } = await request.POST<types.RecipientResponse>("/v1/recipients/create", { query: data });
    standardDispatch(OpCode.RESET, "recipients"); // reset list of recipients because pagination will be different

    return body.recipient;
  } catch (errors) {
    standardDispatch(OpCode.ERROR, "recipient", {
      errors,
    });
    throw errors;
  }
}

// Update the recipient with only the provided fields
export async function updateRecipient(id: string, change: RecipientUpdate) {
  standardDispatch(OpCode.LOADING, "recipient", { id });
  try {
    const { body } = await request.POST<types.RecipientResponse>("/v1/recipients/update", { query: { ...change, id } });
    const {
      recipients: { [id]: recipient },
      recipientAccounts,
    } = groupRecipients([body.recipient]);

    reduxBatch(() => {
      standardDispatch(OpCode.DATA, "recipient", {
        id,
        data: recipient,
      });

      standardDispatch(OpCode.DATA, "recipientAccount", {
        bulk: recipientAccounts,
      });
    });

    return recipient;
  } catch (errors) {
    standardDispatch(OpCode.ERROR, "recipient", {
      errors,
      id,
    });
    throw errors;
  }
}

export async function overrideRecipientIdvStatus(recipientId: string) {
  try {
    const { body } = await request.POST<types.RecipientListResponse>("/v1/idv/override", {
      query: {
        recipientIds: [recipientId],
      },
    });

    const { recipients, recipientAccounts } = groupRecipients(body.recipients);

    reduxBatch(() => {
      standardDispatch(OpCode.DATA, "recipient", {
        bulk: recipients,
      });

      standardDispatch(OpCode.DATA, "recipientAccount", {
        bulk: recipientAccounts,
      });
    });
  } catch (errors) {
    standardDispatch(OpCode.ERROR, "recipient", {
      errors,
      id: recipientId,
    });
    throw errors;
  }
}

export async function updateRecipientStatus(
  rawIds: string[],
  status: types.RecipientStatus.ACTIVE | types.RecipientStatus.DISABLED | types.RecipientStatus.SUSPENDED | "unarchived",
) {
  const ids = rawIds.filter((id) => /^R-\w+/.test(id));

  if (ids.length) {
    standardDispatch(OpCode.LOADING, "recipient", {
      ids,
    });
    try {
      const { body } = await request.POST<types.RecipientListResponse>("/v1/recipients/status", {
        query: {
          ids,
          status,
        },
      });

      const { recipients, recipientAccounts } = groupRecipients(body.recipients);

      reduxBatch(() => {
        standardDispatch(OpCode.DATA, "recipient", {
          bulk: recipients,
        });

        standardDispatch(OpCode.DATA, "recipientAccount", {
          bulk: recipientAccounts,
        });
      });
    } catch (errors) {
      standardDispatch(OpCode.ERROR, "recipient", {
        errors,
        ids,
      });

      throw errors;
    }
  }
}

// Delete a list of recipients
export async function archiveRecipient(ids: string[]) {
  await request.DELETE("/v1/recipients", { deleteBody: { ids } });
  standardDispatch(OpCode.UPDATE, "recipient", {
    bulk: ids.reduce((acc, id) => {
      acc[id] = {
        status: types.RecipientStatus.ARCHIVED,
      };

      return acc;
    }, {}),
  });
}

// * sends an email to a recipient requesting their banking information
export async function solicitRecipients(rawIds: string[], template?: types.EmailTemplate) {
  const ids = uniqueList(rawIds);
  try {
    standardDispatch(OpCode.LOADING, "recipient", { ids });
    await request.POST(`/v1/recipients/solicit`, {
      query: {
        id: ids,
        template,
      },
    });
    standardDispatch(OpCode.LOADING, "recipient", { ids, loading: false });
    standardDispatch(OpCode.RESET, "messages");
  } catch (errors) {
    standardDispatch(OpCode.LOADING, "recipient", { ids, loading: false });
    standardDispatch(OpCode.ERROR, "recipient", { errors });
    throw errors;
  }
}

export function resetRecipients() {
  standardDispatch(OpCode.RESET, "recipients");
}

export function loadRecipients(rawQuery: RecipientsQuery, force?: boolean): string {
  const { recipients } = store.getState();
  const query = { ...rawQuery };
  if (!rawQuery.startDate && !rawQuery.endDate && rawQuery.dateColumn) {
    // if startDate and endDate is not set, querying with dateColumn is pointless
    delete query.dateColumn;
  }

  const id = computeID(query);

  if (force || !isLoaded(recipients.fetchStatus[id])) {
    standardDispatch(OpCode.LOADING, "recipients", { id });

    request
      .POST<types.RecipientListResponse>("/v1/recipients/search", { query })
      .then(({ body }) => {
        const { ids, recipients, recipientAccounts } = groupRecipients(body.recipients);

        reduxBatch(() => {
          standardDispatch(OpCode.DATA, "recipients", {
            id,
            data: { records: ids, meta: body.meta },
          });
          standardDispatch(OpCode.DATA, "recipient", {
            bulk: recipients,
          });
          standardDispatch(OpCode.DATA, "recipientAccount", {
            bulk: recipientAccounts,
          });
        });
      })
      .catch((errors) => {
        standardDispatch(OpCode.ERROR, "recipients", {
          errors,
          id,
        });
      });
  }

  return id;
}
