import styled from "style/classname";
import { Props } from "./index";
import flags24 from "./flags24.png";
import flags32 from "./flags32.png";

export const styledFlagWrapper = styled`
  display: inline;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  .flag-suffix {
    margin-left: 4px;
  }
`();

export const styledFlagIcon = styled`
display: inline-block;
box-sizing: content-box;
overflow: hidden;
margin-right: 4px;
border-radius: 50%;
width: ${(props: Props) => (props.size === "large" ? "20px" : "16px")};
height: ${(props: Props) => (props.size === "large" ? "20px" : "16px")};
margin-bottom: -0.15em;

.flag {
  display: block;
  background-repeat: no-repeat;
  margin-left: ${(props: Props) => (props.size === "large" ? ` -6px` : "-4px")};
  margin-top: ${(props: Props) => (props.size === "large" ? "-6px" : "-4px")};
  &.flag-xx { // unkonwn flag
    background-image: none;
    background-color: black;
  }
  ${(props: Props) =>
    !props.size &&
    `
    width: 24px;
    height: 24px;
    background-image: url(${flags24});
    &.flag-ad { background-position: 0 0; }
    &.flag-ae { background-position: -24px 0; }
    &.flag-af { background-position: -48px 0; }
    &.flag-ag { background-position: -72px 0; }
    &.flag-ai { background-position: -96px 0; }
    &.flag-al { background-position: -120px 0; }
    &.flag-am { background-position: -144px 0; }
    &.flag-an { background-position: -168px 0; }
    &.flag-ao { background-position: -192px 0; }
    &.flag-ar { background-position: -216px 0; }
    &.flag-as { background-position: -240px 0; }
    &.flag-at { background-position: -264px 0; }
    &.flag-au { background-position: -288px 0; }
    &.flag-aw { background-position: -312px 0; }
    &.flag-ax { background-position: -336px 0; }
    &.flag-az { background-position: -360px 0; }
    &.flag-ba { background-position: 0 -24px; }
    &.flag-bb { background-position: -24px -24px; }
    &.flag-bd { background-position: -48px -24px; }
    &.flag-be { background-position: -72px -24px; }
    &.flag-bf { background-position: -96px -24px; }
    &.flag-bg { background-position: -120px -24px; }
    &.flag-bh { background-position: -144px -24px; }
    &.flag-bi { background-position: -168px -24px; }
    &.flag-bj { background-position: -192px -24px; }
    &.flag-bl { background-position: -216px -24px; }
    &.flag-bm { background-position: -240px -24px; }
    &.flag-bn { background-position: -264px -24px; }
    &.flag-bo { background-position: -288px -24px; }
    &.flag-br { background-position: -312px -24px; }
    &.flag-bs { background-position: -336px -24px; }
    &.flag-bt { background-position: -360px -24px; }
    &.flag-bw { background-position: 0 -48px; }
    &.flag-by { background-position: -24px -48px; }
    &.flag-bz { background-position: -48px -48px; }
    &.flag-ca { background-position: -72px -48px; }
    &.flag-cd { background-position: -96px -48px; }
    &.flag-cf { background-position: -120px -48px; }
    &.flag-cg { background-position: -144px -48px; }
    &.flag-ch { background-position: -168px -48px; }
    &.flag-ci { background-position: -192px -48px; }
    &.flag-ck { background-position: -216px -48px; }
    &.flag-cl { background-position: -240px -48px; }
    &.flag-cm { background-position: -264px -48px; }
    &.flag-cn { background-position: -288px -48px; }
    &.flag-co { background-position: -312px -48px; }
    &.flag-cr { background-position: -336px -48px; }
    &.flag-cu { background-position: -360px -48px; }
    &.flag-cv { background-position: 0 -72px; }
    &.flag-cw { background-position: -24px -72px; }
    &.flag-cy { background-position: -48px -72px; }
    &.flag-cz { background-position: -72px -72px; }
    &.flag-de { background-position: -96px -72px; }
    &.flag-dj { background-position: -120px -72px; }
    &.flag-dk { background-position: -144px -72px; }
    &.flag-dm { background-position: -168px -72px; }
    &.flag-do { background-position: -192px -72px; }
    &.flag-dz { background-position: -216px -72px; }
    &.flag-ec { background-position: -240px -72px; }
    &.flag-ee { background-position: -264px -72px; }
    &.flag-eg { background-position: -288px -72px; }
    &.flag-eh { background-position: -312px -72px; }
    &.flag-er { background-position: -336px -72px; }
    &.flag-es { background-position: -360px -72px; }
    &.flag-et { background-position: 0 -96px; }
    &.flag-eu { background-position: -24px -96px; }
    &.flag-fi { background-position: -48px -96px; }
    &.flag-fj { background-position: -72px -96px; }
    &.flag-fk { background-position: -96px -96px; }
    &.flag-fm { background-position: -120px -96px; }
    &.flag-fo { background-position: -144px -96px; }
    &.flag-fr { background-position: -168px -96px; }
    &.flag-re { background-position: -168px -96px; }
    &.flag-ga { background-position: -192px -96px; }
    &.flag-gb { background-position: -216px -96px; }
    &.flag-gd { background-position: -240px -96px; }
    &.flag-ge { background-position: -264px -96px; }
    &.flag-gf { background-position: -168px -96px; }
    &.flag-gg { background-position: -288px -96px; }
    &.flag-gh { background-position: -312px -96px; }
    &.flag-gi { background-position: -336px -96px; }
    &.flag-gl { background-position: -360px -96px; }
    &.flag-gm { background-position: 0 -120px; }
    &.flag-gn { background-position: -24px -120px; }
    &.flag-gp { background-position: -168px -96px; }
    &.flag-gq { background-position: -48px -120px; }
    &.flag-gr { background-position: -72px -120px; }
    &.flag-gs { background-position: -96px -120px; }
    &.flag-gt { background-position: -120px -120px; }
    &.flag-gu { background-position: -144px -120px; }
    &.flag-gw { background-position: -168px -120px; }
    &.flag-gy { background-position: -192px -120px; }
    &.flag-hk { background-position: -216px -120px; }
    &.flag-hn { background-position: -240px -120px; }
    &.flag-hr { background-position: -264px -120px; }
    &.flag-ht { background-position: -288px -120px; }
    &.flag-hu { background-position: -312px -120px; }
    &.flag-ic { background-position: -336px -120px; }
    &.flag-id { background-position: -360px -120px; }
    &.flag-ie { background-position: 0 -144px; }
    &.flag-il { background-position: -24px -144px; }
    &.flag-im { background-position: -48px -144px; }
    &.flag-in { background-position: -72px -144px; }
    &.flag-iq { background-position: -96px -144px; }
    &.flag-ir { background-position: -120px -144px; }
    &.flag-is { background-position: -144px -144px; }
    &.flag-it { background-position: -168px -144px; }
    &.flag-je { background-position: -192px -144px; }
    &.flag-jm { background-position: -216px -144px; }
    &.flag-jo { background-position: -240px -144px; }
    &.flag-jp { background-position: -264px -144px; }
    &.flag-ke { background-position: -288px -144px; }
    &.flag-kg { background-position: -312px -144px; }
    &.flag-kh { background-position: -336px -144px; }
    &.flag-ki { background-position: -360px -144px; }
    &.flag-km { background-position: 0 -168px; }
    &.flag-kn { background-position: -24px -168px; }
    &.flag-kp { background-position: -48px -168px; }
    &.flag-kr { background-position: -72px -168px; }
    &.flag-kw { background-position: -96px -168px; }
    &.flag-ky { background-position: -120px -168px; }
    &.flag-kz { background-position: -144px -168px; }
    &.flag-la { background-position: -168px -168px; }
    &.flag-lb { background-position: -192px -168px; }
    &.flag-lc { background-position: -216px -168px; }
    &.flag-li { background-position: -240px -168px; }
    &.flag-lk { background-position: -264px -168px; }
    &.flag-lr { background-position: -288px -168px; }
    &.flag-ls { background-position: -312px -168px; }
    &.flag-lt { background-position: -336px -168px; }
    &.flag-lu { background-position: -360px -168px; }
    &.flag-lv { background-position: 0 -192px; }
    &.flag-ly { background-position: -24px -192px; }
    &.flag-ma { background-position: -48px -192px; }
    &.flag-mc { background-position: -72px -192px; }
    &.flag-md { background-position: -96px -192px; }
    &.flag-me { background-position: -120px -192px; }
    &.flag-mf { background-position: -144px -192px; }
    &.flag-mg { background-position: -168px -192px; }
    &.flag-mh { background-position: -192px -192px; }
    &.flag-mk { background-position: -216px -192px; }
    &.flag-ml { background-position: -240px -192px; }
    &.flag-mm { background-position: -264px -192px; }
    &.flag-mn { background-position: -288px -192px; }
    &.flag-mo { background-position: -312px -192px; }
    &.flag-mp { background-position: -336px -192px; }
    &.flag-mq { background-position: -360px -192px; }
    &.flag-mr { background-position: 0 -216px; }
    &.flag-ms { background-position: -24px -216px; }
    &.flag-mt { background-position: -48px -216px; }
    &.flag-mu { background-position: -72px -216px; }
    &.flag-mv { background-position: -96px -216px; }
    &.flag-mw { background-position: -120px -216px; }
    &.flag-mx { background-position: -144px -216px; }
    &.flag-my { background-position: -168px -216px; }
    &.flag-mz { background-position: -192px -216px; }
    &.flag-na { background-position: -216px -216px; }
    &.flag-nc { background-position: -240px -216px; }
    &.flag-ne { background-position: -264px -216px; }
    &.flag-nf { background-position: -288px -216px; }
    &.flag-ng { background-position: -312px -216px; }
    &.flag-ni { background-position: -336px -216px; }
    &.flag-nl { background-position: -360px -216px; }
    &.flag-no { background-position: 0 -240px; }
    &.flag-sj { background-position: 0 -240px; }
    &.flag-np { background-position: -24px -240px; }
    &.flag-nr { background-position: -48px -240px; }
    &.flag-nu { background-position: -72px -240px; }
    &.flag-nz { background-position: -96px -240px; }
    &.flag-om { background-position: -120px -240px; }
    &.flag-pa { background-position: -144px -240px; }
    &.flag-pe { background-position: -168px -240px; }
    &.flag-pf { background-position: -192px -240px; }
    &.flag-pg { background-position: -216px -240px; }
    &.flag-ph { background-position: -240px -240px; }
    &.flag-pk { background-position: -264px -240px; }
    &.flag-pl { background-position: -288px -240px; }
    &.flag-pm { background-position: -168px -96px; }
    &.flag-pn { background-position: -312px -240px; }
    &.flag-pr { background-position: -336px -240px; }
    &.flag-ps { background-position: -360px -240px; }
    &.flag-pt { background-position: 0 -264px; }
    &.flag-pw { background-position: -24px -264px; }
    &.flag-py { background-position: -48px -264px; }
    &.flag-qa { background-position: -72px -264px; }
    &.flag-ro { background-position: -96px -264px; }
    &.flag-rs { background-position: -120px -264px; }
    &.flag-ru { background-position: -144px -264px; }
    &.flag-rw { background-position: -168px -264px; }
    &.flag-sa { background-position: -192px -264px; }
    &.flag-sb { background-position: -216px -264px; }
    &.flag-sc { background-position: -240px -264px; }
    &.flag-sd { background-position: -264px -264px; }
    &.flag-se { background-position: -288px -264px; }
    &.flag-sg { background-position: -312px -264px; }
    &.flag-sh { background-position: -336px -264px; }
    &.flag-si { background-position: -360px -264px; }
    &.flag-sk { background-position: 0 -288px; }
    &.flag-sl { background-position: -24px -288px; }
    &.flag-sm { background-position: -48px -288px; }
    &.flag-sn { background-position: -72px -288px; }
    &.flag-so { background-position: -96px -288px; }
    &.flag-sr { background-position: -120px -288px; }
    &.flag-ss { background-position: -144px -288px; }
    &.flag-st { background-position: -168px -288px; }
    &.flag-sv { background-position: -192px -288px; }
    &.flag-sy { background-position: -216px -288px; }
    &.flag-sz { background-position: -240px -288px; }
    &.flag-tc { background-position: -264px -288px; }
    &.flag-td { background-position: -288px -288px; }
    &.flag-tf { background-position: -312px -288px; }
    &.flag-tg { background-position: -336px -288px; }
    &.flag-th { background-position: -360px -288px; }
    &.flag-tj { background-position: 0 -312px; }
    &.flag-tk { background-position: -24px -312px; }
    &.flag-tl { background-position: -48px -312px; }
    &.flag-tm { background-position: -72px -312px; }
    &.flag-tn { background-position: -96px -312px; }
    &.flag-to { background-position: -120px -312px; }
    &.flag-tr { background-position: -144px -312px; }
    &.flag-tt { background-position: -168px -312px; }
    &.flag-tv { background-position: -192px -312px; }
    &.flag-tw { background-position: -216px -312px; }
    &.flag-tz { background-position: -240px -312px; }
    &.flag-ua { background-position: -264px -312px; }
    &.flag-ug { background-position: -288px -312px; }
    &.flag-us { background-position: -312px -312px; }
    &.flag-uy { background-position: -336px -312px; }
    &.flag-uz { background-position: -360px -312px; }
    &.flag-va { background-position: 0 -336px; }
    &.flag-vc { background-position: -24px -336px; }
    &.flag-ve { background-position: -48px -336px; }
    &.flag-vg { background-position: -72px -336px; }
    &.flag-vi { background-position: -96px -336px; }
    &.flag-vu { background-position: -144px -336px; }
    &.flag-wf { background-position: -168px -336px; }
    &.flag-ws { background-position: -192px -336px; }
    &.flag-ye { background-position: -216px -336px; }
    &.flag-yt { background-position: -240px -336px; }
    &.flag-za { background-position: -264px -336px; }
    &.flag-zm { background-position: -288px -336px; }
    &.flag-zw { background-position: -312px -336px; }
    &.flag-xk { background-position: -336px -336px; }
    &.flag-vn { background-position: -120px -336px; }
    &.flag-cx { background-position: -360px -336px; }
    &.flag-sx { background-position: 0 -360px; }
  `}
  ${(props: Props) =>
    props.size === "large" &&
    `
    width: 32px;
    height: 32px;
    background: url(${flags32});
    &.flag-ad { background-position: 0 0; }
    &.flag-ae { background-position: -32px 0; }
    &.flag-af { background-position: -64px 0; }
    &.flag-ag { background-position: -96px 0; }
    &.flag-ai { background-position: -128px 0; }
    &.flag-al { background-position: -160px 0; }
    &.flag-am { background-position: -192px 0; }
    &.flag-an { background-position: -224px 0; }
    &.flag-ao { background-position: -256px 0; }
    &.flag-ar { background-position: -288px 0; }
    &.flag-as { background-position: -320px 0; }
    &.flag-at { background-position: -352px 0; }
    &.flag-au { background-position: -384px 0; }
    &.flag-aw { background-position: -416px 0; }
    &.flag-ax { background-position: -448px 0; }
    &.flag-az { background-position: -480px 0; }
    &.flag-ba { background-position: 0 -32px; }
    &.flag-bb { background-position: -32px -32px; }
    &.flag-bd { background-position: -64px -32px; }
    &.flag-be { background-position: -96px -32px; }
    &.flag-bf { background-position: -128px -32px; }
    &.flag-bg { background-position: -160px -32px; }
    &.flag-bh { background-position: -192px -32px; }
    &.flag-bi { background-position: -224px -32px; }
    &.flag-bj { background-position: -256px -32px; }
    &.flag-bl { background-position: -288px -32px; }
    &.flag-bm { background-position: -320px -32px; }
    &.flag-bn { background-position: -352px -32px; }
    &.flag-bo { background-position: -384px -32px; }
    &.flag-br { background-position: -416px -32px; }
    &.flag-bs { background-position: -448px -32px; }
    &.flag-bt { background-position: -480px -32px; }
    &.flag-bw { background-position: 0 -64px; }
    &.flag-by { background-position: -32px -64px; }
    &.flag-bz { background-position: -64px -64px; }
    &.flag-ca { background-position: -96px -64px; }
    &.flag-cd { background-position: -128px -64px; }
    &.flag-cf { background-position: -160px -64px; }
    &.flag-cg { background-position: -192px -64px; }
    &.flag-ch { background-position: -224px -64px; }
    &.flag-ci { background-position: -256px -64px; }
    &.flag-ck { background-position: -288px -64px; }
    &.flag-cl { background-position: -320px -64px; }
    &.flag-cm { background-position: -352px -64px; }
    &.flag-cn { background-position: -384px -64px; }
    &.flag-co { background-position: -416px -64px; }
    &.flag-cr { background-position: -448px -64px; }
    &.flag-cu { background-position: -480px -64px; }
    &.flag-cv { background-position: 0 -96px; }
    &.flag-cw { background-position: -32px -96px; }
    &.flag-cy { background-position: -64px -96px; }
    &.flag-cz { background-position: -96px -96px; }
    &.flag-de { background-position: -128px -96px; }
    &.flag-dj { background-position: -160px -96px; }
    &.flag-dk { background-position: -192px -96px; }
    &.flag-dm { background-position: -224px -96px; }
    &.flag-do { background-position: -256px -96px; }
    &.flag-dz { background-position: -288px -96px; }
    &.flag-ec { background-position: -320px -96px; }
    &.flag-ee { background-position: -352px -96px; }
    &.flag-eg { background-position: -384px -96px; }
    &.flag-eh { background-position: -416px -96px; }
    &.flag-er { background-position: -448px -96px; }
    &.flag-es { background-position: -480px -96px; }
    &.flag-et { background-position: 0 -128px; }
    &.flag-eu { background-position: -32px -128px; }
    &.flag-fi { background-position: -64px -128px; }
    &.flag-fj { background-position: -96px -128px; }
    &.flag-fk { background-position: -128px -128px; }
    &.flag-fm { background-position: -160px -128px; }
    &.flag-fo { background-position: -192px -128px; }
    &.flag-fr { background-position: -224px -128px; }
    &.flag-ga { background-position: -256px -128px; }
    &.flag-gb { background-position: -288px -128px; }
    &.flag-gd { background-position: -320px -128px; }
    &.flag-ge { background-position: -352px -128px; }
    &.flag-gf { background-position: -224px -128px; }
    &.flag-gg { background-position: -384px -128px; }
    &.flag-gh { background-position: -416px -128px; }
    &.flag-gi { background-position: -448px -128px; }
    &.flag-gl { background-position: -480px -128px; }
    &.flag-gm { background-position: 0 -160px; }
    &.flag-gn { background-position: -32px -160px; }
    &.flag-gp { background-position: -224px -128px; }
    &.flag-gq { background-position: -64px -160px; }
    &.flag-gr { background-position: -96px -160px; }
    &.flag-gs { background-position: -128px -160px; }
    &.flag-gt { background-position: -160px -160px; }
    &.flag-gu { background-position: -192px -160px; }
    &.flag-gw { background-position: -224px -160px; }
    &.flag-gy { background-position: -256px -160px; }
    &.flag-hk { background-position: -288px -160px; }
    &.flag-hn { background-position: -320px -160px; }
    &.flag-hr { background-position: -352px -160px; }
    &.flag-ht { background-position: -384px -160px; }
    &.flag-hu { background-position: -416px -160px; }
    &.flag-ic { background-position: -448px -160px; }
    &.flag-id { background-position: -480px -160px; }
    &.flag-ie { background-position: 0 -192px; }
    &.flag-il { background-position: -32px -192px; }
    &.flag-im { background-position: -64px -192px; }
    &.flag-in { background-position: -96px -192px; }
    &.flag-iq { background-position: -128px -192px; }
    &.flag-ir { background-position: -160px -192px; }
    &.flag-is { background-position: -192px -192px; }
    &.flag-it { background-position: -224px -192px; }
    &.flag-je { background-position: -256px -192px; }
    &.flag-jm { background-position: -288px -192px; }
    &.flag-jo { background-position: -320px -192px; }
    &.flag-jp { background-position: -352px -192px; }
    &.flag-ke { background-position: -384px -192px; }
    &.flag-kg { background-position: -416px -192px; }
    &.flag-kh { background-position: -448px -192px; }
    &.flag-ki { background-position: -480px -192px; }
    &.flag-km { background-position: 0 -224px; }
    &.flag-kn { background-position: -32px -224px; }
    &.flag-kp { background-position: -64px -224px; }
    &.flag-kr { background-position: -96px -224px; }
    &.flag-kw { background-position: -128px -224px; }
    &.flag-ky { background-position: -160px -224px; }
    &.flag-kz { background-position: -192px -224px; }
    &.flag-la { background-position: -224px -224px; }
    &.flag-lb { background-position: -256px -224px; }
    &.flag-lc { background-position: -288px -224px; }
    &.flag-li { background-position: -320px -224px; }
    &.flag-lk { background-position: -352px -224px; }
    &.flag-lr { background-position: -384px -224px; }
    &.flag-ls { background-position: -416px -224px; }
    &.flag-lt { background-position: -448px -224px; }
    &.flag-lu { background-position: -480px -224px; }
    &.flag-lv { background-position: 0 -256px; }
    &.flag-ly { background-position: -32px -256px; }
    &.flag-ma { background-position: -64px -256px; }
    &.flag-mc { background-position: -96px -256px; }
    &.flag-md { background-position: -128px -256px; }
    &.flag-me { background-position: -160px -256px; }
    &.flag-mf { background-position: -192px -256px; }
    &.flag-mg { background-position: -224px -256px; }
    &.flag-mh { background-position: -256px -256px; }
    &.flag-mk { background-position: -288px -256px; }
    &.flag-ml { background-position: -320px -256px; }
    &.flag-mm { background-position: -352px -256px; }
    &.flag-mn { background-position: -384px -256px; }
    &.flag-mo { background-position: -416px -256px; }
    &.flag-mp { background-position: -448px -256px; }
    &.flag-mq { background-position: -480px -256px; }
    &.flag-mr { background-position: 0 -288px; }
    &.flag-ms { background-position: -32px -288px; }
    &.flag-mt { background-position: -64px -288px; }
    &.flag-mu { background-position: -96px -288px; }
    &.flag-mv { background-position: -128px -288px; }
    &.flag-mw { background-position: -160px -288px; }
    &.flag-mx { background-position: -192px -288px; }
    &.flag-my { background-position: -224px -288px; }
    &.flag-mz { background-position: -256px -288px; }
    &.flag-na { background-position: -288px -288px; }
    &.flag-nc { background-position: -320px -288px; }
    &.flag-ne { background-position: -352px -288px; }
    &.flag-nf { background-position: -384px -288px; }
    &.flag-ng { background-position: -416px -288px; }
    &.flag-ni { background-position: -448px -288px; }
    &.flag-nl { background-position: -480px -288px; }
    &.flag-no { background-position: 0 -320px; }
    &.flag-np { background-position: -32px -320px; }
    &.flag-nr { background-position: -64px -320px; }
    &.flag-nu { background-position: -96px -320px; }
    &.flag-nz { background-position: -128px -320px; }
    &.flag-om { background-position: -160px -320px; }
    &.flag-pa { background-position: -192px -320px; }
    &.flag-pe { background-position: -224px -320px; }
    &.flag-pf { background-position: -256px -320px; }
    &.flag-pg { background-position: -288px -320px; }
    &.flag-ph { background-position: -320px -320px; }
    &.flag-pk { background-position: -352px -320px; }
    &.flag-pl { background-position: -384px -320px; }
    &.flag-pm { background-position: -224px -128px; }
    &.flag-pn { background-position: -416px -320px; }
    &.flag-pr { background-position: -448px -320px; }
    &.flag-ps { background-position: -480px -320px; }
    &.flag-pt { background-position: 0 -352px; }
    &.flag-pw { background-position: -32px -352px; }
    &.flag-py { background-position: -64px -352px; }
    &.flag-qa { background-position: -96px -352px; }
    &.flag-re { background-position: -224px -128px; }
    &.flag-ro { background-position: -128px -352px; }
    &.flag-rs { background-position: -160px -352px; }
    &.flag-ru { background-position: -192px -352px; }
    &.flag-rw { background-position: -224px -352px; }
    &.flag-sa { background-position: -256px -352px; }
    &.flag-sb { background-position: -288px -352px; }
    &.flag-sc { background-position: -320px -352px; }
    &.flag-sd { background-position: -352px -352px; }
    &.flag-se { background-position: -384px -352px; }
    &.flag-sg { background-position: -416px -352px; }
    &.flag-sh { background-position: -448px -352px; }
    &.flag-si { background-position: -480px -352px; }
    &.flag-sj { background-position: 0 -340px; }
    &.flag-sk { background-position: 0 -384px; }
    &.flag-sl { background-position: -32px -384px; }
    &.flag-sm { background-position: -64px -384px; }
    &.flag-sn { background-position: -96px -384px; }
    &.flag-so { background-position: -128px -384px; }
    &.flag-sr { background-position: -160px -384px; }
    &.flag-ss { background-position: -192px -384px; }
    &.flag-st { background-position: -224px -384px; }
    &.flag-sv { background-position: -256px -384px; }
    &.flag-sy { background-position: -288px -384px; }
    &.flag-sz { background-position: -320px -384px; }
    &.flag-tc { background-position: -352px -384px; }
    &.flag-td { background-position: -384px -384px; }
    &.flag-tf { background-position: -416px -384px; }
    &.flag-tg { background-position: -448px -384px; }
    &.flag-th { background-position: -480px -384px; }
    &.flag-tj { background-position: 0 -416px; }
    &.flag-tk { background-position: -32px -416px; }
    &.flag-tl { background-position: -64px -416px; }
    &.flag-tm { background-position: -96px -416px; }
    &.flag-tn { background-position: -128px -416px; }
    &.flag-to { background-position: -160px -416px; }
    &.flag-tr { background-position: -192px -416px; }
    &.flag-tt { background-position: -224px -416px; }
    &.flag-tv { background-position: -256px -416px; }
    &.flag-tw { background-position: -288px -416px; }
    &.flag-tz { background-position: -320px -416px; }
    &.flag-ua { background-position: -352px -416px; }
    &.flag-ug { background-position: -384px -416px; }
    &.flag-us { background-position: -416px -416px; }
    &.flag-uy { background-position: -448px -416px; }
    &.flag-uz { background-position: -480px -416px; }
    &.flag-va { background-position: 0 -458px; }
    &.flag-vc { background-position: -32px -458px; }
    &.flag-ve { background-position: -64px -458px; }
    &.flag-vg { background-position: -96px -458px; }
    &.flag-vi { background-position: -128px -458px; }
    &.flag-vu { background-position: -192px -458px; }
    &.flag-wf { background-position: -224px -458px; }
    &.flag-ws { background-position: -256px -458px; }
    &.flag-ye { background-position: -288px -458px; }
    &.flag-yt { background-position: -320px -458px; }
    &.flag-za { background-position: -352px -458px; }
    &.flag-zm { background-position: -384px -458px; }
    &.flag-zw { background-position: -416px -458px; }
    &.flag-xk { background-position: -458px -458px; }
    &.flag-vn { background-position: -140px -458px; }
    &.flag-cx { background-position: -480px -458px; }
    &.flag-sx { background-position: 0 -480px; }
  `}

}
`;
