import { Box, Button, Form, Icon, Input, Space } from "components";
import { styledRemoveIcon } from "components/Form/Input";
import { useRecipientEditable } from "features/recipient";
import React, { useEffect, useState } from "react";
import { notifyError, notifySuccess } from "store/actions/notifications";
import { StoreRecipient, updateRecipient } from "store/actions/recipients";

interface Props {
  recipient: StoreRecipient;
}
export default function Info(props: Props) {
  const { recipient } = props;
  const [busy, setBusy] = useState(false);
  const [form] = Form.useForm();
  const profileEditable = useRecipientEditable();

  useEffect(() => {
    if (!form.isFieldsTouched()) {
      form.resetFields();
    }
  }, recipient.contactEmails);

  return (
    <Box header="Copy Recipient Emails to">
      <p>Send a copy of all recipient emails (excluding Portal login code/authentication emails) to the following email addresses:</p>
      <Form
        form={form}
        disabled={!profileEditable}
        initialValues={recipient}
        onFinish={async ({ contactEmails }: StoreRecipient) => {
          setBusy(true);
          try {
            await updateRecipient(recipient.id, { contactEmails: contactEmails.filter((v) => v) });
            notifySuccess("Contact Email Updated");
            form.resetFields();
          } catch (errors) {
            notifyError("Contact Emails update failed", { errors });
          }
          setBusy(false);
        }}
      >
        <Form.List name="contactEmails">
          {(fields, { add, remove }) => (
            <>
              {fields.map((field) => (
                <Form.Item key={field.key} name={field.name} rules={[{ type: "email", message: "Enter a valid email" }]}>
                  <Input
                    className={styledRemoveIcon}
                    suffix={
                      profileEditable && (
                        <Icon
                          color="grey"
                          theme="solid"
                          type="circle-xmark"
                          tooltip="Remove"
                          onClick={() => {
                            remove(field.name);
                          }}
                        />
                      )
                    }
                  />
                </Form.Item>
              ))}

              {profileEditable && (
                <Space justify="space-between">
                  <Button
                    onClick={() => {
                      add();
                    }}
                    icon={<Icon type="plus" />}
                  >
                    Add Email
                  </Button>
                  <Form.Control shouldUpdate>
                    {(form) =>
                      form.isFieldsTouched() && (
                        <Space direction="row-reverse">
                          <Button htmlType="submit" type="primary" loading={busy}>
                            Save Emails
                          </Button>
                          <Button
                            type="text"
                            onClick={() => {
                              form.resetFields();
                            }}
                          >
                            Cancel
                          </Button>
                        </Space>
                      )
                    }
                  </Form.Control>
                </Space>
              )}
            </>
          )}
        </Form.List>
      </Form>
    </Box>
  );
}
