import { Access } from "@trolley/common-frontend";
import config from "config";
import loadScript from "load-script";
import { getMerchantSalesforceId } from "store/actions/integrations";
import { MerchantSettings } from "store/actions/merchantSettings";
import { User } from "store/actions/user";
import { capitalize } from "utils/helpers";

export function salesforceInit() {
  if (config.SALESFORCE && !window.embedded_svc) {
    loadScript("https://service.force.com/embeddedservice/5.0/esw.min.js", (err, script) => {
      //
    });
  }
}

export async function salesforceBoot(user: User, merchantSettings: MerchantSettings): Promise<boolean> {
  async function initESW(gslbBaseURL: string | null) {
    let sfObjectId = merchantSettings.support?.salesforceObjectId;
    if (!sfObjectId) {
      sfObjectId = await getMerchantSalesforceId();
    }
    const embedded_svc = window.embedded_svc;
    embedded_svc.settings.displayHelpButton = true; // Or false
    embedded_svc.settings.enabledFeatures = ["LiveAgent"];
    embedded_svc.settings.entryFeature = "LiveAgent";
    embedded_svc.settings.defaultMinimizedText = "Chat with Support"; // (Defaults to Chat with an Expert)

    // embedded_svc.settings.defaultMinimizedText = '...'; //(Defaults to Chat with an Expert)
    // embedded_svc.settings.disabledMinimizedText = '...'; //(Defaults to Agent Offline)

    // embedded_svc.settings.loadingText = ''; //(Defaults to Loading)
    // embedded_svc.settings.storageDomain = 'yourdomain.com'; //(Sets the domain for your deployment so that visitors can navigate subdomains during a chat session)

    // Settings for Chat
    // embedded_svc.settings.directToButtonRouting = function(prechatFormData) {
    // Dynamically changes the button ID based on what the visitor enters in the pre-chat form.
    // Returns a valid button ID.
    // };
    // embedded_svc.settings.prepopulatedPrechatFields = {}; //Sets the auto-population of pre-chat form fields
    // embedded_svc.settings.fallbackRouting = []; //An array of button IDs, user IDs, or userId_buttonId
    // embedded_svc.settings.offlineSupportMinimizedText = '...'; //(Defaults to Contact Us)
    embedded_svc.settings.extraPrechatFormDetails = [
      {
        label: "PR User ID",
        value: user.id,
        displayToAgent: false,
      },
      {
        label: "Email",
        value: user.email, // use this as main tracker for user
        displayToAgent: true,
      },
      {
        label: "Name",
        value: user.name, // Just for display
        displayToAgent: true,
      },
      {
        label: "First Name",
        value: user.firstName,
        displayToAgent: false,
      },
      {
        label: "Last Name",
        value: user.lastName,
        displayToAgent: false,
      },
      {
        label: "User Role",
        value: capitalize(user.role.replace(/-/g, " ")), // this needs to match the exact Pick values in Salesforce. "Team Member"
        displayToAgent: true,
      },
      {
        label: "Merchant",
        value: sfObjectId, // Merchant ID on salesforce.
        displayToAgent: true,
      },
      {
        label: "Type",
        value: "Support",
        displayToAgent: false,
      },
    ];

    embedded_svc.settings.extraPrechatInfo = [
      {
        entityName: "Contact",
        showOnCreate: true,
        saveToTranscript: "Contact",
        entityFieldMaps: [
          {
            fieldName: "Email", // this is the main search match criterion. Email is/will be unique in salesforce
            label: "Email",
            doFind: true,
            isExactMatch: true,
            doCreate: true,
          },
          {
            fieldName: "PR_User_ID__c",
            label: "PR User ID",
            isExactMatch: false,
            doFind: false,
            doCreate: true,
          },
          {
            fieldName: "FirstName",
            label: "First Name",
            doFind: false,
            isExactMatch: false,
            doCreate: true,
          },
          {
            fieldName: "LastName",
            label: "Last Name",
            doFind: false,
            isExactMatch: false,
            doCreate: true,
          },
          {
            fieldName: "User_Role__c",
            label: "User Role",
            doFind: false,
            isExactMatch: false,
            doCreate: true,
          },
          {
            fieldName: "contact_merchant_lookup__c",
            label: "Merchant",
            doFind: false,
            isExactMatch: false,
            doCreate: true,
          },
        ],
      },
    ];

    embedded_svc.init(
      config.SALESFORCE.apiUrl,
      config.SALESFORCE.siteUrl,
      gslbBaseURL,
      config.SALESFORCE.id,
      config.SALESFORCE.deployment,
      config.SALESFORCE.chatDetails,
    );

    // reset it to display "auto" because salesforceReset might have hidden it
    const chatButton = document.getElementsByClassName("uiButton")[0];
    chatButton?.setAttribute("style", "display: auto;");
  }

  if (!user.isImpersonated && !user.access.includes(Access.SUPER)) {
    try {
      if (!window.embedded_svc) {
        // in case https://service.force.com/embeddedservice/5.0/esw.min.js failed to load
        loadScript(config.SALESFORCE.eswUrl, async (err, script) => {
          if (!err) {
            await initESW(null);
          }
        });
      } else {
        await initESW("https://service.force.com");
      }

      return true;
    } catch {
      return false;
    }
  }

  return false;
}

export function salesforceReset(button?: Element) {
  // ... cuz salesforce doesn't seem to have a real reset
  window.embedded_svc?.liveAgentAPI?.endChat();
  const chatButton = button || document.getElementsByClassName("uiButton")[0];
  chatButton?.setAttribute("style", "display: none;");
}
