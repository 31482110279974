const { getAlphaColor, getSolidColor } = require("antd/lib/theme/themes/default/colorAlgorithm.js");
const colors = require("./colors.js");
const generate = require("@ant-design/colors").generate;

// this file is mainly used for ThemeProvider configuration. this needs to be a JS file because it needs to be pulled in webpack so less files can use those variables
const greys = generate(colors.grey);
const blues = generate(colors.blue);

module.exports = {
  ...colors,
  fontSize: 14,
  borderRadius: 8,
  fontFamily: `"Proxima Nova", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"`,
  colorPrimary: colors.primary,
  colorInfo: colors.blue,
  colorSuccess: colors.green,
  colorWarning: colors.yellow,
  colorError: colors.red,
  colorTextBase: colors.black,
  colorBgBase: greys[0],
  fontWeightStrong: 500,
  colorBgContainer: colors.white,
  colorTextSecondary: getAlphaColor(colors.black, 0.6),
  colorTextDescription: getAlphaColor(colors.black, 0.6),
  colorTextDisabled: getAlphaColor(colors.black, 0.6),
  fontSizeHeading1: 24,
  fontSizeHeading2: 20,
  fontSizeHeading3: 18, // Box Header size,
  fontSizeHeading4: 16,
  fontSizeHeading5: 14,
  lineHeightHeading1: (24 + 8) / 24,
  lineHeightHeading2: (20 + 8) / 20,
  lineHeightHeading3: (18 + 8) / 18,
  lineHeightHeading4: (16 + 8) / 16,
  lineHeightHeading5: (14 + 8) / 14,
  colorBgLayout: greys[0],
  boxShadow: `0 1px 2px ${getAlphaColor(colors.black, 0.05)}, 0 2px 4px ${getAlphaColor(colors.black, 0.1)}`,
  controlOutline: getAlphaColor(colors.black, 0.05), // box shadow color
  controlOutlineWidth: 2, // shadow y position,
  colorBgElevated: colors.white, // background for dropdown menu
  controlItemBgHover: getSolidColor(blues[1], -3), // by default it uses primary color, which is too saturated
  controlItemBgActive: blues[0], // by default it uses primary color, which is too saturated
  controlItemBgActiveHover: getSolidColor(blues[1], -3), // by default it uses primary color, which is too saturated
};
