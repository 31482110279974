import { Access, EndOfYearFormType, EoyTaxReportStatus, TaxDeliveryType } from "@trolley/common-frontend";
import BigNumber from "bignumber.js";
import {
  Alert,
  Button,
  Container,
  CurrencyDisplay,
  DateDisplay,
  Divider,
  Dropdown,
  FileDownload,
  Grid,
  Heading,
  Icon,
  Link,
  LogoLoader,
  Menu,
  Modal,
  RecordCount,
  Status,
  Table,
  Text,
} from "components";
import { ConnectedRouter } from "connected-react-router";
import dayjs from "dayjs";
import { translateDeliveryMethod } from "features/recipient/RecipientDeliveryMethod";
import { UserProfile } from "features/user";
import { TAX_STATEMENT_FEES } from "pages/SettingsPage/Fees";
import React, { useEffect, useState } from "react";
import Helmet from "react-helmet";
import { FormattedNumber, useIntl } from "react-intl";
import { Provider } from "react-redux";
import { Link as RouteLink, useHistory, useParams } from "react-router-dom";
import { Cell, Label, Legend, Pie, PieChart, ResponsiveContainer } from "recharts";
import history from "services/history";
import store from "store";
import { loadEoySummary } from "store/actions/eoySummary";
import { EoyExportStatusMerchant, EoyTaxExport, EoyTaxFilingQuery, loadEoyTaxExports, startEFile } from "store/actions/eoyTaxExports";
import { EoyTaxQuery, resendEoyTaxForms, sendEoyTaxForms } from "store/actions/eoyTaxForms";
import { notifyError, notifySuccess } from "store/actions/notifications";
import { getTaxFormLabel } from "store/actions/recipientTaxForms";
import { useEoySummary } from "store/hooks/eoySummary";
import { useEoyTaxExports } from "store/hooks/eoyTaxExports";
import { useMerchantTaxYear } from "store/hooks/taxMerchantYear";
import { useAccess } from "store/hooks/user";
import { useWhiteLabel } from "store/hooks/whiteLabel";
import { BaseError, BaseStatus } from "store/reducers/standardReducer";
import colors from "style/colors";
import { capitalize, stringifyQuery } from "utils/helpers";
import { useWindowSize } from "utils/hooks";
import { Pages, StatementActions, getDueDate, isProfileComplete, isStatementActionLocked } from ".";
import { LAST_YEAR, TAX_PATHS } from "../..";

function calculateCost(records: number, deliveryType: TaxDeliveryType, formType: EndOfYearFormType): BigNumber {
  const tier1Count = Math.min(1000, records);
  const tier2Count = records - tier1Count;

  const reportGenerationCost = new BigNumber(tier1Count * TAX_STATEMENT_FEES.base.tier1 + tier2Count * TAX_STATEMENT_FEES.base.tier2);
  if (deliveryType === TaxDeliveryType.E_DELIVERY) {
    return reportGenerationCost;
  }

  /**
   * MAIL
   * 1099s may be mailed outside of US, but we'll assume the majority is mailed within US for estimation purpose.
   * 1042s may be mailed within US, but let's assume the majority is mailed outside the US for estimation purpose.
   */
  const mailCost = reportGenerationCost.plus(tier1Count * TAX_STATEMENT_FEES.mail.tier1 + tier2Count * TAX_STATEMENT_FEES.mail.tier2);
  if (formType !== EndOfYearFormType.F1042) {
    return mailCost;
  }

  return mailCost.plus(records * TAX_STATEMENT_FEES.international); // for international mail)
}

function calculatePercent(needsReview: number, approved: number, sent: number, eFiled: number): number {
  const sum = needsReview + approved + sent + eFiled;
  const numerator = eFiled;

  return sum > 0 ? Number(new BigNumber(numerator).dividedBy(sum).times(100).toFixed(0)) : 0;
}

type DeliveryWarnings = { showAdditionalCostWarning?: boolean };

export function TaxDeliveryCostWarning(props: DeliveryWarnings) {
  return (
    <Provider key="modal-content" store={store}>
      <ConnectedRouter history={history}>
        <TaxDeliveryWarning {...props} />
      </ConnectedRouter>
    </Provider>
  );
}

function TaxDeliveryWarning({ showAdditionalCostWarning }: DeliveryWarnings) {
  const { data: whiteLabel } = useWhiteLabel();

  return (
    <div>
      <p>
        The End of Year statements will be made available on the Recipient Widget or Portal.
        <br />
        {whiteLabel?.taxEoyAvailable ? (
          "An email notification will be sent to the recipient to let them know they can login and download it."
        ) : (
          <Alert type="warning">
            <>
              Email notification will not be sent to the recipients because&nbsp;
              <RouteLink
                onClick={() => {
                  Modal.destroyAll();
                }}
                to="/settings/white-label/emails"
              >
                Tax Statement Delivery
              </RouteLink>
              &nbsp;notification is disabled.
            </>
          </Alert>
        )}
      </p>

      {showAdditionalCostWarning && <p>You will be charged for any additional printing and postal mailing of EOY tax statements.</p>}
    </div>
  );
}

const QUERIES: EoyTaxQuery[] = [
  // NEEDS_REVIEW:
  {
    pageSize: 0,
    status: [EoyTaxReportStatus.NEEDS_REVIEW, EoyTaxReportStatus.HOLD],
    requireTaxForm: true,
  },
  // APPROVED
  {
    pageSize: 0,
    status: [EoyTaxReportStatus.APPROVED],
  },
  // APPROVED_MAIL_COST:
  {
    pageSize: 0,
    status: [EoyTaxReportStatus.APPROVED],
    taxDeliveryType: TaxDeliveryType.MAIL,
  },
  // APPROVED_E_DELIVERY_COST:
  {
    pageSize: 0,
    status: [EoyTaxReportStatus.APPROVED],
    taxDeliveryType: TaxDeliveryType.E_DELIVERY,
  },
  // SENT_UNAMENDED:
  {
    pageSize: 0,
    status: [EoyTaxReportStatus.SENT],
    requireIrsSubmittedAt: false,
    isAmended: false,
  },
  // SENT_AMENDED:
  {
    pageSize: 0,
    status: [EoyTaxReportStatus.SENT],
    requireIrsSubmittedAt: false,
    isAmended: true,
  },
  // EFILED:
  {
    pageSize: 0,
    status: [EoyTaxReportStatus.SENT, EoyTaxReportStatus.VOID],
    requireIrsSubmittedAt: true,
  },
  // SENT TOTAL
  {
    pageSize: 0,
    status: [EoyTaxReportStatus.SENT],
  },
];

type Props = {
  formType: Exclude<EndOfYearFormType, EndOfYearFormType.NONE>;
};

export default function TaxStatements(props: Props) {
  const params = useParams<{ taxYear: string }>();
  const taxYear = Number(params.taxYear);
  const accessTaxEOYWrite = useAccess(Access.TAX_EOY_WRITE);
  const baseUrl = `${TAX_PATHS.EOY_REPORTING}/${taxYear}`;
  const { formType } = props;
  const eoyExportQuery = { formTypes: [formType], taxYear, orderBy: ["count"], sortBy: ["asc"], pageSize: 1000 };
  const { data: taxMerchantYear, status: taxMerchantYearStatus } = useMerchantTaxYear(taxYear);
  const { data: eoyExports } = useEoyTaxExports(eoyExportQuery);
  const history = useHistory();
  const intl = useIntl();
  const { isMobile } = useWindowSize();
  const queries = QUERIES.map((q) => ({
    requireTaxForm: undefined, // Required for redirecting to the review earnings pages. The default on that page is true
    formTypes: [formType],
    taxYear,
    ...q,
  }));
  const [needsReviewQuery, approvedQuery, , , , , eFiledQuery] = queries;
  const {
    data: [
      reviewCount = 0,
      approvedCount = 0,
      toBeMailCount = 0,
      toBeEDeliveryCount = 0,
      sentToRecipientsCountOriginal = 0,
      sentToRecipientsCountAmended = 0,
      eFiledCount = 0,
      resendCount = 0,
    ],
    status: eoySummaryStatus,
  } = useEoySummary(queries);
  const [eoyTaxFormsStatus, setEoyTaxFormsStatus] = useState<BaseStatus>();

  const loading = eoySummaryStatus === BaseStatus.LOADING;
  const sentToRecipientsCount = sentToRecipientsCountOriginal + sentToRecipientsCountAmended;

  useEffect(() => {
    if (formType === EndOfYearFormType.F1099_NEC && taxYear && taxYear < 2020) {
      // NEC forms only started in year 2020
      history.push(`${TAX_PATHS.EOY_REPORTING}/${taxYear}`);
    } else if (eoySummaryStatus === BaseStatus.LOADED) {
      reloadQueries();
    }
  }, [formType, taxYear]);

  useEffect(() => {
    if (eoySummaryStatus === BaseStatus.LOADED && !(reviewCount + approvedCount + sentToRecipientsCount + eFiledCount)) {
      history.push(`${TAX_PATHS.EOY_REPORTING}/${taxYear}`);
    }
  }, [eoySummaryStatus]);

  const ongoingYear = Number(taxYear) >= LAST_YEAR;
  const taxFormLabel = getTaxFormLabel(formType);
  const amendedLabel = formType === EndOfYearFormType.F1042 ? "amended" : "corrected";
  const baseSentCost = calculateCost(toBeEDeliveryCount, TaxDeliveryType.E_DELIVERY, formType);
  const mailCost = calculateCost(toBeMailCount, TaxDeliveryType.MAIL, formType);
  const percentComplete = calculatePercent(reviewCount, approvedCount, sentToRecipientsCount, eFiledCount);
  const GRAPH_colors = [colors.yellow, colors.purple, colors.blue, colors.green];
  const data = [
    { name: <RecordCount value={reviewCount} other="# on hold or in review" />, value: reviewCount },
    { name: <RecordCount value={approvedCount} other="# approved" />, value: approvedCount },
    { name: <RecordCount value={sentToRecipientsCount} other="# sent to recipients" />, value: sentToRecipientsCount },
    { name: <RecordCount value={eFiledCount} other="# E-filed to the IRS" />, value: eFiledCount },
  ];

  const downloadQuery: EoyTaxFilingQuery = {
    taxYear,
    formType,
  };

  function reloadQueries() {
    loadEoyTaxExports(eoyExportQuery, true);
    window.setTimeout(() => {
      loadEoySummary(queries, true); // the count doesn't seem to update immediately
    }, 500);
  }

  return (
    <Container padding="none">
      <Helmet>
        <title>{`${taxFormLabel} Statements Delivery and Filing`}</title>
      </Helmet>
      <Heading tag="h2">{taxFormLabel} Statements Delivery and Filing</Heading>
      <p>
        Deadline for sending {taxFormLabel} to recipients: {dayjs(getDueDate(formType, "delivery", taxYear)).format("ll")}
        <br />
        Deadline for E-Filing to IRS: {dayjs(getDueDate(formType, "efile", taxYear)).format("ll")}
      </p>

      <div style={{ maxWidth: "400px" }}>
        <LogoLoader spinning={loading}>
          <ResponsiveContainer width="100%" minWidth={isMobile ? 260 : 400} height={isMobile ? 260 : 160}>
            <PieChart>
              <Legend align={isMobile ? "center" : "right"} verticalAlign={isMobile ? "bottom" : "middle"} layout="vertical" />
              <Pie legendType="circle" dataKey="value" data={data} innerRadius={50} outerRadius={80}>
                {data.map((entry, index) => (
                  <Cell key={`cell-${entry.name}`} fill={GRAPH_colors[index]} fillOpacity={0.85} />
                ))}
                <Label
                  position="center"
                  value={`${percentComplete}%`}
                  fill={percentComplete < 100 ? colors.black : colors.green}
                  fontSize="28px"
                  fontWeight="bold"
                />
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        </LogoLoader>
      </div>

      <Divider transparent margin="small" />

      {/**
       * ======================================= IN REVIEW / HOLD =================================
       */}
      <Heading tag="h2">
        <Link
          to={{
            pathname: `${baseUrl}/${Pages.EARNINGS}`,
            search: stringifyQuery({ ...needsReviewQuery, page: 1, pageSize: 10 }),
          }}
        >
          <Icon type="circle" size="small" theme="solid" color="yellow" left />
          <RecordCount value={reviewCount} one="# earnings is on hold or needs to be reviewed" other="# earnings are on hold or need to be reviewed" />
        </Link>
      </Heading>
      {ongoingYear && reviewCount > 0 && (
        <Alert type="info" showIcon={!isMobile} style={{ marginLeft: "24px" }} header="Next Step!">
          <p>You need to review and approve earnings before the tax statements can be sent to recipients.</p>
          <Link to={{ pathname: `${baseUrl}/${Pages.EARNINGS}`, search: stringifyQuery({ ...needsReviewQuery, page: 1, pageSize: 10 }) }}>
            Click Here to Review Earnings for {taxFormLabel} Statements
          </Link>
        </Alert>
      )}

      {/**
       * ======================================= APPROVED =================================
       */}

      <Heading tag="h2">
        <Link
          to={{
            pathname: `${baseUrl}/${Pages.EARNINGS}`,
            search: stringifyQuery({
              ...approvedQuery,
              page: 1,
              pageSize: 10,
            }),
          }}
        >
          <Icon type="circle" size="small" theme="solid" color="purple" left />
          <RecordCount
            value={approvedCount}
            one="# approved tax statement needs to be sent to recipients"
            other="# approved tax statements need to be sent to recipients"
          />
        </Link>
      </Heading>
      {ongoingYear && approvedCount > 0 && (
        <Alert type="info" header="Next Step!" style={{ marginLeft: "24px" }}>
          You can now send the approved tax statements to the recipients. The statements will be sent based on the recipient's delivery settings (Postal Mail or
          E-Delivery).
          <Table
            pagination={false}
            columns={[
              {
                dataIndex: "deliveryType",
                render: (deliveryType: TaxDeliveryType) => {
                  if (deliveryType === TaxDeliveryType.E_DELIVERY) {
                    return "E-Delivery";
                  } else if (deliveryType === TaxDeliveryType.MAIL) {
                    return "Postal Mail";
                  }

                  return null;
                },
              },
              {
                title: "# of Statements",
                dataIndex: "count",
                align: "center",
                render: (count: number) => <FormattedNumber value={count} />,
              },
              {
                title: "Statements Generation Cost",
                dataIndex: "deliveryType",
                key: "generationCost",
                align: "right",
                render: (deliveryType: TaxDeliveryType, record) => {
                  const tier1Count = Math.min(1000, record.count);
                  const tier2Count = record.count - tier1Count;

                  return (
                    <Text wrap={false}>
                      <Icon
                        type="circle-info"
                        tooltip={
                          <>
                            <Text wrap={false}>
                              <CurrencyDisplay value={TAX_STATEMENT_FEES.base.tier1} currency="USD" /> per statement (first 1,000)
                            </Text>
                            <Text wrap={false}>
                              <CurrencyDisplay value={TAX_STATEMENT_FEES.base.tier2} currency="USD" /> per statement (over 1,000)
                            </Text>
                          </>
                        }
                        left
                      />
                      <CurrencyDisplay value={TAX_STATEMENT_FEES.base.tier1 * tier1Count + TAX_STATEMENT_FEES.base.tier2 * tier2Count} currency="USD" />
                    </Text>
                  );
                },
              },
              {
                title: "Statements Printing and Mailing Cost",
                dataIndex: "deliveryType",
                align: "right",
                key: "mailingCost",
                render: (deliveryType: TaxDeliveryType, record) => {
                  const tier1Count = Math.min(1000, record.count);
                  const tier2Count = record.count - tier1Count;

                  return deliveryType === TaxDeliveryType.MAIL ? (
                    <>
                      <Icon
                        type="circle-info"
                        left
                        tooltip={
                          <>
                            <Text>
                              <CurrencyDisplay value={TAX_STATEMENT_FEES.mail.tier1} currency="USD" /> per statement (first 1,000)
                            </Text>
                            <Text>
                              <CurrencyDisplay value={TAX_STATEMENT_FEES.mail.tier2} currency="USD" /> per statement (over 1,000)
                            </Text>
                            <Text>
                              <CurrencyDisplay value={TAX_STATEMENT_FEES.international} currency="USD" /> per statement for international postage (non-U.S.
                              address)
                            </Text>
                          </>
                        }
                      />
                      <CurrencyDisplay
                        value={
                          tier1Count * TAX_STATEMENT_FEES.mail.tier1 +
                          tier2Count * TAX_STATEMENT_FEES.mail.tier2 +
                          record.count * (formType === EndOfYearFormType.F1042 ? TAX_STATEMENT_FEES.international : 0) // for international postage
                        }
                        currency="USD"
                      />
                    </>
                  ) : (
                    <Text type="secondary">Not Applicable</Text>
                  );
                },
              },
              {
                title: "Subtotal",
                dataIndex: "subtotal",
                align: "right",
                render: (subtotal: number) => <CurrencyDisplay value={subtotal} currency="USD" />,
              },
            ]}
            rowKey="deliveryType"
            dataSource={[
              {
                deliveryType: TaxDeliveryType.E_DELIVERY,
                count: toBeEDeliveryCount,
                subtotal: baseSentCost,
              },
              {
                deliveryType: TaxDeliveryType.MAIL,
                count: toBeMailCount,
                subtotal: mailCost,
              },
            ]}
          />
          <Text>
            Total Estimated Statements Delivery Cost:
            <CurrencyDisplay value={baseSentCost.plus(mailCost)} currency="USD" style={{ marginLeft: "20px" }} />
          </Text>
          {taxMerchantYearStatus === BaseStatus.LOADED && !isProfileComplete(taxMerchantYear, formType) ? (
            <Alert type="warning">
              You must complete your
              <Link to={`${baseUrl}/${Pages.PROFILE}`}> business tax profile</Link> in order to send statements to recipients.
            </Alert>
          ) : (
            <Divider margin="small" transparent />
          )}
          <Button
            type="primary"
            disabled={
              !accessTaxEOYWrite || !approvedCount || !isProfileComplete(taxMerchantYear, formType) || isStatementActionLocked(taxYear, StatementActions.SEND)
            }
            size="large"
            onClick={() => {
              Modal.confirm({
                title: `Send ${taxFormLabel} Tax Statements?`,
                okText: "Yes, Send Statements to Recipients",
                width: 600,
                okButtonProps: {
                  loading: eoyTaxFormsStatus === BaseStatus.LOADING,
                },
                async onOk() {
                  if (eoyTaxFormsStatus !== BaseStatus.LOADING) {
                    try {
                      setEoyTaxFormsStatus(BaseStatus.LOADING);
                      const ids = await sendEoyTaxForms(taxYear, {
                        formTypes: [formType],
                      });
                      notifySuccess(`Updated ${ids.length} tax statement${ids.length > 1 ? "s" : ""}`);
                      setEoyTaxFormsStatus(BaseStatus.LOADED);

                      reloadQueries();
                    } catch (errors) {
                      setEoyTaxFormsStatus(BaseStatus.ERROR);
                      if (!!errors?.length) {
                        if (errors.some((e: BaseError) => e.code === "empty_field")) {
                          Modal.confirm({
                            title: "Incomplete business tax profile",
                            content: "You cannot send statements because the business tax profile is not complete. Do you want to complete it?",
                            okText: "Yes, bring me there",
                            onOk: () => {
                              history.push(`${baseUrl}/${Pages.PROFILE}`);
                            },
                          });
                        } else {
                          notifyError(errors[0]?.message || "Failed to send tax statements");
                        }
                      }
                    }
                  }
                },
                content: (
                  <span>
                    <TaxDeliveryCostWarning />
                    <Text weight="bold">Total Estimated Statements Delivery Cost: {baseSentCost.plus(mailCost).toFixed(2)} USD</Text>
                    <p>
                      You are about to send {approvedCount} tax statement{approvedCount > 1 ? "s" : ""}. Do you wish to send?
                    </p>
                  </span>
                ),
              });
            }}
          >
            <span>
              <Icon type="paper-plane" left />
              Send Statements to Recipients
            </span>
          </Button>
          <Divider margin="small" transparent />
        </Alert>
      )}

      {/**
       * ======================================= SENT TO RECIPIENTS =================================
       */}

      <Heading tag="h2">
        <Link
          to={{
            pathname: `${baseUrl}/${Pages.EARNINGS}`,
            search: stringifyQuery({
              page: 1,
              pageSize: 10,
              status: [EoyTaxReportStatus.SENT],
              requireIrsSubmittedAt: false,
            }),
          }}
        >
          <Icon type="circle" size="small" theme="solid" color="blue" left />
          <RecordCount
            value={sentToRecipientsCount}
            one="# sent tax statement needs to be submitted to the IRS"
            other="# sent tax statements need to be submitted to the IRS"
          />
        </Link>
      </Heading>

      <Text style={{ marginLeft: "24px" }}>
        {ongoingYear && resendCount > 0 && (
          <>
            <Dropdown
              overlay={
                <Menu
                  onClick={(params) => {
                    params?.domEvent?.preventDefault?.();
                    const taxDeliveryType = params.key === "default" ? undefined : (params.key as TaxDeliveryType);
                    const deliveryTypeLabel = taxDeliveryType ? translateDeliveryMethod(taxDeliveryType) : "Recipient's Preference";

                    Modal.confirm({
                      width: 600,
                      title: `Resend statements?`,
                      content: (
                        <>
                          <p>Tax statements that were previously sent to recipients will be resent by {deliveryTypeLabel}.</p>
                          <TaxDeliveryCostWarning showAdditionalCostWarning={taxDeliveryType !== TaxDeliveryType.E_DELIVERY} />
                        </>
                      ),
                      okText: `Yes, Resend by ${deliveryTypeLabel}`,
                      okButtonProps: {
                        loading: eoyTaxFormsStatus === BaseStatus.LOADING,
                      },
                      onOk: async () => {
                        if (eoyTaxFormsStatus !== BaseStatus.LOADING) {
                          try {
                            setEoyTaxFormsStatus(BaseStatus.LOADING);
                            const ids = await resendEoyTaxForms(taxYear, { taxDeliveryType, formTypes: [formType] });
                            setEoyTaxFormsStatus(BaseStatus.LOADED);

                            notifySuccess(
                              intl.formatMessage(
                                {
                                  id: "resend",
                                  defaultMessage: `
                                {count, plural,
                                  one {# statement has been sent}
                                  other {# statements have been sent}
                                }
                                `,
                                },
                                { count: ids.length },
                              ),
                            );
                          } catch (errors) {
                            setEoyTaxFormsStatus(BaseStatus.ERROR);
                            notifyError("Resending statements failed", { errors });
                          }
                        }
                      },
                    });
                  }}
                >
                  <Menu.Item key="default">By Recipient's Preference</Menu.Item>
                  <Menu.Item key={TaxDeliveryType.E_DELIVERY}>By {translateDeliveryMethod(TaxDeliveryType.E_DELIVERY)}</Menu.Item>
                  <Menu.Item key={TaxDeliveryType.MAIL}>By {translateDeliveryMethod(TaxDeliveryType.MAIL)}</Menu.Item>
                </Menu>
              }
            >
              <Button disabled={!accessTaxEOYWrite || !isProfileComplete(taxMerchantYear, formType) || isStatementActionLocked(taxYear, StatementActions.SEND)}>
                <span>
                  <RecordCount value={resendCount} one="Resend 1 Statement" other="Resend # Statements" />
                  <Icon type="chevron-down" right size="small" />
                </span>
              </Button>
            </Dropdown>
            <Divider transparent margin="small" />
          </>
        )}

        <Alert type="info" header="Next Step!">
          <p>You can now submit your E-File to the IRS with Trolley. The E-File is correctly formatted for the IRS FIRE System.</p>
          <p>
            Submit the {taxFormLabel} E-File by clicking the button below. If needed, you can also download a CSV or PDF version of the {taxFormLabel}{" "}
            statements. Note that the IRS strongly recommends to report digitally using the E-File method, and requires the E-File option if you have 200 or
            more statements to submit.
          </p>
          {taxMerchantYearStatus === BaseStatus.LOADED && !isProfileComplete(taxMerchantYear, formType, true) && (
            <Alert type="warning">
              You must complete your <Link to={`${TAX_PATHS.EOY_REPORTING}/${taxYear}/${Pages.PROFILE}`}>business tax profile</Link> in order to submit the
              E-file to the IRS.
            </Alert>
          )}
          <Grid>
            <Grid.Item xs={24} md={12}>
              <Text size="large" underline>
                IRS Filing
              </Text>
              <p>
                <RecordCount
                  value={sentToRecipientsCountOriginal}
                  equalZero={`We did not find any ${taxFormLabel} tax statements to be submitted to the IRS.`}
                  one={`We found # new ${taxFormLabel} tax statement ready to be submitted to the IRS.`}
                  other={`We found # new ${taxFormLabel} tax statements ready to be submitted to the IRS.`}
                />
              </p>

              <Button.Group key={formType}>
                <Button
                  type="primary"
                  loading={loading}
                  disabled={
                    !ongoingYear ||
                    !accessTaxEOYWrite ||
                    sentToRecipientsCountOriginal === 0 ||
                    !isProfileComplete(taxMerchantYear, formType, true) ||
                    isStatementActionLocked(taxYear, StatementActions.SUBMIT)
                  }
                  onClick={() => {
                    Modal.confirm({
                      title: `Submit ${taxFormLabel} E-File to the IRS`,
                      content: (
                        <>
                          Submitting E-File will generate an FIRE format file, and will be submitted to the IRS by Trolley.
                          <br />
                          Are you sure you want to submit the E-file?
                        </>
                      ),
                      okText: "Ok, Submit E-File",
                      onOk: async () => {
                        try {
                          await startEFile({ ...downloadQuery, isAmendedFile: false }); // isAmendedFile param is ignored for non-1042 generation
                          reloadQueries();
                        } catch (errors) {
                          notifyError("Failed to submit the E-File", { errors });
                        }
                      },
                    });
                  }}
                >
                  Submit E-File ({sentToRecipientsCountOriginal})
                </Button>
                <Dropdown
                  placement="bottomRight"
                  disabled={sentToRecipientsCountOriginal === 0}
                  overlay={
                    <Menu>
                      <Menu.Item key="csv">
                        <FileDownload
                          method="POST"
                          url="/v1/tax-year/eoy-form/filing-export-preview"
                          loading={loading}
                          query={{ ...downloadQuery, format: "csv", isAmendedFile: false }}
                          icon="file-csv"
                          fileName={`EFILE_PREVIEW_${taxFormLabel}`}
                          defaultExtension="csv"
                          onDownloadFail={(errors: BaseError[]) => {
                            notifyError("Failed to download CSV", { errors });
                          }}
                        >
                          Download CSV
                        </FileDownload>
                      </Menu.Item>
                      <Menu.Item key="pdf">
                        <FileDownload
                          method="POST"
                          icon="file-pdf"
                          url="/v1/tax-year/eoy-form/filing-export-preview"
                          loading={loading}
                          query={{ ...downloadQuery, format: "pdf-zip", isAmendedFile: false }}
                          fileName={`EFILE_PREVIEW_${taxFormLabel}`}
                          defaultExtension="pdf"
                          onDownloadFail={(errors: BaseError[]) => {
                            notifyError("Failed to download PDF", { errors });
                          }}
                        >
                          Download PDF
                        </FileDownload>
                      </Menu.Item>
                    </Menu>
                  }
                >
                  <Button type="primary" icon={<Icon type="angle-down" />} />
                </Dropdown>
              </Button.Group>
            </Grid.Item>
            <Grid.Item xs={24} md={12}>
              <Text size="large" underline>
                IRS Filing for {amendedLabel} tax statements
              </Text>
              <p>
                <RecordCount
                  value={sentToRecipientsCountAmended}
                  equalZero={`We did not find any ${amendedLabel} ${taxFormLabel} tax statements.`}
                  one={`We found # ${amendedLabel} ${taxFormLabel} tax statement.`}
                  other={`We found # ${amendedLabel} ${taxFormLabel} tax statements.`}
                />
              </p>
              <Button.Group>
                <Button
                  type="primary"
                  disabled={
                    !ongoingYear ||
                    !accessTaxEOYWrite ||
                    sentToRecipientsCountAmended === 0 ||
                    !isProfileComplete(taxMerchantYear, formType, true) ||
                    isStatementActionLocked(taxYear, StatementActions.SUBMIT)
                  }
                  onClick={() => {
                    Modal.confirm({
                      title: `Submit ${capitalize(amendedLabel)} ${taxFormLabel} E-File to the IRS`,
                      content: (
                        <>
                          Submitting {amendedLabel} E-File will generate an FIRE format file, and will be submitted to the IRS by Trolley.
                          <br />
                          Are you sure you want to submit the {amendedLabel} E-file?
                        </>
                      ),
                      okText: "Ok, Submit E-File",
                      onOk: async () => {
                        try {
                          await startEFile({ ...downloadQuery, isAmendedFile: true });
                          reloadQueries();
                        } catch (errors) {
                          notifyError("Failed to submit E-File", { errors });
                        }
                      },
                    });
                  }}
                >
                  Submit {capitalize(amendedLabel)} E-File ({sentToRecipientsCountAmended})
                </Button>
                <Dropdown
                  placement="bottomRight"
                  disabled={sentToRecipientsCountAmended === 0}
                  overlay={
                    <Menu>
                      <Menu.Item key="csv">
                        <FileDownload
                          method="POST"
                          url="/v1/tax-year/eoy-form/filing-export-preview"
                          loading={loading}
                          query={{ ...downloadQuery, format: "csv", isAmendedFile: true }}
                          icon="file-csv"
                          fileName={`EFILE_PREVIEW_${taxFormLabel}`}
                          defaultExtension="csv"
                          onDownloadFail={(errors: BaseError[]) => {
                            notifyError("Failed to download CSV", { errors });
                          }}
                        >
                          Download CSV
                        </FileDownload>
                      </Menu.Item>
                      <Menu.Item key="pdf">
                        <FileDownload
                          method="POST"
                          icon="file-pdf"
                          url="/v1/tax-year/eoy-form/filing-export-preview"
                          loading={loading}
                          query={{ ...downloadQuery, format: "pdf-zip", isAmendedFile: true }}
                          fileName={`EFILE_PREVIEW_${taxFormLabel}`}
                          defaultExtension="pdf"
                          onDownloadFail={(errors: BaseError[]) => {
                            notifyError("Failed to download PDF", { errors });
                          }}
                        >
                          Download PDF
                        </FileDownload>
                      </Menu.Item>
                    </Menu>
                  }
                >
                  <Button type="primary" icon={<Icon type="angle-down" />} />
                </Dropdown>
              </Button.Group>
            </Grid.Item>
          </Grid>
          <Divider transparent margin="small" />
        </Alert>

        <Divider transparent margin="medium" />
      </Text>

      {/**
       * ======================================= E-FILING =================================
       */}

      <Heading tag="h2">
        <Link
          to={{
            pathname: `${baseUrl}/${Pages.EARNINGS}`,
            search: stringifyQuery({ ...eFiledQuery, page: 1, pageSize: 10, requireTaxForm: undefined }),
          }}
        >
          <Icon type="circle" size="small" theme="solid" color="green" left />
          <RecordCount value={eFiledCount} one="# tax statement has been submitted to the IRS" other="# tax statements have been submitted to the IRS" />
        </Link>
      </Heading>
      <Alert style={{ marginLeft: "24px" }}>
        <p>Below is a table of the E-File submission history.</p>
        <Table
          dataSource={eoyExports.records}
          pagination={false}
          columns={[
            {
              title: (
                <>
                  IRS Filing Status
                  <Icon.Hint
                    right
                    tooltip={
                      <ul>
                        <li>
                          <Status stronger type={EoyExportStatusMerchant.PENDING} size="small" left />
                          The document has been generated and will be submitted to the IRS shortly.
                        </li>
                        <li>
                          <Status stronger type={EoyExportStatusMerchant.PROCESSING} size="small" left />
                          The document has been submitted and is being processed at the IRS.
                        </li>
                        <li>
                          <Status stronger type={EoyExportStatusMerchant.PROCESSED} size="small" left />
                          The document has been processed successfully at the IRS.
                        </li>
                      </ul>
                    }
                  />
                </>
              ),
              dataIndex: "status",
              render: (status: EoyTaxExport["status"]) => <Status type={status} />,
            },
            {
              title: "Document Type",
              dataIndex: "type",
              render: (type: string) => String(type).replace(/_/g, " "),
            },
            {
              title: "Submitted On",
              dataIndex: "exportedAt",
              render: (exportedAt: string) => <DateDisplay value={exportedAt} />,
            },
            {
              title: "Submitted By",
              dataIndex: "actorId",
              render: (actorId: string) => (/^U-\w+/.test(String(actorId)) ? <UserProfile userId={actorId} showEmail showRole /> : actorId),
            },
            {
              title: "# of Tax Statements",
              dataIndex: "recipientEoyTaxIdCount",
              render: (recipientEoyTaxIdCount: number) => <FormattedNumber value={recipientEoyTaxIdCount} />,
            },
            {
              dataIndex: "id",
              align: "right",
              render(id: string, eoyExport: EoyTaxExport) {
                return (
                  <Dropdown
                    placement="bottomRight"
                    overlay={
                      <Menu>
                        <Menu.Item key="csv">
                          <FileDownload
                            icon="file-csv"
                            method="POST"
                            url="/v1/tax-year/eoy-form/filing-export"
                            query={{ taxYear, format: "csv", exportGuid: id }}
                            disabled={!isProfileComplete(taxMerchantYear, formType)}
                            fileName={`EFILE_${taxFormLabel}_${eoyExport.exportedAt}`}
                            defaultExtension="csv"
                            onDownloadFail={(errors: BaseError[]) => {
                              notifyError("Failed to download CSV", { errors });
                            }}
                          >
                            Download CSV
                          </FileDownload>
                        </Menu.Item>
                        <Menu.Item key="pdf">
                          <FileDownload
                            icon="file-pdf"
                            method="POST"
                            url="/v1/tax-year/eoy-form/filing-export"
                            query={{ taxYear, format: "pdf-zip", exportGuid: id }}
                            disabled={!isProfileComplete(taxMerchantYear, formType)}
                            fileName={`EFILE_${taxFormLabel}_${eoyExport.exportedAt}`}
                            defaultExtension="pdf"
                            onDownloadFail={(errors: BaseError[]) => {
                              notifyError("Failed to download PDF", { errors });
                            }}
                          >
                            Download PDF
                          </FileDownload>
                        </Menu.Item>
                      </Menu>
                    }
                  >
                    <Button icon={<Icon type="ellipsis-h" />} />
                  </Dropdown>
                );
              },
            },
          ]}
          emptyProps={{ description: "No E-Files have been submitted previously." }}
        />
      </Alert>
    </Container>
  );
}
