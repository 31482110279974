import * as base64url from "base64-arraybuffer";

function publicKeyCredentialToJSON(item: any): any {
  if (item instanceof Array) {
    return item.map(publicKeyCredentialToJSON);
  }

  if (item instanceof ArrayBuffer) {
    // ArrayBuffer must be converted to typed arrays
    return base64url.encode(item);
  }

  if (item instanceof Object) {
    const obj = {};

    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    for (const key in item) {
      obj[key] = publicKeyCredentialToJSON(item[key]);
    }

    return obj;
  }

  return item;
}

function loginChallengeToPublicKey(getAssert: any) {
  return {
    ...getAssert,
    challenge: base64url.decode(getAssert.challenge),
    allowCredentials: getAssert.allowCredentials.map((allowCredential: any) => ({
      ...allowCredential,
      id: base64url.decode(allowCredential.id),
    })),
  };
}

export async function solveLoginChallenge(credentialsChallengeRequest: any) {
  const publicKey = loginChallengeToPublicKey(credentialsChallengeRequest);

  const credentials = await navigator.credentials.get({
    publicKey,
  });

  return publicKeyCredentialToJSON(credentials);
}

export interface PublicKeyCredentialOptions {
  challenge: string;
  rp: {
    id: string;
    name: string;
  };
  user: {
    id: string;
    displayName: string;
    name: string;
  };
  pubKeyCredParams: [
    {
      type: string;
      alg: number;
    },
  ];
}
export async function solveRegistrationChallenge(credentialsChallengeRequest: PublicKeyCredentialOptions): Promise<Credential> {
  const regOptions: PublicKeyCredentialCreationOptions = {
    challenge: base64url.decode(credentialsChallengeRequest.challenge),
    user: {
      id: base64url.decode(credentialsChallengeRequest.user.id),
      displayName: credentialsChallengeRequest.user.displayName,
      name: credentialsChallengeRequest.user.name,
    },
    rp: { ...credentialsChallengeRequest.rp },
    pubKeyCredParams: credentialsChallengeRequest.pubKeyCredParams.map((x) => {
      return {
        alg: x.alg,
        type: x.type as PublicKeyCredentialType,
      };
    }),
  };
  const credentials = await navigator.credentials.create({
    publicKey: regOptions,
  });
  const jsonCreds = publicKeyCredentialToJSON(credentials);

  return jsonCreds;
}
