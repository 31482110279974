import { Link } from "components";
import { UserAccess } from "features/user";
import React from "react";
import { useLocation } from "react-router-dom";
import { useDnsRecord, useWhiteLabel } from "store/hooks/whiteLabel";
import { BaseStatus } from "store/reducers/standardReducer";
import { Access } from "@trolley/common-frontend";

export default function useWhiteLabelWarning() {
  const { data: whiteLabel, status: whiteLabelStatus, isConfigured: isWhiteLabelConfigured } = useWhiteLabel();
  const { status: whiteLabelDnsStatus, isConfigured: isWhiteLabelDnsConfigured } = useDnsRecord();
  const location = useLocation();

  const warning =
    [whiteLabelStatus, whiteLabelDnsStatus].every((status) => status && status !== BaseStatus.LOADING) &&
    (!isWhiteLabelConfigured || !isWhiteLabelDnsConfigured) ? (
      <>
        White label settings need to be completed before sending automated emails.
        <UserAccess type={Access.SETTINGS_WRITE}>
          <ul>
            {!isWhiteLabelConfigured && [whiteLabel?.supportEmail, whiteLabel?.color, whiteLabel?.businessName, whiteLabel?.website].some((v) => !v) && (
              <li>
                Complete the <Link to="/settings/white-label">white label branding</Link>
              </li>
            )}
            {(!whiteLabel?.address || !whiteLabel?.country) && (
              <li>
                Complete the white label{" "}
                {location.pathname.includes("/settings/white-label") ? "email settings" : <Link to="/settings/white-label/emails">Email Settings</Link>}
              </li>
            )}
            {!isWhiteLabelDnsConfigured && (
              <li>
                Configure and validate the{" "}
                {location.pathname.includes("/settings/white-label") ? (
                  <strong>DNS Records</strong>
                ) : (
                  <Link to="/settings/white-label/emails">DNS Records</Link>
                )}
              </li>
            )}
          </ul>
        </UserAccess>
      </>
    ) : null;

  return { whiteLabel, status: whiteLabelStatus, warning };
}
