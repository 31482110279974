import { DateDisplay, Spinner, Status, Text } from "components";
import React from "react";
import { useTaxProfile } from "store/hooks/taxProfiles";
import { BaseStatus } from "store/reducers/standardReducer";

interface Props {
  taxProfileId: string | undefined | null;
  showStatus?: boolean;
  showSignedDate?: boolean;
  emptyText?: string;
  onClick?(e?: any): void;
}

export default function TaxProfileDisplay({ taxProfileId, showStatus, showSignedDate, onClick, emptyText }: Props) {
  const { data: taxProfile, status } = useTaxProfile(taxProfileId);

  if (taxProfile) {
    const el = (
      <span>
        DAC7 Tax Profile
        {showStatus && <Status type={taxProfile.status} size="small" right />}
        {showSignedDate && taxProfile.signedAt && (
          <Text type="secondary" size="small">
            Signed <DateDisplay value={taxProfile.signedAt} time={false} icon={false} showUtc />
          </Text>
        )}
      </span>
    );

    return onClick ? (
      <a role="button" onClick={onClick}>
        {el}
      </a>
    ) : (
      el
    );
  }

  if (status === BaseStatus.LOADING) {
    return (
      <>
        {emptyText ?? taxProfileId}
        <Spinner right />
      </>
    );
  }

  return <span>No Profile</span>;
}
