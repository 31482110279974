import React, { ReactNode } from "react";

import { Country } from "@trolley/common-frontend";
import { Error, LogoLoader } from "components";
import { useCurrencies } from "store/hooks/currencies";
import { BaseStatus, Mapped } from "store/reducers/standardReducer";

type AllProps = {
  children: (data: Mapped<Country.CountryCurrency[]>) => ReactNode;
};

export default function BankTransferWrapper({ children }: AllProps) {
  const { data: currencies, status: currenciesStatus, error } = useCurrencies();

  return (
    <LogoLoader spinning={currenciesStatus === BaseStatus.LOADING}>
      {currenciesStatus === BaseStatus.ERROR && <Error errors={error} />}
      {currenciesStatus === BaseStatus.LOADED && children(currencies)}
    </LogoLoader>
  );
}
