import React from "react";
import { BaseStatus } from "store/reducers/standardReducer";
import { Spinner } from "components";
import Icon, { IconProps } from ".";

interface Props extends Omit<IconProps, "size" | "type"> {
  status: BaseStatus | undefined;
  size?: "small" | "large" | "xsmall" | "xlarge" | "3x" | "4x" | "5x" | "6x" | "7x" | "8x" | "9x" | "10x";
  onClick(): void;
}

export default function Reloader({ status, style, onClick, tooltip = "Refresh", right = true, ...iconProps }: Props) {
  return status === BaseStatus.LOADING ? (
    <Spinner {...iconProps} style={{ fontSize: "14px", ...style }} right={right} color="blue" />
  ) : (
    <Icon {...iconProps} type="sync" right={right} tooltip={tooltip} onClick={onClick} style={{ fontSize: "14px", ...style }} />
  );
}
