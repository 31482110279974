import { Form as FormAnt, FormProps as AntFormProps, FormInstance } from "antd";
import React from "react";
import css, { classnames, createUseStyle } from "style/classname";
import { BREAKPOINTS } from "style/variables";
import { useWindowSize } from "utils/hooks";
import Control from "./Control";
import FormItem from "./Item";
import SubmitButtons from "./SubmitButtons";

export { Rule } from "antd/lib/form";
export { NamePath } from "antd/lib/form/interface";
export { FormInstance };

export interface FormProps<V = any> extends AntFormProps<V> {
  compact?: boolean;
}

export default function Form<V>(props: FormProps<V>) {
  const { isMobile, width = 0 } = useWindowSize();
  const {
    layout = "vertical",
    validateTrigger = ["onChange", "onBlur"],
    scrollToFirstError = {
      behavior: (actions) => {
        actions.forEach(({ el, top }) => {
          if (el.scrollTop !== top) {
            el.scrollTop = top - (isMobile ? 40 : 120); // consider the page heading height + label height
          }
        });
      },
    },
    labelWrap = true,
    labelAlign = "left",
    labelCol,
    className,
    compact = width < BREAKPOINTS.sm,
    ...rest
  } = props;
  const styledForm = useStyledForm({ compact });

  return (
    <FormAnt<V>
      layout={layout}
      scrollToFirstError={scrollToFirstError}
      validateTrigger={validateTrigger}
      labelWrap={labelWrap}
      labelAlign={labelAlign}
      labelCol={{ ...(layout === "horizontal" ? labelCol ?? { flex: "120px" } : undefined), ...(width < BREAKPOINTS.sm ? { flex: "1 1 100%" } : undefined) }}
      className={classnames(styledForm, className, {
        compact,
      })}
      {...rest}
    />
  );
}

Form.Item = FormItem;
Form.Control = Control;
Form.useForm = FormAnt.useForm;
Form.useFormInstance = FormAnt.useFormInstance;
Form.useWatch = FormAnt.useWatch;
Form.Provider = FormAnt.Provider;
Form.List = FormAnt.List;
Form.ErrorList = FormAnt.ErrorList;
Form.SubmitButtons = SubmitButtons;

const useStyledForm = createUseStyle<{ compact: boolean }>(({ theme, compact }) => {
  return css`
    &.compact {
      .${theme.prefixCls}-form-item {
        .${theme.prefixCls}-form-item-label {
          padding: 0;
        }

        .${theme.prefixCls}-form-item-label > label,
        .${theme.prefixCls}-form-item-control-input {
          min-height: 24px;
          height: auto;
        }
      }
      @media screen and (min-width: ${theme.screenSM}px) {
        &.${theme.prefixCls}-form-horizontal {
          .${theme.prefixCls}-form-item {
            margin-bottom: ${theme.marginXS}px;
          }
        }
      }
    }

    .${theme.prefixCls}-upload > input[type="file"] {
      display: block !important; // default is "none". This is needed for form's scroll to element ID to work
      visibility: hidden;
      position: absolute;
    }
  `();
});
