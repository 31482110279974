import { Avatar, AvatarProps } from "antd";
import { Icon, Text, Tooltip } from "components";
import StatusRecipient from "features/recipient/StatusRecipient";
import React from "react";
import { StoreRecipient } from "store/actions/recipients";
import css, { classnames, createUseStyle } from "style/classname";
import colors from "style/colors";
import { MEDIA_BREAKPOINTS } from "style/variables";

const useStyledAvatar = createUseStyle(({ theme }) =>
  css`
    ${MEDIA_BREAKPOINTS.mdUp} {
      &.${theme.prefixCls}-avatar-lg.${theme.prefixCls}-avatar-icon {
        font-weight: initial;
        height: 80px;
        width: 80px;
        line-height: 80px;
        font-size: 38px;
      }
    }
  `(),
);

interface Props extends Pick<AvatarProps, "src" | "size" | "className" | "style"> {
  recipient?: StoreRecipient;
  showStatus?: boolean;
}

export default function AvatarRecipient(props: Props) {
  const { recipient, className, showStatus, ...avatarProps } = props;
  const styledAvatar = useStyledAvatar();

  if (!recipient) {
    return null;
  }

  const initials =
    recipient.type === "business"
      ? String(recipient.name).charAt(0).toLocaleUpperCase()
      : [String(recipient.firstName).charAt(0), String(recipient.lastName).charAt(0)]
          .filter((v) => v)
          .join("")
          .toLocaleUpperCase();
  const icon = initials ? <span>{initials}</span> : <Icon type={recipient.type === "business" ? "building" : "user"} theme="solid" />;

  const avatar = (
    <div>
      <Avatar
        className={classnames(className, styledAvatar)}
        {...avatarProps}
        src={recipient.gravatarUrl}
        shape={recipient.type === "business" ? "square" : "circle"}
        icon={icon}
      />
      {!!showStatus && (
        <StatusRecipient
          recipient={recipient}
          dot
          style={{
            position: "absolute",
            right: "2px",
            bottom: "-2px",
            border: `2px solid ${colors.white}`,
          }}
        />
      )}
    </div>
  );

  return showStatus ? <Tooltip title={<Text capitalize>{recipient.status}</Text>}>{avatar}</Tooltip> : avatar;
}
