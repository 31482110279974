export const requestController = (() => {
  let controller = new AbortController();

  function getSignal() {
    return controller.signal;
  }

  function cancel() {
    controller.abort();
    controller = new AbortController();
  }

  return {
    cancel,
    getSignal,
  };
})();
