import { useDebouncedValue } from "@shopify/react-hooks";
import { AutoComplete, AutoCompleteProps } from "antd";
import { Grid, Icon, Input, Spinner, Text } from "components";
import { AddRecipientPopup, RecipientPreview, RecipientProfile } from "features/recipient";
import React, { useState } from "react";
import { StoreRecipient } from "store/actions/recipients";
import { useRecipientList } from "store/hooks/recipients";
import { BaseStatus } from "store/reducers/standardReducer";

function getOptions(records: StoreRecipient[], status: BaseStatus | undefined, searchRecipientInput: string): AutoCompleteProps["options"] {
  let recipientMatches: AutoCompleteProps["options"] = [];
  if (status === BaseStatus.LOADING) {
    return [
      {
        key: "loading",
        disabled: true,
        label: (
          <>
            <Spinner left /> searching recipient...
          </>
        ),
      },
    ];
  }

  if (searchRecipientInput && status === BaseStatus.LOADED) {
    recipientMatches =
      records.length > 0
        ? records.map((r) => ({
            key: r.id,
            value: r.id,
            label: <RecipientProfile recipientId={r.id} size="small" showStatus="dot" showEmail />,
          }))
        : [
            {
              disabled: true,
              key: "empty",
              label: <Text padded>No matching recipient found.</Text>,
            },
          ];
  }

  recipientMatches.push({
    value: "new",
    label: (
      <>
        <Icon type="plus" left />
        Create a new recipient
      </>
    ),
  });

  return recipientMatches;
}
type Props = {
  value?: string; // recipientId;
  onChange?(value: string): void;
};

export default function InputRecipientPicker(props: Props) {
  const { value, onChange } = props;
  const [visiblePreview, setVisible] = useState(false);
  const [showNewRecipient, setShowNewRecipient] = useState(false);
  const [searchRecipientInput, setSearchRecipientInput] = useState("");
  const debouncedInput = useDebouncedValue(searchRecipientInput, { timeoutMs: 500 });

  const { data: recipientList, status: recipientListStatus } = useRecipientList({ search: debouncedInput }, !!debouncedInput);
  const recipientId = value && /^R-\w+$/.test(value) ? value : "";

  return recipientId ? (
    <Grid padding="small" justify="space-between">
      <Grid.Item>
        <RecipientProfile
          recipientId={recipientId}
          showEmail
          size="small"
          showStatus="dot"
          showLink={() => {
            setVisible(true);
          }}
        />
        <RecipientPreview
          recipientId={visiblePreview ? value : undefined}
          onClose={() => {
            setVisible(false);
          }}
        />
      </Grid.Item>
      <Grid.Item align="right" alignSelf="center">
        <Icon
          type="trash"
          theme="solid"
          color="red"
          size="large"
          tooltip="Change Recipient"
          onClick={() => {
            setSearchRecipientInput("");
            onChange?.("");
          }}
        />
      </Grid.Item>
    </Grid>
  ) : (
    <>
      <AutoComplete
        options={getOptions(recipientList.records, recipientListStatus, searchRecipientInput)}
        onChange={(search: string) => {
          setSearchRecipientInput(search);
        }}
        value={searchRecipientInput}
        onSelect={(v: string) => {
          setSearchRecipientInput("");
          if (v === "new") {
            setShowNewRecipient(true);
          } else if (v) {
            onChange?.(v);
          }
        }}
        optionFilterProp="value"
      >
        <Input placeholder="Search for a recipient" prefix={<Icon type="search" size="small" color="grey" />} />
      </AutoComplete>
      <AddRecipientPopup
        visible={showNewRecipient}
        showExtraAddOptions={false}
        onAdded={(recipient) => {
          onChange?.(recipient.id);
        }}
        onClose={() => {
          setShowNewRecipient(false);
        }}
      />
    </>
  );
}
