function pick<T extends object>(obj: T, filters: string[]): T;
function pick<T extends object>(obj: T, filters: (key: string, value: any) => boolean): T;
function pick<T extends object>(obj: T, filters: any): T {
  return Object.fromEntries(
    Array.isArray(filters)
      ? filters.filter((key) => key in obj).map((key) => [key, obj[key]])
      : Object.entries(obj).filter(([key, value]) => filters(key, value)),
  ) as T;
}

export default pick;
