import { Access, OnboardingType } from "@trolley/common-frontend";
import { Alert, ButtonLink, Divider, ExternalLink, Grid, Icon, Paragraph, Text } from "components";
import { UserAccess } from "features/user";
import { BUSINESS_ONBOARDING_PATH } from "pages/BusinessProfile";
import React from "react";
import { Link } from "react-router-dom";
import { useMerchantSettings } from "store/hooks/merchantSettings";
import { useOnboarding } from "store/hooks/onboarding";
import styled, { createUseStyle } from "style/classname";
import { useWindowSize } from "utils/hooks";

export const ONBOARDING_TOUR_LINK = "https://trolleyhq.notion.site/Trolley-Onboarding-Guide-b5ea5f63acc848a38abdf6020d037e96";

export default function ActivateAlert() {
  const { isMobile } = useWindowSize();
  const { data: merchantSettings, isActive } = useMerchantSettings();
  const { data: onboarding } = useOnboarding();
  const styledAlert = useStyledAlert({ isMobile });

  if (merchantSettings?.onboardingType === OnboardingType.HEAVY && !merchantSettings.sandbox && onboarding && !isActive) {
    return (
      <UserAccess type={Access.ONBOARDING_WRITE}>
        {!!onboarding?.completedAt ? (
          <Alert type="info" header="Your Business Profile has been submitted">
            <Text style={{ maxWidth: "75ch" }}>
              You’re almost there!
              <br />
              One of our team will contact you by email or phone to follow up within 24-48 hours.
              <br />
              It usually takes about 5 business days to review an application and make an approval decision, for straight forward applications.
              <br />
              {merchantSettings.onboardingType === OnboardingType.HEAVY && (
                <>
                  In the meantime, we suggest you take the <ExternalLink href={ONBOARDING_TOUR_LINK}>Onboarding Guide Tour</ExternalLink>. If you need to change
                  any information provided, you can <Link to={BUSINESS_ONBOARDING_PATH}>update your business profile</Link>.
                  <br />
                </>
              )}
              We are super excited to be working with you and can’t wait to get you up and running!
            </Text>
          </Alert>
        ) : (
          <Grid padding="large" align="middle" justify="space-between" className={styledAlert}>
            <Grid.Item flex={10}>
              <Text weight="bold" size="xxlarge">
                Complete your Business Profile
              </Text>
              <Divider transparent margin="small" />

              <Paragraph limitWordPerLine>
                Activate your account today to enable global transfers and other features. Complete our online activation form in 15 minutes.
              </Paragraph>
            </Grid.Item>
            <Grid.Item flex="1 1 280px" align="center">
              <ButtonLink path={BUSINESS_ONBOARDING_PATH} type="primary" size="large" block>
                Complete Business Profile
                <Icon type="angle-right" right />
              </ButtonLink>
            </Grid.Item>
          </Grid>
        )}
        <Divider margin="medium" transparent />
      </UserAccess>
    );
  }

  return null;
}

const useStyledAlert = createUseStyle<{ isMobile: boolean }>(({ theme, isMobile }) =>
  styled`
  &.grid {
    padding: ${isMobile ? "24px" : "40px"};
    border-radius: ${theme.borderRadius}px;
    color: ${theme.colorWhite};
    background-color: ${theme.purple7};
    background-image: linear-gradient(130deg, ${theme.colorPrimary}, ${theme.purple7} 75%);
    margin: 0 !important;
  }
`(),
);
